<template>
  <div class="pt-3" v-cy="$cy.gathering.details.container">
    <gathering-comments v-if="hasFullAccess" />

    <div v-if="hasFullAccess" class="mt-5 mb-3 d-flex justify-space-between align-center">
      <div>
        <mybb-text v-if="hasPendingSensibleChanges" color="mybb-warning" size="16" weight="bold" class="pl-1">
          {{ t('sensibleChangesHeader', { date: latestSensibleChangeDate }) }}
        </mybb-text>
      </div>

      <div>
        <mybb-btn
          v-if="canEditGatheting"
          inner-icon="mdi-pencil"
          background="white"
          color="mybb-grey-lighten1"
          icon
          @click="openEdit"
          class="mr-3"
        />
        <gathering-status-button
          v-if="$get(gathering, 'status') !== $const.gatheringStatus.DRAFT"
          :gathering="mergedSensiblesGathering"
          class="mr-3"
          hide-on-disabled
          @refresh="$apollo.queries.gathering.refetch()"
        />
        <mybb-btn v-if="canWindedUpGathering" @click="windedUpEventModal = true" color="mybb-error">
          {{ t('windedUpEvent') }}
        </mybb-btn>
      </div>
    </div>

    <informations-tile :gathering="mergedSensiblesGathering" class="mb-6" show-sensible-changes />

    <team-tile :gathering="mergedSensiblesGathering" class="mb-6" />

    <business-unit-tile
      v-for="gbu of gatheringBusinessUnits"
      :key="gbu.businessUnitUuid"
      :business-unit-uuid="gbu.businessUnitUuid"
      :is-main="mergedSensiblesGathering.mainBusinessUnitUuid === gbu.businessUnitUuid"
      :gathering="mergedSensiblesGathering"
      :raw-gathering="gathering"
      :gathering-business-unit="gbu"
      class="mb-6"
      show-sensible-changes
    />

    <documents-tile :gathering="gathering" class="mb-6" />

    <historic-tile :gathering="gathering" />

    <!--  Modal close event  -->
    <modal v-model="windedUpEventModal" @change="resetWindedUpEventConfirmation" :title="t('windedUpEventModal.title')">
      <mybb-text>{{ t('windedUpEventModal.text') }}</mybb-text>
      <mybb-text weight="bold">{{ t('windedUpEventModal.warningText') }}</mybb-text>
      <v-checkbox
        v-model="windedUpEventConfirmation"
        :label="t('windedUpEventModal.checkboxText')"
        color="mybb-primary-lighten1"
        dense
      />

      <div class="text-center mt-10">
        <mybb-btn @click="hideWindedUpEventModal" color="mybb-grey" class="mr-10">
          {{ t('windedUpEventModal.cancel') }}
        </mybb-btn>

        <mybb-btn
          @click="confirmWindedUpEvent"
          :disabled="!windedUpEventConfirmation"
          :loading="windedUpLoading"
          color="mybb-error"
        >
          {{ t('windedUpEventModal.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

import { GET_ALL_SETTINGS_GATHERING_WITH_HISTORIC } from '@/graphql/Gatherings/GetGathering'
import { WINDED_UP } from '@/graphql/Gatherings'
import { mergeSensibleChanges } from '@/services/gathering/mergeSensibleChanges'

import GatheringComments from '@/components/mybb/gathering/GatheringComments'
import InformationsTile from '@/components/mybb/gathering/InformationsTile'
import TeamTile from '@/components/mybb/gathering/TeamTile'
import BusinessUnitTile from '@/components/mybb/gathering/BusinessUnitTile'
import DocumentsTile from '@/components/mybb/gathering/DocumentsTile'
import HistoricTile from '@/components/mybb/gathering/HistoricTile'
import GatheringStatusButton from '@/components/mybb/gathering/GatheringStatusButton'
import Modal from '@/components/mybb/Modal'

export default {
  name: 'GatheringDetails',
  components: {
    Modal,
    GatheringComments,
    InformationsTile,
    TeamTile,
    BusinessUnitTile,
    DocumentsTile,
    HistoricTile,
    GatheringStatusButton
  },
  data() {
    return {
      windedUpEventConfirmation: false,
      windedUpEventModal: false,
      windedUpLoading: false
    }
  },
  computed: {
    hasFullAccess() {
      const { ROC, ADMIN, SUPER_ADMIN } = this.$const.userType
      const userTypes = this.$get(this.$store.state, 'myUser.userTypes', [])

      return [ROC, ADMIN, SUPER_ADMIN].some(role => userTypes.includes(role))
    },
    gatheringBusinessUnits() {
      if (!this.mergedSensiblesGathering) return []

      const gatheringBusinessUnits = Array.from(this.mergedSensiblesGathering.gatheringBusinessUnits)
      const mainBuIndex = gatheringBusinessUnits.findIndex(
        bu => bu.businessUnitUuid === this.mergedSensiblesGathering.mainBusinessUnitUuid
      )
      if (mainBuIndex >= 0) {
        const [mainBu] = gatheringBusinessUnits.splice(mainBuIndex, 1)
        gatheringBusinessUnits.unshift(mainBu)
      }

      return gatheringBusinessUnits
    },
    canEditGatheting() {
      return !this.isWindedUpGathering
    },
    canWindedUpGathering() {
      const { ADMIN, SUPER_ADMIN, ROC } = this.$const.userType
      const userTypes = this.$get(this.$store.state, 'myUser.userTypes', [])

      return [ADMIN, SUPER_ADMIN, ROC].some(role => userTypes.includes(role)) && !this.isWindedUpGathering
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    },
    mergedSensiblesGathering() {
      if (!this.gathering) return this.gathering

      return this.gathering ? mergeSensibleChanges(this.gathering) : this.gathering
    },
    latestSensibleChange() {
      if (!this.gathering || (this.gathering && !Array.isArray(this.gathering.gatheringSensibleChanges))) return null

      return Array.from(this.gathering.gatheringSensibleChanges)
        .filter(gsc => gsc.status === this.$const.gatheringSensibleChangeStatus.PENDING)
        .sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))[0]
    },
    hasPendingSensibleChanges() {
      return Boolean(this.latestSensibleChange)
    },
    latestSensibleChangeDate() {
      if (!this.latestSensibleChange) return null

      return dateFormat(new Date(this.latestSensibleChange.updatedAt), 'dd/MM/yy - HH:mm')
    }
  },
  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING_WITH_HISTORIC,
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.gatheringDetails.${key}`, params)
    },
    openEdit() {
      this.$router.push({
        name: 'UpdateEvent',
        params: { gatheringUuid: this.$route.params.gatheringUuid, step: 1 },
        query: { isEdition: true }
      })
    },
    hideWindedUpEventModal() {
      this.windedUpEventModal = false
      this.resetWindedUpEventConfirmation()
    },
    resetWindedUpEventConfirmation() {
      this.windedUpEventConfirmation = false
    },
    async confirmWindedUpEvent() {
      this.windedUpLoading = true

      try {
        await this.$apollo.mutate({
          mutation: WINDED_UP,
          variables: {
            gatheringUuid: this.$route.params.gatheringUuid,
            congressUuid: this.$get(this.gathering, 'congress.congressUuid'),
            biogenEventUuid: this.$get(this.gathering, 'biogenEvent.biogenEventUuid')
          }
        })

        await this.$apollo.queries.gathering.refetch()
        this.hideWindedUpEventModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.windedUpLoading = false
      }
    }
  }
}
</script>
