<template>
  <div>
    <LoaderTab v-if="!user" />
    <div v-else>
      <div class="px-3 py-4 NavHead">
        <v-icon style="color: white; display: inline-table">mdi-chevron-left</v-icon>
        <a @click="backToList">
          <mybb-text class="white--text">{{ t('goBackLink') }}</mybb-text>
        </a>
      </div>
      <v-card class="mb-5 StaffInformationBloc">
        <v-card-title class="px-5 py-4">
          <mybb-text class="text-uppercase" weight="bold" size="16">
            {{ t('title') }}
          </mybb-text>
          <div class="separator" />
          <v-row>
            <v-col v-for="(field, i) in staffInformations" :key="`${field.label}-${i}`" cols="4">
              <span class="StaffInformationBloc-label">
                <mybb-text size="12" class="mybb-text-lighten1--text">{{ field.label }}</mybb-text>
              </span>
              <mybb-text :class="field.whiteSpace ? 'whiteSpace' : ''">{{ field.value }}</mybb-text>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </div>
  </div>
</template>

<script>
import { GET_USER } from '@/graphql/User/GetUser'
import LoaderTab from '@/components/congrex/tabs/LoaderTab'

export default {
  name: 'StaffProfile',
  components: {
    LoaderTab
  },
  computed: {
    staffInformations() {
      return [
        {
          label: this.t('StaffInformations.firstName'),
          value: this.user.firstName || '- -'
        },
        {
          label: this.t('StaffInformations.lastName'),
          value: this.user.lastName || '- -'
        },
        {
          label: this.t('StaffInformations.businessUnit'),
          value: this.user.mslMeta ? this.user.mslMeta.zone.label : '- -'
        },
        {
          label: this.t('StaffInformations.email'),
          value: this.user.email || '- -'
        },
        {
          label: this.t('StaffInformations.phoneNumber'),
          value: this.user.phone || '- -'
        }
      ]
    }
  },
  methods: {
    backToList() {
      return this.$router.push({ name: 'AttendeeList', params: { gatheringUuuid: this.$route.params.gatheringUuuid } })
    },
    t(key, params) {
      return this.$t(`mybb.StaffProfile.${key}`, params)
    }
  },
  apollo: {
    user: {
      query: GET_USER,
      variables() {
        return {
          uuid: this.$route.params.userUuid
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.StaffInformationBloc {
  border-radius: 8px !important;
}
.StaffInformationBloc-label {
  line-height: 12px;
  display: block;
}

.separator {
  height: 1px;
  background: var(--v-mybb-grey-divider-base);
  display: block;
  width: 100%;
  margin: 5px 0;
}

.NavHead {
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: border 0.3s;
    &:hover {
      border-bottom: 1px solid white;
    }
  }
}

.whiteSpace {
  white-space: break-spaces;
}
</style>
