/**
 * @param {string[]} eventCategories
 * @param {string[]} fileCategories
 * @returns {boolean}
 */
function doesCategoriesHaveChanged(eventCategories, fileCategories) {
  if (!Array.isArray(eventCategories) || !Array.isArray(fileCategories)) return true

  if (eventCategories.length !== fileCategories.length) return true

  const eventCategoriesAreNotDefined = eventCategories.some(category => !fileCategories.includes(category))
  const fileCategoriesAreNotDefined = fileCategories.some(category => !eventCategories.includes(category))

  return eventCategoriesAreNotDefined && fileCategoriesAreNotDefined
}

/**
 * @param {Array<GatheringFile>} eventFiles
 * @param {Array<GatheringFile>} formFiles
 * @returns {boolean}
 */
export default (eventFiles, formFiles) => {
  if (eventFiles.length !== formFiles.length) return true

  for (const eventFile of eventFiles) {
    const alreadyDefined = formFiles.some(formFile => {
      if (
        doesCategoriesHaveChanged(eventFile.categories, formFile.categories) &&
        eventFile.categories &&
        formFile.categories
      )
        return false

      return (
        eventFile.displayToHcp === formFile.displayToHcp &&
        eventFile.file.content.replace(/^data:([^;]+);base64,/, '') ===
          formFile.file.content.replace(/^data:([^;]+);base64,/, '') &&
        eventFile.fileGatheringUuid === formFile.fileGatheringUuid &&
        eventFile.gatheringUuid === formFile.gatheringUuid &&
        eventFile.isPhysical === formFile.isPhysical &&
        eventFile.isVirtual === formFile.isVirtual &&
        eventFile.label === formFile.label &&
        eventFile.type === formFile.type
      )
    })

    if (alreadyDefined) continue

    return true
  }

  return false
}
