var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.activity.addList.container),expression:"$cy.activity.addList.container"}],staticClass:"ActivityParticipants pb-8"},[_c('v-row',{staticClass:"ml-0"},[_c('mybb-btn',{staticClass:"px-0 py-0",attrs:{"text":"","color":"white","inner-icon":"mdi-chevron-left"},on:{"click":_vm.backToDetails}},[_vm._v(" "+_vm._s(_vm.t('return'))+" ")])],1),_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('mybb-text-field',{attrs:{"background-color":"white","placeholder":_vm.t('searchPlaceholder'),"icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('span',{staticClass:"BtnWrapper"},[_c('mybb-btn',{staticClass:"ml-2",attrs:{"color":"mybb-grey-lighten1","background":"white","inner-icon":"mdi-filter-variant","icon":""},on:{"click":function($event){_vm.filtersOpen = true}}}),(_vm.isFilterSelected)?_c('div',{staticClass:"ActivityParticipants-buttonDot"}):_vm._e()],1)],1),_c('v-container',{staticClass:"Container px-6 py-5",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"DataTable",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"custom-filter":_vm.$dataTableFilter(_vm.filterFields),"item-key":"participantUuid","sort-by":"lastName","sort-desc":"","show-select":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('mybb-text',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.activity.addList.item(item.lastName.toUpperCase(), item.firstName).lastName),expression:"$cy.activity.addList.item(item.lastName.toUpperCase(), item.firstName).lastName"}],staticClass:"text-uppercase",attrs:{"weight":"bold"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.firstName",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(value))])]}},{key:"item.category",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":value.color,"dark":""}},[_vm._v(" "+_vm._s(value.label)+" ")]):_vm._e()]}},{key:"item.businessUnit",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.buConfig[value.label].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.buConfig[value.label].label)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('fixed-footer',{attrs:{"visible":_vm.selected.length > 0,"label":_vm.t('footer.label', { selected: _vm.selected.length })}},[_c('mybb-btn',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.activity.addList.subscribe.button),expression:"$cy.activity.addList.subscribe.button"}],attrs:{"color":"mybb-success","inner-icon":"mdi-check"},on:{"click":function($event){_vm.subscriptionModal = true}}},[_vm._v(" "+_vm._s(_vm.t('footer.subscribe'))+" ")])],1),_c('modal',{attrs:{"title":_vm.t('subscriptionModal.title')},model:{value:(_vm.subscriptionModal),callback:function ($$v) {_vm.subscriptionModal=$$v},expression:"subscriptionModal"}},[_c('mybb-text',[_vm._v(" "+_vm._s(_vm.t('subscriptionModal.text', { selected: _vm.selected.length }))+" ")]),_c('div',{staticClass:"d-flex justify-center mt-10"},[_c('mybb-btn',{staticClass:"mr-10",attrs:{"color":"mybb-grey"},on:{"click":function($event){_vm.subscriptionModal = false}}},[_vm._v(" "+_vm._s(_vm.t('subscriptionModal.cancel'))+" ")]),_c('mybb-btn',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.activity.addList.subscribe.confirm),expression:"$cy.activity.addList.subscribe.confirm"}],attrs:{"color":"mybb-success"},on:{"click":_vm.subscribe}},[_vm._v(" "+_vm._s(_vm.t('subscriptionModal.confirm'))+" ")])],1)],1),_c('filter-drawer',{attrs:{"open":_vm.filtersOpen,"items":_vm.filterItems,"label":_vm.$t('mybb.transportNoteList.statusFilterLabel')},on:{"close":function($event){_vm.filtersOpen = false}},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }