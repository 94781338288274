<template>
  <div class="TransportNotePage">
    <Loader v-if="!transportNote" color="white" />
    <v-container v-else fluid class="py-0 pt-0">
      <v-row justify="space-between">
        <mybb-btn text color="white" class="px-0" @click="backToList" inner-icon="mdi-chevron-left">
          {{ t('back') }}
        </mybb-btn>

        <v-chip :color="statusColor" dark class="mr-4 py-2 px-7">
          <mybb-text weight="bold" class="white--text">{{ statusLabel }}</mybb-text>
        </v-chip>
      </v-row>

      <!-- Container -->
      <v-row align="start">
        <!-- Main content -->
        <v-col cols="12" md="8" class="ContentContainer px-6 py-5">
          <!-- Header -->
          <v-row justify="space-between" align="center">
            <v-col class="py-0">
              <mybb-text size="16" weight="bold" class="text-uppercase">{{ informationHead }}</mybb-text>
            </v-col>

            <v-col class="py-0 text-right">
              <mybb-text size="10" weight="semi-bold">
                <v-icon class="mr-2" color="mybb-grey-lighten1" small>mdi-clock-outline</v-icon>
                {{ t('lastUpdate', { date: lastUpdateDate, hour: lastUpdateHour }) }}
              </mybb-text>
            </v-col>
          </v-row>

          <v-divider class="mt-3 mb-5" />

          <!-- Global information -->
          <transport-request-group v-if="!isInternalNote" :note="transportNote" />

          <v-row v-if="!isInternalNote" class="ma-0 pa-0 my-6" justify="center">
            <v-col class="ma-0 pa-0" cols="6" md="6">
              <v-divider />
            </v-col>
          </v-row>

          <!-- Journeys -->
          <v-row class="ma-0 mb-3">
            <mybb-text size="16" weight="bold" class="text-uppercase">{{ t('travelProposal') }}</mybb-text>
          </v-row>

          <transport-journey
            v-model="transportNote"
            @saved="$apollo.queries.transportNote.refresh()"
            travels-through-model
            :is-disabled="isWindedUpGathering || transportNote.status === this.$const.transport.noteStatus.REFUSED"
          />

          <!-- Buttons -->
          <v-row class="mt-10">
            <v-col cols="4">
              <mybb-btn
                v-if="canRefuse"
                :loading="loading.cancel"
                :disabled="isWindedUpGathering"
                color="mybb-error"
                @click="showRefuseModal = true"
              >
                {{ t('cancel') }}
              </mybb-btn>
            </v-col>

            <v-col cols="4" align="center">
              <mybb-btn
                :disabled="isWindedUpGathering"
                :loading="loading.save"
                color="mybb-success"
                inner-icon="mdi-content-save"
                @click="preSave"
              >
                {{ t('save') }}
              </mybb-btn>
            </v-col>

            <v-col cols="4" align="end">
              <mybb-btn
                v-if="showSend"
                :disabled="!canSend || isWindedUpGathering"
                :loading="loading.send"
                inner-icon="mdi-send"
                @click="submit"
              >
                {{ isFromBiogenStaff ? t('validate') : t('send') }}
              </mybb-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- HCP information -->
        <v-col cols="12" md="4" class="py-0 my-0">
          <side-panel :title="t('hosting.sectionTitle')">
            <ul class="pa-0 HostingInformations">
              <li class="HostingInformations-listElement">
                <mybb-text weight="semi-bold" class="mybb-grey-lighten1--text HostingInformations-listElement-header">
                  {{ t('hosting.checkIn') }}
                </mybb-text>

                <mybb-text weight="semi-bold">{{ checkIn }}</mybb-text>
              </li>
              <li class="HostingInformations-listElement">
                <mybb-text weight="semi-bold" class="mybb-grey-lighten1--text HostingInformations-listElement-header">
                  {{ t('hosting.checkOut') }}
                </mybb-text>

                <mybb-text weight="semi-bold">{{ checkOut }}</mybb-text>
              </li>
            </ul>
          </side-panel>

          <hcp-informations v-if="!isInternalNote" :hcp="$get(transportNote, 'participant.healthCareProfessional')" />
        </v-col>
      </v-row>
    </v-container>

    <send-transport-modal v-model="showSendModal" :note="transportNote" @confirm="sendNote" />

    <validate-biogen-staff-transport-modal
      v-model="showValidateBiogenStaffModal"
      :participant="$get(transportNote, 'participant')"
      @confirm="confirmBiogenStaffNote"
    />

    <refusal-modal v-model="showRefuseModal" :note="transportNote" @refuse="cancelNote" />

    <data-not-saved-modal :initial="initial" :current="transportNote" />

    <modal v-model="showConfirmSaveModal" :title="t('confirmSaveModal.title')">
      <mybb-text>{{ t('confirmSaveModal.subtitle') }}</mybb-text>
      <mybb-text weight="bold" class="mt-3">{{ t('confirmSaveModal.text') }}</mybb-text>
      <v-checkbox
        v-model="isConfirmedInSaveModal"
        :label="t('confirmSaveModal.checkboxText')"
        class="mt-3"
        dense
        color="mybb-primary-lighten1"
      />

      <v-row class="mt-10" justify="center">
        <mybb-btn class="mr-10" color="mybb-primary" @click="closeConfirmSaveModal">
          {{ t('confirmSaveModal.cancel') }}
        </mybb-btn>

        <mybb-btn
          :disabled="!isConfirmedInSaveModal"
          :loading="loading.save"
          color="mybb-success"
          @click="confirmSaveModal"
        >
          {{ t('confirmSaveModal.confirm') }}
        </mybb-btn>
      </v-row>
    </modal>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'
import isEqual from 'lodash/isEqual'

import {
  GET_TRANSPORT_NOTE,
  CANCEL_TRANSPORT_NOTE,
  UPDATE_TRANSPORT_NOTE,
  ACCEPT_TRANSPORT_NOTE,
  SEND_TRANSPORT_NOTE_PROPOSITION,
  VALIDATE_BIOGEN_STAFF_TRANSPORT_NOTE
} from '@/graphql/Transport'
import { OVERNIGHT_STAYS_FOR_PARTICIPANT } from '@/graphql/Hosting'

import Loader from '@/components/congrex/tabs/LoaderTab'
import HcpInformations from '@/components/mybb/ui/HcpInformations'
import SidePanel from '@/components/mybb/ui/SidePanel'
import TransportRequestGroup from '@/components/mybb/transport/TransportRequestGroup'
import TransportJourney from '@/components/mybb/transport/TransportJourney'
import DataNotSavedModal from '@/components/mybb/DataNotSavedModal'
import SendTransportModal from '@/components/mybb/transport/SendTransportModal'
import ValidateBiogenStaffTransportModal from '@/components/mybb/transport/ValidateBiogenStaffTransportModal'
import RefusalModal from '@/components/mybb/transport/RefusalModal'
import Modal from '@/components/mybb/Modal'

import { file } from '@/mixins'

export default {
  name: 'TransportNotePage',
  components: {
    Modal,
    Loader,
    HcpInformations,
    SidePanel,
    TransportRequestGroup,
    TransportJourney,
    DataNotSavedModal,
    SendTransportModal,
    ValidateBiogenStaffTransportModal,
    RefusalModal
  },
  mixins: [file],
  data() {
    return {
      initial: null,
      showSendModal: false,
      showRefuseModal: false,
      showValidateBiogenStaffModal: false,
      loading: {
        save: false,
        cancel: false,
        send: false
      },
      showConfirmSaveModal: false,
      isConfirmedInSaveModal: false
    }
  },
  computed: {
    informationHead() {
      const visualIdentifier = this.$get(this.transportNote, 'visualIdentifier', '--')
      const firstName = this.$get(this.transportNote, 'participant.firstName', '--')
      const lastName = this.$get(this.transportNote, 'participant.lastName', '--')
      const date = this.$get(this.transportNote, 'noteReceivalDate', null)

      let label = `${visualIdentifier} ${firstName} ${lastName}`

      if (date) {
        const _date = date instanceof Date ? date : new Date(date)

        label += ` - ${dateFormat(_date, 'dd/MM/yy, HH:mm')}`
      }

      return label
    },
    isInternalNote() {
      const origin = this.$get(this.transportNote, 'origin', null)

      return origin === this.$const.noteOrigin.INTERNAL
    },
    isFromBiogenStaff() {
      return this.$get(this.transportNote, 'participant.userUuid', null) !== null
    },
    lastUpdateDate() {
      const lastUpdate = this.$get(this.transportNote, 'lastUpdate', new Date())

      if (!lastUpdate) return 'N/A'

      const date = new Date(lastUpdate)
      return dateFormat(date, 'dd/MM/yyyy')
    },
    lastUpdateHour() {
      const lastUpdate = this.$get(this.transportNote, 'lastUpdate', new Date())

      if (!lastUpdate) return null

      const date = new Date(lastUpdate)
      return dateFormat(date, 'HH:mm')
    },
    statusColor() {
      const status = this.$get(this.transportNote, 'status', null)

      if (!status) return null

      return this.$const.transport.colors[status]
    },
    statusLabel() {
      const status = this.$get(this.transportNote, 'status', null)

      if (!status) return 'N/A'

      return this.$t(`mybb.transportNotePage.status.${status}`)
    },
    backendTransportNote() {
      return {
        agencyFee: this.transportNote.agencyFee,
        files: this.transportNote.files,
        ticketPrice: this.transportNote.ticketPrice,
        transportNoteUuid: this.transportNote.transportNoteUuid,
        travels: this.transportNote.travels
      }
    },
    canRefuse() {
      return this.transportNote.status !== this.$const.transport.noteStatus.REFUSED
    },
    showSend() {
      const { PENDING, TO_EMIT } = this.$const.transport.noteStatus

      return [PENDING, TO_EMIT].includes(this.$get(this.transportNote, 'status', null))
    },
    canSend() {
      const { PENDING, TO_EMIT } = this.$const.transport.noteStatus

      switch (this.transportNote.status) {
        case PENDING: {
          const travels = this.$get(this.transportNote, 'travels', [])

          if (travels.length <= 0) return false

          const goTravels = travels.filter(travel => travel.type === this.$const.transport.travelType.GO)
          const backTravels = travels.filter(travel => travel.type === this.$const.transport.travelType.BACK)

          return goTravels.length > 0 || backTravels.length > 0
        }

        case TO_EMIT:
          return this.$get(this.transportNote, 'files', []).length > 0

        default:
          return false
      }
    },
    isWindedUpGathering() {
      const gatheringStatus = this.$get(this.transportNote, 'gathering.status', null)

      return gatheringStatus === this.$const.gatheringStatus.WINDED_UP
    },
    checkIn() {
      const stays = (this.overnightStays || []).filter(
        stay => stay.status !== this.$const.hosting.overnightStayStatus.REFUSED
      )

      if (stays.length <= 0) return '- -'

      const checkIn = stays
        .map(stay => stay.date)
        .sort()
        .shift()

      return this.$moment(checkIn).format('DD/MM/YY')
    },
    checkOut() {
      const stays = (this.overnightStays || []).filter(
        stay => stay.status !== this.$const.hosting.overnightStayStatus.REFUSED
      )

      if (stays.length <= 0) return '- -'

      const checkIn = stays
        .map(stay => stay.date)
        .sort()
        .pop()

      return this.$moment(checkIn)
        .add(1, 'day')
        .format('DD/MM/YY')
    }
  },
  apollo: {
    transportNote: {
      query: GET_TRANSPORT_NOTE,
      variables() {
        return {
          transportNoteUuid: this.$get(this.$route, 'params.transportNoteUuid')
        }
      },
      update({ transportNote }) {
        return this.cleanNote(transportNote)
      },
      skip() {
        return !this.$get(this.$route, 'params.transportNoteUuid')
      },
      result() {
        this.initial = JSON.parse(JSON.stringify(this.transportNote))
      }
    },
    overnightStays: {
      query: OVERNIGHT_STAYS_FOR_PARTICIPANT,
      variables() {
        return {
          participantUuid: this.$get(this.transportNote, 'participantUuid')
        }
      },
      update({ overnightStaysForParticipant }) {
        return overnightStaysForParticipant
      },
      skip() {
        return !this.$get(this.transportNote, 'participantUuid')
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transportNotePage.${key}`, params)
    },
    backToList() {
      this.$router.push({
        name: 'TransportNoteList',
        params: {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      })
    },
    submit() {
      if (this.isFromBiogenStaff) {
        this.showValidateBiogenStaffModal = true
        return
      }

      this.showSendModal = true
    },
    cleanNote(transportNote) {
      if (Array.isArray(transportNote.files)) {
        transportNote.files = transportNote.files.map(file => this.formatBackendFile(file))
      }

      if (Array.isArray(transportNote.travels) && transportNote.travels.length > 0) {
        for (const travel of transportNote.travels) {
          delete travel.__typename
        }
      }

      delete transportNote.__typename

      return transportNote
    },
    async save(redirect) {
      if (this.loading.save && redirect) return

      if (redirect) {
        this.loading.save = true
      }

      const {
        data: { updateTransportNote }
      } = await this.$apollo.mutate({
        mutation: UPDATE_TRANSPORT_NOTE,
        variables: {
          transportNoteEntry: this.backendTransportNote,
          notify: this.hasDiff()
        }
      })

      if (redirect) {
        this.loading.save = false
      }

      this.transportNote = this.cleanNote(updateTransportNote)
      this.initial = JSON.parse(JSON.stringify(this.transportNote))

      return redirect ? this.$nextTick(() => this.backToList()) : null
    },
    async cancelNote() {
      if (this.loading.cancel) return
      this.loading.cancel = true

      const {
        data: { refuseTransportNote }
      } = await this.$apollo.mutate({
        mutation: CANCEL_TRANSPORT_NOTE,
        variables: {
          transportNoteUuid: this.$get(this.$route, 'params.transportNoteUuid')
        }
      })

      this.loading.cancel = false

      this.transportNote = this.cleanNote(refuseTransportNote)
      this.initial = JSON.parse(JSON.stringify(this.transportNote))

      return this.$nextTick(() => this.backToList())
    },
    async sendNote() {
      if (this.loading.send) return
      this.loading.send = true

      const { PENDING } = this.$const.transport.noteStatus

      this.showSendModal = false
      // Save any update
      await this.save(false)

      const { data } = await this.$apollo.mutate({
        mutation: this.transportNote.status === PENDING ? SEND_TRANSPORT_NOTE_PROPOSITION : ACCEPT_TRANSPORT_NOTE,
        variables: {
          transportNoteUuid: this.transportNote.transportNoteUuid
        }
      })
      const transportNote = Object.values(data).pop()
      this.loading.send = false

      this.transportNote = this.cleanNote(transportNote)
      this.initial = JSON.parse(JSON.stringify(this.transportNote))

      return this.$nextTick(() => this.backToList())
    },
    async confirmBiogenStaffNote() {
      if (this.loading.save || !this.isFromBiogenStaff) return
      this.showValidateBiogenStaffModal = false
      this.loading.save = true

      try {
        await this.save(false)

        const {
          data: { validateBiogenStaffTransportNote }
        } = await this.$apollo.mutate({
          mutation: VALIDATE_BIOGEN_STAFF_TRANSPORT_NOTE,
          variables: {
            participantUuid: this.transportNote.participantUuid,
            transportNoteUuid: this.transportNote.transportNoteUuid
          }
        })

        this.transportNote = this.cleanNote(validateBiogenStaffTransportNote)
        this.initial = JSON.parse(JSON.stringify(this.transportNote))

        this.$nextTick(() => this.backToList())
      } catch (error) {
        console.error(error)
      } finally {
        this.loading.save = false
      }
    },
    edit() {
      return this.$router.push({
        name: 'TransportNoteForm',
        params: {
          gatheringUuid: this.$route.params.gatheringUuid,
          transportNoteUuid: this.transportNote.transportNoteUuid
        }
      })
    },
    hasDiff() {
      return (
        !isEqual(this.initial.travels, this.transportNote.travels) ||
        !isEqual(this.initial.files, this.transportNote.files)
      )
    },
    preSave() {
      const { PENDING } = this.$const.transport.noteStatus

      const isPending = this.$get(this.transportNote, 'status', null) === PENDING

      if (this.hasDiff() && !isPending) {
        this.showConfirmSaveModal = true
      } else {
        this.save(true)
      }
    },
    closeConfirmSaveModal() {
      this.showConfirmSaveModal = false
      this.isConfirmedInSaveModal = false
    },
    confirmSaveModal() {
      this.save(true)
      this.closeConfirmSaveModal()
    }
  }
}
</script>

<style scoped lang="scss">
.ContentContainer {
  background-color: white;
  border-radius: 8px;
}

.text-right {
  text-align: right;
}

.HostingInformations {
  .HostingInformations-listElement {
    list-style: none;
    text-align: right;
    margin: 10px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #e0e1e3;

    &:last-child {
      border-bottom: none;
    }

    .HostingInformations-listElement-header {
      float: left;
    }
  }
}
</style>
