var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.queries.virtualSession.loading)?_c('v-container',{staticClass:"text-center",attrs:{"height":"100%","fluid":"","full-width":""}},[_c('v-progress-circular',{attrs:{"color":"white","size":"64","indeterminate":""}})],1):_c('div',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.gathering.participant.container),expression:"$cy.gathering.participant.container"}],staticClass:"AttendeeList mt-5 pb-8"},[_c('v-row',{staticClass:"AttendeeList-header mb-3",attrs:{"no-gutters":"","align":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('mybb-text-field',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.gathering.participant.search),expression:"$cy.gathering.participant.search"}],attrs:{"background-color":"white","placeholder":_vm.t('searchPlaceholder'),"icon":"mdi-magnify"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-spacer'),_c('mybb-btn',{on:{"click":_vm.addParticipant}},[_vm._v(" "+_vm._s(_vm.t('addParticipant'))+" ")])],1),_c('v-data-table',{staticClass:"DataTable px-6 py-5",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.$apollo.queries.participants.loading,"search":_vm.searchText,"show-select":!_vm.isWindedUp && !_vm.isOseus,"options":_vm.options,"server-items-length":_vm.$get(_vm.participants, 'total', -1),"footer-props":{ itemsPerPageOptions: [50, 100, 200] },"item-key":"virtualSessionParticipantUuid"},on:{"update:options":function($event){_vm.options=$event},"toggle-select-all":_vm.selectAllItems},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('mybb-text',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.gathering.participant.field(item.lastName.toUpperCase(), _vm.capitalize(item.firstName), header.value)),expression:"$cy.gathering.participant.field(item.lastName.toUpperCase(), capitalize(item.firstName), header.value)"}],key:header.value,class:{ 'text-uppercase': header.value === 'lastName' },attrs:{"weight":['lastName', 'firstName'].includes(header.value) ? 'bold' : undefined}},[_vm._v(" "+_vm._s(header.value === 'firstName' ? _vm.capitalize(value) : value)+" ")])]}}}),{key:"item.subscription",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":_vm.subscriptionColor(value)}},[_vm._v(" "+_vm._s(_vm.subscriptionIcon(value))+" ")]):_vm._e()]}},{key:"item.presence",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":_vm.presenceColor(value)}},[_vm._v(" "+_vm._s(_vm.presenceIcon(value))+" ")]):_vm._e()]}},{key:"item.navigation",fn:function(ref){
var item = ref.item;
return [(item.healthCareProfessionalUuid)?_c('v-icon',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.gathering.participant.participantPageButton(item.lastName.toUpperCase(), item.firstName)),expression:"$cy.gathering.participant.participantPageButton(item.lastName.toUpperCase(), item.firstName)"}],attrs:{"color":"mybb-grey-lighten1"}},[_vm._v(" mdi-eye ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedParticipants),callback:function ($$v) {_vm.selectedParticipants=$$v},expression:"selectedParticipants"}}),_c('virtual-session-participant-footer',{attrs:{"selected":_vm.selectedParticipants,"virtual-session":_vm.virtualSession},on:{"refresh":function($event){return _vm.$apollo.queries.participants.refetch()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }