<template>
  <modal v-model="modal" :title="title">
    <mybb-radios v-model="displayToHcp" :label="t('displayToHcp')" :items="radioItems" />
    <mybb-text v-if="displayToHcp" class="mybb-warning--text my-3 mr-2">
      <v-icon color="mybb-warning" size="20">mdi-alert</v-icon>
      {{ t('warning') }}
    </mybb-text>

    <v-row>
      <v-col cols="6">
        <mybb-text-field v-model="label" :label="t('label')" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <input-file v-model="file" :title="t('file')" accept=".png,.jpeg,.jpg,.pdf" />
      </v-col>
    </v-row>

    <div class="text-center mt-10">
      <mybb-btn class="mr-10" color="mybb-grey" @click="modal = false">{{ t('cancel') }}</mybb-btn>
      <mybb-btn color="mybb-success" inner-icon="mdi-check" @click="submit" :disabled="disabled">{{
        t('confirm')
      }}</mybb-btn>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/mybb/Modal'
import InputFile from '@/components/mybb/InputFile'

export default {
  name: 'AddDocumentModal',
  components: { Modal, InputFile },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean
    },
    fileGathering: {
      type: Object,
      validator(prop) {
        return !prop || (prop.hasOwnProperty('file') && prop.hasOwnProperty('label'))
      }
    }
  },
  data() {
    return {
      document: {
        displayToHcp: null,
        file: null,
        label: null,
        categories: null
      }
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.$nextTick(() => {
            this.document = {
              file: null,
              label: null,
              displayToHcp: null,
              categories: null
            }
          })
        }
      }
    },
    title() {
      return this.t(`title.${this.fileGathering ? 'edit' : 'create'}`)
    },
    radioItems() {
      return [{ label: this.t('yes'), value: true }, { label: this.t('no'), value: false }]
    },
    displayToHcp: {
      get() {
        return this.document.displayToHcp
      },
      set(displayToHcp) {
        this.document.displayToHcp = displayToHcp
      }
    },
    label: {
      get() {
        return this.document.label
      },
      set(label) {
        this.document.label = label
      }
    },
    file: {
      get() {
        return this.document.file
      },
      set(file) {
        this.document.file = file
      }
    },
    disabled() {
      return typeof this.displayToHcp !== 'boolean' || !this.label || !this.file
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.documentStep.documentModal.${key}`, params)
    },
    submit() {
      const payload = {
        fileGatheringUuid: this.$get(this.fileGathering, 'fileGatheringUuid', null),
        displayToHcp: this.displayToHcp,
        label: this.label,
        file: this.file,
        categories: null,
        type: this.$const.gatheringFileType.OTHER
      }

      if (!payload.fileGatheringUuid) {
        delete payload.fileGatheringUuid
      }

      this.$emit('input', payload)
      this.modal = false
    }
  },
  watch: {
    'fileGathering.file'(val, old) {
      if (!old && val) {
        this.document.file = val
      }
    },
    'fileGathering.displayToHcp'(val, old) {
      if (typeof old !== 'boolean' && typeof val === 'boolean') {
        this.document.displayToHcp = val
      }
    },
    'fileGathering.label'(val, old) {
      if (!old && val) {
        this.document.label = val
      }
    }
  }
}
</script>
