<template>
  <v-expansion-panels class="SidePanel" :value="open || onlyOpen ? 0 : null" :readonly="onlyOpen">
    <v-expansion-panel class="white expansion-panel py-2 px-4">
      <v-expansion-panel-header v-cy="`${cypress}/header`" :hide-actions="onlyOpen" class="pa-0 ma-0">
        <div v-cy="cypress" class="d-flex align-center pr-2">
          <v-icon v-if="icon" :color="iconColor" size="18" class="ml-2">{{ icon }}</v-icon>
          <h3 class="Title d-flex flex-grow-1 text-uppercase mybb-primary-sidebar--text">
            {{ title }}
          </h3>

          <div v-if="$slots.headers" class="pr-2">
            <slot name="headers" />
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="ma-0 px-2 SidePanelContent">
        <v-divider class="divider" />
        <slot />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'SidePanel',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String
    },
    iconColor: {
      type: String,
      default: 'mybb-primary-lighten1'
    },
    onlyOpen: {
      type: Boolean
    },
    cypress: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.SidePanel .divider {
  border-color: var(--v-mybb-grey-border-base) !important;
  margin-bottom: 16px;
}

.Title {
  text-indent: 8px;
}
</style>
<style lang="scss">
// Should not be scoped to the file due to vuetify override
// Selector defined should not affect any other definition of it

.SidePanel {
  // Override
  .SidePanelContent {
    .v-expansion-panel-content__wrap {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  }

  .expansion-panel {
    border-radius: 8px !important;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
