<template>
  <status-information-tile
    :title="t('title')"
    :informations="informations"
    :color="color"
    :date="$get(informationStatus, 'date')"
  >
    <mybb-text :class="`${color}--text`" size="16" weight="bold">
      {{ status }}
    </mybb-text>
  </status-information-tile>
</template>

<script>
import StatusInformationTile from '../../ui/StatusInformationTile'

export default {
  name: 'HostingTile',
  components: { StatusInformationTile },
  props: {
    participant: Object,
    informationStatus: Object
  },
  computed: {
    hostingRequest() {
      return this.$get(this.participant, 'hostingParticipantRequests', [])[0]
    },
    overnightStays() {
      return this.$get(this.participant, 'overnightStays', [])
    },
    isPending() {
      return this.$get(this.participant, 'overnightStays', []).some(
        stay => stay.status === this.$const.hosting.overnightStayStatus.PENDING
      )
    },
    isAssigned() {
      return (
        this.hostingRequest ||
        this.$get(this.participant, 'overnightStays', []).some(
          stay => stay.status === this.$const.hosting.overnightStayStatus.ACCEPTED
        )
      )
    },
    hosting() {
      if (this.hostingRequest) return this.hostingRequest.hosting
      if (this.overnightStays.length) return this.$get(this.overnightStays, [0, 'hostingNight', 'hosting'])

      return null
    },
    status() {
      if (this.isAssigned) return this.t('status.assigned')
      if (this.isPending) return this.t('status.pending')

      return '- -'
    },
    informations() {
      return this.hosting ? this.t('informations', this.hosting) : null
    },
    color() {
      if (this.isAssigned) return 'mybb-primary-lighten1'
      if (this.isPending) return 'mybb-warning'

      return 'mybb-grey-lighten1'
    },
    statusLabel() {
      return this.status ? this.t(`status.${this.status}`) : null
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.synthesisPanel.hosting.${key}`, params)
    }
  }
}
</script>
