<template>
  <side-panel v-if="isCongress || isMain" :title="title" :icon="isMain ? 'mdi-star' : null" :only-open="onlyOpen">
    <div v-if="isMain">
      <mybb-text class="mt-5" weight="bold">{{ t('projectTeam.title') }}</mybb-text>

      <v-row>
        <v-col v-for="field of teamFields" :key="field.key" :cols="teamColSize">
          <div class="mb-1">
            <mybb-text size="12" class="mybb-grey-lighten1--text">{{ field.label }}</mybb-text>
          </div>
          <div>
            <mybb-text v-bind="sensibleUserProps(field.userType, field.isBackup)">
              {{ field.user | displayUser }}
            </mybb-text>
          </div>
        </v-col>
      </v-row>
    </div>

    <div v-if="isCongress">
      <v-row v-if="isMain" justify="center">
        <v-col cols="6">
          <v-divider />
        </v-col>
      </v-row>

      <mybb-text :class="{ 'mt-5': !isMain }" weight="bold">{{ t('congressConfig.title') }}</mybb-text>
      <v-row>
        <!-- Medic quota -->
        <v-col cols="3">
          <div class="mb-1">
            <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('congressConfig.medicQuota') }}</mybb-text>
          </div>
          <div>
            <mybb-text v-bind="sensibleProps('doctorQuota')">
              {{ $get(gatheringBusinessUnit, 'doctorQuota', '- -') }}
            </mybb-text>
          </div>
        </v-col>

        <!-- Validation type -->
        <v-col cols="3">
          <div class="mb-1">
            <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('congressConfig.validationType') }}</mybb-text>
          </div>
          <div>
            <mybb-text v-bind="sensibleProps('hasAutomatedValidation')">{{ validationType }}</mybb-text>
          </div>
        </v-col>

        <!-- Other quota -->
        <v-col cols="3">
          <div class="mb-1">
            <mybb-text size="12" class="mybb-grey-lighten1--text">
              {{ t('congressConfig.otherQuota') }}

              <v-tooltip color="mybb-primary-lighten1" max-width="400" top allow-overflow>
                <template v-slot:activator="{ on }">
                  <v-icon small size="20" color="mybb-primary-lighten1" v-on="on">mdi-information</v-icon>
                </template>

                <mybb-text class="white--text">
                  {{ t('congressConfig.otherQuotaInfo') }}
                </mybb-text>
              </v-tooltip>
            </mybb-text>
          </div>
          <div>
            <mybb-text v-bind="sensibleProps('otherDisciplinesQuota')">
              {{ $get(gatheringBusinessUnit, 'otherDisciplinesQuota', '- -') }}
            </mybb-text>
          </div>
        </v-col>

        <!-- Solicitation end date -->
        <v-col cols="3">
          <div class="mb-1">
            <mybb-text size="12" class="mybb-grey-lighten1--text">
              {{ t('congressConfig.solicitationEndDate') }}
            </mybb-text>
          </div>
          <div>
            <mybb-text v-bind="sensibleProps('solicitationEndDate')">
              {{ $get(gatheringBusinessUnit, 'solicitationEndDate') | date }}
            </mybb-text>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <div class="mb-1">
            <mybb-text size="12" class="mybb-grey-lighten1--text">
              {{ t('congressConfig.zoneRepartition.label') }}
            </mybb-text>
          </div>
          <div>
            <mybb-text v-bind="sensibleProps('hasZoneQuota')">{{ zoneRepartited }}</mybb-text>
          </div>
        </v-col>

        <v-col cols="9">
          <div v-for="zone of zones || []" :key="zone.key" class="Column">
            <mybb-text class="d-block">{{ zone.label }}</mybb-text>
            <mybb-text weight="bold" v-bind="sensibleZoneProps(zone.zoneUuid)">{{ zone.quota }}</mybb-text>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <!-- Criterias -->
        <v-col cols="6">
          <div class="mb-1">
            <mybb-text size="12" class="mybb-grey-lighten1--text">
              {{ t('congressConfig.criterias') }}
            </mybb-text>
          </div>
          <div>
            <mybb-text>
              <mybb-text v-for="(criteria, index) of criterias" :key="criteria" v-bind="sensibleCriterias(criteria)">
                {{ criteriaToReadable(criteria) }}<span v-if="index < criterias.length - 1">&nbsp;;&nbsp;</span>
              </mybb-text>
            </mybb-text>
          </div>
        </v-col>

        <!-- MSL Responsible -->
        <v-col cols="6">
          <div class="mb-1">
            <mybb-text size="12" class="mybb-grey-lighten1--text">
              {{ t('congressConfig.mslResponsible') }}
            </mybb-text>
          </div>
          <div>
            <mybb-text v-bind="sensibleProps('mslResponsibleCriteria')">
              {{ mslResponsibleCriteria }}
            </mybb-text>
          </div>
        </v-col>
      </v-row>
    </div>
  </side-panel>
</template>

<script>
import _set from 'lodash/set'
import dateFormat from 'date-fns/format'

import { LIST_USERS } from '@/graphql/User/GetUsers'

import SidePanel from '@/components/mybb/ui/SidePanel'

export default {
  name: 'BusinessUnitTile',
  components: { SidePanel },
  props: {
    gathering: {
      type: Object,
      validator(prop) {
        return !prop || (prop && prop.hasOwnProperty('name'))
      }
    },
    rawGathering: {
      type: Object,
      validator(prop) {
        return !prop || (prop && prop.hasOwnProperty('name'))
      }
    },
    businessUnitUuid: {
      type: String
    },
    gatheringBusinessUnit: {
      type: Object,
      validator(prop) {
        return !prop || (prop && prop.hasOwnProperty('businessUnitUuid'))
      }
    },
    isMain: {
      type: Boolean
    },
    onlyOpen: {
      type: Boolean
    },
    showSensibleChanges: {
      type: Boolean
    },
    canValidateSection: {
      type: Boolean
    }
  },
  computed: {
    title() {
      if (!this.gathering) return this.name

      return this.t(this.isCongress ? 'titleCongress' : 'titleStandalone', { name: this.name })
    },
    isCongress() {
      return Boolean(this.gathering.congress)
    },
    businessUnit() {
      if (!this.gatheringBusinessUnit) return null

      return this.$get(this.$store.state, 'bus', []).find(
        bu => bu.businessUnitUuid === this.gatheringBusinessUnit.businessUnitUuid
      )
    },
    isPendingBusinessUnit() {
      return !this.$get(this.rawGathering, 'gatheringBusinessUnits', []).some(
        gbu => gbu.businessUnitUuid === this.businessUnitUuid
      )
    },
    name() {
      return this.businessUnit ? this.businessUnit.label : null
    },
    teamColSize() {
      if (!this.gathering) return

      return this.gathering.congress ? 3 : 4
    },
    teamFields() {
      if (!this.gathering || !this.listUsers) return []

      const { PROJECT_MANAGER, MEDICAL_DIRECTOR, MARKETING, MEDICAL } = this.$const.gatheringUserType
      const users = this.gathering.users.filter(user => user.businessUnitUuid === this.businessUnitUuid)

      const fields = [
        { userType: PROJECT_MANAGER, key: PROJECT_MANAGER, label: this.t('projectTeam.projectManager') },
        { userType: MARKETING, key: MARKETING, label: this.t('projectTeam.marketing') },
        { userType: MEDICAL, key: MEDICAL, label: this.t('projectTeam.medical') }
      ]

      if (this.isCongress) {
        fields.unshift({
          userType: MEDICAL_DIRECTOR,
          label: this.t('projectTeam.medicalDirector')
        })
      }

      const backups = []
      for (const field of fields) {
        const user = users.find(user => user.gatheringUserType === field.userType && !user.isBackup)
        const backup = users.find(user => user.gatheringUserType === field.userType && user.isBackup)

        field.user = user
        backups.push({
          userType: field.userType,
          isBackup: true,
          key: `${field.userType}-backup`,
          user: backup,
          label: this.t('projectTeam.backup', { role: field.label })
        })
      }

      const allFields = fields.concat(backups)
      // Hydrate users
      for (const field of allFields) {
        if (!Array.isArray(this.listUsers) || !field.user) continue

        const user = this.listUsers.find(user => user.userUuid === field.user.userUuid)
        _set(field, 'user.user', user)
      }

      return allFields
    },
    validationType() {
      if (!this.gatheringBusinessUnit || !this.isCongress) return '- -'

      return this.t(
        this.gatheringBusinessUnit.hasAutomatedValidation
          ? 'congressConfig.automaticValidation'
          : 'congressConfig.manualValidation'
      )
    },
    zoneRepartited() {
      if (!this.gatheringBusinessUnit || !this.isCongress) return '- -'

      return this.t(`congressConfig.zoneRepartition.${this.gatheringBusinessUnit.hasZoneQuota ? 'yes' : 'no'}`)
    },
    zones() {
      if (
        !this.gathering ||
        !this.isCongress ||
        !this.gatheringBusinessUnit ||
        !this.gatheringBusinessUnit.hasZoneQuota
      )
        return null

      const zoneCongresses = this.$get(this.gathering, 'congress.zoneCongresses', [])
        .filter(zc => zc.zone.businessUnitUuid === this.gatheringBusinessUnit.businessUnitUuid)
        .sort((zc1, zc2) => zc1.zone.label.localeCompare(zc2.zone.label))

      return zoneCongresses.map(zc => ({
        key: zc.zoneCongressUuid,
        zoneCongressUuid: zc.zoneCongressUuid,
        zoneUuid: zc.zoneUuid,
        label: zc.zone.label,
        quota: zc.zoneQuota
      }))
    },
    businessUnitCriterias() {
      if (!this.businessUnit) return []

      return this.$const.targetBusinnesUnit[this.businessUnit.label]
    },
    criterias() {
      if (!this.businessUnit) return []

      const availableCriteriasByKey = this.$const.targetBusinessUnitWithLabel[this.businessUnit.label]
      const congressCriterias = this.$get(this.gathering, 'congress.criterias', [])
      const criterias = []

      for (const key in availableCriteriasByKey) {
        if (congressCriterias.includes(key)) {
          criterias.push(key)
        }
      }

      return criterias
    },
    mslResponsibleCriteria() {
      const mslResponsibleCriteria = this.$get(this.gatheringBusinessUnit, 'mslResponsibleCriteria')

      if ([null, undefined].includes(mslResponsibleCriteria)) return '- -'

      return mslResponsibleCriteria
        ? this.t('congressConfig.zoneRepartition.yes')
        : this.t('congressConfig.zoneRepartition.no')
    },
    sensibleChanges() {
      if (!this.gathering || !Array.isArray(this.gathering.gatheringSensibleChanges)) return null

      const sensibleChanges = this.gathering.gatheringSensibleChanges.find(
        gsc => gsc.status === this.$const.gatheringSensibleChangeStatus.PENDING
      )

      return sensibleChanges ? sensibleChanges.changes : null
    },
    hasChanges() {
      const fields = [
        'doctorQuota',
        'hasAutomatedValidation',
        'otherDisciplinesQuota',
        'solicitationEndDate',
        'hasZoneQuota',
        'mslResponsibleCriteria'
      ]

      return (
        fields.some(field => this.sensibleProps(field)) ||
        (Array.isArray(this.teamFields) &&
          this.teamFields.some(user => this.sensibleUserProps(user.userType, user.isBackup))) ||
        (Array.isArray(this.zone) && this.zones.some(zone => this.sensibleZoneProps(zone.zoneUuid)))
      )
    }
  },
  apollo: {
    listUsers: {
      query: LIST_USERS,
      variables() {
        const userUuid = this.gathering.users.map(user => user.userUuid)

        return { userUuid }
      },
      skip() {
        return !this.gathering || !Array.isArray(this.gathering.users)
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.gatheringDetails.businessUnit.${key}`, params)
    },
    sensibleUserProps(gatheringUserType, isBackup = false) {
      if (
        !this.showSensibleChanges ||
        !this.sensibleChanges ||
        !Array.isArray(this.sensibleChanges.users) ||
        !this.gatheringBusinessUnit
      )
        return null

      if (this.isPendingBusinessUnit) return { weight: 'bold', color: 'green' }

      const { businessUnitUuid } = this.gatheringBusinessUnit

      const currentUser = this.sensibleChanges.users.find(
        usr =>
          usr.gatheringUserType === gatheringUserType &&
          usr.isBackup === isBackup &&
          usr.businessUnitUuid === businessUnitUuid
      )
      const rawUser = this.$get(this.rawGathering, 'users', []).find(
        usr =>
          usr.gatheringUserType === gatheringUserType &&
          usr.isBackup === isBackup &&
          usr.businessUnitUuid === businessUnitUuid
      )

      if (!rawUser && currentUser) return { weight: 'bold', color: 'green' }
      if ((!rawUser && !currentUser) || (rawUser && !currentUser)) return null

      return rawUser.userUuid !== currentUser.userUuid ? { weight: 'bold', color: 'green' } : null
    },
    sensibleZoneProps(zoneUuid) {
      if (
        !this.showSensibleChanges ||
        !this.sensibleChanges ||
        !this.sensibleChanges.congress ||
        !Array.isArray(this.sensibleChanges.congress.zoneCongresses)
      )
        return null

      if (this.isPendingBusinessUnit) return { weight: 'bold', color: 'green' }

      const zoneCongressChanges = this.sensibleChanges.congress.zoneCongresses.find(zc => zc.zoneUuid === zoneUuid)
      const rawZoneCongress = this.$get(this.rawGathering, 'congress.zoneCongresses', []).find(
        zc => zc.zoneUuid === zoneUuid
      )

      if (!rawZoneCongress && zoneCongressChanges) return { weight: 'bold', color: 'green' }
      if (!zoneCongressChanges || !rawZoneCongress) return null

      return zoneCongressChanges.zoneQuota !== rawZoneCongress.zoneQuota ? { weight: 'bold', color: 'green' } : null
    },
    sensibleCriterias(criteria) {
      if (this.isPendingBusinessUnit) return { weight: 'bold', color: 'green' }

      const rawCriteriasForBu = this.$get(this.rawGathering, 'congress.criterias', []).filter(crit =>
        this.businessUnitCriterias.includes(crit)
      )
      const currentCriterias = this.$get(this.gathering, 'congress.criterias', []).filter(criteria =>
        this.businessUnitCriterias.includes(criteria)
      )

      return !rawCriteriasForBu.includes(criteria) && currentCriterias.includes(criteria)
        ? { weight: 'bold', color: 'green' }
        : null
    },
    sensibleProps(property) {
      if (
        !this.showSensibleChanges ||
        !this.sensibleChanges ||
        !Array.isArray(this.sensibleChanges.gatheringBusinessUnits) ||
        !this.gatheringBusinessUnit
      )
        return null

      if (this.isPendingBusinessUnit) return { weight: 'bold', color: 'green' }

      const { businessUnitUuid } = this.gatheringBusinessUnit
      const gbuChanges = this.sensibleChanges.gatheringBusinessUnits.find(
        gbu => gbu.businessUnitUuid === businessUnitUuid
      )

      if (!gbuChanges || !this.rawGathering || !Array.isArray(this.rawGathering.gatheringBusinessUnits)) return null

      const rawGatheringBusinessUnit = this.rawGathering.gatheringBusinessUnits.find(
        gbu => gbu.businessUnitUuid === businessUnitUuid
      )

      if (!rawGatheringBusinessUnit) return null

      return gbuChanges[property] !== rawGatheringBusinessUnit[property] ? { weight: 'bold', color: 'green' } : null
    },
    criteriaToReadable(criteria) {
      return this.$get(this.$const.targetBusinessUnitWithLabel, [this.businessUnit.label, criteria], null)
    }
  },
  filters: {
    displayUser(value) {
      if (!value || (value && !value.user)) return '- -'

      return `${value.user.firstName} ${(value.user.lastName || '').toUpperCase()}`
    },
    date(value) {
      if (!value) return '- -'

      return dateFormat(new Date(value), 'dd/MM/yy')
    }
  }
}
</script>

<style lang="scss" scoped>
.Column {
  display: inline-block;
  text-align: center;

  &:not(:last-of-type) {
    padding-right: 16px;
    border-right: 1px solid var(--v-mybb-grey-lighten1);
  }

  &:not(:first-of-type) {
    padding-left: 16px;
  }
}
</style>
