<template>
  <v-row>
    <v-col cols="3" class="py-0">
      <mybb-text weight="bold">{{ commentInformations }}</mybb-text>
    </v-col>

    <v-col v-if="comment.label" cols="2" class="py-0">
      <mybb-text weight="bold">{{ comment.label }}</mybb-text>
    </v-col>

    <v-col :cols="contentCols" class="py-0">
      <div class="mb-1">
        <mybb-text v-if="getAuthor && comment.actionStatus" weight="bold">
          {{ getExpenseActionStatus }}
        </mybb-text>
        <mybb-text v-if="comment.commentHeader" weight="bold">
          {{ comment.commentHeader }}
        </mybb-text>
      </div>

      <mybb-text>{{ comment.text }}</mybb-text>
    </v-col>

    <v-col v-if="comment.file" cols="1" md="1" class="ma-0 pa-0 pr-2 text-center" align-self="center">
      <mybb-btn @click="openFileComment" elevation="3" icon inner-icon="mdi-file-download" color="mybb-grey-lighten1" />
    </v-col>
  </v-row>
</template>

<script>
import dateFormat from 'date-fns/format'

import { file } from '@/mixins'

export default {
  name: 'Comment',
  props: {
    comment: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  mixins: [file],
  computed: {
    commentInformations() {
      const separator = this.formatedDate && this.getAuthor ? ' - ' : !this.formatedDate && !this.getAuthor ? '- -' : ''
      return this.formatedDate + separator + this.getAuthor
    },
    formatedDate() {
      return this.comment.date ? dateFormat(new Date(this.comment.date), 'dd/MM/yy HH:mm') : ''
    },
    getAuthor() {
      return this.comment.user ? this.comment.user.lastName : ''
    },
    getExpenseActionStatus() {
      const oldCategoryID = this.$get(this.comment, 'oldLineValues.expenseNoteCategoryUuid', null)
      const oldDescription = this.$get(this.comment, 'oldLineValues.description', '--')
      const category = this.categories.find(item => item.id === oldCategoryID)

      switch (this.comment.actionStatus) {
        case this.$const.expenseNoteCommentStatus.DIRECT_UPDATE:
        case this.$const.expenseNoteCommentStatus.DIRECT_ADD:
        case this.$const.expenseNoteCommentStatus.DIRECT_DELETION:
        case this.$const.expenseNoteCommentStatus.UPDATED:
          return this.$t(`mybb.expenseNoteDetails.comments.actionStatus.${this.comment.actionStatus}`, {
            category: oldCategoryID && category ? category.label : '--',
            description: oldDescription
          })
        case this.$const.expenseNoteCommentStatus.ARBITRATION:
          return this.$t(`mybb.expenseNoteDetails.comments.actionStatus.${this.comment.actionStatus}`)
        default:
          return this.$t(`mybb.expenseNoteDetails.comments.actionStatus.${this.comment.actionStatus}`)
      }
    },
    contentCols() {
      return this.label && this.file ? 4 : this.label || this.file ? 5 : 6
    }
  },
  methods: {
    async openFileComment() {
      const { file } = this.comment

      await this.openFile(file)
    }
  }
}
</script>

<style scoped>
.font-14 {
  font-size: 14px;
}
</style>
