<template>
  <modal v-model="modal" :title="title">
    <v-row>
      <v-col cols="6">
        <mybb-text-field v-model="label" :label="t('label')" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8" class="pb-0">
        <input-file :file="document" @change="assertLvcFile" :title="t('document')" accept=".docx" hide-delete />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" class="pt-0">
        <mybb-text color="red" size="12">{{ hasFileError ? t('errorAssertFile') : '' }}</mybb-text>
      </v-col>
    </v-row>

    <mybb-radios v-if="isHybrid" v-model="type" :label="t('participationType')" :items="typeOptions" class="my-4" />

    <mybb-text v-if="availableCategories.length">{{ t('categories') }}</mybb-text>
    <v-chip-group v-model="categories" class="mt-2" multiple column dark>
      <v-chip
        v-for="category in availableCategories"
        :key="category.id"
        :value="category.id"
        :color="categories.includes(category.id) ? category.color : 'mybb-text-lighten1'"
        class="white--text"
      >
        {{ category.label }}
      </v-chip>
    </v-chip-group>

    <div class="mt-10 text-center">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">{{ t('cancel') }}</mybb-btn>
      <mybb-btn :disabled="!canSubmit" color="mybb-success" inner-icon="mdi-check" @click="submit">
        {{ t('confirm') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import Categories from 'mybb-categories'

import { ASSERT_LVC_FILE } from '@/graphql/Lvc'

import Modal from '@/components/mybb/Modal'
import InputFile from '@/components/mybb/InputFile'

const TYPES = {
  PHYSICAL: 'PHYSICAL',
  VIRTUAL: 'VIRTUAL'
}

export default {
  name: 'AddLvcModal',
  components: { Modal, InputFile },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean
    },
    lvcGatheringFiles: {
      type: Array,
      default: () => []
    },
    gathering: {
      type: Object
    },
    fileGathering: {
      type: Object,
      validator(prop) {
        return (
          !prop ||
          (prop.hasOwnProperty('file') &&
            prop.hasOwnProperty('label') &&
            prop.hasOwnProperty('categories') &&
            prop.hasOwnProperty('isPhysical') &&
            prop.hasOwnProperty('isVirtual'))
        )
      }
    }
  },
  data() {
    return {
      hasFileError: false,
      gatheringFile: {
        file: null,
        label: null,
        categories: [],
        isPhysical: null,
        isVirtual: null
      }
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        this.$nextTick(() => {
          this.hasFileError = false
          this.gatheringFile = {
            file: null,
            label: null,
            categories: [],
            isPhysical: null,
            isVirtual: null
          }
        })
      }
    },
    gatheringCategories() {
      if (!this.gathering) return []

      const eventType = Categories.getEventTypeFromGathering(this.gathering)

      return Categories.forEventType(eventType, this.gathering.categoryVersion)
    },
    availableCategories() {
      if (this.isHybrid && !this.type) return []

      const otherLvcs = !Array.isArray(this.lvcGatheringFiles)
        ? []
        : this.fileGathering
        ? this.lvcGatheringFiles.filter(gf => gf !== this.fileGathering)
        : this.lvcGatheringFiles

      return this.gatheringCategories.filter(category => {
        if (this.categories.includes(category.id)) return true

        const otherLvcsWithCategory = otherLvcs.filter(
          lvc => Array.isArray(lvc.categories) && lvc.categories.includes(category.id)
        )

        if (otherLvcsWithCategory.length <= 0) return true
        if (!this.isHybrid) return false

        return (
          (this.type === TYPES.PHYSICAL && otherLvcsWithCategory.every(lvc => !lvc.isPhysical)) ||
          (this.type === TYPES.VIRTUAL && otherLvcsWithCategory.every(lvc => !lvc.isVirtual))
        )
      })
    },
    isHybrid() {
      if (!this.gathering) return false

      return this.gathering.isPhysical && this.gathering.isVirtual
    },
    title() {
      return this.t(`title.${this.fileGathering ? 'edit' : 'create'}`)
    },
    typeOptions() {
      return [
        { label: this.t('type.physical'), value: TYPES.PHYSICAL },
        { label: this.t('type.virtual'), value: TYPES.VIRTUAL }
      ]
    },
    canSubmit() {
      return (
        this.label &&
        this.document &&
        !this.hasFileError &&
        (this.isHybrid ? this.type : true) &&
        this.categories.length > 0
      )
    },
    label: {
      get() {
        return this.gatheringFile.label || (this.fileGathering && this.fileGathering.label)
      },
      set(label) {
        this.gatheringFile.label = label
      }
    },
    document: {
      get() {
        return this.gatheringFile.file
      },
      set(file) {
        this.gatheringFile.file = file
      }
    },
    type: {
      get() {
        if (!this.isHybrid) return null

        const isPhysical = this.gatheringFile.isPhysical || (this.fileGathering && this.fileGathering.isPhysical)
        const isVirtual = this.gatheringFile.isVirtual || (this.fileGathering && this.fileGathering.isVirtual)

        return isPhysical ? TYPES.PHYSICAL : isVirtual ? TYPES.VIRTUAL : null
      },
      set(type) {
        if (type === TYPES.PHYSICAL) {
          this.gatheringFile.isPhysical = true
          this.gatheringFile.isVirtual = false
        } else {
          this.gatheringFile.isPhysical = false
          this.gatheringFile.isVirtual = true
        }

        this.$nextTick(() => {
          const availableCategoryIds = this.availableCategories.map(cat => cat.id)
          this.categories = this.categories.filter(category => availableCategoryIds.includes(category.id))
        })
      }
    },
    categories: {
      get() {
        return this.gatheringFile.categories
      },
      set(value) {
        this.gatheringFile.categories = value
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.documentStep.lvcModal.${key}`, params)
    },
    submit() {
      const isPhysical = this.isHybrid ? this.type === TYPES.PHYSICAL : null
      const isVirtual = this.isHybrid ? this.type === TYPES.VIRTUAL : null
      const payload = {
        fileGatheringUuid: this.$get(this.fileGathering, 'fileGatheringUuid', null),
        label: this.label,
        file: this.document,
        isPhysical,
        isVirtual,
        categories: this.categories,
        type: this.$const.gatheringFileType.LVC
      }

      if (!payload.fileGatheringUuid) {
        delete payload.fileGatheringUuid
      }

      this.$emit('input', payload)

      this.modal = false
    },
    async assertLvcFile(file) {
      this.document = file

      try {
        const result = await this.$apollo.query({
          query: ASSERT_LVC_FILE,
          variables: {
            file
          }
        })

        const success = this.$get(result, 'data.assertLvcFile', false)
        this.hasFileError = !success
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    'fileGathering.file'(val, old) {
      if (!old && val) {
        this.gatheringFile.file = val
      }
    },
    'fileGathering.categories'(val, old) {
      const gatheringCategoryIds = this.gatheringCategories.map(category => category.id)

      if (!old && val) {
        this.gatheringFile.categories = val.filter(catId => gatheringCategoryIds.includes(catId))
      }
    }
  }
}
</script>
