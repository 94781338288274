<template>
  <v-card class="mb-5 HcpInformationBloc">
    <v-card-title class="px-5 py-4">
      <mybb-text class="text-uppercase" weight="bold" size="16">
        {{ title }}
      </mybb-text>
      <div class="separator" />
      <v-row>
        <v-col v-for="(field, i) in fields" :key="`${field.label}-${i}`" cols="4">
          <span class="HcpInformationBloc-label">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ field.label }}</mybb-text>
          </span>
          <mybb-text :class="field.whiteSpace ? 'whiteSpace' : ''">{{ field.value }}</mybb-text>
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'HcpInformationBloc',

  props: {
    fields: {
      type: Array,
      required: true,
      validator: val => {
        return val.every(el => el.hasOwnProperty('label') && el.hasOwnProperty('value'))
      }
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.HcpInformationBloc {
  border-radius: 8px !important;
}

.separator {
  height: 1px;
  background: var(--v-mybb-grey-divider-base);
  display: block;
  width: 100%;
  margin: 5px 0;
}

.HcpInformationBloc-label {
  line-height: 12px;
  display: block;
}

.whiteSpace {
  white-space: break-spaces;
}
</style>
