import { congressSchema as congressInformation, biogenEventSchema as biogenEventInformation } from './informations'
import { congressSchema as congressTeam, biogenEventSchema as biogenEventTeam } from './team'
import { congressSchema as congressQuota, biogenEventSchema as biogenEventQuota } from './quota'
import { congressSchema as congressPlace, biogenEventSchema as biogenEventPlace } from './place'
import { congressSchema as congressDocuments, biogenEventSchema as biogenEventDocuments } from './documents'

/**
 * @param {boolean} isCongress
 * @param {boolean} setLazy
 * @returns {SchemaLike}
 */
export function getGatheringSchema(isCongress, setLazy) {
  let schema = isCongress
    ? congressInformation
        .clone()
        .concat(congressTeam)
        .concat(congressQuota)
        .concat(congressPlace)
        .concat(congressDocuments)
    : biogenEventInformation
        .clone()
        .concat(biogenEventTeam)
        .concat(biogenEventQuota)
        .concat(biogenEventPlace)
        .concat(biogenEventDocuments)

  if (setLazy) {
    schema = setSchemaAsLazy(schema)
  }

  return schema
}

function setSchemaAsLazy(baseSchema) {
  const schema = baseSchema.clone()

  if (!schema.fields) {
    return schema.nullable()
  }

  for (const fieldName in schema.fields) {
    const field = schema.fields[fieldName]
    schema.fields[fieldName] = field.nullable()

    switch (field.type) {
      case 'array':
        schema.fields[fieldName].innerType = setSchemaAsLazy(schema.fields[fieldName].innerType)
        break

      case 'object':
        schema.fields[fieldName].fields = setSchemaAsLazy(field).fields
        break
    }
  }

  return schema
}
