<template>
  <div>
    <v-row class="mt-5">
      <v-col cols="12">
        <mybb-text weight="medium">{{ t('lvcLabel') }}</mybb-text>
      </v-col>
    </v-row>
    <!-- LVC Files -->
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="py-1"
        v-for="(gatheringFile, index) of lvcGatheringFiles"
        :key="gatheringFile.fileGatheringUuid"
      >
        <input-file
          :file="gatheringFile.file"
          :title="t('hcpPrefixedFile', { fileName: gatheringFile.label })"
          :disabled="disableAll"
          accept=".docx"
          custom-edit
          @change="onLvcGatheringFileEdit(index)"
          @delete="lvcToDelete = gatheringFile"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <mybb-btn
          @click="addLVCModal = true"
          :disabled="disableAll"
          inner-icon="mdi-plus"
          color="white"
          class="mybb-primary-lighten1--text"
        >
          {{ t('addLvc') }}
        </mybb-btn>
      </v-col>
    </v-row>

    <!-- Other files -->
    <add-document-modal
      v-model="addDocumentModal"
      :gathering="event"
      :file-gathering="otherFileToUpdate"
      :disabled="isWindedUp"
      @input="handleOtherFile"
      class="pl-0"
    />

    <v-row class="mt-4">
      <v-col cols="12">
        <mybb-text weight="medium">{{ t('otherDocumentsLabel') }}</mybb-text>
      </v-col>
      <v-col class="py-1" cols="12" md="6">
        <input-file
          v-model="programFile"
          accept=".jpg,.jpeg,.png,.pdf"
          :title="t('programFile')"
          :disabled="isWindedUp"
        />
      </v-col>

      <v-col v-if="isPhysical" class="py-1" cols="12" md="6">
        <input-file
          v-model="expenseFeeFormFile"
          accept=".jpg,.jpeg,.png,.pdf,.docx"
          :title="t('expenseFeeFormFile')"
          :disabled="isWindedUp"
        />
      </v-col>

      <v-col class="py-1" cols="12" md="6" v-for="(document, index) of otherFiles" :key="document.fileGatheringUuid">
        <input-file
          :file="document.file"
          :title="document.displayToHcp ? t('hcpPrefixedFile', { fileName: document.label }) : document.label"
          :disabled="isWindedUp"
          accept=".png,.jpeg,.jpg,.pdf"
          custom-edit
          @change="onOtherGatheringFileEdit(index)"
          @delete="otherDocumentToDelete = document"
        />
      </v-col>
    </v-row>

    <mybb-btn
      @click="addDocumentModal = true"
      :disabled="!canAddOtherFile || isWindedUp"
      inner-icon="mdi-plus"
      color="white"
      class="mybb-primary-lighten1--text mt-4"
    >
      {{ t('addFile') }}
    </mybb-btn>

    <!-- Delete LVC modal -->
    <modal v-model="deleteLvcModal" :title="t('deleteLvc.title')">
      <div class="text-center">
        <mybb-text class="d-block mb-3">{{ t('deleteLvc.text') }}</mybb-text>
        <mybb-text weight="bold" class="d-block mb-3">{{ t('deleteLvc.highlight') }}</mybb-text>
        <mybb-text
          v-if="$get(this.event, 'status') === this.$const.gatheringStatus.PUBLISHED_FRONT"
          weight="bold"
          class="mybb-warning--text d-block"
        >
          <v-icon size="22" color="mybb-warning">mdi-alert</v-icon>
          {{ t('deleteLvc.warning') }}
        </mybb-text>

        <div class="mt-10">
          <mybb-btn class="mr-10" color="mybb-grey" @click="lvcToDelete = null">
            {{ t('deleteLvc.cancel') }}
          </mybb-btn>

          <mybb-btn color="mybb-error" @click="onLvcDelete(lvcToDelete)">
            {{ t('deleteLvc.confirm') }}
          </mybb-btn>
        </div>
      </div>
    </modal>

    <!-- Delete Other modal -->
    <modal v-model="deleteOtherDocumentModal" :title="t('deleteDocument.title')">
      <div class="text-center">
        <mybb-text class="d-block mb-3">{{ t('deleteDocument.text') }}</mybb-text>
        <mybb-text weight="bold" class="d-block mb-3">{{ t('deleteDocument.highlight') }}</mybb-text>
        <mybb-text
          weight="bold"
          class="mybb-warning--text d-block"
          v-if="
            $get(otherDocumentToDelete, 'displayToHcp') &&
              $get(this.event, 'status') === this.$const.gatheringStatus.PUBLISHED_FRONT
          "
        >
          <v-icon size="22" color="mybb-warning">mdi-alert</v-icon>
          {{ t('deleteDocument.warning') }}
        </mybb-text>

        <div class="mt-10">
          <mybb-btn class="mr-10" color="mybb-grey" @click="otherDocumentToDelete = null">
            {{ t('deleteDocument.cancel') }}
          </mybb-btn>

          <mybb-btn color="mybb-error" @click="onOtherFileDelete(otherDocumentToDelete, true)">
            {{ t('deleteDocument.confirm') }}
          </mybb-btn>
        </div>
      </div>
    </modal>

    <add-lvc-modal
      v-model="addLVCModal"
      :lvc-gathering-files="lvcGatheringFiles"
      :gathering="event"
      :file-gathering="lvcToUpdate"
      :disabled="disableAll"
      @input="handleLvc"
    />
  </div>
</template>

<script>
import { congressSchema, biogenEventSchema } from '@/validations/gathering/documents'

import { yup } from '@/mixins'

import Modal from '@/components/mybb/Modal'
import InputFile from '@/components/mybb/InputFile'

import AddDocumentModal from './document/AddDocumentModal'
import AddLvcModal from './document/AddLvcModal'

export default {
  name: 'DocumentStep',
  components: { AddLvcModal, AddDocumentModal, InputFile, Modal },
  mixins: [yup],
  model: {
    prop: 'event',
    event: 'change'
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    isCongress: {
      type: Boolean
    },
    disableAll: {
      type: Boolean
    }
  },
  data() {
    return {
      addLVCModal: false,
      addDocumentModal: false,
      lvcIndexToUpdate: null,
      otherIndexToUpdate: null,
      otherDocumentToDelete: null,
      lvcToDelete: null
    }
  },
  computed: {
    isWindedUp() {
      return this.$get(this.event, 'status') === this.$const.gatheringStatus.WINDED_UP
    },
    schema() {
      return this.isCongress ? congressSchema : biogenEventSchema
    },
    isPhysical() {
      return this.$get(this.event, 'isPhysical', false)
    },
    lvcGatheringFiles() {
      return this.$get(this.event, 'gatheringFiles', []).filter(gf => gf.type === this.$const.gatheringFileType.LVC)
    },
    otherFiles() {
      return this.$get(this.event, 'gatheringFiles', []).filter(gf => gf.type === this.$const.gatheringFileType.OTHER)
    },
    lvcToUpdate() {
      return this.lvcGatheringFiles[this.lvcIndexToUpdate]
    },
    otherFileToUpdate() {
      return this.otherFiles[this.otherIndexToUpdate]
    },
    canAddOtherFile() {
      if (!Array.isArray(this.otherFiles)) return

      const maxReached = this.otherFiles.length >= 4
      const noEmpty = this.otherFiles.every(entry => entry.label && entry.file && entry.file.content && entry.file.name)

      return !maxReached && noEmpty
    },
    deleteLvcModal: {
      get() {
        return Boolean(this.lvcToDelete)
      },
      set(value) {
        if (!value) {
          this.lvcToDelete = null
        }
      }
    },
    deleteOtherDocumentModal: {
      get() {
        return Boolean(this.otherDocumentToDelete)
      },
      set(value) {
        if (!value) {
          this.otherDocumentToDelete = null
        }
      }
    },
    programFile: {
      get() {
        const program = this.$get(this.event, 'gatheringFiles', []).find(
          gf => gf.type === this.$const.gatheringFileType.PROGRAM
        )

        return program ? program.file : null
      },
      set(file) {
        const files = this.$get(this.event, 'gatheringFiles', []).slice()
        const programIndex = files.findIndex(gf => gf.type === this.$const.gatheringFileType.PROGRAM)

        if (!file) {
          files.splice(programIndex, 1)
        } else if (programIndex >= 0) {
          files[programIndex].file = file
        } else {
          files.push({ file, type: this.$const.gatheringFileType.PROGRAM, label: this.t('programFile') })
        }

        this.$emit('change', { ...this.event, gatheringFiles: files })
      }
    },
    expenseFeeFormFile: {
      get() {
        const expenseFeeForm = this.$get(this.event, 'gatheringFiles', []).find(
          gf => gf.type === this.$const.gatheringFileType.EXPENSE_FEE_FORM
        )

        return expenseFeeForm ? expenseFeeForm.file : null
      },
      set(file) {
        const files = this.$get(this.event, 'gatheringFiles', []).slice()
        const expenseFeeFormIndex = files.findIndex(gf => gf.type === this.$const.gatheringFileType.EXPENSE_FEE_FORM)

        if (!file) {
          files.splice(expenseFeeFormIndex, 1)
        } else if (expenseFeeFormIndex >= 0) {
          files[expenseFeeFormIndex].file = file
        } else {
          files.push({
            file,
            type: this.$const.gatheringFileType.EXPENSE_FEE_FORM,
            label: this.t('expenseFeeFormFile')
          })
        }

        this.$emit('change', { ...this.event, gatheringFiles: files })
      }
    }
  },
  methods: {
    validate() {
      return this.validateSchema(this.event, this.schema)
    },
    t(key, params) {
      return this.$t(`mybb.documentStep.${key}`, params)
    },
    onLvcGatheringFileEdit(index) {
      this.lvcIndexToUpdate = index
      this.addLVCModal = true
    },
    onOtherGatheringFileEdit(index) {
      this.otherIndexToUpdate = index
      this.addDocumentModal = true
    },
    handleLvc(gatheringFile) {
      const files = this.lvcGatheringFiles.slice()

      if (Number.isSafeInteger(this.lvcIndexToUpdate)) {
        files[this.lvcIndexToUpdate] = gatheringFile
      } else {
        files.push(gatheringFile)
      }

      const eventFiles = this.$get(this.event, 'gatheringFiles', []).filter(
        file => file.type !== this.$const.gatheringFileType.LVC
      )

      this.lvcIndexToUpdate = null
      this.$emit('change', { ...this.event, gatheringFiles: [...eventFiles, ...files] })
    },
    handleOtherFile(gatheringFile) {
      const files = this.otherFiles.slice()

      if (Number.isSafeInteger(this.otherIndexToUpdate)) {
        files[this.otherIndexToUpdate] = gatheringFile
      } else {
        files.push(gatheringFile)
      }

      const eventFiles = this.$get(this.event, 'gatheringFiles', []).filter(
        file => file.type !== this.$const.gatheringFileType.OTHER
      )

      this.otherIndexToUpdate = null
      this.$emit('change', { ...this.event, gatheringFiles: [...eventFiles, ...files] })
    },
    onLvcDelete(gatheringFile) {
      const lvcFiles = this.lvcGatheringFiles.filter(file => file !== gatheringFile)
      const eventFiles = this.$get(this.event, 'gatheringFiles', []).filter(
        file => file.type !== this.$const.gatheringFileType.LVC
      )

      this.$emit('change', { ...this.event, gatheringFiles: [...eventFiles, ...lvcFiles] })
      this.lvcToDelete = null
    },
    onOtherFileDelete(gatheringFile, skip) {
      const otherFiles = this.otherFiles.filter(file => file !== gatheringFile)
      const eventFiles = this.$get(this.event, 'gatheringFiles', []).filter(
        file => file.type !== this.$const.gatheringFileType.OTHER
      )

      this.$emit('change', { ...this.event, gatheringFiles: [...eventFiles, ...otherFiles] })
      this.otherDocumentToDelete = null
    }
  },
  watch: {
    addLVCModal(val) {
      if (val) return
      this.lvcIndexToUpdate = null
    },
    addDocumentModal(val) {
      if (val) return
      this.otherIndexToUpdate = null
    }
  }
}
</script>

<style lang="scss" scoped>
.DocumentStepTitle {
  display: block;
}
</style>
