<template>
  <div class="ActivityParticipantCounter pa-0 ml-5">
    <div>
      <v-icon size="24" color="white" class="mr-2">mdi-account-group</v-icon>
      <mybb-text v-cy="$cy.activity.details.counter" size="16" weight="semi-bold" class="white--text">
        {{ label }}
      </mybb-text>
    </div>
  </div>
</template>

<script>
import Categories from 'mybb-categories'

export default {
  name: 'ActivityParticipantCounter',
  props: {
    activity: {
      type: Object
    },
    activityParticipants: {
      type: Array,
      required: false,
      default: () => []
    },
    categoryVersion: {
      type: String,
      required: false
    }
  },
  computed: {
    hasQuota() {
      const quota = this.$get(this.activity, 'quota')

      return Number.isSafeInteger(quota)
    },
    label() {
      return this.hasQuota
        ? this.t('label', { presents: this.presents, total: this.total })
        : this.t('noQuotaLabel', { presents: this.presents })
    },
    categoriesForQuota() {
      if (!this.categoryVersion) return null

      return Categories.forVersion(this.categoryVersion)
        .filter(category => category.tags.includes(Categories.constants.Tag.CountTowardQuota))
        .map(category => category.id)
    },
    activityParticipantsForQuota() {
      return this.activityParticipants.filter(activityParticipant => {
        const participant = this.$get(activityParticipant, 'participant')

        if (!participant) return false

        return Array.isArray(this.categoriesForQuota) && this.categoriesForQuota.includes(participant.category)
      })
    },
    presents() {
      return this.activityParticipants.length
    },
    total() {
      return this.activity.quota
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.activity.detail.participantCounter.${key}`, params)
    }
  }
}
</script>

<style lang="scss" scoped>
.ActivityParticipantCounter {
  display: inline-block;
}
</style>
