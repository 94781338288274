import gql from 'graphql-tag'

export const VSP_LIGHT = gql`
  fragment VirtualSessionParticipantLight on VirtualSessionParticipant {
    firstName
    fromExternalSource
    healthCareProfessionalUuid
    lastName
    presence
    postalCode
    subscription
    type
    virtualSessionParticipantUuid
    virtualSessionUuid
  }
`
