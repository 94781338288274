import gql from 'graphql-tag'

import { FRAGMENT_ALL_SETTINGS_GATHERING } from './GetGathering'

export const SAVE_CONGRESS_EVENT = gql`
  mutation saveCongressEvent($gathering: GatheringUpdateInput!, $congressEvent: CongressUpdateInput!) {
    createOrUpdatePartialCongressEvent(gathering: $gathering, congressEvent: $congressEvent) {
      ...gathering
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING}
`

export const SEND_CONGRESS_TO_VALIDATION = gql`
  mutation sendCongressToValidation($gatheringUuid: GUID!) {
    sendCongressToValidation(gatheringUuid: $gatheringUuid) {
      ...gathering
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING}
`

export const SAVE_BIOGEN_EVENT = gql`
  mutation saveBiogenEvent($gathering: GatheringUpdateInput!, $biogenEvent: BiogenEventUpdateInput!) {
    createOrUpdatePartialBiogenEvent(gathering: $gathering, biogenEvent: $biogenEvent) {
      ...gathering
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING}
`
export const SEND_BIOGEN_EVENT_TO_VALIDATION = gql`
  mutation sendBiogenEventToValidation($gatheringUuid: GUID!) {
    sendBiogenEventToValidation(gatheringUuid: $gatheringUuid) {
      ...gathering
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING}
`

export const CREATE_SENSIBLE_CHANGE_REQUEST = gql`
  mutation registerSensibleChanges($gatheringUuid: GUID!, $changes: JSONObject!) {
    registerSensibleChanges(gatheringUuid: $gatheringUuid, changes: $changes) {
      gatheringSensibleChangeUuid
    }
  }
`
