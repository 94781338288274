import gql from 'graphql-tag'

export const MAIL_FREE_TEXTS = gql`
  query mailFreeTexts($gatheringUuid: GUID!) {
    mailFreeTexts(gatheringUuid: $gatheringUuid) {
      mailFreeTextUuid
      gatheringUuid
      fieldName
      value
    }
  }
`

export const SET_MAIL_FREE_TEXTS = gql`
  mutation setMailFreeTexts($freeTexts: [MailFreeTextInput!]!) {
    setMailFreeTexts(freeTexts: $freeTexts) {
      mailFreeTextUuid
    }
  }
`
