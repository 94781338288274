/**
 * @class CONST
 */
const CONST = {
  bu: {
    Alzheimer: {
      label: 'AD',
      color: '#AFCE79',
      order: 1
    },
    Biosimilaires: {
      label: 'BBU',
      color: '#F75590',
      order: 3
    },
    SEP: {
      label: 'SEP',
      color: '#2573BA',
      order: 2
    },
    SMA: {
      label: 'SMA',
      color: '#624CAB',
      order: 4
    }
  },
  tableSettings: {
    footerProps: {
      itemsPerPageOptions: [5, 10, 15, 50, -1]
    },
    itemsPerPage: 50
  }
}

export default CONST
