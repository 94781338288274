<template>
  <modal v-model="modal" :title="t('title')">
    <template v-slot:text>
      <div class="mb-5">
        <mybb-text>{{ t('text') }}</mybb-text>
      </div>

      <div class="mb-2">
        <mybb-text weight="bold">{{ t('highlight') }}</mybb-text>
      </div>

      <!-- Double validation checkbox -->
      <div class="mt-3">
        <v-checkbox v-model="doubleConfirmation" :label="t('checkboxLabel')" dense />
      </div>

      <v-col class="pa-0 mt-2" cols="6">
        <date-picker v-model="date" :label="t('dateLabel')" />
      </v-col>

      <!-- Buttons -->
      <v-row class="mt-10" justify="center">
        <mybb-btn class="mr-10" color="mybb-primary" @click="closeModal">
          {{ t('cancel') }}
        </mybb-btn>

        <mybb-btn :disabled="!canConfirmPayment" color="mybb-success" :loading="loading" @click="confirmPayment">
          {{ t('confirm') }}
        </mybb-btn>
      </v-row>
    </template>
  </modal>
</template>

<script>
import { CONFIRM_PAYMENT_NOTE } from '@/graphql/ExpenseNote/ExpenseNote'

import Modal from '../Modal'
import DatePicker from '../ui/DatePicker'

export default {
  name: 'ConfirmPaymentModal',
  components: { Modal, DatePicker },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    note: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      date: null,
      doubleConfirmation: false,
      loading: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)

        // Side effect to clear the comment field after the modal is closed
        this.$nextTick(() => {
          if (!value) {
            this.doubleConfirmation = false
            this.date = null
          }
        })
      }
    },
    canConfirmPayment() {
      return this.doubleConfirmation && this.date
    }
  },
  methods: {
    closeModal() {
      this.modal = false
      this.doubleConfirmation = false
    },
    t(key, params) {
      return this.$t(`mybb.expenseNoteDetails.confirmPaymentModal.${key}`, params)
    },
    async confirmPayment() {
      if (this.loading) return
      this.loading = true

      await this.$apollo.mutate({
        mutation: CONFIRM_PAYMENT_NOTE,
        variables: { expenseNoteUuid: this.note.expenseNoteUuid, date: this.date }
      })

      this.$router.push({ name: 'ExpenseNoteList' })
    }
  }
}
</script>
