<template>
  <div class="AddParticipants pb-8">
    <v-row class="ml-0">
      <mybb-btn text color="white" class="px-0 py-0" @click="backToList" inner-icon="mdi-chevron-left">
        {{ t('return') }}
      </mybb-btn>
    </v-row>

    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field
          v-model="search"
          v-cy="$cy.gathering.participant.hcpList.search"
          background-color="white"
          :placeholder="t('searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>
      <span class="BtnWrapper">
        <mybb-btn
          @click="filtersOpen = true"
          color="mybb-grey-lighten1"
          background="white"
          inner-icon="mdi-filter-variant"
          class="ml-2"
          icon
        />
        <div v-if="isFilterSelected" class="AddParticipants-buttonDot" />
      </span>
      <v-row class="ml-2">
        <mybb-btn
          v-if="displayImportButton"
          @click="openModal"
          inner-icon="mdi-file-import"
          color="white"
          background="mybb-primary-lighten1"
          >{{ t('importation-participants.addButton') }}</mybb-btn
        >
      </v-row>
      <v-hover v-slot="{ hover }">
        <mybb-text
          v-if="isFilterSelected"
          weight="bold"
          color="white"
          :class="{ Pointer: true, 'text-decoration-underline': hover, 'ml-2': true }"
          @click="resetFilters"
        >
          {{ t('resetFilters') }}
        </mybb-text>
      </v-hover>
    </v-row>

    <v-container class="Container px-6 py-5" fluid>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search"
        :server-items-length="serverItemsLength"
        :options.sync="options"
        :items-per-page="tableSettings.itemsPerPage"
        :footer-props="footerProps"
        item-key="healthCareProfessionalUuid"
        sort-by="lastName"
        show-select
        @toggle-select-all="selectAllItems"
        class="DataTable"
      >
        <template v-slot:[`header.data-table-select`]="{ on, props }">
          <v-simple-checkbox
            :disabled="!search && !filters.businessUnits.length"
            :ripple="false"
            v-bind="props"
            v-on="on"
          />
        </template>
        <template v-slot:[`item.data-table-select`]="{ item }">
          <v-simple-checkbox
            v-cy="$cy.gathering.participant.hcpList.item(item.lastName.toUpperCase(), item.firstName)"
            :value="selected.includes(item.healthCareProfessionalUuid)"
            @input="selectItem(item)"
            :ripple="false"
          />
        </template>
        <template v-slot:[`item.lastName`]="{ value }">
          <mybb-text weight="bold" class="text-uppercase">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.firstName`]="{ value }">
          <mybb-text weight="bold">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.category`]="{ value }">
          <v-chip :color="categoryColors[value]" dark class="ma-1">
            {{ $t(`mybb.categories.${value}`) }}
          </v-chip>
        </template>
        <template v-slot:[`item.businessUnit`]="{ value }">
          <v-chip v-if="value" :color="buConfig[value.label].color" dark class="ma-1">
            {{ buConfig[value.label].label }}
          </v-chip>
        </template>
      </v-data-table>
    </v-container>

    <fixed-footer :visible="selected.length > 0" :label="t('footer.label', { selected: selected.length })">
      <mybb-btn
        v-cy="$cy.gathering.participant.hcpList.invite.button"
        color="mybb-success"
        @click="invitationModal = true"
        inner-icon="mdi-check"
      >
        {{ t('footer.invite') }}
      </mybb-btn>
    </fixed-footer>

    <hcp-filter-modal v-model="filters" :visible="filtersOpen" @modal="filtersOpen = false" />
    <modal v-model="importModal" :title="t('importation-participants.modal.title')">
      <mybb-text color="mybb-success" size="14" weight="bold" v-if="importSuccess">{{
        t('importation-participants.modal.message.success')
      }}</mybb-text>
      <v-col v-else>
        <v-col align="center" v-if="importLoading">
          <v-progress-circular indeterminate color="mybb-success"></v-progress-circular>
        </v-col>
        <v-col v-else>
          <mybb-text size="16" weight="bold">{{ t('importation-participants.modal.text') }}</mybb-text>
          <mybb-text class="mt-2" color="mybb-warning" weight="bold">{{
            t('importation-participants.modal.textReminder')
          }}</mybb-text>

          <mybb-text class="mt-2" size="14" color="mybb-error" weight="bold" v-if="errorFileType.status">{{
            errorFileType.content
          }}</mybb-text>
          <v-file-input :value="importFile" v-on:change="onChangeFile" accept=".xls,.xlsx" />

          <mybb-btn color="mybb-success" @click="submitImport" :disabled="!displayBtn">{{
            t('importation-participants.modal.confirmButton')
          }}</mybb-btn>
          <mybb-btn class="ml-4" color="mybb-error" v-if="!importLoading" @click="importModal = false">{{
            t('importation-participants.modal.cancelButton')
          }}</mybb-btn>
        </v-col>
      </v-col>
    </modal>

    <modal v-model="invitationModal" :title="t('invitationModal.title')">
      <mybb-text>
        {{
          t('invitationModal.text', {
            selected: selected.length
          })
        }}
      </mybb-text>

      <div class="d-flex justify-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="invitationModal = false">
          {{ t('invitationModal.cancel') }}
        </mybb-btn>
        <mybb-btn
          v-cy="$cy.gathering.participant.hcpList.invite.submit"
          :loading="inviting"
          color="mybb-success"
          @click="invite"
        >
          {{ t('invitationModal.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import MYBB from '@/const/my-bb'
import axios from 'axios'

import FixedFooter from '@/components/mybb/ui/FixedFooter'
import Modal from '@/components/mybb/Modal'
import HcpFilterModal from '@/components/mybb/hcp/HcpFilterModal'
import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'
import { SEARCH_HCPS_FOR_DATATABLE, SEARCH_HCPS_FOR_SELECTION } from '@/graphql/Hcp/Hcp'
import { INVITE_HEALTH_CARE_PROFESSIONALS } from '@/graphql/Participant'
import { INVITE_HEALTHCARE_PROFESSIONALS_TO_VIRTUAL_SESSION } from '@/graphql/VirtualSession'
import { GET_ALL_BUS } from '@/graphql/BusinessUnit/GetBus'

export default {
  name: 'ParticipantsAdd',
  components: { FixedFooter, Modal, HcpFilterModal },
  data() {
    return {
      search: '',
      filtersOpen: false,
      filters: {
        businessUnits: []
      },
      gathering: {
        status: ''
      },
      selected: [],
      invitationModal: false,
      importModal: false,
      importFile: null,
      importSuccess: false,
      importLoading: false,
      errorFileType: {
        status: false,
        message: ''
      },
      displayBtn: false,
      loading: true,
      inviting: false,
      options: {
        page: 1,
        itemsPerPage: MYBB.tableSettings.itemsPerPage
      },
      serverItemsLength: 0,
      sort: {
        field: null,
        desc: false
      }
    }
  },
  computed: {
    isGathering() {
      return this.$route.params.gatheringUuid && !this.$route.params.virtualSessionUuid
    },
    filterItems() {
      return [
        {
          key: 'businessUnits',
          label: this.t('filters.businessUnits'),
          filters: (this.businessUnits || []).map(bu => ({
            key: bu.businessUnitUuid,
            label: bu.label,
            value: bu.businessUnitUuid
          }))
        }
      ]
    },
    isFilterSelected() {
      return this.filters.businessUnits.length > 0
    },
    headers() {
      return [
        { sortable: true, text: this.t('table.lastName'), value: 'lastName' },
        { sortable: false, text: this.t('table.firstName'), value: 'firstName' },
        { sortable: true, text: this.t('table.postalCode'), value: 'postalCode' },
        { sortable: true, text: this.t('table.businessUnit'), value: 'businessUnit' },
        { sortable: true, text: this.t('table.target'), value: 'target' },
        { sortable: true, text: this.t('table.zone'), value: 'zone.label' }
      ]
    },
    items() {
      return this.$get(this.availableHealthCareProfessionals, 'hcps', []).map(hcp => ({
        healthCareProfessionalUuid: hcp.healthCareProfessionalUuid,
        lastName: hcp.lastName,
        firstName: hcp.firstName,
        postalCode: hcp.postalCode,
        businessUnit: hcp.businessUnit,
        target: this.computeTarget(hcp),
        zone: hcp.zone
      }))
    },
    footerProps() {
      const footerProps = JSON.parse(JSON.stringify(this.tableSettings.footerProps))

      if (this.filters.businessUnits.length <= 0) {
        footerProps.itemsPerPageOptions.pop()
      }

      return footerProps
    },
    tableSettings() {
      return MYBB.tableSettings
    },
    buConfig() {
      return MYBB.bu
    }
  },
  apollo: {
    getGatheringSettings: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      result({ data }) {
        this.loading = false
        this.gathering.status = data.gathering.status
      }
    },
    availableHealthCareProfessionals: {
      query: SEARCH_HCPS_FOR_DATATABLE,
      variables() {
        const vars = {
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          excludeParticipants: true,
          gatheringUuid: this.$route.params.gatheringUuid,
          virtualSessionUuid: this.$route.params.virtualSessionUuid
        }

        if (this.search) {
          vars.search = this.search
        }

        if (this.sort.field) {
          vars.sort = this.sort
        }

        if (this.filters.businessUnits.length) {
          vars.businessUnitFilters = this.filters.businessUnits
        }

        return vars
      },
      skip() {
        return !this.$route.params.gatheringUuid && !this.$route.params.virtualSessionUuid
      },
      update({ searchHcpForHcpTable }) {
        return searchHcpForHcpTable
      },
      result({ data }) {
        this.loading = false
        this.serverItemsLength = this.$get(data, 'searchHcpForHcpTable.total', 0)
      }
    },
    businessUnits: {
      query: GET_ALL_BUS
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.participants.add-list.${key}`, params)
    },
    displayImportButton() {
      switch (this.gathering.status) {
        case this.$const.gatheringStatus.CLOSED:
          return false
        case this.$const.gatheringStatus.WINDED_UP:
          return false
        default:
          return true
      }
    },
    openModal() {
      this.importModal = true
    },
    onChangeFile(file) {
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.importFile = file
        this.displayBtn = true
        this.errorFileType = {
          status: false,
          content: ''
        }
      } else {
        this.displayBtn = false
        this.errorFileType = {
          status: true,
          content: this.t('importation-participants.modal.message.errors.typeFile')
        }
      }
    },
    async submitImport() {
      this.importLoading = true
      const formData = new FormData()
      formData.append('file', this.importFile)
      const { data } = await axios.post(
        `gathering/participation/${this.$route.params.gatheringUuid}/import`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-impersonificated-user': this.$store.state.impersonificatedUser
          }
        }
      )
      if (data && data.success) {
        this.importLoading = false
        this.importSuccess = true
        setTimeout(() => {
          this.importSuccess = false
          this.importModal = false
          this.importFile = null
          this.displayBtn = false
          this.errorFileType = {
            status: false,
            content: ''
          }
        }, 2500)
      } else {
        this.importLoading = false
        this.displayBtn = false
        this.errorFileType = {
          status: true,
          content: this.t('importation-participants.modal.message.errors.fail')
        }
      }
    },
    backToList() {
      return this.$router.push({ name: this.isGathering ? 'AttendeeList' : 'VirtualSessionAttendeeList' })
    },
    computeTarget(hcp) {
      const businessUnit = this.$get(hcp, 'businessUnit', null)

      if (!businessUnit) return null

      const targetBu = this.$const.targetBu[businessUnit.label]

      if (!targetBu) return null

      return `${businessUnit.externalId}_${this.$get(hcp, [targetBu])}`
    },
    async invite() {
      if (this.inviting) return
      this.inviting = true

      const mutation = this.isGathering
        ? this.$apollo.mutate({
            fetchPolicy: 'no-cache',
            mutation: INVITE_HEALTH_CARE_PROFESSIONALS,
            variables: {
              gatheringUuid: this.$route.params.gatheringUuid,
              selected: this.selected
            }
          })
        : this.$apollo.mutate({
            fetchPolicy: 'no-cache',
            mutation: INVITE_HEALTHCARE_PROFESSIONALS_TO_VIRTUAL_SESSION,
            variables: {
              virtualSessionUuid: this.$route.params.virtualSessionUuid,
              healthCareProfessionalUuids: this.selected
            }
          })

      try {
        await mutation

        this.invitationModal = false
        this.$router.push({ name: this.isGathering ? 'AttendeeList' : 'VirtualSessionAttendeeList' })
      } catch (error) {
        console.error(error)
      } finally {
        this.inviting = false
      }
    },
    async selectAllItems({ value }) {
      this.loading = true

      const variables = {
        excludeParticipants: true
      }

      if (this.isGathering) {
        variables.gatheringUuid = this.$route.params.gatheringUuid
      } else {
        variables.virtualSessionUuid = this.$route.params.virtualSessionUuid
      }

      if (this.search) {
        variables.search = this.search
      }

      if (this.filters.businessUnits.length) {
        variables.businessUnitFilters = this.filters.businessUnits
      }

      const {
        data: {
          searchHcpForHcpTable: { hcps }
        }
      } = await this.$apollo.query({
        query: SEARCH_HCPS_FOR_SELECTION,
        variables
      })

      if (value) {
        this.selected.push(...hcps.map(hcp => hcp.healthCareProfessionalUuid))
      } else {
        hcps.forEach(hcp => {
          let index

          while ((index = this.selected.indexOf(hcp.healthCareProfessionalUuid)) !== -1) {
            this.selected.splice(index, 1)
          }
        })
      }

      this.loading = false
    },
    selectItem(item) {
      const { healthCareProfessionalUuid } = item

      if (this.selected.includes(healthCareProfessionalUuid)) {
        let index

        while ((index = this.selected.indexOf(healthCareProfessionalUuid)) !== -1) {
          this.selected.splice(index, 1)
        }
      } else {
        this.selected.push(healthCareProfessionalUuid)
      }
    },
    resetFilters() {
      this.filters.businessUnits = []
    }
  },
  watch: {
    search(value) {
      if (!value) return

      this.options.page = 1
    },
    'filters.businessUnits'(value) {
      if (!value) return

      this.options.page = 1
    },
    options(value) {
      this.loading = true

      if (value.sortBy.length && value.sortDesc.length) {
        this.sort.field = value.sortBy[0]
        this.sort.desc = value.sortDesc[0]
      } else if (value.sortBy.length && !value.sortDesc.length) {
        this.sort.field = value.sortBy[0]
        this.sort.desc = false
      } else {
        this.sort.field = null
        this.sort.desc = false
      }
    }
  }
}
</script>

<style lang="scss">
.Pointer {
  cursor: pointer;
}

.text-decoration-underline {
  text-decoration: underline;
}

.AddParticipants {
  .BtnWrapper {
    position: relative;
  }

  .AddParticipants-buttonDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--v-mybb-success-base);
    position: absolute;
    z-index: 10;
    top: -3.75px;
    right: -3.75px;
  }

  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }

  .Container {
    background-color: white;
    border-radius: 8px;

    .DataTable {
      border-radius: 8px;
    }

    .v-data-table {
      thead th {
        font-size: 12px;
        font-weight: 900;
        color: var(--v-mybb-primary-base) !important;
        text-transform: uppercase;
      }

      tbody td {
        color: var(--v-mybb-primary-base);
      }

      th .v-simple-checkbox .v-icon,
      td .v-simple-checkbox .v-icon {
        color: var(--v-mybb-blue-base);
      }
    }
  }
}
</style>
