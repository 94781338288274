<template>
  <div class="AttendeeList mt-5 pb-8" v-cy="$cy.gathering.participant.container">
    <v-row class="AttendeeList-header mb-3" no-gutters align="start">
      <v-col cols="12" md="4">
        <mybb-text-field
          v-model="searchText"
          v-cy="$cy.gathering.participant.search"
          background-color="white"
          :placeholder="t('searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>

      <v-row no-gutters>
        <mybb-select
          class="selectField ml-2"
          v-model="selectedView"
          icon="mdi-eye"
          :items="tableTypes"
          outlined
          background-color="white"
        >
          <template slot="selection" slot-scope="data">
            <mybb-text>{{ data.item.text }}</mybb-text>
          </template>
          <template slot="item" slot-scope="data">
            <mybb-text>{{ data.item.text }}</mybb-text>
          </template>
        </mybb-select>

        <span class="BtnWrapper">
          <mybb-btn
            @click="filtersOpen = true"
            color="mybb-grey-lighten1"
            background="white"
            inner-icon="mdi-filter-variant"
            class="ml-2"
            icon
          />
          <div v-if="isFilterSelected" class="AttendeeList-buttonDot" />
        </span>

        <mybb-btn
          @click="exportModal = true"
          :disabled="configurationLoading"
          background="white"
          color="mybb-grey-lighten1"
          icon
          inner-icon="mdi-file-export"
          class="ml-2"
          :loading="loadingDownloadExport || configurationLoading"
        />
        <div class="AttendeeList-guest ml-2">
          <v-row no-gutters align="center">
            <v-icon color="white" size="20">mdi-account-group</v-icon>
            <mybb-text class="white--text ml-2" weight="bold" size="16">
              {{ t(isCongress ? 'congressTotalPeople' : 'standaloneTotalPeople', guests) }}
            </mybb-text>
          </v-row>
        </div>
      </v-row>

      <v-spacer />

      <mybb-menu-dropdown
        v-if="gathering"
        v-on="dropDownListeners"
        :cypress="$cy.gathering.participant.addButton"
        :title="t('addGuestDropdown.title')"
        :items="dropDownAddAttendee"
        :disabled="isWindedUpGathering"
      />
    </v-row>

    <v-data-table
      v-model="selectedParticipants"
      :headers="headers"
      :items="items"
      :loading="loading || $apollo.queries.participants.loading"
      :search="searchText"
      :show-select="!isWindedUpGathering"
      :options.sync="options"
      :server-items-length="$get(participants, 'total', -1)"
      :footer-props="{ itemsPerPageOptions: [50, 100, 200] }"
      item-key="participantUuid"
      class="DataTable px-6 py-5"
      :height="tableHeight"
      fixed-header
      @toggle-select-all="selectAllItems"
    >
      <!-- Easily configurable text fields -->
      <template v-for="field in fields" v-slot:[`item.${field}`]="{ value, item }">
        <mybb-text
          :key="field"
          :weight="$get(fieldsConfiguration, [field, 'weight'], 'regular')"
          :class="{ 'text-uppercase': $get(fieldsConfiguration, [field, 'uppercase'], false) }"
          v-cy="$cy.gathering.participant.field(item.lastName.toUpperCase(), item.firstName, field)"
        >
          <!-- Price -->
          <template v-if="$get(fieldsConfiguration, [field, 'price'])">
            {{ value | price }}
          </template>
          <!-- Date -->
          <template v-else-if="$get(fieldsConfiguration, [field, 'date'])">
            {{ value | date }}
          </template>
          <!-- Date and hour -->
          <template v-else-if="$get(fieldsConfiguration, [field, 'dateTime'])">
            {{ value | dateTime }}
          </template>
          <!-- Email -->
          <template v-else-if="$get(fieldsConfiguration, [field, 'email'])">
            <div v-if="value.length" class="d-flex">
              {{ value[0].date | shortDate }}

              <v-tooltip color="mybb-primary-lighten1" max-width="400" top allow-overflow>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" color="mybb-primary-lighten1" small class="ml-1" v-on="on">
                    mdi-information
                  </v-icon>
                </template>

                <mybb-text
                  v-for="record in value"
                  :key="record.participantEmailHistoricUuid"
                  class="d-block white--text"
                >
                  {{ record.date | dateTime }}
                </mybb-text>
              </v-tooltip>
            </div>
            <template v-else> - - </template>
          </template>
          <!-- Icon -->
          <v-icon v-else-if="$get(fieldsConfiguration, [field, 'icon']) && value" :color="value.color">
            {{ value.icon }}
          </v-icon>
          <!-- Chip -->
          <v-chip v-else-if="$get(fieldsConfiguration, [field, 'chip']) && value" :color="value.color" dark>
            {{ value.label }}
          </v-chip>
          <!-- Basic text -->
          <template v-else>
            {{ value }}
          </template>
        </mybb-text>
      </template>

      <template v-slot:[`item.participantComments`]="{ value }">
        <v-tooltip v-if="value" color="mybb-primary-darken1" max-width="400" top allow-overflow>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="mybb-primary-lighten1" v-on="on">mdi-message-text</v-icon>
          </template>

          <mybb-text class="white--text">
            {{ value.comment }}
          </mybb-text>
        </v-tooltip>
      </template>

      <!-- Navigation -->
      <template v-slot:[`item.navigation`]="{ item }">
        <v-icon
          v-if="
            item.healthCareProfessionalUuid ||
              (!item.healthCareProfessionalUuid && item.userUuid) ||
              (!item.healthCareProfessionalUuid && !item.userUuid && item.participantUuid)
          "
          v-cy="$cy.gathering.participant.participantPageButton(item.lastName.toUpperCase(), item.firstName)"
          color="mybb-grey-lighten1"
          :href="redirectionLink(item)"
          @click="goToParticipant(item)"
        >
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>

    <participant-footer
      :selected="selectedParticipants"
      :gathering="gathering"
      :isCongress="isCongress"
      @refresh="$apollo.queries.participants.refetch()"
    />

    <FilterDrawer
      v-if="gathering"
      v-model="filters"
      :open="filtersOpen"
      :items="filterItems"
      :label="t('statusFilterLabel')"
      @close="filtersOpen = false"
    />

    <manual-participant-modal
      v-model="manualAdd"
      :gathering="gathering"
      @saved="$apollo.queries.participants.refetch()"
    />

    <export-participant-modal v-model="exportModal" :filters="filters" />
  </div>
</template>

<script>
import { format, compareAsc, subDays } from 'date-fns'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import Categories from 'mybb-categories'

import FilterDrawer from '@/components/mybb/ui/FilterDrawer'
import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'
import { PARTICIPANTS_FOR_GATHERING__PAGINATED } from '@/graphql/Participant'
import MYBB from '@/const/my-bb'
import ParticipantFooter from '@/components/mybb/participant/ParticipantFooter.vue'
import ManualParticipantModal from '@/components/mybb/participant/ManualParticipantModal.vue'
import ExportParticipantModal from '@/components/mybb/participant/ExportParticipantModal'

const DISPLAYS = {
  global: 'GLOBAL',
  veeva: 'VEEVA',
  participation: 'PARTICIPATION',
  transport: 'TRANSPORT',
  hosting: 'HOSTING',
  email: 'EMAIL'
}

/**
 * @see https://docs.google.com/spreadsheets/d/1xq8ivLRJbX5RhcOOBoAisgOOlgkpsfIa/edit#gid=617773948
 */
const PREPEND_COMMON_HEADERS = ['lastName', 'firstName', 'category']
const APPEND_COMMON_HEADERS = ['navigation']

const HEADERS_KEY_BY_DISPLAY = {
  [DISPLAYS.global]: [
    ...PREPEND_COMMON_HEADERS,
    'target',
    'sollicitation',
    'invitationStatus',
    'hosting',
    'transport',
    'expenseNote',
    'presence',
    'participantComments',
    ...APPEND_COMMON_HEADERS
  ],
  [DISPLAYS.veeva]: [
    ...PREPEND_COMMON_HEADERS,
    'veevaId',
    'rppsNumber',
    'postalCode',
    'type',
    'specialty',
    'email',
    ...APPEND_COMMON_HEADERS
  ],
  [DISPLAYS.participation]: [
    ...PREPEND_COMMON_HEADERS,
    'sollicitation',
    'sollicitationDate',
    'invitationStatus',
    'learnedSocietyMemberId',
    'participationType',
    'subscriptionDate',
    'subscriptionPrice',
    'participantComments',
    'presence',
    ...APPEND_COMMON_HEADERS
  ],
  [DISPLAYS.transport]: [
    ...PREPEND_COMMON_HEADERS,
    'transport',
    'transportMode',
    'transportGo',
    'transportBack',
    'transfert',
    ...APPEND_COMMON_HEADERS
  ],
  [DISPLAYS.hosting]: [
    ...PREPEND_COMMON_HEADERS,
    'hosting',
    'hotel',
    'checkIn',
    'checkOut',
    'nightsCount',
    ...APPEND_COMMON_HEADERS
  ],
  [DISPLAYS.email]: [
    ...PREPEND_COMMON_HEADERS,
    'emailSubscription',
    'emailRegistration',
    'emailTransport',
    'emailTransportReminder',
    'emailRecap',
    'emailBeginSoon',
    // 'emailRegistrationReminder',
    'emailPresenceAttestation',
    'emailPayedExpenseNote',
    ...APPEND_COMMON_HEADERS
  ]
}

const FIELDS = {
  lastName: { weight: 'bold', uppercase: true },
  firstName: { weight: 'bold' },
  category: { chip: true },
  target: {},
  sollicitation: {},
  sollicitationDate: { date: true },
  invitationStatus: { icon: true },
  participationType: {},
  subscriptionDate: { date: true },
  subscriptionPrice: { price: true },
  learnedSocietyMemberId: {},
  // participantComments, // Special case - hover icon
  participantComments: {},
  hosting: { icon: true },
  hotel: {},
  checkIn: { date: true },
  checkOut: { date: true },
  nightsCount: {},
  transport: { chip: true },
  transportMode: {},
  transportGo: { dateTime: true },
  transportBack: { dateTime: true },
  // transfert, // Special case - icon @todo
  expenseNote: { chip: true }, // Special case - chip
  presence: { icon: true },
  emailSubscription: { email: true },
  emailRegistration: { email: true },
  emailTransport: { email: true },
  emailTransportReminder: { email: true },
  emailRecap: { email: true },
  emailBeginSoon: { email: true },
  emailRegistrationReminder: { email: true },
  emailPresenceAttestation: { email: true },
  emailPayedExpenseNote: { email: true }
}

export default {
  name: 'AttendeeList',
  components: { FilterDrawer, ParticipantFooter, ManualParticipantModal, ExportParticipantModal },
  data() {
    return {
      tableHeight: 0,
      loading: true,
      configurationLoading: false,
      loadingDownloadExport: false,
      searchText: '',
      isHybridEvent: false,
      filters: {
        solicitation: [],
        invitation: [],
        presence: []
      },
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['lastName'],
        sortDesc: [true]
      },
      filtersOpen: false,
      selectedParticipants: [],
      selectedView: DISPLAYS.global,
      manualAdd: false,
      exportModal: false,
      sortMapping: {
        category: 'category.label',
        invitationStatus: 'invitationStatus.invitationStatus',
        hosting: 'hosting.status',
        transport: 'transport.status',
        expenseNote: 'expenseNote.status',
        presence: 'presence.presence'
      },
      filterFields: {
        lastName: 'lastName',
        firstName: 'firstName',
        category: 'category.label'
      }
    }
  },
  mounted() {
    /**
     * tricky but tableHeight is calculate in function of client Screen less header & footer
     */
    this.tableHeight = window.innerHeight - 400
    window.addEventListener('resize', () => {
      this.tableHeight = window.innerHeight - 400
    })
  },
  computed: {
    categoriesById() {
      if (!this.gathering) return null

      return Categories.forVersion(this.gathering.categoryVersion).reduce((acc, category) => {
        acc[category.id] = category

        return acc
      }, {})
    },
    guests() {
      return {
        counter: this.$get(this.participants, 'counter', 0),
        total: this.$get(this.participants, 'counterTotal', 0)
      }
    },
    dropDownAddAttendee() {
      const { ADMIN, SUPER_ADMIN, ROC } = this.$const.userType
      const { userTypes } = this.$store.state.myUser

      const items = [
        { title: this.t('addGuestDropdown.biogenStaff'), event: 'biogenStaff' },
        null,
        { title: this.t('addGuestDropdown.manualAdd'), event: 'manualAdd' }
      ]

      if (
        this.gathering.gatheringType !== this.$const.gatheringType.CONGRESS &&
        (userTypes.includes(ADMIN) || userTypes.includes(SUPER_ADMIN) || userTypes.includes(ROC))
      ) {
        items.unshift({ title: this.t('addGuestDropdown.listeHCP'), event: 'listHCP' })
      }

      return items
    },
    dropDownListeners() {
      return {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        biogenStaff: () => this.$router.push({ name: 'ParticipantStaffAdd' }),
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        manualAdd: () => (this.manualAdd = true),
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        listHCP: () => this.$router.push({ name: 'ParticipantsAdd' })
      }
    },
    /**
     * @returns {Object[]}
     * @see https://docs.google.com/spreadsheets/d/1xq8ivLRJbX5RhcOOBoAisgOOlgkpsfIa/edit#gid=1249451089
     */
    allHeaders() {
      return [
        { sortable: true, text: this.t('table.lastName'), value: 'lastName' },
        { sortable: false, text: this.t('table.firstName'), value: 'firstName' },
        { sortable: false, text: this.t('table.mdmId'), value: 'veevaId' },
        { sortable: false, text: this.t('table.rpps'), value: 'rppsNumber' },
        { sortable: true, text: this.t('table.email'), value: 'email' },
        { sortable: true, text: this.t('table.category'), value: 'category' },
        { sortable: false, text: this.t('table.postalCode'), value: 'postalCode' },
        { sortable: false, text: this.t('table.type'), value: 'type' },
        { sortable: false, text: this.t('table.title'), value: 'title' },
        { sortable: false, text: this.t('table.specialty'), value: 'specialty' },
        { sortable: true, text: this.t('table.target'), value: 'target' },
        { sortable: true, text: this.t('table.sollicitation'), value: 'sollicitation' },
        { sortable: true, text: this.t('table.sollicitationDate'), value: 'sollicitationDate' },
        {
          sortable: true,
          text: this.t('table.invitationStatus'),
          value: 'invitationStatus'
        },
        { sortable: false, text: this.t('table.learnedSocietyMemberId'), value: 'learnedSocietyMemberId' },
        { sortable: true, text: this.t('table.participationType'), value: 'participationType' },
        { sortable: false, text: this.t('table.invitationDate'), value: 'subscriptionDate' },
        { sortable: false, text: this.t('table.price'), value: 'subscriptionPrice' },
        {
          sortable: true,
          text: this.t('table.hosting'),
          value: 'hosting'
        },
        { sortable: true, text: this.t('table.hotel'), value: 'hotel' },
        { sortable: true, text: this.t('table.checkIn'), value: 'checkIn' },
        { sortable: true, text: this.t('table.checkOut'), value: 'checkOut' },
        { sortable: false, text: this.t('table.nightsCount'), value: 'nightsCount' },
        { sortable: true, text: this.t('table.transport'), value: 'transport' },
        { sortable: false, text: this.t('table.transportMode'), value: 'transportMode' },
        { sortable: true, text: this.t('table.transportGo'), value: 'transportGo' },
        { sortable: true, text: this.t('table.transportBack'), value: 'transportBack' },
        { sortable: true, text: this.t('table.transfert'), value: 'transfert' },
        { sortable: true, text: this.t('table.expenseNote'), value: 'expenseNote' },
        {
          sortable: true,
          text: this.t('table.presence'),
          value: 'presence'
        },
        {
          sortable: true,
          text: this.t('table.subscriptionComments'),
          value: 'participantComments'
        },
        { sortable: true, text: this.t('table.emailSubscription'), value: 'emailSubscription', width: 90 },
        { sortable: true, text: this.t('table.emailRegistration'), value: 'emailRegistration', width: 90 },
        { sortable: true, text: this.t('table.emailTransport'), value: 'emailTransport', width: 90 },
        { sortable: true, text: this.t('table.emailTransportReminder'), value: 'emailTransportReminder', width: 90 },
        { sortable: true, text: this.t('table.emailRecap'), value: 'emailRecap', width: 90 },
        { sortable: true, text: this.t('table.emailBeginSoon'), value: 'emailBeginSoon', width: 90 },
        // {
        //   sortable: true,
        //   text: this.t('table.emailRegistrationReminder'),
        //   value: 'emailRegistrationReminder',
        //   width: 90
        // },
        {
          sortable: true,
          text: this.t('table.emailPresenceAttestation'),
          value: 'emailPresenceAttestation',
          width: 90
        },
        {
          sortable: true,
          text: this.t('table.emailPayedExpenseNote'),
          value: 'emailPayedExpenseNote',
          width: 90
        },
        { sortable: false, text: '', value: 'navigation' }
      ].filter(header => {
        switch (header.value) {
          case 'hosting':
          case 'transport':
          case 'expenseNote':
            return !this.isVirtual
          case 'sollicitation':
            return this.isCongress
          case 'sollicitationDate':
            return this.isCongress
          case 'learnedSocietyMemberId':
            return this.isCongress
          case 'participationType':
            return this.isHybrid
          case 'emailPresenceAttestation':
            return this.isCongress
          default:
            return true
        }
      })
    },
    headers() {
      const selectedHeaders = HEADERS_KEY_BY_DISPLAY[this.selectedView]

      if (!selectedHeaders) return []

      return this.allHeaders.filter(header => {
        if (this.isMci && header.value === 'target') return false

        return selectedHeaders.includes(header.value)
      })
    },
    fields() {
      return Object.keys(FIELDS)
    },
    fieldsConfiguration() {
      return FIELDS
    },
    mybb() {
      return MYBB
    },
    items() {
      const {
        BEGIN_SOON,
        PRESENCE_ATTESTATION,
        RECAPITULATION,
        REGISTRATION_REFUSED,
        // REGISTRATION_REMINDER,
        REGISTRATION_WITH_LOGISTIC,
        REGISTRATION_WITHOUT_LOGISTIC,
        SUBSCRIPTION,
        INVITATION_STAFF,
        TRANSPORT_PROPOSAL,
        TRANSPORT_REMINDER,
        PAYED_EXPENSE_NOTE
      } = this.$const.participantEmailModel
      return this.$get(this.participants, 'hits', []).map(participant => ({
        participantUuid: participant.participantUuid,
        healthCareProfessionalUuid: participant.healthCareProfessionalUuid,
        userUuid: participant.userUuid,
        lastName: participant.lastName,
        firstName: participant.firstName,
        veevaId: this.$get(participant, 'healthCareProfessional.veevaId') || '',
        rppsNumber: this.$get(participant, 'healthCareProfessional.rppsNumber') || '',
        email: this.$get(participant, 'healthCareProfessional.emailAddress') || '',
        category: this.categoriesById ? this.categoriesById[participant.category] : null,
        postalCode: this.$get(participant, 'healthCareProfessional.postalCode'),
        type: this.$get(participant, 'healthCareProfessional.type'),
        title: this.$get(participant, 'healthCareProfessional.title'),
        specialty: this.$get(participant, 'healthCareProfessional.rawSpecialty'),
        target: this.computeTarget(participant),
        invitationStatus: this.computeInvitationStatus(participant),
        learnedSocietyMemberId: this.$get(participant, 'learnedSocietyMemberId'),
        hosting: this.computeHostingStatus(participant),
        transport: this.computeTransportStatus(participant),
        expenseNote: this.computeExpenseNoteStatus(participant),
        sollicitation: this.computeSollicitationStatus(participant),
        sollicitationStatus: this.computeSollicitationRawStatus(participant),
        sollicitationDate: this.computeSollicitationDate(participant),
        participationType: participant.participationType
          ? this.at(`participationType.${participant.participationType}`)
          : null,
        subscriptionDate: participant.subscriptionDate,
        subscriptionPrice: participant.subscriptionPrice,
        participantComments: this.computeParticipantComments(participant),
        transportMode: this.computeTransportMode(participant),
        transportGo: this.computeTransportGo(participant),
        transportBack: this.computeTransportBack(participant),
        transfert: null, // @todo
        hotel: this.computeHotel(participant),
        checkIn: this.computeCheckIn(participant),
        checkOut: this.computeCheckOut(participant),
        nightsCount: this.computeNightsCount(participant),
        presence: this.computePresence(participant),
        // Email
        emailSubscription: this.computeEmails(participant, [SUBSCRIPTION, INVITATION_STAFF]),
        emailRegistration: this.computeEmails(participant, [
          REGISTRATION_REFUSED,
          REGISTRATION_WITH_LOGISTIC,
          REGISTRATION_WITHOUT_LOGISTIC
        ]),
        emailTransport: this.computeEmails(participant, [TRANSPORT_PROPOSAL]),
        emailTransportReminder: this.computeEmails(participant, [TRANSPORT_REMINDER]),
        emailRecap: this.computeEmails(participant, [RECAPITULATION]),
        emailBeginSoon: this.computeEmails(participant, [BEGIN_SOON]),
        // emailRegistrationReminder: this.computeEmails(participant, [REGISTRATION_REMINDER]),
        emailPresenceAttestation: this.computeEmails(participant, [PRESENCE_ATTESTATION]),
        emailPayedExpenseNote: this.computeEmails(participant, [PAYED_EXPENSE_NOTE])
      }))
    },
    tableTypes() {
      const selectItems = [
        { text: this.t('picklist.global'), value: DISPLAYS.global },
        { text: this.t('picklist.veeva'), value: DISPLAYS.veeva },
        { text: this.t('picklist.participation'), value: DISPLAYS.participation },
        { text: this.t('picklist.transport'), value: DISPLAYS.transport },
        { text: this.t('picklist.hosting'), value: DISPLAYS.hosting },
        { text: this.t('picklist.email'), value: DISPLAYS.email }
      ]

      // Remove [transport] and [hosting] for virtual congress
      if (this.isVirtual) {
        selectItems.splice(2, 2)
      }

      return selectItems
    },
    isFilterSelected() {
      const selectedFilterValues = Object.values(this.filters).flat()

      return selectedFilterValues.length >= 1
    },
    isCongress() {
      return Boolean(this.$get(this.gathering, 'congress', null))
    },
    isVirtual() {
      if (!this.gathering) return false

      return this.gathering.isVirtual && !this.gathering.isPhysical
    },
    isPhysical() {
      if (!this.gathering) return false

      return !this.gathering.isVirtual && this.gathering.isPhysical
    },
    isHybrid() {
      if (!this.gathering) return false

      return this.gathering.isVirtual && this.gathering.isPhysical
    },
    expenseNoteColors() {
      const colors = {}

      for (const key in this.$const.expenseNoteColors) {
        colors[this.$const.expenseNoteStatus[key]] = this.$const.expenseNoteColors[key]
      }

      return colors
    },
    filterItems() {
      const filterItems = []

      if (this.isCongress) {
        filterItems.push({
          key: 'solicitation',
          label: this.t('filters.solicitation'),
          filters: [
            this.$const.solicitationStatus.SENT,
            this.$const.solicitationStatus.WAITING_LIST,
            this.$const.solicitationStatus.AD_HOC_VALIDATION,
            this.$const.solicitationStatus.CANCELLED,
            this.$const.solicitationStatus.REFUSED
          ].map(key => ({
            key,
            label: this.t(`filters.solicitationValues.${key}`),
            value: key
          }))
        })
      }

      filterItems.push(
        {
          key: 'invitation',
          label: this.t('filters.invitation'),
          filters: [
            this.$const.invitationStatus.pending,
            this.$const.invitationStatus.confirmed,
            this.$const.invitationStatus.refused
          ].map(key => ({
            key,
            label: this.t(`filters.invitationValues.${key}`),
            value: key
          }))
        },
        {
          key: 'presence',
          label: this.t('filters.presence'),
          filters: (this.isCongress
            ? [null, this.$const.presence.present, this.$const.presence.absent, this.$const.presence.noShow]
            : [null, this.$const.presence.present, this.$const.presence.absent]
          ).map(key => ({
            key,
            label: this.isCongress
              ? this.t(`filters.presenceValues.congress.${key}`)
              : this.t(`filters.presenceValues.standalone.${key}`),
            value: key
          }))
        }
      )

      return filterItems
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    },
    pagination() {
      const options = {
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        limit: this.options.itemsPerPage
      }

      if (this.options.sortBy.length > 0) {
        options.sort = {}

        for (let i = 0; i < this.options.sortBy.length; i++) {
          const field = this.options.sortBy[i]
          const isAsc = this.options.sortDesc[i]

          options.sort[field] = isAsc ? 'ASC' : 'DESC'
        }
      }

      return options
    },
    isMci() {
      const { MCI } = this.$const.userType

      return this.$store.state.myUser.userTypes.includes(MCI)
    }
  },
  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      },
      result() {
        const { SENT, CANCELLED } = this.$const.solicitationStatus

        if (this.isCongress && this.isMci) {
          this.filters.solicitation.push(SENT, CANCELLED, null)
        }
        if (this.isHybrid && !['participationType'].includes(HEADERS_KEY_BY_DISPLAY.GLOBAL)) {
          HEADERS_KEY_BY_DISPLAY.GLOBAL.push('participationType')
        }
        if (!this.isHybrid && ['participationType'].includes(HEADERS_KEY_BY_DISPLAY.GLOBAL)) {
          const findIndex = HEADERS_KEY_BY_DISPLAY.GLOBAL.findIndex(i => i === 'participationType')
          if (findIndex !== -1) HEADERS_KEY_BY_DISPLAY.GLOBAL.splice(findIndex, 1)
        }
      }
    },
    participants: {
      query: PARTICIPANTS_FOR_GATHERING__PAGINATED,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid,
          pagination: this.pagination,
          search: this.searchText,
          filters: this.filters
        }
      },
      skip() {
        return !this.gathering
      },
      update({ participantsForGatheringWithPagination }) {
        return participantsForGatheringWithPagination
      },
      result() {
        this.loading = false
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.AttendeeList.${key}`, params)
    },
    at(key, params) {
      return this.$t(`mybb.activity.detail.table.${key}`, params)
    },
    computeInvitationStatus(participant) {
      const { confirmed, pending, absent } = this.$const.invitationStatus
      const { invitationStatus, presence, presenceDate } = participant
      const { beginDate } = this.gathering
      const subBeginDate = subDays(new Date(beginDate), 1)
      const limitDate = presenceDate && compareAsc(new Date(presenceDate), new Date(subBeginDate)) === 1 ? true : false

      if (!invitationStatus || invitationStatus === pending) return null
      if (presence === absent && invitationStatus === confirmed && !limitDate) {
        return { color: 'mybb-error', icon: 'mdi-close', invitationStatus }
      }
      if (presence === absent && invitationStatus === confirmed && limitDate) {
        return { color: 'mybb-primary-lighten1', icon: 'mdi-check', invitationStatus }
      }

      return invitationStatus === confirmed
        ? { color: 'mybb-primary-lighten1', icon: 'mdi-check', invitationStatus }
        : { color: 'mybb-error', icon: 'mdi-close', invitationStatus }
    },
    computeHostingStatus(participant) {
      const { PENDING, REFUSED, ACCEPTED } = this.$const.hosting.overnightStayStatus
      const overnightStays = this.$get(participant, 'overnightStays', [])
      const hostingRequests = this.$get(participant, 'hostingParticipantRequests', [])

      if (!overnightStays.length && !hostingRequests.length) return null

      const isAccepted =
        hostingRequests.some(request => request.hostingUuid) ||
        (overnightStays.length && overnightStays.some(({ status }) => status === ACCEPTED))
      const isRefused = overnightStays.length && overnightStays.every(({ status }) => status === REFUSED)

      const status = isAccepted ? ACCEPTED : isRefused ? REFUSED : PENDING

      switch (status) {
        case ACCEPTED:
          return { color: 'mybb-primary-lighten1', icon: 'mdi-check', status }

        case REFUSED:
          return { color: 'mybb-error', icon: 'mdi-close', status }

        case PENDING:
        default:
          return { color: 'mybb-grey-lighten1', icon: 'mdi-clock-outline', status }
      }
    },
    computeTransportStatus(participant) {
      const { ACCEPTED, PENDING, REFUSED, TO_EMIT, WAITING_FOR_FEEDBACK } = this.$const.transport.noteStatus
      const notes = this.$get(participant, 'transportNotes', [])

      if (!notes.length) return null

      const isAccepted = notes.some(note => note.status === ACCEPTED)
      const isRefused = notes.some(note => note.status === REFUSED)
      const isPending = notes.some(note => note.status === PENDING)
      const isToEmit = notes.some(note => note.status === TO_EMIT)
      const isWaitingForFeedback = notes.some(note => note.status === WAITING_FOR_FEEDBACK)

      /**
       * WARNING - This ternary order is crucial for a proper data representation
       */
      const status = isAccepted
        ? ACCEPTED
        : isToEmit
        ? TO_EMIT
        : isWaitingForFeedback
        ? WAITING_FOR_FEEDBACK
        : isPending
        ? PENDING
        : isRefused
        ? REFUSED
        : null

      if (!status) return null

      const color = this.$const.transport.colors[status]
      const label = this.$t(`mybb.transportNoteList.status.${status}`)

      return { color, label, status }
    },
    computeExpenseNoteStatus(participant) {
      const { ARBITRATION, PAYED, REFUSED, TO_BE_PROCESSED, VALIDATED } = this.$const.expenseNoteStatus
      const notes = this.$get(participant, 'expenseNotes', [])

      if (!notes.length) return null

      const isPayed = notes.some(note => note.status === PAYED)
      const isValidated = notes.some(note => note.status === VALIDATED)
      const isUnderArbitration = notes.some(note => note.status === ARBITRATION)
      const isPending = notes.some(note => note.status === TO_BE_PROCESSED)
      const isRefused = notes.some(note => note.status === REFUSED)

      /**
       * WARNING - This ternary order is crucial for a proper data representation
       */
      const status = isPayed
        ? PAYED
        : isValidated
        ? VALIDATED
        : isUnderArbitration
        ? ARBITRATION
        : isPending
        ? TO_BE_PROCESSED
        : isRefused
        ? REFUSED
        : null

      if (!status) return null

      const color = this.expenseNoteColors[status]
      const label = this.$t(`mybb.expenseNoteList.status.${status}`)

      return { color, label, status }
    },
    computeSollicitationRawStatus(participant) {
      const solicitation = this.getCurrentSolicitation(participant)

      if (!solicitation || !solicitation.status) return null

      return solicitation.status
    },
    computeSollicitationStatus(participant) {
      const rawStatus = this.computeSollicitationRawStatus(participant)

      if (!rawStatus) return null

      return this.t(`filters.solicitationValues.${rawStatus}`)
    },
    computeTarget(participant) {
      const businessUnit = this.$get(participant, 'healthCareProfessional.businessUnit', null)

      if (!businessUnit) return null

      const targetBu = this.$const.targetBu[businessUnit.label]

      if (!targetBu) return null

      return `${businessUnit.externalId}_${this.$get(participant, ['healthCareProfessional', targetBu])}`
    },
    computeSollicitationDate(participant) {
      const solicitations = this.$get(participant, 'healthCareProfessional.solicitations', [])

      if (!solicitations.length) return null

      // Take the most recent one
      const solicitation = solicitations.sort((s1, s2) => new Date(s2.createdAt) - new Date(s1.createdAt))[0]

      return solicitation.createdAt
    },
    computeParticipantComments(participant) {
      const comments = this.$get(participant, 'participantComments', [])
        .filter(comment => comment.reason === this.$const.participantCommentReason.GLOBAL_COMMENT)
        .sort((c1, c2) => new Date(c2.createdAt) - new Date(c1.createdAt))
      return comments.length > 0 ? comments[0] : null
    },
    computeTransportMode(participant) {
      const note = this.getCurrentTransportNote(participant)

      if (!note) return null

      return note.preferenceModes
        ? note.preferenceModes.map(mode => this.$t(`mybb.transportNotePage.requestGroup.mode.${mode}`)).join(' + ')
        : ''
    },
    computeTransportGo(participant) {
      const note = this.getCurrentTransportNote(participant)

      if (!note) return null

      const travels = this.$get(note, 'travels', []).filter(
        travel => travel.type === this.$const.transport.travelType.GO
      )

      if (!travels.length) return null

      const date = new Date(travels[0].departureDate)
      const [hour, min] = travels[0].departureTime.split(':')
      date.setHours(hour, min)

      return date.toISOString()
    },
    computeTransportBack(participant) {
      const note = this.getCurrentTransportNote(participant)

      if (!note) return null

      const travels = this.$get(note, 'travels', []).filter(
        travel => travel.type === this.$const.transport.travelType.BACK
      )

      if (!travels.length) return null

      const date = new Date(travels[0].departureDate)
      const [hour, min] = travels[0].departureTime.split(':')
      date.setHours(hour, min)

      return date.toISOString()
    },
    computeHotel(participant) {
      const notes = this.$get(participant, 'overnightStays', [])

      if (!notes.length) return null

      const hotels = new Set()

      for (const note of notes) {
        const hotel = this.$get(note, 'hostingNight.hosting.name', null)

        if (hotel) hotels.add(hotel)
      }

      return Array.from(hotels).join(' - ')
    },
    computeCheckIn(participant) {
      const notes = this.$get(participant, 'overnightStays', []).filter(
        stay => stay.status !== this.$const.hosting.overnightStayStatus.REFUSED
      )

      if (!notes.length) return null

      // Chronological sort
      notes.sort((n1, n2) => new Date(n1.date) - new Date(n2.date))

      return this.$get(notes, '[0].date', null)
    },
    computeCheckOut(participant) {
      const notes = this.$get(participant, 'overnightStays', []).filter(
        stay => stay.status !== this.$const.hosting.overnightStayStatus.REFUSED
      )

      if (!notes.length) return null

      // Anti-chronological sort
      notes.sort((n1, n2) => new Date(n2.date) - new Date(n1.date))

      const lastStay = this.$get(notes, '[0].date', null)

      if (!lastStay) return null

      const lastStayDate = new Date(lastStay)
      lastStayDate.setDate(lastStayDate.getDate() + 1)

      return lastStayDate
    },
    computeNightsCount(participant) {
      const checkIn = this.computeCheckIn(participant)
      const checkOut = this.computeCheckOut(participant)

      if (!checkIn || !checkOut) return null

      return differenceInCalendarDays(new Date(checkOut), new Date(checkIn))
    },
    getCurrentTransportNote(participant) {
      const { ACCEPTED, TO_EMIT, WAITING_FOR_FEEDBACK, PENDING, REFUSED } = this.$const.transport.noteStatus
      const notes = this.$get(participant, 'transportNotes', [])

      if (!notes.length) return null

      notes.sort((n1, n2) => new Date(n2.lastUpdate) - new Date(n1.lastUpdate))
      let note = null

      const accepted = notes.find(note => note.status === ACCEPTED)
      if (accepted) note = accepted

      const toEmit = notes.find(note => note.status === TO_EMIT)
      if (toEmit && !note) note = toEmit

      const waitingForFeedback = notes.find(note => note.status === WAITING_FOR_FEEDBACK)
      if (waitingForFeedback && !note) note = waitingForFeedback

      const pending = notes.find(note => note.status === PENDING)
      if (pending && !note) note = pending

      const refused = notes.find(note => note.status === REFUSED)
      if (refused && !note) note = refused

      if (Array.isArray(note.travels)) {
        note.travels = note.travels.sort((t1, t2) => {
          const date1 = new Date(t1.departureDate)
          const [h1, m1] = t1.departureTime.split(':')
          date1.setHours(h1, m1)

          const date2 = new Date(t2.departureDate)
          const [h2, m2] = t2.departureTime.split(':')
          date2.setHours(h2, m2)

          return date1 - date2
        })
      }

      return note
    },
    getCurrentSolicitation(participant) {
      const solicitations = this.$get(participant, 'healthCareProfessional.solicitations', []).filter(
        solicitation => solicitation.congressUuid === this.$get(this.gathering, 'congress.congressUuid')
      )

      if (!solicitations.length) return null

      // Take the most recent one
      return solicitations.sort((s1, s2) => new Date(s2.createdAt) - new Date(s1.createdAt))[0]
    },
    computePresence(participant) {
      const { present, absent, noShow } = this.$const.presence
      const { presence } = participant

      if (!presence) return null

      switch (presence) {
        case present:
          return { color: 'mybb-primary-lighten1', icon: 'mdi-check', presence }

        case absent:
          return { color: 'mybb-error', icon: 'mdi-close', presence }

        case noShow:
          return { color: 'mybb-warning', icon: 'mdi-alert', presence }

        default:
          return null
      }
    },
    computeEmails(participant, types) {
      const records = this.$get(participant, 'participantEmailHistorics', []).filter(record =>
        types.includes(record.emailModel)
      )

      records.sort((h1, h2) => new Date(h2.date) - new Date(h1.date))

      return records
    },
    redirectionLink({ healthCareProfessionalUuid, userUuid, participantUuid }) {
      if (!healthCareProfessionalUuid && !userUuid && participantUuid) {
        const { href } = this.$router.resolve({
          name: 'UserProfile',
          params: { gatheringUuid: this.$route.params.gatheringUuid, userUuid: participantUuid }
        })
        return href
      }

      if (!healthCareProfessionalUuid && userUuid) {
        const { href } = this.$router.resolve({
          name: 'StaffProfile',
          params: { gatheringUuid: this.$route.params.gatheringUuid, userUuid: userUuid }
        })
        return href
      } else {
        const { href } = this.$router.resolve({
          name: 'HCPProfile',
          params: { gatheringUuid: this.$route.params.gatheringUuid, hcpUuid: healthCareProfessionalUuid }
        })

        return href
      }
    },
    goToParticipant({ healthCareProfessionalUuid, userUuid, participantUuid }) {
      const href = this.redirectionLink({ healthCareProfessionalUuid, userUuid, participantUuid })

      if (!href) return
      window.location.assign(href)
    },
    selectAllItems({ value }) {
      if (!value) {
        this.selectedParticipants = []
        return
      }

      this.selectedParticipants = this.participants.hits
    }
  },
  filters: {
    price(value) {
      if (!value) return null

      return `${(value / 100).toFixed(2).replace('.', ',')}€`
    },
    shortDate(value) {
      if (!value) return null

      return format(new Date(value), 'dd/MM')
    },
    date(value) {
      if (!value) return null

      return format(new Date(value), 'dd/MM/yy')
    },
    dateTime(value) {
      if (!value) return null

      return format(new Date(value), 'dd/MM/yy - HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
.AttendeeList-header {
  height: 46px;
}

.selectField {
  max-width: 200px;
}

.AttendeeList-buttonWrapper {
  display: flex;
}

.BtnWrapper {
  position: relative;
}

.AttendeeList-buttonDot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--v-mybb-success-base);
  position: absolute;
  top: -5px;
  right: -3.75px;
}

.Comment {
  color: white;
  word-break: break-word;
}
</style>
<style lang="scss">
.AttendeeList {
  .AttendeeList-guest {
    height: 46px;
    display: grid;
  }

  .DataTable {
    border-radius: 8px;

    th,
    td {
      .v-icon {
        color: var(--v-mybb-primary-lighten1-base);
      }
    }
  }

  .v-data-table thead th {
    font-size: 12px;
    font-weight: 900;
    color: var(--v-mybb-primary-base) !important;
    text-transform: uppercase;
  }

  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }
  .v-input__append-inner {
    margin-top: 10px !important;
  }
}
</style>
