import gql from 'graphql-tag'

const SOLICITATION = gql`
  fragment Solicitation on Solicitation {
    businessUnitUuid
    actualAndNewTherapeutic
    cancellationDate
    clinicalStudyPresentation
    confirmationDate
    congressUuid
    emailAddress
    healthCareProfessionalUuid
    initiatorUuid
    isTherapeuticCommittee
    newsSearchPath
    noBiogenObligation
    noOtherFunding
    noOtherIntercontinentalFunding
    others
    phoneNumber
    requestedSupport
    sentAt
    shareClinicalKnowledge
    signature
    solicitationUuid
    status
    supportRegistration
    supportTransport
    supportAccommodation
    therapeuticArea
    therapeuticCommitteeRole
    zoneUuid
    createdAt
    updatedAt
  }
`

export const GET_SOLICITATIONS = gql`
  query solicitations($status: [String]) {
    solicitations(status: $status) {
      solicitationUuid
      status
      zone {
        label
      }
      healthCareProfessional {
        firstName
        lastName
        targetBbu
        targetMs
        targetSma
      }
      congress {
        maximalQuota
        gathering {
          name
        }
      }
    }
  }
`
export const GET_ALL_SOLICITATIONS = gql`
  query solicitations($limit: Int, $offset: Int, $status: [SolicitationStatus]) {
    solicitations(limit: $limit, offset: $offset, status: $status) {
      createdAt
      solicitationUuid
      status
      healthCareProfessional {
        isMslTarget
        lastName
        firstName
        targetBbu
        targetKme
        targetMs
        targetSma
        type
        zone {
          label
        }
      }
      initiator {
        firstName
        lastName
      }
      congress {
        criterias
        maximalQuota
        gathering {
          gatheringUuid
          name
        }
        stats {
          totalFilled
          zones {
            zoneUuid
            filledZoneQuota
          }
        }
      }
      zone {
        zoneUuid
        label
      }
      zoneCongress {
        zoneQuota
      }
    }
  }
`

export const SOLICITATIONS_FOR_CONGRESS_AND_STATUSES = gql`
  query solicitationsForCongress($congressUuid: GUID!, $statuses: [SolicitationStatus]) {
    solicitationsForCongress(congressUuid: $congressUuid, statuses: $statuses) {
      ...Solicitation
      zone {
        zoneUuid
        businessUnitUuid
      }
      healthCareProfessional {
        type
      }
    }
  }
  ${SOLICITATION}
`
