<template>
  <div>
    <mybb-text class="mb-4">{{ t('subtitle') }}</mybb-text>

    <v-row class="mb-2">
      <v-col cols="6">
        <mybb-autocomplete
          v-model="inChargeRoc"
          :label="t('inChargeRocLabel')"
          :items="rocItems"
          :error-messages="collect('custom-roc')"
          :disabled="disableAll"
          v-cy="$cy.gathering.form.roc"
          icon="mdi-account"
        />
      </v-col>

      <v-col cols="6">
        <mybb-autocomplete
          v-model="backupRoc"
          :label="t('backupRocLabel')"
          :items="backupRocItems"
          :disabled="!inChargeRoc || disableAll"
          v-cy="$cy.gathering.form.rocBackup"
          icon="mdi-account"
          clearable
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <mybb-autocomplete
          v-model="inChargeMci"
          :label="t('inChargeMciLabel')"
          :items="mciItems"
          :error-messages="collect('custom-mci')"
          :disabled="disableAll"
          v-cy="$cy.gathering.form.mci"
          icon="mdi-account"
        />
      </v-col>

      <v-col cols="6">
        <mybb-autocomplete
          v-model="backupMci"
          :label="t('backupMciLabel')"
          :items="mciBackupItems"
          :disabled="!inChargeMci || disableAll"
          v-cy="$cy.gathering.form.mciBackup"
          icon="mdi-account"
          clearable
        />
      </v-col>
    </v-row>

    <!-- Main business unit -->
    <mybb-text :class="{ 'mt-3': true, 'error--text': collect('mainBusinessUnitUuid') }">
      {{ t('mainBusinessUnit') }}
    </mybb-text>
    <v-chip-group v-model="mainBusinessUnitUuid" :disabled="disableAll || lockBusinessUnits" class="mb-2" dark>
      <v-chip
        v-for="bu of businessUnits"
        :key="bu.businessUnitUuid"
        :value="bu.businessUnitUuid"
        :color="bu.businessUnitUuid === mainBusinessUnitUuid ? bu.color : '#9a9fa4'"
        :close="bu.businessUnitUuid === mainBusinessUnitUuid && !disableAll && !lockBusinessUnits"
        :disabled="disableAll || lockBusinessUnits"
        v-cy="$cy.gathering.form.mainBusinessUnitChip(bu.label)"
        v-on="mainBuListeners(bu.businessUnitUuid)"
        class="white--text"
        dark
      >
        {{ bu.label }}
      </v-chip>
    </v-chip-group>

    <team-form
      v-model="event.users"
      :users="users"
      :is-congress="isCongress"
      :business-unit-uuid="mainBusinessUnitUuid"
      :disabled="disableAll"
      is-main
    />

    <!-- Other business units -->
    <div v-if="mainBusinessUnitUuid">
      <mybb-text class="mt-3">{{ t('BuSection') }}</mybb-text>
      <v-chip-group v-model="businessUnitUuids" :disabled="disableAll || lockBusinessUnits" class="mb-2" dark>
        <v-chip
          v-for="bu of businessUnits"
          :key="bu.businessUnitUuid"
          :value="bu.businessUnitUuid"
          :color="
            businessUnitUuids.includes(bu.businessUnitUuid) && bu.businessUnitUuid !== mainBusinessUnitUuid
              ? bu.color
              : '#9a9fa4'
          "
          :close="
            businessUnitUuids.includes(bu.businessUnitUuid) &&
              bu.businessUnitUuid !== mainBusinessUnitUuid &&
              !disableAll &&
              !lockBusinessUnits
          "
          :disabled="bu.businessUnitUuid === mainBusinessUnitUuid || disableAll || lockBusinessUnits"
          v-cy="$cy.gathering.form.businessUnitChip(bu.label)"
          v-on="buListeners(bu.businessUnitUuid)"
          class="white--text"
          dark
        >
          {{ bu.label }}
        </v-chip>
      </v-chip-group>
    </div>

    <!-- Change on main business unit modal -->
    <modal v-model="mainBuModal" :title="t('mainBusinessUnitModal.title')">
      <template v-slot:text>
        <mybb-text class="mb-2">{{ t('mainBusinessUnitModal.text') }}</mybb-text>
      </template>

      <template v-slot:actions>
        <mybb-btn @click="mainBuModal = false" color="mybb-grey">
          {{ t('deleteBuCancelButton') }}
        </mybb-btn>
        <mybb-btn @click="deleteMainBusinessUnit" color="mybb-error" class="ml-15">
          {{ t('deleteBuConfirmButton') }}
        </mybb-btn>
      </template>
    </modal>

    <!-- Deletion on sub business units -->
    <modal v-model="otherBuModal" :title="t('deleteBuConfirmTitle')">
      <template v-slot:text>
        <div class="text-center">
          <div class="mb-2">
            <mybb-text>{{ t('deleteBuConfirmContent') }}</mybb-text>
          </div>
        </div>
      </template>
      <template v-slot:actions>
        <mybb-btn @click="otherBuModal = false" color="mybb-grey">
          {{ t('deleteBuCancelButton') }}
        </mybb-btn>
        <mybb-btn @click="deleteOtherBusinessUnit" color="mybb-error" class="ml-15">
          {{ t('deleteBuConfirmButton') }}
        </mybb-btn>
      </template>
    </modal>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

import MYBB from '@/const/my-bb'
import { SEARCH_ALL_USERS } from '@/graphql/User/GetUsers'
import { yup } from '@/mixins'
import { congressSchema, biogenEventSchema } from '@/validations/gathering/team'

import Modal from '@/components/mybb/Modal'

import TeamForm from './TeamForm'

export default {
  name: 'TeamSupportStep',
  components: { TeamForm, Modal },
  mixins: [yup],
  model: {
    prop: 'event'
  },
  props: {
    event: {
      type: Object
    },
    isCongress: {
      type: Boolean
    },
    disableAll: {
      type: Boolean
    },
    lockBusinessUnits: {
      type: Boolean
    }
  },
  provide() {
    return {
      collect: this.collect,
      clear: this.clear
    }
  },
  data() {
    return {
      mainBuModal: false,
      businessUnitUuidToDelete: null
    }
  },
  computed: {
    schema() {
      return this.isCongress ? congressSchema : biogenEventSchema
    },
    businessUnits() {
      return this.$get(this.$store.state, 'bus', [])
        .map(bu => ({ ...bu, color: MYBB.bu[bu.label].color }))
        .sort((bu1, bu2) => MYBB.bu[bu1.label].order - MYBB.bu[bu2.label].order)
    },
    otherBusinessUnits() {
      return this.$get(this.$store.state, 'bus', [])
        .map(bu => ({ ...bu, color: MYBB.bu[bu.label].color }))
        .sort((bu1, bu2) => MYBB.bu[bu1.label].order - MYBB.bu[bu2.label].order)
        .map(bu => bu.businessUnitUuid)
        .filter(bu => bu !== this.mainBusinessUnitUuid && this.event.businessUnitUuids.includes(bu))
    },
    rocItems() {
      if (!this.users) return []

      const rocs = this.users.filter(user => user.userTypes.includes(this.$const.userType.ROC))

      return this.mapToItems(rocs)
    },
    backupRocItems() {
      if (!this.inChargeRoc || !this.users) return []

      const rocs = this.users.filter(
        user => user.userTypes.includes(this.$const.userType.ROC) && user.userUuid !== this.inChargeRoc
      )

      return this.mapToItems(rocs)
    },
    mciItems() {
      if (!this.users) return []

      const mcis = this.users.filter(user => user.userTypes.includes(this.$const.userType.MCI))

      return this.mapToItems(mcis)
    },
    mciBackupItems() {
      if (!this.inChargeMci || !this.users) return []

      const mcis = this.users.filter(
        user => user.userTypes.includes(this.$const.userType.MCI) && user.userUuid !== this.inChargeMci
      )

      return this.mapToItems(mcis)
    },
    inChargeRoc: {
      get() {
        return this.getUserUuid(null, this.$const.gatheringUserType.ROC, false)
      },
      set(value) {
        this.setUser(null, this.$const.gatheringUserType.ROC, false, value)
        this.clear('custom-roc')

        if (this.backupRoc === value) {
          this.$nextTick(() => {
            this.backupRoc = null
          })
        }
      }
    },
    backupRoc: {
      get() {
        return this.getUserUuid(null, this.$const.gatheringUserType.ROC, true)
      },
      set(value) {
        this.setUser(null, this.$const.gatheringUserType.ROC, true, value)
      }
    },
    inChargeMci: {
      get() {
        return this.getUserUuid(null, this.$const.gatheringUserType.MCI, false)
      },
      set(value) {
        this.setUser(null, this.$const.gatheringUserType.MCI, false, value)
        this.clear('custom-mci')

        if (this.backupMci === value) {
          this.$nextTick(() => {
            this.backupMci = null
          })
        }
      }
    },
    backupMci: {
      get() {
        return this.getUserUuid(null, this.$const.gatheringUserType.MCI, true)
      },
      set(value) {
        this.setUser(null, this.$const.gatheringUserType.MCI, true, value)
      }
    },
    mainBusinessUnitUuid: {
      get() {
        return this.event.mainBusinessUnitUuid
      },
      set(value) {
        if (this.mainBusinessUnitUuid) return

        const businessUnitUuids = (this.event.businessUnitUuids || []).slice()

        if (!businessUnitUuids.includes(value)) {
          businessUnitUuids.push(value)

          this.setProperties({ mainBusinessUnitUuid: value, businessUnitUuids })
        } else {
          this.setProperty('mainBusinessUnitUuid', value)
        }

        this.clear('mainBusinessUnitUuid')
      }
    },
    businessUnitUuids: {
      get() {
        return this.event.businessUnitUuids
      },
      set(value) {
        const businessUnitUuids = Array.from(this.event.businessUnitUuids)

        if (!businessUnitUuids.includes(value)) {
          businessUnitUuids.push(value)
        }

        this.setProperties({ businessUnitUuids })
      }
    },
    otherBuModal: {
      get() {
        return Boolean(this.businessUnitUuidToDelete)
      },
      set(value) {
        if (value) {
          this.businessUnitUuidToDelete = value
        } else {
          this.businessUnitUuidToDelete = null
        }
      }
    }
  },
  apollo: {
    users: {
      loadingKey: 'loadingCount',
      query: SEARCH_ALL_USERS,
      variables() {
        return {
          query: {
            limit: 1000
          }
        }
      },
      update({ searchUsers }) {
        return searchUsers.rows.sort((u1, u2) => u1.lastName.localeCompare(u2.lastName))
      }
    }
  },
  methods: {
    validate() {
      return this.validateSchema(this.event)
    },
    t(key, params) {
      return this.$t(`mybb.supportTeamStep.${key}`, params)
    },
    setProperties(partial) {
      this.$emit('input', { ...this.event, ...partial })
    },
    setProperty(property, value) {
      this.setProperties({ [property]: value })
    },
    getUserUuid(businessUnitUuid, gatheringUserType, isBackup) {
      const user = this.event.users.find(
        user =>
          user.businessUnitUuid === businessUnitUuid &&
          user.gatheringUserType === gatheringUserType &&
          user.isBackup === isBackup
      )

      return user ? user.userUuid : null
    },
    setUser(businessUnitUuid, gatheringUserType, isBackup, userUuid) {
      const users = _cloneDeep(this.event.users)
      const current = users.find(
        usr =>
          usr.businessUnitUuid === businessUnitUuid &&
          usr.gatheringUserType === gatheringUserType &&
          usr.isBackup === isBackup
      )
      const user = {
        businessUnitUuid,
        gatheringUserType,
        isBackup,
        userUuid
      }

      if (!current) {
        users.push(user)
      } else {
        const index = users.findIndex(usr => usr === current)
        users[index] = user
      }

      if (!isBackup) {
        const backupUser = users.find(
          usr =>
            usr.businessUnitUuid === this.businessUnitUuid &&
            usr.gatheringUserType === gatheringUserType &&
            usr.isBackup
        )

        if (backupUser && backupUser.userUuid === userUuid) {
          const backupUserIndex = users.findIndex(user => user === backupUser)

          users.splice(backupUserIndex, 1)
        }
      }

      this.setProperty('users', users.filter(user => user.userUuid))
    },
    mapToItems(users) {
      return users.map(user => ({
        value: user.userUuid,
        text: `${user.lastName} ${user.firstName}`
      }))
    },
    mainBuListeners(businessUnitUuid) {
      if (businessUnitUuid !== this.mainBusinessUnitUuid || this.disableAll) return

      return {
        click: () => (this.mainBuModal = true),
        'click:close': () => (this.mainBuModal = true)
      }
    },
    buListeners(businessUnitUuid) {
      if (
        businessUnitUuid === this.mainBusinessUnitUuid ||
        !this.businessUnitUuids.includes(businessUnitUuid) ||
        this.disableAll
      )
        return

      return {
        click: () => (this.businessUnitUuidToDelete = businessUnitUuid),
        'click:close': () => (this.businessUnitUuidToDelete = businessUnitUuid)
      }
    },
    deleteMainBusinessUnit() {
      const users = (this.event.users || []).filter(user => !user.businessUnitUuid)

      this.setProperties({ users, mainBusinessUnitUuid: null, businessUnitUuids: [], gatheringBusinessUnits: [] })
      this.mainBuModal = false
    },
    deleteOtherBusinessUnit() {
      if (!this.businessUnitUuidToDelete) return

      const businessUnitUuids = this.businessUnitUuids.filter(uuid => uuid !== this.businessUnitUuidToDelete)
      const users = (this.event.users || []).filter(user => user.businessUnitUuid !== this.businessUnitUuidToDelete)
      const gatheringBusinessUnits = (this.event.gatheringBusinessUnits || []).filter(
        gbu => gbu.businessUnitUuid !== this.businessUnitUuidToDelete
      )

      this.setProperties({ users, businessUnitUuids, gatheringBusinessUnits })
      this.businessUnitUuidToDelete = null
    }
  }
}
</script>

<style lang="scss" scoped>
.theme--dark.v-chip--active::before {
  opacity: 0;
}
</style>
