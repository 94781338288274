import gql from 'graphql-tag'

import { VIRTUAL_SESSION_LIST_ITEM } from '../fragments'

export const LIST_LIGHT_VIRTUAL_SESSION_ITEM = gql`
  query virtualSessions {
    virtualSessions {
      ...VirtualSessionListItem
    }
  }
  ${VIRTUAL_SESSION_LIST_ITEM}
`

export const LIST_VIRTUAL_SESSION_ITEM = gql`
  query virtualSessions {
    virtualSessions {
      ...VirtualSessionListItem
      cp {
        firstName
        lastName
      }
      roc {
        firstName
        lastName
      }
    }
  }
  ${VIRTUAL_SESSION_LIST_ITEM}
`

export const LIST_VIRTUAL_SESSION_ITEM_CONGREX = gql`
  query virtualSessions {
    virtualSessions {
      ...VirtualSessionListItem
      businessUnit {
        businessUnitUuid
        label
        externalId
      }
      virtualSessionParticipants {
        virtualSessionParticipantUuid
      }
    }
  }
  ${VIRTUAL_SESSION_LIST_ITEM}
`
