<template>
  <div class="HCPEvent pb-8">
    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field
          v-model="searchText"
          background-color="white"
          :placeholder="t('searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>
    </v-row>

    <Loader v-if="!healthCareProfessional" color="white" />
    <div v-else class="Table px-6 pt-5 pb-3">
      <mybb-text weight="bold" size="16" class="text-uppercase mb-2">
        {{ t('tableTitle') }}
      </mybb-text>

      <v-data-table
        :headers="headers"
        item-key="gatheringUuid"
        :items="events"
        :search="searchText"
        sort-by="date"
        sort-desc
        :hide-default-footer="true"
        disable-pagination
      >
        <template v-slot:[`item.orator`]="{ item }">
          <img
            v-if="item.hasOratorCategory"
            alt="orator"
            class="mt-2"
            height="20px"
            src="@/assets/ionic-ios-microphone-blue.svg"
          />
        </template>
        <template v-slot:[`item.name`]="{ value }">
          <mybb-text weight="bold">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.status`]="{ value: status }">
          <v-chip :color="status.color" class="statusChip py-2 px-4" dark>
            <mybb-text size="12" weight="semi-bold" class="white--text">
              {{ $t(`mybb.eventList.status.${status.text}`) }}
            </mybb-text>
          </v-chip>
        </template>
        <template v-slot:[`item.type`]="{ value }">
          <mybb-text weight="bold">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.geography`]="{ value }">
          <mybb-text
            :class="{ 'mybb-warning--text': isInternationalGeography(value) }"
            :weight="isInternationalGeography(value) ? 'bold' : 'regular'"
          >
            {{ getGeographyLabel(value) }}
          </mybb-text>
        </template>
        <template v-slot:[`item.inscription`]="{ value }">
          <v-icon v-if="value" :color="value.color">{{ value.icon }}</v-icon>
        </template>
        <template v-slot:[`item.presence`]="{ value }">
          <v-icon v-if="value" :color="value.color">{{ value.icon }}</v-icon>
        </template>
        <!-- Navigation -->
        <template v-slot:[`item.navigation`]="{ item }">
          <router-link
            class="noStyleLink"
            :to="{ name: 'HCPEventDetails', params: { gatheringUuid: item.gatheringUuid } }"
          >
            <v-icon color="mybb-grey-lighten1">mdi-eye</v-icon>
          </router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'
import parse from 'date-fns/parse'

import { GET_HCP_EVENTS } from '@/graphql/Hcp/Hcp'
import Categories from 'mybb-categories'

import Loader from '@/components/congrex/tabs/LoaderTab'

export default {
  name: 'HCPEvent',

  components: { Loader },

  data() {
    return {
      searchText: ''
    }
  },

  computed: {
    headers() {
      return [
        { sortable: false, text: '', value: 'orator', width: '20px' },
        { sortable: true, text: this.t('headers.name'), value: 'name' },
        {
          sortable: true,
          text: this.t('headers.status'),
          value: 'status',
          sort: (a, b) => {
            if (!a) return -1
            if (!b) return 1

            return a.text.localeCompare(b.text)
          }
        },
        { sortable: true, text: this.t('headers.type'), value: 'type' },
        {
          sortable: true,
          text: this.t('headers.date'),
          value: 'date',
          sort: (a, b) => {
            const date1 = parse(a, 'dd/MM/yy', new Date())
            const date2 = parse(b, 'dd/MM/yy', new Date())

            return date1 - date2
          }
        },
        { sortable: true, text: this.t('headers.geography'), value: 'geography' },
        { sortable: true, text: this.t('headers.city'), value: 'city' },
        { sortable: true, text: this.t('headers.sollicitation'), value: 'sollicitation' },
        {
          sortable: true,
          text: this.t('headers.inscription'),
          value: 'inscription',
          sort: (a, b) => {
            if (!a) return -1
            if (!b) return 1

            return a.invitationStatus.localeCompare(b.invitationStatus)
          }
        },
        {
          sortable: true,
          text: this.t('headers.presence'),
          value: 'presence',
          sort: (a, b) => {
            if (!a) return -1
            if (!b) return 1

            return a.presence.localeCompare(b.presence)
          }
        },
        { sortable: false, text: '', value: 'navigation' }
      ]
    },
    events() {
      if (!this.healthCareProfessional.participants) {
        return []
      } else {
        return this.healthCareProfessional.participants.map(participant => {
          const gathering = participant.gathering || {}
          return {
            gatheringUuid: gathering.gatheringUuid,
            name: gathering.name,
            status: this.computeGatheringStatus(gathering.status),
            type: this.$t(`mybb.global.eventType.${gathering.gatheringType}`),
            date: dateFormat(new Date(gathering.beginDate), 'dd/MM/yy'),
            geography: this.$get(gathering, 'congress.nationalityType', null),
            city: gathering.city || '- -',
            sollicitation: this.computeSolicitationStatus(participant),
            inscription: this.computeInvitationStatus(participant),
            presence: this.computePresence(participant),
            hasOratorCategory: this.hasOratorCategory(participant)
          }
        })
      }
    }
  },

  apollo: {
    healthCareProfessional: {
      query: GET_HCP_EVENTS,
      variables() {
        return {
          hcpUuid: this.$route.params.hcpUuid
        }
      }
    }
  },

  methods: {
    t(key, params) {
      return this.$t(`mybb.HcpEvents.${key}`, params)
    },
    solicitationTranslation(key) {
      return this.$t(`mybb.AttendeeList.filters.solicitationValues.${key}`)
    },
    computeGatheringStatus(status) {
      // @see EventList.vue if you need to make any change here
      const defineColor = key => {
        switch (key) {
          case this.$const.gatheringStatus.DRAFT:
            return 'gathering-status-draft'
          case this.$const.gatheringStatus.PUBLISHED:
            return 'gathering-status-published'
          case this.$const.gatheringStatus.PUBLISHED_FRONT:
            return 'gathering-status-published-front'
          case this.$const.gatheringStatus.TO_PUBLISH:
            return 'gathering-status-to-publish'
          case this.$const.gatheringStatus.TO_VALIDATE:
            return 'gathering-status-to-validate'
          case this.$const.gatheringStatus.WINDED_UP:
          case this.$const.gatheringStatus.CLOSED:
            return 'error'
          default:
            return 'grey'
        }
      }
      return {
        text: status,
        color: defineColor(status)
      }
    },
    computeSolicitationStatus(participant) {
      const gatheringType = this.$get(participant, 'gathering.gatheringType')

      if (gatheringType === this.$const.gatheringType.BIOGEN_EVENT) {
        return '- -'
      }

      const solicitations = this.$get(participant, 'healthCareProfessional.solicitations', [])
      const gatheringSolicitation = solicitations.find(
        solicitation => solicitation.congressUuid === this.$get(participant, 'gathering.congress.congressUuid', null)
      )

      if (gatheringSolicitation && gatheringSolicitation.status) {
        return this.solicitationTranslation(gatheringSolicitation.status)
      }

      return null
    },
    computeInvitationStatus(participant) {
      const { confirmed, pending } = this.$const.invitationStatus
      const { invitationStatus } = participant

      if (!invitationStatus || invitationStatus === pending) return null

      return invitationStatus === confirmed
        ? { color: 'mybb-primary-lighten1', icon: 'mdi-check', invitationStatus }
        : { color: 'mybb-error', icon: 'mdi-close', invitationStatus }
    },
    computePresence(participant) {
      const { present, absent, noShow } = this.$const.presence
      const { presence } = participant

      if (!presence) return null

      switch (presence) {
        case present:
          return { color: 'mybb-primary-lighten1', icon: 'mdi-check', presence }

        case absent:
          return { color: 'mybb-error', icon: 'mdi-close', presence }

        case noShow:
          return { color: 'mybb-warning', icon: 'mdi-alert', presence }

        default:
          return null
      }
    },
    getGeographyLabel(nationalityType) {
      return nationalityType ? this.$t(`mybb.global.nationalityType.${nationalityType}`) : '- -'
    },
    isInternationalGeography(nationalityType) {
      return nationalityType === this.$const.nationalityType.INTERNATIONAL
    },
    hasOratorCategory(participant) {
      const version = this.$get(participant, 'gathering.categoryVersion', null)
      const category = Categories.forVersionAndCategoryId(version, participant.category)

      return category.tags.includes(Categories.constants.Tag.Orator)
    }
  }
}
</script>

<style lang="scss">
.HCPEvent {
  .Table {
    background-color: white;
    border-radius: 8px;
  }

  .v-data-table {
    thead th {
      font-size: 12px;
      font-weight: 900;
      text-transform: uppercase;
      color: var(--v-mybb-primary-base) !important;
    }

    tbody td {
      color: var(--v-mybb-primary-base);
    }
  }

  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }
}
</style>
