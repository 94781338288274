<template>
  <v-container v-if="expenseNote" fluid class="ExpenseNoteDetails pa-0">
    <v-row justify="space-between" class="px-3">
      <mybb-btn class="px-0" color="white" text @click="backToList" inner-icon="mdi-chevron-left">
        {{ $t('mybb.expenseNoteDetails.return') }}
      </mybb-btn>

      <v-chip v-if="expenseNote.status" :color="statusColor(expenseNote.status)" class="py-1 px-7">
        <mybb-text weight="semi-bold" class="white--text">
          {{ $t(`mybb.expenseNoteList.status.${expenseNote.status}`) }}
        </mybb-text>
      </v-chip>
    </v-row>

    <!-- Content container -->
    <v-row class="mb-5" no-gutters>
      <!-- Main content -->
      <v-col cols="8" class="pr-1">
        <v-container class="Container px-6 py-5">
          <v-row class="px-3" justify="space-between" align="center">
            <mybb-text size="16" weight="bold" class="text-uppercase">
              {{ expenseNoteHeader }}
            </mybb-text>

            <mybb-text size="10" weight="semi-bold">
              <v-icon color="mybb-grey-lighten1" class="mr-1" small>mdi-clock-time-five-outline</v-icon>
              {{ $t('mybb.expenseNoteDetails.lastUpdate', { date: formatedLastUpdateDate }) }}
            </mybb-text>
          </v-row>

          <v-row class="my-3">
            <v-col class="py-0">
              <v-divider />
            </v-col>
          </v-row>

          <!-- Data Table -->
          <v-data-table
            :expanded.sync="expanded"
            :headers="headers"
            :hide-default-footer="true"
            item-key="expenseNoteLineUuid"
            :items-per-page="50"
            :items="items"
            single-select
            show-expand
            :show-select="!isWindedUpGathering"
          >
            <!-- Headers -->
            <template v-for="header in headers" v-slot:[`header.${header.value}`]="{}">
              <mybb-text :key="header.text" size="12" weight="black" class="text-uppercase">
                {{ header.text }}
              </mybb-text>
            </template>
            <!-- Values -->
            <template v-slot:[`item.data-table-select`]="{ item }">
              <v-checkbox
                class="mt-0 pt-0"
                v-model="selected"
                :value="item.expenseNoteLineUuid"
                :disabled="!isSelectable(item)"
                dense
                hide-details
                color="mybb-primary-lighten1"
              />
            </template>
            <template v-slot:[`item.category`]="{ item }">
              <mybb-text weight="bold">{{ item.category }}</mybb-text>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <mybb-text>{{ item.date | dateFormat }}</mybb-text>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <mybb-text weight="bold">{{ item.price | price }}</mybb-text>
            </template>
            <template v-slot:[`item.file`]="{ item }">
              <mybb-btn
                v-if="item.file"
                icon
                @click="openFile(item.file)"
                color="mybb-primary-lighten1"
                inner-icon="mdi-file-download"
              />
              <mybb-text v-else>- -</mybb-text>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <mybb-btn
                v-if="isEditable"
                icon
                @click="openEditModal(item)"
                color="mybb-grey-lighten1"
                inner-icon="mdi-pencil"
              />
            </template>
            <!-- Expanded body -->
            <template v-slot:expanded-item="{ headers, item }">
              <!-- Checkbox column -->
              <td :colspan="headers.length" class="TableExpandContainer py-3">
                <!-- Description -->
                <div class="d-flex mb-5">
                  <mybb-text size="12" weight="black" class="text-uppercase mr-8 TableExpandItem Header">
                    {{ $t('mybb.expenseNoteDetails.headers.description') }}
                  </mybb-text>
                  <mybb-text class="TableExpandItem">
                    {{ item.description || '- -' }}
                  </mybb-text>
                </div>
                <!-- Comment -->
                <div class="d-flex">
                  <mybb-text size="12" weight="black" class="text-uppercase mr-8 TableExpandItem Header">
                    {{ $t('mybb.expenseNoteDetails.headers.comment') }}
                  </mybb-text>
                  <mybb-text class="TableExpandItem">
                    {{ item.comment || '- -' }}
                  </mybb-text>
                </div>
              </td>
            </template>
          </v-data-table>

          <!-- Table footer -->
          <v-row justify="end">
            <!-- Status -->
            <v-col md="7" cols="7">
              <!-- Validated -->
              <mybb-text v-if="isValidated" size="16" weight="bold" class="mybb-green--text pt-4">
                {{ validatedTitle(expenseNote.lastUpdateDate) }}
              </mybb-text>
              <!-- Refused -->
              <mybb-text v-else-if="isRefused" size="16" weight="bold" class="mybb-error--text pt-4">
                {{ refusedTitle(expenseNote.lastUpdateDate) }}
              </mybb-text>
            </v-col>

            <!-- Price summary -->
            <v-col md="5" cols="5">
              <div class="d-flex Price pt-4">
                <mybb-text size="16" weight="bold" class="flex-grow-1">
                  {{ $t('mybb.expenseNoteDetails.toRefund') }}
                </mybb-text>
                <mybb-text size="16" weight="bold">
                  <span class="mybb-primary-lighten1--text">{{ totalSelectedPrice | price }}</span>
                  /
                  {{ totalPrice | price }}
                </mybb-text>
              </div>
            </v-col>
          </v-row>

          <!-- Comments -->
          <div v-if="sortComments.length">
            <comment
              v-for="comment in sortComments"
              :key="comment.expenseNoteCommentUuid"
              :comment="comment"
              :categories="expenseNoteCategories"
              class="mt-6"
            />
          </div>

          <!-- Actions -->
          <v-row
            :justify="
              expenseNote.status !== $const.expenseNoteStatus.VALIDATED ||
              expenseNote.origin === $const.noteOrigin.INTERNAL
                ? 'space-between'
                : 'end'
            "
            class="mt-10 mb-4 px-3"
          >
            <!-- Arbitration -->
            <mybb-btn
              v-if="showArbitrationButton"
              :disabled="!canAskArbitration"
              color="mybb-warning"
              @click="askForArbitration"
            >
              {{ $t('mybb.expenseNoteDetails.askForArbitration') }}
            </mybb-btn>
            <!-- Refuse -->
            <mybb-btn
              v-if="showRefuse"
              color="mybb-error"
              @click="refuseNote"
              :inner-icon="isInternalNote ? null : 'mdi-close'"
              :disabled="isWindedUpGathering"
            >
              {{ isInternalNote ? $t('mybb.expenseNoteDetails.refuseInternal') : $t('mybb.expenseNoteDetails.refuse') }}
            </mybb-btn>

            <!-- Validate -->
            <mybb-btn
              v-if="showValidate"
              :disabled="!canValidate"
              color="mybb-success"
              @click="validateNote"
              inner-icon="mdi-check"
            >
              {{ $t('mybb.expenseNoteDetails.validate') }}
            </mybb-btn>
            <!-- Pay -->
            <mybb-btn
              v-if="showPay"
              color="mybb-success"
              @click="showConfirmPaymentModal = true"
              :disabled="isWindedUpGathering"
            >
              {{ $t('mybb.expenseNoteDetails.confirmPayment') }}
            </mybb-btn>
            <!-- Edit internal -->
            <mybb-btn v-if="showEdit" color="mybb-primary" @click="editInternalNote" :disabled="isWindedUpGathering">
              {{ $t('mybb.expenseNoteDetails.edit') }}
            </mybb-btn>
          </v-row>
        </v-container>
      </v-col>

      <!-- Side pannels -->
      <v-col cols="4" class="pl-2">
        <hcp-informations :fields="hcpFields" :hcp="hcpInformations" />
      </v-col>
    </v-row>

    <arbitration-modal v-model="showArbitrationModal" :note="expenseNote" :selected-lines="selected" />
    <validation-modal v-model="showValidationModal" :note="expenseNote" :selected-lines="selected" />
    <refusal-modal v-model="showRefusalModal" :note="expenseNote" />
    <confirm-payment-modal v-model="showConfirmPaymentModal" :note="expenseNote" />
    <edit-modal
      v-model="showEditModal"
      :note="expenseNote"
      :selected-line="editModalLine"
      @EditModal::update="update"
    />
  </v-container>
</template>

<script>
import dateFormat from 'date-fns/format'
import _findKey from 'lodash/findKey'
import _uniq from 'lodash/uniq'

import { file } from '@/mixins'

import hcpInformations from '@/components/mybb/ui/HcpInformations'
import Comment from '@/components/mybb/ui/Comment'
import { GET_EXPENSE_NOTE, GET_ALL_EXPENSE_NOTE_CATEGORIES } from '@/graphql/ExpenseNote/ExpenseNote'
import ArbitrationModal from '@/components/mybb/expenseNotes/ArbitrationModal'
import ValidationModal from '@/components/mybb/expenseNotes/ValidationModal'
import RefusalModal from '@/components/mybb/expenseNotes/RefusalModal'
import EditModal from '@/components/mybb/expenseNotes/EditModal'
import ConfirmPaymentModal from '@/components/mybb/expenseNotes/ConfirmPaymentModal'

export default {
  name: 'ExpenseNoteDetails',
  components: {
    hcpInformations,
    Comment,
    EditModal,
    ArbitrationModal,
    ValidationModal,
    RefusalModal,
    ConfirmPaymentModal
  },
  mixins: [file],
  data() {
    return {
      expanded: [],
      expenseNote: null,
      selected: [],
      editModalLine: '',
      showArbitrationModal: false,
      showConfirmPaymentModal: false,
      showValidationModal: false,
      showEditModal: false,
      showRefusalModal: false
    }
  },
  computed: {
    expenseNoteHeader() {
      if (!this.expenseNote || !this.expenseNote.participant) {
        return ''
      }
      const id = this.expenseNote.visualIdentifier ? `${this.expenseNote.visualIdentifier} -` : ''
      const hcpFullName = `${this.expenseNote.participant.firstName} ${this.expenseNote.participant.lastName} -`
      const date = this.expenseNote.createdAt ? dateFormat(new Date(this.expenseNote.createdAt), 'dd/MM/yy, HH:mm') : ''

      return `${id} ${hcpFullName} ${date}`
    },
    formatedLastUpdateDate() {
      return this.expenseNote.lastUpdateDate
        ? dateFormat(new Date(this.expenseNote.lastUpdateDate), 'dd/MM/yy, HH:mm')
        : ''
    },
    headers() {
      return [
        { sortable: false, text: this.$t('mybb.expenseNoteDetails.headers.category'), value: 'category' },
        {
          sortable: true,
          sort: (a, b) => {
            const date1 = new Date(a)
            const date2 = new Date(b)
            return date1 - date2
          },
          text: this.$t('mybb.expenseNoteDetails.headers.date'),
          value: 'date'
        },
        { sortable: false, text: this.$t('mybb.expenseNoteDetails.headers.price'), value: 'price' },
        {
          sortable: false,
          text: this.$t('mybb.expenseNoteDetails.headers.file'),
          value: 'file',
          width: '30px',
          align: 'center'
        },
        { sortable: false, text: '', value: 'edit', width: '28px', align: 'end' },
        { sortable: false, text: '', value: 'data-table-expand' }
      ]
    },
    isEditable() {
      const authorizedStatus = {
        admin: [this.$const.expenseNoteStatus.ARBITRATION, this.$const.expenseNoteStatus.TO_BE_PROCESSED],
        roc: [this.$const.expenseNoteStatus.ARBITRATION, this.$const.expenseNoteStatus.TO_BE_PROCESSED],
        mci: [this.$const.expenseNoteStatus.TO_BE_PROCESSED]
      }
      return this.$store.state.myUser.userTypes.some(userType => {
        return authorizedStatus[userType] && authorizedStatus[userType].includes(this.expenseNote.status)
      })
    },
    isRefused() {
      return this.expenseNote.status === this.$const.expenseNoteStatus.REFUSED
    },
    isValidated() {
      return this.expenseNote.status === this.$const.expenseNoteStatus.VALIDATED
    },
    items() {
      const lines = this.$get(this.expenseNote, 'lines', [])
      const noteStatus = this.$get(this.expenseNote, 'status', null)

      if (lines.length <= 0) return []

      return lines.map(line => {
        const price = (this.$get(line, 'price', 0) / 100).toFixed(2)

        return {
          category: this.$get(line, 'expenseNoteCategory.label', '--'),
          description: this.$get(line, 'description', '--'),
          date: new Date(this.$get(line, 'feeDate')),
          price,
          comment: this.$get(line, 'comment', '--'),
          file: this.$get(line, 'file', null),
          expenseNoteLineUuid: this.$get(line, 'expenseNoteLineUuid'),
          noteStatus,
          lineStatus: this.$get(line, 'status', null)
        }
      })
    },
    sortComments() {
      return this.expenseNote.comments
        ? Array.from(this.expenseNote.comments).sort((a, b) => new Date(b.date) - new Date(a.date))
        : []
    },
    totalPrice() {
      return this.items.reduce((total, item) => total + Number(item.price || 0), 0)
    },
    totalSelectedPrice() {
      // need uniq cause expenseNote is updated (so selected too) for some reason
      return _uniq(this.selected).reduce((total, lineUuid) => {
        const item = this.items.find(item => item.expenseNoteLineUuid === lineUuid)

        return total + Number(item.price || 0)
      }, 0)
    },
    showArbitrationButton() {
      const { TO_BE_PROCESSED } = this.$const.expenseNoteStatus
      const { MCI, ADMIN, SUPER_ADMIN } = this.$const.userType

      // Can only ask arbitration if it is MCI and it is to be processed
      return (
        (this.$store.state.myUser.userTypes.includes(MCI) ||
          this.$store.state.myUser.userTypes.includes(ADMIN) ||
          this.$store.state.myUser.userTypes.includes(SUPER_ADMIN)) &&
        this.expenseNote.status === TO_BE_PROCESSED
      )
    },
    canAskArbitration() {
      return this.selected.length < this.items.length && !this.isWindedUpGathering
    },
    showValidate() {
      const { ARBITRATION, TO_BE_PROCESSED } = this.$const.expenseNoteStatus
      const { ROC, ADMIN, SUPER_ADMIN, MCI } = this.$const.userType
      const { userTypes } = this.$store.state.myUser

      if (TO_BE_PROCESSED === this.expenseNote.status) {
        return (
          userTypes.includes(ROC) ||
          userTypes.includes(MCI) ||
          userTypes.includes(ADMIN) ||
          userTypes.includes(SUPER_ADMIN)
        )
      }

      if (ARBITRATION === this.expenseNote.status) {
        return userTypes.includes(ROC) || userTypes.includes(ADMIN) || userTypes.includes(SUPER_ADMIN)
      }

      return false
    },
    canValidate() {
      const { ARBITRATION } = this.$const.expenseNoteStatus

      if (this.expenseNote.status === ARBITRATION) {
        return this.selected.length > 0
      }

      return this.selected.length === this.items.length && !this.isWindedUpGathering
    },
    showRefuse() {
      const { ARBITRATION } = this.$const.expenseNoteStatus
      const { ROC, ADMIN, SUPER_ADMIN, MCI } = this.$const.userType
      const { userTypes } = this.$store.state.myUser

      if (this.expenseNote.status === this.$const.expenseNoteStatus.REFUSED) {
        return false
      }

      switch (this.expenseNote.origin) {
        case this.$const.noteOrigin.INTERNAL:
          return (
            userTypes.includes(MCI) ||
            userTypes.includes(ROC) ||
            userTypes.includes(ADMIN) ||
            userTypes.includes(SUPER_ADMIN)
          )

        case this.$const.noteOrigin.EXTERNAL_FRONT:
        default:
          // Only ROC can see the button if the status is [ARBITRATION]
          if (this.expenseNote.status !== ARBITRATION) return false

          return userTypes.includes(ROC) || userTypes.includes(ADMIN) || userTypes.includes(SUPER_ADMIN)
      }
    },
    showEdit() {
      const { status, origin } = this.expenseNote
      const { INTERNAL } = this.$const.noteOrigin
      const { VALIDATED } = this.$const.expenseNoteStatus

      return status === VALIDATED && origin === INTERNAL
    },
    showPay() {
      const { status } = this.expenseNote
      const { VALIDATED } = this.$const.expenseNoteStatus
      const { ROC, MCI, ADMIN, SUPER_ADMIN } = this.$const.userType
      const { userTypes } = this.$store.state.myUser

      if (status !== VALIDATED) return false

      if (this.isInternalNote) {
        return (
          userTypes.includes(ROC) ||
          userTypes.includes(MCI) ||
          userTypes.includes(ADMIN) ||
          userTypes.includes(SUPER_ADMIN)
        )
      }

      // ROC can't set external-origin note as payed
      return userTypes.includes(MCI) || userTypes.includes(ADMIN) || userTypes.includes(SUPER_ADMIN)
    },
    isInternalNote() {
      const { INTERNAL } = this.$const.noteOrigin

      return this.expenseNote.origin === INTERNAL
    },
    hcpFields() {
      return [
        'honorific',
        'lastName',
        'firstName',
        'birthDate',
        'emailAddress',
        'phoneNumber',
        'address',
        'postalCode',
        'city',
        'country',
        'target',
        'category',
        'lvcHcpFile',
        'bankFileStatementUuid',
        'bankStatement'
      ]
    },
    hcpInformations() {
      const participant = this.$get(this.expenseNote, 'participant', null)
      if (!participant) return null
      if (participant.healthCareProfessional) return participant.healthCareProfessional
      if (participant.manualParticipant)
        return {
          participants: [participant],
          salutation: participant.manualParticipant.title,
          lastName: participant.manualParticipant.lastName,
          firstName: participant.manualParticipant.firstName,
          emailAddress: participant.manualParticipant.email,
          phoneNumber: participant.manualParticipant.phoneNumber,
          category: participant.category,
          hcpAdditionalInformation: {
            address: participant.manualParticipant.address,
            bankStatement: participant.manualParticipant.bankStatement,
            birthDate: participant.manualParticipant.birthDate,
            trainCardName: participant.manualParticipant.sncfCard,
            trainCardExpirationDate: participant.manualParticipant.sncfExpirationDate,
            trainCardNumber: participant.manualParticipant.sncfNumber,
            planeSubscriptionName: participant.manualParticipant.afCard,
            planeSubscriptionExpirationDate: participant.manualParticipant.afExpirationDate,
            planeSubscriptionNumber: participant.manualParticipant.afNumber
          }
          // Not defined with manual creation fields
          // honorific: '',
          // postalCode: '',
          // city: '',
          // country: '',
          // target: '',
          // passportNumber: '',
          // idCard: '',
        }

      return null
    },
    isWindedUpGathering() {
      const gatheringStatus = this.$get(this.expenseNote, 'gathering.status', null)

      return gatheringStatus === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    expenseNote: {
      query: GET_EXPENSE_NOTE,
      variables() {
        return {
          expenseNoteUuid: this.$route.params.expenseNoteUuid
        }
      },
      result() {
        const lines = this.$get(this.expenseNote, 'lines', [])

        for (const line of this.$get(this.expenseNote, 'lines', [])) {
          if (
            [
              this.$const.expenseNoteLineStatus.VALIDATED,
              this.$const.expenseNoteLineStatus.VALIDATED_BESIDE_ARBITRATION
            ].includes(line.status)
          ) {
            this.selected.push(line.expenseNoteLineUuid)
          }
        }

        this.expenseNote.lines = lines.map(line => ({ ...line, file: this.formatBackendFile(line.file) }))
        this.expenseNote.comments = this.$get(this.expenseNote, 'comments', []).sort(
          (comment1, comment2) => new Date(comment2.date) - new Date(comment1.date)
        )
      }
    },
    expenseNoteCategories: {
      query: GET_ALL_EXPENSE_NOTE_CATEGORIES,
      update({ expenseNoteCategories }) {
        return (expenseNoteCategories || []).map(category => ({
          id: category.expenseNoteCategoryUuid,
          label: category.label
        }))
      }
    }
  },
  filters: {
    dateFormat(date) {
      return date ? dateFormat(new Date(date), 'dd/MM/yy') : ''
    },
    dateFormatWithHours(date) {
      return dateFormat(new Date(date), 'dd/MM/yy, HH:mm')
    },
    price(price) {
      return isNaN(price) ? '- -' : `${(+price).toFixed(2).replace('.', ',')}€`
    }
  },
  methods: {
    askForArbitration() {
      if (!this.canAskArbitration) return

      this.showArbitrationModal = true
    },
    backToList() {
      this.$router.push({
        name: 'ExpenseNoteList',
        params: {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      })
    },
    openEditModal(editModalLine) {
      this.editModalLine = editModalLine.expenseNoteLineUuid
      this.showEditModal = true
    },
    statusColor(status) {
      const key = _findKey(this.$const.expenseNoteStatus, value => value === status)
      return this.$const.expenseNoteColors[key]
    },
    validateNote() {
      if (!this.canValidate) return

      this.showValidationModal = true
    },
    refuseNote() {
      this.showRefusalModal = true
    },
    refusedTitle(rawDate) {
      const date = dateFormat(new Date(rawDate), 'dd/MM/yy')
      const hour = dateFormat(new Date(rawDate), 'HH:mm')
      return this.$t('mybb.expenseNoteDetails.refusedTitle', { date, hour })
    },
    isSelectable(item) {
      if (item && item.file) {
        const selectableStatus = [
          this.$const.expenseNoteStatus.ARBITRATION,
          this.$const.expenseNoteStatus.TO_BE_PROCESSED
        ]

        return selectableStatus.includes(item.noteStatus)
      }

      return false
    },
    update(updatedExpensseNote) {
      this.expenseNote = updatedExpensseNote
    },
    validatedTitle(rawDate) {
      const date = dateFormat(new Date(rawDate), 'dd/MM/yy')
      const hour = dateFormat(new Date(rawDate), 'HH:mm')
      return this.$t('mybb.expenseNoteDetails.validatedTitle', { date, hour })
    },
    editInternalNote() {
      return this.$router.push({
        name: 'ExpenseNoteForm',
        params: {
          expenseNoteUuid: this.expenseNote.expenseNoteUuid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ExpenseNoteDetails {
  * {
    color: var(--v-mybb-primary-sidebar-base);
  }

  .Container {
    background-color: white;
    border-radius: 8px;
  }

  .TableExpandContainer {
    padding-left: 80px; // Check box column width (64px) + default padding (16px)

    .TableExpandItem {
      display: inline-block;

      &.Header {
        width: 90px;
      }
    }
  }

  .Price {
    border-top: 1px solid var(--v-mybb-grey-base);
  }
}
</style>

<style lang="scss">
.ExpenseNoteDetails-Table thead th {
  // allow to change header divider color (no vuetify props)
  border-bottom-color: var(--v-mybb-grey-border-base) !important;
}
</style>
