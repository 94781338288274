<template>
  <modal v-model="modal" width="915" :title="t('title')">
    <template v-slot:text>
      <div class="mt-3">
        <mybb-text weight="semi-bold">{{ t('commentHeader') }}</mybb-text>
      </div>
      <v-divider class="my-2" />

      <!-- Comments -->
      <div class="mt-3" v-for="(line, index) in lines" :key="index">
        <mybb-text class="my-2" weight="bold">
          {{ getCategoryLabel(line.expenseNoteCategoryUuid) }} - {{ line.description }}
        </mybb-text>
        <mybb-textarea v-model="comments[index]" :label="t('labelComment')" rows="3" icon="mdi-comment-text" />
      </div>

      <!-- Comments -->
      <div class="my-3" v-for="(line, index) in deletedLines" :key="line.expenseNoteLineUuid">
        <mybb-text weight="bold" class="my-2">
          {{ getCategoryLabel(line.expenseNoteCategoryUuid) }} - {{ line.description }}
        </mybb-text>
        <mybb-textarea
          v-model="deletedLineComments[index]"
          :label="t('labelComment')"
          rows="3"
          icon="mdi-comment-text"
        />
      </div>

      <!-- Buttons -->
      <v-row class="mt-10" justify="center">
        <mybb-btn class="mr-10" color="mybb-grey" @click="close">
          {{ t('cancel') }}
        </mybb-btn>

        <mybb-btn color="mybb-success" :disabled="!canSubmit" :loading="loading" @click="submit">
          {{ t('confirm') }}
        </mybb-btn>
      </v-row>
    </template>
  </modal>
</template>

<script>
import { UPDATE_EXPENSE_NOTE_LINES } from '@/graphql/ExpenseNote/ExpenseNote'
import Modal from '@/components/mybb/Modal.vue'

export default {
  name: 'ExpenseNoteSaveModal',
  components: { Modal },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    participantUuid: {
      type: String,
      default: ''
    },
    lines: {
      type: Array,
      required: true
    },
    expenseNoteUuid: {
      type: String,
      default: ''
    },
    categories: {
      type: Array,
      default: () => []
    },
    originalLines: {
      type: Array,
      default: () => []
    },
    deletedLinesUuid: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      comments: [],
      deletedLineComments: [],
      loading: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)
      }
    },
    canSubmit() {
      return (
        this.lines.length === this.comments.length &&
        this.comments.every(Boolean) &&
        this.deletedLinesUuid.length === this.deletedLineComments.length &&
        this.deletedLineComments.every(Boolean)
      )
    },
    deletedLines() {
      return this.deletedLinesUuid.map(lineUuid =>
        this.originalLines.find(line => line.expenseNoteLineUuid === lineUuid)
      )
    },
    expenseNoteLineEntries() {
      const lineEntries = []

      for (const line of this.originalLines) {
        const lineIndex = this.lines.findIndex(l => l.expenseNoteLineUuid === line.expenseNoteLineUuid)
        const updatedLine = this.lines[lineIndex]
        const toDelete = this.deletedLinesUuid.some(uuid => line.expenseNoteLineUuid === uuid)
        const deleteCommentIndex = this.deletedLinesUuid.findIndex(uuid => line.expenseNoteLineUuid === uuid)

        lineEntries.push({
          line: {
            expenseNoteCategoryUuid: (updatedLine || line).expenseNoteCategoryUuid,
            expenseNoteLineUuid: line.expenseNoteLineUuid,
            feeDate: (updatedLine || line).feeDate,
            price: Math.floor((updatedLine || line).price * 100),
            description: (updatedLine || line).description,
            amountKilometers: (updatedLine || line).amountKilometers,
            departureAddress: (updatedLine || line).departureAddress,
            file: (updatedLine || line).file,
            comment: (updatedLine || line).comment,
            toDelete
          },
          comment: toDelete ? this.deletedLineComments[deleteCommentIndex] : this.comments[lineIndex]
        })
      }

      // Handle new lines
      for (const line of this.lines) {
        const alreadyDefined =
          this.originalLines.some(l => l.expenseNoteLineUuid === line.expenseNoteLineUuid) ||
          this.deletedLinesUuid.some(uuid => line && line.expenseNoteLineUuid === uuid)

        if (alreadyDefined) continue

        const lineIndex = this.lines.findIndex(l => l === line)

        lineEntries.push({
          line: {
            expenseNoteCategoryUuid: line.expenseNoteCategoryUuid,
            expenseNoteLineUuid: line.expenseNoteLineUuid,
            feeDate: line.feeDate,
            price: Math.floor(line.price * 100),
            description: line.description,
            amountKilometers: line.amountKilometers,
            departureAddress: line.departureAddress,
            file: line.file,
            comment: line.comment
          },
          comment: this.comments[lineIndex]
        })
      }

      return lineEntries
    }
  },
  methods: {
    getCategoryLabel(expenseNoteCategoryUuid) {
      const category = this.categories.find(c => c.expenseNoteCategoryUuid === expenseNoteCategoryUuid)
      return category ? category.label : '- -'
    },
    t(key, params) {
      return this.$t(`mybb.expenseNoteDetails.saveModal.${key}`, params)
    },
    close() {
      this.comments = Array(this.lines.length).fill('')
      this.deletedLineComments = Array(this.deletedLinesUuid.length).fill('')
      this.modal = false
    },
    async submit() {
      this.loading = true

      // Comments are included in each line entry
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_EXPENSE_NOTE_LINES,
          variables: {
            expenseNoteLineEntries: this.expenseNoteLineEntries,
            expenseNoteUuid: this.expenseNoteUuid
          }
        })

        this.$router.push({ name: 'ExpenseNoteDetails', params: { expenseNoteUuid: this.expenseNoteUuid } })
        this.close()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.comments = Array(this.lines.length).fill('')
    this.deletedLineComments = Array(this.deletedLinesUuid.length).fill('')
  }
}
</script>
