import gql from 'graphql-tag'

import { HCP_LIGHT_PRIMARY_DATA } from '../../Hcp/Hcp'

import { VSP_LIGHT } from '../fragments'

export const GET_VSP_PAGINATED = gql`
  query virtualSessionParticipants($virtualSessionUuid: GUID!, $search: String, $pagination: Pagination) {
    virtualSessionParticipants(virtualSessionUuid: $virtualSessionUuid, search: $search, pagination: $pagination) {
      total
      items {
        ...VirtualSessionParticipantLight
        healthCareProfessional {
          ...LightHealthCareProfessional
        }
      }
    }
  }
  ${VSP_LIGHT}
  ${HCP_LIGHT_PRIMARY_DATA}
`
