<template>
  <side-panel :title="t('sectionTitle')">
    <ul class="pa-0">
      <li v-for="info in informations" :key="info.key" class="HostingInformations-listElement">
        <mybb-text weight="semi-bold" class="mybb-grey-lighten1--text HostingInformations-listElement-header">
          {{ info.label }}
        </mybb-text>

        <mybb-text weight="semi-bold">{{ info.value }}</mybb-text>
      </li>
    </ul>
  </side-panel>
</template>

<script>
import format from 'date-fns/format'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

import { GET_HOSTING_CONFIGURATION } from '@/graphql/Hosting'
import { PARTICIPANT_OVERNIGHTS } from '@/graphql/Participant'
import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'

import SidePanel from './SidePanel'

const DEFAULT_CONTEXT = 'default'
const HOSTING_FORM_CONTEXT = 'HostingForm'

export default {
  name: 'HostingInformation',
  components: { SidePanel },
  props: {
    context: {
      type: String,
      required: false,
      default: DEFAULT_CONTEXT,
      validator: prop => [DEFAULT_CONTEXT, HOSTING_FORM_CONTEXT].includes(prop)
    },
    gatheringUuid: {
      type: String,
      required: true
    },
    participantUuid: {
      type: String
    }
  },
  computed: {
    informations() {
      const eveArrival = this.$get(this.hostingConfiguration, 'eveArrival', null)

      let beginDate
      let endDate

      if (this.participant) {
        const nights = this.$get(this.participant, 'overnightStays', [])
          .map(stay => new Date(stay.date))
          .sort()

        beginDate = nights[0]
        endDate = nights[nights.length - 1]
      } else if (this.gathering) {
        beginDate = new Date(this.gathering.beginDate)
        endDate = new Date(this.gathering.endDate)

        if (beginDate && eveArrival) {
          beginDate.setDate(beginDate.getDate() - 1)
        }
      }

      const informations = [
        {
          label: this.t('arrivalDate'),
          value: beginDate ? this.formatDate(beginDate) : '- -',
          key: 'arrivalDate'
        },
        {
          label: this.t('departureDate'),
          value: endDate ? this.formatDate(endDate) : '- -',
          key: 'departureDate'
        }
      ]

      if (this.context === HOSTING_FORM_CONTEXT) {
        const chooseNightsAllowed = this.$get(this.hostingConfiguration, 'chooseNightsAllowed', null)

        informations.push(
          {
            label: this.t('nightNumber'),
            value: differenceInCalendarDays(endDate, beginDate) || '- -',
            key: 'nightNumber'
          },
          {
            label: this.t('eveArrival'),
            value: eveArrival ? this.t('eveArrivalYes') : this.t('eveArrivalNo'),
            key: 'eveArrival'
          },
          {
            label: this.t('chooseNightsAllowed'),
            value: chooseNightsAllowed ? this.t('chooseNightsAllowedYes') : this.t('chooseNightsAllowedNo'),
            key: 'chooseNightsAllowed'
          }
        )
      }

      return informations
    }
  },
  apollo: {
    hostingConfiguration: {
      query: GET_HOSTING_CONFIGURATION,
      variables() {
        return {
          gatheringUuid: this.gatheringUuid
        }
      },
      skip() {
        return !this.gatheringUuid
      },
      update({ hostingConfigurationFromGatheringUuid }) {
        return hostingConfigurationFromGatheringUuid
      }
    },
    participant: {
      query: PARTICIPANT_OVERNIGHTS,
      variables() {
        return {
          participantUuid: this.participantUuid
        }
      },
      skip() {
        return !this.participantUuid
      }
    },
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      }
    }
  },
  methods: {
    t(key, params) {
      if (this.context === DEFAULT_CONTEXT) {
        return this.$t(`mybb.hostingInformations.${key}`, params)
      }

      return this.$t(`mybb.hostingInformations.context.${this.context}.${key}`, params)
    },
    formatDate(date) {
      if (!date) return null

      let _date = date

      if (typeof date === 'string') {
        const [year, month, day] = date
        _date = new Date()
        _date.setFullYear(year, month - 1, day)
      }

      return format(new Date(_date), 'dd/MM/yy')
    }
  }
}
</script>

<style lang="scss" scoped>
.HostingInformations-listElement {
  list-style: none;
  text-align: right;
  margin: 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e1e3;

  &:last-child {
    border-bottom: none;
  }

  .HostingInformations-listElement-header {
    float: left;
  }
}
</style>
