<template>
  <fixed-footer :visible="selected.length > 0" :label="t('label', { selected: selected.length })">
    <mybb-btn color="mybb-warning" class="mr-4" @click="subscription.modal = true">
      {{ t('handleSubscription') }}
    </mybb-btn>

    <mybb-btn color="mybb-success" @click="presence.modal = true">
      {{ t('handlePresence') }}
    </mybb-btn>

    <!-- Subscription modal -->
    <modal v-model="subscriptionModal" :title="t('subscription.title')">
      <mybb-radios v-model="subscription.status" :items="subscriptionItems" row dense />

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="closeSubscription">
          {{ t('subscription.cancel') }}
        </mybb-btn>
        <mybb-btn
          :disabled="!subscription.status"
          :loading="subscription.loading"
          color="mybb-success"
          @click="setSubscription"
        >
          {{ t('subscription.confirm') }}
        </mybb-btn>
      </div>
    </modal>

    <!-- Presence modal -->
    <modal v-model="presence.modal" :title="t('presence.title')">
      <mybb-text class="mb-4">{{ t('presence.text') }}</mybb-text>

      <mybb-radios v-model="presence.status" :items="presenceItems" row dense />

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="closePresence">
          {{ t('presence.cancel') }}
        </mybb-btn>
        <mybb-btn :disabled="!presence.status" :loading="presence.loading" color="mybb-success" @click="setPresence">
          {{ t('presence.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </fixed-footer>
</template>

<script>
import { SET_VSP_SUBSCRIPTION, SET_VSP_PRESENCE } from '@/graphql/VirtualSession'

import FixedFooter from '../ui/FixedFooter'
import Modal from '../Modal'

export default {
  name: 'VirtualSessionParticipantFooter',
  components: { FixedFooter, Modal },
  props: {
    selected: {
      type: Array
    },
    virtualSession: {
      type: Object
    }
  },
  data() {
    return {
      presence: {
        modal: false,
        status: null,
        loading: false
      },
      subscription: {
        modal: false,
        status: this.$const.virtualSession.subscription.UNKNOWN,
        loading: false
      }
    }
  },
  computed: {
    subscriptionItems() {
      const { UNKNOWN, REFUSED, SUBSCRIBED } = this.$const.virtualSession.subscription

      return [
        {
          label: this.t(`subscription.options.${UNKNOWN}`),
          value: UNKNOWN
        },
        {
          label: this.t(`subscription.options.${SUBSCRIBED}`),
          value: SUBSCRIBED
        },
        {
          label: this.t(`subscription.options.${REFUSED}`),
          value: REFUSED
        }
      ]
    },
    presenceItems() {
      const { ABSENT, PRESENT } = this.$const.virtualSession.presence

      return [
        {
          label: this.t(`presence.options.${PRESENT}`),
          value: PRESENT
        },
        {
          label: this.t(`presence.options.${ABSENT}`),
          value: ABSENT
        }
      ]
    },
    virtualSessionParticipantUuids() {
      return (this.selected || [])
        .map(participant => participant && participant.virtualSessionParticipantUuid)
        .filter(Boolean)
    },
    subscriptionModal: {
      get() {
        return this.subscription.modal
      },
      set(value) {
        this.subscription.modal = value

        if (!value) {
          this.subscription = {
            modal: false,
            status: this.$const.virtualSession.subscription.UNKNOWN,
            loading: false
          }
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`virtualSession.participants.footer.${key}`, params)
    },
    /**
     * Subscription
     */
    closeSubscription() {
      this.subscription = {
        modal: false,
        status: this.$const.virtualSession.subscription.UNKNOWN,
        loading: false
      }
    },
    async setSubscription() {
      if (!this.subscription.status) return
      if (this.subscription.loading) return

      this.subscription.loading = true

      try {
        await this.$apollo.mutate({
          mutation: SET_VSP_SUBSCRIPTION,
          variables: {
            virtualSessionParticipantUuids: this.virtualSessionParticipantUuids,
            subscription: this.subscription.status
          }
        })

        this.$emit('refresh')
        this.closeSubscription()
      } catch (error) {
        console.error(error)
      } finally {
        this.subscription.loading = false
      }
    },
    /**
     * Presence
     */
    closePresence() {
      this.presence = {
        modal: false,
        status: null,
        loading: false
      }
    },
    async setPresence() {
      if (!this.presence.status) return
      if (this.presence.loading) return

      this.presence.loading = true

      try {
        await this.$apollo.mutate({
          mutation: SET_VSP_PRESENCE,
          variables: {
            virtualSessionParticipantUuids: this.virtualSessionParticipantUuids,
            presence: this.presence.status
          }
        })

        this.$emit('refresh')
        this.closePresence()
      } catch (error) {
        console.error(error)
      } finally {
        this.presence.loading = false
      }
    }
  }
}
</script>
