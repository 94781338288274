<template>
  <div class="InputFile">
    <mybb-text v-if="title" size="12" class="mybb-grey-lighten1--text mb-2">{{ title }}</mybb-text>
    <div
      @click="handleClick"
      class="InputFile-button justify-start pa-4 text-none"
      :class="{ 'InputFile-button--empty': !hasContent }"
    >
      <v-row class="pa-0 flex-nowrap" align="center">
        <v-icon :color="disabled ? 'mybb-grey-lighten1' : 'mybb-primary-darken1'" left>mdi-file-download</v-icon>
        <mybb-text
          size="12"
          weight="bold"
          :class="disabled ? 'mybb-grey-lighten1--text' : 'mybb-primary-lighten1--text'"
          class="text-truncate d-flex flex-grow-1"
        >
          {{ (file && file.name) || $t('mybb.inputFile.defaultTitle') }}
        </mybb-text>

        <div v-if="hasContent && !disabled" class="d-flex">
          <mybb-btn
            v-if="!hideEdit"
            @click.stop="customEdit ? $emit('change', {}) : $refs.inputFile.click()"
            icon
            small
            height="26"
            inner-icon="mdi-pencil"
            color="mybb-grey-lighten1"
          />
          <mybb-btn
            v-if="!hideDelete"
            @click.stop="remove"
            icon
            small
            height="26"
            inner-icon="mdi-delete-forever"
            color="mybb-grey-lighten1"
          />
        </div>

        <v-tooltip v-if="$slots.tooltip" color="mybb-primary-lighten1" max-width="400" top allow-overflow>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="mybb-primary-lighten1" v-on="on">mdi-information</v-icon>
          </template>

          <mybb-text class="white--text">
            <slot name="tooltip" />
          </mybb-text>
        </v-tooltip>
      </v-row>
    </div>

    <input ref="inputFile" type="file" :accept="accept" class="d-none" @change="convertToFile" />

    <modal v-model="deletionModal" :title="$t('mybb.inputFile.modal.title')">
      <template v-slot:actions>
        <mybb-btn @click="deletionModal = false" color="mybb-grey">
          {{ $t('mybb.inputFile.modal.cancel') }}
        </mybb-btn>
        <mybb-btn @click="deleteFile" class="ml-12" color="mybb-error">
          {{ $t('mybb.inputFile.modal.confirm') }}
        </mybb-btn>
      </template>
    </modal>
  </div>
</template>

<script>
import { toBase64 } from '@/services/file'
import Modal from './Modal'

const DOWNLOAD_EXTENSIONS = ['.doc', '.docx']

export default {
  name: 'InputFile',
  components: { Modal },
  inheritAttrs: false,

  model: {
    prop: 'file',
    event: 'change'
  },

  props: {
    accept: {
      type: String,
      required: true
    },
    deleteConfirmation: {
      type: Boolean,
      required: false,
      default: true
    },
    file: {
      type: Object,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean
    },
    customEdit: {
      type: Boolean
    },
    hideDelete: {
      type: Boolean
    },
    hideEdit: {
      type: Boolean
    }
  },

  data() {
    return {
      document: null,
      deletionModal: false
    }
  },

  computed: {
    avaiblesExtensions() {
      return this.accept.split(',').map(ext => ext.replace('.', ''))
    },
    hasContent() {
      return this.file && this.file.content
    }
  },

  methods: {
    handleClick() {
      if (this.hasContent) {
        this.openTab()
      } else if (!this.disabled) {
        this.$refs.inputFile.click()
      }
    },
    assertFileExtension(file) {
      const fullname = file.name
      const extension = fullname.split('.').pop()

      return this.avaiblesExtensions.includes(extension)
    },
    async convertToFile() {
      const file = this.$refs.inputFile.files[0]

      if (file) {
        this.updateFile(file)
      }
    },
    async openTab() {
      if (!this.hasContent) return

      const fetchResult = await fetch(this.file.content)
      const blob = await fetchResult.blob()
      const fileUrl = URL.createObjectURL(blob)

      const fakeLink = document.createElement('a')
      fakeLink.setAttribute('href', fileUrl)
      fakeLink.setAttribute('target', '_blank')

      if (DOWNLOAD_EXTENSIONS.some(ext => this.file.name.endsWith(ext))) {
        fakeLink.setAttribute('download', this.file.name)
      }

      fakeLink.click()

      URL.revokeObjectURL(fileUrl)
    },
    remove(event) {
      event.stopPropagation()

      if (this.customEdit) {
        this.$emit('delete')
      } else if (this.deleteConfirmation) {
        this.deletionModal = true
      } else {
        this.deleteFile()
      }
    },
    deleteFile() {
      this.$emit('change', null)
      this.$refs.inputFile.value = null
      this.deletionModal = false
    },
    async updateFile(file) {
      if (!file) {
        this.$emit('change', null)
        return
      }

      if (!this.assertFileExtension(file)) {
        console.warn('Refusing file: extension not accepted')
        return
      }

      const base64 = await toBase64(file)
      this.$emit('change', { content: base64, name: file.name })
      this.document = base64
    }
  }
}
</script>

<style lang="scss" scoped>
.InputFile {
  width: 100%;

  & > * {
    cursor: pointer;
  }
}

.InputFile-button {
  border: 1px dashed #bfbfbf;
  border-radius: 8px;

  &--empty {
    cursor: pointer;
    transition: background-color;
    transition-duration: 0.3s;
    &:hover {
      background-color: darken(white, 10);
    }
  }
}
</style>
