<template>
  <modal v-if="note" v-model="modal" :title="t('title')">
    <template v-slot:text>
      <div class="text-center" v-if="!isInternal">
        <div class="mb-4">
          <mybb-text>{{ t('text', hcp) }}</mybb-text>
        </div>
        <div>
          <mybb-text weight="bold">{{ t('highlight') }}</mybb-text>
        </div>
      </div>
    </template>

    <template v-slot:actions>
      <mybb-btn @click="modal = false" class="mr-12" color="mybb-grey">
        {{ t('cancel') }}
      </mybb-btn>

      <mybb-btn @click="$emit('confirm')" color="mybb-success">
        {{ t('confirm') }}
      </mybb-btn>
    </template>
  </modal>
</template>

<script>
import Modal from '../Modal'

export default {
  name: 'SendTransportModal',
  components: { Modal },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    note: {
      type: Object,
      required: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    hcp() {
      return this.$get(this.note, 'participant', {})
    },
    isInternal() {
      return this.$get(this.note, 'origin') === this.$const.noteOrigin.INTERNAL
    }
  },
  methods: {
    t(key, params) {
      const status = this.$get(this.note, 'status', null)

      return this.$t(`mybb.transportNotePage.sendModal.${status}.${key}`, params)
    }
  }
}
</script>
