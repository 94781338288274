<template>
  <div>
    <fixed-footer :visible="selected.length > 0" :label="t('title', { selected: selected.length })">
      <mybb-btn v-if="canRemoveParticipants" color="mybb-error" class="mr-6" @click="removeParticipantsModal = true">
        {{ t('removeParticipants') }}
      </mybb-btn>
      <mybb-btn
        v-if="canSendEmail"
        color="mybb-primary-lighten1"
        background="white"
        inner-icon="mdi-email"
        class="mr-3"
        icon
        @click="emailModal = true"
      />
      <mybb-btn
        @click="downloadLvcExport"
        inner-icon="mdi-file-download"
        color="white"
        class="mybb-primary-lighten1--text mr-3"
        :loading="loading"
      >
        {{ t('exportLvc') }}
      </mybb-btn>
      <mybb-btn
        v-cy="$cy.gathering.participant.setCategory.button"
        color="mybb-blue"
        class="mr-6"
        @click="categoryModal = true"
        >{{ t('setCategory') }}</mybb-btn
      >
      <mybb-btn
        v-cy="$cy.gathering.participant.setSubscription.button"
        color="mybb-warning"
        class="mr-6"
        @click="subscriptionModal = true"
      >
        {{ t('handleSubscription') }}
      </mybb-btn>
      <mybb-btn
        v-cy="$cy.gathering.participant.setPresence.button"
        color="mybb-success"
        @click="presenceModal = true"
        >{{ t('presence') }}</mybb-btn
      >
    </fixed-footer>

    <!-- Presence modal -->
    <modal v-model="presenceModal" :title="dt('presenceModal.title')">
      <mybb-text class="my-2">{{ dt('presenceModal.text') }}</mybb-text>

      <mybb-text v-if="presence === $const.presence.noShow" class="mb-2 mybb-warning--text">
        <v-icon color="mybb-warning" size="16"> mdi-alert </v-icon>
        {{ dt('presenceModal.warning') }}
      </mybb-text>

      <v-radio-group class="mt-0" v-model="presence" hide-details dense row>
        <v-radio
          v-cy="$cy.gathering.participant.setPresence.present"
          :value="$const.presence.present"
          color="mybb-primary-lighten1"
        >
          <mybb-text slot="label">{{ dt('presenceModal.PRESENT') }}</mybb-text>
        </v-radio>
        <v-radio
          v-cy="$cy.gathering.participant.setPresence.absent"
          :value="$const.presence.absent"
          color="mybb-primary-lighten1"
        >
          <mybb-text slot="label">{{ dt('presenceModal.ABSENT') }}</mybb-text>
        </v-radio>
        <v-radio
          v-if="isCongress"
          v-cy="$cy.gathering.participant.setPresence.noShow"
          :value="$const.presence.noShow"
          color="mybb-primary-lighten1"
        >
          <mybb-text slot="label">{{ dt('presenceModal.NO_SHOW') }}</mybb-text>
        </v-radio>
      </v-radio-group>

      <!-- Buttons -->
      <v-row class="mt-10" justify="center">
        <mybb-btn class="mr-10" color="mybb-grey" @click="closePresenceModal">
          {{ dt('presenceModal.cancel') }}
        </mybb-btn>

        <mybb-btn
          v-cy="$cy.gathering.participant.setPresence.submit"
          :disabled="!presence"
          inner-icon="mdi-check"
          color="mybb-success"
          @click="validatePresence"
        >
          {{ dt('presenceModal.confirm') }}
        </mybb-btn>
      </v-row>
    </modal>

    <!-- Category modal -->
    <modal v-model="categoryModal" :title="t('categoryModal.title')">
      <mybb-text class="my-2">{{ t('categoryModal.text') }}</mybb-text>

      <v-row>
        <v-col cols="8">
          <mybb-select v-cy="$cy.gathering.participant.setCategory.select" v-model="category" :items="categoryItems">
            <template v-slot:item="{ item, on, attrs }">
              <mybb-text v-on="on" v-bind="attrs">
                <v-icon v-if="item.additional" color="mybb-warning">mdi-alert</v-icon>
                {{ item.text }}
              </mybb-text>
            </template>
          </mybb-select>
        </v-col>
      </v-row>

      <mybb-textarea
        v-if="selectedCategoryIsAdditional"
        v-model="categoryComment"
        :label="t('categoryModal.comment')"
        class="mt-3"
        icon="mdi-message"
      />

      <!-- Buttons -->
      <v-row class="mt-10" justify="center">
        <mybb-btn class="mr-10" color="mybb-grey" @click="closeCategoryModal">
          {{ t('categoryModal.cancel') }}
        </mybb-btn>

        <mybb-btn
          v-cy="$cy.gathering.participant.setCategory.submit"
          :disabled="!canSubmitCategory"
          :loading="loading"
          inner-icon="mdi-check"
          color="mybb-success"
          @click="submitCategory"
        >
          {{ t('categoryModal.confirm') }}
        </mybb-btn>
      </v-row>
    </modal>

    <remove-participants-modal
      v-model="removeParticipantsModal"
      :selected-participants="selected"
      :gathering="gathering"
      :unremovable-participants="unremovableParticipants"
      @refresh="$emit('refresh')"
    />

    <participant-subscription-modal
      v-model="subscriptionModal"
      :selected-participants="selected"
      @refresh="$emit('refresh')"
    />

    <participant-email-modal
      v-model="emailModal"
      :selected-participants="selected"
      :gathering="gathering"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import axios from 'axios'
import Categories from 'mybb-categories'
import { SET_PARTICIPANT_CATEGORY, SET_PRESENT, SET_ABSENT, SET_NO_SHOW } from '@/graphql/Participant'
import { gatheringStatus } from '@/const/shared'

import FixedFooter from '../ui/FixedFooter'
import Modal from '../Modal'
import ParticipantSubscriptionModal from './ParticipantSubscriptionModal'
import ParticipantEmailModal from './ParticipantEmailModal'
import RemoveParticipantsModal from './RemoveParticipantsModal'

export default {
  name: 'ParticipantFooter',
  components: { FixedFooter, Modal, ParticipantSubscriptionModal, ParticipantEmailModal, RemoveParticipantsModal },
  props: {
    selected: {
      type: Array,
      default: () => [],
      validator(prop) {
        return Array.isArray(prop) && prop.every(item => item.hasOwnProperty('participantUuid'))
      }
    },
    isCongress: {
      type: Boolean,
      required: true
    },
    gathering: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      subscriptionModal: false,
      presenceModal: false,
      presence: null,
      categoryModal: false,
      category: null,
      categoryComment: null,
      emailModal: false,
      removeParticipantsModal: false
    }
  },
  computed: {
    gatheringCategories() {
      if (!this.gathering) return null
      const eventType = Categories.getEventTypeFromGathering(this.gathering)
      const categories = Categories.forEventType(eventType, this.gathering.categoryVersion)

      return categories.map(category => ({ value: category.id, text: category.label }))
    },
    additionalCategories() {
      if (!this.gathering || !this.gatheringCategories) return null

      const eventTypeCategoryIds = this.gatheringCategories.map(item => item.value)

      return Categories.forVersion(this.gathering.categoryVersion)
        .filter(category => !eventTypeCategoryIds.includes(category.id))
        .map(category => ({ value: category.id, text: category.label, additional: true }))
    },
    categoryItems() {
      if (!this.gatheringCategories) return []

      const categories = this.canUseAdditionalCategories
        ? this.gatheringCategories.concat(this.additionalCategories)
        : this.gatheringCategories

      const noCategory = Categories.noCategoryForVersion(this.gathering.categoryVersion)

      if (
        ![gatheringStatus.PUBLISHED_FRONT, gatheringStatus.CLOSED, gatheringStatus.WINDED_UP].includes(
          this.gathering.status
        )
      ) {
        return [{ value: noCategory.id, text: noCategory.label }].concat(
          categories.filter(category => category.value !== noCategory.id)
        )
      } else {
        return categories.filter(category => category.value !== noCategory.id)
      }
    },
    canRemoveParticipants() {
      if (!this.gathering) return false

      const { userTypes } = this.$store.state.myUser
      const { ADMIN, SUPER_ADMIN, ROC, MCI } = this.$const.userType

      return (
        userTypes.includes(ADMIN) ||
        userTypes.includes(SUPER_ADMIN) ||
        userTypes.includes(ROC) ||
        userTypes.includes(MCI)
      )
    },
    canUseAdditionalCategories() {
      const { userTypes } = this.$store.state.myUser
      const { ADMIN, SUPER_ADMIN, ROC } = this.$const.userType

      return userTypes.includes(ADMIN) || userTypes.includes(SUPER_ADMIN) || userTypes.includes(ROC)
    },
    selectedCategoryIsAdditional() {
      if (!this.category || !this.additionalCategories) return false

      return this.additionalCategories.map(item => item.value).includes(this.category)
    },
    canSubmitCategory() {
      if (!this.category) return false

      return this.selectedCategoryIsAdditional ? Boolean(this.categoryComment) : true
    },
    canSendEmail() {
      if (!this.gathering) return false

      const { PUBLISHED_FRONT, CLOSED } = this.$const.gatheringStatus

      return [PUBLISHED_FRONT, CLOSED].includes(this.gathering.status)
    },
    unremovableParticipants() {
      const { solicitationStatus } = this.$const

      const unrem = this.selected.filter(
        participant =>
          participant.sollicitationStatus === solicitationStatus.SENT ||
          (participant.solicitation && participant.solicitation.status === solicitationStatus.SENT)
      )

      return unrem
    }
  },
  methods: {
    async downloadLvcExport() {
      this.loadingDownloadExport = true
      const selected = this.selected.map(({ participantUuid }) => participantUuid)
      const query = selected.length > 0 ? { participantUuids: selected } : {}
      const job = axios.post(`/lvc/export/${this.$get(this.$route, 'params.gatheringUuid')}`, query, {
        responseType: 'blob',
        headers: {
          'x-impersonificated-user': this.$store.state.impersonificatedUser
        }
      })

      this.$bus.$emit('extract', {
        job,
        label: this.t(`status.label`),
        fileName: null,
        status: {
          pending: this.t('status.pending'),
          success: this.t('status.done'),
          error: this.t('status.error')
        }
      })
    },
    t(key, params) {
      return this.$t(`mybb.AttendeeList.footer.${key}`, params)
    },
    dt(key, params) {
      return this.t(`${this.isCongress ? 'congress' : 'biogenEvent'}.${key}`, params)
    },
    async validatePresence() {
      const { present, absent, noShow } = this.$const.presence

      const mutation =
        this.presence === present
          ? SET_PRESENT
          : this.presence === absent
          ? SET_ABSENT
          : this.presence === noShow && this.isCongress
          ? SET_NO_SHOW
          : null

      if (!mutation) {
        throw new Error(`No mutation defined for the current presence & gathering combination`)
      }

      this.presenceModal = false

      await this.$apollo.mutate({
        mutation,
        variables: {
          participantUuids: this.selected.map(({ participantUuid }) => participantUuid)
        }
      })

      this.$emit('refresh')
      this.presence = null
    },
    closePresenceModal() {
      this.presenceModal = false
      this.presence = null
    },
    closeCategoryModal() {
      this.category = null
      this.categoryComment = null
      this.categoryModal = false
    },
    async submitCategory() {
      if (this.loading) return
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: SET_PARTICIPANT_CATEGORY,
          variables: {
            gatheringUuid: this.$route.params.gatheringUuid,
            participantUuids: this.selected.map(({ participantUuid }) => participantUuid),
            category: this.category,
            comment: this.categoryComment
          }
        })

        this.$emit('refresh')
        this.closeCategoryModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
