var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ParticipantStaffAdd pb-8"},[_c('v-row',{staticClass:"ml-0"},[_c('mybb-btn',{staticClass:"px-0 py-0",attrs:{"text":"","color":"white","inner-icon":"mdi-chevron-left"},on:{"click":_vm.backToList}},[_vm._v(" "+_vm._s(_vm.t('return'))+" ")])],1),_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('mybb-text-field',{attrs:{"background-color":"white","placeholder":_vm.t('searchPlaceholder'),"icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-container',{staticClass:"Container px-6 py-5",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"DataTable",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"server-items-length":_vm.serverItemsLength,"item-key":"userUuid","hide-default-footer":"","show-select":""},on:{"update:sort-by":_vm.updateSortBy,"update:sort-desc":_vm.updateSortDesc},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{staticClass:"text-uppercase",attrs:{"weight":"bold"}},[_vm._v(_vm._s(value))])]}},{key:"item.firstName",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(value))])]}},{key:"item.category",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.categoryColors[value],"dark":""}},[_vm._v(" "+_vm._s(_vm.$t(("mybb.categories." + value)))+" ")])]}},{key:"item.userTypes",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.zone",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.businessUnit",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.buConfig[value.label].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.buConfig[value.label].label)+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('fixed-footer',{attrs:{"visible":_vm.selected.length > 0,"label":_vm.t('footer.label', { selected: _vm.allSelected ? _vm.serverItemsLength : _vm.selected.length })}},[_c('mybb-btn',{attrs:{"color":"mybb-success","inner-icon":"mdi-check"},on:{"click":function($event){_vm.invitationModal = true}}},[_vm._v(" "+_vm._s(_vm.t('footer.invite'))+" ")])],1),_c('modal',{attrs:{"title":_vm.t('invitationModal.title')},model:{value:(_vm.invitationModal),callback:function ($$v) {_vm.invitationModal=$$v},expression:"invitationModal"}},[_c('mybb-text',[_vm._v(" "+_vm._s(_vm.t('invitationModal.text', { selected: _vm.allSelected ? _vm.serverItemsLength : _vm.selected.length }))+" ")]),_c('div',{staticClass:"d-flex justify-center mt-10"},[_c('mybb-btn',{staticClass:"mr-10",attrs:{"color":"mybb-grey"},on:{"click":function($event){_vm.invitationModal = false}}},[_vm._v(" "+_vm._s(_vm.t('invitationModal.cancel'))+" ")]),_c('mybb-btn',{attrs:{"loading":_vm.inviting,"color":"mybb-success"},on:{"click":_vm.invite}},[_vm._v(_vm._s(_vm.t('invitationModal.confirm')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }