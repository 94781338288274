<template>
  <modal v-model="modal" :title="t('title')">
    <template v-slot:text>
      <!-- Lines recap -->
      <div class="mb-3">
        <mybb-text weight="bold">{{ t('header') }}</mybb-text>
      </div>

      <div v-for="line in refusedLines" :key="line.transportNoteLineUuid" class="my-2">
        <mybb-text>{{ line }}</mybb-text>
      </div>

      <!-- Comment -->
      <div class="mt-4">
        <mybb-text weight="bold">{{ t('commentHeader') }}</mybb-text>
      </div>

      <mybb-textarea v-model="comment" :label="t('commentLabel')" rows="3" class="mt-3" icon="mdi-comment-text" />

      <!-- Buttons -->
      <v-row class="mt-10" justify="center">
        <mybb-btn class="mr-10" color="mybb-primary" @click="modal = false">
          {{ t('cancel') }}
        </mybb-btn>

        <mybb-btn color="mybb-warning" :disabled="!comment" :loading="loading" @click="submit">
          {{ t('confirm') }}
        </mybb-btn>
      </v-row>
    </template>
  </modal>
</template>

<script>
import dateFormat from 'date-fns/format'

import { ASK_ARBITRATION } from '@/graphql/ExpenseNote/ExpenseNote'

import Modal from '../Modal.vue'

export default {
  name: 'ArbitrationModal',
  components: { Modal },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    note: {
      type: Object,
      required: true
    },
    selectedLines: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      comment: '',
      loading: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)

        // Side effect to clear the comment field after the modal is closed
        this.$nextTick(() => {
          if (!value) {
            this.comment = ''
          }
        })
      }
    },
    refusedLines() {
      const refusedLines = this.$get(this.note, 'lines', []).filter(
        line => !this.selectedLines.includes(line.expenseNoteLineUuid)
      )

      return refusedLines.map(line => {
        const category = this.$get(line, 'expenseNoteCategory.label', 'N/A')
        const description = line.description
        const feeDate = dateFormat(new Date(this.$get(line, 'feeDate')), 'dd/MM/yy')
        const price = `${Number(this.$get(line, 'price', 0) / 100).toFixed(2)}€`

        return `${category} - ${description} - ${feeDate} - ${price}`
      })
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.expenseNoteDetails.arbitrationModal.${key}`, params)
    },
    async submit() {
      const { expenseNoteUuid } = this.note

      if (this.loading) return
      this.loading = true
      const validatedExpenseNoteLineUuids = this.$get(this.note, 'lines', []).filter(
        line => this.selectedLines.includes(line.expenseNoteLineUuid)
      ).map(line => line.expenseNoteLineUuid)

      await this.$apollo.mutate({
        mutation: ASK_ARBITRATION,
        variables: { expenseNoteUuid, comment: this.comment, validatedExpenseNoteLineUuids }
      })

      this.$router.push({
        name: 'ExpenseNoteList',
        params: {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      })
    }
  }
}
</script>
