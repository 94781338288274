<template>
  <div class="HcpList mt-5 pb-8" ref="HcpList">
    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="4">
        <mybb-text-field
          background-color="white"
          :placeholder="$t('mybb.hcpList.searchPlaceholder')"
          icon="mdi-magnify"
          :value="searchText"
          @input="onSearch"
        />
      </v-col>
    </v-row>

    <Loader v-if="!items" color="white" />
    <v-data-table
      v-else
      :headers="headers"
      item-key="healthCareProfessionalUuid"
      :items="items"
      :loading="loading"
      :server-items-length="serverItemsLength"
      :options.sync="options"
      :items-per-page="tableSettings.itemsPerPage"
      :footer-props="tableSettings.footerProps"
      @update:page="$vuetify.goTo($refs.HcpList)"
      class="HcpList-table px-4 pt-3"
    >
      <template v-slot:[`item.alert`]="{ item }">
        <v-tooltip color="mybb-warning" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="hasWarning(item.healthCareProfessionalUuid)"
              color="mybb-warning"
              size="20"
              v-bind="attrs"
              v-on="on"
            >
              mdi-alert
            </v-icon>
          </template>
          <span>{{ missedGatheringForHcps[item.healthCareProfessionalUuid] }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.lastName`]="{ value: lastName }">
        <mybb-text weight="bold">{{ lastName }}</mybb-text>
      </template>
      <template v-slot:[`item.firstName`]="{ value: firstName }">
        <mybb-text weight="bold">{{ firstName }}</mybb-text>
      </template>
      <template v-slot:[`item.mslResponsible`]="{ value }">
        <v-icon v-if="value" color="mybb-blue">mdi-check</v-icon>
      </template>
      <template v-slot:[`item.target`]="{ value }">
        <span>{{ value }}</span>
      </template>
      <template v-slot:[`item.healthCareProfessionalUuid`]="{ value: healthCareProfessionalUuid }">
        <router-link
          class="HcpList-tableLink"
          :to="{
            name: 'HCPPage',
            params: { hcpUuid: healthCareProfessionalUuid }
          }"
        >
          <v-btn icon class="table-icon">
            <v-icon color="mybb-grey-lighten1">mdi-eye</v-icon>
          </v-btn>
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Loader from '@/components/congrex/tabs/LoaderTab'

import shared from '@/const/my-bb'
import { SEARCH_HCPS_FOR_DATATABLE } from '@/graphql/Hcp/Hcp'

export default {
  name: 'HcpList',

  components: { Loader },

  data() {
    return {
      healthCareProfessionals: [],
      searchText: '',
      filtersOpen: false,
      filters: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: shared.tableSettings.itemsPerPage
      },
      serverItemsLength: 0,
      sort: {
        field: null,
        desc: false
      },
      missedGatheringForHcps: {}
    }
  },

  computed: {
    selectedFilters() {
      const selectedFilters = {}
      this.filters.forEach(filter => (selectedFilters[filter.key] = filter.value))
      return selectedFilters
    },
    tableSettings() {
      return shared.tableSettings
    },
    headers() {
      return [
        { sortable: false, text: '', value: 'alert' },
        { sortable: false, text: this.$t('mybb.hcpList.tableHeaders.lastName'), value: 'lastName' },
        { sortable: false, text: this.$t('mybb.hcpList.tableHeaders.firstName'), value: 'firstName' },
        { sortable: false, text: this.$t('mybb.hcpList.tableHeaders.type'), value: 'type' },
        { sortable: false, text: this.$t('mybb.hcpList.tableHeaders.title'), value: 'title' },
        { sortable: false, text: this.$t('mybb.hcpList.tableHeaders.postalCode'), value: 'postalCode' },
        { sortable: false, text: this.$t('mybb.hcpList.tableHeaders.city'), value: 'city' },
        { sortable: true, text: this.$t('mybb.hcpList.tableHeaders.target'), value: 'target' },
        { sortable: true, text: this.$t('mybb.hcpList.tableHeaders.zone'), value: 'zone.label' },
        { sortable: false, text: this.$t('mybb.hcpList.tableHeaders.mslResponsible'), value: 'mslResponsible' },
        { sortable: false, text: '', value: 'healthCareProfessionalUuid' }
      ]
    },
    isFilterSelected() {
      return this.filters.some(filter => filter.value)
    },
    items() {
      return this.healthCareProfessionals.map(hcp => ({
        ...hcp,
        target: this.computeTarget(hcp)
      }))
    }
  },

  apollo: {
    healthCareProfessionals: {
      debounce: 300,
      query: SEARCH_HCPS_FOR_DATATABLE,
      variables() {
        const vars = {
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage
        }
        if (this.searchText) {
          vars.search = this.searchText
        }
        if (this.sort.field) {
          vars.sort = this.sort
        }

        return vars
      },
      update({ searchHcpForHcpTable }) {
        return searchHcpForHcpTable.hcps || []
      },
      result({ data }) {
        this.loading = false
        this.serverItemsLength = this.$get(data, 'searchHcpForHcpTable.total', 0)
        this.missedGatheringForHcps = this.$get(data, 'searchHcpForHcpTable.missedGatheringForHcps', {})
      }
    }
  },

  methods: {
    computeTarget(item) {
      const businessUnit = this.$get(item, 'businessUnit', null)

      if (!businessUnit) return null

      const targetBu = this.$const.targetBu[businessUnit.label]

      if (!targetBu) return null

      return `${businessUnit.externalId}_${this.$get(item, [targetBu])}`
    },
    hasWarning(hcpUuid) {
      return Object.keys(this.missedGatheringForHcps).includes(hcpUuid)
    },
    onSearch(text) {
      this.searchText = text
      this.loading = true
    }
  },

  watch: {
    options() {
      this.loading = true

      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.sort.field = this.options.sortBy[0]
        this.sort.desc = this.options.sortDesc[0]
      } else {
        this.sort.field = null
        this.sort.desc = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.HcpList-table {
  border-radius: 8px;
}

.HcpList-tableLink {
  text-decoration: none;
}
</style>

<style lang="scss">
.HcpList {
  .v-data-table thead th {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--v-mybb-primary-base) !important;
  }

  .v-data-table tbody td {
    color: var(--v-mybb-primary-base);
  }

  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }
}
</style>
