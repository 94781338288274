<template>
  <mybb-btn
    v-if="!hidden"
    :loading="!virtualSession || loading"
    :color="statusColor"
    :disabled="buttonDisabled"
    @click="onClick"
  >
    {{ status }}
  </mybb-btn>
</template>

<script>
import { schema } from '@/validations/virtualSession'

export default {
  name: 'VirtualSessionStatusButton',
  props: {
    virtualSession: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    status() {
      if (!this.virtualSession) return null

      return this.t(`label.${this.virtualSession.status || this.$const.virtualSession.status.DRAFT}`)
    },
    statusColor() {
      if (!this.virtualSession) return 'mybb-grey-ligthen1'

      const { DRAFT, PUBLISHED, CLOSED, WINDED_UP } = this.$const.virtualSession.status

      switch (this.virtualSession.status) {
        case WINDED_UP:
          return 'mybb-success'
        case PUBLISHED:
          return 'mybb-grey'
        case CLOSED:
          return 'mybb-error'
        case DRAFT:
        default:
          return 'mybb-blue'
      }
    },
    hidden() {
      const { CLOSED, WINDED_UP } = this.$const.virtualSession.status

      if (!this.virtualSession) return true

      return [CLOSED, WINDED_UP].includes(this.virtualSession.status)
    },
    buttonDisabled() {
      if (this.disabled) return true
      if (!this.virtualSession) return true

      if (!this.virtualSession.status || this.virtualSession.status === this.$const.virtualSession.status.DRAFT) {
        try {
          schema.validateSync(this.virtualSession)
        } catch (error) {
          return true
        }
      }

      return false
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`virtualSession.nextStatusButton.${key}`, params)
    },
    onClick() {
      const { DRAFT, PUBLISHED, CLOSED, WINDED_UP } = this.$const.virtualSession.status

      const statusMap = {
        [DRAFT]: PUBLISHED,
        [PUBLISHED]: CLOSED,
        [CLOSED]: WINDED_UP
      }

      this.$emit('click', statusMap[this.virtualSession.status] || DRAFT)
    }
  }
}
</script>
