<template>
  <side-panel :title="t('title')" only-open>
    <!-- Name & status -->
    <v-row>
      <v-col cols="6" md="4">
        <mybb-text size="12" class="d-block mybb-text-lighten1--text">{{ t('name') }}</mybb-text>
        <mybb-text>{{ name }}</mybb-text>
      </v-col>

      <v-col cols="6" md="4">
        <mybb-text size="12" class="d-block mybb-text-lighten1--text">{{ t('status') }}</mybb-text>
        <virtual-session-status-chip :status="status" />
      </v-col>
    </v-row>

    <!-- Description -->
    <v-row>
      <v-col cols="12">
        <mybb-text size="12" class="d-block mybb-text-lighten1--text">{{ t('description') }}</mybb-text>
        <mybb-text>{{ description }}</mybb-text>
      </v-col>
    </v-row>

    <!-- Temporality -->
    <v-row>
      <v-col cols="6" md="4">
        <mybb-text size="12" class="d-block mybb-text-lighten1--text">{{ t('beginTemporality') }}</mybb-text>
        <mybb-text>{{ beginTemporality }}</mybb-text>
      </v-col>

      <v-col cols="6" md="4">
        <mybb-text size="12" class="d-block mybb-text-lighten1--text">{{ t('endTemporality') }}</mybb-text>
        <mybb-text>{{ endTemporality }}</mybb-text>
      </v-col>
    </v-row>

    <!-- Business unit -->
    <v-row>
      <v-col>
        <mybb-text size="12" class="d-block mybb-text-lighten1--text">{{ t('businessUnit') }}</mybb-text>
        <v-chip v-if="businessUnit" class="ma-1" :color="businessUnit.color" dark>
          <span>{{ businessUnit.label }}</span>
        </v-chip>
      </v-col>
    </v-row>

    <!-- Team -->
    <v-row>
      <v-col cols="6" md="4">
        <mybb-text size="12" class="d-block mybb-text-lighten1--text">{{ t('roc') }}</mybb-text>
        <mybb-text>{{ roc }}</mybb-text>
      </v-col>

      <v-col cols="6" md="4">
        <mybb-text size="12" class="d-block mybb-text-lighten1--text">{{ t('cp') }}</mybb-text>
        <mybb-text>{{ cp }}</mybb-text>
      </v-col>
    </v-row>
  </side-panel>
</template>

<script>
import format from 'date-fns/format'
import _capitalize from 'lodash/capitalize'

import MYBB from '@/const/my-bb'
import SidePanel from '@/components/mybb/ui/SidePanel'

import VirtualSessionStatusChip from './VirtualSessionStatusChip'

export default {
  name: 'VirtualSessionTile',
  components: { SidePanel, VirtualSessionStatusChip },
  props: {
    virtualSession: {
      type: Object
    }
  },
  computed: {
    name() {
      return this.$get(this.virtualSession, 'name')
    },
    status() {
      return this.$get(this.virtualSession, 'status')
    },
    description() {
      return this.$get(this.virtualSession, 'description')
    },
    beginTemporality() {
      const date = this.$get(this.virtualSession, 'beginDate')
      const time = this.$get(this.virtualSession, 'startHour')

      return this.formatDate(date, time)
    },
    endTemporality() {
      const date = this.$get(this.virtualSession, 'endDate')
      const time = this.$get(this.virtualSession, 'endHour')

      return this.formatDate(date, time)
    },
    businessUnit() {
      const businessUnit = this.$get(this.virtualSession, 'businessUnit')

      return {
        businessUnitUuid: businessUnit.businessUnitUuid,
        color: MYBB.bu[businessUnit.label].color,
        label: MYBB.bu[businessUnit.label].label
      }
    },
    roc() {
      const roc = this.$get(this.virtualSession, 'roc')

      return this.formatUser(roc)
    },
    cp() {
      const cp = this.$get(this.virtualSession, 'cp')

      return this.formatUser(cp)
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`virtualSession.details.tile.${key}`, params)
    },
    formatDate(date, time) {
      if (!date) return null

      const fullDate = new Date(date)

      if (time) {
        const [hour, minute] = time.split(':').map(Number)
        fullDate.setHours(hour, minute, 0, 0)

        return format(fullDate, 'dd/MM/yy - HH:mm')
      }

      return format(fullDate, 'dd/MM/yy')
    },
    formatUser(user) {
      if (!user) return null

      const lastName = this.$get(user, 'lastName', '').toUpperCase()
      const firstName = _capitalize(this.$get(user, 'firstName', '').toLowerCase())

      return `${lastName} ${firstName}`
    }
  }
}
</script>
