<template>
  <side-panel :title="t('title')" :only-open="onlyOpen">
    <template v-if="gathering">
      <v-row class="my-1">
        <v-col cols="3">
          <span class="TeamTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('rocLabel') }}</mybb-text>
          </span>
          <mybb-text>
            {{ getUser($const.gatheringUserType.ROC, false) }}
          </mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="TeamTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('rocBackupLabel') }}</mybb-text>
          </span>
          <mybb-text>
            {{ getUser($const.gatheringUserType.ROC, true) }}
          </mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="TeamTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('mciLabel') }}</mybb-text>
          </span>
          <mybb-text>
            {{ getUser($const.gatheringUserType.MCI, false) }}
          </mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="TeamTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('mciBackupLabel') }}</mybb-text>
          </span>
          <mybb-text>
            {{ getUser($const.gatheringUserType.MCI, true) }}
          </mybb-text>
        </v-col>
      </v-row>
    </template>
  </side-panel>
</template>

<script>
import SidePanel from '@/components/mybb/ui/SidePanel'

export default {
  name: 'TeamTile',
  components: { SidePanel },
  props: {
    gathering: {
      type: Object,
      default: () => {}
    },
    onlyOpen: {
      type: Boolean
    },
    canValidateSection: {
      type: Boolean
    }
  },
  methods: {
    getUser(role, isBackup) {
      if (!this.gathering || (this.gathering && !Array.isArray(this.gathering.users))) return '- -'
      const user = this.gathering.users.find(user => user.gatheringUserType === role && user.isBackup === isBackup)
      return user ? `${user.user.firstName} ${user.user.lastName}` : '- -'
    },
    t(key, params) {
      return this.$t(`mybb.gatheringDetails.team.${key}`, params)
    }
  }
}
</script>

<style lang="scss" scoped>
.TeamTileLabel {
  line-height: 12px;
  display: block;
}
</style>
