import { string, object } from 'yup'

const schema = object()
  .noUnknown()
  .shape({
    name: string().required(),
    content: string().required(),
    fileUuid: string().nullable()
  })

export default schema
