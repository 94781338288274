<template>
  <status-information-tile
    :title="t('title')"
    :informations="informations"
    :color="color"
    :date="$get(informationStatus, 'date')"
  >
    <mybb-text :class="`${color}--text`" size="16" weight="bold">
      {{ status }}
    </mybb-text>
  </status-information-tile>
</template>

<script>
import dateCompareAsc from 'date-fns/compareAsc'

import StatusInformationTile from '../../ui/StatusInformationTile'

export default {
  name: 'SolicitationTile',
  components: { StatusInformationTile },
  props: {
    participant: Object,
    informationStatus: Object,
    gathering: Object
  },
  computed: {
    congressUuid() {
      return this.$get(this.gathering, 'congress.congressUuid')
    },
    solicitation() {
      if (!this.congressUuid) return null

      const solicitations = this.$get(this.participant, 'healthCareProfessional.solicitations', []).filter(
        solicitation => solicitation.congressUuid === this.congressUuid
      )

      solicitations.sort((s1, s2) => dateCompareAsc(new Date(s1.updatedAt), new Date(s2.updatedAt)))

      return solicitations[0]
    },
    informations() {
      return this.solicitation ? this.t('informations', this.solicitation.initiator) : null
    },
    color() {
      if (!this.solicitation) return 'mybb-grey-lighten1'

      return 'mybb-primary-lighten1'
    },
    status() {
      return this.solicitation ? this.$t(`solicitation-status.${this.solicitation.status}`) : '- -'
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.synthesisPanel.solicitation.${key}`, params)
    }
  }
}
</script>
