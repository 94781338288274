<template>
  <LoaderTab v-if="!participant || !gathering" />
  <div v-else v-cy="$cy.participant.gathering.container">
    <v-row justify="space-between" class="mb-3 px-3">
      <mybb-btn
        text
        color="white"
        class="px-0"
        inner-icon="mdi-chevron-left"
        @click="$router.push({ name: 'HCPEvent' })"
      >
        {{ t('back') }}
      </mybb-btn>

      <mybb-btn
        color="white"
        class="mybb-primary-lighten1--text"
        inner-icon="mdi-plus"
        width="220"
        @click="commentModal = true"
      >
        {{ t('addComment') }}
      </mybb-btn>
    </v-row>

    <gathering-panel :gathering="gathering" :participant="participant" class="mb-5" />

    <synthesis-panel :gathering="gathering" :participant="participant" class="mb-5" />

    <activities-panel :gathering="gathering" :participant="participant" class="mb-5" />

    <advantages-panel :gathering="gathering" :participant="participant" class="mb-5" />

    <documents-panel :participant="participant" class="mb-5" @refresh="refresh" />

    <comments-panel :participant="participant" />

    <modal v-model="modal" :title="t('commentModal.title')">
      <mybb-text>{{ t('commentModal.text') }}</mybb-text>

      <mybb-textarea v-model="comment" :label="t('commentModal.commentLabel')" icon="mdi-comment-text" class="my-4" />

      <input-file v-model="file" :label="t('commentModal.fileLabel')" accept=".pdf,.jpg,.png" />

      <div class="mt-10 text-center">
        <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
          {{ t('commentModal.cancel') }}
        </mybb-btn>

        <mybb-btn :disabled="!comment" :loading="loading" color="mybb-success" @click="createComment">
          {{ t('commentModal.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import GatheringPanel from '@/components/mybb/hcpParticipant/GatheringPanel'
import SynthesisPanel from '@/components/mybb/hcpParticipant/SynthesisPanel'
import ActivitiesPanel from '@/components/mybb/hcpParticipant/ActivitiesPanel'
import AdvantagesPanel from '@/components/mybb/hcpParticipant/AdvantagesPanel'
import DocumentsPanel from '@/components/mybb/hcpParticipant/DocumentsPanel'
import CommentsPanel from '@/components/mybb/hcpParticipant/CommentsPanel'
import Modal from '@/components/mybb/Modal'
import InputFile from '@/components/mybb/InputFile'
import LoaderTab from '@/components/congrex/tabs/LoaderTab'

import { PARTICIPANT_FOR_GATHERING_AND_HCP, CREATE_PARTICIPANT_COMMENT } from '@/graphql/Participant'
import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'

export default {
  name: 'HcpEventDetail',
  components: {
    LoaderTab,
    GatheringPanel,
    SynthesisPanel,
    ActivitiesPanel,
    AdvantagesPanel,
    DocumentsPanel,
    CommentsPanel,
    Modal,
    InputFile
  },
  data() {
    return {
      loading: false,
      commentModal: false,
      comment: '',
      file: null
    }
  },
  computed: {
    modal: {
      get() {
        return this.commentModal
      },
      set(value) {
        this.commentModal = value

        if (!value) {
          this.$nextTick(() => {
            this.comment = ''
            this.file = null
          })
        }
      }
    }
  },
  apollo: {
    participant: {
      query: PARTICIPANT_FOR_GATHERING_AND_HCP,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid,
          healthCareProfessionalUuid: this.$route.params.hcpUuid
        }
      },
      update({ healthCareProfessionalParticipantForGathering }) {
        return healthCareProfessionalParticipantForGathering
      }
    },
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.${key}`, params)
    },
    refresh() {
      this.$apollo.queries.participant.refetch()
    },
    async createComment() {
      if (this.loading) return
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: CREATE_PARTICIPANT_COMMENT,
          variables: {
            participantUuid: this.participant.participantUuid,
            comment: this.comment,
            file: this.file
          }
        })

        this.modal = false

        await this.$apollo.queries.participant.refetch()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
