import gql from 'graphql-tag'

export const TRANSFER_CONFIGURATION = gql`
  fragment TransferConfiguration on TransferConfiguration {
    backInformations
    comment
    goInformations
  }
`

export const TRANSFER = gql`
  fragment Transfer on Transfer {
    transferUuid
    participantUuid
    participant {
      category
      firstName
      lastName
      presence
    }
    hasTransport
    hasOther
    totalPrice
  }
`

export const TRANSFER_CONFIGURATION_FROM_GATHERING = gql`
  query transferConfigurationForGathering($gatheringUuid: GUID!) {
    transferConfigurationForGathering(gatheringUuid: $gatheringUuid) {
      ...TransferConfiguration
    }
  }
  ${TRANSFER_CONFIGURATION}
`

export const SET_TRANSFER_CONFIGURATION = gql`
  mutation setTransferConfiguration(
    $gatheringUuid: GUID!
    $configuration: TransferConfigurationInput!
    $notify: Boolean
  ) {
    setTransferConfiguration(gatheringUuid: $gatheringUuid, configuration: $configuration, notify: $notify) {
      ...TransferConfiguration
    }
  }
  ${TRANSFER_CONFIGURATION}
`

export const TRANSFERS_FOR_GATHERING = gql`
  query transfersForGathering($gatheringUuid: GUID!) {
    transfersForGathering(gatheringUuid: $gatheringUuid) {
      ...Transfer
    }
  }
  ${TRANSFER}
`

export const DELETE_TRANSFERS = gql`
  mutation deleteTransfers($transferUuids: [GUID!]!) {
    deleteTransfers(transferUuids: $transferUuids)
  }
`
