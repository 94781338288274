<template>
  <div v-if="gathering || virtualSession" class="pb-8 my-4">
    <div class="NavHead">
      <router-link :to="{ name: 'EventListMyBB' }" class="NavBreadcrumbBase">
        {{ this.$t('mybb.eventPage.breadcrumbBase') }}
      </router-link>
      <v-icon style="color: white; display: inline-table">mdi-chevron-right</v-icon>
      <a class="NavEventName">{{ name }}</a>
    </div>
    <div class="mt-3 nav" id="nav-participants">
      <div class="nav-left">
        <div class="pb-4 NavLinkContainer">
          <span v-for="(link, index) of links" :key="`${link.label}-${index}`">
            <!-- Remove the [link.route] condition when all routes are plugged with the v-else-if -->
            <router-link
              v-if="link.show && link.route && !link.isDisabled"
              :to="link.route"
              class="mx-4 pb-3 NavLink"
              :class="link.isHighlighted ? 'router-link-exact-active' : ''"
              v-cy="$cy.gathering.header(link.label)"
            >
              {{ link.label }}
            </router-link>
            <span v-else-if="link.show" class="mx-4 pb-3 NavLink" :class="{ NavDisabled: link.isDisabled }">
              {{ link.label }}
            </span>
          </span>
        </div>
      </div>
      <div class="nav-right"></div>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { GET_GATHERING_INFO } from '@/graphql/Gatherings/GetGathering'
import { GET_LIGHT_VIRTUAL_SESSION } from '@/graphql/VirtualSession'

export default {
  name: 'EventPage',
  data() {
    return {
      scrollPosY: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollPosY = window.scrollY
    })
  },
  computed: {
    links() {
      if (this.gathering) return this.gatheringLinks
      if (this.virtualSession) return this.sessionLinks

      return []
    },
    gatheringLinks() {
      return [
        {
          label: this.t('informations'),
          route: { name: 'GatheringDetails', params: { gatheringUuid: this.$route.params.gatheringUuid } },
          show: true,
          isHighlighted: this.highlightRoute('Gathering')
        },
        {
          label: this.t('guests'),
          route: { name: 'AttendeeList', params: { gatheringUuid: this.$route.params.gatheringUuid } },
          show: true,
          isHighlighted: this.highlightRoute('Attendee') || this.highlightRoute('Participant')
        },
        {
          label: this.t('activities'),
          route: { name: 'ActivityList', params: { gatheringUuid: this.$route.params.gatheringUuid } },
          show: true,
          isHighlighted: this.highlightRoute('Activity')
        },
        {
          label: this.t('hosting'),
          route: { name: 'HostingList', params: { gatheringUuid: this.$route.params.gatheringUuid } },
          show: this.gathering.isPhysical,
          isHighlighted: this.highlightRoute('Hosting')
        },
        {
          label: this.t('transport'),
          route: { name: 'TransportNoteList', params: { gatheringUuid: this.$route.params.gatheringUuid } },
          show: this.gathering.isPhysical,
          isHighlighted: this.highlightRoute('TransportNote')
        },
        {
          label: this.t('transfer'),
          route: { name: 'TransferList', params: { gatheringUuid: this.$route.params.gatheringUuid } },
          show: this.gathering.isPhysical,
          isHighlighted: this.highlightRoute('Transfer')
        },
        {
          label: this.t('expenseNotes'),
          route: { name: 'ExpenseNoteList', params: { gatheringUuid: this.$route.params.gatheringUuid } },
          show: this.gathering.isPhysical,
          isHighlighted: this.highlightRoute('ExpenseNote')
        },
        {
          label: this.t('subscriptionForm'),
          route: { name: 'SubscriptionForm', params: { gatheringUuid: this.$route.params.gatheringUuid } },
          show: true,
          isHightlighted: this.highlightRoute('Subscription')
        }
      ]
    },
    sessionLinks() {
      return [
        {
          label: this.t('informations'),
          route: {
            name: 'VirtualSessionDetails',
            params: { virtualSessionUuid: this.$route.params.virtualSessionUuid }
          },
          show: true,
          isHighlighted: this.highlightRoute('Gathering')
        },
        {
          label: this.t('guests'),
          route: {
            name: 'VirtualSessionAttendeeList',
            params: { virtualSessionUuid: this.$route.params.virtualSessionUuid }
          },
          show: true,
          isHighlighted: this.highlightRoute('Attendee') || this.highlightRoute('Participant')
        }
      ]
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    },
    name() {
      if (this.gathering) return this.gathering.name
      if (this.virtualSession) return this.virtualSession.name
      return null
    }
  },
  apollo: {
    gathering: {
      query: GET_GATHERING_INFO,
      error() {
        return this.$router.push({ name: 'EventListMyBB' })
      },
      skip() {
        return !this.$route.params.gatheringUuid
      },
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    },
    virtualSession: {
      query: GET_LIGHT_VIRTUAL_SESSION,
      skip() {
        return !this.$route.params.virtualSessionUuid
      },
      variables() {
        return {
          virtualSessionUuid: this.$route.params.virtualSessionUuid
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.eventPage.navLinks.${key}`, params)
    },
    highlightRoute(prefixRouteName) {
      return this.$route.name.startsWith(prefixRouteName)
    }
  }
}
</script>

<style lang="scss" scoped>
.NavLink {
  border-bottom: 3px solid transparent;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  transition: border 0.3s;
}

.NavDisabled {
  color: var(--v-mybb-grey-lighten1-base);
}

.NavLink:not(.NavDisabled):hover,
.NavLink:not(.NavDisabled):focus,
.router-link-exact-active:not(.NavDisabled) {
  border-bottom: 3px solid white;
  font-weight: 700;
  text-shadow: 0 0 0.1px #ffffff, 0 0 0.1px #ffffff;
}

.NavHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;

  & .v-icon {
    font-weight: 300;
    margin-right: 4px;
    margin-left: 4px;
  }
}

.NavBreadcrumbBase {
  font-weight: 300;
  text-decoration: none;
  color: #ffffff;
}

.NavEventName {
  font-size: 14px;
  font-weight: 900;
  color: #ffffff;
  text-transform: uppercase;
}

.NavLinkContainer {
  display: inline;

  & :first-child {
    & .NavLink {
      margin-left: 0 !important;
    }
  }
}

.nav {
  display: flex;
  height: auto;
  width: 100%;
}

.nav-right {
  float: right;
}

.nav-left {
  float: left;
  display: flex;
}
</style>
