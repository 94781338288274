<template>
  <modal v-model="modal" :title="t('title', { firstName, lastName })">
    <mybb-text weight="bold" class="mb-3">{{ t('text') }}</mybb-text>

    <div>
      <v-checkbox
        v-for="night of nights"
        :key="night"
        v-model="selectedDates"
        :value="night"
        :disabled="isDisabled(night)"
        color="mybb-primary-lighten1"
        class="mt-0 mr-7 d-inline-block"
        multiple
        dense
        hide-details
      >
        <template v-slot:label>
          <mybb-text class="Checkbox-Label">{{ night | date }}</mybb-text>
        </template>
      </v-checkbox>
    </div>

    <div v-if="showWarningMessage" class="my-3">
      <v-icon color="warning" class="mr-2">mdi-alert</v-icon>
      <mybb-text class="mybb-warning--text">{{ t('warning') }}</mybb-text>
    </div>

    <div v-if="showWarningPostDepartureMessage" class="my-3">
      <v-icon color="warning" class="mr-2">mdi-alert</v-icon>
      <mybb-text class="mybb-warning--text">{{ t('warningPostNightEvent') }}</mybb-text>
    </div>

    <mybb-textarea v-model="comment" :label="t('comment')" class="mt-3" inner-icon="mdi-comment-text" rows="3" />

    <div v-if="isPublished" class="mt-2">
      <mybb-text weight="bold" class="mb-2 d-block">{{ t('notifyText') }}</mybb-text>
    </div>

    <div class="d-flex justify-center mt-10">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">{{ t('cancel') }}</mybb-btn>
      <mybb-btn
        :loading="saving"
        :disabled="disabled"
        color="mybb-success"
        inner-icon="mdi-content-save"
        @click="saveOvernightStays"
      >
        {{ t('confirm') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import dateFormat from 'date-fns/format'

import { SAVE_OVERNIGHT_STAYS } from '@/graphql/Hosting'

import Modal from '../Modal'

export default {
  name: 'EditHostingParticipantModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    configuration: {
      type: Object,
      validator(prop) {
        return (
          !prop ||
          (prop &&
            typeof prop.eveArrival === 'boolean' &&
            (!prop.eveArrival || (prop.eveArrival && typeof prop.chooseEveArrivalAllowed === 'boolean')) &&
            typeof prop.chooseNightsAllowed === 'boolean')
        )
      }
    },
    participants: {
      type: Object,
      validator(prop) {
        return (
          !prop ||
          (prop &&
            Object.values(prop).every(
              item =>
                item.hasOwnProperty('participantUuid') &&
                item.hasOwnProperty('lastName') &&
                item.hasOwnProperty('firstName')
            ))
        )
      }
    },
    hostingUuid: {
      type: String
    },
    participantUuid: {
      type: String
    },
    gathering: {
      type: Object,
      validator(prop) {
        return prop && prop.beginDate && prop.endDate
      }
    }
  },
  data() {
    return {
      saving: false,
      comment: '',
      selectedDates: []
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.$nextTick(() => {
            this.comment = ''
          })
        }
      }
    },
    disabled() {
      return !this.comment
    },
    nights() {
      if (!this.gathering) return []

      const nights = []
      const date = new Date(this.gathering.beginDate)
      const beginDate = date.toISOString().slice(0, 10)
      const endDate = new Date(this.gathering.endDate).toISOString().slice(0, 10)
      const eveArrival = this.configuration ? this.configuration.eveArrival : false
      const postDeparture = this.configuration ? this.configuration.postDeparture : false

      if (endDate === beginDate && !eveArrival) return [beginDate]

      if (eveArrival) {
        date.setDate(date.getDate() - 1)
      }

      while (date.toISOString().slice(0, 10) !== endDate) {
        nights.push(date.toISOString().slice(0, 10))
        date.setDate(date.getDate() + 1)
      }

      if (postDeparture) {
        nights.push(endDate)
      }

      return nights
    },
    participant() {
      if (!this.participants) return null
      if (!this.participantUuid) return null

      return this.participants[this.participantUuid]
    },
    lastName() {
      return this.participant ? (this.participant.lastName || '').toUpperCase() : ''
    },
    firstName() {
      return this.participant ? this.participant.firstName : ''
    },
    showWarningMessage() {
      const canChangeExtraNight = !this.isDisabled(this.preNight)

      return this.selectedDates.includes(this.preNight) && canChangeExtraNight
    },
    showWarningPostDepartureMessage() {
      const canChangeExtraNight = !this.isDisabled(this.postNight)

      return this.selectedDates.includes(this.postNight) && canChangeExtraNight
    },
    preNight() {
      if (!this.gathering || !this.configuration) return null
      if (!this.configuration.eveArrival) return null

      const date = new Date(this.gathering.beginDate)
      date.setDate(date.getDate() - 1)

      return this.dateAsKey(date)
    },
    postNight() {
      if (!this.gathering || !this.configuration) return null

      const date = new Date(this.gathering.endDate)

      return this.dateAsKey(date)
    },
    isPublished() {
      if (!this.$get(this.configuration, 'gathering')) return false

      return this.$const.gatheringStatus.PUBLISHED_FRONT === this.configuration.gathering.status
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hostingParticipantEditModal.${key}`, params)
    },
    dateAsKey(date) {
      return date ? dateFormat(new Date(date), 'yyyy-MM-dd') : null
    },
    isDisabled(date) {
      if (!this.configuration || (this.configuration && !this.configuration.eveArrival)) return false
      if (this.configuration.chooseEveArrivalAllowed) return false
      if (this.nights[0] !== date) return false

      const { MCI } = this.$const.userType
      const userTypes = this.$get(this.$store.state, 'myUser.userTypes', [])

      return userTypes.includes(MCI)
    },
    async saveOvernightStays() {
      if (this.saving) return
      this.saving = true

      try {
        await this.$apollo.mutate({
          mutation: SAVE_OVERNIGHT_STAYS,
          variables: {
            dates: this.selectedDates,
            hostingUuid: this.hostingUuid,
            participantUuid: this.participantUuid,
            comment: this.comment
          }
        })

        this.$emit('refresh')
        this.modal = false
      } catch (error) {
        console.error(error)
      } finally {
        this.saving = false
      }
    }
  },
  filters: {
    date(date) {
      return date ? dateFormat(new Date(date), 'dd/MM') : null
    }
  },
  watch: {
    participant(value) {
      const { ACCEPTED, PENDING } = this.$const.hosting.overnightStayStatus

      if (!value) {
        this.selectedDates = []
      } else {
        if (this.$get(value, 'stays', []).length <= 0) return

        for (const stay of value.stays.filter(stay => [ACCEPTED, PENDING].includes(stay.status))) {
          this.selectedDates.push(this.dateAsKey(stay.date))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Checkbox-Label {
  vertical-align: top;
}
</style>
