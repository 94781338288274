<template>
  <modal v-model="open" :title="virtualSessionUuid ? t('title.edit') : t('title.create')" width="1040" @close="onClose">
    <virtual-session-form
      :virtual-session-uuid="virtualSessionUuid"
      :type="type"
      ref="form"
      @initial="source => (initial = source)"
      @cancel="onClose"
      @save="onSave"
      @publish="onPublish"
    />

    <data-not-saved-modal
      v-if="initial && $get($refs, 'form.form')"
      :initial="initial"
      :current="$refs.form.form"
      ref="dataNotSaved"
      @continue="onForceClose"
    />

    <!-- Status change confirmation modal -->
    <modal v-model="statusModal" :title="t(`status.${nextStatus}.title`)" @close="statusModal = false">
      <mybb-text>{{ t(`status.${nextStatus}.text`) }}</mybb-text>

      <div class="d-flex justify-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="closeStatusModal">
          {{ t('status.cancel') }}
        </mybb-btn>

        <mybb-btn
          color="mybb-success"
          @click="onConfirmStatusChange"
          :loading="$get($refs, 'form.loading.status', false)"
        >
          {{ t('status.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </modal>
</template>

<script>
import { SET_VIRTUAL_SESSION } from '@/graphql/VirtualSession'

import DataNotSavedModal from '../DataNotSavedModal'
import Modal from '../Modal'

import VirtualSessionForm from './VirtualSessionForm'

export default {
  name: 'VirtualSessionFormModal',
  model: {
    prop: 'visible'
  },
  components: { DataNotSavedModal, Modal, VirtualSessionForm },
  props: {
    visible: {
      type: Boolean
    },
    virtualSessionUuid: {
      type: String
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      initial: null,
      statusModal: false,
      nextStatus: null,
      payload: null
    }
  },
  computed: {
    open: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)

        if (!value) {
          this.statusModal = false
          this.nextStatus = null
          this.payload = null
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`virtualSession.formModal.${key}`, params)
    },
    onClose() {
      if (this.$refs.dataNotSaved.isStale) {
        this.$refs.dataNotSaved.trigger()
      } else {
        this.open = false
      }
    },
    onForceClose() {
      this.open = false
    },
    async onSave(virtualSessionPayload) {
      if (this.virtualSessionUuid) {
        Object.assign(virtualSessionPayload, { virtualSessionUuid: this.virtualSessionUuid })
      }

      this.$refs.form.setSaving(true)

      try {
        await this.$apollo.mutate({
          mutation: SET_VIRTUAL_SESSION,
          variables: { virtualSessionPayload }
        })

        this.$refs.form.setSaving(false)
        this.$emit('refresh')
      } catch (error) {
        console.error(error)
      }

      this.onForceClose()
    },
    onPublish(payload) {
      this.nextStatus = this.$const.virtualSession.status.PUBLISHED
      this.statusModal = true
      this.payload = payload
    },
    closeStatusModal() {
      this.statusModal = false
      this.nextStatus = null
      this.payload = null
    },
    async onConfirmStatusChange() {
      const { PUBLISHED, CLOSED, WINDED_UP } = this.$const.virtualSession.status

      switch (this.nextStatus) {
        case PUBLISHED:
          await this.publish(this.payload)
          break

        case CLOSED:
          // @todo
          break

        case WINDED_UP:
          // @todo
          break
      }

      this.payload = null
    },
    async publish(virtualSessionPayload) {
      if (this.virtualSessionUuid) {
        Object.assign(virtualSessionPayload, { virtualSessionUuid: this.virtualSessionUuid })
      }

      this.$refs.form.setStatusChanging(true)

      try {
        await this.$apollo.mutate({
          mutation: SET_VIRTUAL_SESSION,
          variables: { virtualSessionPayload, publish: true }
        })

        this.$refs.form.setStatusChanging(false)
        this.$emit('refresh')
      } catch (error) {
        console.error(error)
      }

      this.onForceClose()
    }
  }
}
</script>
