var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-3"},[_c('v-row',{staticClass:"ma-0 mb-2",attrs:{"align":"center","justify":"space-between"}},[_c('mybb-text',{staticClass:"text-uppercase",attrs:{"weight":"bold","size":"16"}},[_vm._v(_vm._s(_vm.pt('waitingHostingList')))]),_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2 groupIcon",attrs:{"color":"mybb-primary-lighten1"}},[_vm._v("mdi-account-group")]),_c('mybb-text',{attrs:{"weight":"bold","size":"16"}},[_vm._v(" "+_vm._s(_vm.pt('waitingPeopleNumber', { waitingPeople: _vm.waitingPeople }))+" ")])],1)],1),(!_vm.pendingOvernightStays)?_c('Loader',{attrs:{"color":"mybb-blue"}}):_c('v-data-table',{staticClass:"Table",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"participantUuid","show-select":!_vm.isWindedUpGathering,"custom-sort":_vm.$dataTableSort(),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{staticClass:"text-uppercase",attrs:{"weight":"bold"}},[_vm._v(_vm._s(value))])]}},{key:"item.firstName",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(value))])]}},{key:"item.category",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-chip',{attrs:{"color":value.color,"dark":""}},[_vm._v(" "+_vm._s(value.label)+" ")]):_vm._e()]}},_vm._l((_vm.nights),function(night){return {key:("item." + (_vm.dateAsKey(night))),fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{key:_vm.dateAsKey(night),attrs:{"color":"mybb-blue"}},[_vm._v(" mdi-check ")]):_vm._e()]}}}),{key:"item.comments",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"color":"mybb-primary-lighten1","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"mybb-primary-lighten1"}},on),[_vm._v(" mdi-message-text ")])]}}],null,true)},[_c('mybb-text',{staticClass:"white--text"},[_vm._v(" "+_vm._s(value.comment)+" ")])],1):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }