<template>
  <div>
    <!-- Header -->
    <div>
      <mybb-btn class="px-0" text color="white" @click="backToList" inner-icon="mdi-chevron-left">
        {{ t('back') }}
      </mybb-btn>
    </div>

    <!-- Container -->
    <v-container class="Container px-6 py-5" fluid>
      <div class="mb-5">
        <mybb-text weight="bold" size="16" class="text-uppercase">{{ isEdit ? t('titleEdit') : t('title') }}</mybb-text>
      </div>
      <div v-if="!isEdit" class="mt-2">
        <mybb-text weight="bold">{{ t('subtitle') }}</mybb-text>
      </div>

      <!-- Participant informations -->
      <div v-if="!isEdit" class="mt-5">
        <mybb-text>{{ t('hcpSection.title') }}</mybb-text>
        <v-row>
          <v-col col="6" md="6">
            <participant-picker v-model="participantUuid" :label="t('hcpSection.field')" />
          </v-col>
        </v-row>
        <mybb-text v-if="hasAlreadyPendingTransportNote" class="mybb-error--text">
          {{ t('hcpSection.error') }}
        </mybb-text>
        <mybb-text v-else-if="hasPendingSolicitation" class="mybb-error--text">
          {{ t('hcpSection.errorSolicitation') }}
        </mybb-text>

        <v-row justify="center" class="my-5">
          <v-col cols="6" md="6" class="pa-0">
            <v-divider />
          </v-col>
        </v-row>
      </div>

      <!-- Travel informations -->
      <div>
        <mybb-text weight="bold" size="16" class="text-uppercase">{{ t('travelSection.title') }}</mybb-text>
      </div>

      <transport-journey v-model="transportNote" class="my-3" travels-through-model />

      <div class="mt-5 mb-2">
        <mybb-text weight="bold">{{ t('travelSection.ticketsField') }}</mybb-text>
      </div>
      <input-file v-model="transportNote.files[0]" accept=".pdf,.jpg,.png" />

      <v-row justify="end" class="ma-0 pa-0 mt-10">
        <mybb-btn :loading="loading" @click="createNote" :disabled="!canSubmit" color="mybb-success">
          {{ t('submit') }}
        </mybb-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _omit from 'lodash/omit'

import { PARTICIPANT_HAS_PENDING_TRANSPORT_NOTE, PARTICIPANT_WITH_SOLICITATION } from '@/graphql/Participant'
import { CREATE_TRANSPORT_NOTE, GET_TRANSPORT_NOTE, UPDATE_TRANSPORT_NOTE } from '@/graphql/Transport'
import { GET_GATHERING_TYPE } from '@/graphql/Gatherings/GetGathering'
import { file } from '@/mixins'

import ParticipantPicker from '@/components/mybb/user/ParticipantPicker'
import TransportJourney from '@/components/mybb/transport/TransportJourney'
import InputFile from '@/components/mybb/InputFile'

export default {
  name: 'TransportNoteForm',
  components: { ParticipantPicker, TransportJourney, InputFile },
  mixins: [file],
  data() {
    return {
      participantUuid: null,
      transportNote: {
        ticketPrice: 0,
        agencyFee: 0,
        files: [],
        travels: []
      },
      loading: false
    }
  },
  computed: {
    canSubmit() {
      const { ticketPrice, agencyFee, travels } = this.transportNote

      return (
        this.participantUuid &&
        !this.hasPendingSolicitation &&
        !this.hasAlreadyPendingTransportNote &&
        ticketPrice > 0 &&
        agencyFee > 0 &&
        Array.isArray(travels) &&
        (travels.some(travel => travel.type === this.$const.transport.travelType.GO) ||
          travels.some(travel => travel.type === this.$const.transport.travelType.BACK)) &&
        !this.isWindedUpGathering
      )
    },
    isEdit() {
      return typeof this.$route.params.transportNoteUuid === 'string'
    },
    transportNoteEntry() {
      const { ticketPrice, agencyFee, travels, files } = this.transportNote

      return {
        agencyFee,
        files,
        ticketPrice,
        travels,
        transportNoteUuid: this.$route.params.transportNoteUuid
      }
    },
    isWindedUpGathering() {
      return this.gatheringBasicInfo && this.gatheringBasicInfo.status === this.$const.gatheringStatus.WINDED_UP
    },
    hasPendingSolicitation() {
      if (!this.gatheringBasicInfo) return false
      if (!this.participant) return false
      if (!this.gatheringBasicInfo.congress) return false
      if (!this.participant.healthCareProfessionalUuid) return false

      return this.$get(this.participant, 'solicitation.status', null) !== this.$const.solicitationStatus.SENT
    }
  },
  apollo: {
    hasAlreadyPendingTransportNote: {
      query: PARTICIPANT_HAS_PENDING_TRANSPORT_NOTE,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid,
          participantUuid: this.participantUuid
        }
      },
      update({ participantHasPendingTransportNote }) {
        return participantHasPendingTransportNote
      },
      skip() {
        return !this.participantUuid
      }
    },
    participant: {
      query: PARTICIPANT_WITH_SOLICITATION,
      variables() {
        return {
          participantUuid: this.participantUuid
        }
      },
      skip() {
        return !this.participantUuid
      }
    },
    backendTransportNote: {
      query: GET_TRANSPORT_NOTE,
      variables() {
        return {
          transportNoteUuid: this.$route.params.transportNoteUuid,
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update({ transportNote }) {
        return transportNote
      },
      result({ data: { transportNote } }) {
        this.participantUuid = transportNote.participantUuid
        this.transportNote.ticketPrice = transportNote.ticketPrice
        this.transportNote.agencyFee = transportNote.agencyFee
        this.transportNote.files = (transportNote.files || []).map(this.formatBackendFile)
        this.transportNote.travels = (transportNote.travels || []).map(travel => _omit(travel, '__typename'))
        this.transportNote.status = transportNote.status
      },
      skip() {
        return !this.$route.params.transportNoteUuid
      }
    },
    gatheringBasicInfo: {
      query: GET_GATHERING_TYPE,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update({ gathering }) {
        return gathering
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transportNoteForm.${key}`, params)
    },
    backToList() {
      return this.$router.push({ name: 'TransportNoteList' })
    },
    insertStep(currentIndex) {
      // Offset of 1 to start adding after the current index
      this.transportNote.travels.splice(currentIndex + 1, 0, { transportNoteUuid: this.noteUuid, type: this.type })
    },
    removeStep(currentIndex) {
      this.transportNote.travels.splice(currentIndex, 1)
    },
    async createNote() {
      if (!this.canSubmit || this.loading) return
      this.loading = true

      await this.$apollo.mutate({
        mutation: this.isEdit ? UPDATE_TRANSPORT_NOTE : CREATE_TRANSPORT_NOTE,
        variables: this.isEdit
          ? { transportNoteEntry: this.transportNoteEntry }
          : {
              gatheringUuid: this.$route.params.gatheringUuid,
              participantUuid: this.participantUuid,
              transportNote: this.transportNote
            }
      })

      if (this.isEdit) {
        this.$router.push({
          name: 'TransportNotePage',
          params: {
            transportNoteUuid: this.$route.params.transportNoteUuid
          }
        })
      } else {
        this.backToList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Container {
  width: 940px;
  margin: 0;
  background-color: white;
  border-radius: 8px;
}
</style>
