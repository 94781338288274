<template>
  <div v-if="businessUnitUuid" v-cy="$cy.gathering.form.teamFormContainer(businessUnit.label)">
    <mybb-text size="16" weight="semi-bold">
      <v-icon v-if="isMain" color="mybb-primary-lighten1" size="20" class="mr-1">mdi-star</v-icon>
      {{ t(`sectionTitle`, { bu: businessUnit.externalId }) }}
    </mybb-text>

    <!-- Medical director - congress only -->
    <v-row v-if="isCongress">
      <v-col cols="6">
        <mybb-autocomplete
          v-model="inChargeDm"
          :label="mandatoryLabel(t('commonSection.inChargeDmLabel'), $const.gatheringUserType.MEDICAL_DIRECTOR)"
          :items="dmItems"
          :error-messages="!inChargeDm ? collect('custom-dm') : null"
          :disabled="disabled"
          v-cy="$cy.gathering.form.dm(businessUnit.label)"
          icon="mdi-account"
        />
      </v-col>

      <v-col cols="6">
        <mybb-autocomplete
          v-model="dmBackup"
          :label="t('commonSection.backupDmLabel')"
          :items="dmBackupItems"
          :disabled="!inChargeDm || disabled"
          icon="mdi-account"
          v-cy="$cy.gathering.form.dmBackup(businessUnit.label)"
          clearable
          @click:clear="dmBackup = null"
        />
      </v-col>
    </v-row>

    <!-- Project director -->
    <v-row>
      <v-col cols="6">
        <mybb-autocomplete
          v-model="inChargeCp"
          :label="mandatoryLabel(t('commonSection.inChargeCpLabel'), $const.gatheringUserType.PROJECT_MANAGER)"
          :items="cpItems"
          :error-messages="!inChargeCp ? collect('custom-cp') : null"
          :disabled="disabled"
          v-cy="$cy.gathering.form.cp(businessUnit.label)"
          icon="mdi-account"
        />
      </v-col>

      <v-col cols="6">
        <mybb-autocomplete
          v-model="backupCp"
          :label="t('commonSection.backupCpLabel')"
          :items="backupCpItems"
          :disabled="!inChargeCp || disabled"
          v-cy="$cy.gathering.form.cpBackup(businessUnit.label)"
          icon="mdi-account"
          clearable
          @click:clear="backupCp = null"
        />
      </v-col>
    </v-row>

    <!-- Marketing -->
    <v-row>
      <v-col cols="6">
        <mybb-autocomplete
          v-model="inChargeRm"
          :label="t('commonSection.inChargeRmLabel')"
          :items="rmItems"
          :disabled="disabled"
          icon="mdi-account"
        />
      </v-col>

      <v-col cols="6">
        <mybb-autocomplete
          v-model="backupRm"
          :label="t('commonSection.backupRmLabel')"
          :items="backupRmItems"
          :disabled="!inChargeRm || disabled"
          icon="mdi-account"
          clearable
          @click:clear="backupRm = null"
        />
      </v-col>
    </v-row>

    <!-- Medical responsible -->
    <v-row>
      <v-col cols="6">
        <mybb-autocomplete
          v-model="inChargeRmcl"
          :label="t('commonSection.inChargeRmclLabel')"
          :items="rmclItems"
          :disabled="disabled"
          icon="mdi-account"
        />
      </v-col>

      <v-col cols="6">
        <mybb-autocomplete
          v-model="backupRmcl"
          :label="t('commonSection.backupRmclLabel')"
          :items="backupRmclItems"
          :disabled="!inChargeRmcl || disabled"
          icon="mdi-account"
          clearable
          @click:clear="backupRmcl = null"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'TeamForm',
  model: {
    prop: 'eventUsers'
  },
  props: {
    isCongress: {
      type: Boolean
    },
    isMain: {
      type: Boolean
    },
    users: {
      type: Array
    },
    eventUsers: {
      type: Array
    },
    businessUnitUuid: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  inject: ['collect', 'clear'],
  computed: {
    businessUnit() {
      return this.$get(this.$store, 'state.bus', []).find(bu => bu.businessUnitUuid === this.businessUnitUuid)
    },
    dmItems() {
      if (!this.users) return []
      const dms = this.users.filter(
        user =>
          user.userTypes.includes(this.$const.userType.DM) || user.userTypes.includes(this.$const.userType.SUPER_DM)
      )
      return this.mapToItems(dms)
    },
    dmBackupItems() {
      if (!this.users) return []
      const dms = this.users.filter(
        user => user.userTypes.includes(this.$const.userType.SUPER_DM) && user.userUuid !== this.inChargeDm
      )
      return this.mapToItems(dms)
    },
    cpItems() {
      if (!this.users) return []
      const { CP } = this.$const.userType

      const cps = this.users.filter(user => user.userTypes.includes(CP))
      return this.mapToItems(cps)
    },
    backupCpItems() {
      if (!this.users) return []
      const { CP } = this.$const.userType

      const cps = this.users.filter(user => user.userTypes.includes(CP) && user.userUuid !== this.inChargeCp)
      return this.mapToItems(cps)
    },
    rmItems() {
      if (!this.users) return []
      const rms = this.users.filter(user => user.userTypes.includes(this.$const.userType.RM))
      return this.mapToItems(rms)
    },
    backupRmItems() {
      if (!this.users) return []
      const rms = this.users.filter(
        user => user.userTypes.includes(this.$const.userType.RM) && user.userUuid !== this.inChargeRm
      )
      return this.mapToItems(rms)
    },
    rmclItems() {
      if (!this.users) return []
      const { RMCL, DM } = this.$const.userType
      const types = [RMCL, DM]

      const rmcl = this.users.filter(user => types.some(type => user.userTypes.includes(type)))
      return this.mapToItems(rmcl)
    },
    backupRmclItems() {
      if (!this.users) return []
      const { RMCL, DM } = this.$const.userType
      const types = [RMCL, DM]

      const rmcl = this.users.filter(
        user => types.some(type => user.userTypes.includes(type)) && user.userUuid !== this.inChargeRmcl
      )
      return this.mapToItems(rmcl)
    },
    inChargeDm: {
      get() {
        return this.getUserUuid(this.$const.gatheringUserType.MEDICAL_DIRECTOR, false)
      },
      set(value) {
        this.setUser(this.$const.gatheringUserType.MEDICAL_DIRECTOR, false, value)
      }
    },
    dmBackup: {
      get() {
        return this.getUserUuid(this.$const.gatheringUserType.MEDICAL_DIRECTOR, true)
      },
      set(value) {
        this.setUser(this.$const.gatheringUserType.MEDICAL_DIRECTOR, true, value)
      }
    },
    inChargeCp: {
      get() {
        return this.getUserUuid(this.$const.gatheringUserType.PROJECT_MANAGER, false)
      },
      set(value) {
        this.setUser(this.$const.gatheringUserType.PROJECT_MANAGER, false, value)
      }
    },
    backupCp: {
      get() {
        return this.getUserUuid(this.$const.gatheringUserType.PROJECT_MANAGER, true)
      },
      set(value) {
        this.setUser(this.$const.gatheringUserType.PROJECT_MANAGER, true, value)
      }
    },
    inChargeRm: {
      get() {
        return this.getUserUuid(this.$const.gatheringUserType.MARKETING, false)
      },
      set(value) {
        this.setUser(this.$const.gatheringUserType.MARKETING, false, value)
      }
    },
    backupRm: {
      get() {
        return this.getUserUuid(this.$const.gatheringUserType.MARKETING, true)
      },
      set(value) {
        this.setUser(this.$const.gatheringUserType.MARKETING, true, value)
      }
    },
    inChargeRmcl: {
      get() {
        return this.getUserUuid(this.$const.gatheringUserType.MEDICAL, false)
      },
      set(value) {
        this.setUser(this.$const.gatheringUserType.MEDICAL, false, value)
      }
    },
    backupRmcl: {
      get() {
        return this.getUserUuid(this.$const.gatheringUserType.MEDICAL, true)
      },
      set(value) {
        this.setUser(this.$const.gatheringUserType.MEDICAL, true, value)
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.supportTeamStep.${key}`, params)
    },
    mandatoryLabel(label, role) {
      const { MEDICAL_DIRECTOR, PROJECT_MANAGER } = this.$const.gatheringUserType

      if (this.isCongress) {
        return role === MEDICAL_DIRECTOR ? `${label}*` : label
      }

      return role === PROJECT_MANAGER ? `${label}*` : label
    },
    getUserUuid(gatheringUserType, isBackup) {
      const user = this.eventUsers.find(
        user =>
          user.businessUnitUuid === this.businessUnitUuid &&
          user.gatheringUserType === gatheringUserType &&
          user.isBackup === isBackup
      )

      return user ? user.userUuid : null
    },
    setUser(gatheringUserType, isBackup, userUuid) {
      const users = _cloneDeep(this.eventUsers)

      const current = users.find(
        usr =>
          usr.businessUnitUuid === this.businessUnitUuid &&
          usr.gatheringUserType === gatheringUserType &&
          usr.isBackup === isBackup
      )

      if (!userUuid) {
        this.$emit('input', users.filter(usr => usr !== current))
        return
      }

      const user = {
        businessUnitUuid: this.businessUnitUuid,
        gatheringUserType,
        isBackup,
        userUuid
      }

      if (!current) {
        users.push(user)
      } else {
        const index = users.findIndex(usr => usr === current)
        users[index] = user
      }

      if (!isBackup) {
        const backupUser = users.find(
          usr =>
            usr.businessUnitUuid === this.businessUnitUuid &&
            usr.gatheringUserType === gatheringUserType &&
            usr.isBackup
        )

        if (backupUser && backupUser.userUuid === userUuid) {
          const backupUserIndex = users.findIndex(user => user === backupUser)

          users.splice(backupUserIndex, 1)
        }
      }

      this.$emit('input', users)
    },
    mapToItems(users) {
      return users.map(user => ({
        value: user.userUuid,
        text: `${user.lastName} ${user.firstName}`
      }))
    }
  }
}
</script>
