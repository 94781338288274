<template>
  <div>
    <v-chip
      v-cy="$cy.activity.form.targetItem(category.label)"
      :disabled="disabled"
      v-for="category in formattedCategories"
      :key="category.key"
      :color="category.selected ? category.color : 'mybb-text-lighten1'"
      dark
      :close="category.selected"
      class="ma-1"
      @click="select(category)"
      @click:close="select(category)"
    >
      {{ category.label }}
    </v-chip>
  </div>
</template>

<script>
import Categories from 'mybb-categories'

import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'

export default {
  name: 'ActivityCategories',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    categories() {
      if (!this.gathering) return []

      const version = this.$get(this.gathering, 'categoryVersion', null)
      const eventType = Categories.getEventTypeFromGathering(this.gathering)
      const categories = Categories.forEventType(eventType, version)

      return Array.from(categories).map(category => ({
        key: category.id,
        value: category.id,
        label: category.label,
        color: category.color
      }))
    },
    formattedCategories() {
      return this.categories.map(category => ({
        ...category,
        selected: this.value.includes(category.value)
      }))
    }
  },
  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      skip() {
        return !this.$route.params.gatheringUuid
      }
    }
  },
  methods: {
    select(category) {
      if (this.disabled) {
        return
      }
      this.$emit(
        'input',
        this.value.includes(category.value)
          ? this.value.filter(selectedCategory => selectedCategory !== category.value)
          : [...this.value, category.value]
      )
    }
  }
}
</script>
