<template>
  <modal v-model="modal" :title="t('title')">
    <template v-slot:text>
      <!-- Lines recap -->
      <mybb-text class="mb-2">{{ t('header', participantIdentity) }}</mybb-text>
      <mybb-text class="mb-2" weight="bold">{{ t('subHeader') }}</mybb-text>

      <div class="mb-2" v-if="isUnderArbitration && refusedLines.length > 0">
        <mybb-text weight="bold" class="mb-2">{{ t('arbitrationSubline') }}</mybb-text>

        <mybb-text v-for="line in refusedLines" :key="line.id" class="d-block mb-2">
          {{ line.label }}
        </mybb-text>
      </div>

      <!-- Comment -->
      <div class="mt-4">
        <mybb-text weight="bold">{{ t('commentHeader') }}</mybb-text>
      </div>

      <div class="mt-3">
        <mybb-textarea v-model="comment" :label="commentLabel" rows="3" icon="mdi-comment-text" />
      </div>

      <!-- Double validation checkbox -->
      <div class="mt-3">
        <v-checkbox v-model="doubleConfirmation" :label="checkboxLabel" class="mt-0" dense />
      </div>

      <!-- Buttons -->
      <v-row class="mt-10" justify="center">
        <mybb-btn class="mr-10" color="mybb-primary" @click="closeModal">
          {{ t('cancel') }}
        </mybb-btn>

        <mybb-btn
          :disabled="!canValidate"
          :loading="loading"
          inner-icon="mdi-check"
          color="mybb-success"
          @click="validate"
        >
          {{ t('confirm') }}
        </mybb-btn>
      </v-row>
    </template>
  </modal>
</template>

<script>
import dateFormat from 'date-fns/format'

import { VALIDATE_EXPENSE_NOTE } from '@/graphql/ExpenseNote/ExpenseNote'

import Modal from '../Modal.vue'

export default {
  name: 'ValidationModal',
  components: { Modal },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    note: {
      type: Object,
      required: true
    },
    selectedLines: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      comment: '',
      doubleConfirmation: false,
      loading: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)

        // Side effect to clear the comment field after the modal is closed
        this.$nextTick(() => {
          if (!value) {
            this.doubleConfirmation = false
            this.comment = ''
          }
        })
      }
    },
    participantIdentity() {
      return {
        firstName: this.$get(this.note, 'participant.firstName', null),
        lastName: this.$get(this.note, 'participant.lastName', null)
      }
    },
    isUnderArbitration() {
      return this.note.status === this.$const.expenseNoteStatus.ARBITRATION
    },
    refusedLines() {
      const refusedLines = this.$get(this.note, 'lines', []).filter(
        line => !this.selectedLines.includes(line.expenseNoteLineUuid)
      )

      return refusedLines.map(line => {
        const category = this.$get(line, 'expenseNoteCategory.label', 'N/A')
        const description = line.description
        const feeDate = dateFormat(new Date(this.$get(line, 'feeDate')), 'dd/MM/yy')
        const price = `${Number(this.$get(line, 'price', 0) / 100).toFixed(2)}€`

        return { id: line.expenseNoteLineUuid, label: `${category} - ${description} ${feeDate} ${price}` }
      })
    },
    commentLabel() {
      let label = this.t('commentLabel')

      if (this.note.status === this.$const.expenseNoteStatus.ARBITRATION) {
        label += '*'
      }

      return label
    },
    checkboxLabel() {
      switch (this.note.status) {
        case this.$const.expenseNoteStatus.ARBITRATION:
          return this.t('checkboxArbitrationLabel')

        case this.$const.expenseNoteStatus.TO_BE_PROCESSED:
        default:
          return this.t('checkboxValidationLabel')
      }
    },
    canValidate() {
      if (!this.doubleConfirmation) return false

      if (this.note.status === this.$const.expenseNoteStatus.ARBITRATION) {
        return this.comment && this.comment.length > 0
      }

      return true
    }
  },
  methods: {
    closeModal() {
      this.modal = false
      this.doubleConfirmation = false
    },
    t(key, params) {
      return this.$t(`mybb.expenseNoteDetails.validationModal.${key}`, params)
    },
    async validate() {
      const refusedExpenseNoteLineUuids = this.refusedLines.map(({ id }) => id)

      if (this.loading) return
      this.loading = true

      await this.$apollo.mutate({
        mutation: VALIDATE_EXPENSE_NOTE,
        variables: { expenseNoteUuid: this.note.expenseNoteUuid, comment: this.comment, refusedExpenseNoteLineUuids }
      })

      this.$router.push({
        name: 'ExpenseNoteList',
        params: {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
p {
  color: var(--v-mybb-text-base);
}

.ArbitrationModal-button {
  color: white;
  text-transform: none;

  &--rounded {
    border-radius: 10px;
  }
}
</style>
