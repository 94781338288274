<template>
  <modal v-model="showModal" :title="$t('mybb.eventSteps.modalDataChanged.title')">
    <template v-slot:text>
      <div class="text-center">
        <div class="mb-2">
          <mybb-text>{{ $t('mybb.eventSteps.modalDataChanged.text') }}</mybb-text>
        </div>
        <div class="mb-2">
          <mybb-text weight="bold">{{ $t('mybb.eventSteps.modalDataChanged.strongText1') }}</mybb-text>
        </div>
        <mybb-text weight="bold">{{ $t('mybb.eventSteps.modalDataChanged.strongText2') }}</mybb-text>
      </div>
    </template>

    <template v-slot:actions>
      <mybb-btn @click="showModal = false" color="mybb-grey">
        {{ $t('mybb.eventSteps.modalDataChanged.cancelButton') }}
      </mybb-btn>
      <mybb-btn @click="continueRouting" color="mybb-error" class="ml-15">
        {{ $t('mybb.eventSteps.modalDataChanged.exitButton') }}
      </mybb-btn>
    </template>
  </modal>
</template>

<script>
import _isEqual from 'lodash/isEqual'
import _isObjectLike from 'lodash/isObjectLike'

import Modal from './Modal'

/**
 * WARNING :
 * This is the kind of a magical component which prevent
 * navigation by comparing 2 of its props
 *
 * Simply drop it where you want the unified navigation modal/behaviour
 * and give it the proper props
 *
 * NB : Do NOT give the same object reference for [initial] and [current] props
 *      otherwise they will be considered to be the same (as they are)
 *
 * NNB: We do not handle the initial in here (which would be a totally autonomous component) as the [EventSteps]
 *      components do change it without changing this component (which result in a new initial component)
 *
 * NB': Debug is enabled by default which will show you which field are different
 *      Be aware that the debug logs can still occurs with the redirection, as the [bypass] props
 *      can enable this behaviour
 */
export default {
  name: 'DataNotSavedModal',
  components: { Modal },
  props: {
    initial: {
      type: Object,
      required: false,
      default: () => ({})
    },
    current: {
      type: Object,
      required: false,
      default: () => ({})
    },
    bypass: {
      type: Boolean,
      required: false,
      default: false
    },
    debug: {
      type: Boolean,
      required: false,
      default: process.env.NODE_ENV === 'dev'
    }
  },
  data() {
    return {
      showModal: false,
      target: null,
      force: false,
      guard: null
    }
  },
  computed: {
    isStale() {
      return this.recursiveDeepHasDiff(this.current, this.initial)
    }
  },
  methods: {
    navigationGuard(event) {
      if (!this.isStale || this.bypass) return

      // We can't use our own modal for security purposes
      // It is defined in browser security rules and we can't do
      // anything about it, don't even try
      event.preventDefault()
      event.returnValue = ''
    },
    async continueRouting() {
      if (this.$listeners.continue) {
        return this.$emit('continue')
      }

      this.force = true
      await this.$router.push(this.target)
      this.target = null
      this.showModal = false
      this.force = false
    },
    beforeRouteLeave(to, from, next) {
      if (!this.isStale || this.bypass || this.force || this.isOtherSubRoutes(from, to)) {
        return next()
      }

      this.target = to
      this.showModal = true
    },
    isOtherSubRoutes(from, to) {
      if (from.name !== to.name) return false

      if (!_isEqual(from.matched, to.matched)) return false

      return true
    },
    recursiveDeepHasDiff(source, target, stack = []) {
      const iterable = stack.length ? this.$get(source, stack) : source
      let hasDiff = false
      let debugDiffsPath = []

      for (const property in iterable) {
        const path = [...stack, property]

        if (!_isEqual(this.$get(source, path), this.$get(target, path))) {
          if (
            _isObjectLike(this.$get(source, path)) &&
            _isObjectLike(this.$get(target, path)) &&
            Object.keys(this.$get(source, path)).length !== Object.keys(this.$get(target, path)).length &&
            // Array are properly checked with isEqual, so if we are here,
            // arrays are differents
            !Array.isArray(this.$get(source, path)) &&
            !Array.isArray(this.$get(target, path))
          ) {
            hasDiff = this.recursiveDeepHasDiff(source, target, path) || hasDiff
          } else {
            hasDiff = true
          }
        }

        if (this.debug && hasDiff) {
          debugDiffsPath.push(path)
        }
      }

      if (hasDiff && this.debug) {
        const debugDiffs = debugDiffsPath.map(path => ({
          path: path.join(', '),
          source: this.$get(source, path),
          target: this.$get(target, path),
          isEqual: _isEqual(this.$get(source, path), this.$get(target, path))
        }))

        console.table(debugDiffs)
      }

      return hasDiff
    },
    trigger() {
      this.showModal = true
    }
  },
  beforeDestroy() {
    if (typeof this.guard === 'function') {
      this.guard()

      this.guard = null
    }

    window.removeEventListener('beforeunload', this.navigationGuard)
  },
  mounted() {
    window.addEventListener('beforeunload', this.navigationGuard)
    this.guard = this.$router.beforeEach(this.beforeRouteLeave)
  }
}
</script>

<style lang="scss" scoped>
.ml-15 {
  margin-left: 60px !important;
}
</style>
