<template>
  <div>
    <LoaderTab v-if="!healthCareProfessional" />
    <div v-else>
      <hcp-information-bloc :title="t('generalInformation')" :fields="generalInformations" />
      <hcp-information-bloc :title="t('medicalInformation')" :fields="medicalInformations" />
      <hcp-information-bloc :title="t('transportInformation')" :fields="transportInformations" />
      <v-card class="DocumentsContainer">
        <v-card-title class="px-5 py-4">
          <mybb-text class="text-uppercase" weight="bold" size="16">{{ t('documents') }}</mybb-text>
        </v-card-title>

        <v-card-text class="px-5">
          <mybb-text weight="bold">
            {{ t('biogenLincDocuments') }}
          </mybb-text>
          <v-row>
            <v-col cols="4" v-for="document of documents" :key="document.healthCareProfessionalFileUuid">
              <input-file :file="document.file" :title="document.name" accept="*" disabled />

              <div class="d-flex justify-space-between px-2 mt-2">
                <!-- Adding date -->
                <span>
                  <v-icon small color="mybb-primary-lighten1" class="mr-1">mdi-calendar</v-icon>
                  <mybb-text size="12">
                    {{ t('addingDate', { date: addingDate(document) }) }}
                  </mybb-text>
                </span>
                <!-- Expiration date -->
                <span v-if="document.expirationDate">
                  <v-icon small :color="isExpired(document) ? 'mybb-error' : 'mybb-primary-lighten1'" class="mr-1">
                    mdi-camera-timer
                  </v-icon>
                  <mybb-text size="12" :class="isExpired(document) ? 'mybb-error--text' : ''">
                    {{ t('expirationDate', { date: expirationDate(document) }) }}
                  </mybb-text>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

import { HCP_FULL } from '@/graphql/Hcp/Hcp'
import fileMixin from '@/mixins/file'

import InputFile from '@/components/mybb/InputFile'
import HcpInformationBloc from '@/components/mybb/hcp/HcpInformationBloc'
import LoaderTab from '@/components/congrex/tabs/LoaderTab'

export default {
  name: 'HCPProfile',

  mixins: [fileMixin],

  components: {
    LoaderTab,
    HcpInformationBloc,
    InputFile
  },

  computed: {
    target() {
      const businessUnit = this.$get(this.healthCareProfessional, 'businessUnit', null)

      if (!businessUnit) return '- -'

      const targetBu = this.$const.targetBu[businessUnit.label]

      if (!targetBu) return '- -'

      return `${businessUnit.externalId}_${this.$get(this.healthCareProfessional, [targetBu])}`
    },
    medicalContact() {
      const mslEmail = this.$get(this.healthCareProfessional, 'mslResponsible.email', null)
      if (mslEmail) {
        return mslEmail
      }

      const zoneUserEmail = this.$get(this.healthCareProfessional, 'zone.mslMeta.user.email', null)
      if (zoneUserEmail) {
        return zoneUserEmail
      }

      return '- -'
    },
    commercialContacts() {
      const contacts = []

      if (this.healthCareProfessional.abmBbuEmail) {
        contacts.push({
          label: 'BBU',
          email: this.healthCareProfessional.abmBbuEmail
        })
      }
      if (this.healthCareProfessional.abmSmaEmail) {
        contacts.push({
          label: 'SMA',
          email: this.healthCareProfessional.abmSmaEmail
        })
      }
      if (this.healthCareProfessional.abmTecEmail) {
        contacts.push({
          label: 'TEC',
          email: this.healthCareProfessional.abmTecEmail
        })
      }
      if (this.healthCareProfessional.abmTysEmail) {
        contacts.push({
          label: 'TYS',
          email: this.healthCareProfessional.abmTysEmail
        })
      }

      if (!contacts.length) {
        return '- -'
      }

      return contacts.reduce((acc, contact) => `${acc + contact.email} (${contact.label})\n`, '')
    },
    generalInformations() {
      return [
        {
          label: this.t('civility'),
          value: this.healthCareProfessional.gender ? this.t(this.healthCareProfessional.gender) : '- -'
        },
        {
          label: this.t('lastName'),
          value: this.healthCareProfessional.lastName || '- -'
        },
        {
          label: this.t('firstName'),
          value: this.healthCareProfessional.firstName || '- -'
        },
        {
          label: this.t('mdm'),
          value: this.healthCareProfessional.veevaId || '- -'
        },
        {
          label: this.t('rpps'),
          value: this.healthCareProfessional.rppsNumber || '- -'
        },
        {
          label: this.t('adeli'),
          value: this.healthCareProfessional.adeliNumber || '- -'
        },
        {
          label: this.t('establishment'),
          value: this.healthCareProfessional.healthCareOrganization || '- -'
        },
        {
          label: this.t('address'),
          value:
            this.healthCareProfessional.address || this.healthCareProfessional.postalCode
              ? `${this.healthCareProfessional.address || ''}
                ${this.healthCareProfessional.address && this.healthCareProfessional.postalCode ? ' - ' : ''}
                ${this.healthCareProfessional.postalCode || ''}`
              : '- -'
        },
        {
          label: this.t('city'),
          value:
            this.healthCareProfessional.city ||
            (this.healthCareProfessional.hcpAdditionalInformation &&
              this.healthCareProfessional.hcpAdditionalInformation.country)
              ? `${this.healthCareProfessional.city || ''}
                ${
                  this.healthCareProfessional.city &&
                  this.healthCareProfessional.hcpAdditionalInformation &&
                  this.healthCareProfessional.hcpAdditionalInformation.country
                    ? ' - '
                    : ''
                }
                ${
                  this.healthCareProfessional.hcpAdditionalInformation
                    ? this.healthCareProfessional.hcpAdditionalInformation.country
                    : ''
                }`
              : '- -'
        },
        {
          label: this.t('phone'),
          value: this.healthCareProfessional.phoneNumber || '- -'
        },
        {
          label: this.t('emailVeeva'),
          value: this.healthCareProfessional.emailAddress || '- -'
        },
        {
          label: this.t('emailBiogenLinc'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.emailAddress
              ? this.healthCareProfessional.hcpAdditionalInformation.emailAddress
              : '- -'
        }
      ]
    },
    medicalInformations() {
      return [
        {
          label: this.t('franchise'),
          value:
            this.healthCareProfessional.businessUnit && this.healthCareProfessional.businessUnit.externalId
              ? this.healthCareProfessional.businessUnit.externalId
              : '- -'
        },
        {
          label: this.t('target'),
          value: this.target
        },
        {
          label: this.t('zone'),
          value:
            this.healthCareProfessional.zone && this.healthCareProfessional.zone.label
              ? this.healthCareProfessional.zone.label
              : '- -'
        },
        {
          label: this.t('speciality'),
          value: this.healthCareProfessional.rawSpecialty || '- -'
        },
        {
          label: this.t('type'),
          value: this.healthCareProfessional.type || '- -'
        },
        {
          label: this.t('title'),
          value: this.healthCareProfessional.title || '- -'
        },
        {
          label: this.t('mslResponsible'),
          value: this.healthCareProfessional.mslResponsible ? this.t('mslYes') : this.t('mslNo')
        },
        {
          label: this.t('medicalContact'),
          value: this.medicalContact
        },
        {
          label: this.t('commercialContact'),
          value: this.commercialContacts,
          whiteSpace: true
        }
      ]
    },
    transportInformations() {
      return [
        {
          label: this.t('idNumber'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.idCard
              ? this.healthCareProfessional.hcpAdditionalInformation.idCard
              : '- -'
        },
        {
          label: this.t('passeportNumber'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.passportNumber
              ? this.healthCareProfessional.hcpAdditionalInformation.passportNumber
              : '- -'
        },
        {
          label: this.t('birthDate'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.birthDate
              ? dateFormat(new Date(this.healthCareProfessional.hcpAdditionalInformation.birthDate), 'dd/MM/yy')
              : '- -'
        },
        {
          label: this.t('sncfNumber'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.trainCardNumber
              ? this.healthCareProfessional.hcpAdditionalInformation.trainCardNumber
              : '- -'
        },
        {
          label: this.t('sncfCard'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.trainCardName
              ? this.healthCareProfessional.hcpAdditionalInformation.trainCardName
              : '- -'
        },
        {
          label: this.t('sncfExpiration'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.trainCardExpirationDate
              ? dateFormat(
                  new Date(this.healthCareProfessional.hcpAdditionalInformation.trainCardExpirationDate),
                  'dd/MM/yy'
                )
              : '- -'
        },
        {
          label: this.t('afNumber'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.planeSubscriptionNumber
              ? this.healthCareProfessional.hcpAdditionalInformation.planeSubscriptionNumber
              : '- -'
        },
        {
          label: this.t('afCard'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.planeSubscriptionName
              ? this.healthCareProfessional.hcpAdditionalInformation.planeSubscriptionName
              : '- -'
        },
        {
          label: this.t('adExpiration'),
          value:
            this.healthCareProfessional.hcpAdditionalInformation &&
            this.healthCareProfessional.hcpAdditionalInformation.planeSubscriptionExpirationDate
              ? dateFormat(
                  new Date(this.healthCareProfessional.hcpAdditionalInformation.planeSubscriptionExpirationDate),
                  'dd/MM/yy'
                )
              : '- -'
        }
      ]
    },
    documents() {
      return this.$get(this.healthCareProfessional, 'healthCareProfessionalFiles', [])
        .filter(file => !file.gatheringUuid)
        .sort((doc1, doc2) => new Date(doc2.addedDate) - new Date(doc1.addedDate))
        .map(document => ({ ...document, file: this.formatBackendFile(document.file) }))
    }
  },

  apollo: {
    healthCareProfessional: {
      query: HCP_FULL,
      variables() {
        return {
          healthCareProfessionalUuid: this.$route.params.hcpUuid
        }
      }
    }
  },

  methods: {
    t(key, params) {
      return this.$t(`mybb.HCPPage.profil.${key}`, params)
    },
    addingDate(document) {
      if (!document || (document && !document.addedDate)) return null

      return dateFormat(new Date(document.addedDate), 'dd/MM/yy')
    },
    expirationDate(document) {
      if (!document || (document && !document.expirationDate)) return null

      return dateFormat(new Date(document.expirationDate), 'dd/MM/yy')
    },
    isExpired(document) {
      if (!document || (document && !document.expirationDate)) return false

      const date = new Date(document.expirationDate)
      const now = new Date()

      return now >= date
    }
  }
}
</script>

<style lang="scss">
.DocumentsContainer {
  border-radius: 8px !important;
}
</style>
