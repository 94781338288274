<template>
  <modal v-model="modal" :title="t('title')">
    <mybb-radios v-model="exportType" :label="t('label')" :items="options" />

    <div class="mt-10 text-center">
      <mybb-btn class="mr-10" color="mybb-grey" @click="modal = false">{{ t('cancel') }}</mybb-btn>
      <mybb-btn :disabled="!exportType" color="mybb-success" @click="startExport">{{ t('confirm') }}</mybb-btn>
    </div>
  </modal>
</template>

<script>
import axios from 'axios'
import dateFormat from 'date-fns/format'

import Modal from '../Modal'

export default {
  name: 'ExportParticipantModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean
    },
    filters: {
      type: Object,
      validator(prop) {
        return (
          !prop ||
          (prop &&
            prop.hasOwnProperty('solicitation') &&
            prop.hasOwnProperty('invitation') &&
            prop.hasOwnProperty('presence'))
        )
      }
    }
  },
  data() {
    return {
      exportType: null
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.exportType = null
        }
      }
    },
    options() {
      const { GENERAL, TRANSPARENCE_SYNTHETIC, TRANSPARENCE_FULL } = this.$const.participantExportType

      return [
        { label: this.t(`options.${GENERAL}`), value: GENERAL },
        { label: this.t(`options.${TRANSPARENCE_SYNTHETIC}`), value: TRANSPARENCE_SYNTHETIC },
        { label: this.t(`options.${TRANSPARENCE_FULL}`), value: TRANSPARENCE_FULL }
      ]
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.AttendeeList.participantExportModal.${key}`, params)
    },
    startExport() {
      if (!this.exportType) return

      const { GENERAL, TRANSPARENCE_SYNTHETIC, TRANSPARENCE_FULL } = this.$const.participantExportType

      let job
      let fileName
      switch (this.exportType) {
        case GENERAL:
          fileName = `${dateFormat(new Date(), 'yyMMdd')}_MasterExtract.xlsx`
          job = axios.post(
            `/gathering/participants/master-extract/${this.$get(this.$route, 'params.gatheringUuid')}`,
            { filters: this.filters },
            {
              responseType: 'blob',
              headers: {
                'x-impersonificated-user': this.$store.state.impersonificatedUser
              }
            }
          )
          break

        case TRANSPARENCE_SYNTHETIC:
        case TRANSPARENCE_FULL:
          fileName =
            this.exportType === TRANSPARENCE_SYNTHETIC
              ? `${dateFormat(new Date(), 'yyMMdd')}_Transparence-synthétique.xlsx`
              : `${dateFormat(new Date(), 'yyMMdd')}_Transparence-détaillée.xlsx`
          job = axios.post(
            `/gathering/participants/transparence-extract/${this.$get(this.$route, 'params.gatheringUuid')}`,
            { type: this.exportType, filters: this.filters },
            {
              responseType: 'blob',
              headers: {
                'x-impersonificated-user': this.$store.state.impersonificatedUser
              }
            }
          )
          break

        default:
          fileName = `${this.exportType}.xlsx`
          job = new Promise((resolve, reject) => setTimeout(Math.random() > 0.5 ? resolve : reject, 8000))
      }

      this.$bus.$emit('extract', {
        job,
        label: this.t(`options.${this.exportType}`),
        fileName,
        status: {
          pending: this.t('status.pending'),
          success: this.t('status.done'),
          error: this.t('status.error')
        }
      })

      this.modal = false
    }
  }
}
</script>
