<template>
  <v-container class="pa-0 ma-0">
    <!-- Generic informations -->
    <v-row>
      <!-- Modes -->
      <v-col cols="6" md="3" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('transportModes') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ modes }}</mybb-text>
        </div>
      </v-col>

      <!-- Comments -->
      <v-col cols="6" md="5" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('comment') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ hcpComment }}</mybb-text>
        </div>
      </v-col>
    </v-row>

    <!-- Go informations -->
    <div class="mt-6 mb-3">
      <mybb-text size="14" weight="bold">{{ t('goTitle') }}</mybb-text>
    </div>
    <v-row>
      <!-- Go departure place -->
      <v-col cols="6" md="3" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('departurePlace') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ note.goDeparturePlace }}</mybb-text>
        </div>
      </v-col>
      <!-- Go arrival place -->
      <v-col cols="6" md="3" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('arrivalPlace') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ note.goArrivalPlace }}</mybb-text>
        </div>
      </v-col>
      <!-- Go departure date -->
      <v-col cols="6" md="3" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('departureDate') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ note.goDepartureDate | date }}</mybb-text>
        </div>
      </v-col>
      <!-- Go departure time -->
      <v-col cols="6" md="3" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('departureTime') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ note.goDepartureTime || '--' }}</mybb-text>
        </div>
      </v-col>
    </v-row>

    <!-- Return informations -->
    <v-row class="pa-0 ma-0 mt-5 mb-3">
      <mybb-text size="14" weight="bold">{{ t('returnTitle') }}</mybb-text>
    </v-row>
    <v-row>
      <!-- Return departure place -->
      <v-col cols="6" md="3" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('departurePlace') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ note.returnDeparturePlace }}</mybb-text>
        </div>
      </v-col>
      <!-- Go arrival place -->
      <v-col cols="6" md="3" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('arrivalPlace') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ note.returnArrivalPlace }}</mybb-text>
        </div>
      </v-col>
      <!-- Go departure date -->
      <v-col cols="6" md="3" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('departureDate') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ note.returnDepartureDate | date }}</mybb-text>
        </div>
      </v-col>
      <!-- Go departure time -->
      <v-col cols="6" md="3" class="py-0">
        <div class="mb-1">
          <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('departureTime') }}</mybb-text>
        </div>
        <div>
          <mybb-text>{{ note.returnDepartureTime || '--' }}</mybb-text>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dateFormat from 'date-fns/format'

export default {
  name: 'TransportRequestGroup',
  props: {
    note: {
      type: Object,
      required: true
    }
  },
  computed: {
    modes() {
      return this.note.preferenceModes ? this.note.preferenceModes.map(mode => this.t(`mode.${mode}`)).join(' + ') : ''
    },
    hcpComment() {
      if (!Array.isArray(this.note.comments)) return null

      const hcpComments = Array.from(this.note.comments)
        .filter(comment => comment.isHealthCareProfessionalMessage)
        .sort((c1, c2) => new Date(c1.date) - new Date(c2.date))

      if (!hcpComments.length) return null

      return hcpComments.pop().text
    }
  },
  filters: {
    date(date) {
      if (!date) return '--'

      return dateFormat(new Date(date), 'dd/MM/yy')
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transportNotePage.requestGroup.${key}`, params)
    }
  }
}
</script>

<style lang="scss" scoped>
.grey--text {
  color: #03111d66;
}
</style>
