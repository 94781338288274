<template>
  <div class="HostingDetailsPage pb-8">
    <v-row justify="space-between" class="px-3">
      <mybb-btn class="px-0" color="white" @click="backToList" text inner-icon="mdi-chevron-left">
        {{ t('return') }}
      </mybb-btn>
      <v-row class="mb-3" no-gutters align="center" justify="end">
        <mybb-btn background="white" color="mybb-grey-lighten1" icon inner-icon="mdi-file-export" class="mr-2" />

        <mybb-btn
          background="white"
          color="mybb-grey-lighten1"
          icon
          inner-icon="mdi-pencil"
          class="mr-2"
          @click="$router.push({ name: 'HostingForm', params: { hostingUuid: $route.params.hostingUuid } })"
        />
      </v-row>
    </v-row>
    <side-panel :title="t('info')" class="mb-4" open>
      <LoaderTab v-if="!hosting" color="mybb-primary" />
      <v-container v-else fluid class="px-0">
        <v-row class="pa-0 ma-0">
          <v-col cols="4" class="pa-0">
            <div>
              <mybb-text size="12" class="mybb-grey-lighten1--text"> {{ t('infoPanel.name') }}</mybb-text>
            </div>
            <div>
              <mybb-text>{{ formatName }}</mybb-text>
            </div>
          </v-col>
          <v-col cols="4" class="pa-0">
            <div>
              <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('infoPanel.type') }}</mybb-text>
            </div>
            <div>
              <mybb-text>{{ formatRoomType }}</mybb-text>
            </div>
          </v-col>
          <v-col cols="4" class="pa-0">
            <div>
              <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('infoPanel.leader') }}</mybb-text>
            </div>
            <div>
              <mybb-text>{{ formatLeader }}</mybb-text>
            </div>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-5">
          <v-col cols="4" class="pa-0">
            <div>
              <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('infoPanel.adress') }}</mybb-text>
            </div>
            <div>
              <mybb-text>{{ formatAddress }}</mybb-text>
            </div>
          </v-col>
          <v-col cols="4" class="pa-0">
            <div>
              <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('infoPanel.tel') }}</mybb-text>
            </div>
            <div>
              <mybb-text>{{ formatPhone }}</mybb-text>
            </div>
          </v-col>
          <v-col cols="4" class="pa-0">
            <div>
              <mybb-text size="12" class="mybb-grey-lighten1--text">{{ t('infoPanel.web') }}</mybb-text>
            </div>
            <div>
              <mybb-text>{{ formatWebsite }}</mybb-text>
            </div>
          </v-col>
        </v-row>

        <v-row class="my-5" justify="center">
          <v-col class="pa-0" cols="6" md="6">
            <v-divider />
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0 mt-5">
          <div v-for="(hostingNight, i) in nights" :key="i" class="text-center mr-5">
            <div class="mb-1">
              <mybb-text weight="bold">{{ hostingNight.date | formatedDate }}</mybb-text>
            </div>
            <div>
              <mybb-text>
                <span :class="getClassQuota(hostingNight)">
                  {{ $get(hostingNight.overnightStays, 'length', 0) }}
                </span>
                / {{ hostingNight.quota | formatedQuota }}
              </mybb-text>
            </div>
            <div>
              <mybb-text>{{ hostingNight.price | formatedPrice }} €</mybb-text>
            </div>
          </div>
        </v-row>
      </v-container>
    </side-panel>

    <v-container fluid class="Container px-6 py-5">
      <v-row class="px-3 mb-3" justify="space-between" align="center">
        <mybb-text size="16" weight="bold" class="text-uppercase">
          {{ t('guestList') }}
        </mybb-text>

        <v-spacer />

        <mybb-btn
          inner-icon="mdi-plus"
          @click="$router.push({ name: 'HostingParticipants' })"
          :disabled="isWindedUpGathering"
        >
          {{ t('addGuest') }}
        </mybb-btn>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <mybb-text-field
            v-model="searchGuest"
            background-color="white"
            :placeholder="t('searchPlaceholder')"
            icon="mdi-magnify"
          />
        </v-col>
      </v-row>
      <hosting-overnight-stays
        :hosting="hosting"
        :gathering="gathering"
        @refresh="$apollo.queries.hosting.refetch()"
        :searchText="searchGuest"
      />
    </v-container>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

import { HOSTING_FULL, GET_HOSTING_CONFIGURATION } from '@/graphql/Hosting'
import { GET_GATHERING_STATUS } from '@/graphql/Gatherings/GetGathering'

import SidePanel from '@/components/mybb/ui/SidePanel'
import LoaderTab from '@/components/congrex/tabs/LoaderTab'
import HostingOvernightStays from '@/components/mybb/hosting/HostingOvernightStays'

export default {
  name: 'HostingDetailsPage',
  components: { LoaderTab, SidePanel, HostingOvernightStays },
  data() {
    return {
      searchGuest: ''
    }
  },
  computed: {
    formatName() {
      return this.hosting.name || '- -'
    },
    formatRoomType() {
      return this.hosting.roomType || '- -'
    },
    formatLeader() {
      const tourLeader = this.$get(this.hosting, 'tourLeader')

      return tourLeader ? `${tourLeader.firstName} ${(tourLeader.lastName || '').toUpperCase()}` : null
    },
    formatAddress() {
      return this.hosting.address || '- -'
    },
    formatPhone() {
      return this.hosting.phone || '- -'
    },
    formatWebsite() {
      return this.hosting.website || '- -'
    },
    nights() {
      if (!this.gathering) return []

      const nights = []
      const date = new Date(this.gathering.beginDate)
      const endDate = new Date(this.gathering.endDate)
      const eveArrival = this.hostingConfiguration ? this.hostingConfiguration.eveArrival : false
      const postDeparture = this.hostingConfiguration
        ? this.$get(this.hostingConfiguration, 'postDeparture', false)
        : false

      if (this.gathering.endDate === this.gathering.beginDate && !eveArrival) return [date.toISOString().slice(0, 10)]

      if (eveArrival) {
        date.setDate(date.getDate() - 1)
      }

      while (date.toISOString().slice(0, 10) !== this.gathering.endDate) {
        nights.push(date.toISOString().slice(0, 10))
        date.setDate(date.getDate() + 1)
      }

      if (postDeparture) {
        nights.push(endDate.toISOString().slice(0, 10))
      }

      return nights.map(night => {
        const hostingNight = this.$get(this.hosting, 'hostingNights', []).find(hn => hn.date === night)

        return {
          date: night,
          price: hostingNight ? hostingNight.price : null,
          quota: hostingNight ? hostingNight.quota : null,
          overnightStays: hostingNight
            ? hostingNight.overnightStays.filter(
                stay => ![this.$const.presence.absent, this.$const.presence.noShow].includes(stay.participant.presence)
              )
            : null
        }
      })
    },
    isWindedUpGathering() {
      return this.$get(this.gathering, 'status', null) === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    hosting: {
      query: HOSTING_FULL,
      variables() {
        return {
          hostingUuid: this.$route.params.hostingUuid
        }
      },
      update({ hosting }) {
        for (const night of hosting.hostingNights || []) {
          if (!night || !Array.isArray(night.overnightStays)) continue

          night.overnightStays = night.overnightStays.filter(
            stay => stay.status !== this.$const.hosting.overnightStayStatus.REFUSED
          )
        }
        return hosting
      }
    },
    hostingConfiguration: {
      query: GET_HOSTING_CONFIGURATION,
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      },
      update({ hostingConfigurationFromGatheringUuid }) {
        return hostingConfigurationFromGatheringUuid
      }
    },
    gathering: {
      query: GET_GATHERING_STATUS,
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hostingDetailsPage.${key}`, params)
    },
    backToList() {
      this.$router.push({
        name: 'HostingList',
        params: {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      })
    },

    getClassQuota(hostingNight) {
      const classes = []
      const quota = hostingNight.quota || null
      const overnightStays = hostingNight.overnightStays || []

      if (overnightStays.length >= quota) {
        classes.push('font-weight-bold')
      }
      if (overnightStays.length === quota) {
        classes.push('mybb-blue--text')
      }
      if (overnightStays.length > quota) {
        classes.push('mybb-warning--text')
      }

      return classes
    }
  },
  filters: {
    formatedDate: function(value) {
      return value ? dateFormat(new Date(value), 'dd/MM') : ''
    },
    formatedPrice: function(value) {
      return value ? (value / 100).toFixed(2) : '- -'
    },
    formatedQuota: function(value) {
      return value || '- -'
    }
  }
}
</script>

<style lang="scss" scoped>
.Container {
  background-color: white;
  border-radius: 8px;
}
</style>
