<template>
  <side-panel :title="t('title')" open>
    <div class="Container mt-5 mb-3">
      <span class="TileWrapper">
        <div class="Dash" />
        <solicitation-tile
          v-if="showSolicitation"
          :participant="participant"
          :information-status="solicitation"
          :gathering="gathering"
        />
        <subscription-tile :participant="participant" :information-status="subscription" />
        <transport-tile v-if="showTransport" :participant="participant" :information-status="transport" />
        <hosting-tile v-if="showHosting" :participant="participant" :information-status="hosting" />
        <expense-note-tile v-if="showExpenseNote" :participant="participant" :information-status="expenseNote" />
        <presence-tile :participant="participant" :information-status="presence" />
      </span>
    </div>
  </side-panel>
</template>

<script>
import Categories from 'mybb-categories'

import SolicitationTile from './synthesisTile/SolicitationTile'
import SubscriptionTile from './synthesisTile/SubscriptionTile'
import TransportTile from './synthesisTile/TransportTile'
import HostingTile from './synthesisTile/HostingTile'
import ExpenseNoteTile from './synthesisTile/ExpenseNoteTile'
import PresenceTile from './synthesisTile/PresenceTile'
import SidePanel from '../ui/SidePanel'

export default {
  name: 'SynthesisPanel',
  components: {
    SidePanel,
    SolicitationTile,
    SubscriptionTile,
    TransportTile,
    HostingTile,
    PresenceTile,
    ExpenseNoteTile
  },
  props: {
    participant: {
      type: Object
    },
    gathering: {
      type: Object
    }
  },
  computed: {
    category() {
      if (!this.gathering) return null

      return Categories.forVersion(this.gathering.categoryVersion).find(
        category => category.id === this.participant.category
      )
    },
    showSolicitation() {
      if (!this.gathering) return false

      return Boolean(this.gathering.congress)
    },
    showTransport() {
      return this.category ? this.category.modules.includes(Categories.constants.Module.Transport) : false
    },
    showHosting() {
      return this.category ? this.category.modules.includes(Categories.constants.Module.Hosting) : false
    },
    showExpenseNote() {
      return this.category ? this.category.modules.includes(Categories.constants.Module.ExpenseNote) : false
    },
    statusInformations() {
      return this.$get(this.participant, 'statusInformations', [])
    },
    solicitation() {
      const hcpStatusInformations = this.$get(this.participant, 'healthCareProfessional.statusInformations', [])

      if (!hcpStatusInformations.length) return

      return hcpStatusInformations.find(
        statusInformation => statusInformation.context === this.$const.statusInformationContext.SOLICITATION
      )
    },
    subscription() {
      return this.statusInformations.find(
        statusInformation => statusInformation.context === this.$const.statusInformationContext.SUBSCRIPTION
      )
    },
    transport() {
      return this.statusInformations.find(
        statusInformation => statusInformation.context === this.$const.statusInformationContext.TRANSPORT
      )
    },
    hosting() {
      return this.statusInformations.find(
        statusInformation => statusInformation.context === this.$const.statusInformationContext.HOSTING
      )
    },
    presence() {
      return this.statusInformations.find(
        statusInformation => statusInformation.context === this.$const.statusInformationContext.PRESENCE
      )
    },
    expenseNote() {
      return this.statusInformations.find(
        statusInformation => statusInformation.context === this.$const.statusInformationContext.EXPENSE_NOTE
      )
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.synthesisPanel.${key}`, params)
    }
  }
}
</script>

<style lang="scss" scoped>
.Container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .TileWrapper {
    position: relative;

    .Dash {
      position: absolute;
      top: 62%;
      opacity: 0.25;
      height: 3px;
      width: 100%;
      background-color: var(--v-mybb-grey-lighten1);
    }
  }
}
</style>
