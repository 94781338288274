<template>
  <div>
    <v-card v-if="$get(correctionRequests, 'length', 0)" class="GatheringComments px-6 pt-4 pb-6">
      <div class="Body">
        <mybb-text weight="bold">
          <v-icon color="mybb-warning" class="mr-4" small>mdi-alert</v-icon>
          <span>
            {{ t('label', { date, user }) }}
          </span>
        </mybb-text>

        <mybb-text class="LatestComment">
          {{ latestComment.comment }}
        </mybb-text>

        <mybb-text class="SeeAll mybb-warning--text" size="12" weight="bold" @click="seeAll = true">
          {{ t('seeAll') }}
        </mybb-text>
      </div>

      <div class="Highlight mybb-warning" />
    </v-card>

    <!-- Modal -->
    <modal v-model="seeAll" :title="t('modal.title')">
      <mybb-text class="mb-4">{{ t('modal.text') }}</mybb-text>

      <div
        v-for="(comment, index) of correctionRequests"
        :key="comment.gatheringCorrectionRequestUuid"
        :class="{ Comment: true, 'Comment--highlight': isHighlighted(comment) }"
      >
        <mybb-text weight="bold" class="mr-3">{{ header(comment) }}</mybb-text>
        <mybb-text class="d-block mt-1">{{ comment.comment }}</mybb-text>

        <v-divider v-if="index < correctionRequests.length - 1" class="my-3" />
      </div>

      <div class="mt-10 text-center">
        <mybb-btn color="mybb-grey" @click="seeAll = false">
          {{ t('modal.close') }}
        </mybb-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

import { GET_GATHERING_CORRECTION_REQUESTS } from '@/graphql/Gatherings'

import Modal from '../Modal'

export default {
  name: 'GatheringComments',
  components: { Modal },
  data() {
    return {
      seeAll: false
    }
  },
  computed: {
    latestComment() {
      if (!Array.isArray(this.correctionRequests)) return null

      return this.correctionRequests[0]
    },
    gathering() {
      if (!this.latestComment) return null

      return this.latestComment.gathering
    },
    date() {
      if (!this.latestComment) return null

      return dateFormat(new Date(this.latestComment.createdAt), 'dd/MM/yy')
    },
    user() {
      if (!this.latestComment) return null

      const { firstName, lastName } = this.latestComment.user
      return `${firstName} ${lastName}`
    }
  },
  apollo: {
    correctionRequests: {
      query: GET_GATHERING_CORRECTION_REQUESTS,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update({ gatheringCorrectionRequests }) {
        return gatheringCorrectionRequests.sort(
          (correction1, correction2) => new Date(correction2.createdAt) - new Date(correction1.createdAt)
        )
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.gatheringDetails.comments.${key}`, params)
    },
    header(comment) {
      const date = dateFormat(new Date(comment.createdAt), 'dd/MM/yy')
      const user = `${comment.user.firstName} ${comment.user.lastName}`

      return this.t('modal.header', { date, user })
    },
    isHighlighted(comment) {
      if (!this.gathering) return false

      const date = new Date(comment.createdAt)
      const lastGatheringUpdate = new Date(this.gathering.updatedAt)

      return date - lastGatheringUpdate > 0
    }
  }
}
</script>

<style lang="scss">
.GatheringComments {
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  .Body {
    display: flex;
    flex-direction: row;
    align-content: center;
    white-space: nowrap;

    .LatestComment {
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
      padding: 0 50px;
    }

    .SeeAll {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .Highlight {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    border-radius: unset !important;
  }
}

@keyframes commentHighlight {
  0% {
    // mybb-primary-lighten1
    background-color: rgba(37, 115, 186, 0.2);
  }

  66% {
    // mybb-primary-lighten1
    background-color: rgba(37, 115, 186, 0.2);
  }

  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}

.Comment {
  border-radius: 4px;
  background-color: white;
  padding: 0 4px;
  box-sizing: border-box;

  &--highlight {
    animation: commentHighlight 3s;
  }
}
</style>
