<template>
  <side-panel v-if="hcp" :title="$t('mybb.hcpInformations.sectionTitle')">
    <ul class="HcpInformations-list">
      <li
        v-for="info in informations"
        :key="info.key"
        class="HcpInformations-listElement"
        style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;"
      >
        <mybb-text
          class="HcpInformations-listElement-header"
          :class="{ 'HcpInformations-listElement-header--large': info.key === 'category' }"
          >{{ info.label }}</mybb-text
        >
        <v-chip v-if="info.key === 'category'" :color="info.color" dark class="ma-1">
          {{ info.value }}
        </v-chip>
        <mybb-text v-if="info.key === 'bankStatement'" weight="semi-bold">{{ info.value }}</mybb-text>
        <mybb-btn
          v-if="info.key === 'bankFileStatementUuid' && info.value !== ''"
          icon
          @click="openFile(ribHcp)"
          color="mybb-primary-lighten1"
          inner-icon="mdi-file-download"
        />
        <mybb-btn
          v-if="info.key === 'bankStatement'"
          icon
          @click="inputDisplay.toggle = !inputDisplay.toggle"
          color="mybb-primary-darken1"
          inner-icon="mdi-pencil"
        />
        <mybb-btn
          v-if="info.key === 'lvcHcpFile'"
          @click="downloadLvcExport"
          icon
          color="mybb-primary-darken1"
          inner-icon="mdi-download"
        />
        <mybb-text v-else weight="semi-bold">{{ info.value }}</mybb-text>
      </li>
    </ul>
    <v-row v-if="inputDisplay.toggle" style="align-items: center; gap: 25px;">
      <mybb-text-field
        v-model="inputDisplay.content"
        :label="this.$t('mybb.hcpInformations.editBankStatementPlaceHolder')"
        type="text"
        :error="inputDisplay.error"
      />
      <mybb-btn color="mybb-primary" @click="submitRib" width="max-content">
        {{ this.$t('mybb.hcpInformations.editBankStatement') }}
      </mybb-btn>
    </v-row>
  </side-panel>
</template>

<script>
import Categories from 'mybb-categories'
import dateFormat from 'date-fns/format'
import axios from 'axios'
import { file } from '@/mixins'

import { GET_FILE } from '@/graphql/ExpenseNote/ExpenseNote'
import { UPDATE_RIB_LINE_HCP } from '@/graphql/Hcp/Hcp'

import SidePanel from './SidePanel'

export default {
  components: { SidePanel },
  name: 'HcpInformations',
  props: {
    hcp: {
      type: Object,
      required: false
    },
    fields: {
      type: Array
    }
  },
  mixins: [file],
  data() {
    return {
      ribHcp: {
        name: '',
        content: ''
      },
      inputDisplay: {
        toggle: false,
        content: '',
        error: false
      }
    }
  },
  computed: {
    informations() {
      const fields = [
        'bankFileStatementUuid',
        'lvcHcpFile',
        'honorific',
        'lastName',
        'firstName',
        'bankStatement',
        'birthDate',
        'emailAddress',
        'phoneNumber',
        'address',
        'postalCode',
        'city',
        'country',
        'target',
        'category',
        'passportNumber',
        'idCard',
        'trainCardName',
        'trainCardExpirationDate',
        'trainCardNumber',
        'planeSubscriptionName',
        'planeSubscriptionExpirationDate',
        'planeSubscriptionNumber'
      ]
      const informationsToDisplay = Array.isArray(this.fields)
        ? this.fields.filter(field => fields.includes(field))
        : fields

      const dateFields = ['birthDate', 'trainCardExpirationDate', 'planeSubscriptionExpirationDate']

      return informationsToDisplay
        .map(key => {
          if (key === 'target') {
            const businessUnit = this.$get(this.hcp, 'businessUnit', null)

            return {
              key,
              label: this.$t('mybb.hcpInformations.target'),
              value: businessUnit
                ? `${businessUnit.externalId}_${this.$get(this.hcp, [this.$const.targetBu[businessUnit.label]])}`
                : '- -'
            }
          }

          if (key === 'bankStatement') {
            return {
              key,
              label: this.$t('mybb.hcpInformations.bankStatement'),
              value: this.$get(this.hcp, 'hcpAdditionalInformation.bankStatement')
                ? this.$get(this.hcp, 'hcpAdditionalInformation.bankStatement')
                : ''
            }
          }

          if (key === 'bankFileStatementUuid') {
            return {
              key,
              label: this.$t('mybb.hcpInformations.bankStatementFile'),
              value: this.ribHcp.name ? this.ribHcp.name : ''
            }
          }

          if (key === 'category') {
            const participant = this.$get(this.hcp, 'participants', []).find(
              participant => participant.gatheringUuid === this.$route.params.gatheringUuid
            )
            const version = participant && participant.gathering.categoryVersion
            const categories = version ? Categories.forVersion(version) : null
            const category = (categories || []).find(category => category.id === participant.category)

            return {
              key,
              label: this.$t('mybb.hcpInformations.category'),
              value: category ? category.label : '- -',
              color: category ? category.color : ''
            }
          }

          if (this.hcp.hasOwnProperty(key)) {
            return {
              key,
              label: this.$t(`mybb.hcpInformations.${key}`),
              value: this.hcp[key]
                ? dateFields.includes(key)
                  ? dateFormat(new Date(this.hcp[key]), 'dd/MM/yy')
                  : this.hcp[key]
                : '- -'
            }
          }

          if (this.hcp.hcpAdditionalInformation && this.hcp.hcpAdditionalInformation.hasOwnProperty(key)) {
            return {
              key,
              label: this.$t(`mybb.hcpInformations.additionalInformation.${key}`),
              value: this.hcp.hcpAdditionalInformation[key]
                ? dateFields.includes(key)
                  ? dateFormat(new Date(this.hcp.hcpAdditionalInformation[key]), 'dd/MM/yy')
                  : this.hcp.hcpAdditionalInformation[key]
                : '- -'
            }
          }

          return {
            key,
            label: this.$t(`mybb.hcpInformations.additionalInformation.${key}`),
            value: '- -'
          }
        })
        .filter(Boolean)
    }
  },
  methods: {
    async submitRib() {
      const trimRib = this.inputDisplay.content.replace(/\s/g, '')
      const checkingTwoFirstLetters = /[A-Z]/.test(trimRib.substring(0, 2))
      if (checkingTwoFirstLetters && trimRib.length === 27) {
        await this.$apollo.mutate({
          mutation: UPDATE_RIB_LINE_HCP,
          variables: {
            healthCareProfessionalUuid: this.$get(this.hcp, 'healthCareProfessionalUuid'),
            bankStatement: this.inputDisplay.content.trim()
          }
        })
        const findBankStatement = this.informations.find(p => p.key === 'bankStatement')
        findBankStatement.value = this.inputDisplay.content
        this.inputDisplay.content = ''
        this.inputDisplay.toggle = false
        this.inputDisplay.error = false
      } else {
        this.inputDisplay.error = true
      }
    },
    downloadLvcExport() {
      const { participantUuid } = this.$get(this.hcp, 'participants', []).find(
        participant => participant.gatheringUuid === this.$route.params.gatheringUuid
      )

      const query = { participantUuids: [participantUuid] }
      const job = axios.post(`/lvc/export/${this.$get(this.$route, 'params.gatheringUuid')}`, query, {
        responseType: 'blob',
        headers: {
          'x-impersonificated-user': this.$store.state.impersonificatedUser
        }
      })

      this.$bus.$emit('extract', {
        job,
        label: this.tNotification(`status.label`),
        fileName: null,
        status: {
          pending: this.tNotification('status.pending'),
          success: this.tNotification('status.done'),
          error: this.tNotification('status.error')
        }
      })
    },
    tNotification(key, params) {
      return this.$t(`mybb.AttendeeList.footer.${key}`, params)
    }
  },
  apollo: {
    expenseNoteCategories: {
      query: GET_FILE,
      variables() {
        return {
          fileUuid: this.$get(this.hcp, 'bankFileStatementUuid', null)
        }
      },
      result(data) {
        this.ribHcp.name = data.data.file.name
        this.ribHcp.content = data.data.file.content
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.HcpInformations-list {
  padding: 0;

  .HcpInformations-listElement {
    list-style: none;
    text-align: right;
    margin: 10px 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--v-mybb-primary-base);
    padding-bottom: 5px;
    border-bottom: 1px solid #e0e1e3;

    &:last-child {
      border-bottom: none;
    }

    .HcpInformations-listElement-header {
      float: left;
      font-weight: normal;
      color: var(--v-mybb-grey-lighten1);

      &--large {
        line-height: 40px;
      }
    }
  }
}
</style>
