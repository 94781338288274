<template>
  <div class="HostingOvernightStays">
    <loader-tab v-if="!items" />
    <v-data-table
      v-else
      v-model="selected"
      :headers="headers"
      :items="items"
      :custom-sort="$dataTableSort(sortMapping)"
      show-select
      item-key="participantUuid"
      hide-default-footer
      disable-pagination
      :search="searchText"
    >
      <template v-slot:[`item.lastName`]="{ value }">
        <mybb-text weight="bold">{{ value | uppercase }}</mybb-text>
      </template>

      <template v-slot:[`item.firstName`]="{ value }">
        <mybb-text weight="bold">{{ value }}</mybb-text>
      </template>

      <template v-slot:[`item.category`]="{ value }">
        <v-chip v-if="value" :color="value.color" dark>
          {{ value.label }}
        </v-chip>
      </template>

      <template v-slot:[`item.presence`]="{ value }">
        <v-icon v-if="value" :color="value.color">
          {{ value.icon }}
        </v-icon>
      </template>

      <template v-for="night of eventDates" v-slot:[`item.${dateAsKey(night)}`]="{ value }">
        <v-icon v-if="value" :key="dateAsKey(night)" color="mybb-blue">
          mdi-check
        </v-icon>
      </template>

      <template v-slot:[`item.comments`]="{ value }">
        <v-tooltip v-if="value" color="mybb-primary-lighten1" top>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="mybb-primary-lighten1" v-on="on">
              mdi-message-text
            </v-icon>
          </template>

          <mybb-text v-for="comment of value" :key="comment.hostingCommentUuid" class="white--text">
            {{ comment.comment }}
            <br />
          </mybb-text>
        </v-tooltip>
      </template>

      <template v-slot:[`item.edit`]="{ value }">
        <v-icon v-if="!isWindedUpGathering" size="20" color="mybb-grey-lighten1" @click="editModal = value">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>

    <edit-hosting-participant-modal
      v-model="editModal"
      :configuration="configuration"
      :gathering="gathering"
      :participants="participantsByUuid"
      :hostingUuid="$route.params.hostingUuid"
      :participantUuid="editParticipantUuid"
      @refresh="$emit('refresh')"
    />
    <hosting-cancel-modal v-model="cancelHostingModal" @confirm="cancelHostingModalConfirm" />

    <fixed-footer
      :visible="selectedParticipantUuids.length > 0"
      :label="t('footer.label', { selected: selectedParticipantUuids.length })"
    >
      <mybb-btn color="mybb-error" @click="cancelHostingModal = true">{{ t('footer.cancel') }}</mybb-btn>
    </fixed-footer>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'
import Categories from 'mybb-categories'

import LoaderTab from '@/components/congrex/tabs/LoaderTab'
import { GET_HOSTING_CONFIGURATION, CANCEL_HOSTING_REQUESTS } from '@/graphql/Hosting'

import EditHostingParticipantModal from './EditHostingParticipantModal'
import HostingCancelModal from './HostingCancelModal'
import FixedFooter from '../ui/FixedFooter'

export default {
  name: 'HostingOvernightStays',
  components: { LoaderTab, EditHostingParticipantModal, HostingCancelModal, FixedFooter },
  props: {
    hosting: {
      type: Object,
      validator(prop) {
        return !prop || (prop && Array.isArray(prop.hostingNights))
      }
    },
    gathering: {
      type: Object
    },
    searchText: {
      type: String
    }
  },
  data() {
    return {
      editParticipantUuid: null,
      cancelHostingModal: false,
      selected: [],
      sortMapping: {
        category: 'category.label'
      }
    }
  },
  computed: {
    editModal: {
      get() {
        return this.editParticipantUuid !== null
      },
      set(value) {
        this.editParticipantUuid = value || null
      }
    },
    selectedParticipantUuids() {
      return this.selected.map(item => item.participantUuid)
    },
    eventDates() {
      if (!this.gathering) return []

      const nights = []
      const date = new Date(this.gathering.beginDate)
      const beginDate = date.toISOString().slice(0, 10)
      const endDate = new Date(this.gathering.endDate).toISOString().slice(0, 10)
      const eveArrival = this.configuration ? this.configuration.eveArrival : false
      const postDeparture = this.configuration ? this.$get(this.configuration, 'postDeparture', false) : false

      if (endDate === beginDate && !eveArrival) return [beginDate]

      if (eveArrival) {
        date.setDate(date.getDate() - 1)
      }

      while (date.toISOString().slice(0, 10) !== endDate) {
        nights.push(date.toISOString().slice(0, 10))
        date.setDate(date.getDate() + 1)
      }

      if (postDeparture) {
        nights.push(endDate)
      }

      return nights
    },
    headers() {
      const headers = [
        { sortable: true, text: this.t('headers.lastName'), value: 'lastName' },
        { sortable: false, text: this.t('headers.firstName'), value: 'firstName' },
        { sortable: true, text: this.t('headers.category'), value: 'category' },
        { sortable: false, text: this.t('headers.presence'), value: 'presence' },
        { sortable: true, text: this.t('headers.target'), value: 'target' },
        { sortable: true, text: this.t('headers.zone'), value: 'zone' }
      ]

      for (const date of this.eventDates) {
        headers.push({
          sortable: true,
          text: dateFormat(new Date(date), 'dd/MM'),
          value: this.dateAsKey(date),
          width: 80
        })
      }

      headers.push(
        { sortable: false, text: '', value: 'comments', width: 40 },
        { sortable: false, text: '', value: 'edit', width: 40 }
      )

      return headers
    },
    participantsByUuid() {
      const participants = {}

      this.$get(this.hosting, 'hostingNights', [])
        .map(night => night.overnightStays)
        .flat()
        .forEach(stay => {
          if (!participants[stay.participantUuid]) {
            participants[stay.participantUuid] = { ...stay.participant, stays: [] }
          }

          participants[stay.participantUuid].stays.push(stay)
        })

      // Attributed without any stay participants
      for (const request of this.$get(this.hosting, 'hostingParticipantRequests', [])) {
        if (participants[request.participantUuid]) continue

        participants[request.participantUuid] = { ...request.participant, stays: [] }
      }

      return participants
    },
    participants() {
      return Object.values(this.participantsByUuid).sort((a, b) => a.lastName.localeCompare(b.lastName))
    },
    items() {
      const items = []

      for (const participant of this.participants) {
        items.push({
          participantUuid: participant.participantUuid,
          lastName: participant.lastName,
          firstName: participant.firstName,
          category: this.categoryByIds[participant.category],
          presence: this.computePresence(participant),
          target: this.computeTarget(participant),
          zone: this.$get(participant, 'healthCareProfessional.zone.label', null),
          ...this.computeParticipantOvernightStays(participant),
          comments: this.computeComments(participant),
          edit: participant.participantUuid
        })
      }

      return items
    },
    categoryByIds() {
      if (!this.gathering) return {}

      const categories = Categories.forVersion(this.gathering.categoryVersion)

      return categories.reduce((acc, category) => {
        acc[category.id] = category
        return acc
      }, {})
    },
    isWindedUpGathering() {
      const gatheringStatus = this.$get(this.configuration, 'gathering.status', null)

      return gatheringStatus === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    configuration: {
      query: GET_HOSTING_CONFIGURATION,
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      },
      update({ hostingConfigurationFromGatheringUuid }) {
        return hostingConfigurationFromGatheringUuid
      },
      skip() {
        return !this.$route.params.gatheringUuid
      }
    }
  },
  methods: {
    t(key, params) {
      return this.pt(`guestListTable.${key}`, params)
    },
    pt(key, params) {
      return this.$t(`mybb.hostingDetailsPage.${key}`, params)
    },
    dateAsKey(date) {
      if (!date) return null

      return dateFormat(new Date(date), 'yyyy-MM-dd')
    },
    computeTarget(participant) {
      const businessUnit = this.$get(participant, 'healthCareProfessional.businessUnit', null)

      if (!businessUnit) return null

      const targetBu = this.$const.targetBu[businessUnit.label]

      if (!targetBu) return null

      return `${businessUnit.externalId}_${this.$get(participant, ['healthCareProfessional', targetBu])}`
    },
    computeParticipantOvernightStays(participant) {
      const { ACCEPTED, PENDING } = this.$const.hosting.overnightStayStatus
      const result = {}

      for (const night of this.eventDates) {
        const keyDate = this.dateAsKey(night)

        const staysForDate = participant.stays.filter(
          stay => stay.date === keyDate && [ACCEPTED, PENDING].includes(stay.status)
        )

        result[keyDate] = staysForDate.length > 0
      }

      return result
    },
    computeComments(participant) {
      const comments = this.$get(participant, 'hostingComments', [])

      return comments.length > 0 ? comments : null
    },
    computePresence(participant) {
      const { present, absent, noShow } = this.$const.presence
      const { presence } = participant

      if (!presence) return null

      switch (presence) {
        case present:
          return { color: 'mybb-primary-lighten1', icon: 'mdi-check', presence }

        case absent:
          return { color: 'mybb-error', icon: 'mdi-close', presence }

        case noShow:
          return { color: 'mybb-warning', icon: 'mdi-alert', presence }

        default:
          return null
      }
    },
    async cancelHostingModalConfirm() {
      await this.$apollo.mutate({
        mutation: CANCEL_HOSTING_REQUESTS,
        variables: {
          participantUuids: this.selectedParticipantUuids
        }
      })

      this.cancelHostingModal = false
      this.selected = []
      this.$emit('refresh')
    }
  },
  filters: {
    uppercase(value) {
      return value ? value.toUpperCase() : value
    }
  }
}
</script>

<style lang="scss">
.HostingOvernightStays {
  thead th {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--v-mybb-primary-base) !important;
  }

  th .v-simple-checkbox .v-icon,
  td .v-simple-checkbox .v-icon {
    color: var(--v-mybb-primary-lighten1-base);
  }
}
</style>
