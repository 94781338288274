<template>
  <div class="StatusInformationTile d-inline-block">
    <div class="text-center mb-4">
      <mybb-text weight="bold" class="mr-1">{{ title }}</mybb-text>

      <v-tooltip color="mybb-primary-lighten1" max-width="400" top allow-overflow>
        <template v-slot:activator="{ on }">
          <v-icon small size="20" color="mybb-primary-lighten1" v-on="on">mdi-information</v-icon>
        </template>

        <mybb-text class="white--text">
          {{ informations || '- -' }}
        </mybb-text>
      </v-tooltip>
    </div>

    <v-card height="120" width="120" class="Card">
      <div
        :class="`Border ${!useStyleColor ? color : ''}`"
        :style="{ 'background-color': useStyleColor ? color : '' }"
      />

      <div class="CardBody">
        <div v-if="$slots.default" class="SlotWrapper text-center">
          <slot />
        </div>

        <mybb-text v-if="date" weight="bold" size="16" class="Date">
          {{ date | date }}
        </mybb-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

export default {
  name: 'StatusInformationTile',
  props: {
    title: {
      type: String,
      required: true
    },
    informations: {
      type: String
    },
    date: {
      type: [Date, String]
    },
    color: {
      type: String,
      default: 'mybb-grey-lighten1'
    }
  },
  computed: {
    useStyleColor() {
      return this.color ? this.color.charAt(0) === '#' : false
    }
  },
  filters: {
    date(value) {
      return value ? dateFormat(new Date(value), 'dd/MM/yy') : null
    }
  }
}
</script>

<style lang="scss" scoped>
.StatusInformationTile:not(:last-of-type) {
  margin-right: 100px;
}

.v-card.Card {
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  .Border {
    position: absolute;
    height: 12px;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }

  .CardBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    .SlotWrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      min-height: 32px;
    }

    .Date {
      height: fit-content;
    }
  }
}
</style>
