<template>
  <mybb-select
    v-model="value"
    :items="items"
    :menu-props="{ offsetY: true }"
    :label="t('label')"
    :no-data-text="t('empty-text')"
    icon="mdi-map-marker"
    clearable
    @click:clear="$emit('clear')"
  />
</template>

<script>
import dateFormat from 'date-fns/format'

import { GET_FAVORITE_TRAVELS } from '@/graphql/Transport'

export default {
  name: 'FavoriteTravel',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      current: null
    }
  },
  computed: {
    gatheringUuid() {
      return this.$route.params.gatheringUuid
    },
    items() {
      return (this.favoriteTravels || [])
        .map(travel => {
          const mode = this.pt(`modes.${travel.mode}`)
          const departureDate = dateFormat(new Date(travel.departureDate), 'dd/MM/yy')
          const arrivalDate = dateFormat(new Date(travel.arrivalDate), 'dd/MM/yy')

          const text = `${mode} : ${travel.departurePlace} - ${departureDate}, ${travel.departureTime} > ${travel.arrivalPlace} - ${arrivalDate}, ${travel.arrivalTime}`

          return {
            text,
            value: travel
          }
        })
        .sort((a, b) => {
          const cityA = this.type === 'GO' ? a.value.departurePlace.toLowerCase() : a.value.arrivalPlace.toLowerCase()
          const cityB = this.type === 'GO' ? b.value.departurePlace.toLowerCase() : b.value.arrivalPlace.toLowerCase()

          return cityA < cityB ? -1 : 1
        })
    },
    value: {
      get() {
        return this.current
      },
      set(value) {
        this.current = value
        this.$emit('set-from-favorite', value)
      }
    }
  },
  apollo: {
    favoriteTravels: {
      query: GET_FAVORITE_TRAVELS,
      update({ getFavoriteTravels }) {
        return getFavoriteTravels
      },
      variables() {
        return {
          gatheringUuid: this.gatheringUuid,
          type: this.type
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.pt(`favorite.${key}`, params)
    },
    /**
     * Parent translate
     * @param {string} key
     * @param {any} params
     * @returns {string}
     */
    pt(key, params) {
      return this.$t(`mybb.transportTravelModal.step.${key}`, params)
    }
  }
}
</script>
