<template>
  <mybb-autocomplete
    v-model="value"
    :label="t('oseusEvent')"
    :items="items"
    :loading="loading || checkLoading"
    :disabled="loading"
    :error="error"
    :error-messages="errorMessages"
  />
</template>

<script>
import format from 'date-fns/format'

import { LIST_OSEUS_EVENTS } from '@/graphql/VirtualSession'

export default {
  name: 'OseusEventPicker',
  model: {
    prop: 'oseusEventId'
  },
  props: {
    oseusEventId: {
      type: Object,
      validator(prop) {
        return !prop || (prop && prop.hasOwnProperty('oseusEventId') && prop.hasOwnProperty('oseusMeetingId'))
      }
    },
    error: {
      type: Boolean
    },
    errorMessages: {
      type: [String, Array]
    },
    checkLoading: {
      type: Boolean
    }
  },
  computed: {
    value: {
      get() {
        return this.oseusEventId
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    items() {
      return (this.oseusEvents || [])
        .map(event =>
          event.meetings.map(meeting => ({
            value: { oseusEventId: event.oseusEventId, oseusMeetingId: meeting.oseusMeetingId },
            text: this.formatMeetingToItemText(meeting)
          }))
        )
        .flat()
    },
    loading() {
      return this.$apollo.queries.oseusEvents.loading
    }
  },
  apollo: {
    oseusEvents: {
      query: LIST_OSEUS_EVENTS,
      result() {
        this.$emit('events-loaded', this.oseusEvents)
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`virtualSession.form.${key}`, params)
    },
    formatMeetingToItemText(meeting) {
      if (!meeting) return null

      const date = format(new Date(meeting.startDate), 'dd/MM/yy, HH:mm')

      return `${meeting.theme} - ${date}`
    }
  }
}
</script>
