<template>
  <v-container v-if="$apollo.queries.virtualSession.loading" height="100%" class="text-center" fluid full-width>
    <v-progress-circular color="white" size="64" indeterminate />
  </v-container>

  <div v-else class="AttendeeList mt-5 pb-8" v-cy="$cy.gathering.participant.container">
    <v-row class="AttendeeList-header mb-3" no-gutters align="start">
      <v-col cols="12" md="4">
        <mybb-text-field
          v-model="searchText"
          v-cy="$cy.gathering.participant.search"
          background-color="white"
          :placeholder="t('searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>

      <v-spacer />

      <mybb-btn @click="addParticipant">
        {{ t('addParticipant') }}
      </mybb-btn>
    </v-row>

    <v-data-table
      v-model="selectedParticipants"
      :headers="headers"
      :items="items"
      :loading="$apollo.queries.participants.loading"
      :search="searchText"
      :show-select="!isWindedUp && !isOseus"
      :options.sync="options"
      :server-items-length="$get(participants, 'total', -1)"
      :footer-props="{ itemsPerPageOptions: [50, 100, 200] }"
      item-key="virtualSessionParticipantUuid"
      class="DataTable px-6 py-5"
      @toggle-select-all="selectAllItems"
    >
      <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item, value }">
        <mybb-text
          :key="header.value"
          :weight="['lastName', 'firstName'].includes(header.value) ? 'bold' : undefined"
          :class="{ 'text-uppercase': header.value === 'lastName' }"
          v-cy="$cy.gathering.participant.field(item.lastName.toUpperCase(), capitalize(item.firstName), header.value)"
        >
          {{ header.value === 'firstName' ? capitalize(value) : value }}
        </mybb-text>
      </template>

      <!-- Subscription -->
      <template v-slot:[`item.subscription`]="{ value }">
        <v-icon v-if="value" :color="subscriptionColor(value)">
          {{ subscriptionIcon(value) }}
        </v-icon>
      </template>

      <!-- Presence -->
      <template v-slot:[`item.presence`]="{ value }">
        <v-icon v-if="value" :color="presenceColor(value)">
          {{ presenceIcon(value) }}
        </v-icon>
      </template>

      <!-- Navigation -->
      <template v-slot:[`item.navigation`]="{ item }">
        <v-icon
          v-if="item.healthCareProfessionalUuid"
          v-cy="$cy.gathering.participant.participantPageButton(item.lastName.toUpperCase(), item.firstName)"
          color="mybb-grey-lighten1"
        >
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>

    <virtual-session-participant-footer
      :selected="selectedParticipants"
      :virtual-session="virtualSession"
      @refresh="$apollo.queries.participants.refetch()"
    />
  </div>
</template>

<script>
import _capitalize from 'lodash/capitalize'

import { VirtualSessionParticipantFooter } from '@/components/mybb/virtualSession'
import { GET_FULL_VIRTUAL_SESSION, GET_VSP_PAGINATED } from '@/graphql/VirtualSession'

export default {
  name: 'VirtualSessionAttendeeList',
  components: { VirtualSessionParticipantFooter },
  data() {
    return {
      searchText: '',
      selectedParticipants: [],
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['lastName'],
        sortDesc: [true]
      }
    }
  },
  computed: {
    headers() {
      return [
        { sortable: true, text: this.t('table.lastName'), value: 'lastName' },
        { sortable: false, text: this.t('table.firstName'), value: 'firstName' },
        { sortable: true, text: this.t('table.target'), value: 'target' },
        { sortable: false, text: this.t('table.postalCode'), value: 'postalCode' },
        { sortable: false, text: this.t('table.type'), value: 'type' },
        { sortable: false, text: this.t('table.title'), value: 'title' },
        { sortable: false, text: this.t('table.specialty'), value: 'specialty' },
        {
          sortable: false,
          text: this.t('table.subscription'),
          value: 'subscription'
        },
        {
          sortable: false,
          text: this.t('table.presence'),
          value: 'presence'
        },
        {
          sortable: false,
          text: ' ',
          value: 'navigation'
        }
      ]
    },
    items() {
      return this.$get(this.participants, 'items', []).map(participant => {
        return {
          virtualSessionParticipantUuid: participant.virtualSessionParticipantUuid,
          healthCareProfessionalUuid: participant.healthCareProfessionalUuid,
          lastName: participant.lastName,
          firstName: participant.firstName,
          postalCode: participant.postalCode,
          city: participant.city,
          type: this.computeType(participant),
          title: this.$get(participant, 'healthCareProfessional.title'),
          specialty: this.$get(participant, 'healthCareProfessional.rawSpecialty'),
          subscription: participant.subscription,
          presence: participant.presence,
          target: this.computeTarget(participant)
        }
      })
    },
    isOseus() {
      return this.$get(this.virtualSession, 'type') === this.$const.virtualSession.type.oseus
    },
    isWindedUp() {
      return this.$get(this.virtualSession, 'status') === this.$const.virtualSession.status.WINDED_UP
    },
    pagination() {
      const options = {
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        limit: this.options.itemsPerPage
      }

      if (this.options.sortBy.length > 0) {
        options.sort = {}

        for (let i = 0; i < this.options.sortBy.length; i++) {
          const field = this.options.sortBy[i]
          const isAsc = this.options.sortDesc[i]

          options.sort[field] = isAsc ? 'ASC' : 'DESC'
        }
      }

      return options
    }
  },
  apollo: {
    virtualSession: {
      query: GET_FULL_VIRTUAL_SESSION,
      skip() {
        return !this.$route.params.virtualSessionUuid
      },
      variables() {
        return {
          virtualSessionUuid: this.$route.params.virtualSessionUuid
        }
      }
    },
    participants: {
      query: GET_VSP_PAGINATED,
      skip() {
        return !this.virtualSession
      },
      variables() {
        return {
          virtualSessionUuid: this.$route.params.virtualSessionUuid,
          search: this.searchText,
          pagination: this.pagination
        }
      },
      update({ virtualSessionParticipants }) {
        return virtualSessionParticipants
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`virtualSession.participants.${key}`, params)
    },
    computeTarget(participant) {
      const businessUnit = this.$get(participant, 'healthCareProfessional.businessUnit', null)

      if (!businessUnit) return null

      const targetBu = this.$const.targetBu[businessUnit.label]

      if (!targetBu) return null

      return `${businessUnit.externalId}_${this.$get(participant, ['healthCareProfessional', targetBu])}`
    },
    computeType(participant) {
      const { GUEST, PARTNER } = this.$const.virtualSession.participant.type
      const type = this.$get(participant, 'type', null)

      switch (type) {
        case PARTNER:
        case GUEST:
          return this.t(`type.${type}`)

        default:
          return null
      }
    },
    capitalize(string) {
      if (!string) return null

      return _capitalize(string)
    },
    subscriptionColor(subscription) {
      const { UNKNOWN, SUBSCRIBED, REFUSED } = this.$const.virtualSession.subscription

      switch (subscription) {
        case SUBSCRIBED:
          return 'mybb-primary-lighten1'
        case REFUSED:
          return 'mybb-error'
        case UNKNOWN:
        default:
          return undefined
      }
    },
    subscriptionIcon(subscription) {
      const { UNKNOWN, SUBSCRIBED, REFUSED } = this.$const.virtualSession.subscription

      switch (subscription) {
        case SUBSCRIBED:
          return 'mdi-check'
        case REFUSED:
          return 'mdi-close'
        case UNKNOWN:
        default:
          return undefined
      }
    },
    presenceColor(presence) {
      const { PRESENT, ABSENT, NO_SHOW } = this.$const.virtualSession.presence

      if (!presence) return undefined

      switch (presence) {
        case PRESENT:
          return 'mybb-primary-lighten1'
        case ABSENT:
          return 'mybb-error'
        case NO_SHOW:
          return 'mybb-warning'
        default:
          return undefined
      }
    },
    presenceIcon(presence) {
      const { PRESENT, ABSENT, NO_SHOW } = this.$const.virtualSession.presence

      if (!presence) return undefined

      switch (presence) {
        case PRESENT:
          return 'mdi-check'
        case ABSENT:
          return 'mdi-close'
        case NO_SHOW:
          return 'mdi-alert'
        default:
          return undefined
      }
    },
    computeSubscription(participant) {
      if (participant.subscription === this.$const.virtualSession.subscription.UNKNOWN) return null

      return this.t(`subscription.${participant.subscription}`)
    },
    addParticipant() {
      return this.$router.push({ name: 'VirtualSessionParticipantsAdd' })
    },
    selectAllItems() {}
  }
}
</script>

<style lang="scss" scoped>
.AttendeeList-header {
  height: 46px;
}

.selectField {
  max-width: 200px;
}

.AttendeeList-buttonWrapper {
  display: flex;
}

.BtnWrapper {
  position: relative;
}

.AttendeeList-buttonDot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--v-mybb-success-base);
  position: absolute;
  top: -5px;
  right: -3.75px;
}

.Comment {
  word-break: break-word;
}
</style>

<style lang="scss">
.AttendeeList {
  .AttendeeList-guest {
    height: 46px;
    display: grid;
  }

  .DataTable {
    border-radius: 8px;

    th,
    td {
      .v-icon {
        color: var(--v-mybb-primary-lighten1-base);
      }
    }
  }

  .v-data-table thead th {
    font-size: 12px;
    font-weight: 900;
    color: var(--v-mybb-primary-base) !important;
    text-transform: uppercase;
  }

  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }
  .v-input__append-inner {
    margin-top: 10px !important;
  }
}
</style>
