<template>
  <div class="TransferList pt-5 pb-8">
    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field v-model="search" background-color="white" :placeholder="$t('search')" icon="mdi-magnify" />
      </v-col>

      <mybb-btn
        :background="configurationColor.background"
        :color="configurationColor.icon"
        inner-icon="mdi-cogs"
        class="ml-2"
        icon
        @click="configurationModal = true"
      />

      <div class="ml-4">
        <mybb-text class="white--text" weight="bold" size="16">
          {{ t('totalText', { total: totalPriceForPresent }) }}
        </mybb-text>
      </div>

      <v-spacer />
      <mybb-btn
        @click="downloadExport"
        inner-icon="mdi-file-download"
        color="white"
        class="mybb-primary-lighten1--text mr-4"
        :loading="loadingDownloadExport"
      >
        {{ t('export') }}
      </mybb-btn>

      <mybb-btn
        @click="importModal = true"
        inner-icon="mdi-file-upload"
        :loading="loadingImport"
        :disabled="isWindedUpGathering"
      >
        {{ t('import') }}
      </mybb-btn>
    </v-row>

    <v-container class="Container py-5 px-6" fluid>
      <Loader v-if="!items && !gathering" color="white" />
      <v-data-table
        v-else
        v-model="selectedTransfers"
        :headers="headers"
        item-key="transferUuid"
        :items="items"
        :search="search"
        :custom-filter="$dataTableFilter(filterFields)"
        :custom-sort="$dataTableSort(sortMapping)"
        sort-by="lastName"
        :show-select="!isWindedUpGathering"
        disable-pagination
        :hide-default-footer="true"
      >
        <template v-slot:[`item.firstName`]="{ value: firstName }">
          <mybb-text weight="bold">{{ firstName }}</mybb-text>
        </template>
        <template v-slot:[`item.lastName`]="{ value: lastName }">
          <mybb-text weight="bold">{{ lastName }}</mybb-text>
        </template>
        <template v-slot:[`item.category`]="{ value: category }">
          <v-chip v-if="category" :color="category.color" dark>
            {{ category.label }}
          </v-chip>
        </template>
        <template v-slot:[`item.hasTransport`]="{ value: hasTransport }">
          <v-icon v-if="hasTransport" color="mybb-primary-lighten1">
            mdi-check
          </v-icon>
        </template>
        <template v-slot:[`item.hasOther`]="{ value: hasOther }">
          <v-icon v-if="hasOther" color="mybb-primary-lighten1">
            mdi-check
          </v-icon>
        </template>
        <template v-slot:[`item.totalPrice`]="{ value: totalPrice }">
          {{ totalPrice | price }}
        </template>
        <template v-slot:[`item.presence`]="{ value: presence }">
          <v-icon v-if="presence" :color="presence.color">
            {{ presence.icon }}
          </v-icon>
        </template>
      </v-data-table>
    </v-container>

    <!-- Fixed footer -->
    <fixed-footer
      :visible="selectedTransferUuid.length > 0"
      :label="t('actionBarText', { selected: selectedTransferUuid.length })"
    >
      <mybb-btn color="mybb-error" @click="deleteTransferModal = true">{{ t('deleteTransfer') }}</mybb-btn>
    </fixed-footer>

    <!-- Modal delete transfer -->
    <modal v-model="deleteTransferModal" :title="t('deleteModal.title')">
      <mybb-text>{{ t('deleteModal.text') }}</mybb-text>

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="deleteTransferModal = false">
          {{ t('deleteModal.cancel') }}
        </mybb-btn>

        <mybb-btn color="mybb-error" @click="deleteTransfers" :disabled="isWindedUpGathering">
          {{ t('deleteModal.confirm') }}
        </mybb-btn>
      </div>
    </modal>

    <!-- Modal import transfer -->
    <modal v-model="importModal" :title="t('importModal.title')">
      <mybb-text>
        {{ t('importModal.text') }}
      </mybb-text>
      <mybb-text class="mt-2">
        <ul>
          <li v-for="(bullet, index) in t('importModal.bullets')" :key="`bullet-${index}`">
            {{ bullet }}
          </li>
        </ul>
      </mybb-text>

      <div class="text-center mt-10">
        <mybb-btn color="mybb-primary-lighten1" @click="triggerSelectFile" :disabled="isWindedUpGathering">
          {{ t('importModal.import') }}
        </mybb-btn>
      </div>

      <input @change="uploadFile" type="file" ref="importInput" class="d-none" />
    </modal>

    <!-- Modal end import transfer -->
    <modal v-model="endImportModal" :title="endImportModalContent.title">
      <div v-if="endImportModalContent.content === 'icon'" class="d-flex justify-center">
        <div class="icon-wrapper">
          <v-icon size="100" color="mybb-green">mdi-check</v-icon>
        </div>
      </div>
      <div v-else>
        <mybb-text>
          {{ t('endImportModal.subtitleError') }}
        </mybb-text>
        <mybb-text class="mt-2">
          <ul>
            <li v-for="(error, index) in endImportModalContent.content" :key="`importError-${index}`">
              {{ t('endImportModal.textError', { index: error.rowIndex, message: error.message }) }}
            </li>
          </ul>
        </mybb-text>
      </div>

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" @click="endImportModal = false">
          {{ t('endImportModal.close') }}
        </mybb-btn>
      </div>
    </modal>

    <!-- Modal configuration -->
    <transfer-configuration-modal
      v-model="configurationModal"
      :gathering="gathering"
      :transfer-configuration="transferConfiguration"
      @saved="$apollo.queries.transferConfiguration.refetch()"
    />
  </div>
</template>

<script>
import Categories from 'mybb-categories'
import axios from 'axios'
import dateFnsFormat from 'date-fns/format'
import FileDownload from 'js-file-download'

import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'
import { TRANSFER_CONFIGURATION_FROM_GATHERING, TRANSFERS_FOR_GATHERING, DELETE_TRANSFERS } from '@/graphql/Transfer'

import Loader from '@/components/congrex/tabs/LoaderTab'
import TransferConfigurationModal from '@/components/mybb/transfer/TransferConfigurationModal'
import FixedFooter from '@/components/mybb/ui/FixedFooter'
import Modal from '@/components/mybb/Modal'

export default {
  name: 'TransferList',
  components: { FixedFooter, Loader, Modal, TransferConfigurationModal },
  data() {
    return {
      search: '',
      configurationModal: false,
      deleteTransferModal: false,
      endImportModal: false,
      endImportModalContent: {
        title: '',
        content: ''
      },
      importModal: false,
      transfers: [],
      selectedTransfers: [],
      gathering: null,
      loadingTable: false,
      loadingDownloadExport: false,
      loadingImport: false,
      sortMapping: {
        category: 'category.label',
        presence: 'presence.presence'
      },
      filterFields: {
        category: 'category.label',
        lastName: 'lastName',
        firstName: 'firstName'
      }
    }
  },
  computed: {
    configurationColor() {
      const filled = this.transferConfiguration && Object.values(this.transferConfiguration).some(Boolean)

      return {
        background: filled ? 'white' : 'mybb-warning',
        icon: filled ? 'mybb-grey-lighten1' : 'white'
      }
    },
    headers() {
      return [
        { sortable: true, text: this.t('lastNameColumn'), value: 'lastName', class: 'text-uppercase' },
        { sortable: false, text: this.t('firstNameColumn'), value: 'firstName', class: 'text-uppercase' },
        {
          sortable: true,
          text: this.t('categoryColumn'),
          value: 'category',
          class: 'text-uppercase',
          sort: (a, b) => {
            return a.label.localeCompare(b.label)
          }
        },
        { sortable: true, text: this.t('hasTransportColumn'), value: 'hasTransport', class: 'text-uppercase' },
        { sortable: true, text: this.t('hasOtherColumn'), value: 'hasOther', class: 'text-uppercase' },
        { sortable: false, text: this.t('totalPriceColumn'), value: 'totalPrice', class: 'text-uppercase' },
        {
          sortable: true,
          text: this.t('presenceColumn'),
          value: 'presence',
          class: 'text-uppercase',
          sort: (a, b) => {
            return a.presence.localeCompare(b.presence)
          }
        }
      ]
    },
    items() {
      return this.transfers.map(transfer => {
        return {
          transferUuid: transfer.transferUuid,
          firstName: this.$get(transfer, 'participant.firstName', '- -'),
          lastName: this.$get(transfer, 'participant.lastName', '- -'),
          category: this.computeCategory(transfer),
          hasTransport: transfer.hasTransport,
          hasOther: transfer.hasOther,
          totalPrice: transfer.totalPrice / 100,
          presence: this.computePresence(transfer)
        }
      })
    },
    selectedTransferUuid() {
      return this.selectedTransfers.map(transfer => transfer.transferUuid) || []
    },
    totalPriceForPresent() {
      const price = this.transfers.reduce((acc, transfer) => {
        const presence = this.$get(transfer, 'participant.presence', null)

        if (presence && presence === this.$const.presence.present) {
          return acc + Number(transfer.totalPrice / 100)
        }

        return acc
      }, 0)

      return isNaN(price) ? '- -' : `${(+price).toFixed(2).replace('.', ',')}€`
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      skip() {
        return !this.$route.params.gatheringUuid
      }
    },
    transferConfiguration: {
      query: TRANSFER_CONFIGURATION_FROM_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update({ transferConfigurationForGathering }) {
        return transferConfigurationForGathering
      },
      skip() {
        return !this.$route.params.gatheringUuid
      }
    },
    transfers: {
      query: TRANSFERS_FOR_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update({ transfersForGathering }) {
        return transfersForGathering
      }
    }
  },
  filters: {
    price(price) {
      return isNaN(price) || !price ? '- -' : `${(+price).toFixed(2).replace('.', ',')}€`
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transfer.list.${key}`, params)
    },
    computeCategory(transfer) {
      const categoryId = this.$get(transfer, 'participant.category', null)
      const version = this.$get(this.gathering, 'categoryVersion', null)
      const category = categoryId && version ? Categories.forVersionAndCategoryId(version, categoryId) : null

      return category ? category : null
    },
    computePresence(transfer) {
      const { present, absent, noShow } = this.$const.presence
      const presence = this.$get(transfer, 'participant.presence', null)

      if (!presence) return null

      switch (presence) {
        case present:
          return { color: 'mybb-primary-lighten1', icon: 'mdi-check', presence }

        case absent:
          return { color: 'mybb-error', icon: 'mdi-close', presence }

        case noShow:
          return { color: 'mybb-warning', icon: 'mdi-alert', presence }

        default:
          return null
      }
    },
    async deleteTransfers() {
      this.loadingTable = true

      try {
        await this.$apollo.mutate({
          mutation: DELETE_TRANSFERS,
          variables: {
            transferUuids: this.selectedTransferUuid
          }
        })

        this.selectedTransfers = []
        this.deleteTransferModal = false
      } catch (error) {
        console.error(error)
      } finally {
        await this.$apollo.queries.transfers.refetch()
        this.loadingTable = false
      }
    },
    async downloadExport() {
      this.loadingDownloadExport = true

      const file = await axios.post(
        `/transfer/export/${this.$get(this.$route, 'params.gatheringUuid')}`,
        {},
        {
          responseType: 'blob',
          headers: {
            'x-impersonificated-user': this.$store.state.impersonificatedUser
          }
        }
      )

      FileDownload(file.data, `${dateFnsFormat(new Date(), 'yyMMdd')}-transfert.xlsx`)

      this.loadingDownloadExport = false
    },
    triggerSelectFile() {
      this.$refs.importInput.click()
    },
    async uploadFile() {
      this.loadingImport = true
      // get file
      const file = this.$refs.importInput.files[0]
      this.importModal = false

      if (!file) {
        return
      }

      // check extension
      const fullname = file.name
      const extension = fullname.split('.').pop()

      if (extension !== 'xlsx') {
        console.warn('extension not accepted')
        return
      }

      //upload
      try {
        const formData = new FormData()
        formData.append('file', file)

        const { data } = await axios.post(
          `/transfer/import/${this.$get(this.$route, 'params.gatheringUuid')}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-impersonificated-user': this.$store.state.impersonificatedUser
            }
          }
        )

        const { success, fileErrors } = data

        if (success) {
          this.endImportModalContent.title = this.t('endImportModal.titleSucess')
          this.endImportModalContent.content = 'icon'

          await this.$apollo.queries.transfers.refetch()
        } else {
          this.endImportModalContent.title = this.t('endImportModal.titleError')
          this.endImportModalContent.content = fileErrors
        }
      } catch (e) {
        console.warn(e)
      } finally {
        this.endImportModal = true
        this.loadingImport = false
      }
    }
  }
}
</script>

<style lang="scss">
.TransferList {
  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }

  .Container {
    background-color: white;
    border-radius: 8px;

    .v-data-table {
      thead th {
        font-size: 12px;
        font-weight: 900;
        color: var(--v-mybb-primary-base) !important;
      }

      tbody td {
        color: var(--v-mybb-primary-base);
      }

      th .v-simple-checkbox .v-icon,
      td .v-simple-checkbox .v-icon {
        color: var(--v-mybb-primary-lighten1-base);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid var(--v-mybb-green-base);
  font-size: 3rem;
  width: 120px;
  height: 120px;
}
</style>
