<template>
  <span v-if="allowed && status && statusColor && !hide">
    <mybb-btn
      :disabled="disabled"
      :color="statusColor"
      @click="onClick"
      class="white--text"
      v-cy="$cy.gathering.form.statusChange.button"
    >
      {{ status }}
    </mybb-btn>

    <modal v-model="modal" :title="title">
      <mybb-text class="mb-3">{{ modalText }}</mybb-text>
      <mybb-text v-if="highlight" :class="{ 'mybb-error--text': isHighlightRed }" weight="bold">
        {{ highlight }}
      </mybb-text>

      <v-checkbox
        v-model="doubleConfirmation"
        color="mybb-primary-lighten1"
        :label="checkBoxLabel"
        v-cy="$cy.gathering.form.statusChange.doubleConfirmation"
        dense
      />

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false" v-cy="$cy.gathering.form.statusChange.cancel">
          {{ mt('cancel') }}
        </mybb-btn>

        <mybb-btn
          :loading="loading"
          :disabled="!doubleConfirmation"
          v-cy="$cy.gathering.form.statusChange.confirm"
          color="mybb-success"
          @click="submit"
        >
          {{ mt('confirm') }}
        </mybb-btn>
      </div>
    </modal>

    <modal v-model="sensibleModal" :title="st('title')">
      <mybb-text>{{ st('text') }}</mybb-text>

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" @click="sensibleModal = false">{{ st('close') }}</mybb-btn>
      </div>
    </modal>

    <modal v-model="configurationMissingModal" :title="cmt('title')">
      <mybb-text>{{ cmt('text') }}</mybb-text>

      <ul class="mt-2">
        <li v-for="key in missingConfigurationForPublication" :key="key">
          {{ cmt(`configuration.${key}`) }}
        </li>
      </ul>
      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" @click="configurationMissingModal = false">{{ cmt('close') }}</mybb-btn>
      </div>
    </modal>
  </span>
</template>

<script>
import {
  CAN_SEND_TO_VALIDATION,
  GET_MISSING_CONFIGURATION_FOR_PUBLICATION,
  SEND_TO_VALIDATION,
  PUBLISH_INTERNAL,
  PUBLISH_FRONT,
  CLOSE,
  REOPEN
} from '@/graphql/Gatherings'

import Modal from '../Modal'

export default {
  name: 'GatheringStatusButton',
  components: { Modal },
  props: {
    gathering: {
      type: Object,
      validator(prop) {
        return !prop || (prop && prop.hasOwnProperty('gatheringUuid'))
      }
    },
    hideOnDisabled: {
      type: Boolean
    }
  },
  data() {
    return {
      modal: false,
      sensibleModal: false,
      configurationMissingModal: false,
      doubleConfirmation: false,
      loading: false
    }
  },
  computed: {
    hide() {
      return this.hideOnDisabled && this.disabled
    },
    allowed() {
      const { ROC, ADMIN, SUPER_ADMIN } = this.$const.userType
      const userTypes = this.$get(this.$store.state, 'myUser.userTypes', [])

      return (
        (this.isWindedUp && [ADMIN, SUPER_ADMIN].some(role => userTypes.includes(role))) ||
        (!this.isWindedUp && [ROC, ADMIN, SUPER_ADMIN].some(role => userTypes.includes(role)))
      )
    },
    status() {
      if (!this.gathering) return null

      return this.t(`label.${this.gathering.status || this.$const.gatheringStatus.DRAFT}`)
    },
    statusColor() {
      if (!this.gathering) return 'mybb-grey-ligthen1'

      const {
        DRAFT,
        CORRECTION,
        TO_VALIDATE,
        TO_PUBLISH,
        PUBLISHED,
        PUBLISHED_FRONT,
        CLOSED,
        WINDED_UP
      } = this.$const.gatheringStatus

      switch (this.gathering.status) {
        case DRAFT:
        case CORRECTION:
        case TO_PUBLISH:
        case PUBLISHED:
          return 'mybb-blue'
        case PUBLISHED_FRONT:
          return 'mybb-grey'
        case WINDED_UP:
          return 'mybb-success'
        case TO_VALIDATE:
        case CLOSED:
        default:
          return null
      }
    },
    title() {
      if (!this.gathering) return

      return this.mt(`title.${this.gathering.status}`)
    },
    isHighlightRed() {
      if (!this.gathering) return

      const { PUBLISHED, PUBLISHED_FRONT } = this.$const.gatheringStatus

      return [PUBLISHED, PUBLISHED_FRONT].includes(this.gathering.status)
    },
    highlight() {
      if (!this.gathering) return

      return this.mt(`highlight.${this.gathering.gatheringType}.${this.gathering.status}`)
    },
    modalText() {
      return this.isWindedUp ? this.mt('windedUpText') : this.mt('text')
    },
    checkBoxLabel() {
      return this.isWindedUp ? this.mt('windedUpCheckboxLabel') : this.mt('checkboxLabel')
    },
    mutation() {
      const { DRAFT, CORRECTION, TO_PUBLISH, PUBLISHED, PUBLISHED_FRONT, WINDED_UP } = this.$const.gatheringStatus

      switch (this.gathering.status) {
        case DRAFT:
        case CORRECTION:
          return SEND_TO_VALIDATION
        case TO_PUBLISH:
          return PUBLISH_INTERNAL
        case PUBLISHED:
          return PUBLISH_FRONT
        case PUBLISHED_FRONT:
          return CLOSE
        case WINDED_UP:
          return REOPEN
        default:
          return null
      }
    },
    disabled() {
      return (
        [this.$const.gatheringStatus.DRAFT, this.$const.gatheringStatus.CORRECTION].includes(
          this.$get(this.gathering, 'status')
        ) && !this.canSendToValidation
      )
    },
    isWindedUp() {
      return this.$get(this.gathering, 'status') === this.$const.gatheringStatus.WINDED_UP
    },
    hasSensibleChangesPending() {
      return this.$get(this.gathering, 'gatheringSensibleChanges', []).some(
        gsc => gsc.status === this.$const.gatheringSensibleChangeStatus.PENDING
      )
    }
  },
  apollo: {
    canSendToValidation: {
      query: CAN_SEND_TO_VALIDATION,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid,
          congressUuid: this.$get(this.gathering, 'congress.congressUuid'),
          biogenEventUuid: this.$get(this.gathering, 'biogenEvent.biogenEventUuid')
        }
      },
      skip() {
        return (
          !this.gathering ||
          ![this.$const.gatheringStatus.DRAFT, this.$const.gatheringStatus.CORRECTION].includes(this.gathering.status)
        )
      }
    },
    missingConfigurationForPublication: {
      query: GET_MISSING_CONFIGURATION_FOR_PUBLICATION,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.gatheringNextStatusButton.${key}`, params)
    },
    mt(key, params) {
      return this.t(`modal.${key}`, params)
    },
    st(key, params) {
      return this.t(`sensibleChangePendingModal.${key}`, params)
    },
    cmt(key, params) {
      return this.t(`configurationMissingModal.${key}`, params)
    },
    onClick() {
      if (
        this.gathering.status === this.$const.gatheringStatus.PUBLISHED &&
        this.missingConfigurationForPublication.length > 0
      ) {
        this.configurationMissingModal = true
      } else if (this.hasSensibleChangesPending) {
        this.sensibleModal = true
      } else if (this.$listeners.click) {
        this.$emit('click')
      } else {
        this.showModal()
      }
    },
    showModal() {
      this.modal = true
    },
    async submit() {
      if (this.loading || !this.mutation) return

      const payload = {
        mutation: this.mutation,
        variables: {
          gatheringUuid: this.$route.params.gatheringUuid,
          congressUuid: this.$get(this.gathering, 'congress.congressUuid'),
          biogenEventUuid: this.$get(this.gathering, 'biogenEvent.biogenEventUuid')
        }
      }

      this.loading = true

      if (this.$listeners.override) {
        this.$emit('override', payload)
        return
      }

      try {
        await this.$apollo.mutate(payload)

        this.modal = false
        this.doubleConfirmation = false
        this.$emit('refresh')
      } catch (error) {
        console.error(error)
        this.$bus.$emit('showSnackBarGlobal', { text: error.message, backgroundColor: 'mybb-error' })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
