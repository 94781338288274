<template>
  <div class="ActivityInformationForm">
    <!-- Configuration (Category/Organizer/Nature) -->
    <v-row class="pa-0 ma-0 mb-6">
      <!-- Category -->
      <v-col cols="6" md="6" class="py-0 pr-0 pl-0">
        <mybb-select
          v-model="category"
          v-cy="$cy.activity.form.category"
          :items="activityCategories"
          :label="t('category')"
          icon="mdi-calendar-text"
          :disabled="isDisabled"
        />
      </v-col>

      <!-- Organizer -->
      <v-col v-if="isCongress" cols="3" md="3" class="py-0 pr-0">
        <mybb-select
          v-model="organizer"
          v-cy="$cy.activity.form.organizer"
          :items="organizerItems"
          :label="t('organizer')"
          icon="mdi-office-building"
          :disabled="isDisabled"
        />
      </v-col>

      <!-- Nature -->
      <v-col v-if="isHybrid" cols="3" md="3" class="py-0">
        <mybb-select
          v-model="nature"
          v-cy="$cy.activity.form.nature"
          :items="natureItems"
          :label="t('nature')"
          :disabled="isDisabled"
        />
      </v-col>
    </v-row>

    <!-- Name -->
    <div class="mb-6 pr-3">
      <mybb-text-field
        v-model="name"
        v-cy="$cy.activity.form.name"
        :label="t('name')"
        icon="mdi-calendar-text"
        :disabled="isDisabled"
      />
    </div>

    <!-- Date and time -->
    <v-row class="pa-0 ma-0 mb-6">
      <v-col cols="3" md="3" class="py-0 px-0">
        <date-picker v-model="date" :cypress="$cy.activity.form.date" :label="t('date')" :disabled="isDisabled" />
      </v-col>
      <v-col cols="3" md="3" class="py-0 px-1">
        <time-picker
          v-model="startHour"
          :cypress="$cy.activity.form.startHour"
          :label="t('startHour')"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="3" md="3" class="py-0 px-0">
        <time-picker
          v-model="endHour"
          :cypress="$cy.activity.form.endHour"
          :label="t('endHour')"
          :disabled="isDisabled"
        />
      </v-col>
    </v-row>

    <!-- Places -->
    <v-row class="mb-6">
      <!-- Room -->
      <v-col cols="6" md="6" class="py-0">
        <mybb-text-field
          v-model="room"
          v-cy="$cy.activity.form.room"
          :label="t('room')"
          icon="mdi-map-marker"
          :disabled="isDisabled"
        />
      </v-col>
      <!-- Place -->
      <v-col cols="6" md="6" class="py-0 pl-0">
        <mybb-text-field v-model="place" :label="t('place')" icon="mdi-map-marker" :disabled="isDisabled" />
      </v-col>
    </v-row>

    <!-- Links -->
    <v-row class="mb-6">
      <!-- Room -->
      <v-col cols="6" md="6" class="py-0">
        <mybb-text-field v-model="website" :label="t('website')" icon="mdi-earth" :disabled="isDisabled" />
      </v-col>
      <!-- Visio link -->
      <v-col cols="6" md="6" class="py-0 pl-0">
        <mybb-text-field
          v-model="visioLink"
          :label="t('visioLink')"
          icon="mdi-television-play"
          :disabled="isDisabled"
        />
      </v-col>
    </v-row>

    <!-- Files -->
    <v-row class="mb-6">
      <!-- Image -->
      <v-col cols="6" md="6" class="py-0">
        <input-file :title="t('image')" v-model="image" accept=".jpg,.png" :disabled="isDisabled" />
      </v-col>
      <!-- Document -->
      <v-col cols="6" md="6" class="py-0 pl-0">
        <input-file :title="t('document')" v-model="document" accept=".pdf" :disabled="isDisabled" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { GET_ACTIVITY_CATEGORIES } from '@/graphql/Activity'
import { GET_GATHERING_TYPE } from '@/graphql/Gatherings/GetGathering'

import InputFile from '../InputFile'
import DatePicker from '../ui/DatePicker'
import TimePicker from '../ui/TimePicker'

export default {
  name: 'ActivityInformationForm',
  components: { DatePicker, TimePicker, InputFile },
  model: {
    prop: 'activity',
    event: 'change'
  },
  props: {
    activity: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    organizerItems() {
      return Object.values(this.$const.activity.organizer).map(value => ({
        value,
        text: this.pt(`organizer.${value}`)
      }))
    },
    natureItems() {
      return Object.values(this.$const.activity.nature).map(value => ({
        value,
        text: this.pt(`nature.${value}`)
      }))
    },
    isCongress() {
      if (!this.gathering) return false

      return this.gathering.congress
    },
    isHybrid() {
      if (!this.gathering) return false

      const { isPhysical, isVirtual } = this.gathering
      return isPhysical && isVirtual
    },
    // Fields
    category: {
      get() {
        return this.activity.activityCategoryUuid
      },
      set(activityCategoryUuid) {
        this.$emit('change', { ...this.activity, activityCategoryUuid })
      }
    },
    organizer: {
      get() {
        return this.activity.organizer
      },
      set(organizer) {
        this.$emit('change', { ...this.activity, organizer })
      }
    },
    nature: {
      get() {
        const { isPhysical, isVirtual } = this.activity

        if (isPhysical && !isVirtual) return this.$const.activity.nature.physical
        if (!isPhysical && isVirtual) return this.$const.activity.nature.virtual
        if (isPhysical && isVirtual) return this.$const.activity.nature.hybrid

        return null
      },
      set(nature) {
        let isPhysical = false
        let isVirtual = false

        switch (nature) {
          case this.$const.activity.nature.physical:
            isPhysical = true
            isVirtual = false
            break

          case this.$const.activity.nature.virtual:
            isPhysical = false
            isVirtual = true
            break

          case this.$const.activity.nature.hybrid:
            isPhysical = true
            isVirtual = true
            break
        }

        this.$emit('change', { ...this.activity, isPhysical, isVirtual })
      }
    },
    name: {
      get() {
        return this.activity.name
      },
      set(name) {
        this.$emit('change', { ...this.activity, name })
      }
    },
    date: {
      get() {
        return this.activity.date
      },
      set(date) {
        this.$emit('change', { ...this.activity, date })
      }
    },
    startHour: {
      get() {
        return this.activity.startHour
      },
      set(startHour) {
        this.$emit('change', { ...this.activity, startHour })
      }
    },
    endHour: {
      get() {
        return this.activity.endHour
      },
      set(endHour) {
        this.$emit('change', { ...this.activity, endHour })
      }
    },
    room: {
      get() {
        return this.activity.room
      },
      set(room) {
        this.$emit('change', { ...this.activity, room })
      }
    },
    place: {
      get() {
        return this.activity.place
      },
      set(place) {
        this.$emit('change', { ...this.activity, place })
      }
    },
    website: {
      get() {
        return this.activity.website
      },
      set(website) {
        this.$emit('change', { ...this.activity, website })
      }
    },
    visioLink: {
      get() {
        return this.activity.visioLink
      },
      set(visioLink) {
        this.$emit('change', { ...this.activity, visioLink })
      }
    },
    image: {
      get() {
        return this.activity.image
      },
      set(image) {
        this.$emit('change', { ...this.activity, image })
      }
    },
    document: {
      get() {
        return this.activity.document
      },
      set(document) {
        this.$emit('change', { ...this.activity, document })
      }
    }
  },
  apollo: {
    activityCategories: {
      query: GET_ACTIVITY_CATEGORIES,
      update({ activityCategories }) {
        return activityCategories.map(category => ({
          value: category.activityCategoryUuid,
          text: category.label
        }))
      }
    },
    gathering: {
      query: GET_GATHERING_TYPE,
      variables() {
        return {
          gatheringUuid: this.activity.gatheringUuid
        }
      },
      skip() {
        return !this.activity.gatheringUuid
      }
    }
  },
  methods: {
    t(key, params) {
      return this.pt(`form.${key}`, params)
    },
    pt(key, params) {
      return this.$t(`mybb.activity.${key}`, params)
    }
  }
}
</script>

<style scoped>
.ActivityInformationForm {
  width: 100%;
  color: var(--v-mybb-text-base);
}
</style>
