<template>
  <modal v-model="modal" :title="t('title')">
    <div v-if="canSave">
      <div v-if="isPublishedGathering">
        <mybb-text color="red">{{ t('altText') }}</mybb-text>
        <mybb-text color="red">{{ t('confirmText') }}</mybb-text>
      </div>
      <mybb-text v-else>{{ t('text') }}</mybb-text>
    </div>
    <div v-else>
      <mybb-text>{{ t('errorMessage') }}</mybb-text>
      <ul>
        <li v-bind:key="index" v-for="(unremovableParticipantLabel, index) of unremovableParticipantLabels">
          {{ unremovableParticipantLabel }}
        </li>
      </ul>
    </div>

    <div v-if="canSave" class="mt-10 text-center">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
        {{ t('no') }}
      </mybb-btn>

      <mybb-btn :loading="loading" color="mybb-success" inner-icon="mdi-content-save" @click="save">
        {{ t('yes') }}
      </mybb-btn>
    </div>

    <div v-else class="mt-10 text-center">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
        {{ t('cancel') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import { REMOVE_PARTICIPANTS } from '@/graphql/Participant'

import Modal from '../Modal'

export default {
  name: 'RemoveParticipantsModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    gathering: {
      type: Object,
      required: false
    },
    visible: {
      type: Boolean,
      required: true
    },
    selectedParticipants: {
      type: Array,
      required: true
    },
    unremovableParticipants: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.$nextTick(() => {
            this.loading = false
          })
        }
      }
    },
    isPublishedGathering() {
      const { PUBLISHED_FRONT, CLOSED, WINDED_UP } = this.$const.gatheringStatus

      if ([PUBLISHED_FRONT, CLOSED, WINDED_UP].includes(this.gathering.status)) {
        return true
      }

      return false
    },
    canSave() {
      return this.unremovableParticipants.length === 0
    },
    unremovableParticipantLabels() {
      const participantLabels = this.unremovableParticipants.slice(0, 10).map(p => `${p.firstName} ${p.lastName}`)
      if (this.unremovableParticipants.length > 10) {
        participantLabels.push(this.t('otherParticipants', { count: this.unremovableParticipants.length - 10 }))
      }

      return participantLabels
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.AttendeeList.footer.removeParticipantsModal.${key}`, params)
    },
    async save() {
      if (!this.canSave || this.loading) return
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: REMOVE_PARTICIPANTS,
          variables: {
            participantUuids: this.selectedParticipants.map(({ participantUuid }) => participantUuid),
            gatheringUuid: this.gathering.gatheringUuid
          }
        })

        this.$emit('refresh')
        this.modal = false
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
