<template>
  <div class="pb-3">
    <v-row class="ma-0 mb-2" align="center" justify="space-between">
      <mybb-text weight="bold" size="16" class="text-uppercase">{{ pt('waitingHostingList') }}</mybb-text>
      <div class="d-flex">
        <v-icon class="mr-2 groupIcon" color="mybb-primary-lighten1">mdi-account-group</v-icon>
        <mybb-text weight="bold" size="16">
          {{ pt('waitingPeopleNumber', { waitingPeople }) }}
        </mybb-text>
      </div>
    </v-row>
    <Loader v-if="!pendingOvernightStays" color="mybb-blue" />
    <v-data-table
      v-else
      v-model="selected"
      class="Table"
      :headers="headers"
      :items="items"
      item-key="participantUuid"
      :show-select="!isWindedUpGathering"
      :custom-sort="$dataTableSort()"
      hide-default-footer
    >
      <template v-slot:[`item.lastName`]="{ value }">
        <mybb-text weight="bold" class="text-uppercase">{{ value }}</mybb-text>
      </template>

      <template v-slot:[`item.firstName`]="{ value }">
        <mybb-text weight="bold">{{ value }}</mybb-text>
      </template>

      <template v-slot:[`item.category`]="{ value }">
        <v-chip v-if="value" :color="value.color" dark>
          {{ value.label }}
        </v-chip>
      </template>

      <template v-for="night of nights" v-slot:[`item.${dateAsKey(night)}`]="{ value }">
        <v-icon v-if="value" :key="dateAsKey(night)" color="mybb-blue"> mdi-check </v-icon>
      </template>

      <template v-slot:[`item.comments`]="{ value }">
        <v-tooltip v-if="value" color="mybb-primary-lighten1" top>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="mybb-primary-lighten1" v-on="on"> mdi-message-text </v-icon>
          </template>

          <mybb-text class="white--text">
            {{ value.comment }}
          </mybb-text>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dateDiff from 'date-fns/differenceInCalendarDays'
import dateFormat from 'date-fns/format'
import Categories from 'mybb-categories'

import Loader from '@/components/congrex/tabs/LoaderTab'

export default {
  name: 'HostingParticipantsTable',
  components: { Loader },
  model: {
    prop: 'selectedParticipants',
    event: 'change'
  },
  props: {
    configuration: {
      type: Object,
      validator(prop) {
        return (
          !prop ||
          (prop.hasOwnProperty('eveArrival') &&
            prop.hasOwnProperty('gathering') &&
            prop.gathering.hasOwnProperty('endDate') &&
            prop.gathering.hasOwnProperty('beginDate'))
        )
      }
    },
    pendingOvernightStays: {
      type: Array
    },
    selectedParticipants: {
      type: Array
    },
    gathering: {
      type: Object,
      validator(prop) {
        return !prop || (prop && prop.hasOwnProperty('categoryVersion'))
      }
    }
  },
  computed: {
    selected: {
      get() {
        return (this.selectedParticipants || []).map(participantUuid =>
          this.items.find(item => item.participantUuid === participantUuid)
        )
      },
      set(value) {
        this.$emit('change', value.map(({ participantUuid }) => participantUuid))
      }
    },
    nights() {
      const begin = this.$get(this.configuration, 'gathering.beginDate')
      const end = this.$get(this.configuration, 'gathering.endDate')
      const eveArrival = this.$get(this.configuration, 'eveArrival')
      const postDeparture = this.$get(this.configuration, 'postDeparture')

      if (!begin || !end) return []

      const beginDate = new Date(begin)
      const endDate = new Date(end)

      if (eveArrival) {
        beginDate.setDate(beginDate.getDate() - 1)
      }

      const nights = []
      const diff = Math.abs(dateDiff(beginDate, endDate))

      for (let i = 0; i < diff; i++) {
        nights.push(new Date(beginDate))
        beginDate.setDate(beginDate.getDate() + 1)
      }

      if (postDeparture) {
        nights.push(new Date(endDate))
      }

      return nights
    },
    headers() {
      const headers = [
        { sortable: true, text: this.t('header.lastName'), value: 'lastName' },
        { sortable: false, text: this.t('header.firstName'), value: 'firstName' },
        { sortable: true, text: this.t('header.category'), value: 'category' },
        { sortable: true, text: this.t('header.target'), value: 'target' },
        { sortable: true, text: this.t('header.zone'), value: 'zone' }
      ]

      for (const night of this.nights) {
        headers.push({
          sortable: false,
          text: dateFormat(night, 'dd/MM'),
          value: this.dateAsKey(night),
          width: 80
        })
      }

      headers.push({ sortable: false, text: '', value: 'comments', width: 40 })

      return headers
    },
    items() {
      if (!(this.pendingOvernightStays || []).length) return []

      const items = []

      const staysByParticipant = this.pendingOvernightStays.reduce((acc, stay) => {
        if (!stay.hasOwnProperty(stay.participantUuid)) {
          acc[stay.participantUuid] = []
        }

        acc[stay.participantUuid].push(stay)

        return acc
      }, {})

      for (const participantUuid in staysByParticipant) {
        const participantStays = staysByParticipant[participantUuid]
        const [stay] = participantStays

        const item = {
          participantUuid,
          lastName: stay.participant.lastName,
          firstName: stay.participant.firstName,
          category: this.categoriesById[stay.participant.category],
          zone: this.$get(stay.participant, 'healthCareProfessional.zone.label', null),
          target: this.computeTarget(stay.participant),
          ...this.computeParticipantOvernightStays(stay.participant),
          comments: this.computeComments(stay.participant)
        }

        items.push(item)
      }

      return items
    },
    waitingPeople() {
      const participants = (this.pendingOvernightStays || []).reduce(
        (set, stay) => set.add(stay.participantUuid),
        new Set()
      )

      return Array.from(participants).length
    },
    categoriesById() {
      if (!this.gathering) return {}

      const categories = Categories.forVersion(this.gathering.categoryVersion)

      return categories.reduce((acc, category) => {
        acc[category.id] = category
        return acc
      })
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    }
  },
  methods: {
    t(key, params) {
      return this.pt(`participantTable.${key}`, params)
    },
    pt(key, params) {
      return this.$t(`mybb.hostingList.${key}`, params)
    },
    dateAsKey(date) {
      if (!date) return null

      return dateFormat(new Date(date), 'yyyy-MM-dd')
    },
    computeTarget(participant) {
      const businessUnit = this.$get(participant, 'healthCareProfessional.businessUnit', null)

      if (!businessUnit) return null

      const targetBu = this.$const.targetBu[businessUnit.label]

      if (!targetBu) return null

      return `${businessUnit.externalId}_${this.$get(participant, ['healthCareProfessional', targetBu])}`
    },
    computeParticipantOvernightStays(participant) {
      const result = {}

      for (const night of this.nights) {
        const keyDate = this.dateAsKey(night)

        const pendingOvernightStaysForDate = this.pendingOvernightStays.filter(
          stay => stay.date === keyDate && stay.participantUuid === participant.participantUuid
        )

        result[keyDate] = pendingOvernightStaysForDate.length > 0
      }

      return result
    },
    computeComments(participant) {
      return this.$get(participant, 'participantComments', []).find(
        comment => comment.reason === this.$const.participantCommentReason.REQUIRE_HOSTING
      )
    }
  }
}
</script>

<style lang="scss">
.Table {
  border-radius: 8px;

  thead th {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--v-mybb-primary-base) !important;
  }

  tbody td {
    color: var(--v-mybb-primary-base);
  }

  th .v-simple-checkbox .v-icon,
  td .v-simple-checkbox .v-icon {
    color: var(--v-mybb-primary-lighten1-base);
  }
}
</style>
