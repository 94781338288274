<template>
  <modal v-model="modal" :title="isInternalNote ? t('titleInternal') : t('title')">
    <template v-slot:text>
      <!-- Lines recap -->
      <div class="mb-5" v-if="!isInternalNote">
        <mybb-text>{{ t('header') }}</mybb-text>
      </div>
      <!-- Comment -->
      <div v-if="!isInternalNote">
        <mybb-text weight="bold">{{ t('commentHeader') }}</mybb-text>
      </div>

      <div class="mt-3" v-if="!isInternalNote">
        <mybb-textarea v-model="comment" :label="t('commentLabel')" rows="3" icon="mdi-comment-text" />
      </div>

      <!-- Double validation checkbox -->
      <div v-if="!isInternalNote" class="mt-3">
        <v-checkbox v-model="doubleConfirmation" :label="t('checkboxLabel')" dense />
      </div>

      <!-- Buttons -->
      <v-row class="mt-10" justify="center">
        <mybb-btn class="mr-10" color="mybb-primary" @click="closeModal">
          {{ t('cancel') }}
        </mybb-btn>

        <mybb-btn :disabled="!canRefuse" color="mybb-error" :loading="loading" @click="refuse">
          {{ isInternalNote ? t('refuseInternal') : t('refuse') }}
        </mybb-btn>
      </v-row>
    </template>
  </modal>
</template>

<script>
import { REFUSE_EXPENSE_NOTE } from '@/graphql/ExpenseNote/ExpenseNote'

import Modal from '../Modal.vue'

export default {
  name: 'RefusalModal',
  components: { Modal },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    note: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      comment: '',
      doubleConfirmation: false,
      loading: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)

        // Side effect to clear the comment field after the modal is closed
        this.$nextTick(() => {
          if (!value) {
            this.doubleConfirmation = false
            this.comment = ''
          }
        })
      }
    },
    canRefuse() {
      return this.isInternalNote || (this.doubleConfirmation && this.comment)
    },
    isInternalNote() {
      const { INTERNAL } = this.$const.noteOrigin

      return this.$get(this.note, 'origin') === INTERNAL
    }
  },
  methods: {
    closeModal() {
      this.modal = false
      this.doubleConfirmation = false
    },
    t(key, params) {
      return this.$t(`mybb.expenseNoteDetails.refusalModal.${key}`, params)
    },
    async refuse() {
      if (this.loading) return
      this.loading = true

      await this.$apollo.mutate({
        mutation: REFUSE_EXPENSE_NOTE,
        variables: { expenseNoteUuid: this.note.expenseNoteUuid, comment: this.comment }
      })

      this.$router.push({
        name: 'ExpenseNoteList',
        params: {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      })
    }
  }
}
</script>
