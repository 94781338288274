<template>
  <side-panel
    :cypress="$cy.participant.gathering.activities.container"
    :title="t('title')"
    class="ActivitiesPanel"
    open
  >
    <v-data-table :headers="headers" :items="items" item-key="participantUuid" hide-default-footer>
      <template v-slot:[`item.isOrator`]="{ value, item }">
        <v-icon
          v-cy="$cy.participant.gathering.activities.item(item.name).isOrator"
          v-if="value"
          color="mybb-primary-lighten1"
        >
          mdi-microphone
        </v-icon>
      </template>

      <template v-slot:[`item.name`]="{ value, item }">
        <mybb-text v-cy="$cy.participant.gathering.activities.item(item.name).name" weight="bold">
          {{ value }}
        </mybb-text>
      </template>

      <template v-slot:[`item.category`]="{ value }">
        <mybb-text weight="bold">
          {{ value }}
        </mybb-text>
      </template>

      <template v-slot:[`item.organizer`]="{ value }">
        <mybb-text>{{ $t(`mybb.activity.organizer.${value}`) }}</mybb-text>
      </template>

      <template v-slot:[`item.date`]="{ value }">
        <mybb-text>{{ value | date }}</mybb-text>
      </template>

      <template v-slot:[`item.schedule`]="{ value }">
        <mybb-text>{{ Array.isArray(value) ? value.join(' - ') : value }}</mybb-text>
      </template>

      <template v-slot:[`item.presence`]="{ value }">
        <v-icon v-if="value" :color="value.color">
          {{ value.icon }}
        </v-icon>
      </template>

      <template v-slot:[`item.activityUuid`]="{ value }">
        <v-icon color="mybb-grey-lighten1" @click="openActivity(value)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </side-panel>
</template>

<script>
import dateFormat from 'date-fns/format'
import dateCompare from 'date-fns/compareAsc'

import SidePanel from '../ui/SidePanel'

export default {
  name: 'ActivitiesPanel',
  components: { SidePanel },
  props: {
    participant: {
      type: Object,
      validator(prop) {
        return !prop || (prop && Array.isArray(prop.activityParticipants))
      }
    },
    gathering: {
      type: Object
    }
  },
  computed: {
    isCongress() {
      return Boolean(this.$get(this.gathering.congress))
    },
    headers() {
      if (!this.gathering) return []

      const headers = [
        { sortable: false, text: '', value: 'isOrator', width: 60 },
        { sortable: false, text: this.t('headers.name'), value: 'name' },
        { sortable: false, text: this.t('headers.category'), value: 'category' }
      ]

      if (this.gathering.congress) {
        headers.push({ sortable: false, text: this.t('headers.organizer'), value: 'organizer' })

        if (this.gathering.isPhysical && this.gathering.isVirtual) {
          headers.push({ sortable: false, text: this.t('headers.participationType'), value: 'participationType' })
        }
      }

      headers.push(
        { sortable: false, text: this.t('headers.date'), value: 'date' },
        { sortable: false, text: this.t('headers.schedule'), value: 'schedule' },
        { sortable: false, text: this.t('headers.presence'), value: 'presence' },
        { sortable: false, text: '', value: 'activityUuid' }
      )

      return headers
    },
    items() {
      if (!this.participant) return []

      return Array.from(this.participant.activityParticipants)
        .sort((ap1, ap2) =>
          dateCompare(this.buildActivityStartDate(ap1.activity), this.buildActivityStartDate(ap2.activity))
        )
        .map(activityParticipant => {
          const schedule =
            activityParticipant.activity.startHour && activityParticipant.activity.endHour
              ? [activityParticipant.activity.startHour, activityParticipant.activity.endHour]
              : '- -'

          return {
            activityUuid: activityParticipant.activityUuid,
            isOrator: activityParticipant.isOrator,
            name: activityParticipant.activity.name,
            category: activityParticipant.activity.activityCategory.label,
            organizer: activityParticipant.activity.organizer,
            participationType: this.computeParticipationType(activityParticipant),
            date: activityParticipant.activity.date,
            schedule,
            presence: this.computePresence(activityParticipant)
          }
        })
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.activitiesPanel.${key}`, params)
    },
    computePresence(activityParticipant) {
      const { absent, noShow, present } = this.$const.presence

      switch (activityParticipant.presence) {
        case absent:
          return { icon: 'mdi-close', color: 'mybb-error' }

        case noShow:
          return { icon: 'mdi-account-question-outline', color: 'mybb-warning' }

        case present:
          return { icon: 'mdi-check', color: 'mybb-primary-lighten1' }

        default:
          return { icon: 'mdi-clock-outline', color: 'mybb-grey-lighten1' }
      }
    },
    computeParticipationType(activityParticipant) {
      return activityParticipant.participationType
        ? this.$t(`mybb.activity.detail.table.participationType.${activityParticipant.participationType}`)
        : null
    },
    buildActivityStartDate(activity) {
      if (!activity) return null

      const date = new Date(activity.date)
      if (activity.startHour) {
        const [hour, min] = activity.startHour.split(':')
        date.setHours(hour, min, 0, 0)
      }

      return date
    },
    openActivity(activityUuid) {
      const route = this.$router.resolve({ name: 'ActivityDetail', params: { activityUuid } })

      window.open(route.href, '_blank')
    }
  },
  filters: {
    date(value) {
      if (!value) return '- -'

      return dateFormat(new Date(value), 'dd/MM/yy')
    }
  }
}
</script>

<style lang="scss">
.ActivitiesPanel {
  thead th {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--v-mybb-primary-base) !important;
  }
}
</style>
