<template>
  <status-information-tile
    :title="t('title')"
    :informations="informations"
    :color="color"
    :date="$get(informationStatus, 'date')"
  >
    <mybb-text :class="`${color}--text`" size="16" weight="bold">
      {{ statusLabel }}
    </mybb-text>
  </status-information-tile>
</template>

<script>
import StatusInformationTile from '../../ui/StatusInformationTile'

export default {
  name: 'TransportTile',
  components: { StatusInformationTile },
  props: {
    participant: Object,
    informationStatus: Object
  },
  computed: {
    status() {
      return this.$get(this.informationStatus, 'newStatus', null)
    },
    informations() {
      const initiator = this.$get(this.informationStatus, 'initiator')

      return initiator ? this.t('informations', initiator) : null
    },
    color() {
      return this.status ? this.$const.transport.colors[this.status] : 'mybb-grey-lighten1'
    },
    statusLabel() {
      return this.status ? this.t(`status.${this.status}`) : '- -'
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.synthesisPanel.transport.${key}`, params)
    }
  }
}
</script>
