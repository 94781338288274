import { object, string, number } from 'yup'

import CONST from '@/const/shared'

function invitationTypeTest(invitationType, { options: { context } }) {
  const { eventType } = context.biogenEvent

  const { MEDICAL, MEDICAL_UP_LETTER, COMMERCIAL } = CONST.biogenEventInvitationType

  return eventType === MEDICAL ? [MEDICAL, MEDICAL_UP_LETTER].includes(invitationType) : invitationType === COMMERCIAL
}

const gatheringSchema = object()
  .noUnknown()
  .shape({})

export const congressSchema = gatheringSchema.shape({
  congress: object()
    .noUnknown()
    .shape({})
})

export const biogenEventSchema = gatheringSchema.shape({
  biogenEvent: object()
    .noUnknown()
    .shape({
      availablePlace: number()
        .min(0)
        .required(),
      invitationType: string()
        .oneOf(Object.values(CONST.biogenEventInvitationType))
        .test({
          name: 'invitation-type',
          test: invitationTypeTest
        })
    })
})

export default gatheringSchema
