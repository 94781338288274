import gql from 'graphql-tag'

import { FULL_VIRTUAL_SESSION, FULL_VIRTUAL_SESSION_WITH_TEAM, LIGHT_VIRTUAL_SESSION } from '../fragments'

export const GET_FULL_VIRTUAL_SESSION = gql`
  query virtualSession($virtualSessionUuid: GUID!) {
    virtualSession(virtualSessionUuid: $virtualSessionUuid) {
      ...FullVirtualSession
    }
  }
  ${FULL_VIRTUAL_SESSION}
`

export const GET_FULL_VIRTUAL_SESSION_WITH_TEAM = gql`
  query virtualSession($virtualSessionUuid: GUID!) {
    virtualSession(virtualSessionUuid: $virtualSessionUuid) {
      ...FullVirtualSessionWithTeam
    }
  }
  ${FULL_VIRTUAL_SESSION_WITH_TEAM}
`

export const VIRTUAL_SESSION_CONGREX = gql`
  query virtualSession($virtualSessionUuid: GUID!) {
    virtualSession(virtualSessionUuid: $virtualSessionUuid) {
      ...FullVirtualSessionWithTeam
      virtualSessionParticipants {
        virtualSessionParticipantUuid
        firstName
        lastName
        presence
        subscription
        postalCode
        city
        type
        healthCareProfessional {
          healthCareProfessionalUuid
          businessUnit {
            businessUnitUuid
            label
            externalId
          }
          zone {
            zoneUuid
            label
          }
          targetBbu
          targetKme
          targetMs
          targetSma
        }
      }
    }
  }
  ${FULL_VIRTUAL_SESSION_WITH_TEAM}
`

export const GET_LIGHT_VIRTUAL_SESSION = gql`
  query virtualSession($virtualSessionUuid: GUID!) {
    virtualSession(virtualSessionUuid: $virtualSessionUuid) {
      ...LightVirtualSession
    }
  }
  ${LIGHT_VIRTUAL_SESSION}
`
