<template>
  <div>
    <mybb-text class="mb-4">{{ t('subtitle') }}</mybb-text>

    <div>
      <mybb-text>{{ t('availablePlaceSectionLabel') }}</mybb-text>
      <v-row>
        <v-col cols="6">
          <mybb-text-field
            v-model.number="availablePlace"
            :label="t('availablePlaceLabel')"
            :error-messages="collect('biogenEvent.availablePlace')"
            :disabled="disableAll"
            v-cy="$cy.gathering.form.availablePlace"
            type="number"
          />
        </v-col>
      </v-row>
    </div>

    <mybb-radios
      v-model="invitationType"
      :cypress="$cy.gathering.form.invitationType"
      :label="t('invitationTypeLabel')"
      :items="invitationTypeItems"
      :error-messages="collect('biogenEvent.invitationType')"
      :disabled="disableAll"
    />
  </div>
</template>

<script>
import { yup } from '@/mixins'

import { congressSchema, biogenEventSchema } from '@/validations/gathering/place'

export default {
  name: 'PlaceStep',
  components: {},
  mixins: [yup],
  model: {
    prop: 'event'
  },
  props: {
    event: {
      type: Object
    },
    isCongress: {
      type: Boolean
    },
    disableAll: {
      type: Boolean
    }
  },
  data() {
    return {
      errors: []
    }
  },
  computed: {
    schema() {
      return this.isCongress ? congressSchema : biogenEventSchema
    },
    invitationTypeItems() {
      const { MEDICAL, MEDICAL_UP_LETTER, COMMERCIAL } = this.$const.biogenEventInvitationType

      if (this.event.biogenEvent.eventType === MEDICAL) {
        return [
          { value: MEDICAL, label: this.$t(`mybb.global.biogenEventInvitationType.${MEDICAL}`) },
          { value: MEDICAL_UP_LETTER, label: this.$t(`mybb.global.biogenEventInvitationType.${MEDICAL_UP_LETTER}`) }
        ]
      }

      return [{ value: COMMERCIAL, label: this.$t(`mybb.global.biogenEventInvitationType.${COMMERCIAL}`) }]
    },
    availablePlace: {
      get() {
        return this.event.biogenEvent.availablePlace
      },
      set(value) {
        this.setBiogenEventProperty('availablePlace', value)
        this.clear('biogenEvent.availablePlace')
      }
    },
    invitationType: {
      get() {
        return this.event.biogenEvent.invitationType
      },
      set(value) {
        this.setBiogenEventProperty('invitationType', value)
        this.clear('biogenEvent.invitationType')
      }
    }
  },
  methods: {
    validate() {
      return this.validateSchema(this.event)
    },
    t(key, params) {
      return this.$t(`mybb.placeStep.${key}`, params)
    },
    setProperties(partial) {
      this.$emit('input', { ...this.event, ...partial })
    },
    setProperty(property, value) {
      this.setProperties({ [property]: value })
    },
    setBiogenEventProperty(property, value) {
      this.setProperties({ ...this.event, biogenEvent: { ...this.event.biogenEvent, [property]: value } })
    }
  },
  mounted() {
    const { MEDICAL } = this.$const.biogenEventInvitationType

    if (!this.invitationType && this.event.biogenEvent.eventType === MEDICAL) {
      this.setBiogenEventProperty('invitationType', MEDICAL)
    }
  }
}
</script>
