<template>
  <modal v-model="modal" :title="t('title')">
    <mybb-text>{{ t('text') }}</mybb-text>

    <mybb-text v-if="showWarning" class="mybb-warning--text my-2">
      <v-icon color="mybb-warning">mdi-alert</v-icon>
      {{ t('warning') }}
    </mybb-text>

    <!-- Buttons -->
    <div class="d-flex justify-center mt-5">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">{{ t('cancel') }}</mybb-btn>
      <mybb-btn :loading="loading" color="mybb-success" @click="save">
        {{ t('confirm') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import Modal from '../Modal'

export default {
  components: { Modal },
  name: 'ShareHostingsModal',
  model: { prop: 'visible' },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    pendingOvernightStays: {
      type: Array
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)

        this.$nextTick(() => {
          this.loading = false
        })
      }
    },
    showWarning() {
      return !Array.isArray(this.pendingOvernightStays) || this.pendingOvernightStays.length > 0
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hostingList.shareModal.${key}`, params)
    },
    save() {
      this.loading = true

      this.$emit('confirm')
    }
  }
}
</script>
