import gql from 'graphql-tag'

import { FULL_OSEUS_EVENT } from '../fragments'

export const LIST_OSEUS_EVENTS = gql`
  query listOseusEvents {
    oseusEvents {
      ...FullOseusEvent
    }
  }
  ${FULL_OSEUS_EVENT}
`
