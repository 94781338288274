<template>
  <div>
    <v-row v-if="index > 0" class="my-4" justify="center">
      <v-col class="pa-0 ma-0">
        <v-divider />
      </v-col>
    </v-row>

    <div>
      <mybb-text weight="semi-bold" class="mb-4">{{ t('title', { index: index + 1 }) }}</mybb-text>
    </div>

    <favorite-travel v-if="canEdit" :type="type" @set-from-favorite="setFromFavorite" class="mt-2" @clear="clear" />

    <!-- Travel mode + ticket number -->
    <v-row :class="'pa-0 ma-0 ' + (canEdit ? 'mt-6' : '')" align="center">
      <v-col cols="4" md="4" class="pa-0 pl-2">
        <v-radio-group class="mt-0" v-model="mode" hide-details dense row>
          <v-radio :disabled="!canEdit" :label="t('modes.TRAIN')" :value="$const.transport.modes.TRAIN" />
          <v-radio :disabled="!canEdit" :label="t('modes.PLANE')" :value="$const.transport.modes.PLANE" />
        </v-radio-group>
      </v-col>

      <v-col cols="8" md="8" class="pa-0">
        <mybb-text-field
          v-model="ticketNumber"
          :disabled="!canEdit"
          :label="ticketNumberLabel"
          :icon="ticketNumberIcon"
        />
      </v-col>
    </v-row>

    <!-- Places -->
    <v-row class="pa-0 ma-0 mt-6">
      <v-col cols="6" md="6" class="pa-0 pr-2">
        <mybb-text-field
          v-model="departurePlace"
          :disabled="!canEdit"
          :label="t('departurePlace')"
          icon="mdi-map-marker"
        />
      </v-col>

      <v-col cols="6" md="6" class="pa-0">
        <mybb-text-field v-model="arrivalPlace" :disabled="!canEdit" :label="t('arrivalPlace')" icon="mdi-map-marker" />
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0 mt-6">
      <v-col cols="3" md="3" class="pa-0 pr-1">
        <date-picker v-model="departureDate" :disabled="!canEdit" :label="t('departureDate')" />
      </v-col>

      <v-col cols="3" md="3" class="pa-0 px-1">
        <time-picker v-model="departureTime" :disabled="!canEdit" :label="t('departureTime')" />
      </v-col>

      <v-col cols="3" md="3" class="pa-0 px-1">
        <date-picker v-model="arrivalDate" :disabled="!canEdit" :label="t('arrivalDate')" :min="departureDate" />
      </v-col>

      <v-col cols="3" md="3" class="pa-0 pl-1">
        <time-picker v-model="arrivalTime" :disabled="!canEdit" :label="t('arrivalTime')" />
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0 mt-6 mb-4" justify="space-between" v-if="canEdit">
      <mybb-btn
        color="white"
        @click="$emit('add-step', index)"
        inner-icon="mdi-plus"
        class="mybb-primary-lighten1--text"
      >
        {{ t('addGroup') }}
      </mybb-btn>

      <mybb-btn
        v-if="index > 0"
        color="white"
        class="mybb-error--text"
        @click="showDeleteConfirm = true"
        inner-icon="mdi-trash-can-outline"
      >
        {{ t('deleteGroup') }}
      </mybb-btn>
    </v-row>

    <!-- Deletion modal confirmation -->
    <modal :title="t('deleteModal.title')" v-model="showDeleteConfirm">
      <template v-slot:text>
        <v-row class="pa-0 ma-0" justify="center">
          <v-btn
            class="text-none mybb-button-14 mr-7"
            color="mybb-grey"
            height="46"
            min-width="200"
            dark
            @click="showDeleteConfirm = false"
          >
            <span>{{ t('deleteModal.cancel') }}</span>
          </v-btn>

          <v-btn
            class="text-none mybb-button-14"
            color="mybb-error"
            dark
            height="46"
            min-width="200"
            @click="removeStep"
          >
            <span>{{ t('deleteModal.confirm') }}</span>
          </v-btn>
        </v-row>
      </template>
    </modal>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

import DatePicker from '@/components/mybb/ui/DatePicker'
import TimePicker from '@/components/mybb/ui/TimePicker'

import Modal from '../Modal'
import FavoriteTravel from './FavoriteTravel'

export default {
  components: { FavoriteTravel, DatePicker, TimePicker, Modal },
  name: 'TravelForm',
  model: {
    prop: 'travel',
    event: 'change'
  },
  props: {
    canEdit: {
      type: Boolean,
      required: false,
      default: true
    },
    index: {
      type: Number,
      required: true
    },
    travel: {
      type: Object,
      required: false,
      default: () => ({})
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showDeleteConfirm: false
    }
  },
  computed: {
    ticketNumberLabel() {
      switch (this.mode) {
        case this.$const.transport.modes.PLANE:
          return this.t('ticketNumber.PLANE')

        case this.$const.transport.modes.TRAIN:
        default:
          return this.t('ticketNumber.TRAIN')
      }
    },
    ticketNumberIcon() {
      const { modeIcons, modes } = this.$const.transport
      const icon = this.$get(modeIcons, this.mode, modeIcons[modes.TRAIN])

      return `mdi-${icon}`
    },
    // Travel computeds for v-model
    mode: {
      get() {
        return this.$get(this.travel, 'mode', null)
      },
      set(mode) {
        this.$emit('change', { ...this.travel, mode })
      }
    },
    ticketNumber: {
      get() {
        return this.$get(this.travel, 'ticketNumber', null)
      },
      set(ticketNumber) {
        this.$emit('change', { ...this.travel, ticketNumber })
      }
    },
    departurePlace: {
      get() {
        return this.$get(this.travel, 'departurePlace', null)
      },
      set(departurePlace) {
        this.$emit('change', { ...this.travel, departurePlace })
      }
    },
    arrivalPlace: {
      get() {
        return this.$get(this.travel, 'arrivalPlace', null)
      },
      set(arrivalPlace) {
        this.$emit('change', { ...this.travel, arrivalPlace })
      }
    },
    departureDate: {
      get() {
        return this.$get(this.travel, 'departureDate', null)
      },
      set(departureDate) {
        // [departureDate] changes reset the [arrivalDate] field
        this.$emit('change', { ...this.travel, departureDate, arrivalDate: null })
      }
    },
    departureTime: {
      get() {
        return this.$get(this.travel, 'departureTime', null)
      },
      set(departureTime) {
        this.$emit('change', { ...this.travel, departureTime })
      }
    },
    arrivalDate: {
      get() {
        return this.$get(this.travel, 'arrivalDate', null)
      },
      set(arrivalDate) {
        this.$emit('change', { ...this.travel, arrivalDate })
      }
    },
    arrivalTime: {
      get() {
        return this.$get(this.travel, 'arrivalTime', null)
      },
      set(arrivalTime) {
        this.$emit('change', { ...this.travel, arrivalTime })
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transportTravelModal.step.${key}`, params)
    },
    removeStep() {
      this.$emit('remove-step', this.index)
      this.showDeleteConfirm = false
    },
    setFromFavorite(travel) {
      if (!travel) return

      delete travel.__typename
      delete travel.travelUuid

      this.$emit('change', travel)
    },
    clear() {
      const payload = this.travel && this.travel.travelUuid ? { travelUuid: this.travel.travelUuid } : null

      this.$emit('change', payload)
    }
  },
  filters: {
    date(value) {
      if (!value) return null

      return dateFormat(new Date(value), 'dd/MM/yy')
    }
  }
}
</script>
