import dateFormat from 'date-fns/format'
import _set from 'lodash/set'
import _get from 'lodash/get'
import _difference from 'lodash/difference'

import store from '@/store'
import CONST from '@/const/shared'

function flattenObject(object, target = {}, prefix = []) {
  if (typeof object !== 'object' || !object) return object

  for (const property in object) {
    if (typeof object[property] === 'object' && object[property] !== null) {
      if (!Array.isArray(object[property])) {
        flattenObject(object[property], target, [...prefix, property])
      } else {
        target[prefix.concat(property).join('.')] = object[property].map(item => flattenObject(item))
      }
    } else {
      target[prefix.concat(property).join('.')] = object[property]
    }
  }

  return target
}

/**
 * @param {string} field
 * @param {string[]} fieldSkipCollection
 * @returns {boolean}
 */
function keepField(field, fieldSkipCollection = []) {
  return !fieldSkipCollection.includes(field)
}

/**
 * Build the pojo which register the sensible changes of the gathering
 * @param {Object<string, any>} event
 * @param {string[]} sensibleFields
 * @returns {Object<string, any>}
 */
export function buildSensibleChangesPayload(event, sensibleFields = []) {
  const payload = {}
  const flatEvent = flattenObject(event)

  for (const field of sensibleFields) {
    if (!flatEvent.hasOwnProperty(field)) continue

    _set(payload, field, _get(event, field))
  }

  // Users edge case - only keep BU related users
  if (Array.isArray(payload.users)) {
    payload.users = payload.users.filter(user => user.businessUnitUuid)

    if (payload.users.length <= 0) delete payload.users
  }

  return payload
}

/**
 * Map the gathering builded from the form to a GatheringInput
 * @param {Object<string, any>} payload
 * @param {Object<string, any>} rawGathering
 * @param {boolean} isCongress
 * @param {string[]} withoutFields
 * @returns {Object<string, any>}
 */
export function mapGatheringPayload(payload, rawGathering, isCongress, withoutFields = []) {
  const additional = {}

  const gathering = { gatheringUuid: payload.gatheringUuid }

  // Informations
  gathering.beginDate = dateFormat(payload.beginDate, 'yyyy-MM-dd')
  if (keepField('description', withoutFields)) gathering.description = payload.description
  gathering.endDate = dateFormat(payload.endDate, 'yyyy-MM-dd')
  gathering.eventSchedule = [payload.beginHour, payload.endHour]
  gathering.isPhysical = payload.isPhysical
  gathering.isVirtual = payload.isVirtual
  gathering.limitDate = payload.limitDate ? dateFormat(payload.limitDate, 'yyyy-MM-dd') : null
  if (keepField('location', withoutFields)) gathering.location = payload.location
  gathering.name = payload.name
  if (keepField('timezone', withoutFields)) gathering.timezone = payload.timezone
  if (keepField('mainBusinessUnit', withoutFields)) gathering.mainBusinessUnitUuid = payload.mainBusinessUnitUuid
  if (keepField('businessUnitUuids', withoutFields)) gathering.businessUnitUuids = payload.businessUnitUuids
  if (keepField('visioUrl', withoutFields)) gathering.visioUrl = payload.visioUrl
  // Teams
  if (keepField('users', withoutFields)) {
    gathering.users = payload.users
  } else {
    // Only keep non-business related users for the update with skip
    // Or user which didn't changed
    gathering.users = payload.users.filter(
      user =>
        !user.businessUnitUuid ||
        (rawGathering.users || []).some(
          usr =>
            usr.gatheringUserType === user.gatheringUserType &&
            usr.isBackup === user.isBackup &&
            usr.userUuid === user.userUuid &&
            usr.businessUnitUuid &&
            usr.businessUnitUuid === user.businessUnitUuid
        )
    )

    // Ensure CP/DM is registered from the current gathering
    const gatheringUserType = isCongress
      ? CONST.gatheringUserType.MEDICAL_DIRECTOR
      : CONST.gatheringUserType.PROJECT_MANAGER

    const alreadyDefined = gathering.users.some(
      usr =>
        usr.businessUnitUuid === rawGathering.mainBusinessUnitUuid &&
        usr.gatheringUserType === gatheringUserType &&
        !usr.isBackup
    )

    if (!alreadyDefined) {
      const currentResponsible = rawGathering.users.find(
        usr =>
          usr.businessUnitUuid === rawGathering.mainBusinessUnitUuid &&
          usr.gatheringUserType === gatheringUserType &&
          !usr.isBackup
      )
      gathering.users.push({
        businessUnitUuid: rawGathering.mainBusinessUnitUuid,
        gatheringUserType,
        isBackup: false,
        userUuid: currentResponsible.userUuid
      })
    }
  }

  // Documents - Only add them to the payload if needed
  const files = getGatheringFilesChanged(payload.gatheringFiles, _get(rawGathering, 'gatheringFiles', null))
  if (Array.isArray(files)) {
    gathering.files = files
  }

  if (isCongress) {
    const { congress } = payload

    // Informations
    additional.congressUuid = congress.congressUuid
    if (keepField('congress.additionalLinks', withoutFields)) additional.additionalLinks = congress.additionalLinks
    if (keepField('congress.learnedSociety', withoutFields)) additional.learnedSociety = congress.learnedSociety
    if (keepField('congress.nationalityType', withoutFields)) additional.nationalityType = congress.nationalityType
    additional.symposiumProgramFile = congress.symposiumProgramFile
    if (keepField('congress.websiteUrl', withoutFields)) additional.websiteUrl = congress.websiteUrl
    // Quotas
    if (
      keepField('gatheringBusinessUnits', withoutFields) &&
      keepField('congress.zoneCongresses', withoutFields) &&
      keepField('congress.criterias', withoutFields) &&
      keepField('users', withoutFields)
    )
      additional.businessUnitSetups = mapGatheringBusinessUnits(payload)
  } else {
    const { biogenEvent } = payload

    if (keepField('biogenEvent.availablePlace', withoutFields)) additional.availablePlace = biogenEvent.availablePlace
    if (keepField('biogenEvent.eventType', withoutFields)) additional.eventType = biogenEvent.eventType
    if (keepField('biogenEvent.hasSpecialtyQuota', withoutFields))
      additional.hasSpecialtyQuota = biogenEvent.hasSpecialtyQuota
    additional.invitationEndDate = biogenEvent.invitationEndDate
    if (keepField('biogenEvent.invitationType', withoutFields)) additional.invitationType = biogenEvent.invitationType
  }

  return { gathering, [isCongress ? 'congressEvent' : 'biogenEvent']: additional }
}

function mapGatheringBusinessUnits(payload) {
  const { gatheringBusinessUnits, businessUnitUuids } = payload

  if (!Array.isArray(gatheringBusinessUnits)) return null

  const gatheringBusinessUnitMapped = gatheringBusinessUnits.map(gbu => ({
    businessUnitUuid: gbu.businessUnitUuid,
    solicitationEndDate: gbu.solicitationEndDate ? dateFormat(gbu.solicitationEndDate, 'yyyy-MM-dd') : null,
    doctorQuota: gbu.doctorQuota,
    hasZoneQuota: gbu.hasZoneQuota,
    hasAutomatedValidation: gbu.hasAutomatedValidation,
    mslResponsibleCriteria: gbu.mslResponsibleCriteria,
    otherDisciplinesQuota: gbu.otherDisciplinesQuota,
    criterias: getCriteriasFromGatheringBusinessUnit(payload, gbu),
    zoneCongresses: getZoneCongressFromGatheringBusinessUnit(payload, gbu)
  }))

  const gbuMappedBusinessUnitUuids = gatheringBusinessUnitMapped.map(gbu => gbu.businessUnitUuid)

  if (businessUnitUuids.some(bu => !gbuMappedBusinessUnitUuids.includes(bu))) {
    const emptyGatheringBusinessUnits = businessUnitUuids
      .filter(businessUnitUuid => !gbuMappedBusinessUnitUuids.includes(businessUnitUuid))
      .map(businessUnitUuid => ({ businessUnitUuid }))

    if (emptyGatheringBusinessUnits.length) {
      gatheringBusinessUnitMapped.push(...emptyGatheringBusinessUnits)
    }
  }

  return gatheringBusinessUnitMapped
}

function getCriteriasFromGatheringBusinessUnit(event, gatheringBusinessUnit) {
  const { businessUnitUuid } = gatheringBusinessUnit
  const businessUnit = (store.state.bus || []).find(bu => bu.businessUnitUuid === businessUnitUuid)

  if (!businessUnit) return null

  const criterias = CONST.targetBusinessUnitWithLabel[businessUnit.label]

  if (!criterias) return null

  const availableCriterias = Object.keys(criterias)

  return (event.congress.criterias || []).filter(criteria => availableCriterias.includes(criteria))
}

function getZoneCongressFromGatheringBusinessUnit(event, gatheringBusinessUnit) {
  const { businessUnitUuid } = gatheringBusinessUnit
  const zoneCongresses = event.congress.zoneCongresses || []

  if (!businessUnitUuid || !zoneCongresses.length) return null

  return zoneCongresses
    .filter(zc => {
      if (!zc.zone) return false

      return zc.zone.businessUnitUuid === businessUnitUuid
    })
    .map(({ zoneUuid, zoneQuota }) => ({ zoneUuid, quota: zoneQuota }))
}

function getGatheringFilesChanged(payloadFiles, gatheringFiles) {
  if (!gatheringFiles) return payloadFiles

  if (payloadFiles.length !== gatheringFiles.length) return payloadFiles

  if (payloadFiles.some(file => file && file.file && !file.file.fileUuid)) return payloadFiles

  const haveCategoriesChanged = payloadFiles.some(file => {
    const gatheringFile = gatheringFiles.find(gf => gf.fileGatheringUuid === file.fileGatheringUuid)

    if (!gatheringFile) return false

    return (
      _difference(file.categories, gatheringFile.categories).length > 0 ||
      _difference(gatheringFile.categories, file.categories).length > 0
    )
  })

  if (haveCategoriesChanged) return payloadFiles

  return null
}
