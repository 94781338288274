<template>
  <modal v-model="modal" :title="t('title')">
    <mybb-text>{{ t('subtitle') }}</mybb-text>
    <mybb-text weight="bold" class="my-3">{{ t('highlight') }}</mybb-text>
    <mybb-text>{{ t('text') }}</mybb-text>

    <v-row justify="center" class="mt-10">
      <mybb-btn class="mr-10" color="mybb-grey" @click="onClose">
        {{ t('cancel') }}
      </mybb-btn>
      <mybb-btn color="mybb-success" @click="$emit('confirm')">
        {{ t('confirm') }}
      </mybb-btn>
    </v-row>
  </modal>
</template>

<script>
import Modal from '@/components/mybb/Modal'

export default {
  name: 'SensibleChangesModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.$nextTick(() => {
            this.loading = false
          })
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.eventSteps.sensibleChangesModal.${key}`, params)
    },
    onClose() {
      this.$emit('close')
      this.modal = false
    }
  }
}
</script>
