var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"HCPEvent pb-8"},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('mybb-text-field',{attrs:{"background-color":"white","placeholder":_vm.t('searchPlaceholder'),"icon":"mdi-magnify"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),(!_vm.healthCareProfessional)?_c('Loader',{attrs:{"color":"white"}}):_c('div',{staticClass:"Table px-6 pt-5 pb-3"},[_c('mybb-text',{staticClass:"text-uppercase mb-2",attrs:{"weight":"bold","size":"16"}},[_vm._v(" "+_vm._s(_vm.t('tableTitle'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"item-key":"gatheringUuid","items":_vm.events,"search":_vm.searchText,"sort-by":"date","sort-desc":"","hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.orator",fn:function(ref){
var item = ref.item;
return [(item.hasOratorCategory)?_c('img',{staticClass:"mt-2",attrs:{"alt":"orator","height":"20px","src":require("@/assets/ionic-ios-microphone-blue.svg")}}):_vm._e()]}},{key:"item.name",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(value))])]}},{key:"item.status",fn:function(ref){
var status = ref.value;
return [_c('v-chip',{staticClass:"statusChip py-2 px-4",attrs:{"color":status.color,"dark":""}},[_c('mybb-text',{staticClass:"white--text",attrs:{"size":"12","weight":"semi-bold"}},[_vm._v(" "+_vm._s(_vm.$t(("mybb.eventList.status." + (status.text))))+" ")])],1)]}},{key:"item.type",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(value))])]}},{key:"item.geography",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{class:{ 'mybb-warning--text': _vm.isInternationalGeography(value) },attrs:{"weight":_vm.isInternationalGeography(value) ? 'bold' : 'regular'}},[_vm._v(" "+_vm._s(_vm.getGeographyLabel(value))+" ")])]}},{key:"item.inscription",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":value.color}},[_vm._v(_vm._s(value.icon))]):_vm._e()]}},{key:"item.presence",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":value.color}},[_vm._v(_vm._s(value.icon))]):_vm._e()]}},{key:"item.navigation",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"noStyleLink",attrs:{"to":{ name: 'HCPEventDetails', params: { gatheringUuid: item.gatheringUuid } }}},[_c('v-icon',{attrs:{"color":"mybb-grey-lighten1"}},[_vm._v("mdi-eye")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }