import { object, string, boolean, number, date, array, mixed } from 'yup'
import dateCompare from 'date-fns/differenceInDays'
import _get from 'lodash/get'

import store from '@/store'
import CONST from '@/const/shared'

function getBusinessUnit(businessUnitUuid) {
  return (store.state.bus || []).find(bu => bu.businessUnitUuid === businessUnitUuid)
}

function getZoneCongresses(gathering, businessUnitUuid) {
  return (gathering.congress.zoneCongresses || []).filter(
    zc => zc.zone && zc.zone.businessUnitUuid === businessUnitUuid
  )
}

function solicitationEndDateTest(solicitationEndDate, { options: { context } }) {
  const { beginDate } = context

  return dateCompare(new Date(beginDate), new Date(solicitationEndDate)) >= 0
}

function criteriasByBuTest(_, { parent, options: { context } }) {
  const { criterias } = context.congress
  if (!Array.isArray(criterias)) return false

  const businessUnit = getBusinessUnit(parent.businessUnitUuid)
  if (!businessUnit) return false

  const criteriasForBusinessUnit = CONST.targetBusinessUnitWithLabel[businessUnit.label]
  const criteriaKeys = Object.keys(criteriasForBusinessUnit)

  return criterias.some(criteria => criteriaKeys.includes(criteria))
}

function zoneRepartitionTest(_, { parent, options: { context } }) {
  if (!parent.hasZoneQuota) return true

  const zoneCongresses = getZoneCongresses(context, parent.businessUnitUuid)
  if (!zoneCongresses.length) return false

  const zoneTotal = zoneCongresses.reduce((sum, zoneCongress) => sum + zoneCongress.zoneQuota, 0)

  return zoneTotal === parent.doctorQuota
}

function doctorQuotasTest(quota, { parent, options: { context } }) {
  if (quota <= 0 && _get(parent, 'otherDisciplinesQuota', 0) <= 0) return false

  const solicitationsForBu = _get(context, 'validatedSolicitations', []).filter(
    solicitation =>
      solicitation.businessUnitUuid === parent.businessUnitUuid &&
      solicitation.healthCareProfessional.type === CONST.importedUserTypes.doctor
  )

  return solicitationsForBu.length <= quota
}

function otherDisciplinesQuotaTest(quota, { parent, options: { context } }) {
  const solicitationsForBu = _get(context, 'validatedSolicitations', []).filter(
    solicitation =>
      solicitation.businessUnitUuid === parent.businessUnitUuid &&
      solicitation.healthCareProfessional.type !== CONST.importedUserTypes.doctor
  )

  return solicitationsForBu.length <= quota
}

function zoneCongressSolicitationQuotaTest(zoneCongress, { options: { context } }) {
  const solicitationsForZone = _get(context, 'validatedSolicitations', []).filter(
    solicitation =>
      solicitation.zoneUuid === zoneCongress.zoneUuid &&
      solicitation.healthCareProfessional.type === CONST.importedUserTypes.doctor
  )

  return solicitationsForZone.length <= zoneCongress.zoneQuota
}

const gatheringBusinessUnitSchema = object()
  .noUnknown()
  .shape({
    businessUnitUuid: string().required(),
    doctorQuota: number()
      .test({
        name: 'doctorQuota',
        test: doctorQuotasTest
      })
      .required(),
    hasAutomatedValidation: boolean().required(),
    hasZoneQuota: boolean().required(),
    mslResponsibleCriteria: boolean().required(),
    otherDisciplinesQuota: number()
      .min(0)
      .test({
        name: 'otherDisciplinesQuota',
        test: otherDisciplinesQuotaTest
      })
      .nullable(),
    solicitationEndDate: date()
      .test({
        name: 'solicitation-end-date',
        test: solicitationEndDateTest
      })
      .required(),
    // fake fields
    criterias: mixed().test({
      name: 'criterias',
      test: criteriasByBuTest
    }),
    zones: mixed().test({
      name: 'zones',
      test: zoneRepartitionTest
    })
  })

const zoneCongressSchema = object()
  .noUnknown()
  .shape({
    zoneUuid: string().required(),
    zoneQuota: number().required(),
    zone: object().shape({
      zoneUuid: string(),
      businessUnitUuid: string().required()
    })
  })
  .test({
    name: 'zone-congress-solicitation-quota',
    test: zoneCongressSolicitationQuotaTest
  })

const gatheringSchema = object()
  .noUnknown()
  .shape({
    gatheringBusinessUnits: array()
      .of(gatheringBusinessUnitSchema)
      .min(1)
      .required()
  })

export const congressSchema = gatheringSchema.shape({
  congress: object()
    .noUnknown()
    .shape({
      criterias: array()
        .ensure()
        .of(string().required())
        .required(),
      zoneCongresses: array()
        .ensure()
        .of(zoneCongressSchema)
        .required()
    })
})

export const biogenEventSchema = gatheringSchema.shape({
  biogenEvent: object()
    .noUnknown()
    .shape({})
})

export default gatheringSchema
