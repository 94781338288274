import { object, string, boolean, date, array } from 'yup'
import isSameDay from 'date-fns/isSameDay'

import CONST from '@/const/shared'

import fileSchema from '../file'

function endHourTest(value, { parent }) {
  if (!value || !parent.beginDate || !parent.endDate || !parent.beginHour) return true

  const begin = new Date(parent.beginDate)
  const end = new Date(parent.endDate)

  if (!isSameDay(begin, end)) return true

  const [bHour, bMinute] = parent.beginHour.split(':').map(Number)
  const [eHour, eMinute] = value.split(':').map(Number)

  begin.setHours(bHour, bMinute, 0, 0)
  end.setHours(eHour, eMinute, 0, 0)

  return begin < end
}

const gatheringSchema = object()
  .noUnknown()
  .shape({
    isPhysical: boolean().required(),
    isVirtual: boolean().required(),
    logoFile: fileSchema.nullable(),
    name: string().required(),
    description: string().nullable(),
    beginDate: date().required(),
    endDate: date().required(),
    beginHour: string()
      .matches(/^\d{2}:\d{2}$/)
      .nullable(),
    endHour: string()
      .matches(/^\d{2}:\d{2}$/)
      .test({ test: endHourTest })
      .nullable(),
    timezone: string()
      .matches(/^GMT( [-|+] \d+(\.\d+)?)?$/)
      .nullable(),
    location: string().nullable(),
    limitDate: date().nullable(),
    visioUrl: string().nullable()
  })

export const congressSchema = gatheringSchema.shape({
  congress: object()
    .noUnknown()
    .shape({
      learnedSociety: string().required(),
      nationalityType: string()
        .nullable()
        .test({
          test: (value, { options: { context } }) => {
            if (!context.isPhysical) return true

            return Object.values(CONST.nationalityType).includes(value)
          }
        }),
      websiteUrl: string().nullable(),
      additionalLinks: array().of(string().required())
    })
})

export const biogenEventSchema = gatheringSchema.shape({
  biogenEvent: object()
    .noUnknown()
    .shape({
      eventType: string()
        .oneOf(Object.values(CONST.biogenEventType))
        .required()
    })
})

export default gatheringSchema
