<template>
  <v-card v-if="businessUnitUuid" class="px-5 py-4">
    <v-card-title>
      <v-icon v-if="isMain" color="mybb-primary-lighten1" size="20" class="mr-1">mdi-star</v-icon>
      <mybb-text weight="semi-bold" size="16" class="text-uppercase">{{ businessUnit.externalId }}</mybb-text>
    </v-card-title>

    <v-divider />

    <mybb-text class="mt-5 mb-3">{{ t('solicitationEndDateLabel') }}</mybb-text>
    <v-row>
      <v-col cols="4">
        <date-picker
          v-model="solicitationEndDate"
          :label="t('solicitationEndDate')"
          :min="new Date().toISOString()"
          :max="maxSolicitationEndDate"
          :error-messages="!solicitationEndDate ? errorCollection('solicitationEndDate') : null"
          :disabled="disabled"
          :cypress="$cy.gathering.form.solicitationEndDate(businessUnit.label)"
        />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="6">
        <v-divider />
      </v-col>
    </v-row>

    <mybb-text>{{ t('hcpQuotaTitle') }}</mybb-text>
    <v-row>
      <v-col cols="3">
        <mybb-text-field
          v-model.number="doctorQuota"
          :label="t('hcpQuotaLabel')"
          :error-messages="
            errorCollection('doctorQuota', t('solicitationQuotaError'))
              ? doctorQuota <= 0
                ? $t('customError.required')
                : errorCollection('doctorQuota', t('solicitationQuotaError'))
              : null
          "
          :disabled="disabled"
          v-cy="$cy.gathering.form.doctorQuota(businessUnit.label)"
          type="number"
          icon="mdi-plus"
        />
      </v-col>
    </v-row>

    <mybb-radios
      v-model="hasZoneQuota"
      :label="t('hasZoneQuotaTitle')"
      :items="zoneQuotaItems"
      :error-messages="typeof hasZoneQuota !== 'boolean' ? errorCollection('hasZoneQuota') : null"
      :disabled="disabled || doctorQuota <= 0"
      :cypress="$cy.gathering.form.hasZoneQuota(businessUnit.label)"
      row
    />

    <div v-if="hasZoneQuota">
      <mybb-text class="mt-4">{{ t('zoneQuotaTitle') }}</mybb-text>

      <v-row class="mb-2">
        <v-col v-for="zone of zones" :key="zone.zoneUuid" cols="2">
          <mybb-text-field
            :value="getZoneQuota(zone.zoneUuid)"
            @input="value => setZoneQuota(zone.zoneUuid, value)"
            :label="zone.label"
            :error-messages="
              zoneCongressIndex(zone) !== null
                ? collect(`congress.zoneCongresses[${zoneCongressIndex(zone)}]`, t('solicitationQuotaError'))
                : errorCollection('zones')
            "
            :disabled="disabled"
            v-cy="$cy.gathering.form.zoneQuota(businessUnit.label, zone.label)"
            type="number"
          />
        </v-col>
      </v-row>

      <completion-bar :data="completionData" :total="doctorQuota || 0" />
    </div>

    <v-row class="mt-3 mb-4" justify="center">
      <v-col cols="6">
        <v-divider />
      </v-col>
    </v-row>

    <mybb-radios
      v-model="hasAutomatedValidation"
      :label="t('solicitationValidationTypeTitle')"
      :items="hasAutomatedValidationItems"
      :error-messages="typeof hasAutomatedValidation !== 'boolean' ? errorCollection('hasAutomatedValidation') : null"
      :disabled="disabled"
      :cypress="$cy.gathering.form.hasAutomatedValidation(businessUnit.label)"
      class="mb-4"
      row
    />

    <mybb-text :class="{ 'error--text': errorCollection('criterias') }">{{ t('solicitationCriteriaTitle') }}</mybb-text>
    <div class="d-flex flex-wrap mb-4">
      <v-checkbox
        v-for="criteriaKey in keyOfCriteriaList"
        :key="criteriaKey"
        v-model="criterias"
        :value="criteriaKey"
        :error-messages="errorCollection('criterias')"
        :disabled="disabled"
        class="mr-3 mt-1"
        color="mybb-primary-lighten1"
        hide-details="auto"
        multiple
      >
        <mybb-text
          slot="label"
          :class="{ 'error--text': errorCollection('criterias') }"
          v-cy="$cy.gathering.form.criteria(businessUnit.label, criteriaList[criteriaKey])"
        >
          {{ criteriaList[criteriaKey] }}
        </mybb-text>
      </v-checkbox>
    </div>

    <mybb-radios
      v-model="mslResponsibleCriteria"
      :label="t('mslResponsibleTitle')"
      :items="mslResponsibleCriteriaItems"
      :error-messages="errorCollection('mslResponsibleCriteria')"
      :disabled="disabled"
      :cypress="$cy.gathering.form.mslResponsible(businessUnit.label)"
      row
    />

    <v-row class="mt-3 mb-4" justify="center">
      <v-col cols="6">
        <v-divider />
      </v-col>
    </v-row>

    <mybb-text>{{ t('otherQuotaTitle') }}</mybb-text>
    <v-row>
      <v-col cols="3">
        <mybb-text-field
          v-model.number="otherDisciplinesQuota"
          :label="t('otherQuotaLabel')"
          :error-messages="errorCollection('otherDisciplinesQuota', t('solicitationQuotaError'))"
          :disabled="disabled"
          v-cy="$cy.gathering.form.otherQuota(businessUnit.label)"
          icon="mdi-account-group"
          type="number"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

import DatePicker from '@/components/mybb/ui/DatePicker'
import CompletionBar from '@/components/mybb/completion/CompletionBar'
import { GET_ZONES_FROM_BU } from '@/graphql/Zone/GetZones'

export default {
  name: 'BuQuotaForm',
  components: { DatePicker, CompletionBar },
  inject: ['clear', 'collect', 'revalidate'],
  model: {
    prop: 'event'
  },
  props: {
    event: {
      type: Object
    },
    isMain: {
      type: Boolean
    },
    businessUnitUuid: {
      type: String
    },
    index: {
      type: Number
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    businessUnit() {
      return this.$get(this.$store, 'state.bus', []).find(bu => bu.businessUnitUuid === this.businessUnitUuid)
    },
    zoneCongresses() {
      return this.$get(this.event, 'congress.zoneCongresses', [])
        .filter(zc => zc.zone.businessUnitUuid === this.businessUnitUuid)
        .sort((zc1, zc2) => zc1.zone.label.localeCompare(zc2.zone.label))
    },
    criteriaList() {
      return this.$const.targetBusinessUnitWithLabel[this.businessUnit.label]
    },
    keyOfCriteriaList() {
      return Object.keys(this.criteriaList)
    },
    maxSolicitationEndDate() {
      return new Date(this.event.beginDate).toISOString()
    },
    gatheringBusinessUnits() {
      return this.event.gatheringBusinessUnits || []
    },
    gatheringBusinessUnit() {
      return this.gatheringBusinessUnits.find(gbu => gbu.businessUnitUuid === this.businessUnitUuid)
    },
    zoneQuotaItems() {
      return [{ value: true, label: this.$t('yes') }, { value: false, label: this.$t('no') }]
    },
    completionData() {
      if (!this.hasZoneQuota) return []

      const value = this.zoneCongresses.reduce((acc, { zoneQuota }) => acc + (zoneQuota || 0), 0)

      return [
        {
          value,
          percentage: (100 * value) / this.gatheringBusinessUnit.doctorQuota
        }
      ]
    },
    hasAutomatedValidationItems() {
      return [
        { label: this.t('solicitationValidationAuto'), value: true },
        { label: this.t('solicitationValidationManual'), value: false }
      ]
    },
    mslResponsibleCriteriaItems() {
      return [{ label: this.$t('yes'), value: true }, { label: this.$t('no'), value: false }]
    },
    solicitationEndDate: {
      get() {
        return this.$get(this.gatheringBusinessUnit, 'solicitationEndDate')
      },
      set(value) {
        this.setGatheringBuProperty('solicitationEndDate', value)
      }
    },
    doctorQuota: {
      get() {
        return this.$get(this.gatheringBusinessUnit, 'doctorQuota', null)
      },
      set(value) {
        // Revalidate field
        if (![null, undefined].includes(value)) {
          const errorKey = `gatheringBusinessUnits[${this.index}].doctorQuota`
          this.revalidate(errorKey, value)
        }

        this.setGatheringBuProperty('doctorQuota', value)
      }
    },
    hasZoneQuota: {
      get() {
        return this.$get(this.gatheringBusinessUnit, 'hasZoneQuota')
      },
      set(value) {
        if (!value) {
          const zoneCongresses = this.$get(this.event, 'congress.zoneCongresses', []).filter(
            zc => zc.zone.businessUnitUuid !== this.businessUnitUuid
          )

          return this.setGbuAndCongressProperties({ hasZoneQuota: value }, { zoneCongresses })
        }

        this.setGatheringBuProperty('hasZoneQuota', value)
      }
    },
    hasAutomatedValidation: {
      get() {
        return this.$get(this.gatheringBusinessUnit, 'hasAutomatedValidation')
      },
      set(value) {
        this.setGatheringBuProperty('hasAutomatedValidation', value)
      }
    },
    criterias: {
      get() {
        return this.$get(this.event, 'congress.criterias')
      },
      set(value) {
        this.setCongressProperty('criterias', value)
        this.errorClear('criterias')
      }
    },
    mslResponsibleCriteria: {
      get() {
        return this.$get(this.gatheringBusinessUnit, 'mslResponsibleCriteria')
      },
      set(value) {
        this.setGatheringBuProperty('mslResponsibleCriteria', value)
        this.errorClear('mslResponsibleCriteria')
      }
    },
    otherDisciplinesQuota: {
      get() {
        return this.$get(this.gatheringBusinessUnit, 'otherDisciplinesQuota')
      },
      set(value) {
        this.setGatheringBuProperty('otherDisciplinesQuota', value)
        this.errorClear('otherDisciplinesQuota')
      }
    }
  },
  apollo: {
    zones: {
      query: GET_ZONES_FROM_BU,
      variables() {
        return {
          businessUnitUuid: this.businessUnitUuid
        }
      },
      skip() {
        return !this.businessUnitUuid
      },
      update({ businessUnit }) {
        return businessUnit.zones.sort((z1, z2) => z1.label.localeCompare(z2.label))
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.quotaBuCard.${key}`, params)
    },
    errorCollection(field, message) {
      return this.collect(`gatheringBusinessUnits[${this.index}].${field}`, message)
    },
    errorClear(field) {
      return this.clear(`gatheringBusinessUnits[${this.index}].${field}`)
    },
    setGbuAndCongressProperties(gbuPartial, congressPartial) {
      const gatheringBusinessUnits = _cloneDeep(this.gatheringBusinessUnits)
      const index = this.gatheringBusinessUnits.findIndex(gbu => gbu.businessUnitUuid === this.businessUnitUuid)

      Object.assign(gatheringBusinessUnits[index], gbuPartial)

      this.$emit('input', {
        ...this.event,
        gatheringBusinessUnits,
        congress: { ...this.event.congress, ...congressPartial }
      })
    },
    setGatheringBuProperty(property, value) {
      const gatheringBusinessUnits = _cloneDeep(this.gatheringBusinessUnits)
      let index = this.gatheringBusinessUnits.findIndex(gbu => gbu.businessUnitUuid === this.businessUnitUuid)

      if (index === -1) {
        gatheringBusinessUnits.push({
          businessUnitUuid: this.businessUnitUuid,
          doctorQuota: null,
          hasAutomatedValidation: null,
          hasZoneQuota: null,
          mslResponsibleCriteria: null,
          otherDisciplinesQuota: null,
          solicitationEndDate: null
        })

        index = gatheringBusinessUnits.length - 1
      }

      gatheringBusinessUnits[index][property] = value

      this.$emit('input', { ...this.event, gatheringBusinessUnits })
    },
    setCongressProperty(property, value) {
      this.$emit('input', { ...this.event, congress: { ...this.event.congress, [property]: value } })
    },
    getZoneQuota(zoneUuid) {
      const zoneCongress = this.zoneCongresses.find(zc => zc.zoneUuid === zoneUuid)

      return zoneCongress ? zoneCongress.zoneQuota : null
    },
    setZoneQuota(zoneUuid, value) {
      const zoneCongresses = Array.from(this.event.congress.zoneCongresses)
      const zoneCongressIndex = zoneCongresses.findIndex(zc => zc.zoneUuid === zoneUuid)
      const zoneCongress = zoneCongresses.find(zc => zc.zoneUuid === zoneUuid)

      if (!zoneCongress) {
        const zone = this.zones.find(z => z.zoneUuid === zoneUuid)

        zoneCongresses.push({
          zoneQuota: Number(value),
          zoneUuid,
          zone
        })
      } else {
        zoneCongresses[zoneCongressIndex].zoneQuota = Number(value)
      }

      this.setCongressProperty('zoneCongresses', zoneCongresses)
      this.errorClear('zones')
    },
    zoneCongressIndex(zone) {
      const zoneIndex = this.$get(this.event, 'congress.zoneCongresses', []).findIndex(
        zoneCongress => zoneCongress.zoneUuid === zone.zoneUuid
      )

      return zoneIndex < 0 ? null : zoneIndex
    }
  }
}
</script>
