import gql from 'graphql-tag'

export const SET_VSP_SUBSCRIPTION = gql`
  mutation setVirtualSessionParticipantsSubscription(
    $virtualSessionParticipantUuids: [GUID!]!
    $subscription: VirtualSessionParticipantSubscription
  ) {
    setVirtualSessionParticipantsSubscription(
      virtualSessionParticipantUuids: $virtualSessionParticipantUuids
      subscription: $subscription
    ) {
      virtualSessionParticipantUuid
    }
  }
`
