<template>
  <status-information-tile
    :title="t('title')"
    :informations="informations"
    :color="color"
    :date="$get(informationStatus, 'date')"
  >
    <mybb-text :style="{ color }" size="16" weight="bold">
      {{ status }}
    </mybb-text>
  </status-information-tile>
</template>

<script>
import _findKey from 'lodash/findKey'
import StatusInformationTile from '../../ui/StatusInformationTile'

export default {
  name: 'HostingTile',
  components: { StatusInformationTile },
  props: {
    participant: Object,
    informationStatus: Object
  },
  computed: {
    initiator() {
      return this.$get(this.informationStatus, 'initiator')
    },
    expenseNote() {
      const notes = this.$get(this.participant, 'expenseNotes', [])

      if (!notes.length) return null

      const { ARBITRATION, PAYED, REFUSED, TO_BE_PROCESSED, VALIDATED } = this.$const.expenseNoteStatus

      // Order has an importance, don't change it
      if (notes.some(note => note.status === PAYED)) return notes.find(note => note.status === PAYED)
      if (notes.some(note => note.status === VALIDATED)) return notes.find(note => note.status === VALIDATED)
      if (notes.some(note => note.status === ARBITRATION)) return notes.find(note => note.status === ARBITRATION)
      if (notes.some(note => note.status === TO_BE_PROCESSED))
        return notes.find(note => note.status === TO_BE_PROCESSED)
      if (notes.some(note => note.status === REFUSED)) return notes.find(note => note.status === REFUSED)

      return null
    },
    status() {
      return this.expenseNote ? this.t(`status.${this.expenseNote.status}`) : '- -'
    },
    informations() {
      return this.initiator ? this.t('informations', this.initiator) : null
    },
    color() {
      if (!this.expenseNote) return 'mybb-grey-lighten1'

      const key = _findKey(this.$const.expenseNoteStatus, value => value === this.expenseNote.status)

      return this.$const.expenseNoteColors[key]
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.synthesisPanel.expenseNote.${key}`, params)
    }
  }
}
</script>
