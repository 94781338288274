<template>
  <div>
    <div class="px-3 py-4 NavHead">
      <v-icon style="color: white; display: inline-table">mdi-chevron-left</v-icon>
      <a @click="backToList">
        <mybb-text class="white--text">{{ t('goBackLink') }}</mybb-text>
      </a>
    </div>
    <v-card class="mb-5 StaffInformationBloc">
      <v-card-title class="px-5 py-4">
        <mybb-text class="text-uppercase" weight="bold" size="16">
          {{ t('UserTitle') }}
        </mybb-text>
        <div class="separator" />
        <v-row>
          <v-col v-for="(field, i) in userInformations" :key="`${field.label}-${i}`" cols="4">
            <span class="StaffInformationBloc-label">
              <mybb-text size="12" class="mybb-text-lighten1--text">{{ field.label }}</mybb-text>
            </span>
            <mybb-text :class="field.whiteSpace ? 'whiteSpace' : ''">{{ field.value }}</mybb-text>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'
import { GET_USER_PROFIL } from '@/graphql/User/GetUser'
import { PARTICIPANT_MANUAL } from '@/graphql/Participant/'

export default {
  name: 'UserProfile',
  data() {
    return {
      user: {}
    }
  },
  computed: {
    userInformations() {
      this.getUserInformation()

      const fieldsArray = []
      for (const field in this.user) {
        if (field !== '__typename' && field !== 'mslMeta' && field !== 'phone' && field !== 'birthDate') {
          fieldsArray.push({ label: this.t(`informations.${field}`), value: this.user[field] })
        }
        if (field === 'mslMeta' && this.user[field]) {
          fieldsArray.push({ label: this.t(`informations.businessUnit`), value: this.user[field].zone.label })
        }
        if (field === 'phone') {
          fieldsArray.push({ label: this.t(`informations.phoneNumber`), value: this.user[field] })
        }
        if (field === 'birthDate' && this.user[field]) {
          fieldsArray.push({
            label: this.t(`informations.${field}`),
            value: dateFormat(new Date(this.user[field]), 'dd/MM/yyyy')
          })
        }
      }
      return fieldsArray
    }
  },
  methods: {
    backToList() {
      return this.$router.push({ name: 'AttendeeList', params: { gatheringUuuid: this.$route.params.gatheringUuuid } })
    },
    t(key, params) {
      return this.$t(`mybb.UserProfile.${key}`, params)
    },
    async getUserInformation() {
      const { data } = await this.$apollo.query({
        query: GET_USER_PROFIL,
        variables: { uuid: this.$route.params.userUuid }
      })

      if (data.user) this.user = data.user
      else {
        const { data } = await this.$apollo.query({
          query: PARTICIPANT_MANUAL,
          variables: { participantUuid: this.$route.params.userUuid }
        })
        if (data.participant) this.user = data.participant.manualParticipant
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.StaffInformationBloc-label {
  line-height: 12px;
  display: block;
}

.separator {
  height: 1px;
  background: var(--v-mybb-grey-divider-base);
  display: block;
  width: 100%;
  margin: 5px 0;
}

.NavHead {
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: border 0.3s;
    &:hover {
      border-bottom: 1px solid white;
    }
  }
}
</style>
