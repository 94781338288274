<template>
  <div class="Container mt-3">
    <div class="Form mr-4">
      <subscription-form :gathering="gathering" />
    </div>

    <div class="Side">
      <gathering-informations :gatheringUuid="$route.params.gatheringUuid" />
    </div>
  </div>
</template>

<script>
import GatheringInformations from '@/components/mybb/ui/GatheringInformations'
import SubscriptionForm from '@/components/mybb/subscriptionForm/SubscriptionForm'
import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'

export default {
  name: 'SubscriptionFormPage',
  components: { GatheringInformations, SubscriptionForm },
  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.subscriptionForm.${key}`, params)
    }
  }
}
</script>

<style lang="scss" scoped>
.Container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.Form {
  width: 70%;
}

.Side {
  flex-grow: 1;
}
</style>
