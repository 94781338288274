<template>
  <div class="ExpenseNoteList mt-5 pb-8">
    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field
          v-model="searchText"
          background-color="white"
          :placeholder="$t('mybb.expenseNoteList.searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>

      <span class="ExpenseNoteList-filter-wrapper">
        <mybb-btn
          @click="filtersOpen = true"
          background="white"
          color="mybb-grey-lighten1"
          icon
          inner-icon="mdi-filter-variant"
          class="ml-2"
        />
        <div v-if="isFilterSelected" class="ExpenseNoteList-buttonDot" />
      </span>

      <mybb-btn
        @click="extractExpenseNote()"
        background="white"
        color="mybb-grey-lighten1"
        icon
        inner-icon="mdi-file-export"
        class="ml-2"
      />

      <v-spacer />
      <mybb-btn
        @click="$router.push({ name: 'ExpenseNoteForm' })"
        inner-icon="mdi-plus"
        :disabled="isWindedUpGathering"
      >
        {{ $t('mybb.expenseNoteList.createExpenseButton') }}
      </mybb-btn>
    </v-row>

    <Loader v-if="!items" color="white" />
    <v-data-table
      v-else
      :headers="headers"
      item-key="uuid"
      :items-per-page="tableSettings.itemsPerPage"
      :items="items"
      :search="searchText"
      :custom-filter="$dataTableFilter(filterFields)"
      :custom-sort="$dataTableSort(sortMapping)"
      sort-by="date"
      sort-desc
      :footer-props="tableSettings.footerProps"
      class="ExpenseNoteList-table px-4 pt-3"
    >
      <template v-slot:[`item.id`]="{ value: id }">
        <mybb-text weight="bold">{{ id }}</mybb-text>
      </template>
      <template v-slot:[`item.lastname`]="{ value: lastname }">
        <mybb-text weight="bold">{{ lastname }}</mybb-text>
      </template>
      <template v-slot:[`item.firstname`]="{ value: firstname }">
        <mybb-text weight="bold">{{ firstname }}</mybb-text>
      </template>
      <template v-slot:[`item.date`]="{ value: date }">
        <mybb-text>{{ date | dateFormat }}</mybb-text>
      </template>
      <template v-slot:[`item.validatedPrice`]="{ value: validatedPrice }">
        <mybb-text>{{ validatedPrice | price }}</mybb-text>
      </template>
      <template v-slot:[`item.totalPrice`]="{ value: totalPrice }">
        <mybb-text>{{ totalPrice | price }}</mybb-text>
      </template>
      <template v-slot:[`item.status`]="{ value: status }">
        <v-chip :color="status.color" dark class="Chip py-2 px-4">{{ status.text }}</v-chip>
      </template>
      <template v-slot:[`item.expenseNoteUuid`]="{ value: expenseNoteUuid }">
        <router-link
          class="ExpenseNoteList-tableLink"
          :to="{
            name: 'ExpenseNoteDetails',
            params: { gatheringUuid: $route.params.gatheringUuid, expenseNoteUuid }
          }"
        >
          <v-btn icon class="table-icon">
            <v-icon color="mybb-grey-lighten1">mdi-eye</v-icon>
          </v-btn>
        </router-link>
      </template>
    </v-data-table>

    <FilterDrawer
      v-model="filters"
      :open="filtersOpen"
      :label="$t('mybb.expenseNoteList.statusFilterLabel')"
      @close="filtersOpen = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import dateFormat from 'date-fns/format'
import { mapGetters } from 'vuex'

import { GET_EXPENSE_NOTES } from '@/graphql/ExpenseNote/ExpenseNote'
import { GET_GATHERING_INFO } from '@/graphql/Gatherings/GetGathering'
import shared from '@/const/my-bb'

import Loader from '@/components/congrex/tabs/LoaderTab'
import FilterDrawer from '@/components/mybb/ui/FilterDrawer'

export default {
  name: 'ExpenseNoteList',

  components: { Loader, FilterDrawer },

  data() {
    return {
      expenseNotes: [],
      searchText: '',
      filtersOpen: false,
      filters: [],
      sortMapping: {
        status: 'status.text'
      },
      filterFields: {
        id: 'id',
        lastname: 'lastname',
        firstname: 'firstname'
      }
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    selectedFilters() {
      const selectedFilters = {}
      this.filters.forEach(filter => (selectedFilters[filter.key] = filter.value))
      return selectedFilters
    },
    tableSettings() {
      return shared.tableSettings
    },
    headers() {
      return [
        { sortable: true, text: this.$t('mybb.expenseNoteList.tableHeaders.id'), value: 'id' },
        { sortable: true, text: this.$t('mybb.expenseNoteList.tableHeaders.lastname'), value: 'lastname' },
        { sortable: false, text: this.$t('mybb.expenseNoteList.tableHeaders.firstname'), value: 'firstname' },
        {
          sortable: true,
          text: this.$t('mybb.expenseNoteList.tableHeaders.date'),
          value: 'date'
        },
        { sortable: true, text: this.$t('mybb.expenseNoteList.tableHeaders.totalPrice'), value: 'totalPrice' },
        { sortable: true, text: this.$t('mybb.expenseNoteList.tableHeaders.validatedPrice'), value: 'validatedPrice' },
        {
          sortable: true,
          text: this.$t('mybb.expenseNoteList.tableHeaders.status'),
          value: 'status'
        },
        { sortable: false, text: '', value: 'expenseNoteUuid' }
      ]
    },
    isFilterSelected() {
      return this.filters.some(filter => filter.value)
    },
    items() {
      const items = this.expenseNotes.map(expenseNote => ({
        date: expenseNote.createdAt,
        expenseNoteUuid: expenseNote.expenseNoteUuid,
        firstname: this.$get(expenseNote, 'participant.firstName', ''),
        id: String(expenseNote.visualIdentifier),
        lastname: this.$get(expenseNote, 'participant.lastName', ''),
        totalPrice: expenseNote.totalPrice / 100,
        status: this.getLabelStatus(expenseNote.status),
        validatedPrice: this.getValidatedPrice(expenseNote.lines || [], expenseNote.status)
      }))
      return this.isFilterSelected
        ? items.filter(item => {
            return this.selectedFilters[item.status.key]
          })
        : items.filter(item => {
            return item.status.key !== 'draft'
          })
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    }
  },

  apollo: {
    expenseNotes: {
      query: GET_EXPENSE_NOTES,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    },
    gathering: {
      query: GET_GATHERING_INFO,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    }
  },

  filters: {
    dateFormat(date) {
      return date ? dateFormat(new Date(date), 'dd/MM/yy') : ''
    },
    price(price) {
      return isNaN(price) ? '- -' : `${(+price).toFixed(2).replace('.', ',')}€`
    }
  },

  created() {
    this.filters = Object.keys(this.$const.expenseNoteStatus)
      .filter(key => this.$const.expenseNoteStatus[key] !== this.$const.expenseNoteStatus.EX_NIHILO)
      .map(key => ({
        key: this.$const.expenseNoteStatus[key],
        label: this.$t(`mybb.expenseNoteList.status.${this.$const.expenseNoteStatus[key]}`),
        value: false
      }))
  },

  methods: {
    t(key, params) {
      return this.$t(`mybb.AttendeeList.participantExportModal.${key}`, params)
    },
    extractExpenseNote() {
      let fileName = `${dateFormat(new Date(), 'yyMMdd')}_ExpenseNoteExtract.xlsx`
      let job = axios.post(
        `/gathering/participants/expensenote-extract/${this.$get(this.$route, 'params.gatheringUuid')}`,
        { filters: this.filters },
        {
          responseType: 'blob',
          headers: {
            'x-impersonificated-user': this.$store.state.impersonificatedUser
          }
        }
      )
      this.$bus.$emit('extract', {
        job,
        label: this.t(`options.EXPENSE_NOTE`),
        fileName,
        status: {
          pending: this.t('status.pending'),
          success: this.t('status.done'),
          error: this.t('status.error')
        }
      })
    },
    getLabelStatus(status) {
      const colors = {
        [this.$const.expenseNoteStatus.TO_BE_PROCESSED]: this.$const.expenseNoteColors.TO_BE_PROCESSED,
        [this.$const.expenseNoteStatus.CANCEL]: this.$const.expenseNoteColors.CANCEL,
        [this.$const.expenseNoteStatus.ARBITRATION]: this.$const.expenseNoteColors.ARBITRATION,
        [this.$const.expenseNoteStatus.VALIDATED]: this.$const.expenseNoteColors.VALIDATED,
        [this.$const.expenseNoteStatus.REFUSED]: this.$const.expenseNoteColors.REFUSED,
        [this.$const.expenseNoteStatus.PAYED]: this.$const.expenseNoteColors.PAYED
      }
      return {
        key: status,
        text: this.$t(`mybb.expenseNoteList.status.${status}`),
        color: colors[status]
      }
    },
    getValidatedPrice(lines, noteStatus) {
      if (noteStatus === this.$const.expenseNoteStatus.REFUSED) {
        return 0
      }

      const total = lines.reduce((total, line) => {
        if (line.status === this.$const.expenseNoteLineStatus.VALIDATED) {
          return total + Number(line.price || 0)
        }

        return total
      }, 0)

      return total === 0 ? '- -' : total / 100
    }
  }
}
</script>

<style lang="scss" scoped>
.ExpenseNoteList {
  .Chip {
    font-size: 13px;
    font-weight: 600;
    min-width: 130px;
    text-align: center;
    justify-content: center;
  }
}

.ExpenseNoteList-filter-wrapper {
  position: relative;
}
.ExpenseNoteList-buttonDot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--v-mybb-success-base);
  position: absolute;
  top: -5px;
  right: -3.75px;
}

.ExpenseNoteList-table {
  border-radius: 8px;
}

.ExpenseNoteList-tableLink {
  text-decoration: none;
}
</style>

<style lang="scss">
.ExpenseNoteList {
  .v-data-table thead th {
    font-size: 12px;
    font-weight: 900;
    color: var(--v-mybb-primary-base) !important;
  }

  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }
}
</style>
