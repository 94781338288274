<template>
  <modal v-model="modal" width="915" :title="t('title')">
    <template v-if="expenseNoteLine" v-slot:text>
      <!-- Expense note -->
      <v-row>
        <v-col cols="6">
          <mybb-autocomplete
            :value="expenseNoteLine.expenseNoteCategory.expenseNoteCategoryUuid"
            @change="changeCategory"
            item-text="label"
            item-value="expenseNoteCategoryUuid"
            :items="expenseNoteCategories"
            :label="t('labelCategory')"
            :no-data-text="$t('mybb.global.noData')"
            return-object
            :disabled="isWindedUpGathering"
          />
        </v-col>
        <v-col cols="3">
          <date-picker v-model="expenseNoteLine.feeDate" :label="t('labelDate')" :disabled="isWindedUpGathering" />
        </v-col>
        <v-col cols="3">
          <mybb-text-field
            v-model="expenseNoteLine.price"
            :label="t('labelPrice')"
            type="number"
            inner-icon="mdi-currency-eur"
            data-e2e="input-event-date"
            :disabled="isWindedUpGathering"
          />
        </v-col>
        <v-col cols="6">
          <mybb-text-field
            type="number"
            v-if="expenseNoteLine.expenseNoteCategory.expenseNoteCategoryUuid === expenseNoteLineCategory.FEEKM"
            v-model="expenseNoteLine.amountKilometers"
            :label="t('labelAmountKm')"
            data-e2e="input-event-date"
            v-validate="'required'"
            :disabled="isWindedUpGathering"
          />
        </v-col>
        <v-col cols="6">
          <mybb-text-field
            v-if="expenseNoteLine.expenseNoteCategory.expenseNoteCategoryUuid === expenseNoteLineCategory.FEEKM"
            v-model="expenseNoteLine.departureAddress"
            :label="t('labelDepartureAddress')"
            data-e2e="input-event-date"
            v-validate="'required'"
            :disabled="isWindedUpGathering"
          />
        </v-col>
        <v-col cols="12">
          <mybb-text-field
            v-model="expenseNoteLine.description"
            :label="t('labelDescription')"
            data-e2e="input-event-date"
            v-validate="'required'"
            :disabled="isWindedUpGathering"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <InputFile
            v-model="expenseNoteLine.file"
            accept=".jpg,.jpeg,.png,.pdf"
            :title="t('labelFile')"
            :disabled="isWindedUpGathering"
          />
        </v-col>
      </v-row>

      <!-- Comment -->
      <div class="mt-3">
        <mybb-text>{{ t('commentHeader') }}</mybb-text>
      </div>
      <div class="mt-3">
        <mybb-textarea
          v-model="comment"
          :label="t('labelComment')"
          rows="3"
          icon="mdi-comment-text"
          :disabled="isWindedUpGathering"
        />
      </div>

      <!-- Attachment -->
      <v-row class="mb-10 mt-3">
        <v-col cols="6">
          <InputFile
            v-model="attachment"
            accept=".jpg,.jpeg,.png,.pdf"
            :title="t('attachment')"
            :disabled="isWindedUpGathering"
          />
        </v-col>
      </v-row>

      <!-- Buttons -->
      <v-row justify="center">
        <mybb-btn class="mr-10" color="mybb-grey" @click="close">
          {{ t('cancel') }}
        </mybb-btn>

        <mybb-btn color="mybb-success" :disabled="!canSubmit" :loading="loading" @click="submit">
          {{ t('confirm') }}
        </mybb-btn>
      </v-row>
    </template>
  </modal>
</template>

<script>
import { UPDATE_EXPENSE_NOTE_LINE, GET_EXPENSE_NOTES_CATEGORIES } from '@/graphql/ExpenseNote/ExpenseNote'
import { expenseNoteLineCategory } from '@/const/shared'
import InputFile from '@/components/mybb/InputFile'
import DatePicker from '@/components/mybb/ui/DatePicker'
import Modal from '@/components/mybb/Modal.vue'

export default {
  name: 'EditModalModal',
  components: { DatePicker, InputFile, Modal },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    note: {
      type: Object,
      required: true
    },
    selectedLine: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      expenseNoteLineCategory: expenseNoteLineCategory,
      isOpen: true,
      comment: '',
      attachment: null,
      expenseNoteLine: null,
      expenseNoteCategories: [],
      loading: false
    }
  },
  computed: {
    canSubmit() {
      return (
        !!this.note.expenseNoteUuid &&
        !!this.expenseNoteLine.description &&
        !!this.expenseNoteLine.expenseNoteCategory.expenseNoteCategoryUuid &&
        !!this.expenseNoteLine.expenseNoteLineUuid &&
        !!this.expenseNoteLine.feeDate &&
        !!this.expenseNoteLine.file &&
        !!this.expenseNoteLine.price &&
        this.expenseNoteLine.price > 0 &&
        !!this.comment &&
        !this.isWindedUpGathering
      )
    },
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)
      }
    },
    isWindedUpGathering() {
      const gatheringStatus = this.$get(this.note, 'gathering.status', null)

      return gatheringStatus === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    expenseNoteCategories: {
      query: GET_EXPENSE_NOTES_CATEGORIES
    }
  },
  watch: {
    modal(value) {
      if (value && this.selectedLine && this.note && this.note.lines) {
        const expenseNoteLine = this.note.lines.find(line => line.expenseNoteLineUuid === this.selectedLine)
        if (expenseNoteLine) {
          this.expenseNoteLine = JSON.parse(
            JSON.stringify({
              ...expenseNoteLine,
              price: (expenseNoteLine.price / 100).toFixed(2)
            })
          )
        }
      }
    }
  },
  methods: {
    changeCategory(value) {
      this.expenseNoteLine.expenseNoteCategory = value
    },
    close() {
      this.modal = false
      this.comment = ''
      this.attachment = null
    },
    t(key, params) {
      return this.$t(`mybb.expenseNoteDetails.editModal.${key}`, params)
    },
    async submit() {
      if (this.loading) return
      this.loading = true

      const { expenseNoteUuid } = this.note

      const expenseNoteLineEntry = {
        description: this.expenseNoteLine.description,
        expenseNoteCategoryUuid: this.expenseNoteLine.expenseNoteCategory.expenseNoteCategoryUuid,
        expenseNoteLineUuid: this.expenseNoteLine.expenseNoteLineUuid,
        feeDate: this.expenseNoteLine.feeDate,
        amountKilometers: parseInt(this.expenseNoteLine.amountKilometers),
        departureAddress: this.expenseNoteLine.departureAddress,
        file: {
          content: this.expenseNoteLine.file.content,
          fileUuid: this.expenseNoteLine.file.fileUuid,
          name: this.expenseNoteLine.file.name
        },
        price: Number((this.expenseNoteLine.price * 100).toFixed(0))
      }

      const response = await this.$apollo.mutate({
        mutation: UPDATE_EXPENSE_NOTE_LINE,
        variables: { expenseNoteUuid, expenseNoteLineEntry, comment: this.comment, attachment: this.attachment }
      })

      if (response.data && response.data.updateExpenseNoteLine) {
        this.close()
        this.$emit('EditModal::update', response.data.updateExpenseNoteLine)
        this.loading = false
      }
    }
  }
}
</script>
