<template>
  <div class="ParticipantStaffAdd pb-8">
    <v-row class="ml-0">
      <mybb-btn text color="white" class="px-0 py-0" @click="backToList" inner-icon="mdi-chevron-left">
        {{ t('return') }}
      </mybb-btn>
    </v-row>

    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field
          v-model="search"
          background-color="white"
          :placeholder="t('searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>
    </v-row>

    <v-container class="Container px-6 py-5" fluid>
      <v-data-table
        v-model="selected"
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search"
        :server-items-length="serverItemsLength"
        class="DataTable"
        item-key="userUuid"
        hide-default-footer
        show-select
        @update:sort-by="updateSortBy"
        @update:sort-desc="updateSortDesc"
      >
        <template v-slot:[`item.lastName`]="{ value }">
          <mybb-text weight="bold" class="text-uppercase">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.firstName`]="{ value }">
          <mybb-text weight="bold">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.category`]="{ value }">
          <v-chip :color="categoryColors[value]" dark class="ma-1">
            {{ $t(`mybb.categories.${value}`) }}
          </v-chip>
        </template>
        <template v-slot:[`item.userTypes`]="{ value }">
          <mybb-text>
            {{ value }}
          </mybb-text>
        </template>
        <template v-slot:[`item.zone`]="{ value }">
          <mybb-text>
            {{ value }}
          </mybb-text>
        </template>
        <template v-slot:[`item.businessUnit`]="{ value }">
          <v-chip v-if="value" :color="buConfig[value.label].color" dark class="ma-1">
            {{ buConfig[value.label].label }}
          </v-chip>
        </template>
      </v-data-table>
    </v-container>

    <fixed-footer
      :visible="selected.length > 0"
      :label="t('footer.label', { selected: allSelected ? serverItemsLength : selected.length })"
    >
      <mybb-btn color="mybb-success" @click="invitationModal = true" inner-icon="mdi-check">
        {{ t('footer.invite') }}
      </mybb-btn>
    </fixed-footer>

    <modal v-model="invitationModal" :title="t('invitationModal.title')">
      <mybb-text>
        {{
          t('invitationModal.text', {
            selected: allSelected ? serverItemsLength : selected.length
          })
        }}
      </mybb-text>

      <div class="d-flex justify-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="invitationModal = false">
          {{ t('invitationModal.cancel') }}
        </mybb-btn>
        <mybb-btn :loading="inviting" color="mybb-success" @click="invite">{{ t('invitationModal.confirm') }}</mybb-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import MYBB from '@/const/my-bb'
import FixedFooter from '@/components/mybb/ui/FixedFooter'
import Modal from '@/components/mybb/Modal'
import { SEARCH_USERS_FOR_GATHERING } from '@/graphql/User/GetUsers'
import { INVITE_INTERNAL_USERS } from '@/graphql/Participant'

export default {
  name: 'ParticipantStaffAdd',
  components: { FixedFooter, Modal },
  data() {
    return {
      search: '',
      filtersOpen: false,
      filters: {
        businessUnits: []
      },
      selected: [],
      invitationModal: false,
      loading: true,
      inviting: false,
      serverItemsLength: 0,
      sort: {
        field: 'lastName',
        desc: false
      }
    }
  },
  computed: {
    headers() {
      return [
        { sortable: true, text: this.t('table.lastName'), value: 'lastName' },
        { sortable: false, text: this.t('table.firstName'), value: 'firstName' },
        { sortable: true, text: this.t('table.email'), value: 'email' },
        { sortable: true, text: this.t('table.role'), value: 'userTypes' },
        { sortable: true, text: this.t('table.businessUnit'), value: 'businessUnit' },
        { sortable: true, text: this.t('table.zone'), value: 'zone' }
      ]
    },
    items() {
      return this.$get(this.availableInternalUsers, 'rows', []).map(user => ({
        userUuid: user.userUuid,
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        userTypes: (user.userTypes || []).map(role => this.$t(`userType.${role}`)).join(' ; '),
        businessUnit: this.computeBusinessUnit(user),
        zone: this.$get(user, 'mslMeta.zone.label', null)
      }))
    },
    tableSettings() {
      return MYBB.tableSettings
    },
    buConfig() {
      return MYBB.bu
    },
    allSelected() {
      if (!this.availableInternalUsers) return false
      return this.selected.length === this.availableInternalUsers.rows.length
    }
  },
  apollo: {
    availableInternalUsers: {
      query: SEARCH_USERS_FOR_GATHERING,
      deep: true,
      variables() {
        const vars = {
          gatheringUuid: this.$route.params.gatheringUuid,
          sort: this.sort
        }

        if (this.search) {
          vars.search = this.search
        }

        return vars
      },
      skip() {
        return !this.$route.params.gatheringUuid
      },
      update({ searchUsersForGathering }) {
        return searchUsersForGathering
      },
      result({ data }) {
        this.loading = false
        this.serverItemsLength = this.$get(data, 'searchUsersForGathering.count', 0)
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.participants.add-staff-list.${key}`, params)
    },
    backToList() {
      return this.$router.push({ name: 'AttendeeList' })
    },
    computeBusinessUnit(user) {
      if (!user || (user && (!user.mslMeta && !user.abmMeta))) return null

      if (user.mslMeta) {
        return this.$get(user, 'mslMeta.zone.businessUnit')
      }

      if (user.abmMeta) {
        return this.$get(user, 'abmMeta.businessUnit')
      }

      return null
    },
    updateSortBy(sort) {
      const [field] = sort
      this.sort.field = field
    },
    updateSortDesc(sort) {
      const [desc] = sort
      this.sort.desc = Boolean(desc)
    },
    async invite() {
      if (this.inviting) return
      this.inviting = true

      try {
        await this.$apollo.mutate({
          mutation: INVITE_INTERNAL_USERS,
          variables: {
            gatheringUuid: this.$route.params.gatheringUuid,
            userUuids: this.selected.map(hcp => hcp.userUuid)
          }
        })

        this.invitationModal = false
        this.$router.push({ name: 'AttendeeList' })
      } catch (error) {
        console.error(error)
      } finally {
        this.inviting = false
      }
    }
  }
}
</script>

<style lang="scss">
.ParticipantStaffAdd {
  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }

  .Container {
    background-color: white;
    border-radius: 8px;

    .DataTable {
      border-radius: 8px;
    }

    .v-data-table {
      thead th {
        font-size: 12px;
        font-weight: 900;
        color: var(--v-mybb-primary-base) !important;
        text-transform: uppercase;
      }

      tbody td {
        color: var(--v-mybb-primary-base);
      }

      th .v-simple-checkbox .v-icon,
      td .v-simple-checkbox .v-icon {
        color: var(--v-mybb-blue-base);
      }
    }
  }
}
</style>
