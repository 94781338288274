import { object, array, string, boolean } from 'yup'

import CONST from '@/const/shared'

import fileSchema from '../file'

const gatheringFileSchema = object()
  .noUnknown()
  .shape({
    fileGatheringUuid: string().nullable(),
    file: fileSchema,
    label: string().required(),
    type: string()
      .oneOf(Object.values(CONST.gatheringFileType))
      .required(),
    isPhysical: boolean().nullable(),
    isVirtual: boolean().nullable(),
    categories: array()
      .of(string())
      .nullable(),
    displayToHcp: boolean().nullable()
  })

const gatheringSchema = object()
  .noUnknown()
  .shape({
    gatheringFiles: array().of(gatheringFileSchema)
  })

export const congressSchema = gatheringSchema.shape({
  congress: object()
    .noUnknown()
    .shape({})
})

export const biogenEventSchema = gatheringSchema.shape({
  biogenEvent: object()
    .noUnknown()
    .shape({})
})

export default gatheringSchema
