import { object, string, boolean, array } from 'yup'

import CONST from '@/const/shared'

function rocTest(users) {
  const rocUser = users.find(
    user => user.gatheringUserType === CONST.gatheringUserType.ROC && !user.isBackup && !user.businessUnitUuid
  )

  return Boolean(rocUser)
}

function mciTest(users) {
  const rocUser = users.find(
    user => user.gatheringUserType === CONST.gatheringUserType.MCI && !user.isBackup && !user.businessUnitUuid
  )

  return Boolean(rocUser)
}

function dmTest(users, { parent }) {
  const isCongress = parent.hasOwnProperty('congress')

  if (!isCongress) return true

  const { mainBusinessUnitUuid } = parent

  if (!mainBusinessUnitUuid) return false

  const dmUser = users.find(
    user =>
      user.gatheringUserType === CONST.gatheringUserType.MEDICAL_DIRECTOR &&
      user.businessUnitUuid === parent.mainBusinessUnitUuid &&
      !user.isBackup
  )

  return Boolean(dmUser)
}

function cpTest(users, { parent }) {
  const isBiogenEvent = parent.hasOwnProperty('biogenEvent')

  if (!isBiogenEvent) return true

  const { mainBusinessUnitUuid } = parent

  if (!mainBusinessUnitUuid) return false

  const cpUser = users.find(
    user =>
      user.gatheringUserType === CONST.gatheringUserType.PROJECT_MANAGER &&
      user.businessUnitUuid === mainBusinessUnitUuid &&
      !user.isBackup
  )

  return Boolean(cpUser)
}

const userSchema = object()
  .noUnknown()
  .shape({
    businessUnitUuid: string().nullable(),
    gatheringUserType: string()
      .oneOf(Object.values(CONST.gatheringUserType))
      .required(),
    isBackup: boolean().required(),
    userUuid: string().required()
  })

const gatheringSchema = object()
  .noUnknown()
  .shape({
    mainBusinessUnitUuid: string().required(),
    businessUnitUuids: array().of(string()),
    users: array()
      .of(userSchema)
      .test({
        name: 'custom-roc',
        test: rocTest
      })
      .test({
        name: 'custom-mci',
        test: mciTest
      })
      .test({
        name: 'custom-dm',
        test: dmTest
      })
      .test({
        name: 'custom-cp',
        test: cpTest
      })
  })

export const congressSchema = gatheringSchema.shape({
  congress: object()
    .noUnknown()
    .shape({})
})

export const biogenEventSchema = gatheringSchema.shape({
  biogenEvent: object()
    .noUnknown()
    .shape({})
})

export default gatheringSchema
