<template>
  <div class="TravelDetail">
    <v-card class="border-radius-6-forced pa-5" width="97%" height="100%" @click="openModal">
      <div class="d-flex justify-space-between">
        <mybb-text weight="semi-bold">
          {{ t('stepNumber', { stepNumber: travels.length }) }}
        </mybb-text>
        <span class="float-right" v-if="canEdit">
          <v-icon color="mybb-grey">mdi-pencil</v-icon>
        </span>
      </div>

      <v-row v-for="step in travels" :key="step.travelUuid">
        <v-col cols="1" class="TravelType-icons-wrapper">
          <div class="TravelType-icons-subwrapper mt-1">
            <v-icon
              class="TravelType-icon"
              color="mybb-primary-lighten1"
              :class="'TravelType-icon-' + getModeIcon(step.mode)"
            >
              mdi-{{ getModeIcon(step.mode) }}
            </v-icon>
            <img alt="travel-progress-icon" height="37" width="100%" src="@/assets/travel-end-direction.svg" />
          </div>
        </v-col>
        <v-col cols="10">
          <div class="TravelDetail-startRowSize">
            <mybb-text weight="semi-bold" class="mr-1">
              {{ getTravelDate(step.departureDate, step.departureTime) }}
            </mybb-text>
            <mybb-text size="12">{{ step.departurePlace }}</mybb-text>
          </div>
          <div>
            <mybb-text weight="semi-bold" class="mr-1">
              {{ getTravelDate(step.arrivalDate, step.arrivalTime) }}
            </mybb-text>
            <mybb-text size="12">{{ step.arrivalPlace }}</mybb-text>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <transport-travel-modal
      v-model="transportNote"
      :modal="showModal"
      @modal="val => (showModal = val)"
      :type="type"
      @saved="$emit('saved')"
      :travels-through-model="travelsThroughModel"
    />
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

import TransportTravelModal from './TransportTravelModal'

export default {
  name: 'TransportTravelDetail',
  components: { TransportTravelModal },
  model: {
    prop: 'note',
    event: 'change'
  },
  props: {
    travels: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    note: {
      type: Object,
      required: false
    },
    travelsThroughModel: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    transportNote: {
      get() {
        return this.note
      },
      set(value) {
        this.$emit('change', value)
      }
    },
    canEdit() {
      const { EX_NIHILO, PENDING } = this.$const.transport.noteStatus
      const noteStatus = this.$get(this.transportNote, 'status', null)

      return [null, EX_NIHILO, PENDING].includes(noteStatus)
    }
  },
  methods: {
    getModeIcon(mode) {
      return this.$const.transport.modeIcons[mode] || ''
    },
    getTravelDate(date, time) {
      return `${dateFormat(new Date(date), 'dd/MM/yy')} - ${time}`
    },
    t(key, params) {
      return this.$t(`mybb.transportNotePage.travelGroup.${key}`, params)
    },
    openModal() {
      this.showModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-2px {
  margin-top: 2px;
}

.TravelDetail {
  height: 100%;
}

.TravelType-icons-wrapper {
  max-width: 38px;
}
.TravelType-icons-subwrapper {
  display: flex;
  flex-direction: column;
}

.TravelType-icon {
  font-size: 14px;
}
.TravelType-icon-airplane {
  transform: rotate(180deg);
}

.TravelDetail-startRowSize {
  height: 38px;
}
</style>
