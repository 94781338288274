<template>
  <modal :title="t('title')" :modal="value" @change="close(true)" width="1040">
    <template v-slot:text>
      <form
        @submit="saveConfiguration"
        class="TransportConfigurationModal"
        v-cy="$cy.gathering.transport.configuration.modal.container"
      >
        <mybb-text weight="semi-bold" class="d-block mb-4">
          {{ t('title-section-1') }}
          <span class="error--text">{{ t('subtitle-highlight') }}</span>
        </mybb-text>

        <!-- Transfert option -->
        <mybb-radios
          v-model="transfertOptionEnabled"
          :label="t('form.transfertOptionEnabled')"
          :items="[{ label: $t`yes`, value: true }, { label: $t`no`, value: false }]"
          :cypress="$cy.gathering.transport.configuration.modal.transferEnabled"
          row
        />

        <v-row class="my-7" justify="center">
          <v-col class="pa-0 ma-0" cols="6" md="6">
            <v-divider />
          </v-col>
        </v-row>

        <div class="mb-4">
          <mybb-text weight="semi-bold">{{ t('title-section-2') }}</mybb-text>
        </div>
        <div class="mb-4">
          <mybb-text>{{ t('subtitle-section-2') }}</mybb-text>
        </div>

        <transport-business-unit-configuration v-model="businessUnitConfiguration" :is-disabled="isDisabled" />

        <div class="mt-6 mb-5">
          <mybb-text>{{ t('subtitle-section-3') }}</mybb-text>
        </div>

        <v-row>
          <v-col cols="12" md="3" class="pa-0 px-1">
            <date-picker v-model="favouredDepartureDate" :label="t('favouredDepartureDate')" :disabled="isDisabled" />
          </v-col>

          <v-col cols="12" md="3" class="pa-0 px-1">
            <mybb-select
              v-model="favouredDepartureTime"
              :items="times"
              :label="t('favouredDepartureTime')"
              icon="mdi-clock-outline"
              :disabled="isDisabled"
            />
          </v-col>

          <v-col cols="12" md="3" class="pa-0 px-1">
            <date-picker
              v-model="favouredReturnDate"
              :label="t('favouredArrivalDate')"
              :min="favouredDepartureDate"
              icon="mdi-calendar"
              :disabled="isDisabled"
            />
          </v-col>

          <v-col cols="12" md="3" class="pa-0 px-1">
            <mybb-select
              v-model="favouredReturnTime"
              :items="times"
              :label="t('favouredArrivalTime')"
              :disabled="isDisabled"
            />
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col class="pa-0 px-1">
            <mybb-textarea
              v-model="comment"
              :label="t('comment')"
              rows="3"
              icon="mdi-message"
              :disabled="isDisabled"
              v-cy="$cy.gathering.transport.configuration.modal.comment"
            />
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-10">
          <v-col class="pa-0 px-1" align="center">
            <mybb-btn
              :disabled="disabled"
              :loading="loading"
              @click="saveConfiguration"
              color="mybb-success"
              v-cy="$cy.gathering.transport.configuration.modal.submit"
            >
              {{ $t('mybb.eventSteps.saveButton') }}
            </mybb-btn>
          </v-col>
        </v-row>
      </form>
    </template>
  </modal>
</template>

<script>
import dateFnsFormat from 'date-fns/format'
import _omit from 'lodash/omit'

import { rangeTime } from '@/services/hours'
import { SET_TRANSPORT_CONFIGURATION_FOR_GATHERING } from '@/graphql/Gatherings/TransportConfigurationGathering'

import Modal from '../Modal'
import TransportBusinessUnitConfiguration from './TransportBusinessUnitConfiguration.vue'
import DatePicker from '../ui/DatePicker.vue'

export default {
  name: 'TransportConfigurationModal',
  components: { Modal, TransportBusinessUnitConfiguration, DatePicker },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    gatheringUuid: {
      type: String,
      required: true
    },
    configuration: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      transfertOptionEnabled: null,
      businessUnitConfiguration: {},
      favouredReturnDate: null,
      favouredReturnTime: null,
      favouredDepartureDate: null,
      favouredDepartureTime: null,
      comment: null
    }
  },
  computed: {
    color() {
      return '#03111d66'
    },
    transportConfiguration() {
      const businessUnitConfiguration = _omit(this.businessUnitConfiguration, '__typename')

      return {
        gatheringUuid: this.gatheringUuid,
        transfertOptionEnabled: this.transfertOptionEnabled,
        businessUnitConfiguration,
        favouredReturnDate: this.favouredReturnDate,
        favouredReturnTime: this.favouredReturnTime,
        favouredDepartureDate: this.favouredDepartureDate,
        favouredDepartureTime: this.favouredDepartureTime,
        comment: this.comment
      }
    },
    times() {
      return rangeTime(0, 24, 0.25)
        .map(time => time.display)
        .slice(0, -1)
    },
    disabled() {
      if (this.isDisabled) {
        return true
      }

      const mandatoryFields = [
        'gatheringUuid',
        'businessUnitConfiguration.businessUnitUuid',
        'favouredReturnDate',
        'favouredDepartureDate'
      ]

      return (
        mandatoryFields.some(field => !this.$get(this.transportConfiguration, field, false)) ||
        typeof this.transportConfiguration.transfertOptionEnabled !== 'boolean'
      )
    }
  },
  filters: {
    datePickerFormat(value, formatType = 'dd/MM/yyyy') {
      if (!value) return ''

      return dateFnsFormat(new Date(value), formatType)
    }
  },
  methods: {
    async saveConfiguration() {
      this.loading = true

      await this.$apollo.mutate({
        mutation: SET_TRANSPORT_CONFIGURATION_FOR_GATHERING,
        variables: { transportConfiguration: this.transportConfiguration }
      })

      this.$emit('success')
      this.close(false)
    },
    close(reset = true) {
      if (reset) {
        if (this.configuration) {
          for (const property in this.configuration) {
            this[property] = this.configuration[property]
          }
        } else {
          this.transfertOptionEnabled = false
          this.businessUnitConfiguration = {}
          this.favouredReturnDate = null
          this.favouredReturnTime = null
          this.favouredDepartureDate = null
          this.favouredDepartureTime = null
          this.comment = null
        }
      }

      this.loading = false

      this.$emit('input', false)
    },
    t(key, params) {
      return this.$t(`mybb.transportNoteList.configurationModal.${key}`, params)
    }
  },
  watch: {
    favouredDepartureDate(val, old) {
      if (val && val !== old && this.favouredReturnDate !== null && this.value) {
        this.favouredReturnDate = null
      }
    },
    configuration(val, old) {
      if (val) {
        for (const property in val) {
          this[property] = val[property]
        }
      }
    }
  }
}
</script>
