<template>
  <modal v-model="modal" :title="t('title')" width="912">
    <div>
      <mybb-text weight="semi-bold">
        {{ t('goText_1') }}
        <mybb-text weight="bold" class="red--text">{{ t('hcp') }}</mybb-text>
        {{ t('goText_2') }}
      </mybb-text>
    </div>

    <mybb-textarea
      v-model="configuration.goInformations"
      :label="t('goLabel')"
      class="mt-5"
      rows="3"
      icon="mdi-comment-text"
      :disabled="isWindedUpGathering"
    />

    <div class="mt-6">
      <mybb-text weight="semi-bold">
        {{ t('backText_1') }}
        <mybb-text weight="bold" class="red--text">{{ t('hcp') }}</mybb-text>
        {{ t('backText_2') }}
      </mybb-text>
    </div>

    <mybb-textarea
      v-model="configuration.backInformations"
      :label="t('backLabel')"
      class="mt-5"
      rows="3"
      icon="mdi-comment-text"
      :disabled="isWindedUpGathering"
    />

    <v-row class="my-8" justify="center">
      <v-col cols="6" class="pa-0 ma-0">
        <v-divider />
      </v-col>
    </v-row>

    <mybb-textarea
      v-model="configuration.comment"
      :label="t('comment')"
      rows="3"
      icon="mdi-comment-text"
      :disabled="isWindedUpGathering"
    />

    <div class="text-center mt-10">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
        {{ t('cancel') }}
      </mybb-btn>

      <mybb-btn
        :disabled="loading || disabled || isWindedUpGathering"
        :loading="loading"
        color="mybb-success"
        inner-icon="mdi-content-save"
        @click="confirm"
      >
        {{ t('confirm') }}
      </mybb-btn>
    </div>

    <modal v-model="notificationModal" :title="nmt('title')" centered>
      <mybb-text class="mr-4">{{ nmt('label') }}</mybb-text>
      <v-radio-group v-model="notify" class="mt-0 d-inline-block" hide-details dense row>
        <v-radio :value="true" color="mybb-primary-lighten1">
          <mybb-text slot="label">{{ nmt('yes') }}</mybb-text>
        </v-radio>
        <v-radio :value="false" color="mybb-primary-lighten1">
          <mybb-text slot="label">{{ nmt('no') }}</mybb-text>
        </v-radio>
      </v-radio-group>

      <div v-if="notify" class="mt-4">
        <v-icon color="mybb-warning" class="mr-2" small>mdi-alert</v-icon>
        <mybb-text class="mybb-warning--text">
          {{ nmt('warning') }}
        </mybb-text>
      </div>

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="notificationModal = false">
          {{ nmt('cancel') }}
        </mybb-btn>

        <mybb-btn :loading="loading" :disabled="canSend" inner-icon="mdi-send" @click="save">
          {{ nmt('confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </modal>
</template>

<script>
import { SET_TRANSFER_CONFIGURATION } from '@/graphql/Transfer'

import Modal from '../Modal'

export default {
  name: 'TransferConfigurationModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean
    },
    gathering: {
      type: Object,
      validator(prop) {
        return !prop || (prop && prop.hasOwnProperty('status'))
      }
    },
    transferConfiguration: {
      type: Object,
      validator(prop) {
        return (
          !prop ||
          (prop &&
            prop.hasOwnProperty('goInformations') &&
            prop.hasOwnProperty('backInformations') &&
            prop.hasOwnProperty('comment'))
        )
      }
    }
  },
  data() {
    return {
      loading: false,
      notificationModal: false,
      notify: null,
      initialConfiguration: {
        goInformations: '',
        backInformations: '',
        comment: ''
      },
      configuration: {
        goInformations: '',
        backInformations: '',
        comment: ''
      }
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.configuration = {
            goInformations: this.$get(this.transferConfiguration, 'goInformations', ''),
            backInformations: this.$get(this.transferConfiguration, 'backInformations', ''),
            comment: this.$get(this.transferConfiguration, 'comment', '')
          }

          this.initialConfiguration = JSON.parse(JSON.stringify(this.configuration))
        }
      }
    },
    alreadyFrontPublished() {
      const status = this.$get(this.gathering, 'status')

      if (!status) return false

      return status === this.$const.gatheringStatus.PUBLISHED_FRONT
    },
    canSend() {
      return typeof this.notify !== 'boolean'
    },
    updated() {
      return Object.keys(this.configuration).some(key => this.configuration[key] !== this.initialConfiguration[key])
    },
    disabled() {
      return !Object.values(this.configuration).some(Boolean)
    },
    isWindedUpGathering() {
      if (!this.gathering) return false

      return this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transfer.configuration.${key}`, params)
    },
    nmt(key, params) {
      return this.t(`notificationModal.${key}`, params)
    },
    confirm() {
      if (this.alreadyFrontPublished && this.updated) {
        this.notificationModal = true
        return
      }

      this.save()
    },
    async save() {
      if (this.loading) return
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: SET_TRANSFER_CONFIGURATION,
          variables: {
            gatheringUuid: this.$route.params.gatheringUuid,
            configuration: this.configuration,
            notify: this.notify
          }
        })

        this.$emit('saved')
        this.notificationModal = false
        this.modal = false
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    notificationModal(value) {
      if (!value) {
        this.notify = null
      }
    },
    transferConfiguration(val, old) {
      if (!val || old) return

      this.configuration = {
        goInformations: val.goInformations,
        backInformations: val.backInformations,
        comment: val.comment
      }

      this.initialConfiguration = JSON.parse(JSON.stringify(this.configuration))
    }
  }
}
</script>
