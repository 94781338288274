<template>
  <div class="ActivityForm pb-8" v-cy="$cy.activity.form.container">
    <v-row class="px-2">
      <mybb-btn text color="white" inner-icon="mdi-chevron-left" class="pl-0" @click="backToList()">
        {{ t('return') }}
      </mybb-btn>
      <v-spacer />
    </v-row>
    <v-row class="mb-5" no-gutters>
      <!-- Main Container -->
      <v-col cols="12" md="8">
        <v-card class="Container py-5 px-6">
          <!-- Titles -->
          <div v-if="!isEdition" class="mb-4">
            <mybb-text size="16" weight="bold" class="text-uppercase">
              {{ t('form.title') }}
            </mybb-text>
          </div>
          <div v-if="!isEdition">
            <mybb-text weight="semi-bold">{{ t('form.subtitle') }}</mybb-text>
          </div>

          <v-row class="mt-5">
            <v-col class="py-0">
              <activity-information-form v-model="activity" :is-disabled="isWindedUpGathering" />

              <v-row class="my-7" justify="center">
                <v-col md="6" cols="6">
                  <v-divider />
                </v-col>
              </v-row>

              <activity-orators
                v-model="activity.activityOrators"
                :gatheringUuid="$route.params.gatheringUuid"
                :is-disabled="isWindedUpGathering"
              />

              <v-row class="my-7" justify="center">
                <v-col md="6" cols="6">
                  <v-divider />
                </v-col>
              </v-row>

              <activity-settings v-model="activity" :gathering="gathering" :minimum-quota="minimumQuota" />
            </v-col>
          </v-row>

          <v-row class="mt-10 mb-5 px-2" justify="space-between">
            <mybb-btn color="mybb-grey" @click="backToList()">
              {{ t('cancel') }}
            </mybb-btn>

            <mybb-btn
              v-if="isEdition"
              v-cy="$cy.activity.form.deletion"
              color="mybb-error"
              @click="deleteModal = true"
              :disabled="isWindedUpGathering"
              :loading="loading.delete"
            >
              {{ t('delete') }}
            </mybb-btn>

            <mybb-btn
              v-cy="$cy.activity.form.submit"
              color="mybb-success"
              inner-icon="mdi-content-save"
              :disabled="!canSubmit"
              :loading="loading.save"
              @click="saveModal = true"
            >
              {{ t('save') }}
            </mybb-btn>
          </v-row>
        </v-card>
      </v-col>

      <!-- Side panels -->
      <v-col cols="12" md="4" class="px-3">
        <gathering-informations :gatheringUuid="$route.params.gatheringUuid" />
      </v-col>
    </v-row>

    <!-- Save modal -->
    <modal v-model="modal" :title="t(isEdition ? 'confirmEdit' : 'confirmSave')">
      <div v-if="isEditionUnderPublished">
        <mybb-text class="d-block">{{ t('confirmEditText') }}</mybb-text>
      </div>

      <div class="mt-4" v-if="isEditionUnderPublished || isCreationUnderPublished">
        <mybb-text class="mb-3" weight="bold">
          {{ t('confirmHighlight') }}
        </mybb-text>
      </div>

      <v-row justify="center" class="mt-10">
        <mybb-btn class="mr-10" color="mybb-grey" @click="modal = false">
          {{ t('cancelButton') }}
        </mybb-btn>
        <mybb-btn
          v-cy="$cy.activity.form.saveModal.submit"
          :loading="loading.save"
          color="mybb-success"
          @click="submit"
        >
          {{ t('confirmButton') }}
        </mybb-btn>
      </v-row>
    </modal>

    <!-- Delete modal -->
    <modal v-model="deleteModal" :title="t('deleteModal.title')">
      <div class="text-center">
        <mybb-text>{{ t('deleteModal.text') }}</mybb-text>
      </div>

      <div v-if="hasParticipants" class="text-center mt-4">
        <mybb-text class="mybb-error--text">{{ t('deleteModal.warning') }}</mybb-text>
      </div>

      <template v-slot:actions>
        <v-row justify="center">
          <mybb-btn class="mr-10" color="mybb-grey" @click="deleteModal = false">
            {{ t('deleteModal.cancel') }}
          </mybb-btn>
          <mybb-btn
            :loading="loading.delete"
            v-cy="$cy.activity.form.deletionModal.submit"
            color="mybb-error"
            @click="deleteActivity"
          >
            {{ t('deleteModal.confirm') }}
          </mybb-btn>
        </v-row>
      </template>
    </modal>

    <!-- Not saved modal -->
    <data-not-saved-modal :initial="initialActivity" :current="activity" :bypass="savingStep" />
  </div>
</template>

<script>
import GatheringInformations from '@/components/mybb/ui/GatheringInformations'
import ActivityInformationForm from '@/components/mybb/activity/ActivityInformationForm.vue'
import ActivityOrators from '@/components/mybb/activity/ActivityOrators'
import ActivitySettings from '@/components/mybb/activity/ActivitySettings'
import DataNotSavedModal from '@/components/mybb/DataNotSavedModal'
import Modal from '@/components/mybb/Modal'

import { file } from '@/mixins'

import { CREATE_ACTIVITY, UPDATE_ACTIVITY } from '@/graphql/Activities/Activities'
import { GET_ACTIVITY, DELETE_ACTIVITY } from '@/graphql/Activity'
import { GET_GATHERING_TYPE } from '@/graphql/Gatherings/GetGathering'

export default {
  name: 'ActivityForm',
  mixins: [file],
  components: {
    ActivityInformationForm,
    ActivityOrators,
    ActivitySettings,
    DataNotSavedModal,
    GatheringInformations,
    Modal
  },
  data() {
    return {
      cancelModal: false,
      saveModal: false,
      deleteModal: false,
      savingStep: false,
      loading: {
        save: false,
        delete: false
      },
      notify: null,
      activity: {
        gatheringUuid: this.$route.params.gatheringUuid,
        activityOrators: []
      },
      initialActivity: null
    }
  },
  computed: {
    modal: {
      get() {
        return this.saveModal
      },
      set(value) {
        this.saveModal = value

        if (!value) {
          this.$nextTick(() => {
            this.notify = null
          })
        }
      }
    },
    activityEntry() {
      const activity = {
        activityCategoryUuid: this.activity.activityCategoryUuid,
        name: this.activity.name,
        date: this.activity.date,
        startHour: this.activity.startHour,
        endHour: this.activity.endHour,
        room: this.activity.room,
        place: this.activity.place,
        visioLink: this.activity.visioLink,
        website: this.activity.website,
        entryType: this.activity.entryType,
        organizer: this.activity.organizer,
        isVirtual: this.activity.isVirtual,
        isPhysical: this.activity.isPhysical,
        image: this.activity.image,
        document: this.activity.document,
        price: this.activity.price * 100,
        quota: this.activity.quota,
        hasCustomTarget: this.activity.hasCustomTarget,
        activityTarget: this.activity.activityTarget,
        activityOrators: this.activity.activityOrators
      }

      if (this.isEdition) {
        activity.activityUuid = this.$route.params.activityUuid
      }

      return activity
    },
    isQuotaRestricted() {
      if (!this.isEdition || !this.gathering) return false

      const { PUBLISHED, PUBLISHED_FRONT, WINDED_UP, CLOSED } = this.$const.gatheringStatus

      return [PUBLISHED, PUBLISHED_FRONT, WINDED_UP, CLOSED].includes(this.gathering.status)
    },
    minimumQuota() {
      if (!this.isQuotaRestricted || !(this.activity && this.activity.activityParticipants)) {
        return 0
      }

      return this.activity.activityParticipants.filter(
        ap => !ap.presence || ap.presence === this.$const.presence.present
      ).length
    },
    validQuota() {
      return (
        this.activity.entryType !== this.$const.activity.entryType.onInvitation ||
        (this.activity.entryType === this.$const.activity.entryType.onInvitation &&
          this.activity.quota &&
          this.activity.quota > 0 &&
          !(this.isQuotaRestricted && this.activity.quota < this.minimumQuota))
      )
    },
    canSubmit() {
      // can submit form if basic fields are filled
      // and if entry is "free" or "on invitation with customTarget"
      // if customTarget is choosen, need to have at least 1 category
      if (this.isWindedUpGathering) return false

      if (!this.validQuota) return false

      if (!this.activity.activityCategoryUuid) return false

      if (!this.activity.date || !this.activity.startHour || !this.activity.endHour) return false

      if (this.isCongress && !this.activity.organizer) return false

      if (
        this.isHybrid &&
        typeof this.activity.isPhysical !== 'boolean' &&
        typeof this.activity.isVirtual !== 'boolean'
      )
        return false

      if (this.activity.price < 0) return false

      if (!this.activity.entryType) return false

      const { onInvitation, onInvitationWithoutQuota } = this.$const.activity.entryType

      if ([onInvitation, onInvitationWithoutQuota].includes(this.activity.entryType)) {
        if (
          this.activity.hasCustomTarget !== false && // "hasCustomTarget" can be null - don't change this for a falsy test
          (!Array.isArray(this.activity.activityTarget) ||
            (Array.isArray(this.activity.activityTarget) && this.activity.activityTarget.length <= 0))
        )
          return false
      }

      return true
    },
    isCongress() {
      if (!this.gathering) return false

      return this.gathering.congress
    },
    isHybrid() {
      if (!this.gathering) return false

      const { isPhysical, isVirtual } = this.gathering
      return isPhysical && isVirtual
    },
    isEdition() {
      return typeof this.$route.params.activityUuid === 'string'
    },
    isEditionUnderPublished() {
      if (!this.gathering) return false

      const { PUBLISHED_FRONT } = this.$const.gatheringStatus

      return this.isEdition && this.gathering.status === PUBLISHED_FRONT
    },
    isCreationUnderPublished() {
      if (!this.gathering) return false

      const { PUBLISHED_FRONT } = this.$const.gatheringStatus

      return !this.isEdition && this.gathering.status === PUBLISHED_FRONT
    },
    hasParticipants() {
      if (!this.isEdition) return false

      return this.$get(this.backendActivity, 'activityParticipants', []).length > 0
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    backendActivity: {
      query: GET_ACTIVITY,
      variables() {
        return {
          activityUuid: this.$route.params.activityUuid
        }
      },
      update({ activity }) {
        const _activity = JSON.parse(JSON.stringify(activity))

        if (!Array.isArray(activity.activityOrators)) {
          _activity.activityOrators = []
        }

        _activity.activityOrators = activity.activityOrators.map(orator => ({
          participantUuid: orator.participantUuid
        }))

        if (Number.isSafeInteger(activity.price)) {
          _activity.price = activity.price / 100
        }

        if (activity.image) {
          _activity.image = this.formatBackendFile(activity.image)
        }

        if (activity.document) {
          _activity.document = this.formatBackendFile(activity.document)
        }

        return _activity
      },
      result() {
        this.activity = this.backendActivity
        this.initialActivity = JSON.parse(JSON.stringify(this.backendActivity))
      },
      skip() {
        return !this.$route.params.activityUuid
      }
    },
    gathering: {
      query: GET_GATHERING_TYPE,
      variables() {
        return {
          gatheringUuid: this.activity.gatheringUuid
        }
      },
      skip() {
        return !this.activity.gatheringUuid
      }
    }
  },
  mounted() {
    this.initialActivity = JSON.parse(JSON.stringify(this.activity))
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.activity.${key}`, params)
    },
    backToList(forceList = false) {
      this.$nextTick(() => {
        this.loading = {
          save: false,
          delete: false
        }
      })

      if (!this.isEdition || forceList) {
        this.$router.push({
          name: 'ActivityList',
          params: {
            gatheringUuid: this.$route.params.gatheringUuid
          }
        })
      } else {
        this.$router.push({ name: 'ActivityDetail', params: { activityUuid: this.$route.params.activityUuid } })
      }
    },
    async submit() {
      if (!this.canSubmit || this.savingStep) return
      this.loading.save = true
      this.savingStep = true

      await this.$apollo.mutate({
        mutation: this.isEdition ? UPDATE_ACTIVITY : CREATE_ACTIVITY,
        variables: {
          gatheringUuid: this.$route.params.gatheringUuid,
          activityEntry: this.activityEntry
        }
      })
      this.savingStep = false
      this.backToList()
    },
    async deleteActivity() {
      this.loading.delete = true
      this.savingStep = true

      await this.$apollo.mutate({
        mutation: DELETE_ACTIVITY,
        variables: {
          activityUuid: this.$route.params.activityUuid
        }
      })

      this.backToList(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.ActivityForm {
  * {
    color: var(--v-mybb-primary-sidebar-base);
  }

  .Container {
    border-radius: 8px;
  }
}
</style>
