var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExpenseNoteList mt-5 pb-8"},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('mybb-text-field',{attrs:{"background-color":"white","placeholder":_vm.$t('mybb.expenseNoteList.searchPlaceholder'),"icon":"mdi-magnify"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('span',{staticClass:"ExpenseNoteList-filter-wrapper"},[_c('mybb-btn',{staticClass:"ml-2",attrs:{"background":"white","color":"mybb-grey-lighten1","icon":"","inner-icon":"mdi-filter-variant"},on:{"click":function($event){_vm.filtersOpen = true}}}),(_vm.isFilterSelected)?_c('div',{staticClass:"ExpenseNoteList-buttonDot"}):_vm._e()],1),_c('mybb-btn',{staticClass:"ml-2",attrs:{"background":"white","color":"mybb-grey-lighten1","icon":"","inner-icon":"mdi-file-export"},on:{"click":function($event){return _vm.extractExpenseNote()}}}),_c('v-spacer'),_c('mybb-btn',{attrs:{"inner-icon":"mdi-plus","disabled":_vm.isWindedUpGathering},on:{"click":function($event){return _vm.$router.push({ name: 'ExpenseNoteForm' })}}},[_vm._v(" "+_vm._s(_vm.$t('mybb.expenseNoteList.createExpenseButton'))+" ")])],1),(!_vm.items)?_c('Loader',{attrs:{"color":"white"}}):_c('v-data-table',{staticClass:"ExpenseNoteList-table px-4 pt-3",attrs:{"headers":_vm.headers,"item-key":"uuid","items-per-page":_vm.tableSettings.itemsPerPage,"items":_vm.items,"search":_vm.searchText,"custom-filter":_vm.$dataTableFilter(_vm.filterFields),"custom-sort":_vm.$dataTableSort(_vm.sortMapping),"sort-by":"date","sort-desc":"","footer-props":_vm.tableSettings.footerProps},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var id = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(id))])]}},{key:"item.lastname",fn:function(ref){
var lastname = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(lastname))])]}},{key:"item.firstname",fn:function(ref){
var firstname = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(firstname))])]}},{key:"item.date",fn:function(ref){
var date = ref.value;
return [_c('mybb-text',[_vm._v(_vm._s(_vm._f("dateFormat")(date)))])]}},{key:"item.validatedPrice",fn:function(ref){
var validatedPrice = ref.value;
return [_c('mybb-text',[_vm._v(_vm._s(_vm._f("price")(validatedPrice)))])]}},{key:"item.totalPrice",fn:function(ref){
var totalPrice = ref.value;
return [_c('mybb-text',[_vm._v(_vm._s(_vm._f("price")(totalPrice)))])]}},{key:"item.status",fn:function(ref){
var status = ref.value;
return [_c('v-chip',{staticClass:"Chip py-2 px-4",attrs:{"color":status.color,"dark":""}},[_vm._v(_vm._s(status.text))])]}},{key:"item.expenseNoteUuid",fn:function(ref){
var expenseNoteUuid = ref.value;
return [_c('router-link',{staticClass:"ExpenseNoteList-tableLink",attrs:{"to":{
          name: 'ExpenseNoteDetails',
          params: { gatheringUuid: _vm.$route.params.gatheringUuid, expenseNoteUuid: expenseNoteUuid }
        }}},[_c('v-btn',{staticClass:"table-icon",attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"mybb-grey-lighten1"}},[_vm._v("mdi-eye")])],1)],1)]}}],null,true)}),_c('FilterDrawer',{attrs:{"open":_vm.filtersOpen,"label":_vm.$t('mybb.expenseNoteList.statusFilterLabel')},on:{"close":function($event){_vm.filtersOpen = false}},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }