<template>
  <modal v-model="modal" :title="t('title')">
    <div class="HostingConfiguration" v-cy="$cy.gathering.hosting.configuration.modal.container">
      <!-- Informations based on gathering -->
      <v-row>
        <v-col cols="3" md="3" class="pr-0">
          <div>
            <mybb-text size="12" class="mybb-grey-lighten1--text">
              {{ t('checkIn') }}
            </mybb-text>
          </div>
          <div>
            <mybb-text>
              {{ checkIn | date }}
            </mybb-text>
          </div>
        </v-col>
        <v-col cols="3" md="3" class="px-0">
          <div>
            <mybb-text size="12" class="mybb-grey-lighten1--text">
              {{ t('checkOut') }}
            </mybb-text>
          </div>
          <div>
            <mybb-text>
              {{ checkOut | date }}
            </mybb-text>
          </div>
        </v-col>
        <v-col cols="3" md="3" class="pl-0">
          <div>
            <mybb-text size="12" class="mybb-grey-lighten1--text">
              {{ t('nightsCount') }}
            </mybb-text>
          </div>
          <div>
            <mybb-text>
              {{ nightsCount }}
            </mybb-text>
          </div>
        </v-col>
      </v-row>

      <!-- Warning text in case eveArrival or postDeparture is choosed-->
      <div class="mt-2" v-if="configuration.eveArrival">
        <v-icon small color="mybb-warning" class="mr-2">mdi-alert</v-icon>
        <mybb-text class="mybb-warning--text">
          {{ t('eveArrivalWarning') }}
        </mybb-text>
      </div>
      <div class="mt-2" v-if="configuration.postDeparture">
        <v-icon small color="mybb-warning" class="mr-2">mdi-alert</v-icon>
        <mybb-text class="mybb-warning--text">
          {{ t('postDepartureWarning') }}
        </mybb-text>
      </div>

      <v-row justify="center" class="py-8">
        <v-col cols="8" md="8" class="pa-0">
          <v-divider />
        </v-col>
      </v-row>

      <!-- Eve arrival -->
      <fieldset id="eve-arrival-field" class="border-off">
        <v-row align="center" class="px-3 mb-4">
          <mybb-text class="mr-10">
            {{ t('eveArrival') }}
          </mybb-text>

          <v-radio-group
            v-model="configuration.eveArrival"
            class="RadioGroup mt-0 pt-0"
            hide-details
            dense
            row
            :disabled="isWindedUpGathering"
          >
            <v-radio
              :value="true"
              color="mybb-primary-lighten1"
              :label="t('yes')"
              v-cy="$cy.gathering.hosting.configuration.modal.eveArrival.yes"
            />
            <v-radio
              :value="false"
              color="mybb-primary-lighten1"
              :label="t('no')"
              v-cy="$cy.gathering.hosting.configuration.modal.eveArrival.no"
            />
          </v-radio-group>
        </v-row>
      </fieldset>

      <!-- Post event departure-->
      <fieldset id="post-departure-field" class="border-off">
        <v-row align="center" class="px-3 mb-4">
          <mybb-text class="mr-10">
            {{ t('postDeparture') }}
          </mybb-text>

          <v-radio-group
            v-model="configuration.postDeparture"
            class="RadioGroup mt-0 pt-0"
            hide-details
            dense
            row
            :disabled="isWindedUpGathering"
          >
            <v-radio :value="true" color="mybb-primary-lighten1" :label="t('yes')" />
            <v-radio :value="false" color="mybb-primary-lighten1" :label="t('no')" />
          </v-radio-group>
        </v-row>
      </fieldset>

      <!-- Choose eve arrival allowed -->
      <v-row align="center" class="px-3 mb-5" v-if="configuration.eveArrival">
        <mybb-text class="mr-10">
          {{ t('chooseEveArrivalAllowed') }}
        </mybb-text>

        <v-radio-group
          v-model="configuration.chooseEveArrivalAllowed"
          class="RadioGroup mt-0 pt-0"
          hide-details
          dense
          row
          :disabled="isWindedUpGathering"
        >
          <v-radio
            :value="true"
            color="mybb-primary-lighten1"
            :label="t('yes')"
            v-cy="$cy.gathering.hosting.configuration.modal.chooseNights.yes"
          />
          <v-radio
            :value="false"
            color="mybb-primary-lighten1"
            :label="t('no')"
            v-cy="$cy.gathering.hosting.configuration.modal.chooseNights.no"
          />
        </v-radio-group>

        <div class="mt-2" v-if="configuration.chooseEveArrivalAllowed">
          <v-icon small color="mybb-warning" class="mr-2">mdi-alert</v-icon>
          <mybb-text class="mybb-warning--text">
            {{ t('chooseEveArrivalAllowedWarning') }}
          </mybb-text>
        </div>
      </v-row>

      <!-- Choose post departure allowed -->
      <v-row align="center" class="px-3 mb-5" v-if="configuration.postDeparture">
        <mybb-text class="mr-10">
          {{ t('choosePostDepartureAllowed') }}
        </mybb-text>

        <v-radio-group
          v-model="configuration.choosePostDepartureAllowed"
          class="RadioGroup mt-0 pt-0"
          hide-details
          dense
          row
          :disabled="isWindedUpGathering"
        >
          <v-radio :value="true" color="mybb-primary-lighten1" :label="t('yes')" />
          <v-radio :value="false" color="mybb-primary-lighten1" :label="t('no')" />
        </v-radio-group>

        <div class="mt-2" v-if="configuration.choosePostDepartureAllowed">
          <v-icon small color="mybb-warning" class="mr-2">mdi-alert</v-icon>
          <mybb-text class="mybb-warning--text">
            {{ t('choosePostDepartureAllowedWarning') }}
          </mybb-text>
        </div>
      </v-row>

      <!-- Comment -->
      <mybb-textarea
        v-if="configuration.eveArrival"
        v-model="configuration.comment"
        :label="t('comment')"
        icon="mdi-message"
        :disabled="isWindedUpGathering"
      />

      <!-- Choose nights allowed -->
      <v-row align="center" class="px-3 mt-5" v-if="!isOneDayEvent">
        <mybb-text class="mr-10">
          {{ t('chooseNightsAllowed') }}
        </mybb-text>

        <v-radio-group
          v-model="configuration.chooseNightsAllowed"
          class="RadioGroup mt-0 pt-0"
          hide-details
          dense
          row
          :disabled="isWindedUpGathering"
        >
          <v-radio
            :value="true"
            color="mybb-primary-lighten1"
            :label="t('yes')"
            v-cy="$cy.gathering.hosting.configuration.modal.chooseNights.yes"
          />
          <v-radio
            :value="false"
            color="mybb-primary-lighten1"
            :label="t('no')"
            v-cy="$cy.gathering.hosting.configuration.modal.chooseNights.no"
          />
        </v-radio-group>
      </v-row>

      <!-- Buttons -->
      <v-row class="mt-10" justify="center">
        <mybb-btn @click="modal = false" color="mybb-grey" class="Btn-marged">{{ t('cancel') }}</mybb-btn>
        <mybb-btn
          :disabled="!canSave"
          :loading="saving"
          @click="submit"
          v-cy="$cy.gathering.hosting.configuration.modal.submit"
          inner-icon="mdi-content-save"
          color="mybb-success"
        >
          {{ t('confirm') }}
        </mybb-btn>
      </v-row>
    </div>
  </modal>
</template>

<script>
import { compareAsc, format, addDays } from 'date-fns'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

import { GET_HOSTING_CONFIGURATION, SET_HOSTING_CONFIGURATION } from '@/graphql/Hosting'
import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'

import Modal from '../Modal'

export default {
  components: { Modal },
  name: 'HostingConfiguration',
  props: {
    gatheringUuid: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      configuration: {},
      saving: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)

        this.configuration = this.backendConfiguration || {}

        if (!value) {
          this.$nextTick(() => {
            this.saving = false
          })
        }
      }
    },
    checkIn() {
      const checkIn = this.$get(this.gathering, 'beginDate', null)

      if (!checkIn) return null

      const date = new Date(checkIn)

      if (this.configuration.eveArrival) {
        date.setDate(date.getDate() - 1)
      }

      return date.toISOString()
    },
    checkOut() {
      if (this.configuration.postDeparture) {
        const endDate = this.$get(this.gathering, 'endDate', null)
        return addDays(new Date(endDate), 1)
      }
      return this.$get(this.gathering, 'endDate', null)
    },
    nightsCount() {
      if (!this.checkIn || !this.checkOut) return '- -'

      return differenceInCalendarDays(new Date(this.checkOut), new Date(this.checkIn))
    },
    canSave() {
      if (this.isWindedUpGathering) {
        return false
      }

      if (
        typeof this.configuration.eveArrival !== 'boolean' ||
        typeof this.configuration.chooseNightsAllowed !== 'boolean' ||
        typeof this.configuration.postDeparture !== 'boolean'
      )
        return false

      if (!this.configuration.eveArrival && !this.configuration.postDeparture) {
        return true
      }

      if (!this.configuration.eveArrival && this.configuration.postDeparture) {
        return true
      }

      return (
        typeof this.configuration.chooseEveArrivalAllowed === 'boolean' && (this.configuration.comment || '').length > 0
      )
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    },
    isOneDayEvent() {
      if (this.gathering && compareAsc(new Date(this.gathering.beginDate), new Date(this.gathering.endDate)) === 0) {
        return true
      }
      return false
    }
  },
  apollo: {
    backendConfiguration: {
      query: GET_HOSTING_CONFIGURATION,
      variables() {
        return {
          gatheringUuid: this.gatheringUuid
        }
      },
      update({ hostingConfigurationFromGatheringUuid }) {
        return hostingConfigurationFromGatheringUuid
      },
      result() {
        if (this.backendConfiguration) {
          this.configuration = JSON.parse(JSON.stringify(this.backendConfiguration))
        }
      },
      skip() {
        return !this.gatheringUuid
      }
    },
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.gatheringUuid
        }
      },
      skip() {
        return !this.gatheringUuid
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hostingConfiguration.${key}`, params)
    },
    async submit() {
      if (this.saving || !this.canSave) return
      this.saving = true

      const {
        data: { setHostingConfiguration }
      } = await this.$apollo.mutate({
        mutation: SET_HOSTING_CONFIGURATION,
        variables: {
          gatheringUuid: this.gatheringUuid,
          configuration: {
            eveArrival: this.configuration.eveArrival,
            postDeparture: this.configuration.postDeparture,
            chooseEveArrivalAllowed: this.configuration.eveArrival ? this.configuration.chooseEveArrivalAllowed : false,
            choosePostDepartureAllowed: this.configuration.postDeparture
              ? this.configuration.choosePostDepartureAllowed
              : false,
            chooseNightsAllowed: this.isOneDayEvent ? false : this.configuration.chooseNightsAllowed,
            comment: this.configuration.comment
          }
        }
      })

      this.configuration = setHostingConfiguration
      this.$emit('refresh')
      this.modal = false
    }
  },
  filters: {
    date(value) {
      if (!value) return '- -'

      return format(new Date(value), 'dd/MM/yy')
    }
  }
}
</script>

<style lang="scss">
.HostingConfiguration {
  .RadioGroup {
    display: inline-block;

    label.v-label {
      color: var(--v-mybb-primary-base);
    }
  }

  .Btn-marged {
    margin-right: 60px;
  }

  .border-off {
    border: 0px;
  }
}
</style>
