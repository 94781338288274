import gql from 'graphql-tag'

export const GET_TRANSPORT_CONFIGURATION_FOR_GATHERING = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      status
      transportConfiguration {
        transportConfigurationUuid
        businessUnitConfiguration {
          businessUnitUuid
          companyCode
          geoCode
          centerCost
          accountCode
          localCode
          productCode
          poCode
        }
        comment
        favouredDepartureDate
        favouredDepartureTime
        favouredReturnDate
        favouredReturnTime
        gatheringUuid
        transfertOptionEnabled
      }
    }
  }
`

export const SET_TRANSPORT_CONFIGURATION_FOR_GATHERING = gql`
  mutation setTransportConfiguration($transportConfiguration: InputTransportConfiguration!) {
    setTransportConfiguration(transportConfiguration: $transportConfiguration) {
      transportConfigurationUuid
      businessUnitConfiguration {
        businessUnitUuid
        companyCode
        geoCode
        centerCost
        accountCode
        localCode
        productCode
        poCode
      }
      comment
      favouredDepartureDate
      favouredDepartureTime
      favouredReturnDate
      favouredReturnTime
      gatheringUuid
      transfertOptionEnabled
    }
  }
`
