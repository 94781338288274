<template>
  <div v-if="transportNote">
    <v-row class="ma-0 pa-0">
      <v-col class="pa-0 mr-3" cols="6" md="3">
        <mybb-text-field
          v-model="ticketPrice"
          type="number"
          step="0.05"
          :label="`${t('ticketPrice')}${isInternal ? '*' : ''}`"
          icon="mdi-currency-eur"
          :disabled="isDisabled"
        />
      </v-col>

      <v-col class="pa-0" cols="6" md="3">
        <mybb-text-field
          v-model="agencyFee"
          type="number"
          step="0.05"
          :label="`${t('agencyFee')}${isInternal ? '*' : ''}`"
          icon="mdi-currency-eur"
          :disabled="isDisabled"
        />
      </v-col>
    </v-row>

    <transport-travel-group
      v-model="note"
      @saved="$emit('saved')"
      :travels-through-model="travelsThroughModel"
      :is-disabled="isDisabled"
    />

    <!-- Ticket groups -->
    <v-row v-if="canAddJourneyTicket" class="mt-6">
      <v-col class="py-0">
        <!-- Title -->
        <div class="mb-3">
          <mybb-text weight="bold">
            {{ $get(transportNote, 'participant.userUuid') ? t('ticketGroupTitle') : `${t('ticketGroupTitle')}*` }}
          </mybb-text>
        </div>

        <!-- Files -->
        <v-row>
          <v-col cols="6" md="6" class="py-0">
            <input-file v-model="file" accept=".pdf,.jpg,.png" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TransportTravelGroup from '@/components/mybb/transport/TransportTravelGroup'

import InputFile from '../InputFile'

export default {
  components: { InputFile, TransportTravelGroup },
  name: 'TransportJourney',
  model: {
    prop: 'transportNote',
    event: 'change'
  },
  props: {
    transportNote: {
      type: Object,
      required: false
    },
    travelsThroughModel: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canAddJourneyTicket() {
      const status = this.$get(this.transportNote, 'status')

      if (!status) return false

      const { ACCEPTED, TO_EMIT, REFUSED } = this.$const.transport.noteStatus

      return [ACCEPTED, TO_EMIT, REFUSED].includes(status)
    },
    agencyFee: {
      get() {
        return this.toReadablePrice(this.transportNote.agencyFee)
      },
      set(value) {
        this.$emit('change', { ...this.transportNote, agencyFee: Math.round(Number(value) * 100) })
      }
    },
    file: {
      get() {
        return this.$get(this.note, 'files[0]', null)
      },
      set(value) {
        const files = []

        if (Array.isArray(value)) {
          files.push(...value.filter(file => file && file.name && file.content))
        } else if (value && value.name && value.content) {
          files.push(value)
        }

        this.$emit('change', { ...this.note, files })
      }
    },
    ticketPrice: {
      get() {
        return this.toReadablePrice(this.transportNote.ticketPrice)
      },
      set(value) {
        this.$emit('change', { ...this.transportNote, ticketPrice: Math.round(Number(value) * 100) })
      }
    },
    note: {
      get() {
        return this.transportNote
      },
      set(value) {
        this.$emit('change', value)
      }
    },
    isInternal() {
      const status = this.$get(this.note, 'origin', null)
      return [null, this.$const.noteOrigin.INTERNAL].includes(status)
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transportNotePage.travelGroup.${key}`, params)
    },
    toReadablePrice(value) {
      return value ? Number(value) / 100 : null
    }
  }
}
</script>
