<template>
  <modal v-model="modal" :title="isInternal ? t('titleInternal') : t('title')">
    <template v-slot:text>
      <div class="text-center" v-if="!isInternal">
        <div class="mb-4">
          <mybb-text>{{ t('header', healthCareProfessional) }}</mybb-text>
        </div>
        <div>
          <mybb-text weight="bold">{{ t('subline') }}</mybb-text>
        </div>
      </div>
    </template>

    <template v-slot:actions>
      <mybb-btn @click="modal = false" class="mr-12" color="mybb-grey">
        {{ t('cancel') }}
      </mybb-btn>

      <mybb-btn :loading="loading" @click="onRefuse" color="mybb-error">
        {{ t('confirm') }}
      </mybb-btn>
    </template>
  </modal>
</template>

<script>
import Modal from '../Modal'

export default {
  name: 'RefusalModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    note: {
      type: Object,
      required: false
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.loading = false
        }
      }
    },
    healthCareProfessional() {
      return this.$get(this.note, 'healthCareProfessional', null)
    },
    isInternal() {
      return this.$get(this.note, 'origin') === this.$const.noteOrigin.INTERNAL
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transportNotePage.refusalModal.${key}`, params)
    },
    onRefuse() {
      this.loading = true

      this.$emit('refuse')
    }
  }
}
</script>
