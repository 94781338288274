<template>
  <v-container fluid class="HostingForm pa-0">
    <!--  Header  -->
    <v-row justify="space-between" class="px-3">
      <mybb-btn class="px-0" color="white" text @click="goBack" inner-icon="mdi-chevron-left">
        {{ t('back') }}
      </mybb-btn>
    </v-row>

    <!-- Content container -->
    <v-row class="mb-5" no-gutters>
      <!-- Main content -->
      <v-col cols="8">
        <v-container class="Container px-6 py-5">
          <v-row class="px-3" justify="space-between" align="center">
            <mybb-text size="16" weight="bold" class="text-uppercase">
              {{ t('formHeader') }}
            </mybb-text>
          </v-row>

          <v-row class="px-3 mt-7">
            <v-col class="pa-0">
              <v-row class="mb-6">
                <v-col cols="6" md="6" class="py-0 pr-0">
                  <mybb-text-field
                    v-model="hosting.name"
                    :label="t('nameLabel')"
                    icon="mdi-home"
                    :disabled="isWindedUpGathering"
                  />
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <mybb-text-field
                    v-model="hosting.roomType"
                    :label="t('roomTypeLabel')"
                    icon="mdi-home"
                    :disabled="isWindedUpGathering"
                  />
                </v-col>
              </v-row>
              <v-row class="mb-6">
                <v-col cols="6" md="6" class="py-0 pr-0">
                  <mybb-text-field
                    v-model="hosting.address"
                    :label="t('addressLabel')"
                    icon="mdi-map-marker"
                    :disabled="isWindedUpGathering"
                  />
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <mybb-text-field
                    v-model="hosting.phone"
                    :label="t('phoneLabel')"
                    icon="mdi-phone"
                    :disabled="isWindedUpGathering"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="6" class="py-0 pr-0">
                  <participant-picker
                    v-model="hosting.tourLeaderUuid"
                    :tags="tags"
                    :label="t('tourLeaderLabel')"
                    icon="mdi-account"
                    :disabled="isWindedUpGathering"
                    clearable
                    has-append-icon
                    autocomplete-empty-search
                  />
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <mybb-text-field
                    v-model="hosting.website"
                    :label="t('websiteLabel')"
                    icon="mdi-earth"
                    :disabled="isWindedUpGathering"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="nights.length" class="px-3 mt-7">
            <mybb-text size="14" weight="semi-bold">
              {{ t('quotaTitle') }}
            </mybb-text>
          </v-row>

          <v-row class="px-3">
            <div class="HostingForm-nightConfig text-center" v-for="(night, i) in nights" :key="i">
              <mybb-text size="14" class="mt-5">
                {{ night | nightFormat }}
              </mybb-text>
              <v-col cols="12" class="pa-2 pb-0 pt-3">
                <mybb-text-field
                  @input="value => setNightQuota(night, value)"
                  :value="hosting.nights[dateAsKey(night)].quota"
                  type="number"
                  :label="t('quotaLabel')"
                  icon="mdi-account-group"
                  min="0"
                  :disabled="isWindedUpGathering"
                />
              </v-col>
              <v-col cols="12" class="pa-2 pb-0 pt-5">
                <mybb-text-field
                  v-model.number="hosting.nights[dateAsKey(night)].price"
                  type="number"
                  :label="t('quotaPriceLabel')"
                  :disabled="isDisabled(night) || isWindedUpGathering"
                  icon="mdi-currency-eur"
                  min="0"
                />
              </v-col>
            </div>
          </v-row>

          <!-- Actions -->
          <v-row justify="space-between" class="mt-10 mb-4 px-3">
            <!-- Cancel -->
            <mybb-btn color="mybb-grey" @click="goBack">
              {{ t('cancelButton') }}
            </mybb-btn>

            <!-- Save -->
            <mybb-btn
              color="mybb-success"
              :disabled="!canSave"
              :loading="loading"
              @click="modal = true"
              inner-icon="mdi-content-save"
            >
              {{ t('saveButton') }}
            </mybb-btn>
          </v-row>
        </v-container>
      </v-col>

      <!-- Side information -->
      <v-col cols="12" md="4" class="px-3 py-0 my-0">
        <hosting-informations context="HostingForm" :gatheringUuid="$route.params.gatheringUuid" class="mb-3" />
      </v-col>
    </v-row>

    <data-not-saved-modal :initial="initial" :current="hosting" />

    <modal v-model="modal" :title="t(`modal.${isEdit ? 'edit' : 'create'}.title`)">
      <mybb-text>{{ t(`modal.${isEdit ? 'edit' : 'create'}.text`) }}</mybb-text>

      <div class="mt-10 text-center">
        <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
          {{ t('modal.cancel') }}
        </mybb-btn>

        <mybb-btn color="mybb-success" :loading="loading" @click="submit">
          {{ t('modal.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </v-container>
</template>

<script>
import Categories from 'mybb-categories'

import DataNotSavedModal from '@/components/mybb/DataNotSavedModal'
import HostingInformations from '@/components/mybb/ui/HostingInformations'
import ParticipantPicker from '@/components/mybb/user/ParticipantPicker'
import Modal from '@/components/mybb/Modal'

import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'
import { GET_HOSTING_CONFIGURATION, HOSTING_FULL, CREATE_HOSTING, UPDATE_HOSTING } from '@/graphql/Hosting'
import dateFnsFormat from 'date-fns/format'

export default {
  name: 'HostingForm',
  components: { ParticipantPicker, HostingInformations, DataNotSavedModal, Modal },

  data() {
    return {
      loading: false,
      modal: false,
      initial: {
        name: '',
        roomType: '',
        address: '',
        phone: '',
        tourLeaderUuid: null,
        website: '',
        nights: {}
      },
      hosting: {
        name: '',
        roomType: '',
        address: '',
        phone: '',
        tourLeaderUuid: null,
        website: '',
        nights: {}
      }
    }
  },

  computed: {
    isEdit() {
      return Boolean(this.$route.params.hostingUuid)
    },
    canSave() {
      const nightsFilled = Object.values(this.hosting.nights).reduce(
        (acc, night) => acc && (!night.quota || (night.quota && Number.isFinite(night.price) && night.price > 0)),
        true
      )

      return this.hosting.name && this.hosting.roomType && nightsFilled && !this.isWindedUpGathering
    },
    tags() {
      return [Categories.constants.Tag.TourLeader]
    },
    isWindedUpGathering() {
      const gatheringStatus = this.$get(this.gathering, 'status', null)

      return gatheringStatus === this.$const.gatheringStatus.WINDED_UP
    },
    nights() {
      if (!this.gathering) return []

      const nights = []
      const date = new Date(this.gathering.beginDate)
      const endDate = new Date(this.gathering.endDate).toISOString().slice(0, 10)
      const postDeparture = this.$get(this.hostingConfigurationFromGatheringUuid, 'postDeparture', false)

      if (this.$get(this.hostingConfigurationFromGatheringUuid, 'eveArrival', false)) {
        date.setDate(date.getDate() - 1)
      }

      while (date.toISOString().slice(0, 10) !== endDate) {
        nights.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }

      if (postDeparture) {
        nights.push(new Date(endDate))
      }

      return nights
    }
  },

  apollo: {
    hostingConfigurationFromGatheringUuid: {
      query: GET_HOSTING_CONFIGURATION,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      result() {
        if (this.isEdit) return

        this.hosting.nights = this.nights.reduce((result, current) => {
          return {
            ...result,
            [this.dateAsKey(current)]: {}
          }
        }, {})

        this.initial.nights = JSON.parse(JSON.stringify(this.hosting.nights))
      },
      skip() {
        return !this.$route.params.gatheringUuid
      }
    },
    hostingFromBackend: {
      query: HOSTING_FULL,
      variables() {
        return {
          hostingUuid: this.$route.params.hostingUuid
        }
      },
      update({ hosting }) {
        return hosting
      },
      result() {
        const { name, roomType, address, phone, tourLeaderUuid, website, hostingNights } = this.hostingFromBackend

        this.hosting.name = name
        this.hosting.roomType = roomType
        this.hosting.address = address
        this.hosting.phone = phone
        this.hosting.tourLeaderUuid = tourLeaderUuid
        this.hosting.website = website

        for (const night of hostingNights || []) {
          const key = this.dateAsKey(night.date)

          this.$set(this.hosting.nights, key, { quota: night.quota, price: night.price / 100 })
        }

        this.initial = JSON.parse(JSON.stringify(this.hosting))
      },
      skip() {
        return !this.$route.params.hostingUuid
      }
    },
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      result() {
        if (this.isEdit) return

        this.hosting.nights = this.nights.reduce((result, current) => {
          return {
            ...result,
            [this.dateAsKey(current)]: {}
          }
        }, {})

        this.initial.nights = JSON.parse(JSON.stringify(this.hosting.nights))
      }
    }
  },

  filters: {
    nightFormat(date) {
      return dateFnsFormat(new Date(date), 'dd/MM')
    }
  },

  methods: {
    t(key, params) {
      return this.$t(`mybb.hostingForm.${key}`, params)
    },
    goBack() {
      if (this.isEdit) {
        return this.$router.push({
          name: 'HostingDetailsPage',
          params: { hostingUuid: this.$route.params.hostingUuid }
        })
      }

      return this.$router.push({ name: 'HostingList' })
    },
    dateAsKey(date) {
      if (!date) return null

      return dateFnsFormat(new Date(date), 'yyyy-MM-dd')
    },
    isDisabled(date) {
      const key = this.dateAsKey(date)
      const night = this.hosting.nights[key]

      return !night || (night && (!night.quota || night.quota <= 0))
    },
    setNightQuota(night, value) {
      if (!Number.isNaN(value) && Number(value) >= 0) {
        this.$set(this.hosting.nights[this.dateAsKey(night)], 'quota', value)

        if (Number(value) === 0) {
          delete this.hosting.nights[this.dateAsKey(night)].price
        }
      } else if (!value) {
        delete this.hosting.nights[this.dateAsKey(night)]
      }
    },
    async submit() {
      if (!this.canSave || this.loading) return
      this.loading = true

      try {
        const hosting = JSON.parse(JSON.stringify(this.hosting))
        for (const night in hosting.nights) {
          if (!hosting.nights[night].quota) {
            delete hosting.nights[night]
          } else {
            hosting.nights[night].price = Number(hosting.nights[night].price) * 100

            if (hosting.nights[night].quota) {
              hosting.nights[night].quota = Number(hosting.nights[night].quota)
            }
          }
        }

        await this.$apollo.mutate({
          mutation: this.isEdit ? UPDATE_HOSTING : CREATE_HOSTING,
          variables: {
            gatheringUuid: this.$route.params.gatheringUuid,
            hosting,
            hostingUuid: this.$route.params.hostingUuid
          }
        })

        this.initial = JSON.parse(JSON.stringify(this.hosting))

        this.$nextTick(() => this.goBack())
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.HostingForm {
  .Container {
    background-color: white;
    border-radius: 8px;
  }
}

.HostingForm-nightConfig {
  width: 12.5%;
}
</style>
