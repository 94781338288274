<template>
  <div class="ExpenseNoteForm">
    <!-- Header -->
    <mybb-btn text color="white" class="px-0" @click="backToList" inner-icon="mdi-chevron-left">
      {{ t('back') }}
    </mybb-btn>

    <!-- Container -->
    <v-container class="Container px-6 py-5">
      <div class="mb-5">
        <mybb-text size="16" weight="bold" class="text-uppercase">
          {{ isEdition ? t('title-edit') : t('title') }}
        </mybb-text>
      </div>
      <div v-if="!isEdition">
        <mybb-text weight="bold">
          {{ t('subtitle') }}
        </mybb-text>
      </div>

      <!-- Participant informations -->
      <div v-if="!isEdition" class="mt-5">
        <mybb-text>{{ t('hcpSection.title') }}</mybb-text>
        <v-row class="mt-4 mb-6">
          <v-col class="py-0" cols="6" md="6">
            <participant-picker v-model="participantUuid" :label="t('hcpSection.field')" />
          </v-col>
          <v-col v-if="hasPendingSolicitation" cols="12" class="mt-2 py-0">
            <mybb-text class="mybb-error--text">
              {{ t('hcpSection.errorSolicitation') }}
            </mybb-text>
          </v-col>
        </v-row>
      </div>

      <mybb-text class="mb-4">{{ t('expenseFeeSection.title') }}</mybb-text>
      <!-- Expense note lines -->
      <div v-for="(line, index) of lines" :key="`line-${index}`">
        <v-row v-if="index > 0" justify="center">
          <v-col cols="6" md="6">
            <v-divider class="my-6" />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col md="6" cols="6" class="pa-0 pr-2">
            <!-- Expense note category -->
            <mybb-select
              v-model="line.expenseNoteCategoryUuid"
              :items="categories"
              :label="t('expenseFeeSection.category')"
            />
          </v-col>

          <v-col md="3" cols="6" class="pa-0 pl-2 pl-1">
            <!-- Fee date -->
            <date-picker v-model="line.feeDate" :label="t('expenseFeeSection.date')" />
          </v-col>

          <v-col md="3" cols="6" class="pa-0 pl-2">
            <!-- Price -->
            <mybb-text-field
              v-model="line.price"
              type="number"
              step="0.05"
              :label="t('expenseFeeSection.price')"
              icon="mdi-currency-eur"
            />
          </v-col>
        </v-row>

        <!-- Description -->
        <mybb-text-field v-model="line.description" :label="t('expenseFeeSection.description')" class="mt-6" />

        <!-- Amount KM-->
        <mybb-text-field
          type="number"
          step="0.05"
          v-if="line.expenseNoteCategoryUuid === expenseNoteLineCategory.FEEKM"
          v-model="line.amountKilometers"
          :label="t('expenseFeeSection.amountKilometers')"
          class="mt-6"
        />

        <!-- Departure address-->
        <mybb-text-field
          v-if="line.expenseNoteCategoryUuid === expenseNoteLineCategory.FEEKM"
          v-model="line.departureAddress"
          :label="t('expenseFeeSection.departureAddress')"
          class="mt-6"
        />

        <v-row class="mt-6">
          <v-col class="py-0" cols="12" md="6">
            <mybb-text size="12" class="mybb-grey-lighten1--text mb-2">{{ t('expenseFeeSection.file') }}</mybb-text>
            <input-file v-model="line.file" accept=".pdf,.jpg,.png" />
          </v-col>
        </v-row>

        <div class="mt-6">
          <v-row align="center" justify="center">
            <v-col class="py-0 pr-4" cols="12" md="9">
              <mybb-textarea
                v-model="line.comment"
                :label="t('expenseFeeSection.comment')"
                height="64"
                rows="2"
                no-resize
                icon="mdi-message"
              />
            </v-col>

            <v-col class="py-0" cols="6" md="3" justify="center">
              <mybb-btn
                v-if="index > 0"
                @click="removeModal = index"
                class="mybb-error--text"
                color="white"
                inner-icon="mdi-delete-forever"
              >
                {{ t('removeLine') }}
              </mybb-btn>
            </v-col>
          </v-row>
        </div>

        <v-row class="mt-6 px-3" v-if="index === lines.length - 1">
          <mybb-btn @click="addLine" class="mybb-primary-lighten1--text" color="white" inner-icon="mdi-plus">
            {{ t('addLine') }}
          </mybb-btn>
        </v-row>
      </div>

      <v-row class="mt-10 px-3" justify="end">
        <mybb-btn @click="submit" :disabled="!canSubmit" :loading="loading" color="mybb-success">
          {{ t('submit') }}
        </mybb-btn>
      </v-row>
    </v-container>

    <expense-note-save-modal
      v-model="saveModal"
      :participant-uuid="participantUuid"
      :lines="linesDiff"
      :original-lines="originalLines"
      :expense-note-uuid="expenseNoteUuid"
      :categories="expenseNoteCategories"
      :deleted-lines-uuid="deletedLinesUuid"
    />

    <modal v-model="removeModal" :title="t('removeModal.title')">
      <template v-slot:text>
        <v-row justify="center">
          <mybb-btn @click="removeModal = false" class="mr-10" color="mybb-grey">
            {{ t('removeModal.cancel') }}
          </mybb-btn>

          <mybb-btn @click="removeLine" color="mybb-error" inner-icon="mdi-delete-forever">
            {{ t('removeModal.confirm') }}
          </mybb-btn>
        </v-row>
      </template>
    </modal>
  </div>
</template>

<script>
import {
  GET_ALL_EXPENSE_NOTE_CATEGORIES,
  CREATE_EXPENSE_NOTE,
  GET_EXPENSE_NOTE
} from '@/graphql/ExpenseNote/ExpenseNote'
import { GET_GATHERING_TYPE } from '@/graphql/Gatherings/GetGathering'
import { PARTICIPANT_WITH_SOLICITATION } from '@/graphql/Participant'

import DatePicker from '@/components/mybb/ui/DatePicker'
import ParticipantPicker from '@/components/mybb/user/ParticipantPicker'
import InputFile from '@/components/mybb/InputFile'
import Modal from '@/components/mybb/Modal'
import { file } from '@/mixins'
import ExpenseNoteSaveModal from '../../components/mybb/expenseNotes/ExpenseNoteSaveModal.vue'
import _isEqual from 'lodash/isEqual'
import _isObjectLike from 'lodash/isObjectLike'
import _omit from 'lodash/omit'
import { expenseNoteLineCategory } from '@/const/shared'

export default {
  name: 'ExpenseNoteForm',
  components: { ParticipantPicker, DatePicker, InputFile, Modal, ExpenseNoteSaveModal },
  mixins: [file],
  data() {
    return {
      expenseNoteLineCategory: expenseNoteLineCategory,
      deletedLinesUuid: [],
      removeIndex: null,
      participantUuid: null,
      saveModal: false,
      lines: [
        {
          expenseNoteCategoryUuid: null,
          feeDate: null,
          price: null,
          description: null,
          amountKilometers: null,
          departureAddress: null,
          file: null,
          comment: null
        }
      ],
      loading: false,
      linesDiff: [],
      originalLines: []
    }
  },
  computed: {
    categories() {
      return (this.expenseNoteCategories || []).map(category => ({
        value: category.expenseNoteCategoryUuid,
        text: category.label
      }))
    },
    removeModal: {
      get() {
        return typeof this.removeIndex === 'number'
      },
      set(value) {
        if (typeof value === 'number') {
          this.removeIndex = value
        } else {
          this.removeIndex = null
        }
      }
    },
    canSubmit() {
      return (
        this.participantUuid &&
        !this.hasPendingSolicitation &&
        this.lines.every(
          line => line.expenseNoteCategoryUuid && line.feeDate && line.price > 0 && line.description && line.file
        ) &&
        !this.isWindedUpGathering
      )
    },
    expenseNoteUuid() {
      return this.$route.params.expenseNoteUuid
    },
    isEdition() {
      return typeof this.expenseNoteUuid === 'string'
    },
    isWindedUpGathering() {
      if (!this.gathering) return false

      return this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    },
    hasPendingSolicitation() {
      if (!this.gathering) return false
      if (!this.participant) return false
      if (!this.gathering.congress) return false
      if (!this.participant.healthCareProfessionalUuid) return false

      return this.$get(this.participant, 'solicitation.status', null) !== this.$const.solicitationStatus.SENT
    }
  },
  apollo: {
    expenseNoteCategories: {
      query: GET_ALL_EXPENSE_NOTE_CATEGORIES
    },
    initialExpenseNote: {
      query: GET_EXPENSE_NOTE,
      variables() {
        return {
          expenseNoteUuid: this.$route.params.expenseNoteUuid
        }
      },
      update({ expenseNote }) {
        return expenseNote
      },
      result({ data: { expenseNote } }) {
        this.healthCareProfessionalUuid = expenseNote.healthCareProfessionalUuid
        this.participantUuid = expenseNote.participantUuid
        this.lines = expenseNote.lines.map(line => ({
          expenseNoteCategoryUuid: line.expenseNoteCategory.expenseNoteCategoryUuid,
          expenseNoteCategory: line.expenseNoteCategory.label,
          expenseNoteLineUuid: line.expenseNoteLineUuid,
          feeDate: line.feeDate,
          price: line.price / 100,
          description: line.description,
          amountKilometers: line.amountKilometers,
          departureAddress: line.departureAddress,
          file: this.formatBackendFile(line.file),
          comment: line.comment
        }))
        this.originalLines = JSON.parse(JSON.stringify(this.lines))
      },
      skip() {
        return !this.isEdition
      }
    },
    gathering: {
      query: GET_GATHERING_TYPE,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      skip() {
        return !this.$route.params.gatheringUuid
      }
    },
    participant: {
      query: PARTICIPANT_WITH_SOLICITATION,
      variables() {
        return { participantUuid: this.participantUuid }
      },
      skip() {
        return !this.participantUuid
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.expenseNoteForm.${key}`, params)
    },
    backToList() {
      return this.$router.push({ name: 'ExpenseNoteList' })
    },
    addLine() {
      this.lines.push({
        expenseNoteCategoryUuid: null,
        expenseNoteLineUuid: null,
        feeDate: null,
        price: null,
        description: null,
        amountKilometers: null,
        departureAddress: null,
        file: null,
        comment: null
      })
    },
    removeLine() {
      if (this.isEdition && this.lines[this.removeIndex].expenseNoteLineUuid) {
        this.deletedLinesUuid.push(this.lines[this.removeIndex].expenseNoteLineUuid)
      }

      this.lines.splice(this.removeIndex, 1)
      this.removeModal = null
    },
    async submit() {
      for (const line of this.lines) {
        if (line.amountKilometers) {
          line.amountKilometers = parseInt(line.amountKilometers)
        }
      }

      if (!this.canSubmit || this.loading) return

      if (this.isEdition) {
        const linesDiff = []

        for (const line of this.lines) {
          let originalLine = null
          if (line.expenseNoteLineUuid) {
            originalLine = this.originalLines.find(l => l.expenseNoteLineUuid === line.expenseNoteLineUuid)
          } else {
            const index = this.originalLines.findIndex(l => l === line)
            originalLine = this.originalLines[index]
          }

          if ((originalLine && this.recursiveDeepHasDiff(line, originalLine)) || !originalLine) {
            linesDiff.push(line)
          }
        }

        if (linesDiff.length) {
          this.linesDiff = linesDiff
          this.saveModal = true
        } else {
          this.$router.push({ name: 'ExpenseNoteDetails', params: { expenseNoteUuid: this.expenseNoteUuid } })
        }
      } else {
        this.loading = true
        await this.$apollo.mutate({
          mutation: CREATE_EXPENSE_NOTE,
          variables: {
            gatheringUuid: this.$route.params.gatheringUuid,
            participantUuid: this.participantUuid,
            lines: this.lines.map(line =>
              _omit({ ...line, price: Math.trunc(line.price * 100) }, ['expenseNoteLineUuid'])
            ),
            expenseNoteUuid: this.expenseNoteUuid
          }
        })
        this.backToList()
      }
    },
    recursiveDeepHasDiff(source, target, stack = []) {
      const iterable = stack.length ? this.$get(source, stack) : source
      let hasDiff = false

      for (const property in iterable) {
        const path = [...stack, property]

        if (!_isEqual(this.$get(source, path), this.$get(target, path))) {
          if (
            _isObjectLike(this.$get(source, path)) &&
            _isObjectLike(this.$get(target, path)) &&
            Object.keys(this.$get(source, path)).length !== Object.keys(this.$get(target, path)).length &&
            // Array are properly checked with isEqual, so if we are here,
            // arrays are differents
            !Array.isArray(this.$get(source, path)) &&
            !Array.isArray(this.$get(target, path))
          ) {
            hasDiff = this.recursiveDeepHasDiff(source, target, path) || hasDiff
          } else {
            hasDiff = true
          }
        }
      }

      return hasDiff
    }
  }
}
</script>

<style lang="scss">
.ExpenseNoteForm {
  .Container {
    width: 940px;
    margin: 0;
    background-color: white;
    border-radius: 8px;
  }
}
</style>
