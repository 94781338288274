<template>
  <Loader v-if="!hcp" color="white" />
  <div v-else class="relative pb-8 px-3 px-md-10 my-4">
    <div class="NavHead">
      <a @click="backToList">
        <mybb-text class="white--text">{{ t('breadcrumbBase') }}</mybb-text>
      </a>
      <v-icon style="color: white; display: inline-table">mdi-chevron-right</v-icon>
      <mybb-text class="white--text" weight="bold">
        {{ name }}
      </mybb-text>
    </div>
    <div id="nav-hcp" class="mt-5 nav mb-4">
      <div class="nav-left">
        <div class="pb-4 NavLinkContainer">
          <span v-for="(link, index) of links" :key="`${link.label}-${index}`">
            <router-link
              :to="link.route"
              class="mx-4 pb-3 NavLink"
              :class="link.isHighlighted ? 'router-link-exact-active' : ''"
            >
              <mybb-text class="white--text" :weight="link.isHighlighted ? 'bold' : 'regular'">
                {{ link.label }}
              </mybb-text>
            </router-link>
          </span>
        </div>
      </div>
      <div class="nav-right"></div>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { HCP_PARTIAL } from '@/graphql/Hcp/Hcp'

import Loader from '@/components/congrex/tabs/LoaderTab'

export default {
  name: 'HCPPage',
  components: { Loader },
  data() {
    return {
      scrollPosY: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollPosY = window.scrollY
    })
  },
  computed: {
    links() {
      return [
        {
          label: this.t('navLinks.profil'),
          route: { name: 'HCPProfile' },
          isHighlighted: this.highlightRoute('HCPProfile')
        },
        {
          label: this.t('navLinks.event'),
          route: { name: 'HCPEvent' },
          isHighlighted: this.highlightRoute('HCPEvent')
        }
      ]
    },
    name() {
      const lastName = this.$get(this.hcp, 'lastName', '')
      const firstName = this.$get(this.hcp, 'firstName', '')
      const veevaId = this.$get(this.hcp, 'veevaId')

      return `${lastName.toUpperCase()} ${firstName} - ${veevaId}`
    }
  },

  apollo: {
    hcp: {
      query: HCP_PARTIAL,
      variables() {
        return {
          healthCareProfessionalUuid: this.$route.params.hcpUuid
        }
      },
      update({ healthCareProfessional }) {
        return healthCareProfessional
      }
    }
  },
  methods: {
    t(key) {
      return this.$t(`mybb.HCPPage.${key}`)
    },
    highlightRoute(prefixRouteName) {
      return this.$route.name.startsWith(prefixRouteName)
    },
    backToList() {
      return this.$router.push({ name: 'ListHCP' })
    }
  }
}
</script>

<style lang="scss" scoped>
.NavLink {
  border-bottom: 3px solid transparent;
  text-decoration: none;
  transition: border 0.3s;
}

.NavLink:hover,
.NavLink:focus,
.router-link-exact-active {
  border-color: white;
}

.NavHead {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NavLinkContainer {
  display: inline;

  & :first-child {
    & .NavLink {
      margin-left: 0 !important;
    }
  }
}

.relative {
  position: relative;
}

.nav {
  display: flex;
  height: auto;
  width: 100%;
}

.nav-right {
  float: right;
}

.nav-left {
  float: left;
  display: flex;
}
</style>
