import gql from 'graphql-tag'

export const INVITE_HEALTHCARE_PROFESSIONALS_TO_VIRTUAL_SESSION = gql`
  mutation inviteHealthCareProfessionalsToVirtualSession(
    $virtualSessionUuid: GUID!
    $healthCareProfessionalUuids: [GUID!]!
  ) {
    inviteHealthCareProfessionalsToVirtualSession(
      virtualSessionUuid: $virtualSessionUuid
      healthCareProfessionalUuids: $healthCareProfessionalUuids
    ) {
      virtualSessionParticipantUuid
    }
  }
`
