<template>
  <form>
    <!-- Oseus -->
    <v-row v-if="isOseus" class="mb-2">
      <v-col cols="12" md="6">
        <oseus-event-picker
          v-model="oseusEvent"
          @events-loaded="onEventLoaded"
          ref="oseusPicker"
          :error="isOseusMeetingAvailable === false"
          :error-messages="isOseusMeetingAvailable === false ? t('oseusMeetingAlreadyUsedError') : null"
          :check-loading="$apollo.queries.isOseusMeetingAvailable.loading"
        />
      </v-col>
    </v-row>

    <!-- Name -->
    <v-row>
      <v-col cols="12" md="8">
        <mybb-text-field v-model="form.name" :label="t('name')" :disabled="isOseus" />
      </v-col>
    </v-row>

    <!-- Description -->
    <v-row>
      <v-col cols="12">
        <mybb-text-field v-model="form.description" :label="t('description')" />
      </v-col>
    </v-row>

    <!-- Date & time -->
    <v-row>
      <v-col cols="3">
        <date-picker
          v-model="dates"
          :label="t('dates')"
          :min="new Date().toISOString()"
          :disabled="isOseus"
          icon="mdi-calendar"
          multiple
        />
      </v-col>

      <v-col cols="3">
        <time-picker v-model="form.startHour" :label="t('startHour')" />
      </v-col>

      <v-col cols="3">
        <time-picker v-model="form.endHour" :label="t('endHour')" />
      </v-col>
    </v-row>

    <!-- Business unit -->
    <v-row>
      <v-col cols="12">
        <div>
          <!-- @todo - Revoir le style avec Olivier -->
          <mybb-text class="mybb-grey--text">{{ t('businessUnit') }}</mybb-text>
        </div>
        <v-chip-group v-model="form.businessUnitUuid" class="mb-2" dark>
          <v-chip
            v-for="bu of businessUnitItems"
            :key="bu.businessUnitUuid"
            :value="bu.businessUnitUuid"
            :color="bu.businessUnitUuid === form.businessUnitUuid ? bu.color : '#9a9fa4'"
            :close="bu.businessUnitUuid === form.businessUnitUuid"
            @click:close="form.businessUnitUuid = null"
            class="white--text"
            dark
          >
            {{ bu.label }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <!-- Team -->
    <v-row>
      <v-col cols="6" md="3">
        <mybb-autocomplete v-model="form.rocUuid" :label="t('roc')" :items="rocItems" icon="mdi-account" />
      </v-col>
      <v-col cols="6" md="3">
        <mybb-autocomplete v-model="form.cpUuid" :label="t('cp')" :items="cpItems" icon="mdi-account" />
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-10">
      <v-col cols="6" md="3">
        <mybb-btn color="mybb-grey" @click="$emit('cancel')">{{ t('cancel') }}</mybb-btn>
      </v-col>
      <v-col cols="6" md="3">
        <mybb-btn
          :disabled="!canSave"
          :loading="loading.save"
          color="mybb-success"
          inner-icon="mdi-content-save"
          @click="onSave"
        >
          {{ t('save') }}
        </mybb-btn>
      </v-col>

      <v-col
        v-if="!$get(source, 'status') || $get(source, 'status') === $const.virtualSession.status.DRAFT"
        cols="6"
        md="3"
      >
        <virtual-session-status-button
          :virtual-session="form"
          @click="onStatusChange"
          :disabled="isOseusMeetingAvailable === false || $apollo.queries.isOseusMeetingAvailable.loading"
          :loading="loading.status"
        />
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import format from 'date-fns/format'

import MYBB from '@/const/my-bb'
import { SEARCH_ALL_USERS } from '@/graphql/User/GetUsers'
import { GET_FULL_VIRTUAL_SESSION, IS_OSEUS_MEETING_AVAILABLE } from '@/graphql/VirtualSession'
import { schema } from '@/validations/virtualSession'
import DatePicker from '@/components/mybb/ui/DatePicker'
import TimePicker from '@/components/mybb/ui/TimePicker'

import OseusEventPicker from './OseusEventPicker'
import VirtualSessionStatusButton from './VirtualSessionStatusButton'

export default {
  name: 'VirtualSessionForm',
  components: {
    OseusEventPicker,
    DatePicker,
    TimePicker,
    VirtualSessionStatusButton
  },
  props: {
    virtualSessionUuid: {
      type: String
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      oseusEvents: [],
      loading: {
        save: false,
        status: false
      },
      form: {
        oseusEventId: null,
        oseusMeetingId: null,
        name: null,
        description: null,
        beginDate: null,
        endDate: null,
        startHour: null,
        endHour: null,
        businessUnitUuid: null,
        rocUuid: null,
        cpUuid: null,
        type: this.type
      }
    }
  },
  computed: {
    ...mapGetters(['businessUnits']),
    oseusEvent: {
      get() {
        return {
          oseusEventId: this.form.oseusEventId,
          oseusMeetingId: this.form.oseusMeetingId
        }
      },
      set(value) {
        if (!value) {
          this.form.oseusEventId = null
          this.form.oseusMeetingId = null
        } else {
          const { oseusEventId, oseusMeetingId } = value

          this.form.oseusEventId = oseusEventId
          this.form.oseusMeetingId = oseusMeetingId

          this.hydrateFormFromOseus()
        }
      }
    },
    dates: {
      get() {
        if (!this.form.beginDate || !this.form.endDate) return []

        return [this.form.beginDate, this.form.endDate]
      },
      set(value) {
        if (!Array.isArray(value)) return
        if (value.length !== 2) return

        const [beginDate, endDate] = value
        this.form.beginDate = beginDate
        this.form.endDate = endDate
      }
    },
    businessUnitItems() {
      return (this.businessUnits || [])
        .map(bu => ({ ...bu, color: MYBB.bu[bu.label].color }))
        .sort((bu1, bu2) => MYBB.bu[bu1.label].order - MYBB.bu[bu2.label].order)
    },
    virtualSessionType() {
      return this.form.type || this.type
    },
    isOseus() {
      return this.virtualSessionType === this.$const.virtualSession.type.oseus
    },
    rocItems() {
      if (!this.users) return []

      const rocs = this.users.filter(user => user.userTypes.includes(this.$const.userType.ROC))

      return this.mapToItems(rocs)
    },
    cpItems() {
      if (!this.users) return []

      const cps = this.users.filter(user => user.userTypes.includes(this.$const.userType.CP))

      return this.mapToItems(cps)
    },
    virtualSession() {
      return {
        oseusEventId: this.form.oseusEventId,
        oseusMeetingId: this.form.oseusMeetingId,
        name: this.form.name,
        description: this.form.description,
        beginDate: format(new Date(this.form.beginDate), 'yyyy-MM-dd'),
        endDate: format(new Date(this.form.endDate), 'yyyy-MM-dd'),
        startHour: this.form.startHour,
        endHour: this.form.endHour,
        businessUnitUuid: this.form.businessUnitUuid,
        rocUuid: this.form.rocUuid,
        cpUuid: this.form.cpUuid,
        type: this.form.type
      }
    },
    canSave() {
      if (this.isOseusMeetingAvailable === false || this.$apollo.queries.isOseusMeetingAvailable.loading) return false

      try {
        schema.validateSync(this.form)
        return true
      } catch (error) {
        return false
      }
    }
  },
  apollo: {
    users: {
      loadingKey: 'loadingCount',
      query: SEARCH_ALL_USERS,
      variables() {
        return {
          query: {
            limit: 1000
          }
        }
      },
      update({ searchUsers }) {
        return searchUsers.rows.sort((u1, u2) => u1.lastName.localeCompare(u2.lastName))
      }
    },
    isOseusMeetingAvailable: {
      query: IS_OSEUS_MEETING_AVAILABLE,
      variables() {
        return { oseusMeetingId: this.form.oseusMeetingId, virtualSessionUuid: this.virtualSessionUuid }
      },
      skip() {
        return !this.isOseus || !this.form.oseusMeetingId
      }
    },
    source: {
      query: GET_FULL_VIRTUAL_SESSION,
      variables() {
        return { virtualSessionUuid: this.virtualSessionUuid }
      },
      skip() {
        return !this.virtualSessionUuid
      },
      result({ data: { virtualSession } }) {
        this.form = {
          oseusEventId: virtualSession.oseusEventId,
          oseusMeetingId: virtualSession.oseusMeetingId,
          name: virtualSession.name,
          description: virtualSession.description,
          beginDate: virtualSession.beginDate,
          endDate: virtualSession.endDate,
          startHour: virtualSession.startHour,
          endHour: virtualSession.endHour,
          businessUnitUuid: virtualSession.businessUnitUuid,
          rocUuid: virtualSession.rocUuid,
          cpUuid: virtualSession.cpUuid,
          type: virtualSession.type
        }

        this.$nextTick(() => this.$emit('initial', this.form))
      },
      update({ virtualSession }) {
        return virtualSession
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`virtualSession.form.${key}`, params)
    },
    onEventLoaded(events) {
      this.oseusEvents = events
    },
    hydrateFormFromOseus() {
      const oseusEvent = (this.oseusEvents || []).find(event => event.oseusEventId === this.form.oseusEventId)
      const meeting = this.$get(oseusEvent, 'meetings', []).find(
        meeting => meeting.oseusMeetingId === this.form.oseusMeetingId
      )

      if (!meeting) return

      this.form.name = this.$refs.oseusPicker.formatMeetingToItemText(meeting)

      const date = new Date(meeting.startDate)
      this.form.beginDate = this.form.endDate = date
      this.form.startHour = format(date, 'HH:mm')
    },
    mapToItems(users) {
      return users.map(user => ({
        value: user.userUuid,
        text: `${user.lastName} ${user.firstName}`
      }))
    },
    onSave() {
      this.$emit('save', this.virtualSession)
    },
    onStatusChange(status) {
      const { DRAFT, PUBLISHED, CLOSED } = this.$const.virtualSession.status

      switch (status) {
        case DRAFT:
          return this.$emit('publish', this.virtualSession)

        case PUBLISHED:
          return this.$emit('close', this.virtualSession)

        case CLOSED:
          return this.$emit('wind-up', this.virtualSession)
      }
    },
    // Methods exposed for parent
    setSaving(saving) {
      this.loading.save = saving
    },
    setStatusChanging(statusChanging) {
      this.loading.status = statusChanging
    }
  },
  mounted() {
    if (!this.virtualSessionUuid) {
      this.$emit('initial', JSON.parse(JSON.stringify(this.form)))
    }
  }
}
</script>
