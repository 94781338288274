import { string, object, date } from 'yup'

import { virtualSession } from '@/const/shared'

export const schema = object().shape({
  type: string()
    .oneOf(Object.values(virtualSession.type))
    .required(),
  oseusEventId: string().when('type', {
    is: virtualSession.type.oseus,
    then: schema => schema.required(),
    otherwise: schema => schema.nullable()
  }),
  oseusMeetingId: string().when('type', {
    is: virtualSession.type.oseus,
    then: schema => schema.required(),
    otherwise: schema => schema.nullable()
  }),
  name: string().required(),
  description: string().nullable(),
  beginDate: date().required(),
  endDate: date().required(),
  startHour: string()
    .matches(/\d{2}:\d{2}/)
    .nullable(),
  endHour: string()
    .matches(/\d{2}:\d{2}/)
    .nullable(),
  businessUnitUuid: string().required(),
  rocUuid: string().required(),
  cpUuid: string().required()
})
