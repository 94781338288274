<template>
  <div>
    <v-card height="140" width="140" class="TransportButton" :ripple="canEdit" @click="openModal">
      <v-card-text>
        <v-row justify="center" class="py-4">
          <img alt="travel-progress" height="50" src="@/assets/travel-progress.svg" />
          <mybb-text weight="bold" class="pt-3">
            {{ $t('mybb.transportNotePage.travelGroup.addTravel') }}
          </mybb-text>
        </v-row>
      </v-card-text>
    </v-card>

    <transport-travel-modal
      v-model="transportNote"
      :modal="showModal"
      @modal="val => (showModal = val)"
      :type="type"
      @saved="$emit('saved')"
      :travels-through-model="travelsThroughModel"
    />
  </div>
</template>

<script>
import TransportTravelModal from './TransportTravelModal.vue'

export default {
  name: 'AddTransportButton',
  components: { TransportTravelModal },
  model: {
    prop: 'note',
    event: 'change'
  },
  props: {
    type: {
      type: String,
      required: true
    },
    note: {
      type: Object,
      required: false
    },
    travelsThroughModel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    transportNote: {
      get() {
        return this.note
      },
      set(value) {
        this.$emit('change', value)
      }
    },
    canEdit() {
      const { EX_NIHILO, PENDING, ACCEPTED } = this.$const.transport.noteStatus
      const noteStatus = this.$get(this.transportNote, 'status', null)
      return [null, EX_NIHILO, PENDING, ACCEPTED].includes(noteStatus)
    }
  },
  methods: {
    openModal() {
      if (this.canEdit) {
        this.showModal = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.TransportButton {
  cursor: pointer;
}
</style>
