var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"HcpList",staticClass:"HcpList mt-5 pb-8"},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('mybb-text-field',{attrs:{"background-color":"white","placeholder":_vm.$t('mybb.hcpList.searchPlaceholder'),"icon":"mdi-magnify","value":_vm.searchText},on:{"input":_vm.onSearch}})],1)],1),(!_vm.items)?_c('Loader',{attrs:{"color":"white"}}):_c('v-data-table',{staticClass:"HcpList-table px-4 pt-3",attrs:{"headers":_vm.headers,"item-key":"healthCareProfessionalUuid","items":_vm.items,"loading":_vm.loading,"server-items-length":_vm.serverItemsLength,"options":_vm.options,"items-per-page":_vm.tableSettings.itemsPerPage,"footer-props":_vm.tableSettings.footerProps},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.$vuetify.goTo(_vm.$refs.HcpList)}},scopedSlots:_vm._u([{key:"item.alert",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"mybb-warning","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.hasWarning(item.healthCareProfessionalUuid))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"mybb-warning","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.missedGatheringForHcps[item.healthCareProfessionalUuid]))])])]}},{key:"item.lastName",fn:function(ref){
var lastName = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(lastName))])]}},{key:"item.firstName",fn:function(ref){
var firstName = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(firstName))])]}},{key:"item.mslResponsible",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":"mybb-blue"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.target",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.healthCareProfessionalUuid",fn:function(ref){
var healthCareProfessionalUuid = ref.value;
return [_c('router-link',{staticClass:"HcpList-tableLink",attrs:{"to":{
          name: 'HCPPage',
          params: { hcpUuid: healthCareProfessionalUuid }
        }}},[_c('v-btn',{staticClass:"table-icon",attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"mybb-grey-lighten1"}},[_vm._v("mdi-eye")])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }