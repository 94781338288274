<template>
  <side-panel :title="t('title', { name })">
    <v-row>
      <!-- Name -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('name') }}
        </mybb-text>
        <mybb-text class="d-block">
          {{ name }}
        </mybb-text>
      </v-col>

      <!-- Type -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('type') }}
        </mybb-text>
        <mybb-text class="d-block">
          {{ type }}
        </mybb-text>
      </v-col>

      <!-- Nature -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('nature') }}
        </mybb-text>
        <mybb-text class="d-block">
          {{ nature }}
        </mybb-text>
      </v-col>
    </v-row>

    <v-row>
      <!-- Beginning -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('beginning') }}
        </mybb-text>
        <mybb-text class="d-block">
          {{ beginning }}
        </mybb-text>
      </v-col>

      <!-- Ending -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('ending') }}
        </mybb-text>
        <mybb-text class="d-block">
          {{ ending }}
        </mybb-text>
      </v-col>

      <!-- Timezone -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('timezone') }}
        </mybb-text>
        <mybb-text class="d-block">
          {{ $get(gathering, 'timezone', '- -') }}
        </mybb-text>
      </v-col>
    </v-row>

    <v-row>
      <!-- Place -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('place') }}
        </mybb-text>
        <mybb-text class="d-block">
          {{ place }}
        </mybb-text>
      </v-col>

      <!-- ROC responsible -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('roc') }}
        </mybb-text>
        <mybb-text class="d-block">
          {{ roc }}
        </mybb-text>
      </v-col>

      <!-- MCI responsible -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('mci') }}
        </mybb-text>
        <mybb-text class="d-block">
          {{ mci }}
        </mybb-text>
      </v-col>
    </v-row>

    <v-row>
      <!-- Business units -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('businessUnits') }}
        </mybb-text>
        <span v-if="businessUnits">
          <v-chip
            v-for="businessUnit of businessUnits || []"
            :key="businessUnit.label"
            :color="businessUnit.color"
            class="mr-2"
            dark
          >
            {{ businessUnit.label }}
          </v-chip>
        </span>
        <mybb-text v-else>- -</mybb-text>
      </v-col>

      <!-- Category -->
      <v-col cols="4">
        <mybb-text class="d-block mybb-grey-lighten1--text mb-1" size="12">
          {{ t('category') }}
        </mybb-text>
        <v-chip v-if="category" :color="category.color" dark>
          {{ category.label }}
        </v-chip>
        <mybb-text v-else>- -</mybb-text>
      </v-col>
    </v-row>
  </side-panel>
</template>

<script>
import dateFormat from 'date-fns/format'
import Categories from 'mybb-categories'

import MYBB from '@/const/my-bb'

import SidePanel from '../ui/SidePanel'

export default {
  name: 'GatheringPanel',
  components: { SidePanel },
  props: {
    participant: {
      type: Object
    },
    gathering: {
      type: Object
    }
  },
  computed: {
    name() {
      return this.$get(this.gathering, 'name', '')
    },
    type() {
      if (!this.gathering) return '- -'

      return this.gathering.congress ? this.t('congress') : this.t('biogenEvent')
    },
    nature() {
      if (!this.gathering) return '- -'

      const { isPhysical, isVirtual } = this.gathering

      return isPhysical && !isVirtual
        ? this.t('physical')
        : !isPhysical && isVirtual
        ? this.t('virtual')
        : this.t('hybrid')
    },
    beginning() {
      if (!this.gathering) return '- -'

      const { beginDate, eventSchedule } = this.gathering

      const date = new Date(beginDate)

      if (Array.isArray(eventSchedule)) {
        const [hour, minute] = eventSchedule[0].split(':')
        date.setHours(hour, minute, 0, 0)

        return dateFormat(date, 'dd/MM/yy - HH:mm')
      } else {
        return dateFormat(date, 'dd/MM/yy')
      }
    },
    ending() {
      if (!this.gathering) return '- -'

      const { endDate, eventSchedule } = this.gathering

      const date = new Date(endDate)

      if (Array.isArray(eventSchedule)) {
        const [hour, minute] = eventSchedule[1].split(':')
        date.setHours(hour, minute, 0, 0)

        return dateFormat(date, 'dd/MM/yy - HH:mm')
      } else {
        return dateFormat(date, 'dd/MM/yy')
      }
    },
    place() {
      if (!this.gathering) return '- -'

      const { city, country } = this.gathering

      return `${city} - ${country}`
    },
    roc() {
      if (!this.gathering) return '- -'

      const roc = this.gathering.users.find(
        user => user.gatheringUserType === this.$const.gatheringUserType.ROC && !user.isBackup
      )

      if (!roc) return '- -'

      return `${roc.user.firstName} ${roc.user.lastName}`
    },
    mci() {
      if (!this.gathering) return '- -'

      const mci = this.gathering.users.find(
        user => user.gatheringUserType === this.$const.gatheringUserType.MCI && !user.isBackup
      )

      if (!mci) return '- -'

      return `${mci.user.firstName} ${mci.user.lastName}`
    },
    businessUnits() {
      const gatheringBusinessUnits = this.$get(this.gathering, 'gatheringBusinessUnits')

      if (!gatheringBusinessUnits) return null

      return gatheringBusinessUnits.map(gbu => MYBB.bu[gbu.businessUnit.label])
    },
    category() {
      if (!this.gathering || (this.gathering && !this.gathering.categoryVersion)) return null

      const categories = Categories.forVersion(this.gathering.categoryVersion)

      return categories.find(category => this.$get(this.participant, 'category') === category.id)
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.gatheringPanel.${key}`, params)
    }
  }
}
</script>
