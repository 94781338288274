<template>
  <modal :title="title" v-model="modalModel" width="912">
    <template v-slot:text>
      <div v-for="(travel, index) in travels" :key="`travel-${index}`">
        <travel-form
          v-model="travels[index]"
          :index="index"
          :type="type"
          :canEdit="canEdit"
          @add-step="insertStep"
          @remove-step="removeStep"
        />
      </div>
    </template>

    <template v-slot:actions>
      <v-row justify="center">
        <mybb-btn class="mr-10" color="mybb-grey" @click="modalModel = false">
          {{ t('cancel') }}
        </mybb-btn>

        <mybb-btn inner-icon="mdi-plus" :disabled="!canSave" @click="save">
          {{ t('confirm') }}
        </mybb-btn>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/mybb/Modal'
import { GET_TRAVELS_TYPES, SET_TRAVELS } from '@/graphql/Transport'

import TravelForm from './TravelForm'

export default {
  name: 'TransportTravelModal',
  components: { Modal, TravelForm },
  model: {
    prop: 'note',
    event: 'change'
  },
  props: {
    modal: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    note: {
      type: Object,
      required: true
    },
    travelsThroughModel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      travels:
        this.travelsThroughModel &&
        Array.isArray(this.note.travels) &&
        this.note.travels.filter(travel => travel.type === this.type).length
          ? this.note.travels.filter(travel => travel.type === this.type)
          : [{ transportNoteUuid: this.note.transportNoteUuid, type: this.type }]
    }
  },
  computed: {
    title() {
      return this.t('title', { travelType: this.t(`travelType.${this.type}`) })
    },
    modalModel: {
      get() {
        return this.modal
      },
      set(value) {
        this.$emit('modal', value)
      }
    },
    canSave() {
      const { PLANE, TRAIN } = this.$const.transport.modes
      const { BACK, GO } = this.$const.transport.travelType
      const { REFUSED } = this.$const.transport.noteStatus
      const noteStatus = this.$get(this.note, 'status', null)

      // Every travels must have its mandatory filled to save them
      return this.travels.every(travel => {
        if (!travel) return false

        return (
          noteStatus !== REFUSED &&
          (this.travelsThroughModel ? true : travel.transportNoteUuid) &&
          [BACK, GO].includes(travel.type) &&
          [PLANE, TRAIN].includes(travel.mode) &&
          travel.ticketNumber &&
          travel.departurePlace &&
          travel.arrivalPlace &&
          travel.departureDate &&
          travel.departureTime &&
          travel.arrivalDate &&
          travel.arrivalTime
        )
      })
    },
    canEdit() {
      const { REFUSED } = this.$const.transport.noteStatus
      const noteStatus = this.$get(this.note, 'status', null)
      const noteOrigin = this.$get(this.note, 'origin', null)

      return noteStatus !== REFUSED || (noteOrigin === null && noteStatus === null)
    }
  },
  apollo: {
    travels: {
      query: GET_TRAVELS_TYPES,
      variables() {
        return {
          transportNoteUuid: this.note.transportNoteUuid,
          type: this.type
        }
      },
      update({ getTravelByType }) {
        const travels =
          Array.isArray(getTravelByType) && getTravelByType.length > 0
            ? getTravelByType
            : [{ transportNoteUuid: this.note.transportNoteUuid, type: this.type }]

        for (const travel of travels) {
          delete travel.__typename
        }

        return travels
      },
      skip() {
        return this.travelsThroughModel
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transportTravelModal.${key}`, params)
    },
    insertStep(currentIndex) {
      // Offset of 1 to start adding after the current index
      this.travels.splice(currentIndex + 1, 0, { transportNoteUuid: this.note.transportNoteUuid, type: this.type })
    },
    removeStep(currentIndex) {
      this.travels.splice(currentIndex, 1)
    },
    async save() {
      if (this.travelsThroughModel) {
        const travels = (this.note.travels || []).filter(travel => travel.type !== this.type).concat(this.travels)
        this.$emit('change', { ...this.note, travels })
      } else {
        await this.$apollo.mutate({
          mutation: SET_TRAVELS,
          variables: {
            transportNoteUuid: this.note.transportNoteUuid,
            travels: this.travels
          }
        })

        this.$emit('saved')
      }

      this.modalModel = false
    }
  }
}
</script>
