<template>
  <div>
    <Loader v-if="!hostings" color="mybb-blue" />
    <v-data-table v-else class="Table" :headers="headers" :items="items" :custom-sort="$dataTableSort()">
      <template v-slot:[`item.name`]="{ value }">
        <mybb-text weight="bold">{{ value }}</mybb-text>
      </template>

      <template v-for="night of nights" v-slot:[`item.${dateAsKey(night)}`]="{ value }">
        <mybb-text v-if="value" :key="dateAsKey(night)">
          <span
            :class="{
              'font-weight-bold': value.registered >= value.quota,
              'mybb-blue--text': value.registered === value.quota,
              'mybb-warning--text': value.registered > value.quota
            }"
          >
            {{ value.registered }} </span
          >/{{ value.quota }}
        </mybb-text>
      </template>

      <template v-slot:[`item.navigation`]="{ value }">
        <v-icon
          size="20"
          color="mybb-grey-lighten1"
          @click="$router.push({ name: 'HostingDetailsPage', params: { hostingUuid: value } })"
        >
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dateDiff from 'date-fns/differenceInCalendarDays'
import dateFormat from 'date-fns/format'

import Loader from '@/components/congrex/tabs/LoaderTab'

export default {
  name: 'HostingListTable',
  components: { Loader },
  props: {
    configuration: {
      type: Object,
      validator(prop) {
        return !prop || prop.hasOwnProperty('eveArrival')
      }
    },
    gathering: {
      type: Object,
      validator(prop) {
        return !prop || (prop.hasOwnProperty('endDate') && prop.hasOwnProperty('beginDate'))
      }
    },
    hostings: {
      type: Array
    }
  },
  computed: {
    nights() {
      const begin = this.$get(this.gathering, 'beginDate')
      const end = this.$get(this.gathering, 'endDate')
      const eveArrival = this.$get(this.configuration, 'eveArrival')
      const postDeparture = this.$get(this.configuration, 'postDeparture')

      if (!begin || !end) return []

      const beginDate = new Date(begin)
      const endDate = new Date(end)

      if (eveArrival) {
        beginDate.setDate(beginDate.getDate() - 1)
      }

      const nights = []
      const diff = Math.abs(dateDiff(beginDate, endDate))

      for (let i = 0; i < diff; i++) {
        nights.push(new Date(beginDate))
        beginDate.setDate(beginDate.getDate() + 1)
      }

      if (postDeparture) {
        nights.push(new Date(endDate))
      }

      return nights
    },
    headers() {
      const headers = [
        { sortable: true, text: this.t('header.name'), value: 'name' },
        { sortable: false, text: this.t('header.roomType'), value: 'roomType' },
        { sortable: false, text: this.t('header.tourLeader'), value: 'tourLeader' }
      ]

      for (const night of this.nights) {
        headers.push({
          sortable: false,
          text: dateFormat(night, 'dd/MM'),
          value: this.dateAsKey(night),
          width: 80
        })
      }

      headers.push({ sortable: false, text: '', value: 'navigation', width: 40 })

      return headers
    },
    items() {
      if (!Array.isArray(this.hostings)) return []

      const items = []

      for (const hosting of this.hostings) {
        const tourLeader = hosting.tourLeader
          ? `${this.$get(hosting.tourLeader, 'firstName') || ''} ${(
              this.$get(hosting.tourLeader, 'lastName') || ''
            ).toUpperCase()}`
          : ''

        const item = {
          name: hosting.name,
          roomType: hosting.roomType,
          tourLeader,
          navigation: hosting.hostingUuid,
          ...this.computeHostingNightQuota(hosting)
        }

        items.push(item)
      }

      return items
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hostingList.hostingTable.${key}`, params)
    },
    dateAsKey(date) {
      if (!date) return null

      return dateFormat(new Date(date), 'yyyy-MM-dd')
    },
    computeHostingNightQuota(hosting) {
      const quota = {}

      for (const night of hosting.hostingNights || []) {
        quota[this.dateAsKey(night.date)] = {
          quota: night.quota,
          registered: night.overnightStays.filter(
            stay =>
              ![this.$const.presence.absent, this.$const.presence.noShow, this.$const.presence.refuse].includes(
                stay.participant.presence
              ) && ![this.$const.hosting.overnightStayStatus.REFUSED].includes(stay.status)
          ).length
        }
      }

      return quota
    }
  }
}
</script>

<style lang="scss">
.Table {
  border-radius: 8px;

  thead th {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--v-mybb-primary-base) !important;
  }

  tbody td {
    color: var(--v-mybb-primary-base);
  }
}
</style>
