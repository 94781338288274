<template>
  <side-panel :cypress="$cy.participant.gathering.advantages.container" :title="t('title')">
    <div class="Container mt-5 mb-3">
      <div v-for="priceItem in priceItems" :key="priceItem.field" class="Item">
        <v-icon class="Item-icon" color="my-bb-grey-lighten1" size="100">{{ priceItem.icon }}</v-icon>
        <div class="Item-text">
          <mybb-text weight="bold">{{ t(priceItem.field) }}</mybb-text>
          <mybb-text
            v-if="priceItem.customValue"
            v-cy="$cy.participant.gathering.advantages.field(priceItem.field)"
            weight="bold"
            size="20"
          >
            {{ priceItem.customValue | price }}
          </mybb-text>
          <mybb-text
            v-else
            v-cy="$cy.participant.gathering.advantages.field(priceItem.field)"
            weight="bold"
            size="20"
            >{{ participant.fees[priceItem.field] | price }}</mybb-text
          >
        </div>
      </div>
      <div class="Item-total">
        <v-icon class="Item-icon" color="my-bb-grey-lighten1" size="100">mdi-check-bold</v-icon>
        <div class="Item-text">
          <mybb-text weight="bold" size="16">{{ t('total') }}</mybb-text>
          <mybb-text weight="bold" size="24" class="mybb-primary-lighten1--text">{{ total | price }}</mybb-text>
        </div>
      </div>
    </div>
  </side-panel>
</template>

<script>
import Categories from 'mybb-categories'

import SidePanel from '../ui/SidePanel'

export default {
  name: 'AdvantagesPanel',
  components: { SidePanel },
  props: {
    participant: {
      type: Object
    },
    gathering: {
      type: Object
    }
  },
  computed: {
    isCongress() {
      const gatheringType = this.$get(this.gathering, 'gatheringType', null)

      return gatheringType === this.$const.gatheringType.CONGRESS
    },
    priceItems() {
      if (!this.participant) return []

      const gatheringCategoryVersion = this.$get(this.gathering, 'categoryVersion', null)
      const participantCategory = Categories.forVersionAndCategoryId(
        gatheringCategoryVersion,
        this.participant.category
      )
      const finalFields = []

      if (this.isCongress) {
        finalFields.push({ icon: 'mdi-calendar-check ', field: 'subscriptionPrice' })
      }
      if (participantCategory.modules.includes(Categories.constants.Module.Transport)) {
        finalFields.push({ icon: 'mdi-airplane  ', field: 'transportsPrice' })
      }

      const transfer = this.$get(this.participant, 'transfer')
      if (transfer) {
        finalFields.push({ icon: 'mdi-bus-marker', field: 'transfersPrice', customValue: transfer.totalPrice })
      }

      if (participantCategory.modules.includes(Categories.constants.Module.Hosting)) {
        finalFields.push({ icon: 'mdi-bed', field: 'hostingsPrice' })
      }

      if (this.$get(this.participant, 'fees.activitiesPrice')) {
        finalFields.push({ icon: 'mdi-presentation', field: 'activitiesPrice' })
      }
      if (this.$get(this.participant, 'fees.restaurantsPrice')) {
        finalFields.push({ icon: 'mdi-silverware-fork-knife', field: 'restaurantsPrice' })
      }

      if (participantCategory.modules.includes(Categories.constants.Module.ExpenseNote)) {
        finalFields.push({ icon: 'mdi-account-cash', field: 'expenseNotesPrice' })
      }

      return finalFields
    },
    total() {
      if (!this.participant) return 0

      return Object.values(this.participant.fees).reduce((acc, price) => {
        if (Number.isInteger(price)) {
          return acc + price
        }
        return acc
      }, 0)
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.advantagesPanel.${key}`, params)
    }
  },
  filters: {
    price(val) {
      const value = val / 100
      return isNaN(value) || val === 0 ? '- -' : `${(+value).toFixed(2).replace('.', ',')}€`
    }
  }
}
</script>

<style lang="scss" scoped>
.Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Item {
  margin-right: 100px;
  position: relative;
  width: 100px;
  height: 100px;
}
.Item-total {
  margin-right: 50px;
  position: relative;
  width: 100px;
  height: 100px;
}

.Item-icon {
  position: absolute;
  opacity: 0.1;
}

.Item-text {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
