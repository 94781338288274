<template>
  <div class="InformationStep">
    <v-row>
      <v-col cols="6">
        <mybb-text weight="bold">
          {{ t('eventNatureLabel') }}
          <mybb-text>{{ nature }}</mybb-text>
        </mybb-text>
      </v-col>

      <v-col cols="6">
        <input-file v-model="logo" :title="t('logoFileLabel')" accept=".jpg,.jpeg,.png" :delete-confirmation="false" />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="isCongress ? 6 : 12">
        <mybb-text-field
          v-model="name"
          :label="t('eventNameLabel')"
          :error-messages="collect('name')"
          :disabled="disableTouchyFields || disableAll"
          icon="mdi-calendar-text"
          v-cy="$cy.gathering.form.name"
        >
          <template v-if="$apollo.queries.isGatheringNameAvailable.loading" v-slot:append>
            <v-progress-circular size="20" color="blue" width="2" indeterminate />
          </template>
        </mybb-text-field>
      </v-col>

      <v-col v-if="isCongress" cols="6">
        <mybb-text-field
          v-model="learnedSociety"
          :label="t('learnedSocietyLabel')"
          :error-messages="collect('congress.learnedSociety')"
          :disabled="disableAll"
          icon="mdi-office-building"
          v-cy="$cy.gathering.form.learnedSociety"
        />
      </v-col>
    </v-row>

    <mybb-text-field
      v-model="description"
      :label="t('eventDescriptionLabel')"
      :disabled="disableAll"
      icon="mdi-calendar-text"
      class="my-4"
      v-cy="$cy.gathering.form.description"
    />

    <v-row>
      <v-col cols="3">
        <date-picker
          v-model="dates"
          :label="t('eventDateLabel')"
          :error-messages="collect('beginDate') || collect('endDate')"
          :min="new Date().toISOString()"
          :disabled="disableTouchyFields || disableAll"
          :cypress="$cy.gathering.form.eventDates"
          icon="mdi-calendar"
          multiple
        />
      </v-col>

      <v-col cols="3">
        <time-picker v-model="beginHour" :label="t('eventBeginHourLabel')" :disabled="disableAll" clearable />
      </v-col>

      <v-col cols="3">
        <time-picker
          v-model="endHour"
          @input="clear('endHour')"
          :label="t('eventEndHourLabel')"
          :error-messages="
            collect(
              'endHour',
              this.$t('customError.afterAlphabetical', {
                value: this.t('eventEndHourLabel'),
                ref: beginHour
              })
            )
          "
          :disabled="disableAll"
          clearable
        />
      </v-col>

      <v-col cols="3">
        <mybb-select v-model="timezone" :items="timezones" :label="t('timezoneLabel')" :disabled="disableAll" />
      </v-col>
    </v-row>
    <v-row v-if="!this.isCongress">
      <v-col cols="3">
        <date-picker v-model="limitRegisterDate" :label="t('eventLimitRegisterDate')" icon="mdi-calendar" />
      </v-col>
    </v-row>

    <v-row>
      <!-- Congress -->
      <v-col v-if="isCongress" cols="6">
        <mybb-radios
          v-if="event.isPhysical"
          v-model="nationalityType"
          :items="nationalityTypes"
          :label="t('nationalityTypeLabel')"
          :error-messages="collect('congress.nationalityType')"
          :disabled="disableAll"
          :cypress="$cy.gathering.form.nationalityType"
          text-color="mybb-grey-lighten1"
          two-rows
          @blur="revalidate('congress.nationalityType', nationalityType)"
        />
      </v-col>
      <!-- Standalone -->
      <v-col v-else>
        <mybb-radios
          v-model="eventType"
          :items="eventTypes"
          :label="t('biogenEventTypeLabel')"
          :error-messages="collect('biogenEvent.eventType')"
          :disabled="disableAll"
          :cypress="$cy.gathering.form.eventType"
          text-color="mybb-grey-lighten1"
          two-rows
          @blur="revalidate('biogenEvent.eventType', eventType)"
        />
      </v-col>

      <v-col cols="6">
        <mybb-text-field v-model="location" :label="t('location')" :disabled="disableAll" icon="mdi-map-marker" />
      </v-col>
    </v-row>

    <v-row v-if="event.isVirtual">
      <v-col cols="6" offset="6">
        <mybb-text-field
          v-model="visioUrl"
          :label="t('visioLabel')"
          :disabled="disableAll"
          icon="mdi-television-play"
        />
      </v-col>
    </v-row>

    <v-row v-if="isCongress">
      <v-col cols="6">
        <mybb-text-field v-model="websiteUrl" :label="t('websiteLabel')" :disabled="disableAll" icon="mdi-earth" />
      </v-col>

      <v-col v-for="(link, index) of additionalLinks" :key="`additional-link-${index}`" cols="6">
        <mybb-text-field
          :value="link"
          @input="value => setCongressArrayProperty('additionalLinks', value, additionalLinks, index)"
          :label="t('addedLink')"
          :disabled="disableAll"
          icon="mdi-earth"
        />
      </v-col>

      <v-col cols="6">
        <mybb-btn
          :disabled="disableAll"
          color="white"
          class="mybb-primary-lighten1--text"
          inner-icon="mdi-plus"
          @click="addLink"
        >
          {{ t('addLinkButton') }}
        </mybb-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { listTimeZones, findTimeZone, convertDateToTime, setTimeZone } from 'timezone-support'

import { yup } from '@/mixins'
import InputFile from '@/components/mybb/InputFile'
import DatePicker from '@/components/mybb/ui/DatePicker'
import TimePicker from '@/components/mybb/ui/TimePicker'

import { congressSchema, biogenEventSchema } from '@/validations/gathering/informations'
import { IS_GATHERING_NAME_AVAILABLE } from '@/graphql/Gatherings'

export default {
  name: 'InformationStep',
  components: { InputFile, DatePicker, TimePicker },
  mixins: [yup],
  model: {
    prop: 'event'
  },
  props: {
    event: {
      type: Object
    },
    isCongress: {
      type: Boolean
    },
    disableTouchyFields: {
      type: Boolean
    },
    disableAll: {
      type: Boolean
    }
  },
  computed: {
    schema() {
      return this.isCongress ? congressSchema : biogenEventSchema
    },
    nature() {
      if (typeof this.event.isPhysical !== 'boolean' && typeof this.event.isVirtual !== 'boolean') return '- -'

      if (this.event.isPhysical && this.event.isVirtual) return this.$t('gatheringFormat.HYBRID')
      if (this.event.isPhysical) return this.$t('gatheringFormat.PHYSICAL')
      if (this.event.isVirtual) return this.$t('gatheringFormat.VIRTUAL')

      return '- -'
    },
    timezones() {
      const offsets = new Set()
      const zones = listTimeZones()

      for (const zone of zones) {
        offsets.add(this.GMTLabelFromTimezone(zone))
      }

      return Array.from(offsets).sort((offset1, offset2) => {
        // Transform "GMT +|- XX" to "-|+XXX"
        let off1 = Number(offset1.replace(/^GMT\s/, '').replace(/\s/, ''))
        let off2 = Number(offset2.replace(/^GMT\s/, '').replace(/\s/, ''))

        if (Number.isNaN(off1)) off1 = 0
        if (Number.isNaN(off2)) off2 = 0

        return off1 < off2 ? -1 : 1
      })
    },
    nationalityTypes() {
      const { INTERNATIONAL, EUROPEAN, FRENCH } = this.$const.nationalityType
      return [
        { label: this.$t(`mybb.global.nationalityType.${INTERNATIONAL}`), value: INTERNATIONAL },
        { label: this.$t(`mybb.global.nationalityType.${EUROPEAN}`), value: EUROPEAN },
        { label: this.$t(`mybb.global.nationalityType.${FRENCH}`), value: FRENCH }
      ]
    },
    eventTypes() {
      const { MEDICAL, COMMERCIAL } = this.$const.biogenEventType
      return [
        { label: this.$t(`mybb.global.biogenEventType.${MEDICAL}`), value: MEDICAL },
        { label: this.$t(`mybb.global.biogenEventType.${COMMERCIAL}`), value: COMMERCIAL }
      ]
    },
    logo: {
      get() {
        const logo = this.$get(this.event, 'gatheringFiles', []).find(
          gf => gf.type === this.$const.gatheringFileType.LOGO
        )

        if (!logo) return null

        return logo.file
      },
      set(value) {
        const { LOGO } = this.$const.gatheringFileType
        const gatheringFiles = this.$get(this.event, 'gatheringFiles', [])
        const files = Array.from(gatheringFiles)

        const index = files.findIndex(file => file.type === LOGO)

        if (index < 0) {
          files.push({ type: LOGO, file: value, label: 'Logo' })
        } else if (!value) {
          files.splice(index, 1)
        } else {
          files[index].file = value
        }

        this.setProperty('gatheringFiles', files)
      }
    },
    name: {
      get() {
        return this.event.name
      },
      set(value) {
        this.clear('name')
        this.setProperty('name', value)
      }
    },
    learnedSociety: {
      get() {
        return this.event.congress.learnedSociety
      },
      set(value) {
        this.clear('congress.learnedSociety')
        this.setCongressProperty('learnedSociety', value)
      }
    },
    description: {
      get() {
        return this.event.description
      },
      set(value) {
        this.setProperty('description', value)
      }
    },
    dates: {
      get() {
        const dates = []
        if (this.event.beginDate) dates.push(this.event.beginDate)
        if (this.event.endDate && dates.length > 0) dates.push(this.event.endDate)

        return dates
      },
      set(value) {
        if (!Array.isArray(value)) return

        const [beginDate, endDate] = value

        if (beginDate && endDate) {
          this.clear('beginDate')
          this.clear('endDate')
        }

        this.setProperties({ beginDate, endDate })
      }
    },
    limitRegisterDate: {
      get() {
        return this.event.limitDate
      },
      set(value) {
        this.setProperty('limitDate', value)
      }
    },
    beginHour: {
      get() {
        return this.event.beginHour
      },
      set(value) {
        this.setProperty('beginHour', value)
      }
    },
    endHour: {
      get() {
        return this.event.endHour
      },
      set(value) {
        this.setProperty('endHour', value)
      }
    },
    timezone: {
      get() {
        return this.event.timezone
      },
      set(value) {
        this.clear('timezone')
        this.setProperty('timezone', value)
      }
    },
    nationalityType: {
      get() {
        return this.event.congress.nationalityType
      },
      set(value) {
        this.clear('congress.nationalityType')
        this.setCongressProperty('nationalityType', value)
      }
    },
    eventType: {
      get() {
        return this.event.biogenEvent.eventType
      },
      set(value) {
        this.setBiogenEventProperty('eventType', value)
        this.clear('biogenEvent.eventType')
      }
    },
    location: {
      get() {
        return this.event.location
      },
      set(value) {
        this.setProperty('location', value)
      }
    },
    visioUrl: {
      get() {
        return this.event.visioUrl
      },
      set(value) {
        this.setProperty('visioUrl', value)
      }
    },
    websiteUrl: {
      get() {
        return this.event.congress.websiteUrl
      },
      set(value) {
        this.setCongressProperty('websiteUrl', value)
      }
    },
    additionalLinks() {
      return this.event.congress.additionalLinks
    }
  },
  apollo: {
    isGatheringNameAvailable: {
      query: IS_GATHERING_NAME_AVAILABLE,
      skip() {
        return !this.name
      },
      variables() {
        return {
          name: this.name,
          gatheringUuid: this.$get(this.event, 'gatheringUuid', null)
        }
      },
      debounce: 500,
      result(result) {
        const isAvailable = this.$get(result, 'data.isGatheringNameAvailable', false)

        if (!isAvailable) {
          this.setError('name', 'Déjà utilisé')
        } else {
          this.clear('name')
        }
      }
    }
  },
  methods: {
    validate() {
      return this.validateSchema(this.event)
    },
    t(key, params) {
      return this.$t(`mybb.informationStep.${key}`, params)
    },
    setProperties(partial) {
      this.$emit('input', { ...this.event, ...partial })
    },
    setProperty(property, value) {
      this.setProperties({ [property]: value })
    },
    setCongressProperty(property, value) {
      this.setProperties({ ...this.event, congress: { ...this.event.congress, [property]: value } })
    },
    setBiogenEventProperty(property, value) {
      this.setProperties({ ...this.event, biogenEvent: { ...this.event.biogenEvent, [property]: value } })
    },
    setCongressArrayProperty(property, value, collection, index) {
      const _collection = collection.slice()

      _collection[index] = value

      this.setCongressProperty(property, _collection)
    },
    GMTLabelFromTimezone(zone) {
      const timezone = findTimeZone(zone)
      const nowTime = convertDateToTime(new Date())
      const zonedTime = setTimeZone(nowTime, timezone)
      // We must invert those as it is the offset to reach GMT (and not the time added to reach GMT)
      const offset = (zonedTime.zone.offset / 60) * -1

      if (offset < 0) return `GMT - ${-offset}`
      if (offset > 0) return `GMT + ${offset}`
      return 'GMT'
    },
    addLink() {
      const links = this.additionalLinks.slice()
      links.push(null)

      this.setCongressProperty('additionalLinks', links)
    }
  }
}
</script>
