import gql from 'graphql-tag'

export const FULL_OSEUS_MEETING = gql`
  fragment FullOseusMeeting on OseusMeeting {
    availablePlaces
    experts
    oseusMeetingId
    startDate
    status
    theme
  }
`

export const FULL_OSEUS_EVENT = gql`
  fragment FullOseusEvent on OseusEvent {
    maxContactRegistrations
    meetings {
      ...FullOseusMeeting
    }
    oseusEventId
    title
  }
  ${FULL_OSEUS_MEETING}
`

export const LIGHT_OSEUS_EVENT = gql`
  fragment LightOseusEvent on OseusEvent {
    oseusEventId
    title
  }
`
