import gql from 'graphql-tag'

export const REMOVE_HCP_DOCUMENT = gql`
  mutation removeHealthCareProfessionalDocument($documentUuid: GUID!) {
    removeHealthCareProfessionalDocument(documentUuid: $documentUuid)
  }
`

export const SET_HCP_DOCUMENT = gql`
  mutation setHealthCareProfessionalDocument(
    $healthCareProfessionalUuid: GUID!
    $document: InputHealthCareProfessionalFile!
    $gatheringUuid: GUID
    $fileUuid: GUID
  ) {
    setHealthCareProfessionalDocument(
      healthCareProfessionalUuid: $healthCareProfessionalUuid
      document: $document
      gatheringUuid: $gatheringUuid
      fileUuid: $fileUuid
    ) {
      healthCareProfessionalFileUuid
    }
  }
`
