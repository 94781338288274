<template>
  <div v-cy="$cy.activity.list.container" class="ActivityList pt-5 pb-8">
    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field
          v-model="searchText"
          background-color="white"
          :placeholder="$t('mybb.ActivityList.searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>
      <v-spacer />
      <mybb-btn
        inner-icon="mdi-plus"
        @click="$router.push({ name: 'ActivityForm' })"
        :disabled="isWindedUpGathering"
        v-cy="$cy.activity.list.createButton"
      >
        {{ $t('mybb.ActivityList.createActivityButton') }}
      </mybb-btn>
    </v-row>

    <Loader v-if="!items" color="white" />
    <v-data-table
      v-else
      :headers="headers"
      item-key="uuid"
      :items="items"
      :search="searchText"
      :custom-filter="$dataTableFilter(filterFields)"
      class="px-4 py-3 DataTable"
      :custom-sort="$dataTableSort()"
      disable-pagination
      :hide-default-footer="true"
    >
      <template v-slot:[`item.name`]="{ item }">
        <mybb-text weight="bold">{{ item.name }}</mybb-text>
      </template>
      <template v-slot:[`item.category.text`]="{ item }">
        <v-chip :color="item.category.color" class="categoryChip py-2 px-4" dark>
          {{ item.category.text }}
        </v-chip>
      </template>
      <template v-slot:[`item.totalGuest`]="{ item }">
        <div class="text-left">
          <mybb-text class="mr-1" :weight="item.quota === item.participantCounter ? 'bold' : 'regular'">
            {{ item.participantCounter }}
            <template v-if="item.quota !== null">
              /{{ item.quota }}
            </template>
          </mybb-text>
        </div>
      </template>
      <template v-slot:[`item.redirect`]="{ item }">
        <router-link
          class="noStyleLink"
          :to="{ name: 'ActivityDetail', params: { activityUuid: item.uuid } }"
          v-cy="$cy.activity.list.item(item.name)"
        >
          <v-icon color="mybb-grey-lighten1">mdi-eye</v-icon>
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'
import { mapGetters } from 'vuex'

import { GET_GATHERING_INFO } from '@/graphql/Gatherings/GetGathering'
import { GET_ACTIVITIES } from '@/graphql/Activities/Activities'
import shared from '@/const/my-bb'

import Loader from '@/components/congrex/tabs/LoaderTab'

import Categories from 'mybb-categories'

export default {
  name: 'ActivityList',

  components: { Loader },

  data() {
    return {
      activities: [],
      searchText: '',
      filterFields: {
        name: 'name',
        category: 'category.text',
        starting: 'starting',
        schedules: 'schedules',
        location: 'location'
      }
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    tableSettings() {
      return shared.tableSettings
    },
    headers() {
      const headers = [
        {
          sortable: false,
          text: this.$t('mybb.ActivityList.tableHeaders.name'),
          value: 'name',
          class: 'text-uppercase'
        },
        {
          sortable: true,
          text: this.$t('mybb.ActivityList.tableHeaders.category'),
          value: 'category.text',
          class: 'text-uppercase'
        }
      ]

      if (this.gathering && this.gathering.isVirtual && this.gathering.isPhysical) {
        headers.push({
          sortable: true,
          text: this.$t('mybb.ActivityList.tableHeaders.nature'),
          value: 'nature',
          class: 'text-uppercase'
        })
      }

      headers.push(
        {
          sortable: true,
          text: this.$t('mybb.ActivityList.tableHeaders.starting'),
          value: 'starting',
          class: 'text-uppercase'
        },
        {
          sortable: false,
          text: this.$t('mybb.ActivityList.tableHeaders.schedules'),
          value: 'schedules',
          class: 'text-uppercase'
        },
        {
          sortable: true,
          text: this.$t('mybb.ActivityList.tableHeaders.location'),
          value: 'location',
          class: 'text-uppercase'
        },
        {
          sortable: false,
          text: this.$t('mybb.ActivityList.tableHeaders.guest'),
          value: 'totalGuest',
          align: 'left',
          class: 'text-uppercase'
        },
        { text: '', value: 'redirect', align: 'center' }
      )

      return headers
    },
    items() {
      return this.activities.map(activity => {
        return {
          name: activity.name,
          category: this.getCategory(activity.activityCategory),
          nature:
            activity.isVirtual && activity.isPhysical
              ? this.$t('mybb.activity.nature.HYBRID')
              : activity.isPhysical
              ? this.$t('mybb.activity.nature.PHYSICAL')
              : this.$t('mybb.activity.nature.VIRTUAL'),
          starting: dateFormat(new Date(activity.date), 'dd/MM/yy'),
          schedules:
            (activity.startHour || '') +
            (activity.startHour && activity.endHour ? ' - ' : '') +
            (activity.endHour || ''),
          location: (activity.room || '') + (activity.room && activity.place ? ' - ' : '') + (activity.place || ''),
          totalGuest: activity.activityParticipants ? activity.activityParticipants.length : 0,
          participantCounter: this.participantCounter(activity),
          uuid: activity.activityUuid,
          quota: activity.quota
        }
      })
    },
    isWindedUpGathering() {
      const activity = this.$get(this.activities, '[0]', null)
      const gatheringStatus = this.$get(activity, 'gathering.status', null)

      return gatheringStatus === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    activities: {
      query: GET_ACTIVITIES,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update({ activitiesFromGathering }) {
        return Array.from(activitiesFromGathering).sort((a, b) => {
          const date1 = new Date(a.date)
          const hourAndMinute1 = a.startHour ? a.startHour.split(':') : ['00', '00']
          date1.setHours(Number(hourAndMinute1[0]))
          date1.setMinutes(Number(hourAndMinute1[1]))

          const date2 = new Date(b.date)
          const hourAndMinute2 = b.startHour ? b.startHour.split(':') : ['00', '00']
          date2.setHours(Number(hourAndMinute2[0]))
          date2.setMinutes(Number(hourAndMinute2[1]))

          return date1 - date2
        })
      }
    },
    gathering: {
      query: GET_GATHERING_INFO,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    }
  },
  methods: {
    getCategory(category) {
      return {
        text: category.label,
        color: category.color
      }
    },
    participantCounter(activity) {
      if (!activity.activityParticipants) return 0

      const categoryIds = this.availableCategoryIds(activity)

      const activityParticipants = activity.activityParticipants.filter(participant => {
        const category = this.$get(participant, 'participant.category', null)
        return (
          (!participant.presence || participant.presence === this.$const.presence.present) &&
          categoryIds.includes(category)
        )
      })
      return activityParticipants.length
    },
    availableCategoryIds(activity) {
      const categoryVersion = this.$get(activity, 'gathering.categoryVersion')
      if (!categoryVersion) return []

      const categories = Categories.forVersion(categoryVersion)

      return categories
        .filter(category => category.tags.includes(Categories.constants.Tag.CountTowardQuota))
        .map(({ id }) => id)
    }
  }
}
</script>

<style lang="scss">
.ActivityList {
  .DataTable {
    border-radius: 8px;
  }

  .v-data-table {
    thead th {
      font-size: 12px;
      font-weight: 900;
      color: var(--v-mybb-primary-base) !important;
    }

    tbody td {
      color: var(--v-mybb-primary-base);
    }
  }
  .categoryChip {
    font-size: 14px;
    font-weight: 600;
    min-width: 175px;
    justify-content: center;
    white-space: normal;
  }
}
</style>

<style lang="scss">
.ActivityList {
  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }
}
</style>
