<template>
  <div class="HostingList mt-5 pb-8" v-cy="$cy.gathering.hosting.container">
    <v-row class="mb-3" no-gutters align="center" justify="end">
      <mybb-btn
        @click="hostingExtract"
        background="white"
        color="mybb-grey-lighten1"
        icon
        inner-icon="mdi-file-export"
        class="mr-2"
      />

      <mybb-btn
        @click="configurationModal = true"
        :background="hostingConfiguration ? 'white' : 'mybb-warning'"
        :color="hostingConfiguration ? 'mybb-grey-lighten1' : 'white'"
        v-cy="$cy.gathering.hosting.configuration.button"
        icon
        inner-icon="mdi-cogs"
        class="mr-2"
      />

      <mybb-btn
        v-if="canShareHostings"
        @click="shareHostingsModal = true"
        background="mybb-green"
        class="mr-2"
        inner-icon="mdi-send"
      >
        <span class="d-inline-block flex-grow-1">{{ t('publishHostings') }}</span>
      </mybb-btn>

      <mybb-btn @click="goToHostingForm" inner-icon="mdi-plus" :disabled="isWindedUpGathering">
        {{ t('addHosting') }}
      </mybb-btn>
    </v-row>

    <div class="HostingList-table-wrapper px-6 pt-5">
      <mybb-text weight="bold" size="16" class="text-uppercase">{{ t('hostingList') }}</mybb-text>
      <v-chip v-if="$get(gathering, 'areHostingShared')" class="ml-2" color="mybb-success" dark>
        {{ t('publishedHostings') }}
      </v-chip>
      <hosting-list-table :configuration="hostingConfiguration" :hostings="hostings" :gathering="gathering" />
    </div>

    <div class="HostingList-table-wrapper mt-7 px-6 pt-5">
      <hosting-participants-table
        v-model="selectedParticipantUuids"
        :configuration="hostingConfiguration"
        :pending-overnight-stays="pendingOvernightStays"
        :gathering="gathering"
      />
    </div>

    <hosting-configuration
      v-model="configurationModal"
      :gatheringUuid="$route.params.gatheringUuid"
      @refresh="$apollo.queries.hostingConfiguration.refresh()"
    />

    <!-- Fixed footer -->
    <fixed-footer
      :visible="selectedParticipantUuids.length > 0"
      :label="t('footer.label', { selected: selectedParticipantUuids.length })"
    >
      <mybb-btn color="mybb-error" class="mr-4" @click="cancelHostingModal = true">{{ t('footer.cancel') }}</mybb-btn>
      <mybb-btn color="mybb-success" @click="hostingModal = true">{{ t('footer.confirm') }}</mybb-btn>
    </fixed-footer>

    <!-- Modals -->
    <hosting-modal
      v-model="hostingModal"
      :selected="selectedParticipantUuids.length"
      :hostings="hostings || []"
      @confirm="hostingModalConfirm"
    />

    <hosting-cancel-modal v-model="cancelHostingModal" @confirm="cancelHostingModalConfirm" />
    <share-hostings-modal
      v-model="shareHostingsModal"
      :pending-overnight-stays="pendingOvernightStays"
      @confirm="onHostingShared"
    />
  </div>
</template>

<script>
import HostingConfiguration from '@/components/mybb/hosting/HostingConfiguration'
import HostingListTable from '@/components/mybb/hosting/HostingListTable'
import HostingParticipantsTable from '@/components/mybb/hosting/HostingParticipantsTable'
import FixedFooter from '@/components/mybb/ui/FixedFooter'
import HostingModal from '@/components/mybb/hosting/HostingModal'
import HostingCancelModal from '@/components/mybb/hosting/HostingCancelModal.vue'
import ShareHostingsModal from '@/components/mybb/hosting/ShareHostingsModal'

import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'
import {
  GET_HOSTING_CONFIGURATION,
  HOSTING_FROM_GATHERING,
  GET_PENDING_OVERNIGHT_STAY,
  CREATE_HOSTING_REQUESTS,
  CANCEL_HOSTING_REQUESTS,
  SHARE_HOSTINGS
} from '@/graphql/Hosting'

import axios from 'axios'
import dateFormat from 'date-fns/format'

export default {
  name: 'HostingList',
  components: {
    FixedFooter,
    HostingConfiguration,
    HostingListTable,
    HostingModal,
    HostingParticipantsTable,
    HostingCancelModal,
    ShareHostingsModal
  },
  data() {
    return {
      configurationModal: false,
      hostingModal: false,
      cancelHostingModal: false,
      shareHostingsModal: false,
      selectedParticipantUuids: []
    }
  },
  computed: {
    waitingPeople() {
      return 0
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    },
    canShareHostings() {
      if (!this.gathering) return false

      return this.gathering.status === this.$const.gatheringStatus.PUBLISHED_FRONT && !this.gathering.areHostingShared
    }
  },
  apollo: {
    hostingConfiguration: {
      query: GET_HOSTING_CONFIGURATION,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update({ hostingConfigurationFromGatheringUuid }) {
        return hostingConfigurationFromGatheringUuid
      },
      skip() {
        return !this.$route.params.gatheringUuid
      }
    },
    pendingOvernightStays: {
      query: GET_PENDING_OVERNIGHT_STAY,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      skip() {
        return !this.$route.params.gatheringUuid
      }
    },
    hostings: {
      query: HOSTING_FROM_GATHERING,
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      },
      skip() {
        return !this.$route.params.gatheringUuid
      },
      update({ hostingsFromGatheringUuid }) {
        return hostingsFromGatheringUuid
      }
    },
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    }
  },
  methods: {
    t(key, value) {
      return this.$t(`mybb.hostingList.${key}`, value)
    },
    goToHostingForm() {
      return this.$router.push({ name: 'HostingForm' })
    },
    hostingExtract() {
      const fileName = `${dateFormat(new Date(), 'yyMMdd')}_HostingExtract.xlsx`
      const job = axios.post(
        `/gathering/participants/hosting-extract/${this.$get(this.$route, 'params.gatheringUuid')}`,
        { filters: { solicitation: [], invitation: [], presence: [] } },
        {
          responseType: 'blob',
          headers: {
            'x-impersonificated-user': this.$store.state.impersonificatedUser
          }
        }
      )

      this.$bus.$emit('extract', {
        job,
        label: this.t(`options.hostingExtract`),
        fileName,
        status: {
          pending: this.t('status.pending'),
          success: this.t('status.done'),
          error: this.t('status.error')
        }
      })
    },
    async hostingModalConfirm(hosting) {
      await this.$apollo.mutate({
        mutation: CREATE_HOSTING_REQUESTS,
        variables: {
          hostingUuid: hosting.hostingUuid,
          participantUuids: this.selectedParticipantUuids
        }
      })

      this.hostingModal = false
      this.selectedParticipantUuids = []
      this.$apollo.queries.hostings.refetch()
      this.$apollo.queries.pendingOvernightStays.refetch()
    },
    async cancelHostingModalConfirm() {
      await this.$apollo.mutate({
        mutation: CANCEL_HOSTING_REQUESTS,
        variables: {
          participantUuids: this.selectedParticipantUuids
        }
      })

      this.cancelHostingModal = false
      this.selectedParticipantUuids = []
      this.$apollo.queries.hostings.refetch()
      this.$apollo.queries.pendingOvernightStays.refetch()
    },
    async onHostingShared() {
      await this.$apollo.mutate({
        mutation: SHARE_HOSTINGS,
        variables: {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      })

      await this.$apollo.queries.gathering.refetch()

      this.shareHostingsModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.HostingList-table-wrapper {
  background-color: white;
  border-radius: 8px;
}
.HostingList-table {
  border-radius: 8px;
}
.groupIcon {
  font-size: 20px;
}
</style>
