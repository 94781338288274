<template>
  <div class="ActivityParticipants pb-8" v-cy="$cy.activity.addList.container">
    <v-row class="ml-0">
      <mybb-btn text color="white" class="px-0 py-0" @click="backToDetails" inner-icon="mdi-chevron-left">
        {{ t('return') }}
      </mybb-btn>
    </v-row>

    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field
          v-model="search"
          background-color="white"
          :placeholder="t('searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>
      <span class="BtnWrapper">
        <mybb-btn
          @click="filtersOpen = true"
          color="mybb-grey-lighten1"
          background="white"
          inner-icon="mdi-filter-variant"
          class="ml-2"
          icon
        />
        <div v-if="isFilterSelected" class="ActivityParticipants-buttonDot" />
      </span>
    </v-row>

    <v-container class="Container px-6 py-5" fluid>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        :custom-filter="$dataTableFilter(filterFields)"
        item-key="participantUuid"
        sort-by="lastName"
        sort-desc
        show-select
        hide-default-footer
        class="DataTable"
      >
        <template v-slot:[`item.lastName`]="{ value, item }">
          <mybb-text
            v-cy="$cy.activity.addList.item(item.lastName.toUpperCase(), item.firstName).lastName"
            weight="bold"
            class="text-uppercase"
          >
            {{ value }}
          </mybb-text>
        </template>
        <template v-slot:[`item.firstName`]="{ value }">
          <mybb-text weight="bold">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.category`]="{ value }">
          <v-chip v-if="value" :color="value.color" dark class="ma-1">
            {{ value.label }}
          </v-chip>
        </template>
        <template v-slot:[`item.businessUnit`]="{ value }">
          <v-chip :color="buConfig[value.label].color" dark class="ma-1">
            {{ buConfig[value.label].label }}
          </v-chip>
        </template>
      </v-data-table>
    </v-container>

    <fixed-footer :visible="selected.length > 0" :label="t('footer.label', { selected: selected.length })">
      <mybb-btn
        v-cy="$cy.activity.addList.subscribe.button"
        color="mybb-success"
        @click="subscriptionModal = true"
        inner-icon="mdi-check"
      >
        {{ t('footer.subscribe') }}
      </mybb-btn>
    </fixed-footer>

    <modal v-model="subscriptionModal" :title="t('subscriptionModal.title')">
      <mybb-text>
        {{ t('subscriptionModal.text', { selected: selected.length }) }}
      </mybb-text>

      <div class="d-flex justify-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="subscriptionModal = false">
          {{ t('subscriptionModal.cancel') }}
        </mybb-btn>
        <mybb-btn v-cy="$cy.activity.addList.subscribe.confirm" color="mybb-success" @click="subscribe">
          {{ t('subscriptionModal.confirm') }}
        </mybb-btn>
      </div>
    </modal>

    <filter-drawer
      v-model="filters"
      :open="filtersOpen"
      :items="filterItems"
      :label="$t('mybb.transportNoteList.statusFilterLabel')"
      @close="filtersOpen = false"
    />
  </div>
</template>

<script>
import Categories from 'mybb-categories'
import MYBB from '@/const/my-bb'
import FilterDrawer from '@/components/mybb/ui/FilterDrawer'
import FixedFooter from '@/components/mybb/ui/FixedFooter'
import Modal from '@/components/mybb/Modal'
import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'
import { GET_AVAILABLES_PARTICIPANTS, SUBSCRIBE_PARTICIPANTS } from '@/graphql/Activity'
import { GET_ALL_BUS } from '@/graphql/BusinessUnit/GetBus'

export default {
  name: 'ActivityParticipants',
  components: { FilterDrawer, FixedFooter, Modal },
  data() {
    return {
      search: '',
      filtersOpen: false,
      filters: {
        categories: [],
        businessUnits: []
      },
      selected: [],
      subscriptionModal: false,
      filterFields: {
        category: 'category.label',
        lastName: 'lastName',
        firstName: 'firstName'
      }
    }
  },
  computed: {
    filterItems() {
      return [
        {
          key: 'categories',
          label: this.t('filters.categories'),
          filters: this.allCategoriesForGathering
        },
        {
          key: 'businessUnits',
          label: this.t('filters.businessUnits'),
          filters: (this.businessUnits || []).map(bu => ({
            key: bu.businessUnitUuid,
            label: bu.label,
            value: bu.businessUnitUuid
          }))
        }
      ]
    },
    isFilterSelected() {
      const selectedFilterValues = Object.values(this.filters).flat()

      return (
        selectedFilterValues.some(filter => filter) &&
        selectedFilterValues.length !== Object.values(this.$const.categories).length + (this.businessUnits || []).length
      )
    },
    headers() {
      return [
        { sortable: true, text: this.t('table.lastName'), value: 'lastName' },
        { sortable: false, text: this.t('table.firstName'), value: 'firstName' },
        { sortable: true, text: this.t('table.category'), value: 'category' },
        { sortable: true, text: this.t('table.businessUnit'), value: 'businessUnits' }
      ]
    },
    items() {
      if (!Array.isArray(this.availableParticipants) || !this.gathering) return []

      return this.availableParticipants
        .filter(participant => {
          if (!this.filters.businessUnits.length && !this.filters.categories.length) return true

          // Business unit filter if it is defined
          if (
            this.filters.businessUnits.length &&
            this.filters.businessUnits.length !== (this.businessUnits || []).length
          ) {
            const businessUnitUuid = this.$get(participant, 'healthCareProfessional.businessUnitUuid', null)

            if (!this.filters.businessUnits.includes(businessUnitUuid)) {
              return false
            }
          }

          if (
            this.filters.categories.length &&
            this.filters.categories.length !== Object.values(this.$const.categories).length
          ) {
            if (!this.filters.categories.includes(participant.category)) {
              return false
            }
          }

          return true
        })
        .map(participant => ({
          participantUuid: participant.participantUuid,
          lastName: participant.lastName,
          firstName: participant.firstName,
          category: this.categoriesById[participant.category],
          businessUnit: this.$get(participant, 'healthCareProfessional.businessUnit')
        }))
    },
    buConfig() {
      return MYBB.bu
    },
    categoriesById() {
      if (!this.gathering) return

      const categories = Categories.forVersion(this.gathering.categoryVersion)

      return categories.reduce((acc, category) => {
        acc[category.id] = category
        return acc
      }, {})
    },
    allCategoriesForGathering() {
      if (!this.gathering) return

      const eventType = Categories.getEventTypeFromGathering(this.gathering)

      return Categories.forEventType(eventType, this.gathering.categoryVersion).map(category => ({
        key: category.id,
        label: category.label,
        value: category.id
      }))
    }
  },
  apollo: {
    availableParticipants: {
      query: GET_AVAILABLES_PARTICIPANTS,
      variables() {
        return {
          activityUuid: this.$route.params.activityUuid
        }
      },
      skip() {
        return !this.$route.params.activityUuid
      },
      update({ getAvailableParticipants }) {
        return getAvailableParticipants
      }
    },
    businessUnits: {
      query: GET_ALL_BUS
    },
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.activity.participants.${key}`, params)
    },
    backToDetails() {
      return this.$router.push({
        name: 'ActivityDetail',
        params: {
          activityUuid: this.$route.params.activityUuid
        }
      })
    },
    async subscribe() {
      await this.$apollo.mutate({
        mutation: SUBSCRIBE_PARTICIPANTS,
        variables: {
          activityUuid: this.$route.params.activityUuid,
          participantsUuid: this.selected.map(selected => selected.participantUuid)
        }
      })

      return this.backToDetails()
    }
  }
}
</script>

<style lang="scss">
.ActivityParticipants {
  .BtnWrapper {
    position: relative;
  }

  .ActivityParticipants-buttonDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--v-mybb-success-base);
    position: absolute;
    z-index: 10;
    top: -3.75px;
    right: -3.75px;
  }

  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }

  .Container {
    background-color: white;
    border-radius: 8px;

    .DataTable {
      border-radius: 8px;
    }

    .v-data-table {
      thead th {
        font-size: 12px;
        font-weight: 900;
        color: var(--v-mybb-primary-base) !important;
        text-transform: uppercase;
      }

      tbody td {
        color: var(--v-mybb-primary-base);
      }

      th .v-simple-checkbox .v-icon,
      td .v-simple-checkbox .v-icon {
        color: var(--v-mybb-blue-base);
      }
    }
  }
}
</style>
