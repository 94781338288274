<template>
  <div>
    <v-alert v-if="!gathering.gatheringBusinessUnits || gathering.gatheringBusinessUnits.length === 0" type="info">
      <mybb-text class="white--text">{{ t('noBuMessage') }}</mybb-text>
    </v-alert>

    <div v-else>
      <bu-quota-form
        v-for="(businessUnitUuid, index) of businessUnitUuids"
        :key="businessUnitUuid"
        v-model="gathering"
        :is-main="businessUnitUuid === event.mainBusinessUnitUuid"
        :business-unit-uuid="businessUnitUuid"
        :event="event"
        :index="index"
        :disabled="disableAll"
        class="mb-6"
      />

      <mybb-text weight="bold" class="mb-3">
        {{ t('globalQuota', { total: total || 0 }) }}
      </mybb-text>
      <completion-bar v-if="total > 0" legend :total="total" :data="completionData" />
    </div>
  </div>
</template>

<script>
import MYBB from '@/const/my-bb'

import CompletionBar from '@/components/mybb/completion/CompletionBar'
import { congressSchema, biogenEventSchema } from '@/validations/gathering/quota'
import { yup } from '@/mixins'

import BuQuotaForm from './BuQuotaForm'

export default {
  name: 'QuotaStep',
  components: { BuQuotaForm, CompletionBar },
  mixins: [yup],
  model: {
    prop: 'event'
  },
  props: {
    event: {
      type: Object
    },
    isCongress: {
      type: Boolean
    },
    disableAll: {
      type: Boolean
    },
    validatedSolicitations: {
      type: Array,
      default: () => []
    }
  },
  provide() {
    return {
      clear: this.clear,
      collect: this.collect,
      revalidate: this.revalidate
    }
  },
  computed: {
    schema() {
      return this.isCongress ? congressSchema : biogenEventSchema
    },
    businessUnitUuids() {
      return this.$get(this.$store.state, 'bus', [])
        .map(bu => ({ ...bu, color: MYBB.bu[bu.label].color }))
        .sort((bu1, bu2) => {
          if (bu1.businessUnitUuid === this.event.mainBusinessUnitUuid) return -1
          if (bu2.businessUnitUuid === this.event.mainBusinessUnitUuid) return 1

          return MYBB.bu[bu1.label].order - MYBB.bu[bu2.label].order
        })
        .map(bu => bu.businessUnitUuid)
        .filter(bu => this.event.businessUnitUuids.includes(bu))
    },
    completionData() {
      if (!Array.isArray(this.event.gatheringBusinessUnits)) return []

      return this.event.gatheringBusinessUnits.reduce((acc, gbu) => {
        const buConfig = this.buConfig(gbu.businessUnitUuid)

        if (gbu.doctorQuota > 0) {
          acc.push({
            value: gbu.doctorQuota,
            percentage: (100 * gbu.doctorQuota) / this.total,
            color: buConfig.color,
            legend: this.t('hcpQuota', { name: buConfig.businessUnit.label })
          })
        }

        if (gbu.otherDisciplinesQuota > 0) {
          acc.push({
            value: gbu.otherDisciplinesQuota,
            percentage: (100 * gbu.otherDisciplinesQuota) / this.total,
            color: buConfig.color,
            light: true,
            legend: this.t('otherQuota', { name: buConfig.businessUnit.label })
          })
        }

        return acc
      }, [])
    },
    total() {
      if (!Array.isArray(this.event.gatheringBusinessUnits)) return 0

      return this.event.gatheringBusinessUnits.reduce(
        (acc, gbu) => acc + (gbu.doctorQuota || 0) + (gbu.otherDisciplinesQuota || 0),
        0
      )
    },
    gathering: {
      get() {
        return this.event
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    validate() {
      return this.validateSchema(this.event, this.schema, { validatedSolicitations: this.validatedSolicitations })
    },
    t(key, params) {
      return this.$t(`mybb.quotaStep.${key}`, params)
    },
    buConfig(businessUnitUuid) {
      const businessUnit = this.$get(this.$store, 'state.bus', []).find(bu => bu.businessUnitUuid === businessUnitUuid)

      if (!businessUnit) return null

      return { ...MYBB.bu[businessUnit.label], businessUnit }
    },
    setProperty(property, value) {
      this.$emit('input', { ...this.event, [property]: value })
    }
  },
  mounted() {
    if (Array.isArray(this.event.gatheringBusinessUnits) && this.event.gatheringBusinessUnits.length > 0) return
    if (!Array.isArray(this.event.businessUnitUuids) || this.event.businessUnitUuids.length <= 0) return

    const gatheringBusinessUnits = this.event.businessUnitUuids.reduce((acc, businessUnitUuid) => {
      acc.push({
        businessUnitUuid,
        doctorQuota: null,
        hasAutomatedValidation: null,
        hasZoneQuota: null,
        mslResponsibleCriteria: null,
        otherDisciplinesQuota: null,
        solicitationEndDate: null
      })

      return acc
    }, [])

    this.setProperty('gatheringBusinessUnits', gatheringBusinessUnits)
  }
}
</script>
