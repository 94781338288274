var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.activity.list.container),expression:"$cy.activity.list.container"}],staticClass:"ActivityList pt-5 pb-8"},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('mybb-text-field',{attrs:{"background-color":"white","placeholder":_vm.$t('mybb.ActivityList.searchPlaceholder'),"icon":"mdi-magnify"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-spacer'),_c('mybb-btn',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.activity.list.createButton),expression:"$cy.activity.list.createButton"}],attrs:{"inner-icon":"mdi-plus","disabled":_vm.isWindedUpGathering},on:{"click":function($event){return _vm.$router.push({ name: 'ActivityForm' })}}},[_vm._v(" "+_vm._s(_vm.$t('mybb.ActivityList.createActivityButton'))+" ")])],1),(!_vm.items)?_c('Loader',{attrs:{"color":"white"}}):_c('v-data-table',{staticClass:"px-4 py-3 DataTable",attrs:{"headers":_vm.headers,"item-key":"uuid","items":_vm.items,"search":_vm.searchText,"custom-filter":_vm.$dataTableFilter(_vm.filterFields),"custom-sort":_vm.$dataTableSort(),"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.category.text",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"categoryChip py-2 px-4",attrs:{"color":item.category.color,"dark":""}},[_vm._v(" "+_vm._s(item.category.text)+" ")])]}},{key:"item.totalGuest",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_c('mybb-text',{staticClass:"mr-1",attrs:{"weight":item.quota === item.participantCounter ? 'bold' : 'regular'}},[_vm._v(" "+_vm._s(item.participantCounter)+" "),(item.quota !== null)?[_vm._v(" /"+_vm._s(item.quota)+" ")]:_vm._e()],2)],1)]}},{key:"item.redirect",fn:function(ref){
var item = ref.item;
return [_c('router-link',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.activity.list.item(item.name)),expression:"$cy.activity.list.item(item.name)"}],staticClass:"noStyleLink",attrs:{"to":{ name: 'ActivityDetail', params: { activityUuid: item.uuid } }}},[_c('v-icon',{attrs:{"color":"mybb-grey-lighten1"}},[_vm._v("mdi-eye")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }