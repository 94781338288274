<template>
  <nav class="EventSteps-navBar d-none d-sm-flex flex-column align-center pt-12">
    <div class="EventSteps-navBar-container px-8">
      <div class="mb-2">
        <mybb-text weight="black" size="24" class="white--text text-uppercase">
          {{ title }}
        </mybb-text>
      </div>
      <v-btn class="text-none EventSteps-exitButton pl-0" dark exact small text @click="$emit('exit')">
        <v-icon class="EventSteps-exitIcon" left small>mdi-exit-to-app</v-icon>
        <mybb-text class="white--text">{{ t('exitButton') }}</mybb-text>
      </v-btn>

      <div class="EventSteps-steps">
        <div v-for="step in steps" :key="step.id" class="EventSteps-step">
          <div class="d-flex align-center">
            <div :class="`EventSteps-label-icon ${step.color} mr-7`">
              <v-icon v-if="step.icon && step.valid && !step.current" :color="step.text">mdi-check</v-icon>
              <mybb-text v-else weight="bold" size="16" :color="step.text">
                {{ step.index }}
              </mybb-text>
            </div>
            <mybb-text weight="bold" size="18" :color="step.color">
              {{ step.label }}
            </mybb-text>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { yup } from '@/mixins'
import {
  congressSchema as congressInformation,
  biogenEventSchema as biogenEventInformation
} from '@/validations/gathering/informations'
import { congressSchema as congressTeam, biogenEventSchema as biogenEventTeam } from '@/validations/gathering/team'
import { congressSchema as congressQuota, biogenEventSchema as biogenEventQuota } from '@/validations/gathering/quota'
import { congressSchema as congressPlace, biogenEventSchema as biogenEventPlace } from '@/validations/gathering/place'

export default {
  name: 'StepStatus',
  mixins: [yup],
  props: {
    isCongress: {
      type: Boolean,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    maxStepReached: {
      type: Number
    },
    validatedSolicitations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isEdition() {
      return ['true', true].includes(this.$route.query.isEdition)
    },
    title() {
      return this.isEdition
        ? this.t('structure.titleEdit')
        : this.t(`structure.${this.isCongress ? 'titleCongress' : 'titleStandalone'}`)
    },
    schemaInformations() {
      return this.isCongress ? congressInformation : biogenEventInformation
    },
    schemaTeam() {
      return this.isCongress ? congressTeam : biogenEventTeam
    },
    schemaQuota() {
      return this.isCongress ? congressQuota : biogenEventQuota
    },
    schemaPlace() {
      return this.isCongress ? congressPlace : biogenEventPlace
    },
    steps() {
      const currentStep = Number(this.$route.params.step)

      const steps = [
        {
          id: 'informations',
          index: 1,
          label: this.t('sideBar.informationLabel'),
          valid: this.validateSchema(this.event, this.schemaInformations)
        },
        {
          id: 'team',
          index: 2,
          label: this.t('sideBar.hcpSupportLabel'),
          valid: this.validateSchema(this.event, this.schemaTeam)
        }
      ]

      if (this.isCongress) {
        steps.push({
          id: 'quota',
          index: 3,
          label: this.t('sideBar.quotaLabel'),
          valid: this.validateSchema(this.event, this.schemaQuota, {
            validatedSolicitations: this.validatedSolicitations
          })
        })
      } else {
        steps.push({
          id: 'place',
          index: 3,
          label: this.t('sideBar.quotaLabel'),
          valid: this.validateSchema(this.event, this.schemaPlace)
        })
      }

      steps.push({
        id: 'documents',
        index: 4,
        label: this.t('sideBar.documentsLabel')
      })

      // Define steps color
      for (const step of steps) {
        if (step.index === currentStep) {
          step.current = true
          step.color = 'primary'
          step.text = 'white'
          continue
        }

        if (
          typeof step.valid !== 'undefined' &&
          (step.index <= this.maxStepReached ||
            step.index < currentStep ||
            (this.isEdition && this.isStepReachedFromData(step.index)))
        ) {
          step.icon = true
          step.color = step.valid ? 'success' : 'mybb-warning'
          step.text = 'white'
          continue
        }

        step.color = 'white'
        step.text = 'black'
      }

      return steps
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.eventSteps.${key}`, params)
    },
    isStepReachedFromData(index) {
      switch (index) {
        case 1:
          return true

        case 2:
          return (
            (Array.isArray(this.event.users) && this.event.users.length > 0) ||
            (Array.isArray(this.event.businessUnitUuids) && this.event.businessUnitUuids.length > 0)
          )

        case 3:
          if (this.isCongress) {
            return (
              Array.isArray(this.event.gatheringBusinessUnits) &&
              this.event.gatheringBusinessUnits.some(gatheringBusinessUnit => {
                for (const property of [
                  'doctorQuota',
                  'hasAutomatedValidation',
                  'hasZoneQuota',
                  'mslResponsibleCriteria',
                  'otherDisciplinesQuota',
                  'solicitationEndDate'
                ]) {
                  if (gatheringBusinessUnit[property] !== null) return true
                }
              })
            )
          } else {
            return this.event.biogenEvent.invitationType !== null || this.event.biogenEvent.availablePlace > 0
          }

        default:
          return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.EventSteps-navBar {
  width: calc((100vw - 1680px) / 2);
  min-width: 430px;
  box-sizing: border-box;
  padding-left: 80px;
  padding-right: 40px;
  background-color: var(--v-mybb-primary-sidebar-base);
  height: 100%;
  @media (max-width: 1240px) {
    width: 336px;
  }
}
.EventSteps-navBar-container {
  position: fixed;
}

.EventSteps-exitButton {
  font-size: 14px !important;
  font-weight: 400;
}
.EventSteps-exitButton i {
  transform: rotate(180deg);
}

.EventSteps-steps {
  margin-top: 75px;
}

.EventSteps-step {
  &::after {
    content: '--';
    display: block;
    position: relative;
    left: 13px;
    margin: 1em 0;
    height: 60px;
    width: 23px;
    border-left: 3px solid white;
    color: transparent;
  }
  &:last-child::after {
    display: none;
  }
}

.EventSteps-label-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>
