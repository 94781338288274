<template>
  <modal v-model="modal" :title="t('title')">
    <div class="text-center">
      <mybb-text class="mb-4">{{ t('label', { selected }) }}</mybb-text>
    </div>

    <div class="text-center">
      <mybb-text weight="bold" class="mb-6">{{ t('highlight') }}</mybb-text>
    </div>

    <div class="d-flex justify-center mt-5">
      <mybb-btn v-cy="$cy.activity.details.absentModal.cancel" color="mybb-grey" class="mr-10" @click="modal = false">
        {{ t('cancel') }}
      </mybb-btn>
      <mybb-btn v-cy="$cy.activity.details.absentModal.confirm" color="mybb-warning" @click="$emit('confirm')">
        {{ t('confirm') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import Modal from '../Modal.vue'

export default {
  name: 'AbsentModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.activity.detail.absent.${key}`, params)
    }
  }
}
</script>
