import gql from 'graphql-tag'

import { WITH_ALL_COMMENT, WITH_USER } from '../Participant/fragments'

const HOSTING_FRAGMENT = gql`
  fragment Hosting on Hosting {
    address
    gatheringUuid
    hostingUuid
    name
    phone
    roomType
    tourLeaderUuid
    website
  }
`

const OVERNIGHT_STAY_FRAGMENT = gql`
  fragment OvernightStay on OvernightStay {
    date
    hostingNightUuid
    overnightStayUuid
    participantUuid
    participant {
      ...ParticipantWithUser
      hostingComments {
        comment
        hostingCommentUuid
      }
    }
    status
  }
  ${WITH_USER}
`

const HOSTING_NIGHT_FRAGMENT = gql`
  fragment HostingNight on HostingNight {
    date
    hostingNightUuid
    hostingUuid
    price
    quota
    overnightStays {
      ...OvernightStay
    }
  }
  ${OVERNIGHT_STAY_FRAGMENT}
`

const FULL_HOSTING_FRAGMENT = gql`
  fragment FullHosting on Hosting {
    ...Hosting
    tourLeader {
      participantUuid
      firstName
      lastName
    }
    hostingNights {
      ...HostingNight
    }
    hostingParticipantRequests {
      hostingUuid
      hostingParticipantRequestUuid
      participantUuid
      participant {
        ...ParticipantWithUser
      }
    }
    pendingOvernightStays {
      ...OvernightStay
    }
  }
  ${HOSTING_FRAGMENT}
  ${HOSTING_NIGHT_FRAGMENT}
  ${WITH_USER}
  ${OVERNIGHT_STAY_FRAGMENT}
`

export const GET_HOSTING_CONFIGURATION = gql`
  query hostingConfigurationFromGatheringUuid($gatheringUuid: GUID!) {
    hostingConfigurationFromGatheringUuid(gatheringUuid: $gatheringUuid) {
      eveArrival
      postDeparture
      chooseEveArrivalAllowed
      choosePostDepartureAllowed
      chooseNightsAllowed
      comment
      gatheringUuid
      gathering {
        status
        categoryVersion
        beginDate
        endDate
        biogenEvent {
          biogenEventUuid
        }
        congress {
          congressUuid
        }
      }
      hostingConfigurationUuid
    }
  }
`

export const SET_HOSTING_CONFIGURATION = gql`
  mutation setHostingConfiguration($gatheringUuid: GUID!, $configuration: HostingConfigurationInput!) {
    setHostingConfiguration(gatheringUuid: $gatheringUuid, configuration: $configuration) {
      eveArrival
      postDeparture
      chooseEveArrivalAllowed
      choosePostDepartureAllowed
      chooseNightsAllowed
      comment
      gatheringUuid
      gathering {
        beginDate
        endDate
      }
      hostingConfigurationUuid
    }
  }
`

export const HOSTING_FROM_GATHERING = gql`
  query hostingsFromGatheringUuid($gatheringUuid: GUID!) {
    hostingsFromGatheringUuid(gatheringUuid: $gatheringUuid) {
      ...FullHosting
    }
  }
  ${FULL_HOSTING_FRAGMENT}
`

export const HOSTING_FULL = gql`
  query hosting($hostingUuid: GUID!) {
    hosting(hostingUuid: $hostingUuid) {
      ...FullHosting
    }
  }
  ${FULL_HOSTING_FRAGMENT}
`

export const GET_PENDING_OVERNIGHT_STAY = gql`
  query pendingOvernightStays($gatheringUuid: GUID!) {
    pendingOvernightStays(gatheringUuid: $gatheringUuid) {
      ...OvernightStay
      participant {
        ...ParticipantWithAllComments
      }
    }
  }
  ${OVERNIGHT_STAY_FRAGMENT}
  ${WITH_ALL_COMMENT}
`

export const GET_HOSTING_PENDING_PARTICIPANTS = gql`
  query getHostingPendingParticipants($gatheringUuid: GUID!) {
    getHostingPendingParticipants(gatheringUuid: $gatheringUuid) {
      ...ParticipantWithUser
    }
  }
  ${WITH_USER}
`

export const CREATE_HOSTING_REQUESTS = gql`
  mutation createHostingRequests($hostingUuid: GUID!, $participantUuids: [GUID!]!) {
    createHostingRequests(hostingUuid: $hostingUuid, participantUuids: $participantUuids) {
      participantUuid
    }
  }
`

export const CANCEL_HOSTING_REQUESTS = gql`
  mutation cancelHostingRequests($participantUuids: [GUID!]!) {
    cancelHostingRequests(participantUuids: $participantUuids) {
      participantUuid
    }
  }
`

export const SAVE_OVERNIGHT_STAYS = gql`
  mutation saveOvernightStays($participantUuid: GUID!, $hostingUuid: GUID!, $dates: [String!]!, $comment: String!) {
    saveOvernightStays(participantUuid: $participantUuid, hostingUuid: $hostingUuid, dates: $dates, comment: $comment) {
      ...OvernightStay
    }
  }
  ${OVERNIGHT_STAY_FRAGMENT}
`

export const CREATE_HOSTING = gql`
  mutation createHosting($gatheringUuid: GUID!, $hosting: HostingInput!) {
    createHosting(gatheringUuid: $gatheringUuid, hosting: $hosting) {
      ...FullHosting
    }
  }
  ${FULL_HOSTING_FRAGMENT}
`

export const SHARE_HOSTINGS = gql`
  mutation shareHostings($gatheringUuid: GUID!) {
    shareHostings(gatheringUuid: $gatheringUuid) {
      gatheringUuid
    }
  }
`

export const UPDATE_HOSTING = gql`
  mutation updateHosting($hostingUuid: GUID!, $hosting: HostingInput!) {
    updateHosting(hostingUuid: $hostingUuid, hosting: $hosting) {
      ...FullHosting
    }
  }
  ${FULL_HOSTING_FRAGMENT}
`

export const OVERNIGHT_STAYS_FOR_PARTICIPANT = gql`
  query overnightStaysForParticipant($participantUuid: GUID!) {
    overnightStaysForParticipant(participantUuid: $participantUuid) {
      overnightStayUuid
      date
      status
    }
  }
`
