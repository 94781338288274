var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hostings)?_c('Loader',{attrs:{"color":"mybb-blue"}}):_c('v-data-table',{staticClass:"Table",attrs:{"headers":_vm.headers,"items":_vm.items,"custom-sort":_vm.$dataTableSort()},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(value))])]}},_vm._l((_vm.nights),function(night){return {key:("item." + (_vm.dateAsKey(night))),fn:function(ref){
var value = ref.value;
return [(value)?_c('mybb-text',{key:_vm.dateAsKey(night)},[_c('span',{class:{
            'font-weight-bold': value.registered >= value.quota,
            'mybb-blue--text': value.registered === value.quota,
            'mybb-warning--text': value.registered > value.quota
          }},[_vm._v(" "+_vm._s(value.registered)+" ")]),_vm._v("/"+_vm._s(value.quota)+" ")]):_vm._e()]}}}),{key:"item.navigation",fn:function(ref){
          var value = ref.value;
return [_c('v-icon',{attrs:{"size":"20","color":"mybb-grey-lighten1"},on:{"click":function($event){return _vm.$router.push({ name: 'HostingDetailsPage', params: { hostingUuid: value } })}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }