<template>
  <modal v-model="modal" :title="t('title')">
    <mybb-text>{{ t('text', participant) }}</mybb-text>

    <div class="text-center mt-10">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">{{ t('cancel') }}</mybb-btn>
      <mybb-btn color="mybb-success" @click="$emit('confirm')">{{ t('confirm') }}</mybb-btn>
    </div>
  </modal>
</template>

<script>
import Modal from '../Modal'

export default {
  name: 'ValidateBiogenStaffTransportModal',
  components: { Modal },
  model: {
    prop: 'visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    participant: {
      type: Object
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transportNotePage.validateBiogenStaffModal.${key}`, params)
    }
  }
}
</script>
