<template>
  <div class="ActivitySettingsForm">
    <!-- Settings -->
    <v-row class="mb-6">
      <v-col cols="6" md="6" class="py-0 pr-0">
        <mybb-select
          v-model="entryType"
          v-cy="$cy.activity.form.entryType"
          :disabled="!canEdit || isWindedUpGathering"
          :items="entryItems"
          :label="t('entry')"
          icon="mdi-card-account-details"
          :hide-details="false"
        />
      </v-col>
      <v-col cols="3" md="3" class="py-0 pr-0 pl-1">
        <mybb-text-field
          v-model.number="price"
          v-cy="$cy.activity.form.price"
          :label="t('price')"
          type="number"
          icon="mdi-currency-eur"
          :disabled="isWindedUpGathering"
          :hide-details="false"
        />
      </v-col>
      <v-col v-if="displayQuota" cols="3" md="3" class="py-0 pr-0 pl-1">
        <mybb-text-field
          v-model.number="quota"
          v-cy="$cy.activity.form.quota"
          icon="mdi-account-group"
          type="number"
          min="1"
          :label="t('quota')"
          :error-messages="quotaError"
          :disabled="isWindedUpGathering"
          required
        />
      </v-col>
    </v-row>

    <v-row v-if="displayCustomTarget" class="mb-6">
      <v-col cols="6" md="6" class="py-0 pr-0">
        <mybb-select
          v-model="hasCustomTarget"
          v-cy="$cy.activity.form.target"
          :disabled="!canEdit || isWindedUpGathering"
          :items="hasCustomTargetItems"
          :label="t('target')"
          icon="mdi-account"
        />
      </v-col>
    </v-row>

    <v-row v-if="displayActivities" class="mb-6">
      <div class="px-3">
        <mybb-text class="mb-4">{{ t('activitiesTitle') }}</mybb-text>
        <activity-categories v-model="activityTarget" :disabled="!canEdit || isWindedUpGathering" />
      </div>
    </v-row>
  </div>
</template>

<script>
import ActivityCategories from '@/components/mybb/activity/ActivityCategories'

export default {
  name: 'ActivitySettingsForm',
  components: { ActivityCategories },
  model: {
    prop: 'activity',
    event: 'change'
  },
  props: {
    activity: {
      type: Object,
      required: false,
      default: () => ({})
    },
    gathering: {
      type: Object,
      default: () => {}
    },
    minimumQuota: {
      type: Number,
      default: 0
    }
  },
  computed: {
    canEdit() {
      if (!this.isEditing || !this.gathering) {
        return true
      }
      const forbiddenStatus = [
        this.$const.gatheringStatus.PUBLISHED,
        this.$const.gatheringStatus.PUBLISHED_FRONT,
        this.$const.gatheringStatus.CLOSED,
        this.$const.gatheringStatus.WINDED_UP
      ]
      return !forbiddenStatus.includes(this.gathering.status)
    },
    entryItems() {
      return Object.values(this.$const.activity.entryType)
        .map(value => ({
          value,
          text: this.pt(`entryType.${value}`)
        }))
        .sort((a, b) => a.text > b.text)
    },
    hasCustomTargetItems() {
      return [
        {
          value: false,
          text: this.pt('hasCustomTarget.false')
        },
        {
          value: true,
          text: this.pt('hasCustomTarget.true')
        }
      ]
    },
    isEditing() {
      return typeof this.$route.params.activityUuid === 'string'
    },
    displayQuota() {
      return this.entryType === this.$const.activity.entryType.onInvitation
    },
    displayCustomTarget() {
      return (
        this.entryType === this.$const.activity.entryType.onInvitation ||
        this.entryType === this.$const.activity.entryType.onInvitationWithoutQuota
      )
    },
    displayActivities() {
      return this.displayCustomTarget && this.hasCustomTarget
    },
    quotaError() {
      const errors = []

      if (this.activity.quota !== null && this.activity.quota <= 0) {
        errors.push(this.t('invalidPositiveQuota'))
      }
      if (this.minimumQuota && this.activity.quota < this.minimumQuota) {
        errors.push(this.t('invalidQuota', { quota: this.minimumQuota }))
      }

      return errors
    },
    // Fields
    entryType: {
      get() {
        return this.activity.entryType
      },
      set(entryType) {
        this.$emit('change', {
          ...this.activity,
          entryType,
          quota: null,
          hasCustomTarget: null
        })
      }
    },
    price: {
      get() {
        return this.activity.price
      },
      set(price) {
        this.$emit('change', { ...this.activity, price: price ? Number(price) : null })
      }
    },
    quota: {
      get() {
        return this.activity.quota
      },
      set(quota) {
        this.$emit('change', { ...this.activity, quota: quota ? Number(quota) : null })
      }
    },
    hasCustomTarget: {
      get() {
        return this.activity.hasCustomTarget
      },
      set(hasCustomTarget) {
        this.$emit('change', { ...this.activity, hasCustomTarget })
      }
    },
    activityTarget: {
      get() {
        return this.activity.activityTarget
      },
      set(activityTarget) {
        this.$emit('change', { ...this.activity, activityTarget })
      }
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    }
  },
  methods: {
    t(key, params) {
      return this.pt(`form.${key}`, params)
    },
    pt(key, params) {
      return this.$t(`mybb.activity.${key}`, params)
    }
  }
}
</script>

<style scoped>
.ActivitySettingsForm {
  width: 100%;
}
</style>
