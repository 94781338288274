<template>
  <side-panel :title="t('title')" :only-open="onlyOpen">
    <v-row v-for="record of historicalRecords" :key="record.historicalRecordUuid">
      <!-- Date & author -->
      <v-col cols="3">
        <mybb-text weight="bold">{{ record.createdAt | date }} - {{ record.user | author }}</mybb-text>
      </v-col>

      <!-- Status -->
      <v-col cols="3">
        <mybb-text weight="bold">
          {{
            record.data.isValidation
              ? record.data.comment
                ? t('row.askCorrection')
                : t('row.validation')
              : t('row.statusChange')
          }}
        </mybb-text>
      </v-col>

      <!-- Comments -->
      <v-col v-if="record.data.comment" cols="6">
        <mybb-text class="d-block" weight="bold">{{ t('row.comment') }}</mybb-text>
        <mybb-text class="d-block">{{ record.data.comment }}</mybb-text>
      </v-col>
      <!-- Status change -->
      <v-col v-else-if="record.data.newState || record.data.initialState" cols="6">
        <mybb-text v-if="record.data.newState" class="d-block mb-1" weight="bold">
          {{ t('row.newStatus', { status: formatStatus(record.data.newState) }) }}
        </mybb-text>
        <mybb-text v-if="record.data.initialState" class="d-block">
          {{ t('row.oldStatus', { status: formatStatus(record.data.initialState) }) }}
        </mybb-text>
      </v-col>
    </v-row>
  </side-panel>
</template>

<script>
import dateFormat from 'date-fns/format'

import SidePanel from '@/components/mybb/ui/SidePanel'

export default {
  name: 'HistoricTile',
  components: { SidePanel },
  props: {
    gathering: {
      type: Object,
      default: () => {}
    },
    onlyOpen: {
      type: Boolean
    }
  },
  computed: {
    historicalRecords() {
      if (!this.gathering || (this.gathering && !Array.isArray(this.gathering.historicalRecords))) return []

      return Array.from(this.gathering.historicalRecords).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.gatheringDetails.historic.${key}`, params)
    },
    formatStatus(status) {
      return this.t(`status.${status}`)
    }
  },
  filters: {
    date(value) {
      if (!value) return null

      return dateFormat(new Date(value), 'dd/MM/yy - HH:mm')
    },
    author(value) {
      if (!value) return null

      return `${value.lastName} ${value.firstName}`
    }
  }
}
</script>
