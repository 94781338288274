<template>
  <side-panel :title="t('title')">
    <div class="py-5">
      <mybb-text weight="bold">{{ t('hcpDocumentsTitle') }}</mybb-text>
      <v-row>
        <v-col cols="4" v-for="document of frontDocuments" :key="document.healthCareProfessionalFileUuid">
          <input-file :file="document.file" :title="document.name" accept="*" disabled />

          <div class="d-flex justify-space-between px-2 mt-2">
            <!-- Adding date -->
            <span>
              <v-icon small color="mybb-primary-lighten1" class="mr-1">mdi-calendar</v-icon>
              <mybb-text size="12">
                {{ t('addingDate', { date: addingDate(document) }) }}
              </mybb-text>
            </span>
            <!-- Expiration date -->
            <span v-if="document.expirationDate">
              <v-icon small :color="isExpired(document) ? 'mybb-error' : 'mybb-primary-lighten1'" class="mr-1">
                mdi-camera-timer
              </v-icon>
              <mybb-text size="12" :class="isExpired(document) ? 'mybb-error--text' : ''">
                {{ t('expirationDate', { date: expirationDate(document) }) }}
              </mybb-text>
            </span>
          </div>
        </v-col>
      </v-row>

      <mybb-text weight="bold" class="mt-5">{{ t('internalDocumentsTitle') }}</mybb-text>
      <v-row>
        <v-col v-if="lvcDocument" cols="4">
          <input-file :file="lvcDocument" :title="t('lvcDocument')" hide-delete hide-edit />
        </v-col>
        <v-col cols="4" v-for="document of congrexDocuments" :key="document.healthCareProfessionalFileUuid">
          <input-file
            :file="document.file"
            :title="document.name"
            :delete-confirmation="false"
            accept=".jpg,.png,.pdf"
            custom-edit
            @change="file => handleChange(file, document)"
          />

          <div class="d-flex justify-space-between px-2 mt-2">
            <!-- Adding date -->
            <span>
              <v-icon small color="mybb-primary-lighten1" class="mr-1">mdi-calendar</v-icon>
              <mybb-text size="12">
                {{ t('addingDate', { date: addingDate(document) }) }}
              </mybb-text>
            </span>
            <!-- Expiration date -->
            <span v-if="document.expirationDate">
              <v-icon small :color="isExpired(document) ? 'mybb-error' : 'mybb-primary-lighten1'" class="mr-1">
                mdi-camera-timer
              </v-icon>
              <mybb-text size="12" :class="isExpired(document) ? 'mybb-error--text' : ''">
                {{ t('expirationDate', { date: expirationDate(document) }) }}
              </mybb-text>
            </span>
          </div>
        </v-col>
      </v-row>

      <mybb-btn
        color="white"
        class="mybb-primary-lighten1--text mt-10"
        inner-icon="mdi-plus"
        @click="addDocumentModal = true"
      >
        {{ t('addDocument') }}
      </mybb-btn>
    </div>

    <add-document-modal v-model="documentModal" :edited-document="editedDocument" @refresh="$emit('refresh')" />

    <!-- Delete document modal -->
    <modal v-model="modal" :title="t('deleteModal.title')">
      <mybb-text>{{ t('deleteModal.text') }}</mybb-text>

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
          {{ t('deleteModal.cancel') }}
        </mybb-btn>

        <mybb-btn color="mybb-error" @click="removeDocument">
          {{ t('deleteModal.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </side-panel>
</template>

<script>
import dateFormat from 'date-fns/format'

import fileMixin from '@/mixins/file'
import { REMOVE_HCP_DOCUMENT } from '@/graphql/Hcp'

import AddDocumentModal from './AddDocumentModal'
import InputFile from '../InputFile'
import SidePanel from '../ui/SidePanel'
import Modal from '../Modal'

export default {
  name: 'DocumentsPanel',
  components: { SidePanel, InputFile, Modal, AddDocumentModal },
  mixins: [fileMixin],
  props: {
    participant: {
      type: Object
    }
  },
  data() {
    return {
      deleteDocument: null,
      addDocumentModal: false,
      editedDocument: null
    }
  },
  computed: {
    modal: {
      get() {
        return Boolean(this.deleteDocument)
      },
      set(value) {
        if (!value) {
          this.deleteDocument = null
        } else {
          this.deleteDocument = value
        }
      }
    },
    documentModal: {
      get() {
        return this.addDocumentModal || Boolean(this.editedDocument)
      },
      set(value) {
        if (!value) {
          this.addDocumentModal = false
          this.editedDocument = null
        } else if (typeof value === 'object') {
          this.editedDocument = value
        } else if (typeof value === 'boolean') {
          this.addDocumentModal = value
        }
      }
    },
    documents() {
      return this.$get(this.participant, 'healthCareProfessional.healthCareProfessionalFiles', [])
        .filter(document => !document.gatheringUuid || document.gatheringUuid === this.$route.params.gatheringUuid)
        .sort((doc1, doc2) => new Date(doc1.addedDate) - new Date(doc2.addedDate))
        .map(document => ({ ...document, file: this.formatBackendFile(document.file) }))
    },
    frontDocuments() {
      return this.documents.filter(
        document => document.source === this.$const.healthCareProfessionalFileSource.FRONT_APP
      )
    },
    congrexDocuments() {
      return this.documents.filter(
        document => document.source === this.$const.healthCareProfessionalFileSource.INTERNAL
      )
    },
    lvcDocument() {
      const rawLvc = this.$get(this.participant, 'lvc')

      if (!rawLvc) return null

      return this.formatBackendFile({ name: 'LVC.pdf', content: rawLvc })
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.documentsPanel.${key}`, params)
    },
    handleChange(file, document) {
      if (file) {
        this.editedDocument = document
      } else if (file === null) {
        this.deleteDocument = document.healthCareProfessionalFileUuid
      }
    },
    async removeDocument() {
      if (!this.deleteDocument) {
        return
      }

      try {
        await this.$apollo.mutate({
          mutation: REMOVE_HCP_DOCUMENT,
          variables: {
            documentUuid: this.deleteDocument
          }
        })

        this.$emit('refresh')
      } catch (error) {
        console.error(error)
      } finally {
        this.deleteDocument = null
      }
    },
    addingDate(document) {
      if (!document || (document && !document.addedDate)) return null

      return dateFormat(new Date(document.addedDate), 'dd/MM/yy')
    },
    expirationDate(document) {
      if (!document || (document && !document.expirationDate)) return null

      return dateFormat(new Date(document.expirationDate), 'dd/MM/yy')
    },
    isExpired(document) {
      if (!document || (document && !document.expirationDate)) return false

      const date = new Date(document.expirationDate)
      const now = new Date()

      return now >= date
    }
  }
}
</script>
