<template>
  <modal v-model="modal" :title="t('title', { selected: selectedParticipants.length })">
    <div>
      <mybb-text>{{ t('text') }}</mybb-text>
    </div>

    <v-row class="mt-3">
      <v-col cols="4" class="ma-0 py-0">
        <mybb-text-field v-model="price" :label="t('price')" icon="mdi-currency-eur" type="number" />
      </v-col>
      <v-col cols="4" class="ma-0 py-0">
        <date-picker v-model="date" :label="t('date')" />
      </v-col>
    </v-row>

    <div class="mt-6">
      <mybb-textarea v-model="comment" :label="t('comment')" rows="3" />
    </div>

    <div class="mt-4">
      <v-checkbox v-model="doubleConfirmation" :label="t('checkbox')" class="mt-0" dense />
    </div>

    <div class="mt-10 text-center">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
        {{ t('cancel') }}
      </mybb-btn>

      <mybb-btn
        :loading="loading"
        :disabled="!canSave"
        color="mybb-success"
        inner-icon="mdi-content-save"
        @click="save"
      >
        {{ t('confirm') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import { SET_PARTICIPANTS_SUBSCRIPTION } from '@/graphql/Participant'

import Modal from '../Modal'
import DatePicker from '../ui/DatePicker'

export default {
  name: 'ParticipantSubscriptionModal',
  components: { Modal, DatePicker },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    selectedParticipants: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      doubleConfirmation: false,
      price: null,
      date: null,
      comment: null
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.$nextTick(() => {
            this.price = null
            this.date = null
            this.comment = null
            this.doubleConfirmation = null
          })
        }
      }
    },
    canSave() {
      return this.date && this.comment && this.doubleConfirmation
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.AttendeeList.footer.subscriptionModal.${key}`, params)
    },
    async save() {
      if (!this.canSave || this.loading) return
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: SET_PARTICIPANTS_SUBSCRIPTION,
          variables: {
            participantUuids: this.selectedParticipants.map(({ participantUuid }) => participantUuid),
            price: Number.isFinite(Number(this.price)) ? Number(this.price) * 100 : null,
            date: this.date,
            comment: this.comment
          }
        })

        this.$emit('refresh')
        this.modal = false
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
