var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-panel',{staticClass:"ActivitiesPanel",attrs:{"cypress":_vm.$cy.participant.gathering.activities.container,"title":_vm.t('title'),"open":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"participantUuid","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.isOrator",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('v-icon',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.participant.gathering.activities.item(item.name).isOrator),expression:"$cy.participant.gathering.activities.item(item.name).isOrator"}],attrs:{"color":"mybb-primary-lighten1"}},[_vm._v(" mdi-microphone ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('mybb-text',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.participant.gathering.activities.item(item.name).name),expression:"$cy.participant.gathering.activities.item(item.name).name"}],attrs:{"weight":"bold"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.category",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.organizer",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',[_vm._v(_vm._s(_vm.$t(("mybb.activity.organizer." + value))))])]}},{key:"item.date",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',[_vm._v(_vm._s(_vm._f("date")(value)))])]}},{key:"item.schedule",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',[_vm._v(_vm._s(Array.isArray(value) ? value.join(' - ') : value))])]}},{key:"item.presence",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":value.color}},[_vm._v(" "+_vm._s(value.icon)+" ")]):_vm._e()]}},{key:"item.activityUuid",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":"mybb-grey-lighten1"},on:{"click":function($event){return _vm.openActivity(value)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }