<template>
  <v-container class="EventForm pa-0" fluid v-cy="$cy.gathering.form.container">
    <step-status
      :event="event"
      :is-congress="isCongress"
      :max-step-reached="maxStepReached"
      :validated-solicitations="validatedSolicitations"
      @exit="handleExit"
    />

    <div class="StepContainer" v-if="!this.$apollo.queries.gathering.loading">
      <gathering-comments v-if="$route.params.gatheringUuid" class="mb-7" />
      <mybb-text weight="bold" size="20" class="mb-6 text-uppercase">{{ currentComponent.title }}</mybb-text>

      <component
        class="Step"
        v-model="event"
        :is="currentComponent.component"
        :is-congress="isCongress"
        :disable-touchy-fields="disableTouchyFields"
        :disable-all="disableAll"
        :lock-business-units="lockBusinessUnits"
        :validated-solicitations="validatedSolicitations"
        ref="currentStep"
      />

      <v-row justify="space-between" class="ButtonWrapper">
        <div>
          <mybb-btn
            v-if="canPrevious"
            @click="previous"
            :loading="loading.previous"
            color="mybb-grey"
            v-cy="$cy.gathering.form.prev"
          >
            {{ t('prevButton') }}
          </mybb-btn>
        </div>

        <div>
          <mybb-btn
            :disabled="disableSave"
            :loading="loading.save"
            color="mybb-success"
            inner-icon="mdi-content-save"
            @click="save"
            v-cy="$cy.gathering.form.save"
          >
            {{ t('saveButton') }}
          </mybb-btn>
        </div>

        <div>
          <mybb-btn
            v-if="canNext"
            @click="next"
            :loading="loading.next"
            :disabled="currentStep === 1 && !isGatheringNameAvailable"
            color="mybb-primary-lighten1"
            v-cy="$cy.gathering.form.next"
          >
            {{ t('nextButton') }}
          </mybb-btn>

          <gathering-status-button v-else-if="canChangeStatus" :gathering="event" @override="save" />
        </div>
      </v-row>
    </div>

    <data-not-saved-modal
      :initial="initial"
      :current="event"
      :bypass="confirmCreationModal || skipRefresh || submitting"
    />
    <confirm-creation-modal v-model="confirmCreationModal" :mode="creationMode" @confirm="finishCreation" />
    <sensible-changes-modal
      v-model="sensibleChangesModal"
      @confirm="saveSensibleChanges"
      @close="loading = { save: false, next: false, previous: false }"
    />
  </v-container>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _merge from 'lodash/merge'

import { GET_ALL_SETTINGS_GATHERING_WITH_FILES } from '@/graphql/Gatherings/GetGathering'
import {
  SAVE_CONGRESS_EVENT,
  SAVE_BIOGEN_EVENT,
  CREATE_SENSIBLE_CHANGE_REQUEST
} from '@/graphql/Gatherings/SaveGathering'
import { SOLICITATIONS_FOR_CONGRESS_AND_STATUSES } from '@/graphql/Solicitation/GetSolicitations'
import { IS_GATHERING_NAME_AVAILABLE } from '@/graphql/Gatherings'
import { file, yup } from '@/mixins'
import { mapGatheringPayload, buildSensibleChangesPayload } from '@/services/mapper'
import getSensibleChanges from '@/services/gathering/getSensibleChanges'
import gatheringFilesHaveChanged from '@/services/gathering/gatheringFilesHaveChanged'
import { mergeSensibleChanges } from '@/services/gathering/mergeSensibleChanges'
import { getGatheringSchema } from '@/validations/gathering'

import StepStatus from '@/components/mybb/ui/StepStatus'
import DataNotSavedModal from '@/components/mybb/DataNotSavedModal'
import GatheringStatusButton from '@/components/mybb/gathering/GatheringStatusButton'
import ConfirmCreationModal from '@/components/mybb/gathering/ConfirmCreationModal'
import GatheringComments from '@/components/mybb/gathering/GatheringComments'
import SensibleChangesModal from '@/components/mybb/gathering/SensibleChangesModal'

import InformationStep from '@/components/mybb/gathering/form/InformationStep'
import TeamSupportStep from '@/components/mybb/gathering/form/TeamSupportStep'
import QuotaStep from '@/components/mybb/gathering/form/QuotaStep'
import PlaceStep from '@/components/mybb/gathering/form/PlaceStep'
import DocumentStep from '@/components/mybb/gathering/form/DocumentStep'

export default {
  name: 'EventForm',
  components: {
    StepStatus,
    DataNotSavedModal,
    GatheringStatusButton,
    ConfirmCreationModal,
    GatheringComments,
    SensibleChangesModal
  },
  mixins: [file, yup],
  data() {
    return {
      submitting: false,
      routerGuard: null,
      maxStepReached: 0,
      confirmCreationModal: false,
      sensibleChangesModal: false,
      creationMode: null,
      skipRefresh: false,
      loading: {
        save: false,
        next: false,
        previous: false
      },
      initial: null,
      event: {
        isPhysical: null,
        isVirtual: null,
        logoFile: null,
        name: null,
        learnedSociety: null,
        description: null,
        beginDate: null,
        endDate: null,
        limitDate: null,
        beginHour: null,
        endHour: null,
        timezone: null,
        location: null,
        users: [],
        mainBusinessUnitUuid: null,
        businessUnitUuids: [],
        gatheringBusinessUnits: [],
        congress: {
          nationalityType: null,
          websiteUrl: null,
          additionalLinks: [],
          zoneCongresses: [],
          criterias: []
        },
        biogenEvent: {
          eventType: null,
          availablePlace: null,
          invitationType: null
        },
        visioUrl: null
      }
    }
  },
  computed: {
    isEdition() {
      return this.$route.query.isEdition
    },
    currentStep() {
      return Number(this.$route.params.step)
    },
    stepComponents() {
      const components = [
        {
          title: this.$t('mybb.informationStep.title'),
          component: InformationStep
        },
        {
          title: this.$t('mybb.supportTeamStep.title'),
          component: TeamSupportStep
        }
      ]

      // Step 3 - Congress differs from Standalone
      if (this.isCongress) {
        components.push({
          title: this.$t('mybb.quotaStep.title'),
          component: QuotaStep
        })
      } else {
        components.push({
          title: this.$t('mybb.placeStep.title'),
          component: PlaceStep
        })
      }

      components.push({
        title: this.$t('mybb.documentStep.title'),
        component: DocumentStep
      })

      return components
    },
    currentComponent() {
      return this.stepComponents[this.currentStep - 1]
    },
    isCongress() {
      if (this.$route.query.eventType === this.$const.gatheringType.CONGRESS) return true

      return this.gathering ? Boolean(this.gathering.congress) : false
    },
    eventNature() {
      return {
        HYBRID: 'HYBRID',
        PHYSICAL: 'PHYSICAL',
        VIRTUAL: 'VIRTUAL'
      }
    },
    canPrevious() {
      return this.currentStep > 1
    },
    canNext() {
      return this.currentStep < this.stepComponents.length
    },
    canChangeStatus() {
      return (
        this.gathering &&
        (!this.gathering.status ||
          [this.$const.gatheringStatus.DRAFT, this.$const.gatheringStatus.CORRECTION].includes(this.gathering.status))
      )
    },
    disableTouchyFields() {
      if (!this.event.gatheringUuid) return false

      const { TO_VALIDATE, TO_PUBLISH, PUBLISHED, PUBLISHED_FRONT, CLOSED, WINDED_UP } = this.$const.gatheringStatus

      return [TO_VALIDATE, TO_PUBLISH, PUBLISHED, PUBLISHED_FRONT, CLOSED, WINDED_UP].includes(this.event.status)
    },
    disableAll() {
      if (!this.event.gatheringUuid) return false

      const { CLOSED, WINDED_UP } = this.$const.gatheringStatus

      return [CLOSED, WINDED_UP].includes(this.event.status)
    },
    lockBusinessUnits() {
      if (!this.event.gatheringUuid) return false

      const { CLOSED, WINDED_UP, PUBLISHED, PUBLISHED_FRONT } = this.$const.gatheringStatus
      const { CONGRESS } = this.$const.gatheringType

      if (
        this.event.gatheringType === CONGRESS &&
        [PUBLISHED, PUBLISHED_FRONT, CLOSED, WINDED_UP].includes(this.event.status)
      )
        return true

      return [CLOSED, WINDED_UP].includes(this.event.status)
    },
    bufferChanges() {
      if (!this.event.gatheringUuid) return false

      const { TO_PUBLISH, PUBLISHED, PUBLISHED_FRONT } = this.$const.gatheringStatus

      return [TO_PUBLISH, PUBLISHED, PUBLISHED_FRONT].includes(this.event.status)
    },
    gatheringWithPendingChanges() {
      if (!this.gathering) return

      const pendingChanges = (this.gathering.gatheringSensibleChanges || []).find(
        gsc => gsc.status === this.$const.gatheringSensibleChangeStatus.PENDING
      )

      if (!pendingChanges) return this.gathering

      const mergedGathering = _merge({}, this.gathering, pendingChanges.changes)

      // Compensate non-deep merge of array
      if (Array.isArray(pendingChanges.changes.users)) {
        const nonBuRelatedUsers = this.gathering.users.filter(user => !user.businessUnitUuid)

        if (nonBuRelatedUsers.length > 0) {
          mergedGathering.users.push(...nonBuRelatedUsers)
        }
      }

      return mergedGathering
    },
    disableSave() {
      // Super mandatories
      if (!this.event.name || !this.isGatheringNameAvailable || !this.event.beginDate || !this.event.endDate)
        return true

      // Sub type super mandatories
      if (this.isCongress) {
        if (!this.event.congress.learnedSociety || !this.event.congress.nationalityType) return true
      } else {
        if (!this.event.biogenEvent.eventType) return true
      }

      if (
        !this.gathering ||
        (this.gathering &&
          (!this.gathering.status ||
            [this.$const.gatheringStatus.DRAFT, this.$const.gatheringStatus.CORRECTION].includes(
              this.gathering.status
            )))
      )
        return false

      if (getSensibleChanges(this.event, this.gatheringWithPendingChanges).length > 0) {
        return false
      }
      if (gatheringFilesHaveChanged(this.event.gatheringFiles, this.gatheringWithPendingChanges.gatheringFiles))
        return false

      // Non sensible users
      const currentNonSensibles = (this.event.users || []).filter(usr => !usr.businessUnitUuid)
      const initialNonsensibles = (this.gathering.users || []).filter(usr => !usr.businessUnitUuid)

      if (currentNonSensibles.length !== initialNonsensibles.length) return false

      const usersHaveChanged = currentNonSensibles.some(user => {
        const initialUser = initialNonsensibles.find(
          usr => usr.gatheringUserType === user.gatheringUserType && usr.isBackup === user.isBackup
        )

        if (!initialUser) return true

        return initialUser.userUuid !== user.userUuid
      })

      if (usersHaveChanged) return false

      return true
    }
  },
  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING_WITH_FILES,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      skip() {
        return !this.$route.params.gatheringUuid
      },
      result() {
        if (!this.gathering) return

        this.event = _cloneDeep(this.gathering)

        if (this.gathering.logoFile) {
          this.event.logoFile = this.formatBackendFile(this.gathering.logoFile)
        }

        if (this.gathering.beginDate) this.event.beginDate = this.event.beginDate.slice(0, 10)
        if (this.gathering.endDate) this.event.endDate = this.event.endDate.slice(0, 10)

        if (Array.isArray(this.gathering.eventSchedule)) {
          this.event.beginHour = this.gathering.eventSchedule[0] || null
          this.event.endHour = this.gathering.eventSchedule[1] || null
        }

        if (Array.isArray(this.gathering.businessUnits)) {
          this.event.businessUnitUuids = this.gathering.businessUnits
            .map(bu => bu.businessUnitUuid)
            .sort((bu1, bu2) => {
              if (bu1 === this.gathering.mainBusinessUnitUuid) return -1
              if (bu2 === this.gathering.mainBusinessUnitUuid) return 1
              return 0
            })
          this.gathering = {
            ...this.gathering,
            businessUnitUuids: this.event.businessUnitUuids
          }
        }

        if (Array.isArray(this.gathering.gatheringBusinessUnits)) {
          this.event.gatheringBusinessUnits = this.gathering.gatheringBusinessUnits.sort((gbu1, gbu2) => {
            if (gbu1.businessUnitUuid === this.gathering.mainBusinessUnitUuid) return -1
            if (gbu2.businessUnitUuid === this.gathering.mainBusinessUnitUuid) return 1
            return 0
          })
        }

        if (Array.isArray(this.gathering.users)) {
          this.event.users = this.gathering.users.map(usr => ({
            businessUnitUuid: usr.businessUnitUuid,
            gatheringUserType: usr.gatheringUserType,
            isBackup: usr.isBackup,
            userUuid: usr.userUuid
          }))
        }

        if (Array.isArray(this.gathering.gatheringFiles)) {
          this.event.gatheringFiles = this.gathering.gatheringFiles.map(gf => ({
            categories: gf.categories,
            displayToHcp: gf.displayToHcp,
            file: this.formatBackendFile(gf.file),
            fileGatheringUuid: gf.fileGatheringUuid,
            gatheringUuid: gf.gatheringUuid,
            isPhysical: gf.isPhysical,
            isVirtual: gf.isVirtual,
            label: gf.label,
            type: gf.type
          }))
        }

        this.event = mergeSensibleChanges(this.event)

        this.initial = _cloneDeep(this.event)
      }
    },
    validatedSolicitations: {
      query: SOLICITATIONS_FOR_CONGRESS_AND_STATUSES,
      variables() {
        const { CONFIRMED, SENT } = this.$const.solicitationStatus

        return {
          congressUuid: this.gathering.congress.congressUuid,
          statuses: [CONFIRMED, SENT]
        }
      },
      skip() {
        return !this.$get(this.gathering, 'congress.congressUuid', null)
      },
      update({ solicitationsForCongress }) {
        return solicitationsForCongress
      }
    },
    isGatheringNameAvailable: {
      query: IS_GATHERING_NAME_AVAILABLE,
      skip() {
        return !this.event.name
      },
      variables() {
        return {
          name: this.event.name,
          gatheringUuid: this.$get(this.event, 'gatheringUuid', null)
        }
      },
      debounce: 500
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.eventSteps.${key}`, params)
    },
    castGathering() {
      const schema = getGatheringSchema(this.isCongress, true)
      const event = _merge({}, this.event, schema.default())

      return this.castSchema(event, schema)
    },
    async previous() {
      this.loading.previous = true

      if (!this.disableAll) {
        await this.persistToBackend()
      }

      this.toStep(this.currentStep - 1)
    },
    async next() {
      const valid = this.$refs.currentStep.validate()
      if (!valid && this.currentStep === 1 && !this.event.gatheringUuid) return

      if (this.currentStep === 1 && !this.isEdition && !this.event.gatheringUuid) {
        this.confirmCreationModal = true
        this.creationMode = 'next'
        return
      }

      this.loading.next = true

      if (this.currentStep + 1 > this.maxStepReached) {
        this.maxStepReached = this.currentStep + 1
      }

      if (!this.disableAll && this.currentStep !== 4) {
        await this.persistToBackend()
      }

      this.toStep(this.currentStep + 1)
    },
    toStep(step, gatheringUuid) {
      if (step <= 0 || step > this.stepComponents.length) return

      if (step >= 1 && this.$route.name === 'CreateEvent') {
        return this.$router.push({
          name: 'UpdateEvent',
          params: { ...this.$route.params, gatheringUuid: this.event.gatheringUuid || gatheringUuid, step }
        })
      }

      this.$router.push({
        params: { ...this.$route.params, step },
        query: this.$route.query
      })

      // Trigger step validation if we are coming back on it
      this.$nextTick(() => {
        this.loading = {
          save: false,
          next: false,
          previous: false
        }

        if (
          this.currentStep < this.maxStepReached &&
          [null, undefined, this.$const.gatheringStatus.DRAFT].includes(this.event.status)
        ) {
          this.$refs.currentStep.validate()
        }
      })
    },
    async persistToBackend(excludedFields = []) {
      if (this.bufferChanges) return

      const mutation = this.isCongress ? SAVE_CONGRESS_EVENT : SAVE_BIOGEN_EVENT
      const gathering = this.castGathering()

      // Name is super mandatory
      if (!gathering.name) return

      if (this.$route.params.gatheringUuid) {
        gathering.gatheringUuid = this.$route.params.gatheringUuid
      }

      const response = await this.$apollo.mutate({
        mutation,
        variables: mapGatheringPayload(gathering, this.gathering, this.isCongress, excludedFields)
      })

      if (!this.skipRefresh) {
        await this.$apollo.queries.gathering.refetch()
      }

      return Object.values(response.data).pop()
    },
    async save(payload) {
      this.loading.save = true

      if (!this.isEdition && this.currentStep === 1 && (!payload || (payload && !payload.hasOwnProperty('mutation')))) {
        this.confirmCreationModal = true
        this.creationMode = 'save'
        return
      }

      if (this.bufferChanges) {
        const newSensibleChanges = getSensibleChanges(this.event, this.gatheringWithPendingChanges)

        // Persist non-sensible gathering informations
        const nonSensibleChanges = mapGatheringPayload(
          this.castGathering(),
          this.gathering,
          this.isCongress,
          newSensibleChanges
        )
        nonSensibleChanges.gathering.gatheringUuid = this.$route.params.gatheringUuid

        await this.$apollo.mutate({
          mutation: this.isCongress ? SAVE_CONGRESS_EVENT : SAVE_BIOGEN_EVENT,
          variables: nonSensibleChanges
        })

        if (newSensibleChanges.length > 0) {
          this.sensibleChangesModal = true
        } else {
          this.skipRefresh = true

          this.$nextTick(async () => {
            if (payload && payload.mutation) {
              await this.$apollo.mutate(payload)
            }

            this.toGatheringDetails()

            this.skipRefresh = false
          })
        }
      } else {
        this.skipRefresh = true

        this.$nextTick(async () => {
          await this.persistToBackend([])

          if (payload && payload.mutation) {
            await this.$apollo.mutate(payload)
          }

          this.toGatheringDetails()

          this.skipRefresh = false
        })
      }
    },
    toGatheringDetails(gatheringUuid) {
      this.$nextTick(() => {
        this.loading = {
          loading: false,
          next: false,
          previous: false
        }
      })

      return this.$router.push({
        name: 'GatheringDetails',
        params: { gatheringUuid: this.$route.params.gatheringUuid || gatheringUuid }
      })
    },
    handleExit() {
      const route = ['true', true].includes(this.$route.query.isEdition)
        ? { name: 'GatheringDetails', params: { gatheringUuid: this.$route.params.gatheringUuid } }
        : { name: 'CongrexBO' }

      return this.$router.push(route)
    },
    async finishCreation() {
      if (!this.creationMode) return

      this.loading.next = true
      this.loading.save = true

      const gathering = await this.persistToBackend()

      switch (this.creationMode) {
        case 'save':
          this.toGatheringDetails(gathering.gatheringUuid)
          break

        case 'next':
          this.toStep(this.currentStep + 1, gathering.gatheringUuid)
          break
      }

      this.confirmCreationModal = false
      this.creationMode = null
    },
    async saveSensibleChanges(payload) {
      this.submitting = true
      const sensibleChanges = getSensibleChanges(this.event, this.gathering)

      try {
        // Save non-sensible fields
        await this.persistToBackend(sensibleChanges)

        const sensibleGatheringPayload = buildSensibleChangesPayload(this.event, sensibleChanges)

        await this.$apollo.mutate({
          mutation: CREATE_SENSIBLE_CHANGE_REQUEST,
          variables: {
            gatheringUuid: this.$route.params.gatheringUuid,
            changes: sensibleGatheringPayload
          }
        })

        if (payload && payload.hasOwnProperty('mutation')) {
          await this.$apollo.mutate(payload)
        }

        this.toGatheringDetails()
      } catch (error) {
        console.error(error)
      } finally {
        this.sensibleChangesModal = false
      }
    }
  },
  watch: {
    '$route.params.step'(value, old) {
      if (Number(value) !== Number(old)) {
        this.loading = {
          previous: false,
          next: false,
          save: false
        }
      }
    }
  },
  mounted() {
    document.querySelector('.IndexMyBiogen-content').classList.add('form')

    this.maxStepReached = this.currentStep

    this.routerGuard = this.$router.beforeEach((to, from, next) => {
      this.submitting = false

      return next()
    })

    if (this.$route.params.gatheringUuid) return

    const { HYBRID, PHYSICAL, VIRTUAL } = this.$const.gatheringFormat

    // Set congress event type
    switch (this.$route.query.eventNature) {
      case HYBRID:
        this.event.isPhysical = true
        this.event.isVirtual = true
        break
      case PHYSICAL:
        this.event.isPhysical = true
        this.event.isVirtual = false
        break
      case VIRTUAL:
        this.event.isPhysical = false
        this.event.isVirtual = true
    }

    this.initial = this.event
  },
  beforeDestroy() {
    document.querySelector('.IndexMyBiogen-content').classList.remove('form')

    if (typeof this.routerGuard === 'function') {
      this.routerGuard()
      this.routerGuard = null
    }
  }
}
</script>

<style lang="scss">
.EventForm {
  background-color: white;
  display: flex;
  height: 100%;

  .StepContainer {
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
    box-sizing: border-box;
    width: calc(100% - 430px);

    .Step {
      flex-grow: 1;
    }

    .ButtonWrapper {
      margin-top: 30px;
      padding: 0 24px;

      > div {
        min-width: 200px;
      }
    }
  }
}
</style>
