<template>
  <modal v-model="modal" :title="t('title')">
    <mybb-text>{{ t('subtitle') }}</mybb-text>
    <mybb-text weight="bold">{{ t('participantsNumber', { selected }) }}</mybb-text>

    <!-- Picklist -->
    <v-col cols="12" class="mt-4">
      <mybb-select v-model="selectedHosting" :items="formattedHostings" :label="t('picklist')" icon="mdi-home" />
    </v-col>

    <!-- Buttons -->
    <div class="d-flex justify-center mt-5">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">{{ t('cancel') }}</mybb-btn>
      <mybb-btn color="mybb-success" :loading="loading" @click="confirm" :disabled="!selectedHosting">
        {{ t('confirm') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import Modal from '../Modal.vue'

export default {
  name: 'HostingModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    hostings: {
      type: Array,
      required: true
    },
    selected: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedHosting: null,
      loading: false
    }
  },
  computed: {
    formattedHostings() {
      return this.hostings
        ? this.hostings.map(hosting => ({
            value: hosting,
            text: `${hosting.name} - ${hosting.roomType}`
          }))
        : []
    },
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  },
  methods: {
    confirm() {
      this.loading = true
      this.$emit('confirm', this.selectedHosting)
    },
    t(key, params) {
      return this.$t(`mybb.hostingList.modal.${key}`, params)
    }
  },
  watch: {
    modal() {
      this.loading = false
      this.selectedHosting = null
    }
  }
}
</script>
