<template>
  <!--   TODO add if on apollo activity, with loader maybe   -->
  <div v-cy="$cy.activity.details.container" class="ActivityDetail pb-8">
    <v-row class="ml-0" align="center">
      <mybb-btn text color="white" class="px-0 py-0" @click="backToList" inner-icon="mdi-chevron-left">
        {{ t('return') }}
      </mybb-btn>
    </v-row>

    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field
          v-model="search"
          background-color="white"
          :placeholder="t('searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>

      <mybb-btn
        v-cy="$cy.activity.details.editButton"
        background="white"
        color="mybb-grey-lighten1"
        icon
        inner-icon="mdi-pencil"
        class="ml-2"
        @click="editActivity"
      />

      <activity-participant-counter
        :activity="activity"
        :activity-participants="$get(activity, 'activityParticipants', [])"
        :category-version="$get(gathering, 'categoryVersion')"
      />

      <v-spacer />

      <mybb-btn
        v-cy="$cy.activity.details.addParticipants"
        v-if="isOnInvitation"
        @click="toParticipantsList"
        inner-icon="mdi-plus"
        :disabled="isWindedUpGathering"
      >
        {{ t('addPeople') }}
      </mybb-btn>
    </v-row>

    <Loader v-if="!activity" color="white" />
    <div v-else class="ActivityDetail-table-wrapper">
      <mybb-text v-cy="$cy.activity.details.title" weight="bold" size="16" class="text-uppercase mt-5 mb-3 px-6">
        {{ tableTitle }}
      </mybb-text>

      <v-data-table
        class="ActivityDetail-table px-5"
        v-model="selected"
        :headers="headers"
        :items="participants"
        :search="search"
        :custom-filter="$dataTableFilter(filterFields)"
        :custom-sort="$dataTableSort(sortMapping)"
        :show-select="!isWindedUpGathering"
        item-key="activityParticipantUuid"
      >
        <template v-slot:no-data>
          <mybb-text weight="semi-bold">{{ t('table.empty.label') }}</mybb-text>
        </template>
        <template v-slot:[`item.lastName`]="{ value, item }">
          <mybb-text
            v-cy="$cy.activity.details.item(item.lastName.toUpperCase(), item.firstName).lastName"
            weight="bold"
          >
            {{ value }}
          </mybb-text>
        </template>
        <template v-slot:[`item.firstName`]="{ value }">
          <mybb-text weight="bold">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.participationType`]="{ value }">
          <mybb-text v-if="value">{{ t(`table.participationType.${value}`) }}</mybb-text>
        </template>
        <template v-slot:[`item.category`]="{ value }">
          <v-chip v-if="value" dense :color="value.color">
            <mybb-text size="12" class="white--text">{{ value.label }}</mybb-text>
          </v-chip>
        </template>
        <template v-slot:[`item.presence`]="{ value, item }">
          <span v-cy="$cy.activity.details.item(item.lastName.toUpperCase(), item.firstName).presence[value]">
            <v-icon :color="presenceColors[value]">
              {{ presenceIcons[value] }}
            </v-icon>
          </span>
        </template>
      </v-data-table>
    </div>

    <!-- Fixed footer -->
    <fixed-footer :visible="selected.length > 0" :label="t('footer.label', { selected: selected.length })">
      <mybb-btn color="mybb-error" inner-icon="mdi-close" class="mr-6" @click="unsubscribeModal = true">
        {{ t('footer.unsubscribe') }}
      </mybb-btn>
      <mybb-btn v-cy="$cy.activity.details.absentModal.button" color="mybb-warning" @click="absentModal = true">
        {{ t('footer.absent') }}
      </mybb-btn>
    </fixed-footer>

    <!-- Modals -->
    <unsubscribe-modal v-model="unsubscribeModal" :selected="selected.length" @confirm="unsubscribe" />
    <absent-modal v-model="absentModal" :selected="selected.length" @confirm="setAbsents" />
  </div>
</template>

<script>
import Categories from 'mybb-categories'

import { GET_ACTIVITY, UNSUBSCRIBE_PARTICIPANTS, SET_PARTICIPANTS_ABSENT } from '@/graphql/Activity'
import { GET_GATHERING_STATUS } from '@/graphql/Gatherings/GetGathering'
import Loader from '@/components/congrex/tabs/LoaderTab'
import ActivityParticipantCounter from '@/components/mybb/activity/ActivityParticipantCounter'
import FixedFooter from '@/components/mybb/ui/FixedFooter'
import UnsubscribeModal from '@/components/mybb/activity/UnsubscribeModal'
import AbsentModal from '@/components/mybb/activity/AbsentModal'

export default {
  name: 'ActivityDetail',
  components: { Loader, ActivityParticipantCounter, FixedFooter, UnsubscribeModal, AbsentModal },
  data() {
    return {
      selected: [],
      unsubscribeModal: false,
      absentModal: false,
      loading: false,
      search: '',
      sortMapping: {
        category: 'category.label'
      },
      filterFields: {
        category: 'category.label',
        lastName: 'lastName',
        firstName: 'firstName'
      }
    }
  },
  computed: {
    tableTitle() {
      if (!this.activity) return null

      return this.t('tableTitle', { name: this.activity.name, category: this.activity.activityCategory.label })
    },
    participants() {
      return this.$get(this.activity, 'activityParticipants', []).map(activityParticipant => ({
        activityParticipantUuid: activityParticipant.activityParticipantUuid,
        category: this.computeCategory(activityParticipant),
        lastName: this.$get(activityParticipant, 'participant.lastName'),
        firstName: this.$get(activityParticipant, 'participant.firstName'),
        participationType: this.$get(activityParticipant, 'participationType', null),
        presence: this.$get(activityParticipant, 'presence', null)
      }))
    },
    headers() {
      const headers = [
        { sortable: true, text: this.t('table.lastName'), value: 'lastName' },
        { sortable: false, text: this.t('table.firstName'), value: 'firstName' },
        { sortable: true, text: this.t('table.participation'), value: 'participationType' },
        { sortable: true, text: this.t('table.category'), value: 'category' },
        { sortable: true, text: this.t('table.presence'), value: 'presence' }
      ]

      return headers.filter(header => {
        if (header.value === 'participationType') {
          if (this.gathering && !(this.gathering.isPhysical && this.gathering.isVirtual)) {
            return false
          }
        }

        return true
      })
    },
    categoriesById() {
      const version = this.$get(this.gathering, 'categoryVersion')

      if (!version) return null

      return Categories.forVersion(version).reduce((acc, category) => {
        acc[category.id] = category
        return acc
      }, {})
    },
    presenceColors() {
      const { absent, noShow, present } = this.$const.presence

      return {
        [absent]: 'mybb-error',
        [noShow]: 'mybb-warning',
        [present]: 'mybb-primary-lighten1'
      }
    },
    presenceIcons() {
      const { absent, noShow, present } = this.$const.presence

      return {
        [absent]: 'mdi-close',
        [noShow]: 'mdi-account-question-outline',
        [present]: 'mdi-check'
      }
    },
    isOnInvitation() {
      return (
        this.activity &&
        (this.activity.entryType === this.$const.activity.entryType.onInvitation ||
          this.activity.entryType === this.$const.activity.entryType.onInvitationWithoutQuota)
      )
    },
    isWindedUpGathering() {
      const gatheringStatus = this.$get(this.activity, 'gathering.status', null)

      return gatheringStatus === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    activity: {
      query: GET_ACTIVITY,
      variables() {
        return {
          activityUuid: this.$route.params.activityUuid
        }
      },
      skip() {
        return !this.$route.params.activityUuid
      }
    },
    gathering: {
      query: GET_GATHERING_STATUS,
      skip() {
        return !this.$route.params.gatheringUuid
      },
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({
        name: 'ActivityList',
        params: {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      })
    },
    t(key, value) {
      return this.$t(`mybb.activity.detail.${key}`, value)
    },
    computeCategory(activityParticipant) {
      const category = this.$get(activityParticipant, 'participant.category')

      if (!category || !this.categoriesById) return null

      return this.categoriesById[category]
    },
    async unsubscribe() {
      const {
        data: { unsubscribeParticipants }
      } = await this.$apollo.mutate({
        mutation: UNSUBSCRIBE_PARTICIPANTS,
        variables: {
          activityUuid: this.activity.activityUuid,
          activityParticipantsUuid: this.selected.map(({ activityParticipantUuid }) => activityParticipantUuid)
        }
      })
      this.selected = []
      this.activity = unsubscribeParticipants
      this.unsubscribeModal = false
    },
    async setAbsents() {
      await this.$apollo.mutate({
        mutation: SET_PARTICIPANTS_ABSENT,
        variables: {
          activityUuid: this.activity.activityUuid,
          activityParticipantsUuid: this.selected.map(({ activityParticipantUuid }) => activityParticipantUuid)
        }
      })

      await this.$apollo.queries.activity.refetch()
      this.absentModal = false
    },
    toParticipantsList() {
      return this.$router.push({
        name: 'ActivityParticipants',
        params: {
          activityUuid: this.$route.params.activityUuid
        }
      })
    },
    editActivity() {
      this.$router.push({
        name: 'ActivityForm',
        params: {
          activityUuid: this.activity.activityUuid
        }
      })
    },
    selectAll() {
      this.selected =
        this.selected.length >= 1 ? [] : this.participants.map(participant => participant.activityParticipantUuid)
    }
  }
}
</script>

<style lang="scss">
.ActivityDetail-table-wrapper {
  background-color: white;
  border-radius: 8px;
}

.ActivityDetail-table-title {
  @extend .font-size-16-forced;
  font-weight: 700;
  color: var(--v-mybb-primary-sidebar-base);
}

.ActivityDetail-table {
  border-radius: 8px;
}

.ActivityDetail {
  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }

  .v-data-table {
    thead th {
      font-size: 12px;
      font-weight: 900;
      color: var(--v-mybb-primary-base) !important;
      text-transform: uppercase;
    }

    tbody td {
      color: var(--v-mybb-primary-base);
    }

    th .v-simple-checkbox .v-icon,
    td .v-simple-checkbox .v-icon {
      color: var(--v-mybb-blue-base);
    }
  }
}
</style>
