import gql from 'graphql-tag'

export const GET_ACTIVITIES = gql`
  query activitiesFromGathering($gatheringUuid: GUID!) {
    activitiesFromGathering(gatheringUuid: $gatheringUuid) {
      activityParticipants {
        activityParticipantUuid
        presence
        participant {
          category
        }
      }
      name
      isVirtual
      isPhysical
      date
      startHour
      endHour
      place
      room
      activityUuid
      quota
      activityCategory {
        activityCategoryUuid
        label
        color
      }
      gathering {
        categoryVersion
        status
      }
    }
  }
`

export const CREATE_ACTIVITY = gql`
  mutation createActivity($gatheringUuid: GUID!, $activityEntry: ActivityInput!) {
    createActivity(gatheringUuid: $gatheringUuid, activityEntry: $activityEntry) {
      activityUuid
    }
  }
`

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity($activityEntry: ActivityInput!) {
    updateActivity(activityEntry: $activityEntry) {
      activityUuid
    }
  }
`
