var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.gathering.list.container),expression:"$cy.gathering.list.container"}],staticClass:"EventList pt-6 pb-8"},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('mybb-text-field',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.gathering.list.search),expression:"$cy.gathering.list.search"}],attrs:{"background-color":"white","placeholder":_vm.$t('mybb.eventList.searchPlaceholder'),"icon":"mdi-magnify"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-spacer'),(_vm.hasRight(_vm.$const.right.createCongress) && _vm.hasRight(_vm.$const.right.createBiogenEvent))?_c('mybb-btn',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.gathering.list.createButton),expression:"$cy.gathering.list.createButton"}],attrs:{"color":"primary","inner-icon":"mdi-plus"},on:{"click":function($event){_vm.modalCreateEvent = true}}},[_vm._v(" "+_vm._s(_vm.$t('mybb.eventList.createEventButton'))+" ")]):_vm._e()],1),(!_vm.items)?_c('Loader',{attrs:{"color":"white"}}):_c('v-data-table',{staticClass:"px-4 py-3 DataTable",attrs:{"headers":_vm.headers,"sort-by":"beginDate","sort-desc":"","item-key":"uuid","hide-default-footer":true,"items":_vm.items,"search":_vm.searchText,"custom-filter":_vm.$dataTableFilter(_vm.filterFields),"custom-sort":_vm.$dataTableSort(_vm.sortMapping),"disable-pagination":"","must-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.status",fn:function(ref){
var status = ref.value;
return [_c('v-chip',{staticClass:"statusChip py-2 px-4",attrs:{"color":status.color,"dark":""}},[_c('mybb-text',{staticClass:"white--text",attrs:{"size":"12","weight":"semi-bold"}},[_vm._v(" "+_vm._s(_vm.$t(("mybb.eventList.status." + (status.text))))+" ")])],1)]}},{key:"item.manager",fn:function(ref){
var manager = ref.value;
return [_c('v-tooltip',{attrs:{"color":"mybb-primary-darken1","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"mybb-primary-darken1","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-group ")])]}}],null,true)},[(manager && manager.roc)?_c('span',[_vm._v(_vm._s(_vm.$t('mybb.eventList.manager.roc', { roc: manager.roc }))),_c('br')]):_vm._e(),(manager && manager.mci)?_c('span',[_vm._v(_vm._s(_vm.$t('mybb.eventList.manager.mci', { mci: manager.mci }))),_c('br')]):_vm._e(),(manager && manager.cp)?_c('span',[_vm._v(_vm._s(_vm.$t('mybb.eventList.manager.cp', { cp: manager.cp }))),_c('br')]):_vm._e(),(!manager || (!manager.roc && !manager.mci && !manager.cp))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('mybb.eventList.manager.none'))+" ")]):_vm._e()])]}},{key:"item.franchises",fn:function(ref){
var franchises = ref.value;
return _vm._l((franchises),function(franchise,i){return _c('v-chip',{key:i,staticClass:"ml-1 my-1",attrs:{"color":franchise.color,"pill":"","small":""}},[_c('mybb-text',{staticClass:"white--text",attrs:{"size":"12"}},[_vm._v(_vm._s(franchise.text))])],1)})}},{key:"item.guest",fn:function(ref){
var guest = ref.value;
return [(guest)?_c('div',{staticClass:"text-right Guests"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(guest.size))]),_c('v-tooltip',{attrs:{"color":"mybb-primary-darken1","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"infoIcon",attrs:{"color":"mybb-primary-darken1","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('mybb.eventList.guestStatus.confirmed', { value: guest.confirmed }))),_c('br')]),_c('span',[_vm._v(_vm._s(_vm.$t('mybb.eventList.guestStatus.cancel', { value: guest.refused }))),_c('br')]),_c('span',[_vm._v(_vm._s(_vm.$t('mybb.eventList.guestStatus.unanswered', { value: guest.notReplied })))])])],1):_vm._e()]}},{key:"item.redirect",fn:function(ref){
var item = ref.item;
return [_c('router-link',{directives:[{name:"cy",rawName:"v-cy",value:(_vm.$cy.gathering.list.item.detailsButton(item.name)),expression:"$cy.gathering.list.item.detailsButton(item.name)"}],staticClass:"noStyleLink",attrs:{"to":item.eventType === 'VIRTUAL_SESSION'
            ? { name: 'VirtualSessionPage', params: { virtualSessionUuid: item.uuid } }
            : { name: 'EventPage', params: { gatheringUuid: item.uuid } }}},[_c('v-icon',{attrs:{"color":"mybb-grey-lighten1"}},[_vm._v("mdi-eye")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('Modal',{attrs:{"width":"580px","centered":""},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('ChooseEventType',{on:{"cancel":function($event){_vm.modalCreateEvent = false},"create-virtual-event":function (type) { return (_vm.virtualSessionType = type); }}})]},proxy:true}]),model:{value:(_vm.modalCreateEvent),callback:function ($$v) {_vm.modalCreateEvent=$$v},expression:"modalCreateEvent"}}),_c('virtual-session-form-modal',{attrs:{"type":_vm.virtualSessionType},on:{"refresh":function($event){return _vm.$apollo.queries.virtualSessions.refetch()}},model:{value:(_vm.virtualSessionModalOpen),callback:function ($$v) {_vm.virtualSessionModalOpen=$$v},expression:"virtualSessionModalOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }