import i18n from '@/i18n'

export function pad(value) {
  return ('0' + value).slice(-2)
}

export function rangeTime(from, to, increment, { hourMode = false } = { hourMode: false }) {
  let availableHours = []
  for (let i = from; i <= to; i += increment) {
    availableHours.push(formatHour(i, { hourMode }))
  }
  return availableHours
}

export function formatHour(
  value,
  { hourMode = false, withoutSuffix = true } = { hourMode: false, withoutSuffix: true }
) {
  // TODO - internationalization this with i18n.locale
  const period = value >= 13 ? 'pm' : 'am'
  let display = ('0' + Math.floor(value)).slice(-2)
  // => Float hours
  let floatHour = value - Math.floor(value)

  if (!hourMode) {
    switch (floatHour) {
      case 0.25:
        display += ':15'
        break
      case 0.5:
        display += ':30'
        break
      case 0.75:
        display += ':45'
        break
      default:
        display += ':00'
        break
    }
  }

  const res = withoutSuffix ? display : `${display} ${period}`

  return { display: res, text: formatDisplayHourToText(res), value }
}

export function formatDisplayHourToText(value) {
  const [hour, minute] = value.split(':')
  const textHour = parseInt(hour) > 0 ? `${parseInt(hour)} ${i18n.t('format.time.hForHour')}` : ''
  const textMinute = parseInt(minute) > 0 ? `${parseInt(minute)} ${i18n.t('format.time.minForMinute')}` : ''

  return `${textHour} ${textMinute}`.trim()
}

export function formatHourToFloat(value) {
  let res = Number.parseFloat(value.slice(0, 2))

  if (value.length === 5 && value.slice(-2) !== '00') {
    switch (value.slice(-2)) {
      case '15':
        res += 0.25
        break
      case '30':
        res += 0.5
        break
      case '45':
        res += 0.75
        break
      default:
        break
    }
  }

  return res
}
