<template>
  <div class="ChooseEventType mt-7" v-cy="$cy.gathering.form.typeModal.container">
    <mybb-text weight="bold" size="20" class="mb-5">{{ $t('mybb.chooseEventType.title') }}</mybb-text>
    <mybb-text class="mb-4">{{ $t('mybb.chooseEventType.subtitle') }}*</mybb-text>

    <div class="d-flex justify-center mb-6">
      <v-card
        @click="eventTypeSelected = $const.gatheringType.CONGRESS"
        class="d-flex flex-column justify-center border-radius-6-forced"
        :class="congressSelected ? 'mybb-primary-lighten1' : 'white'"
        height="140px"
        width="140px"
        v-cy="$cy.gathering.form.typeModal.congress"
      >
        <img
          v-if="congressSelected"
          alt="Biogen event"
          class="mb-3"
          height="50px"
          src="@/assets/ionic-ios-microphone.svg"
        />
        <img v-else alt="Biogen event" class="mb-3" height="50px" src="@/assets/ionic-ios-microphone-blue.svg" />

        <mybb-text weight="bold" :class="congressSelected ? 'white--text' : 'mybb-primary-darken1--text'">
          {{ $t('mybb.global.eventType.congress') }}
        </mybb-text>
      </v-card>
      <v-card
        @click="eventTypeSelected = $const.gatheringType.BIOGEN_EVENT"
        class="d-flex flex-column justify-center ml-12 border-radius-6-forced"
        :class="biogenEventSelected ? 'mybb-primary-lighten1 white--text' : 'white mybb-primary-darken1--text'"
        height="140px"
        width="140px"
        v-cy="$cy.gathering.form.typeModal.biogenEvent"
      >
        <img
          v-if="biogenEventSelected"
          alt="Biogen event"
          class="mb-3"
          height="50px"
          src="@/assets/standalone_white.svg"
        />
        <img v-else alt="Biogen event" class="mb-3" height="50px" src="@/assets/standalone_blue.svg" />

        <mybb-text weight="bold" :class="biogenEventSelected ? 'white--text' : 'mybb-primary-darken1--text'">
          {{ $t('mybb.global.eventType.biogenEvent') }}
        </mybb-text>
      </v-card>
      <v-card
        @click="eventTypeSelected = $const.virtualSession.eventType"
        class="d-flex flex-column justify-center ml-12 border-radius-6-forced"
        :class="virtualSessionSelected ? 'mybb-primary-lighten1 white--text' : 'white mybb-primary-darken1--text'"
        height="140px"
        width="140px"
      >
        <v-icon
          :style="{ fontSize: '50px' }"
          height="50px"
          class="mb-3"
          :color="virtualSessionSelected ? 'white' : 'mybb-primary-darken1'"
        >
          mdi-laptop
        </v-icon>
        <mybb-text weight="bold" :class="virtualSessionSelected ? 'white--text' : 'mybb-primary-darken1--text'">
          {{ $t('mybb.global.eventType.virtualSession') }}
        </mybb-text>
      </v-card>
    </div>

    <div v-if="congressSelected || biogenEventSelected">
      <mybb-text>
        {{
          congressSelected ? $t('mybb.chooseEventType.congressNature') : $t('mybb.chooseEventType.biogenEventNature')
        }}*
      </mybb-text>
      <v-radio-group v-model="eventNature" class="mt-1" dense row>
        <div class="flex-grow-1 d-flex justify-center">
          <v-radio
            :value="$const.gatheringFormat.PHYSICAL"
            color="mybb-primary-lighten1"
            v-cy="$cy.gathering.form.typeModal.nature.physical"
          >
            <mybb-text slot="label">{{ $t('mybb.chooseEventType.physicalLabel') }}</mybb-text>
          </v-radio>
          <v-radio
            v-if="congressSelected"
            :value="$const.gatheringFormat.VIRTUAL"
            color="mybb-primary-lighten1"
            v-cy="$cy.gathering.form.typeModal.nature.virtual"
          >
            <mybb-text slot="label">{{ $t('mybb.chooseEventType.virtualLabel') }}</mybb-text>
          </v-radio>
          <v-radio
            :value="$const.gatheringFormat.HYBRID"
            color="mybb-primary-lighten1"
            v-cy="$cy.gathering.form.typeModal.nature.hybrid"
          >
            <mybb-text slot="label">{{ $t('mybb.chooseEventType.hybridLabel') }}</mybb-text>
          </v-radio>
        </div>
      </v-radio-group>
    </div>

    <div v-else-if="virtualSessionSelected">
      <mybb-text>{{ $t('mybb.chooseEventType.virtualSessionNature') }}*</mybb-text>
      <v-radio-group v-model="eventNature" class="mt-1" dense row>
        <div class="flex-grow-1 d-flex justify-center">
          <v-radio :value="$const.virtualSession.type.oseus" color="mybb-primary-lighten1">
            <mybb-text slot="label">{{ $t('virtualSession.type.oseus') }}</mybb-text>
          </v-radio>
          <v-radio :value="$const.virtualSession.type.manual" color="mybb-primary-lighten1">
            <mybb-text slot="label">{{ $t('virtualSession.type.manual') }}</mybb-text>
          </v-radio>
        </div>
      </v-radio-group>
    </div>

    <div class="d-flex justify-center mt-10">
      <mybb-btn @click="$emit('cancel')" color="mybb-grey">
        {{ $t('mybb.chooseEventType.cancelButton') }}
      </mybb-btn>
      <mybb-btn
        class="ml-8"
        color="mybb-primary-lighten1"
        :disabled="!canSubmit"
        @click="onSubmit"
        v-cy="$cy.gathering.form.typeModal.submit"
      >
        {{ $t('mybb.chooseEventType.validButton') }}
      </mybb-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChooseEventType',

  data() {
    return {
      eventNature: null,
      eventTypeSelected: null
    }
  },

  computed: {
    biogenEventSelected() {
      return this.eventTypeSelected === this.$const.gatheringType.BIOGEN_EVENT
    },
    congressSelected() {
      return this.eventTypeSelected === this.$const.gatheringType.CONGRESS
    },
    virtualSessionSelected() {
      return this.eventTypeSelected === this.$const.virtualSession.eventType
    },
    canSubmit() {
      return this.eventTypeSelected && Boolean(this.eventNature)
    },
    computedTo() {
      if (!this.canSubmit) return null

      const to = { name: 'CreateEvent', params: { step: 1 } }

      if (this.biogenEventSelected) {
        to.query = { eventNature: this.eventNature, eventType: this.$const.gatheringType.BIOGEN_EVENT }
      } else {
        to.query = { eventNature: this.eventNature, eventType: this.$const.gatheringType.CONGRESS }
      }

      return to
    }
  },
  methods: {
    onSubmit() {
      if (!this.canSubmit) return

      if (this.virtualSessionSelected) {
        this.$emit('create-virtual-event', this.eventNature)
        this.$emit('cancel')
        return
      }

      this.$router.push(this.computedTo)
    }
  }
}
</script>

<style scoped>
.ChooseEventType {
  text-align: center;
}
</style>
