<template>
  <div>
    <mybb-text>
      {{ t('buHead') }}
      <mybb-text weight="bold">{{ businessUnit.label }}</mybb-text>
    </mybb-text>

    <v-row>
      <!-- Target -->
      <v-col cols="6">
        <mybb-select v-model="criteria" :items="criterias" :label="t('criteria')" />
      </v-col>

      <v-col cols="6">
        <mybb-select v-model="zone" :items="zonesForBu" :label="t('zone')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'HcpBusinessUnitFilterGroup',
  model: {
    prop: 'businessUnit'
  },
  props: {
    businessUnitUuid: {
      type: String,
      required: true
    },
    filter: {
      type: Object
    },
    zones: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    criteria: {
      get() {
        return this.filter.criteria
      },
      set(criteria) {
        this.setPartial({ criteria })
      }
    },
    zone: {
      get() {
        return this.filter.zoneUuid
      },
      set(zoneUuid) {
        this.setPartial({ zoneUuid })
      }
    },
    businessUnit() {
      return this.$get(this.$store, 'state.bus', []).find(bu => bu.businessUnitUuid === this.businessUnitUuid)
    },
    criterias() {
      const criterias = this.$const.targetBusinessUnitWithLabel[this.businessUnit.label]

      return Object.keys(criterias).map(key => ({ value: key, text: criterias[key] }))
    },
    zonesForBu() {
      return this.zones
        .filter(zone => zone.businessUnitUuid === this.businessUnitUuid)
        .map(zone => ({ value: zone.zoneUuid, text: zone.label }))
        .sort((z1, z2) => z1.text.localeCompare(z2.text))
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.participants.add-list.filters.${key}`, params)
    },
    setPartial(partial) {
      this.$emit('input', { ...partial, businessUnitUuid: this.businessUnitUuid })
    }
  }
}
</script>
