<template>
  <v-slide-y-reverse-transition v-if="visible">
    <div class="FixedFooter d-flex px-10 py-3 align-center">
      <mybb-text size="16" weight="bold" class="white--text">
        {{ label }}
      </mybb-text>

      <v-spacer />

      <div>
        <slot />
      </div>
    </div>
  </v-slide-y-reverse-transition>
</template>

<script>
export default {
  name: 'FixedFooter',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss">
.FixedFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: var(--v-mybb-primary-base);
  border-top: 3px solid white;
}
</style>
