<template>
  <div>
    <v-row class="mb-6">
      <v-col cols="12" md="3" class="pa-0 px-1">
        <mybb-select
          v-model="businessUnitUuid"
          :items="items"
          :label="t('businessUnit')"
          icon="mdi-office-building"
          :disabled="isDisabled"
          v-cy="$cy.gathering.transport.configuration.modal.businessUnit"
        />
      </v-col>

      <v-col cols="12" md="3" class="pa-0 px-1">
        <mybb-text-field
          v-model="companyCode"
          :label="t('companyCode')"
          icon="mdi-office-building"
          :disabled="isDisabled"
          v-cy="$cy.gathering.transport.configuration.modal.companyCode"
        />
      </v-col>

      <v-col cols="12" md="3" class="pa-0 px-1">
        <mybb-text-field
          v-model="geoCode"
          :label="t('geoCode')"
          icon="mdi-office-building"
          :disabled="isDisabled"
          v-cy="$cy.gathering.transport.configuration.modal.geoCode"
        />
      </v-col>

      <v-col cols="12" md="3" class="pa-0 px-1">
        <mybb-text-field
          v-model="centerCost"
          :label="t('costCenter')"
          icon="mdi-office-building"
          :disabled="isDisabled"
          v-cy="$cy.gathering.transport.configuration.modal.centerCost"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3" class="pa-0 px-1">
        <mybb-text-field
          v-model="accountCode"
          :label="t('accountCode')"
          icon="mdi-office-building"
          :disabled="isDisabled"
          v-cy="$cy.gathering.transport.configuration.modal.accountCode"
        />
      </v-col>

      <v-col cols="12" md="3" class="pa-0 px-1">
        <mybb-text-field
          v-model="localCode"
          :label="t('localCode')"
          icon="mdi-office-building"
          :disabled="isDisabled"
          v-cy="$cy.gathering.transport.configuration.modal.localCode"
        />
      </v-col>

      <v-col cols="12" md="3" class="pa-0 px-1">
        <mybb-text-field
          v-model="productCode"
          :label="t('productCode')"
          icon="mdi-office-building"
          :disabled="isDisabled"
          v-cy="$cy.gathering.transport.configuration.modal.productCode"
        />
      </v-col>

      <v-col cols="12" md="3" class="pa-0 px-1">
        <mybb-text-field
          v-model="poCode"
          :label="t('poCode')"
          icon="mdi-office-building"
          :disabled="isDisabled"
          v-cy="$cy.gathering.transport.configuration.modal.poCode"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TransportBusinessConfiguration',
  model: {
    prop: 'configuration'
  },
  props: {
    configuration: {
      type: Object,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items() {
      const items = []

      for (const bu of this.$const.businessUnitOrder) {
        const businessUnit = this.$store.state.bus.find(b => b.externalId === bu.code)

        items.push({
          value: businessUnit.businessUnitUuid,
          text: businessUnit.externalId
        })
      }

      return items
    },
    selectValue() {
      return this.$get(this.configuration, 'businessUnitUuid')
    },
    businessUnitUuid: {
      get() {
        return this.$get(this.configuration, 'businessUnitUuid', null)
      },
      set(businessUnitUuid) {
        this.$emit('input', { ...this.configuration, businessUnitUuid })
      }
    },
    companyCode: {
      get() {
        return this.$get(this.configuration, 'companyCode', null)
      },
      set(companyCode) {
        this.$emit('input', { ...this.configuration, companyCode })
      }
    },
    geoCode: {
      get() {
        return this.$get(this.configuration, 'geoCode', null)
      },
      set(geoCode) {
        this.$emit('input', { ...this.configuration, geoCode })
      }
    },
    centerCost: {
      get() {
        return this.$get(this.configuration, 'centerCost', null)
      },
      set(centerCost) {
        this.$emit('input', { ...this.configuration, centerCost })
      }
    },
    accountCode: {
      get() {
        return this.$get(this.configuration, 'accountCode', null)
      },
      set(accountCode) {
        this.$emit('input', { ...this.configuration, accountCode })
      }
    },
    localCode: {
      get() {
        return this.$get(this.configuration, 'localCode', null)
      },
      set(localCode) {
        this.$emit('input', { ...this.configuration, localCode })
      }
    },
    productCode: {
      get() {
        return this.$get(this.configuration, 'productCode', null)
      },
      set(productCode) {
        this.$emit('input', { ...this.configuration, productCode })
      }
    },
    poCode: {
      get() {
        return this.$get(this.configuration, 'poCode', null)
      },
      set(poCode) {
        this.$emit('input', { ...this.configuration, poCode })
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.transportNoteList.configurationModal.businessUnitRow.${key}`, params)
    },
    onInput(key, value) {
      this.$emit('input', Object.assign({}, this.configuration, { [key]: value }))
    }
  }
}
</script>
