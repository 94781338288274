<template>
  <div class="HostingParticipants pb-8">
    <v-row class="ml-0">
      <mybb-btn text color="white" class="px-0 py-0" @click="backToDetails" inner-icon="mdi-chevron-left">
        {{ t('return') }}
      </mybb-btn>
    </v-row>

    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field
          v-model="search"
          background-color="white"
          :placeholder="t('searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>
    </v-row>

    <v-container class="Container px-6 py-5" fluid>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        :custom-filter="$dataTableFilter(filterFields)"
        item-key="participantUuid"
        sort-by="lastName"
        sort-desc
        :show-select="!isWindedUpGathering"
        hide-default-footer
        class="DataTable"
        disable-pagination
      >
        <template v-slot:[`item.lastName`]="{ value }">
          <mybb-text weight="bold" class="text-uppercase">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.firstName`]="{ value }">
          <mybb-text weight="bold">{{ value }}</mybb-text>
        </template>
        <template v-slot:[`item.category`]="{ value }">
          <v-chip v-if="value" :color="value.color" dark class="ma-1">
            {{ value.label }}
          </v-chip>
        </template>
      </v-data-table>
    </v-container>

    <fixed-footer :visible="selected.length > 0" :label="t('footer.label', { selected: selected.length })">
      <mybb-btn color="mybb-success" @click="setHostingModal = true" inner-icon="mdi-check">
        {{ t('footer.button') }}
      </mybb-btn>
    </fixed-footer>

    <modal v-model="setHostingModal" :title="t('setHostingModal.title')">
      <mybb-text>
        {{ t('setHostingModal.text', { selected: selected.length }) }}
      </mybb-text>

      <div class="d-flex justify-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="setHostingModal = false">
          {{ t('setHostingModal.cancel') }}
        </mybb-btn>
        <mybb-btn :loading="loading" color="mybb-success" @click="setHostingRequest">
          {{ t('setHostingModal.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import Categories from 'mybb-categories'

import { GET_HOSTING_PENDING_PARTICIPANTS, CREATE_HOSTING_REQUESTS } from '@/graphql/Hosting'
import { GET_GATHERING_STATUS } from '@/graphql/Gatherings/GetGathering'

import FixedFooter from '@/components/mybb/ui/FixedFooter'
import Modal from '@/components/mybb/Modal'

export default {
  name: 'HostingParticipants',
  components: { FixedFooter, Modal },
  data() {
    return {
      search: '',
      selected: [],
      setHostingModal: false,
      loading: false,
      filterFields: {
        category: 'category.label',
        lastName: 'lastName',
        firstName: 'firstName'
      }
    }
  },
  computed: {
    headers() {
      return [
        { sortable: true, text: this.t('table.lastName'), value: 'lastName' },
        { sortable: false, text: this.t('table.firstName'), value: 'firstName' },
        { sortable: true, text: this.t('table.category'), value: 'category' }
      ]
    },
    items() {
      return (this.availableParticipants || []).map(participant => ({
        participantUuid: participant.participantUuid,
        lastName: participant.lastName,
        firstName: participant.firstName,
        category: this.categoryByIds[participant.category]
      }))
    },
    categoryByIds() {
      if (!this.gathering) return null

      const categories = Categories.forVersion(this.gathering.categoryVersion)

      return categories.reduce((acc, category) => {
        acc[category.id] = category
        return acc
      }, {})
    },
    isWindedUpGathering() {
      if (!this.gathering) return false

      return this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    availableParticipants: {
      query: GET_HOSTING_PENDING_PARTICIPANTS,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      skip() {
        return !this.$route.params.gatheringUuid
      },
      update({ getHostingPendingParticipants }) {
        return getHostingPendingParticipants
      }
    },
    gathering: {
      query: GET_GATHERING_STATUS,
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hostingParticipants.${key}`, params)
    },
    backToDetails() {
      this.loading = false
      return this.$router.push({ name: 'HostingDetailsPage' })
    },
    async setHostingRequest() {
      this.loading = true

      await this.$apollo.mutate({
        mutation: CREATE_HOSTING_REQUESTS,
        variables: {
          hostingUuid: this.$route.params.hostingUuid,
          participantUuids: this.selected.map(({ participantUuid }) => participantUuid)
        }
      })

      return this.backToDetails()
    }
  }
}
</script>

<style lang="scss">
.HostingParticipants {
  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }

  .Container {
    background-color: white;
    border-radius: 8px;

    .DataTable {
      border-radius: 8px;
    }

    .v-data-table {
      thead th {
        font-size: 12px;
        font-weight: 900;
        color: var(--v-mybb-primary-base) !important;
        text-transform: uppercase;
      }

      tbody td {
        color: var(--v-mybb-primary-base);
      }

      th .v-simple-checkbox .v-icon,
      td .v-simple-checkbox .v-icon {
        color: var(--v-mybb-blue-base);
      }
    }
  }
}
</style>
