<template>
  <div class="EventList pt-6 pb-8" v-cy="$cy.gathering.list.container">
    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="4">
        <mybb-text-field
          v-model="searchText"
          background-color="white"
          :placeholder="$t('mybb.eventList.searchPlaceholder')"
          icon="mdi-magnify"
          v-cy="$cy.gathering.list.search"
        />
      </v-col>
      <v-spacer />
      <mybb-btn
        v-if="hasRight($const.right.createCongress) && hasRight($const.right.createBiogenEvent)"
        @click="modalCreateEvent = true"
        color="primary"
        inner-icon="mdi-plus"
        v-cy="$cy.gathering.list.createButton"
      >
        {{ $t('mybb.eventList.createEventButton') }}
      </mybb-btn>
    </v-row>

    <Loader v-if="!items" color="white" />
    <v-data-table
      v-else
      v-model="selected"
      :headers="headers"
      sort-by="beginDate"
      sort-desc
      item-key="uuid"
      :hide-default-footer="true"
      :items="items"
      :search="searchText"
      :custom-filter="$dataTableFilter(filterFields)"
      :custom-sort="$dataTableSort(sortMapping)"
      class="px-4 py-3 DataTable"
      disable-pagination
      must-sort
    >
      <!-- Values -->
      <template v-slot:[`item.name`]="{ item }">
        <mybb-text weight="bold">{{ item.name }}</mybb-text>
      </template>
      <template v-slot:[`item.status`]="{ value: status }">
        <v-chip :color="status.color" class="statusChip py-2 px-4" dark>
          <mybb-text size="12" weight="semi-bold" class="white--text">
            {{ $t(`mybb.eventList.status.${status.text}`) }}
          </mybb-text>
        </v-chip>
      </template>
      <template v-slot:[`item.manager`]="{ value: manager }">
        <v-tooltip color="mybb-primary-darken1" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="mybb-primary-darken1" dark v-bind="attrs" v-on="on"> mdi-account-group </v-icon>
          </template>
          <span v-if="manager && manager.roc">{{ $t('mybb.eventList.manager.roc', { roc: manager.roc }) }}<br /></span>
          <span v-if="manager && manager.mci">{{ $t('mybb.eventList.manager.mci', { mci: manager.mci }) }}<br /></span>
          <span v-if="manager && manager.cp">{{ $t('mybb.eventList.manager.cp', { cp: manager.cp }) }}<br /></span>
          <span v-if="!manager || (!manager.roc && !manager.mci && !manager.cp)">
            {{ $t('mybb.eventList.manager.none') }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.franchises`]="{ value: franchises }">
        <v-chip v-for="(franchise, i) in franchises" :key="i" :color="franchise.color" class="ml-1 my-1" pill small>
          <mybb-text size="12" class="white--text">{{ franchise.text }}</mybb-text>
        </v-chip>
      </template>
      <template v-slot:[`item.guest`]="{ value: guest }">
        <div v-if="guest" class="text-right Guests">
          <span class="mr-1">{{ guest.size }}</span>
          <v-tooltip color="mybb-primary-darken1" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="mybb-primary-darken1" class="infoIcon" dark v-bind="attrs" v-on="on" small>
                mdi-information
              </v-icon>
            </template>
            <span>{{ $t('mybb.eventList.guestStatus.confirmed', { value: guest.confirmed }) }}<br /></span>
            <span>{{ $t('mybb.eventList.guestStatus.cancel', { value: guest.refused }) }}<br /></span>
            <span>{{ $t('mybb.eventList.guestStatus.unanswered', { value: guest.notReplied }) }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.redirect`]="{ item }">
        <router-link
          class="noStyleLink"
          :to="
            item.eventType === 'VIRTUAL_SESSION'
              ? { name: 'VirtualSessionPage', params: { virtualSessionUuid: item.uuid } }
              : { name: 'EventPage', params: { gatheringUuid: item.uuid } }
          "
          v-cy="$cy.gathering.list.item.detailsButton(item.name)"
        >
          <v-icon color="mybb-grey-lighten1">mdi-eye</v-icon>
        </router-link>
      </template>
    </v-data-table>

    <Modal v-model="modalCreateEvent" width="580px" centered>
      <template v-slot:text>
        <ChooseEventType
          @cancel="modalCreateEvent = false"
          @create-virtual-event="type => (virtualSessionType = type)"
        />
      </template>
    </Modal>

    <virtual-session-form-modal
      v-model="virtualSessionModalOpen"
      :type="virtualSessionType"
      @refresh="$apollo.queries.virtualSessions.refetch()"
    />
  </div>
</template>

<script>
import _sortBy from 'lodash/sortBy'
import dateFormat from 'date-fns/format'
import parse from 'date-fns/parse'
import { mapGetters } from 'vuex'

import { GET_MYBB_GATHERINGS } from '@/graphql/Gatherings/GetGatherings'
import { LIST_VIRTUAL_SESSION_ITEM } from '@/graphql/VirtualSession'
import Loader from '@/components/congrex/tabs/LoaderTab'
import Modal from '@/components/mybb/Modal'
import ChooseEventType from '@/components/mybb/ChooseEventType'
import { VirtualSessionFormModal } from '@/components/mybb/virtualSession'

export default {
  name: 'EventList',

  components: { ChooseEventType, Loader, Modal, VirtualSessionFormModal },

  data() {
    return {
      events: null,
      modalCreateEvent: false,
      virtualSessionType: null,
      searchText: '',
      selected: [],
      filterFields: {
        beginDate: 'beginDate',
        endDate: 'endDate',
        name: 'name'
      },
      sortMapping: {
        manager: 'manager.roc',
        guest: 'guest.size',
        status: 'status.text',
        arr: {
          franchises: 'text'
        }
      }
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    virtualSessionModalOpen: {
      get() {
        return Boolean(this.virtualSessionType)
      },
      set(value) {
        if (!value) {
          this.virtualSessionType = null
        } else {
          this.virtualSessionType = value
        }
      }
    },
    headers() {
      return [
        { text: this.$t('mybb.eventList.nameHeader'), value: 'name', class: 'text-uppercase' },
        { text: this.$t('mybb.eventList.typeHeader'), value: 'type', class: 'text-uppercase' },
        { text: this.$t('mybb.eventList.natureHeader'), value: 'nature', class: 'text-uppercase' },
        {
          text: this.$t('mybb.eventList.beginDateHeader'),
          value: 'beginDate',
          class: 'text-uppercase',
          sort: (a, b) => {
            const date1 = parse(a, 'dd/MM/yy', new Date())
            const date2 = parse(b, 'dd/MM/yy', new Date())

            return date1 - date2
          }
        },
        {
          text: this.$t('mybb.eventList.endDateHeader'),
          value: 'endDate',
          class: 'text-uppercase',
          sort: (a, b) => {
            const date1 = parse(a, 'dd/MM/yy', new Date())
            const date2 = parse(b, 'dd/MM/yy', new Date())

            return date1 - date2
          }
        },
        { text: this.$t('mybb.eventList.managerHeader'), value: 'manager', class: 'text-uppercase' },
        { text: this.$t('mybb.eventList.franchiseHeader'), value: 'franchises', class: 'text-uppercase' },
        {
          text: this.$t('mybb.eventList.guestHeader'),
          value: 'guest',
          class: 'text-uppercase',
          sort: (a, b) => {
            return a.size - b.size
          }
        },
        {
          text: this.$t('mybb.eventList.statusHeader'),
          value: 'status',
          class: 'text-uppercase',
          sort: (a, b) => {
            return a.text.localeCompare(b.text)
          }
        },
        { text: '', value: 'redirect', align: 'center' }
      ]
    },
    items() {
      if (!this.gatherings) return null
      const events = this.gatherings.map(event => {
        const status = this.getLabelStatus(event.status)
        const franchises = this.getLabelfranchises(event.gatheringBusinessUnits)
        const manager = this.getManagers(event.users)
        const guest = this.getGuests(event.participants)
        const nature = this.getLabelGatheringFormat(event.isPhysical, event.isVirtual)

        return {
          beginDate: dateFormat(new Date(event.beginDate), 'dd/MM/yy'),
          endDate: dateFormat(new Date(event.endDate), 'dd/MM/yy'),
          name: event.name,
          nature,
          order: event.beginDate,
          status,
          franchises,
          manager,
          guest,
          type: this.$t(`mybb.global.eventType.${event.gatheringType}`),
          uuid: event.gatheringUuid
        }
      })

      for (const virtualSession of this.virtualSessions || []) {
        events.push({
          beginDate: dateFormat(new Date(virtualSession.beginDate), 'dd/MM/yy'),
          endDate: dateFormat(new Date(virtualSession.endDate), 'dd/MM/yy'),
          name: virtualSession.name,
          nature: this.$t('mybb.global.eventNature.VIRTUAL'),
          order: virtualSession.beginDate,
          status: this.getLabelStatus(virtualSession.status),
          franchises: this.getLabelfranchises([virtualSession]),
          manager: {
            cp: `${virtualSession.cp.lastName} ${virtualSession.cp.firstName}`,
            roc: `${virtualSession.roc.lastName} ${virtualSession.roc.firstName}`
          },
          type: this.$t('mybb.global.eventType.virtualSession'),
          uuid: virtualSession.virtualSessionUuid,
          eventType: 'VIRTUAL_SESSION',
          guest: this.getVirtualSessionGuests(virtualSession)
        })
      }

      return _sortBy(events, ['order']).reverse()
    }
  },

  apollo: {
    gatherings: {
      query: GET_MYBB_GATHERINGS,
      variables: { limit: 1000 }
    },
    virtualSessions: {
      query: LIST_VIRTUAL_SESSION_ITEM
    }
  },

  methods: {
    getLabelStatus(status) {
      // @see HCPEvent.vue if you need to make any change here
      const defineColor = key => {
        switch (key) {
          case this.$const.gatheringStatus.DRAFT:
            return 'gathering-status-draft'
          case this.$const.gatheringStatus.CORRECTION:
            return 'gathering-status-correction'
          case this.$const.gatheringStatus.PUBLISHED:
            return 'gathering-status-published'
          case this.$const.gatheringStatus.PUBLISHED_FRONT:
            return 'gathering-status-published-front'
          case this.$const.gatheringStatus.TO_PUBLISH:
            return 'gathering-status-to-publish'
          case this.$const.gatheringStatus.TO_VALIDATE:
            return 'gathering-status-to-validate'
          case this.$const.gatheringStatus.WINDED_UP:
          case this.$const.gatheringStatus.CLOSED:
            return 'error'
          default:
            return 'grey'
        }
      }
      return {
        text: status,
        color: defineColor(status)
      }
    },
    getLabelfranchises(franchises) {
      return franchises.map(franchise => {
        const text = this.$t(`mybb.supportTeamStep.${franchise.businessUnit.label.toLowerCase()}.label`)
        const defineColor = key => {
          switch (key) {
            case this.$const.businessUnitExternalId.SMA:
              return 'franchises-SMA'
            case this.$const.businessUnitExternalId.BBU:
              return 'franchises-BBU'
            case this.$const.businessUnitExternalId.AD:
              return 'franchises-AD'
            case this.$const.businessUnitExternalId.MS:
              return 'franchises-MS'
            default:
              return 'grey'
          }
        }
        return {
          text,
          color: defineColor(text)
        }
      })
    },
    getLabelGatheringFormat(isPhysical, isVirtual) {
      if (isPhysical && !isVirtual) {
        return this.$t(`gatheringFormat.PHYSICAL`)
      }

      if (!isPhysical && isVirtual) {
        return this.$t(`gatheringFormat.VIRTUAL`)
      }

      return this.$t(`gatheringFormat.HYBRID`)
    },
    getManagers(users) {
      const managers = {
        roc: null,
        mci: null
      }
      users.forEach(element => {
        if (!element.isBackup && element.gatheringUserType === this.$const.userType.MCI)
          managers.mci = element.user.firstName + ' ' + element.user.lastName
        if (!element.isBackup && element.gatheringUserType === this.$const.userType.ROC)
          managers.roc = element.user.firstName + ' ' + element.user.lastName
      })
      return managers
    },
    getGuests(guests) {
      const guestsList = {
        size: guests.length,
        confirmed: 0,
        refused: 0,
        notReplied: 0
      }

      guests.forEach(element => {
        if (element.invitationStatus === this.$const.invitationStatus.confirmed) guestsList.confirmed += 1
        else if (element.invitationStatus === this.$const.invitationStatus.refused) guestsList.refused += 1
        else guestsList.notReplied += 1
      })
      return guestsList
    },
    getVirtualSessionGuests(virtualSession) {
      const { ABSENT, PRESENT, UNKNOWN } = this.$const.virtualSession.presence

      const participants = virtualSession.virtualSessionParticipants || []

      const guests = {
        size: participants.length,
        confirmed: 0,
        refused: 0,
        notReplied: 0
      }

      for (const participant of participants) {
        switch (participant.presence) {
          case ABSENT:
            guests.refused++
            break

          case PRESENT:
            guests.confirmed++
            break

          case UNKNOWN:
            guests.notReplied++
            break
        }
      }

      return guests
    }
  }
}
</script>

<style lang="scss">
.EventList {
  .DataTable {
    border-radius: 8px;
  }

  .v-data-table {
    thead th {
      font-size: 12px;
      font-weight: 900;
      color: var(--v-mybb-primary-base) !important;
    }

    .v-data-table-header th {
      white-space: nowrap;
    }

    tbody td {
      color: var(--v-mybb-primary-base);
    }
  }

  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }
}

.infoIcon {
  margin-bottom: 2px;
}

.statusChip {
  min-width: 130px;
  justify-content: center;
}

.Guests {
  width: 75%;
}
</style>
