<template>
  <modal v-model="modal" :title="t('title')">
    <mybb-text class="d-block mb-2">{{ t('text') }}</mybb-text>
    <mybb-text class="d-block mb-2">{{ t('subText') }}</mybb-text>
    <mybb-text weight="bold" class="d-block mb-2">{{ t('highlight') }}</mybb-text>

    <v-chip-group v-model="businessUnitUuids" class="mb-2" dark>
      <v-chip
        v-for="bu of businessUnits"
        :key="bu.businessUnitUuid"
        :value="bu.businessUnitUuid"
        :color="businessUnitUuids.includes(bu.businessUnitUuid) ? bu.color : '#9a9fa4'"
        :close="businessUnitUuids.includes(bu.businessUnitUuid)"
        class="white--text"
        @click:close="removeBusinessUnit(bu.businessUnitUuid)"
        dark
      >
        {{ bu.label }}
      </v-chip>
    </v-chip-group>

    <div v-for="businessUnit of localFilters" :key="businessUnit.businessUnitUuid" class="mb-4">
      <hcp-business-unit-filter-group
        :business-unit-uuid="businessUnit.businessUnitUuid"
        :filter="businessUnit"
        :zones="zones"
        @input="setBusinessUnitPartial"
      />
    </div>

    <div class="mt-10 text-center">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
        {{ t('cancel') }}
      </mybb-btn>
      <mybb-btn :disabled="localFilters.length <= 0" @click="submit">
        {{ t('submit') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import MYBB from '@/const/my-bb'
import { GET_ALL_ZONES } from '@/graphql/Zone/GetZones'

import Modal from '../Modal'
import HcpBusinessUnitFilterGroup from './HcpBusinessUnitFilterGroup'

export default {
  name: 'HcpFilterModal',
  components: { Modal, HcpBusinessUnitFilterGroup },
  model: {
    prop: 'filters'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object,
      required: true,
      validator(prop) {
        return prop && Array.isArray(prop.businessUnits)
      }
    }
  },
  data() {
    return {
      localFilters: []
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('modal', value)
      }
    },
    businessUnitUuids: {
      get() {
        return this.businessUnits
          .filter(businessUnit => this.localFilters.some(bu => bu.businessUnitUuid === businessUnit.businessUnitUuid))
          .map(bu => bu.businessUnitUuid)
      },
      set(businessUnitUuid) {
        const alreadyDefined = this.localFilters.some(bu => bu.businessUnitUuid === businessUnitUuid)

        if (alreadyDefined) return this.removeBusinessUnit(businessUnitUuid)

        this.setBusinessUnitPartial({ businessUnitUuid })
      }
    },
    businessUnits() {
      return this.$get(this.$store.state, 'bus', [])
        .map(bu => ({ ...bu, color: MYBB.bu[bu.label].color }))
        .sort((bu1, bu2) => MYBB.bu[bu1.label].order - MYBB.bu[bu2.label].order)
    }
  },
  apollo: {
    zones: {
      query: GET_ALL_ZONES
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.participants.add-list.filters.${key}`, params)
    },
    setBusinessUnitPartial(partial) {
      const filters = Array.from(this.localFilters)
      const index = filters.findIndex(bu => bu.businessUnitUuid === partial.businessUnitUuid)

      if (index >= 0) {
        Object.assign(filters[index], partial)
      } else {
        filters.push(partial)
      }

      filters.sort((f1, f2) => {
        const index1 = this.businessUnits.findIndex(bu => bu.businessUnitUuid === f1.businessUnitUuid)
        const index2 = this.businessUnits.findIndex(bu => bu.businessUnitUuid === f2.businessUnitUuid)

        return index1 - index2
      })

      this.localFilters = filters
    },
    removeBusinessUnit(businessUnitUuid) {
      this.localFilters = this.localFilters.filter(bu => bu.businessUnitUuid !== businessUnitUuid)
    },
    getBusinessUnit(businessUnitUuid) {
      return this.businessUnits.find(bu => bu.businessUnitUuid === businessUnitUuid)
    },
    name(businessUnitUuid) {
      const businessUnit = this.getBusinessUnit(businessUnitUuid)

      return businessUnit ? businessUnit.label : '- -'
    },
    criteriasForBu(businessUnitUuid) {
      const businessUnit = this.getBusinessUnit(businessUnitUuid)

      return businessUnit ? Object.keys(this.$const.targetBusinessUnitWithLabel[businessUnit.label]) : []
    },
    criteriaLabel(businessUnitUuid, criteria) {
      const businessUnit = this.getBusinessUnit(businessUnitUuid)

      return businessUnit ? this.$const.targetBusinessUnitWithLabel[businessUnit.label][criteria] : ''
    },
    submit() {
      this.$emit('input', { businessUnits: this.localFilters })

      this.modal = false
    }
  },
  watch: {
    modal(value, old) {
      if (old || !value) return

      this.localFilters = Array.from(this.filters.businessUnits)
    }
  }
}
</script>
