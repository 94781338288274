import gql from 'graphql-tag'

import { VSP_LIGHT } from './virtualSessionParticipant'

export const FULL_VIRTUAL_SESSION = gql`
  fragment FullVirtualSession on VirtualSession {
    virtualSessionUuid
    type
    status
    name
    description
    beginDate
    endDate
    startHour
    endHour
    oseusEventId
    oseusMeetingId
    businessUnitUuid
    rocUuid
    cpUuid
  }
`

export const FULL_VIRTUAL_SESSION_WITH_TEAM = gql`
  fragment FullVirtualSessionWithTeam on VirtualSession {
    ...FullVirtualSession
    businessUnit {
      businessUnitUuid
      label
      externalId
    }
    roc {
      firstName
      lastName
    }
    cp {
      firstName
      lastName
    }
  }
  ${FULL_VIRTUAL_SESSION}
`

export const LIGHT_VIRTUAL_SESSION = gql`
  fragment LightVirtualSession on VirtualSession {
    virtualSessionUuid
    type
    status
    name
    beginDate
    endDate
    startHour
    endHour
  }
`

export const VIRTUAL_SESSION_LIST_ITEM = gql`
  fragment VirtualSessionListItem on VirtualSession {
    ...LightVirtualSession
    businessUnit {
      businessUnitUuid
      label
    }
    virtualSessionParticipants {
      ...VirtualSessionParticipantLight
    }
  }
  ${LIGHT_VIRTUAL_SESSION}
  ${VSP_LIGHT}
`
