<template>
  <v-navigation-drawer :value="open" @input="handleInput" absolute temporary width="347" class="FilterDrawer">
    <div>
      <div class="d-flex ma-6">
        <mybb-text size="20" weight="bold">{{ $t('mybb.filters.drawerTitle') }}</mybb-text>
        <v-spacer />
        <mybb-btn
          icon
          inner-icon="mdi-chevron-left"
          color="mybb-grey"
          height="28"
          width="28"
          class="text-right"
          @click="$emit('close')"
        />
      </div>

      <div v-if="!items">
        <div class="FilterDrawer-group">
          <mybb-text v-if="label" class="FilterDrawer-title mybb-grey-lighten1--text pa-1">{{ label }}</mybb-text>
          <div v-for="filter in filters" class="FilterDrawer-checkboxContainer" :key="filter.key" link>
            <v-checkbox v-model="filter.value" color="mybb-primary-lighten1" :label="filter.label" dense hide-details />
          </div>
        </div>
      </div>

      <div v-else>
        <div v-for="group in items" :key="group.key" class="FilterDrawer-group">
          <mybb-text v-if="label" class="FilterDrawer-title mybb-grey-lighten1--text pa-1">{{ group.label }}</mybb-text>
          <div v-for="filter in group.filters" class="FilterDrawer-checkboxContainer" :key="filter.key" link>
            <v-checkbox
              v-model="filters[group.key]"
              :value="filter.value"
              color="mybb-primary-lighten1"
              :label="filter.label"
              dense
              hide-details
              mutliple
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="text-center FooterDrawer-Resetcontainer">
        <mybb-text v-if="isFilterSelected" class="FilterDrawer-resetBtn" @click="resetFilters">
          {{ $t('mybb.filters.reset') }}
        </mybb-text>
      </div>
      <div class="FooterDrawer-btn text-center mt-2">
        <mybb-btn @click="applyFilters" color="mybb-success" inner-icon="mdi-check">
          {{ $t('mybb.filters.applyButon') }}
        </mybb-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'FilterDrawer',
  props: {
    value: {
      type: [Array, Object],
      required: true,
      validator: v =>
        (Array.isArray(v) && v.every(item => typeof item === 'object' && item.hasOwnProperty('key'))) ||
        typeof v === 'object'
    },
    open: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: false
    }
  },
  data() {
    let filters

    if (Array.isArray(this.items)) {
      filters = {}

      for (const group of this.items) {
        if (!this.value) {
          filters[group.key] = []
        } else {
          filters = { ...filters, [group.key]: this.value[group.key] }
        }
      }
    } else {
      filters = JSON.parse(JSON.stringify(this.value))
    }

    return {
      filters
    }
  },
  computed: {
    isFilterSelected() {
      let ret = false
      if (!this.items) {
        this.filters.forEach(element => {
          if (element.value) {
            ret = true
          }
        })
      } else {
        this.items.forEach(element => {
          if (this.filters[element.key].length > 0) {
            ret = true
          }
        })
      }
      return ret
    }
  },
  methods: {
    applyFilters() {
      this.$emit('input', JSON.parse(JSON.stringify(this.filters)))
      this.$emit('close')
    },
    resetFilters() {
      if (Array.isArray(this.items)) {
        this.filters = {}
        for (const group of this.items) {
          this.filters = { ...this.filters, [group.key]: [] }
        }
      } else {
        this.filters.forEach(element => {
          element.value = false
        })
      }
    },
    handleInput(value) {
      if (!value) {
        this.$emit('close')
        this.filters = JSON.parse(JSON.stringify(this.value))
      }
    }
  }
}
</script>

<style lang="scss">
.FilterDrawer {
  // screen height without header
  max-height: calc(100vh - 50px);
  overflow-y: auto;

  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .FilterDrawer-checkboxContainer {
    &:not(:last-of-type) {
      margin-bottom: 4px;
    }

    & .v-label {
      color: var(--v-mybb-text-base) !important;
    }

    & .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }

    & .v-input__control {
      .v-input__slot {
        min-height: 32px !important;
      }
    }
  }
}

.FilterDrawer-group {
  position: relative;
  margin: 0 25px 25px 25px;
  width: 297px;
  padding: 16px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--v-mybb-grey-lighten1);
}

.FilterDrawer-title {
  position: absolute;
  top: -12px;
  left: 15px;
  background: #fff;
}

.FooterDrawer-btn {
  margin-bottom: 40px;
}

.FilterDrawer-resetBtn:hover {
  text-decoration: underline;
  cursor: pointer;
}

.FooterDrawer-Resetcontainer {
  height: 21px;
}
</style>
