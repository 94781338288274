<template>
  <modal v-model="modal" :title="t('title')">
    <mybb-radios v-model="type" :label="t('type.label')" :items="typeOptions" />

    <mybb-select
      v-if="hasModel"
      v-model="model"
      :label="t('model.label')"
      :items="modelOptions"
      :loading="$apollo.queries.failingParticipants.loading"
      class="mt-4"
    />

    <v-row v-if="error" class="mt-2" align="center">
      <v-col>
        <mybb-text color="error">
          {{ error }}
        </mybb-text>

        <mybb-btn
          v-if="failingParticipants.length !== selectedParticipants.length"
          :ripple="false"
          color="mybb-primary"
          class="px-0 py-0 ml-2"
          text
          plain
          @click="showDetails = !showDetails"
        >
          <mybb-text weight="bold" class="text-decoration-underline">
            {{ t('error.see-details') }}
          </mybb-text>
        </mybb-btn>
      </v-col>
    </v-row>

    <mybb-text v-if="showDetails">
      {{ failingParticipantsList }}
    </mybb-text>

    <mybb-text v-if="warning" color="mybb-warning" weight="bold" class="mt-4">
      <v-icon color="mybb-warning" size="20">mdi-alert</v-icon>
      {{ warning }}
    </mybb-text>

    <mybb-text-field
      v-if="isEmailTemplateModel"
      v-model="emailTemplateObject"
      :label="t('freeText.emailTemplateModel.objectLabel')"
      class="mt-4"
    />

    <mybb-textarea
      v-if="isEmailTemplateModel"
      v-model="emailTemplateText"
      :label="t('freeText.emailTemplateModel.contentLabel')"
      :hide-details="false"
      :counter="4000"
      :maxlength="4000"
      class="mt-4"
    />

    <mybb-textarea
      v-if="isCongress && isRecapitulationModel"
      v-model="badgeText"
      :label="t('freeText.type.badge')"
      :hide-details="false"
      :counter="4000"
      :maxlength="4000"
      class="mt-4"
    />

    <mybb-textarea
      v-if="isCongress && isRecapitulationModel"
      v-model="presenceCertificateText"
      :label="t('freeText.type.presence-certificate')"
      :hide-details="false"
      :counter="4000"
      :maxlength="4000"
      class="mt-4"
    />

    <mybb-textarea
      v-if="isRecapitulationModel || isRecapitulationStaffModel"
      v-model="contactsText"
      :label="t('freeText.type.contacts')"
      :hide-details="false"
      :counter="4000"
      :maxlength="4000"
      class="mt-4"
    />

    <mybb-textarea
      v-if="isRecapitulationDigitalModel"
      v-model="recapDigitalText"
      :label="t('freeText.type.digitalRecap')"
      :hide-details="false"
      :counter="4000"
      :maxlength="4000"
      class="mt-4"
    />

    <div class="mt-10 text-center">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
        {{ t('cancel') }}
      </mybb-btn>

      <mybb-btn :loading="loading" :disabled="!canSubmit" color="mybb-success" @click="submit">
        {{ t('confirm') }}
      </mybb-btn>
    </div>

    <!-- Double confirmation modal if some HCPs doesn't match the criterias -->
    <modal v-model="doubleConfirmationModal" :title="t('double-confirmation.title')">
      <mybb-text>{{ t('double-confirmation.text') }}</mybb-text>
      <div class="d-flex justify-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="closeModals">
          {{ t('double-confirmation.cancel') }}
        </mybb-btn>

        <mybb-btn color="mybb-success" @click="sendMails">
          {{ t('double-confirmation.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </modal>
</template>

<script>
import axios from 'axios'
import _omit from 'lodash/omit'

import { CHECK_PARTICIPANTS_EMAIL_MODEL_CRITERIAS } from '@/graphql/Participant'
import { MAIL_FREE_TEXTS, SET_MAIL_FREE_TEXTS } from '@/graphql/MailFreeText'

import Modal from '../Modal'

export default {
  name: 'ParticipantEmailModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    selectedParticipants: {
      type: Array,
      required: true
    },
    gathering: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      type: null,
      model: null,
      loading: false,
      failingParticipants: [],
      showDetails: false,
      doubleConfirmationModal: false,
      mailFreeTexts: []
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.$nextTick(() => {
            this.type = null
            this.model = null
            this.loading = false

            this.$apollo.queries.mailFreeTexts.refetch()
          })
        }
      }
    },
    badgeText: {
      get() {
        const text = this.mailFreeTexts.find(text => text.fieldName === this.$const.mailFreeText.type.BADGE)

        return text ? text.value : null
      },
      set(value) {
        const mailFreeTexts = Array.from(this.mailFreeTexts)
        const index = mailFreeTexts.findIndex(text => text.fieldName === this.$const.mailFreeText.type.BADGE)

        mailFreeTexts[index].value = value ? value.slice(0, 4000) : value

        this.mailFreeTexts = mailFreeTexts
      }
    },
    presenceCertificateText: {
      get() {
        const text = this.mailFreeTexts.find(
          text => text.fieldName === this.$const.mailFreeText.type.PRESENCE_CERTIFICATE
        )

        return text ? text.value : null
      },
      set(value) {
        const mailFreeTexts = Array.from(this.mailFreeTexts)
        const index = mailFreeTexts.findIndex(
          text => text.fieldName === this.$const.mailFreeText.type.PRESENCE_CERTIFICATE
        )

        mailFreeTexts[index].value = value ? value.slice(0, 4000) : value

        this.mailFreeTexts = mailFreeTexts
      }
    },
    emailTemplateObject: {
      get() {
        const text = this.mailFreeTexts.find(text => text.fieldName === this.$const.mailFreeText.type.EMAIL_OBJECT)

        return text ? text.value : null
      },
      set(value) {
        const mailFreeTexts = Array.from(this.mailFreeTexts)

        const index = mailFreeTexts.findIndex(text => text.fieldName === this.$const.mailFreeText.type.EMAIL_OBJECT)

        mailFreeTexts[index].value = value ? value.slice(0, 4000) : value

        this.mailFreeTexts = mailFreeTexts
      }
    },
    emailTemplateText: {
      get() {
        const text = this.mailFreeTexts.find(text => text.fieldName === this.$const.mailFreeText.type.EMAIL_CONTENT)

        return text ? text.value : null
      },
      set(value) {
        const mailFreeTexts = Array.from(this.mailFreeTexts)

        const index = mailFreeTexts.findIndex(text => text.fieldName === this.$const.mailFreeText.type.EMAIL_CONTENT)

        mailFreeTexts[index].value = value ? value.slice(0, 4000) : value

        this.mailFreeTexts = mailFreeTexts
      }
    },
    contactsText: {
      get() {
        const text = this.mailFreeTexts.find(text => text.fieldName === this.$const.mailFreeText.type.CONTACTS)

        return text ? text.value : null
      },
      set(value) {
        const mailFreeTexts = Array.from(this.mailFreeTexts)
        const index = mailFreeTexts.findIndex(text => text.fieldName === this.$const.mailFreeText.type.CONTACTS)

        mailFreeTexts[index].value = value ? value.slice(0, 4000) : value
        this.mailFreeTexts = mailFreeTexts
      }
    },
    recapDigitalText: {
      get() {
        const text = this.mailFreeTexts.find(
          text => text.fieldName === this.$const.mailFreeText.type.RECAPITULATION_DIGITAL
        )
        return text ? text.value : null
      },
      set(value) {
        const mailFreeTexts = Array.from(this.mailFreeTexts)
        const index = mailFreeTexts.findIndex(
          text => text.fieldName === this.$const.mailFreeText.type.RECAPITULATION_DIGITAL
        )

        mailFreeTexts[index].value = value ? value.slice(0, 4000) : value

        this.mailFreeTexts = mailFreeTexts
      }
    },
    isCongress() {
      return Boolean(this.$get(this.gathering, 'congress'))
    },
    isVirtual() {
      return this.$get(this.gathering, 'isVirtual', false)
    },
    isEmailTemplateModel() {
      return this.model === this.$const.participantEmailModel.EMAIL_TEMPLATE
    },
    isRecapitulationModel() {
      return this.model === this.$const.participantEmailModel.RECAPITULATION
    },
    isRecapitulationDigitalModel() {
      return this.model === this.$const.participantEmailModel.RECAPITULATION_DIGITAL
    },
    isRecapitulationStaffModel() {
      return this.model === this.$const.participantEmailModel.RECAPITULATION_STAFF
    },
    hasModel() {
      return this.type && this.type !== this.$const.participantEmailType.CUSTOM
    },
    typeOptions() {
      return Object.values(this.$const.participantEmailType).map(value => ({
        value,
        label: this.t(`type.option.${value}`)
      }))
    },
    warning() {
      if (!this.type) return null

      if (this.isFullyOnError) return null

      return this.t(`warning.${this.type}`)
    },
    error() {
      if (!this.type || !this.model) return null
      if (this.failingParticipants.length <= 0) return null

      return this.isFullyOnError ? this.t('error.full') : this.t('error.partial')
    },
    isFullyOnError() {
      return this.failingParticipants.length === this.selectedParticipants.length
    },
    modelOptions() {
      const {
        ASKING_CANCEL_PARTICIPATION,
        EMAIL_TEMPLATE,
        SUBSCRIPTION,
        SUBSCRIPTION_ORATOR,
        TRANSPORT_REMINDER,
        TRANSPORT_PROPOSAL,
        RECAPITULATION,
        RECAPITULATION_STAFF,
        BEGIN_SOON,
        INVITATION_STAFF,
        // REGISTRATION_REMINDER,
        PAYED_EXPENSE_NOTE,
        PRESENCE_ATTESTATION,
        EXPENSE_NOTE_REVIVAL_HCP,
        RECAPITULATION_DIGITAL,
        PRESENCE_ATTESTATION_DONE
      } = this.$const.participantEmailModel

      const items = [
        ASKING_CANCEL_PARTICIPATION,
        EMAIL_TEMPLATE,
        SUBSCRIPTION,
        SUBSCRIPTION_ORATOR,
        TRANSPORT_REMINDER,
        TRANSPORT_PROPOSAL,
        RECAPITULATION,
        RECAPITULATION_DIGITAL,
        RECAPITULATION_STAFF,
        BEGIN_SOON,
        INVITATION_STAFF,
        // REGISTRATION_REMINDER,
        PRESENCE_ATTESTATION,
        PRESENCE_ATTESTATION_DONE,
        PAYED_EXPENSE_NOTE,
        EXPENSE_NOTE_REVIVAL_HCP,
        PRESENCE_ATTESTATION_DONE
      ]

      if (!this.isCongress || (this.isCongress && !this.isVirtual)) {
        const index = items.indexOf(BEGIN_SOON)
        items.splice(index, 1)
      }

      if (!this.isCongress) {
        const index = items.indexOf(PRESENCE_ATTESTATION)
        items.splice(index, 1)
      }

      if (
        ![this.$const.gatheringStatus.PUBLISHED_FRONT, this.$const.gatheringStatus.PUBLISHED].includes(
          this.gathering.status
        )
      ) {
        const index = items.indexOf(INVITATION_STAFF)
        items.splice(index, 1)
      }

      if (!this.isVirtual) {
        const index = items.indexOf(RECAPITULATION_DIGITAL)
        items.splice(index, 1)
      }

      return items.map(value => ({
        value,
        text: this.t(`model.option.${value}`)
      }))
    },
    canSubmit() {
      const find = this.mailFreeTexts.find(text => text.value !== null || text.value === '')

      const findingEmailTemplateObjectValue = this.mailFreeTexts.find(
        text => text.fieldName === this.$const.mailFreeText.type.EMAIL_OBJECT
      )
      const findingEmailTemplateContentValue = this.mailFreeTexts.find(
        text => text.fieldName === this.$const.mailFreeText.type.EMAIL_CONTENT
      )

      const findingContactField = this.mailFreeTexts.find(
        text => text.fieldName === this.$const.mailFreeText.type.CONTACTS && text.value
      )

      if (!this.type) {
        return false
      }

      if (this.hasModel && !this.model) {
        return false
      }

      if (this.isFullyOnError) {
        return false
      }

      if (this.isRecapitulationDigitalModel && !find) {
        return false
      }

      if (
        this.isCongress &&
        this.isRecapitulationModel &&
        this.mailFreeTexts.some(
          text =>
            [
              this.$const.mailFreeText.type.BADGE,
              this.$const.mailFreeText.type.PRESENCE_CERTIFICATE,
              this.$const.mailFreeText.type.CONTACTS
            ].includes(text.fieldName) && !text.value
        )
      ) {
        return false
      }

      if (this.isRecapitulationModel && !findingContactField && !this.isCongress) {
        return false
      }

      if (this.isRecapitulationStaffModel && !findingContactField) {
        return false
      }

      if (
        this.isEmailTemplateModel &&
        (!findingEmailTemplateObjectValue.value || !findingEmailTemplateContentValue.value)
      ) {
        return false
      }

      return true
    },
    failingParticipantsList() {
      return this.failingParticipants.map(({ lastName, firstName }) => `${lastName} ${firstName}`).join(' ; ')
    }
  },
  apollo: {
    failingParticipants: {
      query: CHECK_PARTICIPANTS_EMAIL_MODEL_CRITERIAS,
      variables() {
        return {
          participantUuids: this.selectedParticipants.map(participant => participant.participantUuid),
          model: this.model
        }
      },
      update({ checkParticipantEmailModelCriteria }) {
        return checkParticipantEmailModelCriteria
      },
      skip() {
        return !this.hasModel || this.selectedParticipants.length <= 0 || !this.model
      }
    },
    mailFreeTexts: {
      query: MAIL_FREE_TEXTS,
      variables() {
        return {
          gatheringUuid: this.gathering.gatheringUuid
        }
      },
      update({ mailFreeTexts }) {
        return mailFreeTexts.map(freeText => _omit(freeText, ['__typename']))
      },
      result() {
        let _mailFreeTexts = Array.from(this.mailFreeTexts)
        for (const fieldName of Object.values(this.$const.mailFreeText.type)) {
          const exists = _mailFreeTexts.some(mailFreeText => mailFreeText.fieldName === fieldName)

          if (!exists) {
            _mailFreeTexts.push({
              gatheringUuid: this.gathering.gatheringUuid,
              value: null,
              fieldName
            })
          }
        }

        if (!this.isCongress) {
          _mailFreeTexts = _mailFreeTexts.filter(
            freeText =>
              freeText.fieldName === this.$const.mailFreeText.type.CONTACTS ||
              freeText.fieldName === this.$const.mailFreeText.type.RECAPITULATION_DIGITAL ||
              freeText.fieldName === this.$const.mailFreeText.type.EMAIL_OBJECT ||
              freeText.fieldName === this.$const.mailFreeText.type.EMAIL_CONTENT
          )
        }
        this.mailFreeTexts = _mailFreeTexts
      },
      skip() {
        return !this.gathering || !this.modal
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.AttendeeList.footer.emailModal.${key}`, params)
    },
    async submit() {
      if (this.isFullyOnError) return

      if (this.type === this.$const.participantEmailType.FROM_MODEL) {
        this.doubleConfirmationModal = true
        return
      }

      this.sendMails()
    },
    async submitAction(participantUuids) {
      const { CUSTOM, FROM_MODEL, FROM_MODEL_TEST } = this.$const.participantEmailType

      switch (this.type) {
        case FROM_MODEL:
        case FROM_MODEL_TEST:
          await this.setFreeTextMails()
          return this.sendEmailModel(participantUuids, this.type === FROM_MODEL_TEST)
        case CUSTOM:
          return this.sendPersonalized(participantUuids)
      }
    },
    async sendEmailModel(participantUuids, isTest = false) {
      let freeTexts

      if (this.model === this.$const.participantEmailModel.EMAIL_TEMPLATE) {
        const findObject = this.mailFreeTexts.find(
          value => value.fieldName === this.$const.mailFreeText.type.EMAIL_OBJECT
        )
        const findContent = this.mailFreeTexts.find(
          value => value.fieldName === this.$const.mailFreeText.type.EMAIL_CONTENT
        )

        freeTexts = findObject && findContent ? [findObject, findContent] : null
        if (!freeTexts) return false
      }

      const response = await axios.post(
        `participant/sendMail`,
        { model: this.model, participantUuids, initiatorUuid: this.$store.state.myUser.userUuid, isTest, freeTexts },
        {
          headers: {
            'x-impersonificated-user': this.$store.state.impersonificatedUser
          }
        }
      )

      this.$bus.$emit('monitor', { bulkId: response.data.bulkId, label: this.t(`model.option.${this.model}`) })
    },
    sendPersonalized(participantUuids) {
      const job = axios.post(
        'participant/sendPersonalized',
        { participantUuids, initiatorUuid: this.$store.state.myUser.userUuid },
        {
          responseType: 'blob',
          headers: {
            'x-impersonificated-user': this.$store.state.impersonificatedUser
          }
        }
      )

      this.$bus.$emit('extract', {
        job,
        label: this.t(`export.label`)
      })
    },
    async setFreeTextMails() {
      if (
        !this.isRecapitulationModel &&
        !this.isEmailTemplateModel &&
        !this.isRecapitulationDigitalModel &&
        !this.isRecapitulationStaffModel
      )
        return

      return this.$apollo.mutate({
        mutation: SET_MAIL_FREE_TEXTS,
        variables: {
          freeTexts: this.mailFreeTexts
        }
      })
    },
    closeModals() {
      this.modal = false
      this.doubleConfirmationModal = false
    },
    async sendMails() {
      if (this.loading) return

      this.loading = true

      const participantUuids = (this.selectedParticipants || []).map(participant => participant.participantUuid)

      try {
        await this.submitAction(participantUuids)

        this.$emit('refresh')
        this.doubleConfirmationModal = false
        this.modal = false
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    type(value, old) {
      if (value !== old) {
        this.failingParticipants = []
        this.model = null
        this.showDetails = false
      }
    },
    model(value) {
      this.showDetails = false

      this.$apollo.queries.mailFreeTexts.refetch()
    }
  }
}
</script>

<style lang="scss" scoped>
.text-decoration-underline {
  text-decoration: underline;
}
</style>
