<template>
  <modal v-model="modal" :title="t(this.editedDocument ? 'titleEdit' : 'title')">
    <mybb-text-field v-model="name" :label="t('name')" class="mb-4" />

    <input-file v-model="file" accept=".jpg,.pdf,.png" class="mb-4" />

    <v-row>
      <v-col class="py-0" cols="6">
        <date-picker v-model="addedDate" :label="t('addedDate')" />
      </v-col>

      <v-col class="py-0" cols="6">
        <date-picker v-model="expirationDate" :label="t('expirationDate')" />
      </v-col>
    </v-row>

    <div class="text-center mt-10">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
        {{ t('cancel') }}
      </mybb-btn>

      <mybb-btn :loading="loading" :disabled="!canSave" color="mybb-success" @click="setDocument">
        {{ t('confirm') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import { SET_HCP_DOCUMENT } from '@/graphql/Hcp'

import Modal from '../Modal'
import InputFile from '../InputFile'
import DatePicker from '../ui/DatePicker'

export default {
  name: 'AddDocumentModal',
  components: { Modal, DatePicker, InputFile },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    editedDocument: {
      type: Object,
      validator(prop) {
        return (
          !prop ||
          (prop &&
            prop.hasOwnProperty('name') &&
            prop.hasOwnProperty('addedDate') &&
            prop.hasOwnProperty('file') &&
            prop.file.hasOwnProperty('fileUuid') &&
            prop.file.hasOwnProperty('name') &&
            prop.file.hasOwnProperty('content'))
        )
      }
    }
  },
  data() {
    return {
      loading: false,
      document: {
        name: null,
        addedDate: null,
        expirationDate: null,
        file: null
      }
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        this.$nextTick(() => {
          this.document = {
            name: null,
            addedDate: null,
            expirationDate: null,
            file: null
          }
        })
      }
    },
    name: {
      get() {
        return this.editedDocument && !this.document.name ? this.editedDocument.name : this.document.name
      },
      set(value) {
        this.document.name = value
      }
    },
    file: {
      get() {
        return this.editedDocument && !this.document.file ? this.editedDocument.file : this.document.file
      },
      set(value) {
        this.document.file = value
      }
    },
    addedDate: {
      get() {
        return (
          (this.editedDocument && !this.document.addedDate ? this.editedDocument.addedDate : this.document.addedDate) ||
          new Date().toISOString()
        )
      },
      set(value) {
        this.document.addedDate = value
      }
    },
    expirationDate: {
      get() {
        return this.editedDocument && !this.document.expirationDate
          ? this.editedDocument.expirationDate
          : this.document.expirationDate
      },
      set(value) {
        this.document.expirationDate = value
      }
    },
    canSave() {
      return this.name && this.file && this.addedDate
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.documentsPanel.addDocumentModal.${key}`, params)
    },
    async setDocument() {
      if (this.loading) return
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: SET_HCP_DOCUMENT,
          variables: {
            healthCareProfessionalUuid: this.$route.params.hcpUuid,
            gatheringUuid: this.$route.params.gatheringUuid,
            document: {
              healthCareProfessionalFileUuid: this.$get(this.editedDocument, 'healthCareProfessionalFileUuid', null),
              name: this.name,
              file: this.file,
              addedDate: this.addedDate ? new Date(this.addedDate) : this.addedDate,
              expirationDate: this.expirationDate ? new Date(this.expirationDate) : this.expirationDate
            },
            fileUuid: this.$get(this.editedDocument, 'file.fileUuid', null)
          }
        })

        this.modal = false
        this.$emit('refresh')
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
