<template>
  <side-panel :cypress="$cy.gathering.sidePanel.default" :title="t('title')">
    <ul class="pa-0">
      <li v-for="info in informations" :key="info.key" class="GatheringInformations-listElement">
        <mybb-text weight="semi-bold" class="mybb-grey-lighten1--text GatheringInformations-listElement-header">
          {{ info.label }}
        </mybb-text>

        <mybb-text v-if="!info.chip" v-cy="$cy.gathering.sidePanel.field(info.key)" weight="semi-bold">
          {{ info.value }}
        </mybb-text>
        <span v-else>
          <v-chip v-for="bu in info.value" :key="bu.key" class="ma-1" :color="bu.color" dark>
            <span v-cy="$cy.gathering.sidePanel.field(bu.label)">{{ bu.label }}</span>
          </v-chip>
        </span>
      </li>
    </ul>
  </side-panel>
</template>

<script>
import dateFormat from 'date-fns/format'

import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'
import MYBB_CONST from '@/const/my-bb'

import SidePanel from './SidePanel'

export default {
  name: 'GatheringInformations',
  components: { SidePanel },
  props: {
    gatheringUuid: {
      type: String,
      required: true
    }
  },
  computed: {
    informations() {
      if (!this.gathering) return []

      const {
        name,
        congress,
        isVirtual,
        isPhysical,
        beginDate,
        endDate,
        city,
        location,
        users,
        eventSchedule,
        gatheringBusinessUnits
      } = this.gathering

      let startHour
      let endHour

      if (Array.isArray(eventSchedule)) {
        ;[startHour, endHour] = eventSchedule
      }

      const inChargeRoc = (users || []).find(
        user => !user.businessUnitUuid && user.gatheringUserType === this.$const.userType.ROC
      )
      const inChargeMci = (users || []).find(
        user => !user.businessUnitUuid && user.gatheringUserType === this.$const.userType.MCI
      )
      const _beginDate = new Date(beginDate)
      const _endDate = new Date(endDate)
      const nature =
        isPhysical && !isVirtual
          ? this.tg('eventNature.PHYSICAL')
          : !isPhysical && isVirtual
          ? this.tg('eventNature.VIRTUAL')
          : this.tg('eventNature.HYBRID')

      return [
        {
          key: 'name',
          label: this.t('name'),
          value: name
        },
        {
          key: 'type',
          label: this.t('type'),
          value: congress ? this.tg('eventType.congress') : this.tg('eventType.biogenEvent')
        },
        {
          key: 'nature',
          label: this.t('nature'),
          value: nature
        },
        {
          key: 'beginDate',
          label: this.t('beginDate'),
          value: startHour ? `${dateFormat(_beginDate, 'dd/MM/yy')} - ${startHour}` : dateFormat(_beginDate, 'dd/MM/yy')
        },
        {
          key: 'endDate',
          label: this.t('endDate'),
          value: endHour ? `${dateFormat(_endDate, 'dd/MM/yy')} - ${endHour}` : dateFormat(_endDate, 'dd/MM/yy')
        },
        {
          key: 'place',
          label: this.t('place'),
          value: city || location || '- -'
        },
        {
          key: 'roc',
          label: this.t('roc'),
          value: inChargeRoc ? `${inChargeRoc.user.firstName} ${inChargeRoc.user.lastName}` : '- -'
        },
        {
          key: 'mci',
          label: this.t('mci'),
          value: inChargeMci ? `${inChargeMci.user.firstName} ${inChargeMci.user.lastName}` : '- -'
        },
        {
          key: 'mainBusinessUnits',
          label: this.t('mainBusinessUnits'),
          value: this.buildMainBusinessUnit(gatheringBusinessUnits)
            ? [this.buildMainBusinessUnit(gatheringBusinessUnits)]
            : '- -',
          chip: !!this.buildMainBusinessUnit(gatheringBusinessUnits)
        },
        {
          key: 'otherBusinessUnits',
          label: this.t('otherBusinessUnits'),
          value:
            this.buildOtherBusinessUnits(gatheringBusinessUnits).length > 0
              ? this.buildOtherBusinessUnits(gatheringBusinessUnits)
              : '- -',
          chip: this.buildOtherBusinessUnits(gatheringBusinessUnits).length > 0
        }
      ]
    }
  },
  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.gatheringUuid
        }
      },
      skip() {
        return !this.gatheringUuid
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.gatheringSidePanel.${key}`, params)
    },
    tg(key, params) {
      return this.$t(`mybb.global.${key}`, params)
    },
    buildMainBusinessUnit(gatheringBusinessUnits) {
      if (!Array.isArray(gatheringBusinessUnits) || !this.gathering) return null

      const mainBusinessUnit = gatheringBusinessUnits.find(
        bu => bu.businessUnitUuid === this.gathering.mainBusinessUnitUuid
      )

      return mainBusinessUnit
        ? {
            label: mainBusinessUnit.businessUnit.label,
            color: MYBB_CONST.bu[mainBusinessUnit.businessUnit.label].color,
            key: mainBusinessUnit.businessUnitUuid
          }
        : null
    },
    buildOtherBusinessUnits(gatheringBusinessUnits) {
      if (!Array.isArray(gatheringBusinessUnits) || !this.gathering) return []

      return gatheringBusinessUnits
        .filter(bu => bu.businessUnitUuid !== this.gathering.mainBusinessUnitUuid)
        .map(bu => ({
          label: bu.businessUnit.label,
          color: MYBB_CONST.bu[bu.businessUnit.label].color,
          key: bu.businessUnitUuid
        }))
    }
  }
}
</script>

<style lang="scss" scoped>
.GatheringInformations-listElement {
  list-style: none;
  text-align: right;
  margin: 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e1e3;

  &:last-child {
    border-bottom: none;
  }

  .GatheringInformations-listElement-header {
    float: left;
  }
}
</style>
