<template>
  <modal v-model="modal" :title="t(`${mode}.title`)">
    <mybb-text>{{ t(`${mode}.text`) }}</mybb-text>

    <v-row justify="center" class="mt-10">
      <mybb-btn
        class="mr-10"
        color="mybb-grey"
        @click="modal = false"
        v-cy="$cy.gathering.form.confirmCreateModal.cancel"
      >
        {{ t('cancel') }}
      </mybb-btn>
      <mybb-btn
        color="mybb-success"
        :loading="loading"
        @click="onConfirm"
        v-cy="$cy.gathering.form.confirmCreateModal.confirm"
      >
        {{ t('confirm') }}
      </mybb-btn>
    </v-row>
  </modal>
</template>

<script>
import Modal from '@/components/mybb/Modal'

export default {
  name: 'ConfirmCreationModal',
  components: { Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean
    },
    mode: {
      type: String,
      validator(prop) {
        return ['save', 'next'].includes(prop)
      }
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.loading = false
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.eventSteps.modalConfirmCreation.${key}`, params)
    },
    onConfirm() {
      this.loading = true

      this.$emit('confirm')
    }
  }
}
</script>
