<template>
  <div class="pt-3">
    <div class="mt-5 mb-3 d-flex justify-end align-center">
      <!-- Edition -->
      <mybb-btn
        v-if="canEdit"
        inner-icon="mdi-pencil"
        background="white"
        color="mybb-grey-lighten1"
        icon
        @click="openEdit"
        class="mr-3"
      />

      <!-- Status -->
      <virtual-session-status-button
        :virtual-session="virtualSession"
        @click="nextStatusModal = true"
        :disabled="$apollo.queries.virtualSession.loading"
        :loading="nextStatus.loading"
      />

      <!-- Wind up -->
      <mybb-btn v-if="canWindUp" color="mybb-error" class="ml-3" @click="windUpModal = true">
        {{ t('windUpEvent') }}
      </mybb-btn>
    </div>

    <v-progress-circular v-if="$apollo.queries.virtualSession.loading" size="64" indeterminate />
    <virtual-session-tile v-else :virtual-session="virtualSession" />

    <!-- Wind up modal -->
    <modal v-model="windUpModal" :title="t('windUp.title')">
      <mybb-text>{{ t('windUp.text') }}</mybb-text>
      <mybb-text weight="bold">{{ t('windUp.warning') }}</mybb-text>
      <v-checkbox v-model="windUp.confirmation" :label="t('windUp.confirmation')" color="mybb-primary-lighten1" dense />

      <div class="mt-10 text-center">
        <mybb-btn class="mr-10" color="mybb-grey" @click="windUpModal = false">{{ t('windUp.cancel') }}</mybb-btn>
        <mybb-btn :disabled="!canWindUp || !windUp.confirmation" color="mybb-error" @click="windUpEvent">
          {{ t('windUp.confirm') }}
        </mybb-btn>
      </div>
    </modal>

    <!-- Edition modal form -->
    <virtual-session-form-modal
      v-model="edit"
      :virtual-session-uuid="$route.params.virtualSessionUuid"
      :type="$get(virtualSession, 'type')"
      @refresh="$apollo.queries.virtualSession.refetch()"
    />

    <!-- Status change confirmation modal -->
    <modal
      v-model="nextStatusModal"
      :title="t(`status.${$get(virtualSession, 'status')}.title`)"
      @close="nextStatusModal = false"
    >
      <mybb-text>{{ t(`status.${$get(virtualSession, 'status')}.text`) }}</mybb-text>

      <div class="d-flex justify-center mt-10">
        <mybb-btn color="mybb-grey" class="mr-10" @click="nextStatusModal = false">
          {{ t('status.cancel') }}
        </mybb-btn>

        <mybb-btn color="mybb-success" @click="setNextStatus" :loading="nextStatus.loading">
          {{ t('status.confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import { GET_FULL_VIRTUAL_SESSION_WITH_TEAM, SET_VIRTUAL_SESSION_STATUS } from '@/graphql/VirtualSession'
import {
  VirtualSessionTile,
  VirtualSessionFormModal,
  VirtualSessionStatusButton
} from '@/components/mybb/virtualSession'
import Modal from '@/components/mybb/Modal'

export default {
  name: 'VirtualSessionDetails',
  components: { VirtualSessionTile, Modal, VirtualSessionFormModal, VirtualSessionStatusButton },
  data() {
    return {
      windUp: {
        modal: false,
        confirmation: null
      },
      nextStatus: {
        modal: false,
        loading: false,
        payload: null
      },
      edit: false
    }
  },
  computed: {
    canEdit() {
      return this.$get(this.virtualSession, 'status') !== this.$const.virtualSession.status.WINDED_UP
    },
    canChangeStatus() {
      const { ADMIN, SUPER_ADMIN, ROC } = this.$const.userType
      const userTypes = this.$get(this.$store.state, 'myUser.userTypes', [])

      return [ADMIN, SUPER_ADMIN, ROC].some(role => userTypes.includes(role))
    },
    canWindUp() {
      return this.canChangeStatus && !this.isWindedUp
    },
    isWindedUp() {
      return this.virtualSession && this.virtualSession.status === this.$const.gatheringStatus.WINDED_UP
    },
    windUpModal: {
      get() {
        return this.windUp.modal
      },
      set(value) {
        this.windUp.modal = value

        if (!value) {
          this.windUp.confirmation = null
        }
      }
    },
    nextStatusModal: {
      get() {
        return this.nextStatus.modal
      },
      set(value) {
        this.nextStatus.modal = value

        if (!value) {
          this.nextStatus = {
            modal: false,
            loading: null,
            payload: null
          }
        }
      }
    }
  },
  apollo: {
    virtualSession: {
      query: GET_FULL_VIRTUAL_SESSION_WITH_TEAM,
      variables() {
        return { virtualSessionUuid: this.$route.params.virtualSessionUuid }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`virtualSession.details.${key}`, params)
    },
    openEdit() {
      this.edit = true
    },
    async windUpEvent() {
      if (!this.canWindUp || !this.windUp.confirmation || this.windUp.loading) return

      this.windUp.loading = true

      try {
        await this.setStatus(this.$const.virtualSession.status.WINDED_UP)
        this.windUpModal = false
      } catch (error) {
        console.error(error)
      } finally {
        this.windUp.loading = false
      }
    },
    async setNextStatus() {
      if (this.nextStatus.loading) return

      this.nextStatus.loading = true

      const { DRAFT, PUBLISHED, CLOSED } = this.$const.virtualSession.status

      const map = {
        [DRAFT]: PUBLISHED,
        [PUBLISHED]: CLOSED
      }

      const nextStatus = map[this.$get(this.virtualSession, 'status')]

      if (!nextStatus) return

      try {
        await this.setStatus(nextStatus)
        this.nextStatusModal = false
      } catch (error) {
        console.error(error)
      } finally {
        this.nextStatus.loading = false
      }
    },
    async setStatus(status) {
      await this.$apollo.mutate({
        mutation: SET_VIRTUAL_SESSION_STATUS,
        variables: {
          virtualSessionUuid: this.$route.params.virtualSessionUuid,
          status
        }
      })

      return this.$apollo.queries.virtualSession.refetch()
    }
  }
}
</script>
