<template>
  <side-panel :title="t('title')" :only-open="onlyOpen" open>
    <template v-if="gathering">
      <v-row class="my-1">
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('gatheringTypeLabel') }}</mybb-text>
          </span>
          <mybb-text>{{ $t(`mybb.global.eventType.${gathering.gatheringType}`) }}</mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('natureLabel') }}</mybb-text>
          </span>
          <mybb-text>{{ nature }}</mybb-text>
        </v-col>
        <v-col v-if="displayNationalityType" cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('nationalityTypeLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('congress.nationalityType')">
            {{ nationalityType }}
          </mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('statusLabel') }}</mybb-text>
          </span>
          <v-chip :color="status.color" class="statusChip py-2 px-4" dark>
            <mybb-text size="12" weight="semi-bold" class="white--text" v-cy="$cy.gathering.details.status">
              {{ $t(`mybb.eventList.status.${status.text}`) }}
            </mybb-text>
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('nameLabel') }}</mybb-text>
          </span>
          <mybb-text v-cy="$cy.gathering.details.name">{{ gathering.name }}</mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('learnedSocietyLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('congress.learnedSociety')">{{ learnedSociety }}</mybb-text>
        </v-col>
        <v-col cols="6">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('descriptionLabel') }}</mybb-text>
          </span>
          <mybb-text
            ref="InformationTileDescriptionContent"
            class="InformationsTile-description"
            v-bind="sensibleProps('description')"
          >
            {{ gathering.description }}
          </mybb-text>
          <v-tooltip v-if="isDescriptionElipsis" color="mybb-primary-lighten1" max-width="80%" top>
            <template v-slot:activator="{ on }">
              <mybb-text size="12" class="mybb-primary-lighten1--text font-weight-bold" v-on="on">
                {{ t('seeMore') }}
              </mybb-text>
            </template>

            <mybb-text color="white" v-bind="sensibleProps('description')">
              {{ gathering.description }}
            </mybb-text>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('placeLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('location') || sensibleProps('city')">{{ place }}</mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('virtualLinkLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('biogenEvent.virtualLink')">
            {{ virtualLink }}
          </mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('websiteLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('congress.websiteUrl')">{{ website }}</mybb-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('beginDateLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('beginDate') || sensibleProps('beginHour')"
            >{{ gathering.beginDate | dateFormat }} - {{ beginHour }}</mybb-text
          >
        </v-col>

        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('endDateLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('endDate') || sensibleProps('endHour')">
            {{ gathering.endDate | dateFormat }} - {{ endHour }}
          </mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('timezoneLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('timezone')">{{ gathering.timezone || '- -' }}</mybb-text>
        </v-col>
        <v-col cols="3" v-if="gathering.gatheringType === 'biogenEvent'">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('limitDateLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('limitDate')">{{ gathering.limitDate | dateFormat }}</mybb-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('mainBusinessUnitLabel') }}</mybb-text>
          </span>
          <v-chip v-if="mainBusinessUnit" class="ma-1" :color="mainBusinessUnit.color" dark>
            <span>{{ mainBusinessUnit.label }}</span>
          </v-chip>
          <mybb-text v-else>- -</mybb-text>
        </v-col>
        <v-col cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('businessUnitLabel') }}</mybb-text>
          </span>
          <mybb-text v-if="otherBusinessUnits.length === 0">- -</mybb-text>
          <v-chip v-for="bu in otherBusinessUnits" :key="bu.businessUnitUuid" class="ma-1" :color="bu.color" dark>
            <span>{{ bu.label }}</span>
          </v-chip>
        </v-col>
        <v-col v-if="gathering.gatheringType === $const.gatheringType.BIOGEN_EVENT" cols="3">
          <span class="InformationsTileLabel">
            <mybb-text size="12" class="mybb-text-lighten1--text">{{ t('invitedLabel') }}</mybb-text>
          </span>
          <mybb-text v-bind="sensibleProps('biogenEvent.availablePlace')">
            {{ $get(this.gathering, 'biogenEvent.availablePlace', 0) }}
          </mybb-text>
        </v-col>
      </v-row>
    </template>
  </side-panel>
</template>

<script>
import dateFormat from 'date-fns/format'
import _has from 'lodash/has'

import MYBB from '@/const/my-bb'
import SidePanel from '@/components/mybb/ui/SidePanel'

export default {
  name: 'InformationsTile',
  components: { SidePanel },
  props: {
    gathering: {
      type: Object,
      default: () => {}
    },
    onlyOpen: {
      type: Boolean
    },
    showSensibleChanges: {
      type: Boolean
    },
    canValidateSection: {
      type: Boolean
    }
  },
  data() {
    return {
      isDescriptionElipsis: false
    }
  },
  computed: {
    sensibleChanges() {
      const sensibleChanges = this.$get(this.gathering, 'gatheringSensibleChanges', []).find(
        gsc => gsc.status === this.$const.gatheringSensibleChangeStatus.PENDING
      )

      return sensibleChanges ? sensibleChanges.changes : null
    },
    hasChanges() {
      const fields = [
        'congress.nationalityType',
        'congress.learnedSociety',
        'description',
        'location',
        'city',
        'biogenEvent.virtualLink',
        'congress.websiteUrl',
        'beginDate',
        'beginHour',
        'endDate',
        'endHour',
        'limitDate',
        'timezone'
      ]

      return fields.some(field => this.sensibleProps(field))
    },
    nature() {
      return this.gathering
        ? this.gathering.isVirtual && this.gathering.isPhysical
          ? this.$t('mybb.activity.nature.HYBRID')
          : this.gathering.isPhysical
          ? this.$t('mybb.activity.nature.PHYSICAL')
          : this.$t('mybb.activity.nature.VIRTUAL')
        : '- -'
    },
    displayNationalityType() {
      if (
        !this.gathering ||
        this.gathering.gatheringType === this.$const.gatheringType.BIOGEN_EVENT ||
        (this.gathering.isVirtual && !this.gathering.isPhysical)
      ) {
        return false
      }
      return true
    },
    nationalityType() {
      if (
        !this.gathering ||
        !this.gathering.congress ||
        this.gathering.gatheringType === this.$const.gatheringType.BIOGEN_EVENT
      ) {
        return '- -'
      }

      return this.t(`nationalityTypes.${this.gathering.congress.nationalityType}`)
    },
    learnedSociety() {
      if (
        !this.gathering ||
        !this.gathering.congress ||
        this.gathering.gatheringType === this.$const.gatheringType.BIOGEN_EVENT
      ) {
        return '- -'
      }
      return this.gathering.congress.learnedSociety || '- -'
    },
    status() {
      const defineColor = key => {
        switch (key) {
          case this.$const.gatheringStatus.DRAFT:
            return 'gathering-status-draft'
          case this.$const.gatheringStatus.CORRECTION:
            return 'gathering-status-correction'
          case this.$const.gatheringStatus.PUBLISHED:
            return 'gathering-status-published'
          case this.$const.gatheringStatus.PUBLISHED_FRONT:
            return 'gathering-status-published-front'
          case this.$const.gatheringStatus.TO_PUBLISH:
            return 'gathering-status-to-publish'
          case this.$const.gatheringStatus.TO_VALIDATE:
            return 'gathering-status-to-validate'
          case this.$const.gatheringStatus.WINDED_UP:
          case this.$const.gatheringStatus.CLOSED:
            return 'error'
          default:
            return 'grey'
        }
      }
      return {
        text: this.gathering.status,
        color: defineColor(this.gathering.status)
      }
    },
    place() {
      const place = [this.gathering.location, this.gathering.city].filter(Boolean).join(', ')

      return this.gathering.location ? place : '- -'
    },
    virtualLink() {
      if (!this.gathering) {
        return '- -'
      }

      return this.$get(this.gathering, 'visioUrl', '- -')
    },
    website() {
      if (!this.gathering || !this.gathering.congress) {
        return '- -'
      }

      return this.gathering.congress.websiteUrl || '- -'
    },
    businessUnits() {
      const businessUnitUuids = []

      if (!this.gathering) return businessUnitUuids

      if (this.gathering.hasOwnProperty('businessUnitUuids')) {
        businessUnitUuids.push(...this.gathering.businessUnitUuids)
      } else if (this.gathering.hasOwnProperty('businessUnits')) {
        businessUnitUuids.push(...this.gathering.businessUnits.map(bu => bu.businessUnitUuid))
      }

      return businessUnitUuids
        .map(businessUnitUuid =>
          this.$get(this.$store, 'state.bus', []).find(bu => bu.businessUnitUuid === businessUnitUuid)
        )
        .map(bu => {
          return {
            businessUnitUuid: bu.businessUnitUuid,
            color: MYBB.bu[bu.label].color,
            label: MYBB.bu[bu.label].label
          }
        })
    },
    mainBusinessUnit() {
      return this.businessUnits.find(bu => bu.businessUnitUuid === this.gathering.mainBusinessUnitUuid)
    },
    otherBusinessUnits() {
      return this.businessUnits.filter(bu => bu.businessUnitUuid !== this.gathering.mainBusinessUnitUuid)
    },
    beginHour() {
      console.log(this.gathering)
      if (!Array.isArray(this.gathering.eventSchedule)) return null

      return this.gathering.eventSchedule[0]
    },
    endHour() {
      if (!Array.isArray(this.gathering.eventSchedule)) return null

      return this.gathering.eventSchedule[1]
    }
  },
  filters: {
    dateFormat(value) {
      if (!value) return '- -'
      const date = new Date(value)
      return dateFormat(date, 'dd/MM/yy')
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.gatheringDetails.informations.${key}`, params)
    },
    sensibleProps(field) {
      if (!this.showSensibleChanges || !this.sensibleChanges) return false

      const hasSensibleChanges = _has(this.sensibleChanges, field)

      return hasSensibleChanges ? { weight: 'bold', color: 'green' } : null
    }
  },
  watch: {
    gathering() {
      this.$nextTick(() => {
        const desc = this.$refs.InformationTileDescriptionContent
        this.isDescriptionElipsis = desc.$el.offsetWidth < desc.$el.scrollWidth
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.InformationsTileLabel {
  line-height: 12px;
  display: block;
}

.statusChip {
  transform: translateY(5px);
}

.InformationsTile-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 60px);
}
</style>
