import _merge from 'lodash/merge'
import _get from 'lodash/get'

import CONST from '@/const/shared'

function mergeSensibleUserChanges(merged, gathering, changes) {
  const users = gathering.users.filter(usr => !usr.businessUnitUuid)
  const businessUnitUuids = new Set()

  for (const { businessUnitUuid } of [...gathering.users, ...changes.users]) {
    businessUnitUuids.add(businessUnitUuid)
  }

  for (const businessUnitUuid of Array.from(businessUnitUuids)) {
    if (!businessUnitUuid) continue

    const hasBuUsersChanged = changes.users.some(usr => usr.businessUnitUuid === businessUnitUuid)

    if (!hasBuUsersChanged) {
      users.push(...gathering.users.filter(user => user.businessUnitUuid === businessUnitUuid))
    } else {
      users.push(...changes.users.filter(user => user.businessUnitUuid === businessUnitUuid))
    }
  }

  merged.users = users
}

export function mergeSensibleChanges(gathering) {
  if (!Array.isArray(gathering.gatheringSensibleChanges)) return gathering

  const sensibleChanges = gathering.gatheringSensibleChanges.find(
    gsc => gsc.status === CONST.gatheringSensibleChangeStatus.PENDING
  )

  if (!sensibleChanges) return gathering

  const { changes } = sensibleChanges
  const merged = _merge({}, gathering, changes)

  if (changes.hasOwnProperty('users')) {
    mergeSensibleUserChanges(merged, gathering, changes)
  }

  if (changes.hasOwnProperty('businessUnitUuids')) {
    const businessUnitUuids = new Set()

    for (const businessUnit of _get(gathering, 'businessUnits', [])) {
      businessUnitUuids.add(businessUnit.businessUnitUuid)
    }

    for (const businessUnitUuid of changes.businessUnitUuids) {
      businessUnitUuids.add(businessUnitUuid)
    }

    merged.businessUnitUuids = Array.from(businessUnitUuids)
  }

  return merged
}
