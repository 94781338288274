<template>
  <div class="IndexMyBiogen">
    <div class="IndexMyBiogen-content">
      <div class="IndexMyBiogen-contentWrapper">
        <router-view />
      </div>
    </div>

    <div class="IndexMyBiogen-disclaimer">
      <img src="@/assets/biogen-logo-light.svg" alt="Biogen" />
      <span>{{ $t('indexPage.disclaimerMyBiogen') }}</span>
    </div>
  </div>
</template>

<script>
import { Validator } from 'vee-validate'

export default {
  name: 'Index',
  mounted() {
    const dictionary = {
      fr: {
        messages: {
          required: this.$t('customError.required')
        }
      }
    }

    // To custom error messages in My BB
    Validator.localize(dictionary)
    Validator.localize('fr')
  }
}
</script>

<style lang="scss">
.IndexMyBiogen {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: var(--v-mybb-text-base);

  .v-text-field--outlined {
    border-radius: 8px;
  }
}

.IndexMyBiogen-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-image: url('~@/assets/bckg-my-biogen.svg'), url('~@/assets/bckg-gradient-my-biogen.png');
  background-size: auto 900px, contain;
  background-repeat: no-repeat, repeat-y;

  &.form {
    background-image: none;
    background: rgb(9, 59, 104);
    background: linear-gradient(90deg, rgba(9, 59, 104, 1) 50%, rgba(255, 255, 255, 1) 50%);
  }
}

.IndexMyBiogen-disclaimer {
  bottom: 0;
  height: 38px;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  font-size: 0.8em;
  color: white;
  background-color: var(--v-mybb-primary-base);

  img {
    height: 30px;
  }
}
</style>
