<template>
  <status-information-tile
    :title="t('title')"
    :informations="informations"
    :color="color"
    :date="$get(informationStatus, 'date')"
  >
    <mybb-text :class="`${color}--text text-center`" size="16" weight="bold">
      {{ status }}
    </mybb-text>
  </status-information-tile>
</template>

<script>
import StatusInformationTile from '../../ui/StatusInformationTile'

export default {
  name: 'PresenceTile',
  components: { StatusInformationTile },
  props: {
    participant: Object,
    informationStatus: Object
  },
  computed: {
    isCongress() {
      return this.$get(this.participant, 'gathering.congress') !== null
    },
    informations() {
      const initiator = this.$get(this.informationStatus, 'initiator')

      return initiator ? this.t('informations', initiator) : null
    },
    color() {
      const { absent, present, noShow } = this.$const.presence

      switch (this.$get(this.informationStatus, 'newStatus')) {
        case present:
          return 'mybb-primary-lighten1'
        case absent:
        case noShow:
          return 'mybb-error'
        default:
          return 'mybb-grey-lighten1'
      }
    },
    status() {
      const { absent, present, noShow } = this.$const.presence
      const eventType = this.isCongress ? 'congress' : 'standalone'

      switch (this.$get(this.informationStatus, 'newStatus')) {
        case present:
          return this.t(`status.${eventType}.${present}`)
        case absent:
          return this.t(`status.${eventType}.${absent}`)
        case noShow:
          return this.t(`status.congress.${noShow}`)
        default:
          return '- -'
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.synthesisPanel.presence.${key}`, params)
    }
  }
}
</script>
