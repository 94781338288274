var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"HostingOvernightStays"},[(!_vm.items)?_c('loader-tab'):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"custom-sort":_vm.$dataTableSort(_vm.sortMapping),"show-select":"","item-key":"participantUuid","hide-default-footer":"","disable-pagination":"","search":_vm.searchText},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(_vm._f("uppercase")(value)))])]}},{key:"item.firstName",fn:function(ref){
var value = ref.value;
return [_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(value))])]}},{key:"item.category",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-chip',{attrs:{"color":value.color,"dark":""}},[_vm._v(" "+_vm._s(value.label)+" ")]):_vm._e()]}},{key:"item.presence",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":value.color}},[_vm._v(" "+_vm._s(value.icon)+" ")]):_vm._e()]}},_vm._l((_vm.eventDates),function(night){return {key:("item." + (_vm.dateAsKey(night))),fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{key:_vm.dateAsKey(night),attrs:{"color":"mybb-blue"}},[_vm._v(" mdi-check ")]):_vm._e()]}}}),{key:"item.comments",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"color":"mybb-primary-lighten1","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"mybb-primary-lighten1"}},on),[_vm._v(" mdi-message-text ")])]}}],null,true)},_vm._l((value),function(comment){return _c('mybb-text',{key:comment.hostingCommentUuid,staticClass:"white--text"},[_vm._v(" "+_vm._s(comment.comment)+" "),_c('br')])}),1):_vm._e()]}},{key:"item.edit",fn:function(ref){
var value = ref.value;
return [(!_vm.isWindedUpGathering)?_c('v-icon',{attrs:{"size":"20","color":"mybb-grey-lighten1"},on:{"click":function($event){_vm.editModal = value}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('edit-hosting-participant-modal',{attrs:{"configuration":_vm.configuration,"gathering":_vm.gathering,"participants":_vm.participantsByUuid,"hostingUuid":_vm.$route.params.hostingUuid,"participantUuid":_vm.editParticipantUuid},on:{"refresh":function($event){return _vm.$emit('refresh')}},model:{value:(_vm.editModal),callback:function ($$v) {_vm.editModal=$$v},expression:"editModal"}}),_c('hosting-cancel-modal',{on:{"confirm":_vm.cancelHostingModalConfirm},model:{value:(_vm.cancelHostingModal),callback:function ($$v) {_vm.cancelHostingModal=$$v},expression:"cancelHostingModal"}}),_c('fixed-footer',{attrs:{"visible":_vm.selectedParticipantUuids.length > 0,"label":_vm.t('footer.label', { selected: _vm.selectedParticipantUuids.length })}},[_c('mybb-btn',{attrs:{"color":"mybb-error"},on:{"click":function($event){_vm.cancelHostingModal = true}}},[_vm._v(_vm._s(_vm.t('footer.cancel')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }