<template>
  <side-panel :title="t('title')">
    <comment v-for="comment in sortComments" :key="comment.commentUuid" :comment="comment" class="mt-6 mb-4" />
  </side-panel>
</template>

<script>
import SidePanel from '../ui/SidePanel'
import Comment from '@/components/mybb/ui/Comment'

export default {
  name: 'CommentsPanel',

  components: { Comment, SidePanel },

  props: {
    participant: {
      type: Object
    }
  },

  computed: {
    sortComments() {
      if (!this.participant) {
        return []
      }
      const hostingComments = (this.participant.hostingComments || []).map(comment => ({
        commentUuid: comment.hostingCommentUuid,
        label: this.t('hostingComments'),
        commentHeader: this.t('hostingCommentsHeader'),
        date: comment.createdAt,
        text: comment.comment || '- -',
        user: comment.user,
        file: comment.file
      }))

      const participantComments = (this.participant.participantComments || [])
        .filter(
          comment =>
            ![
              this.$const.participantCommentReason.REQUIRE_HOSTING,
              this.$const.participantCommentReason.RESTAURATION
            ].includes(comment.reason)
        )
        .map(comment => {
          let commentHeader
          let label

          switch (comment.reason) {
            case this.$const.participantCommentReason.GLOBAL_COMMENT:
              commentHeader = this.t('generalCommentsHeader')
              label = this.t('generalComments')
              break
            case this.$const.participantCommentReason.SET_CUSTOM_CATEGORY:
              commentHeader = this.t('customCategoryHeader')
              label = this.t('participantComments')
              break
            case this.$const.participantCommentReason.SET_HOSTING_OVERNIGHT_STAY:
              commentHeader = this.t('hostingCommentsHeader')
              label = this.t('hostingComments')
              break
            case this.$const.participantCommentReason.SET_SUBSCRIPTION:
              commentHeader =
                this.t('participantCommentsHeader') +
                (this.participant.subscriptionPrice
                  ? this.t('participantCommentsHeaderSuffix', {
                      price: (this.participant.subscriptionPrice / 100).toFixed(2)
                    })
                  : '')
              label = this.t('participantComments')
              break
          }

          return {
            commentUuid: comment.participantCommentUuid,
            label,
            commentHeader,
            date: comment.createdAt,
            text: comment.comment || '- -',
            user: comment.user,
            file: comment.file
          }
        })

      const expenseNotesComments = (this.participant.expenseNotes || [])
        .reduce((acc, cur) => {
          return cur.comments ? [...acc, ...cur.comments] : acc
        }, [])
        .map(comment => ({
          commentUuid: comment.expenseNoteCommentUuid,
          label: this.t('expenseNotesComments'),
          actionStatus: comment.actionStatus,
          date: comment.date,
          text: comment.text || '- -',
          user: comment.user,
          oldLineValues: comment.oldLineValues
        }))

      return [...hostingComments, ...participantComments, ...expenseNotesComments].sort((a, b) => {
        if (!a.date && b.date) return 1
        if (a.date && !b.date) return -1
        if (!a.date && !b.date) return -1
        const dateA = new Date(a.date)
        const dateB = new Date(b.date)
        return dateA.valueOf() < dateB.valueOf() ? 1 : -1
      })
    }
  },

  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.commentsPanel.${key}`, params)
    }
  }
}
</script>
