<template>
  <div class="TransportNoteList mt-5 pb-8" v-cy="$cy.gathering.transport.container">
    <v-row class="mb-3" no-gutters align="center">
      <v-col cols="12" md="5">
        <mybb-text-field
          v-model="searchText"
          background-color="white"
          :placeholder="$t('mybb.transportNoteList.searchPlaceholder')"
          icon="mdi-magnify"
        />
      </v-col>
      <div>
        <span class="BtnWrapper">
          <mybb-btn
            @click="filtersOpen = true"
            color="mybb-grey-lighten1"
            background="white"
            inner-icon="mdi-filter-variant"
            class="ml-2"
            icon
          />
          <div v-if="isFilterSelected" class="TransportNoteList-buttonDot" />
        </span>
        <mybb-btn
          @click="downloadExport"
          :disabled="configurationLoading"
          background="white"
          color="mybb-grey-lighten1"
          icon
          inner-icon="mdi-file-export"
          class="ml-2"
          :loading="loadingDownloadExport || configurationLoading"
        />
        <mybb-btn
          @click="modalConfigureTransport = true"
          :disabled="configurationLoading"
          :loading="configurationLoading"
          :color="isConfigured ? 'mybb-grey-lighten1' : 'white'"
          :background="isConfigured ? 'white' : 'mybb-warning'"
          v-cy="$cy.gathering.transport.configuration.button"
          inner-icon="mdi-cogs"
          class="ml-2"
          icon
        />
      </div>
      <v-spacer />
      <mybb-btn
        @click="$router.push({ name: 'TransportNoteForm' })"
        class="ml-8"
        color="primary"
        inner-icon="mdi-plus"
        :disabled="isWindedUpGathering"
      >
        {{ $t('mybb.transportNoteList.createTransportButton') }}
      </mybb-btn>
    </v-row>

    <Loader v-if="!items" color="white" />
    <v-data-table
      v-else
      :headers="headers"
      item-key="uuid"
      :items-per-page="tableSettings.itemPerPage"
      :items="items"
      :search="searchText"
      :custom-filter="$dataTableFilter(filterFields)"
      :custom-sort="$dataTableSort(sortMapping)"
      sort-by="status"
      sort-asc
      :footer-props="tableSettings.footerProps"
      class="px-4 pt-3 DataTable"
    >
      <template v-slot:[`item.id`]="{ value: id }">
        <mybb-text weight="bold">{{ id }}</mybb-text>
      </template>
      <template v-slot:[`item.firstname`]="{ value: firstname }">
        <mybb-text weight="bold">{{ firstname }}</mybb-text>
      </template>
      <template v-slot:[`item.lastname`]="{ value: lastname }">
        <mybb-text weight="bold">{{ lastname }}</mybb-text>
      </template>
      <template v-slot:[`item.date`]="{ value: date }">
        <span>{{ date | dateFormat }}</span>
      </template>
      <template v-slot:[`item.status`]="{ value: status }">
        <v-chip :color="status.color" dark class="Chip py-2 px-4 text-center" pill>
          <mybb-text size="12" weight="semi-bold" class="white--text">{{ status.text }}</mybb-text>
        </v-chip>
      </template>
      <template v-slot:[`item.lastUpdate`]="{ value: date }">
        <mybb-text>{{ date | dateFormat }}</mybb-text>
      </template>
      <template v-slot:[`item.goTo`]="{ value: goTo }">
        <v-btn icon class="table-icon" :to="goTo">
          <v-icon color="mybb-grey-lighten1">mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <FilterDrawer
      v-model="filters"
      :open="filtersOpen"
      :label="$t('mybb.transportNoteList.statusFilterLabel')"
      @close="filtersOpen = false"
    />

    <transport-configuration-modal
      v-model="modalConfigureTransport"
      :gatheringUuid="$route.params.gatheringUuid"
      :configuration="$get(this.gatheringTransportConfiguration, 'transportConfiguration')"
      :is-disabled="isWindedUpGathering"
      @success="refetchConfiguration"
    />
  </div>
</template>

<script>
import axios from 'axios'
import dateFnsFormat from 'date-fns/format'
import FileDownload from 'js-file-download'
import { mapGetters } from 'vuex'

import Loader from '@/components/congrex/tabs/LoaderTab'
import FilterDrawer from '@/components/mybb/ui/FilterDrawer'
import shared from '@/const/my-bb'
import { GET_TRANSPORT_CONFIGURATION_FOR_GATHERING } from '@/graphql/Gatherings/TransportConfigurationGathering'
import { SEARCH_TRANSPORTS_FOR_GATHERING } from '@/graphql/Transport'
import TransportConfigurationModal from '@/components/mybb/transport/TransportConfigurationModal'

export default {
  name: 'TransportNoteList',

  components: { Loader, FilterDrawer, TransportConfigurationModal },

  data() {
    return {
      loadingDownloadExport: false,
      configurationLoading: true,
      modalConfigureTransport: false,
      searchText: '',
      filtersOpen: false,
      filters: [],
      sortMapping: {
        status: 'status.text'
      },
      filterFields: {
        id: 'id',
        lastname: 'lastname',
        firstname: 'firstname',
        startPlace: 'startPlace'
      }
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    selectedFilters() {
      return this.filters.reduce(
        (acc, filter) => ({
          ...acc,
          [filter.key]: filter.value
        }),
        {}
      )
    },
    tableSettings() {
      return shared.tableSettings
    },
    headers() {
      return [
        { sortable: true, text: this.$t('mybb.transportNoteList.tableHeaders.id'), value: 'id' },
        { sortable: true, text: this.$t('mybb.transportNoteList.tableHeaders.lastname'), value: 'lastname' },
        { sortable: false, text: this.$t('mybb.transportNoteList.tableHeaders.firstname'), value: 'firstname' },
        { sortable: true, text: this.$t('mybb.transportNoteList.tableHeaders.startPlace'), value: 'startPlace' },
        {
          sortable: true,
          text: this.$t('mybb.transportNoteList.tableHeaders.date'),
          value: 'date'
        },
        { sortable: true, text: this.$t('mybb.transportNoteList.tableHeaders.transportMode'), value: 'transportMode' },
        {
          sortable: true,
          text: this.$t('mybb.transportNoteList.tableHeaders.lastUpdate'),
          value: 'lastUpdate'
        },
        {
          sortable: true,
          text: this.$t('mybb.transportNoteList.tableHeaders.status'),
          value: 'status'
        },
        { sortable: false, text: '', value: 'goTo' }
      ]
    },
    isFilterSelected() {
      return this.filters.some(filter => filter.value)
    },
    items() {
      if (!Array.isArray(this.transportNotes)) return []

      const items = this.transportNotes.map(note => {
        const _note = {
          goTo: { name: 'TransportNotePage', params: { transportNoteUuid: note.transportNoteUuid } },
          date: this.$get(note, 'noteReceivalDate'),
          id: note.visualIdentifier,
          lastname: this.$get(note, 'participant.lastName'),
          firstname: this.$get(note, 'participant.firstName'),
          status: this.getLabelStatus(note.status),
          lastUpdate: this.$get(note, 'lastUpdate'),
          transportNoteUuid: note.transportNoteUuid
        }

        _note.startPlace = this.$get(note, 'travels[0].departurePlace', '--')

        const transportMode = new Set()
        for (const travel of note.travels || []) {
          transportMode.add(this.$t(`mybb.transportNoteList.modes.${travel.mode}`))
        }

        _note.transportMode = Array.from(transportMode).join(' + ')

        return _note
      })

      return this.isFilterSelected
        ? items.filter(item => {
            return this.selectedFilters[item.status.key]
          })
        : items
    },
    transportConfiguration() {
      return this.$get(this.gatheringTransportConfiguration, 'transportConfiguration', null)
    },
    isConfigured() {
      return this.$get(this.transportConfiguration, 'transportConfigurationUuid', null)
    },
    isWindedUpGathering() {
      const gatheringStatus = this.$get(this.gatheringTransportConfiguration, 'status', null)

      return gatheringStatus === this.$const.gatheringStatus.WINDED_UP
    }
  },

  filters: {
    dateFormat(date) {
      if (!date) return '--'

      return dateFnsFormat(new Date(date), 'dd/MM/yy')
    }
  },

  apollo: {
    transportNotes: {
      query: SEARCH_TRANSPORTS_FOR_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$get(this.$route, 'params.gatheringUuid'),
          limit: 1000
        }
      },
      skip() {
        return !this.$get(this.$route, 'params.gatheringUuid')
      }
    },
    gatheringTransportConfiguration: {
      query: GET_TRANSPORT_CONFIGURATION_FOR_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$get(this.$route, 'params.gatheringUuid')
        }
      },
      skip() {
        return !this.$get(this.$route, 'params.gatheringUuid')
      },
      update({ gathering }) {
        return gathering
      },
      result() {
        // Purely UX feeling fix - prevent the button from flickering
        // which let time to the user to see the loading in action
        // For high bandwidth connections
        setTimeout(() => {
          this.configurationLoading = false
        }, 500)
      }
    }
  },

  created() {
    this.filters = Object.keys(this.$const.transport.noteStatus)
      .filter(key => this.$const.transport.noteStatus[key] !== this.$const.transport.noteStatus.EX_NIHILO)
      .map(key => ({
        key: this.$const.transport.noteStatus[key],
        label: this.$t(`mybb.transportNoteList.status.${this.$const.transport.noteStatus[key]}`),
        value: false
      }))
  },

  methods: {
    getLabelStatus(status) {
      const colors = {}

      Object.keys(this.$const.transport.noteStatus)
        .filter(key => key !== 'EX_NIHILO')
        .forEach(key => {
          colors[this.$const.transport.noteStatus[key]] = this.$const.transport.colors[key]
        })
      return {
        key: status,
        text: this.$t(`mybb.transportNoteList.status.${status}`),
        color: colors[status]
      }
    },
    async downloadExport() {
      this.loadingDownloadExport = true
      const filters = Object.keys(this.selectedFilters).filter(filter => this.selectedFilters[filter])
      const query = filters.length > 0 ? { noteStatus: filters } : {}

      const file = await axios.post(`/transport/export/${this.$get(this.$route, 'params.gatheringUuid')}`, query, {
        responseType: 'blob',
        headers: {
          'x-impersonificated-user': this.$store.state.impersonificatedUser
        }
      })

      FileDownload(file.data, `${dateFnsFormat(new Date(), 'yyMMdd')}-transport.xlsx`)
      this.loadingDownloadExport = false
    },
    refetchConfiguration() {
      this.$apollo.queries.gatheringTransportConfiguration.refetch()
    }
  }
}
</script>

<style lang="scss">
.TransportNoteList {
  .DataTable {
    border-radius: 8px;
  }

  .v-data-table {
    thead th {
      font-size: 12px;
      font-weight: 900;
      color: var(--v-mybb-primary-base) !important;
    }

    tbody td {
      color: var(--v-mybb-primary-base);
    }
  }

  .Chip {
    width: 108px;

    .v-chip__content {
      width: 100%;
      justify-content: center;
    }
  }

  // UGLY, but after rollback ui, need to force that case at 46
  // because 56 is really to high in this situation
  .v-input__control > .v-input__slot {
    min-height: 46px !important;
  }
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 15px !important;
  }
}

.BtnWrapper {
  position: relative;
}

.TransportNoteList-buttonDot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--v-mybb-success-base);
  position: absolute;
  z-index: 10;
  top: -15px;
  right: -3.75px;
}
</style>
