import gql from 'graphql-tag'

export const SET_VSP_PRESENCE = gql`
  mutation setVirtualSessionParticipantsPresence(
    $virtualSessionParticipantUuids: [GUID!]!
    $presence: VirtualSessionParticipantPresence
  ) {
    setVirtualSessionParticipantsPresence(
      virtualSessionParticipantUuids: $virtualSessionParticipantUuids
      presence: $presence
    ) {
      virtualSessionParticipantUuid
    }
  }
`
