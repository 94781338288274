<template>
  <status-information-tile
    :title="t('title')"
    :informations="informations"
    :color="color"
    :date="$get(informationStatus, 'date')"
  >
    <mybb-text :class="`${color}--text`" size="16" weight="bold">
      {{ status }}
    </mybb-text>
  </status-information-tile>
</template>

<script>
import dateFormat from 'date-fns/format'

import StatusInformationTile from '../../ui/StatusInformationTile'

export default {
  name: 'SubscriptionTile',
  components: { StatusInformationTile },
  props: {
    participant: Object,
    informationStatus: Object
  },
  computed: {
    informations() {
      const date = this.$get(this.participant, 'subscriptionDate')

      return date ? this.t('informations', { date: dateFormat(new Date(date), 'dd/MM/yy') }) : null
    },
    subscriptionStatus() {
      return this.$get(this.participant, 'invitationStatus')
    },
    color() {
      const { pending, confirmed, refused } = this.$const.invitationStatus

      switch (this.subscriptionStatus) {
        case confirmed:
          return 'mybb-primary-lighten1'
        case refused:
          return 'mybb-error'
        case pending:
        default:
          return 'mybb-grey-lighten1'
      }
    },
    status() {
      if (!this.subscriptionStatus) return '- -'

      return this.t(`status.${this.subscriptionStatus}`)
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.hcpEventDetails.synthesisPanel.subscription.${key}`, params)
    }
  }
}
</script>
