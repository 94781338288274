<template>
  <v-chip v-if="status" :color="color" class="statusChip py-2 px-4" dark>
    <mybb-text size="12" weight="semi-bold" class="white--text">
      {{ label }}
    </mybb-text>
  </v-chip>
</template>

<script>
export default {
  name: 'VirtualSessionStatusChip',
  props: {
    status: {
      type: String
    }
  },
  computed: {
    label() {
      return this.$t(`virtualSession.status.${this.status}`)
    },
    color() {
      const { CLOSED, DRAFT, PUBLISHED, WINDED_UP } = this.$const.virtualSession.status
      const map = {
        [CLOSED]: 'error',
        [DRAFT]: 'gathering-status-draft',
        [PUBLISHED]: 'gathering-status-published',
        [WINDED_UP]: 'error'
      }

      return map[this.status]
    }
  }
}
</script>

<style scoped>
.statusChip {
  transform: translateY(5px);
}
</style>
