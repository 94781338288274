import { render, staticRenderFns } from "./AdvantagesPanel.vue?vue&type=template&id=4cdf0ef6&scoped=true&"
import script from "./AdvantagesPanel.vue?vue&type=script&lang=js&"
export * from "./AdvantagesPanel.vue?vue&type=script&lang=js&"
import style0 from "./AdvantagesPanel.vue?vue&type=style&index=0&id=4cdf0ef6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdf0ef6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
