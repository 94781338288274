<template>
  <div class="TravelGroup">
    <v-row class="ma-0 pa-0 mt-6">
      <!-- Go group -->
      <v-col v-if="goTravels.length" class="pa-0 d-flex flex-column" cols="12" md="6">
        <mybb-text weight="bold" class="mb-5">{{ t('goGroupTitle') }}</mybb-text>
        <transport-travel-detail
          :travels-through-model="travelsThroughModel"
          :travels="goTravels"
          :type="$const.transport.travelType.GO"
          v-model="transportNote"
          @saved="$emit('saved')"
          :is-disabled="isDisabled"
        />
      </v-col>
      <v-col v-else class="pa-0" cols="12" md="6">
        <mybb-text weight="bold" class="mb-5">{{ t('goGroupTitle') }}</mybb-text>
        <add-transport-button
          :travels-through-model="travelsThroughModel"
          :type="$const.transport.travelType.GO"
          v-model="transportNote"
          @saved="$emit('saved')"
        />
      </v-col>

      <!-- Return group -->
      <v-col v-if="backTravels.length" class="pa-0 d-flex flex-column" cols="12" md="6">
        <mybb-text weight="bold" class="mb-5">{{ t('returnGroupTitle') }}</mybb-text>
        <transport-travel-detail
          :travels-through-model="travelsThroughModel"
          :travels="backTravels"
          :type="$const.transport.travelType.BACK"
          v-model="transportNote"
          @saved="$emit('saved')"
          :is-disabled="isDisabled"
        />
      </v-col>
      <v-col v-else class="pa-0" cols="12" md="6">
        <mybb-text weight="bold" class="mb-5">{{ t('returnGroupTitle') }}</mybb-text>
        <add-transport-button
          :travels-through-model="travelsThroughModel"
          :type="$const.transport.travelType.BACK"
          v-model="transportNote"
          @saved="$emit('saved')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddTransportButton from '@/components/mybb/transport/AddTransportButton'
import TransportTravelDetail from '@/components/mybb/transport/TransportTravelDetail'

export default {
  name: 'TransportTravelGroup',
  components: { AddTransportButton, TransportTravelDetail },
  model: {
    prop: 'note',
    event: 'change'
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    travelsThroughModel: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    backTravels() {
      return this.sortTravel(
        this.$get(this.note, 'travels', []).filter(travel => travel.type === this.$const.transport.travelType.BACK)
      )
    },
    goTravels() {
      return this.sortTravel(
        this.$get(this.note, 'travels', []).filter(travel => travel.type === this.$const.transport.travelType.GO)
      )
    },
    transportNote: {
      get() {
        return this.note
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  },
  methods: {
    sortTravel(travels) {
      return Array.from(travels).sort((a, b) => {
        const date1 = new Date(`${a.arrivalDate} ${a.arrivalTime}`)
        const date2 = new Date(`${b.departureDate} ${b.departureTime}`)

        return date1 - date2
      })
    },
    t(key, params) {
      return this.$t(`mybb.transportNotePage.travelGroup.${key}`, params)
    }
  }
}
</script>

<style></style>
