<template>
  <modal v-model="modal" :title="t('title')">
    <!-- Participation type -->
    <div v-if="isHybrid">
      <mybb-text weight="bold">{{ t('participationType.header') }}</mybb-text>

      <div class="mt-3">
        <mybb-text>{{ t('participationType.label') }}</mybb-text>
        <v-radio-group v-model="participationType" class="mt-2" hide-details dense row>
          <v-radio :value="$const.activity.participationType.physical" color="mybb-primary-lighten1">
            <mybb-text slot="label">{{ t('participationType.physical') }}</mybb-text>
          </v-radio>
          <v-radio :value="$const.activity.participationType.virtual" color="mybb-primary-lighten1">
            <mybb-text slot="label">{{ t('participationType.virtual') }}</mybb-text>
          </v-radio>
        </v-radio-group>
      </div>
    </div>

    <!-- Information -->
    <div class="mt-3">
      <mybb-text weight="bold">{{ t('informations.header') }}</mybb-text>

      <v-row>
        <!-- Gender -->
        <v-col cols="6" class="ma-0 pr-1">
          <mybb-text class="mybb-grey-lighten1--text" size="12">{{ t('informations.gender.label') }}</mybb-text>
          <v-radio-group v-model="details.gender" class="mt-0" hide-details dense row>
            <v-radio :value="$const.gender.MALE" color="mybb-primary-lighten1">
              <mybb-text slot="label">{{ t('informations.gender.male') }}</mybb-text>
            </v-radio>
            <v-radio :value="$const.gender.FEMALE" color="mybb-primary-lighten1">
              <mybb-text slot="label">{{ t('informations.gender.female') }}</mybb-text>
            </v-radio>
          </v-radio-group>
        </v-col>

        <!-- Title -->
        <v-col cols="6" class="ma-0 pl-1">
          <mybb-text-field v-model="details.title" :label="t('informations.title')" icon="mdi-account" />
        </v-col>
      </v-row>

      <v-row>
        <!-- Last name -->
        <v-col cols="6" class="ma-0 pr-1">
          <mybb-text-field v-model="details.lastName" :label="t('informations.lastName')" icon="mdi-account" />
        </v-col>
        <!-- First name -->
        <v-col cols="6" class="ma-0 pl-1">
          <mybb-text-field v-model="details.firstName" :label="t('informations.firstName')" icon="mdi-account" />
        </v-col>
      </v-row>

      <v-row>
        <!-- Email -->
        <v-col cols="6" class="ma-0 pr-1">
          <mybb-text-field v-model="details.email" :label="t('informations.email')" icon="mdi-account" />
        </v-col>
        <!-- Phone number -->
        <v-col cols="6" class="ma-0 pl-1">
          <mybb-text-field v-model="details.phoneNumber" :label="t('informations.phoneNumber')" icon="mdi-account" />
        </v-col>
      </v-row>

      <div class="my-3">
        <mybb-text-field v-model="details.address" :label="t('informations.address')" icon="mdi-map-marker" />
      </div>

      <v-row>
        <!-- Birthdate -->
        <v-col cols="6" class="ma-0 pr-1">
          <date-picker v-model="details.birthDate" :label="t('informations.birthDate')" icon="mdi-account" />
        </v-col>
        <!-- Society -->
        <v-col cols="6" class="ma-0 pl-1">
          <mybb-text-field v-model="details.society" :label="t('informations.society')" icon="mdi-office-building" />
        </v-col>
      </v-row>

      <v-row>
        <!-- SNCF Number -->
        <v-col cols="3" class="ma-0 pr-1">
          <mybb-text-field v-model="details.sncfNumber" :label="t('informations.sncfNumber')" icon="mdi-train" />
        </v-col>
        <!-- SNCF Card -->
        <v-col cols="6" class="ma-0 px-1">
          <mybb-text-field v-model="details.sncfCard" :label="t('informations.sncfCard')" icon="mdi-train" />
        </v-col>
        <!-- SNCF expiration -->
        <v-col cols="3" class="ma-0 pl-1">
          <date-picker
            v-model="details.sncfExpirationDate"
            :label="t('informations.sncfExpirationDate')"
            icon="mdi-train"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- AF Number -->
        <v-col cols="3" class="ma-0 pr-1">
          <mybb-text-field v-model="details.afNumber" :label="t('informations.afNumber')" icon="mdi-airplane" />
        </v-col>
        <!-- AF Card -->
        <v-col cols="6" class="ma-0 px-1">
          <mybb-text-field v-model="details.afCard" :label="t('informations.afCard')" icon="mdi-airplane" />
        </v-col>
        <!-- Society -->
        <v-col cols="3" class="ma-0 pl-1">
          <date-picker
            v-model="details.afExpirationDate"
            :label="t('informations.afExpirationDate')"
            icon="mdi-airplane"
          />
        </v-col>
      </v-row>
    </div>

    <!-- Buttons -->
    <div class="mt-10 text-center">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">{{ t('cancel') }}</mybb-btn>
      <mybb-btn
        color="mybb-success"
        :disabled="!canSave"
        :loading="loading"
        inner-icon="mdi-content-save"
        @click="save"
      >
        {{ t('confirm') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import { CREATE_PARTICIPANT } from '@/graphql/Participant'

import Modal from '../Modal'
import DatePicker from '../ui/DatePicker'

export default {
  name: 'ManualParticipantModal',
  components: { Modal, DatePicker },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    gathering: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      participationType: null,
      details: {
        gender: null,
        title: null,
        lastName: null,
        firstName: null,
        email: null,
        phoneNumber: null,
        address: null,
        birthDate: null,
        society: null,
        sncfNumber: null,
        sncfCard: null,
        sncfExpirationDate: null,
        afNumber: null,
        afCard: null,
        afExpirationDate: null
      }
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        this.$nextTick(() => {
          if (value) return

          this.participationType = null
          this.details = {
            gender: null,
            title: null,
            lastName: null,
            firstName: null,
            email: null,
            phoneNumber: null,
            address: null,
            birthDate: null,
            society: null,
            sncfNumber: null,
            sncfCard: null,
            sncfExpirationDate: null,
            afNumber: null,
            afCard: null,
            afExpirationDate: null
          }
        })
      }
    },
    isHybrid() {
      if (!this.gathering) return false

      return this.gathering.isPhysical && this.gathering.isVirtual
    },
    canSave() {
      return (
        this.details.gender &&
        this.details.lastName &&
        this.details.firstName &&
        this.details.lastName &&
        this.details.email &&
        (!this.isHybrid || (this.isHybrid && this.participationType))
      )
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.participants.manual-add-modal.${key}`, params)
    },
    async save() {
      if (!this.canSave || this.loading) return
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: CREATE_PARTICIPANT,
          variables: {
            gatheringUuid: this.$route.params.gatheringUuid,
            participantDetails: this.details,
            participationType: this.participationType
          }
        })

        this.$emit('saved')
        this.modal = false
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
