<template>
  <side-panel :title="t('title')" :only-open="onlyOpen">
    <!-- LVCs -->
    <div>
      <mybb-text class="d-block mt-3" weight="bold">
        {{ t('lvcs') }}
      </mybb-text>

      <v-row>
        <v-col v-for="file of lvcFiles" :key="file.fileGatheringUuid" cols="4">
          <input-file :file="file.file" :title="file.label" accept=".docx" hide-edit hide-delete>
            <template v-if="file.categories && file.categories.length > 0" v-slot:tooltip>
              <mybb-text weight="semi-bold" color="white">{{ t('lvcTooltip') }}</mybb-text>
              <ul>
                <li v-for="category of file.categories" :key="categoryKey(file, category)">
                  {{ categoryLabel(category) }}
                </li>
              </ul>
            </template>
          </input-file>

          <mybb-text v-if="isHybridCongress" class="mt-1 mb-3 ml-1">
            <mybb-text weight="bold">{{ t('lvcParticipation.label') }}</mybb-text>
            <mybb-text class="ml-1">{{ fileParticipation(file) }}</mybb-text>
          </mybb-text>
        </v-col>
      </v-row>
    </div>

    <!-- Other files -->
    <div>
      <mybb-text class="d-block mt-3" weight="bold">
        {{ t('otherFiles') }}
      </mybb-text>

      <v-row>
        <v-col v-for="file of otherFiles" :key="file.fileGatheringUuid" cols="4">
          <input-file :file="file.file" :title="file.label" accept=".docx" hide-edit hide-delete>
            <template v-slot:tooltip>
              <mybb-text color="white">
                <mybb-text weight="semi-bold" color="white">
                  {{ t('public.label') }}
                </mybb-text>
                {{ t(file.displayToHcp ? 'public.yes' : 'public.no') }}
              </mybb-text>
            </template>
          </input-file>
        </v-col>
      </v-row>
    </div>
  </side-panel>
</template>

<script>
import Categories from 'mybb-categories'

import { file } from '@/mixins'
import SidePanel from '@/components/mybb/ui/SidePanel'
import InputFile from '@/components/mybb/InputFile'

export default {
  name: 'DocumentsTile',
  mixins: [file],
  components: { SidePanel, InputFile },
  props: {
    gathering: {
      type: Object,
      default: () => {}
    },
    onlyOpen: {
      type: Boolean
    }
  },
  computed: {
    categories() {
      return this.gathering ? Categories.forVersion(this.gathering.categoryVersion) : []
    },
    isHybridCongress() {
      if (!this.gathering) return false

      return Boolean(this.gathering.congress) && this.gathering.isPhysical && this.gathering.isVirtual
    },
    fileTypes() {
      return this.$const.gatheringFileType
    },
    files() {
      return this.gathering
        ? this.gathering.gatheringFiles.map(gatheringFile => ({
            ...gatheringFile,
            file: this.formatBackendFile(gatheringFile.file)
          }))
        : []
    },
    lvcFiles() {
      return this.files.filter(gatheringFile => gatheringFile.type === this.fileTypes.LVC)
    },
    otherFiles() {
      const WEIGHT_MAP = {
        [this.fileTypes.PROGRAM]: 0,
        [this.fileTypes.EXPENSE_FEE_FORM]: 1,
        [this.fileTypes.OTHER]: 2
      }

      return this.files
        .filter(gatheringFile =>
          [this.fileTypes.PROGRAM, this.fileTypes.EXPENSE_FEE_FORM, this.fileTypes.OTHER].includes(gatheringFile.type)
        )
        .sort((gatheringFile1, gatheringFile2) => {
          return WEIGHT_MAP[gatheringFile1.type] - WEIGHT_MAP[gatheringFile2.type]
        })
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.gatheringDetails.documents.${key}`, params)
    },
    categoryKey(file, category) {
      const { isPhysical } = file

      let type
      if (typeof isPhysical === 'boolean') {
        type = isPhysical ? 'physical' : 'virtual'
      }

      return `${file.fileGatheringUuid}_${category}_${type}`
    },
    categoryLabel(categoryId) {
      const category = this.categories.find(cat => cat.id === categoryId)

      return category ? category.label : '- -'
    },
    fileParticipation(file) {
      return file.isPhysical ? this.t('lvcParticipation.physical') : this.t('lvcParticipation.virtual')
    }
  }
}
</script>
