import _has from 'lodash/get'
import _transform from 'lodash/transform'
import _isEqual from 'lodash/isEqual'
import _isObject from 'lodash/isObject'
import CONST from '../../const/shared'

const SENSIBLE_FIELDS = ['businessUnitUuids', 'description', 'timezone', 'location', 'visioUrl', 'limitDate']

const CONGRESS_SENSIBLE_FIELDS = ['learnedSociety', 'nationalityType', 'websiteUrl', 'additionalLinks']

const BIOGEN_EVENT_SENSIBLE_FIELDS = ['availablePlace', 'eventType', 'invitationType']

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 * @see https://gist.github.com/Yimiprod/7ee176597fef230d1451
 */
function deepDiff(object, base) {
  function changes(object, base) {
    return _transform(object, function(result, value, key) {
      if (!_isEqual(value, base[key])) {
        result[key] = _isObject(value) && _isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

function _hydrateCongressSensibleChanges(event, gathering, differences, sensiblesChanges) {
  for (const field of CONGRESS_SENSIBLE_FIELDS) {
    if (_has(differences.congress || {}, field)) {
      sensiblesChanges.add(`congress.${field}`)
    }
  }

  // Congress field - Gathering business unit
  const allGatheringBusinessUnits = (event.gatheringBusinessUnits || []).concat(gathering.gatheringBusinessUnits || [])
  for (const gatheringBusinessUnit of allGatheringBusinessUnits) {
    const { businessUnitUuid } = gatheringBusinessUnit

    const eventGatheringBusinessUnit = (event.gatheringBusinessUnits || []).find(
      gbu => gbu.businessUnitUuid === businessUnitUuid
    )
    const gatheringGatheringBusinessUnit = (gathering.gatheringBusinessUnits || []).find(
      gbu => gbu.businessUnitUuid === businessUnitUuid
    )

    if (!eventGatheringBusinessUnit || !gatheringGatheringBusinessUnit) {
      sensiblesChanges.add('gatheringBusinessUnits')
      continue
    }

    for (const property of [
      'doctorQuota',
      'hasAutomatedValidation',
      'hasZoneQuota',
      'mslResponsibleCriteria',
      'otherDisciplinesQuota',
      'solicitationEndDate'
    ]) {
      if (eventGatheringBusinessUnit[property] !== gatheringGatheringBusinessUnit[property]) {
        sensiblesChanges.add('gatheringBusinessUnits')
      }
    }
  }

  // Congress field - Zone congresses
  const allZoneCongresses = (event.congress.zoneCongresses || []).concat(gathering.congress.zoneCongresses || [])
  for (const zoneCongress of allZoneCongresses) {
    const eventZone = (event.congress.zoneCongresses || []).find(zc => zc.zoneUuid === zoneCongress.zoneUuid)
    const gatheringZone = (gathering.congress.zoneCongresses || []).find(zc => zc.zoneUuid === zoneCongress.zoneUuid)

    if (!eventZone || !gatheringZone || eventZone.zoneQuota !== gatheringZone.zoneQuota) {
      sensiblesChanges.add('congress.zoneCongresses')
    }
  }

  // Congress field - Criterias
  const eventCriterias = event.congress.criterias || []
  const gatheringCriterias = gathering.congress.criterias || []
  if (
    eventCriterias.some(criteria => !gatheringCriterias.includes(criteria)) ||
    gatheringCriterias.some(criteria => !eventCriterias.includes(criteria))
  ) {
    sensiblesChanges.add('congress.criterias')
  }
}

function _hydrateBiogenEventSensibleChanges(event, gathering, differences, sensiblesChanges) {
  for (const field of BIOGEN_EVENT_SENSIBLE_FIELDS) {
    if (_has(differences, `biogenEvent.${field}`)) {
      sensiblesChanges.add(`biogenEvent.${field}`)
    }
  }
}

export default function getSensibleChanges(event, gathering) {
  const sensiblesChanges = new Set()
  const differences = deepDiff(event, gathering)

  // Gathering fields
  for (const field of SENSIBLE_FIELDS) {
    if (_has(differences, field)) {
      sensiblesChanges.add(field)
    }
  }

  // Gathering fields - begin & end hour
  if (Array.isArray(gathering.eventSchedule)) {
    if (event.beginHour !== gathering.eventSchedule[0]) {
      sensiblesChanges.add('beginHour')
    }
    if (event.endHour !== gathering.eventSchedule[1]) {
      sensiblesChanges.add('endHour')
    }
  }

  // Gathering field - support team
  if (event.users.length !== gathering.users.length) {
    const eventSensibleUsers = event.users.filter(user => user.businessUnitUuid)
    const gatheringSensibleUsers = gathering.users.filter(user => user.businessUnitUuid)

    if (eventSensibleUsers.length !== gatheringSensibleUsers.length) {
      sensiblesChanges.add('users')
    }
  }

  const allUsers = (event.users || []).concat(gathering.users || [])
  for (const user of allUsers) {
    if (!user.businessUnitUuid) continue

    const { businessUnitUuid, gatheringUserType, isBackup } = user

    const eventUser = (event.users || []).find(
      usr =>
        usr.businessUnitUuid === businessUnitUuid &&
        usr.gatheringUserType === gatheringUserType &&
        usr.isBackup === isBackup
    )
    const gatheringUser = (gathering.users || []).find(
      usr =>
        usr.businessUnitUuid === businessUnitUuid &&
        usr.gatheringUserType === gatheringUserType &&
        usr.isBackup === isBackup
    )

    if (!eventUser || !gatheringUser || eventUser.userUuid !== gatheringUser.userUuid) {
      sensiblesChanges.add('users')
    }
  }

  // Gathering field - Main business unit
  if (event.mainBusinessUnitUuid !== gathering.mainBusinessUnitUuid) {
    sensiblesChanges.add('mainBusinessUnitUuid')
  }

  // Gathering field - Business units
  const allBus = (event.businessUnitUuids || []).concat(gathering.businessUnitUuids || [])
  for (const businessUnitUuid of allBus) {
    const eventBu = (event.businessUnitUuids || []).find(uuid => uuid === businessUnitUuid)
    const gatheringBu = (gathering.businessUnitUuids || []).find(uuid => uuid === businessUnitUuid)

    if (
      (!eventBu || !gatheringBu) &&
      [CONST.gatheringStatus.DRAFT, CONST.gatheringStatus.TO_VALIDATE, CONST.gatheringStatus.TO_PUBLISH].includes(
        event.status
      )
    ) {
      sensiblesChanges.add('businessUnitUuids')
    }
  }

  // Congress fields
  if (gathering.congress) {
    _hydrateCongressSensibleChanges(event, gathering, differences, sensiblesChanges)
  } else if (gathering.biogenEvent) {
    _hydrateBiogenEventSensibleChanges(event, gathering, differences, sensiblesChanges)
  }

  return Array.from(sensiblesChanges)
}
